<template>
  <div>
    <base-button
      data-testid="close-btn"
      class="absolute p-0 m-0"
      style="top: 28px; right:11px"
      icon="close"
      icon-size="18"
      color="text"
      @click="$modal.hide('level-setting')"
    />
    <p class="font-medium border-b p-4 text-lg pl-5 mt-2">
      {{ $t('boardSetting.createLevel.value') }}
    </p>
    <div class="p-4">
      <p class="text-sm font-medium pt-2">
        {{ $t('boardSetting.createLevel.name') }}
      </p>
      <div class="pt-1">
        <base-input-text
          v-model="levelname"
          data-testid="board-name-input"
          :placeholder="$t('boardSetting.createLevel.addName')"
          type="text"
        />
      </div>
      <p class="text-sm font-medium mt-4">
        {{ $t('boardSetting.createLevel.value') }}
      </p>
      <div class="grid grid-cols-6 gap-0" style="max-width: 480px;">
        <base-button
          v-for="level in 10"
          :key="level"
          style="max-width: 61px;"
          :color="selectColor(level)"
          :disabled="isDisabled(level)"
          wide="px-2 py-2 text-sm mt-2"
          class="shape-btn-level rounded-lg text-base font-semibold"
          @click="selectLevel(level)"
        >
          {{ level }}
        </base-button>
      </div>
      <div class="pt-5 text-sm">
        <span class="font-medium">{{
          $t('boardSetting.createLevel.description')
        }}</span>
        <span class="text-gray-caption">
          ({{ $t('boardSetting.createLevel.option') }})</span
        >
      </div>
      <div class="pt-2">
        <textarea-autosize
          v-model="formDescription"
          data-testid="description-textarea"
          class=" w-full resize-none base-input"
          :style="'background-color: white'"
          rows="4"
          :min-height="122"
          :placeholder="$t('boardSetting.createLevel.addDescription')"
          type="textarea"
        />
      </div>
      <div v-if="!isEditLevel" class="pt-5 flex">
        <base-button
          color="transparent"
          wide="px-8 py-2 text-sm"
          class="ml-auto"
        >
          {{ $t('cancel') }}
        </base-button>
        <base-button
          color="brand"
          wide="px-8 py-2 text-sm"
          :disabled="!this.levelname || this.localValue == 0"
          @click="submit"
        >
          {{ $t('boardSetting.createLevel.create') }}
        </base-button>
      </div>
      <div v-if="isEditLevel" class="pt-5 flex">
        <div
          class="grid justify-items-center py-2 text-red-danger ml-auto mr-4"
        >
          <span class=" cursor-pointer" @click="deleteCard">{{
            $t('boardSetting.createLevel.delete')
          }}</span>
        </div>
        <base-button color="brand" wide="px-8 py-2 text-sm" @click="submitEdit">
          {{ $t('boardSetting.createLevel.save') }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import BoardDialogConfirm from '@/components/board/board-dialog-confirm';

export default {
  data: () => ({
    levelname: '',
    localValue: 0,
    formDescription: '',
    editLevel: 0,
    isEdit: false,
    levelID: 0
  }),
  computed: {
    ...mapGetters('boards', ['getLevels', 'isEditLevel', 'getDataEditLevel']),
    boardID() {
      return parseInt(this.$route.params.id);
    }
  },
  mounted() {
    this.editLevel = this.getDataEditLevel.level;
    this.levelID = this.getDataEditLevel.id;
    this.formDescription = this.getDataEditLevel.description;
    this.levelname = this.getDataEditLevel.name;
  },
  methods: {
    ...mapActions('boards', ['createLevels', 'editLevels', 'deleteLevels']),
    selectLevel(level) {
      this.localValue = level;
      this.isEditLevel = false;
    },
    isDisabled(level) {
      const disabled = this.getLevels.filter(e => e.level == level);

      return disabled.length > 0 && this.editLevel != level;
    },
    selectColor(level) {
      if (this.isEditLevel) {
        return this.editLevel == level ? 'brand' : 'white';
      } else {
        return level == this.localValue ? 'brand' : 'white';
      }
    },
    create() {
      this.$emit('createLevel');
      this.levelID = 0;
      this.isEdit = false;
      this.formDescription = '';
      this.levelname = '';

      const difficult = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      const allLevels = this.getLevels.map(e => e.level);
      const activeLevel = difficult.filter(val => !allLevels.includes(val));

      this.localValue = activeLevel.length ? Math.min(...activeLevel) : 0;
    },
    submit() {
      this.createLevels({
        boardID: this.boardID,
        description: this.formDescription,
        level: this.localValue,
        name: this.levelname
      });

      this.$emit('cancel');
      this.resetValue();
      this.$modal.hide('level-setting');
    },
    submitEdit() {
      this.editLevels({
        levelID: this.levelID,
        description: this.formDescription,
        level: this.localValue,
        name: this.levelname
      });
      this.resetValue();
      this.$emit('cancel');
      this.$modal.hide('level-setting');
    },
    async deleteCard() {
      this.$modal.show(
        BoardDialogConfirm,
        {
          title: 'Delete level',
          description: 'Are you sure you want to delete this level?',
          color: 'text-red-600',
          button: 'Delete Level',
          onConfirm: async () => {
            await this.deleteLevels(this.levelID);
            this.resetValue();
            this.$emit('cancel');
            this.$modal.hide('level-setting');
          }
        },
        {
          height: 'auto',
          maxWidth: 440,
          adaptive: true
        }
      );
    },
    resetValue() {
      this.isEditLevel = false;
      this.editLevel = 0;
      this.localValue = 0;
    }
  }
};
</script>
