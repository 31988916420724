import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      loading: false
    };
  },
  methods: {
    onLoading() {
      this.loading = true;
    },
    offLoading() {
      this.loading = false;
    },
    toggleLoading() {
      this.loading = !this.loading;
    }
  }
});
