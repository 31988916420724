<template>
  <div>
    <div v-if="loading" class="has-centered-item h-full">
      <base-spinner size="60" />
    </div>
    <div v-else class="p-5">
      <!-- bio start -->
      <div class="user-info__box">
        <info-user :user="user" with-email size="60px" />
        <div class="mt-5">
          <h3 class="user-info__heading">{{ $t('admin.bio') }}</h3>
          <p class="overflow-hidden text-gray-600 text-sm max-w-xl">
            {{ user.bio }}
          </p>
        </div>
      </div>
      <!-- bio end -->

      <!-- dashboard start -->
      <div class="mt-5">
        <h3 class="user-info__heading">
          {{ $t('admin.dashborad') }}
        </h3>
        <div>
          <div class="dashboard__row">
            <total-plan-unplan :value="totalPlanUnplan" />
            <total-boards-tasks :value="totalBoardsTasks" />
          </div>
          <div class="dashboard__row">
            <total-working-time
              ref="totalWorkingTime"
              :value="planUnplanByBoard"
              @show-all="setUserTimelogsHeight"
            />

            <user-timelogs
              :height="userTimelogsHeight"
              :timelogs="planUnplanByBoard"
            />
          </div>
        </div>
      </div>
      <!-- dashboard end -->
    </div>
  </div>
</template>

<script>
import apisDashboards from '@/services/apis-dashboards';
import apis from '@/services/apis';
import LoadingMixin from '@/mixins/LoadingMixin';
import InfoUser from '@/components/info/info-user';
import TotalPlanUnplan from '@/components/admin/TotalPlanUnplan';
import TotalBoardsTasks from '@/components/admin/TotalBoardsTasks';
import TotalWorkingTime from '@/components/admin/TotalWorkingTime';
import UserTimelogs from '@/components/admin/UserTimelogs';
import WindowResize from '@/mixins/WindowResize';

const HEADER_HEIGHT = 42;
const DEFAULT_HEIGHT = '0px';
const MAX_HEIGHT = 512;
const MOBILE_WIDTH = 768;

export default {
  mixins: [LoadingMixin, WindowResize],
  components: {
    InfoUser,
    TotalPlanUnplan,
    TotalBoardsTasks,
    TotalWorkingTime,
    UserTimelogs
  },
  data() {
    return {
      user: null,
      totalPlanUnplan: null,
      totalBoardsTasks: null,
      planUnplanByBoard: [],
      userTimelogsHeight: DEFAULT_HEIGHT
    };
  },
  computed: {
    userId() {
      return this.$route.query.user;
    },
    apiUsers() {
      const userId = parseInt(this.userId);
      return {
        ...apisDashboards.user(userId),
        getUser() {
          return apis.get(`/user/${userId}`);
        }
      };
    },
    totalWorkingTimeComp() {
      return this.$refs.totalWorkingTime;
    }
  },
  watch: {
    userId: {
      async handler(userId) {
        if (!userId) return;
        this.onLoading();
        await Promise.all([
          this.fetchUserProfile(),
          this.fetchTotalPlanUnPlan(),
          this.fetchTotalBoardsAndTasks(),
          this.fetchPlanAndUnPlanByBoard()
        ]);
        this.offLoading();
      },
      immediate: true
    }
  },
  methods: {
    async fetchUserProfile() {
      const { data } = await this.apiUsers.getUser();
      this.user = data;
    },
    async fetchTotalPlanUnPlan() {
      const { data } = await this.apiUsers.totalPlanAndUnPlan();
      this.totalPlanUnplan = data;
    },
    async fetchTotalBoardsAndTasks() {
      const { data } = await this.apiUsers.totalBoardsAndTasks();
      this.totalBoardsTasks = data;
    },
    async fetchPlanAndUnPlanByBoard() {
      const { data } = await this.apiUsers.planAndUnPlanByBoard();
      this.planUnplanByBoard = data;
    },
    setUserTimelogsHeight() {
      this.userTimelogsHeight = DEFAULT_HEIGHT;
      this.$nextTick(function() {
        const windowWidth = window.innerWidth;
        const refHeight = this.totalWorkingTimeComp.$el.clientHeight;
        const baseHeight = windowWidth > MOBILE_WIDTH ? refHeight : MAX_HEIGHT;
        this.userTimelogsHeight = `${baseHeight - HEADER_HEIGHT}px`;
      });
    },
    windowResizeCallback() {
      this.setUserTimelogsHeight();
    }
  }
};
</script>

<style>
.user-info__box {
  @apply px-8 py-4 border rounded-md;
}
.user-info__heading {
  @apply font-medium mb-3;
}
.dashboard__row {
  @apply grid grid-cols-1 grid-rows-1 gap-5 mt-5;
}

@screen lg {
  .dashboard__row {
    @apply grid-cols-2;
  }
}
</style>
