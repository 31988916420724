


































import Vue from 'vue';
import serviceApis from '@/services/apis';
import InfoUser from '@/components/info/info-user.vue';
import AdminUser from './AdminUser.vue';
import { User } from '@/types/User';
import {
  DashboardBoardsTasks,
  DashboardPlanAndUnPlanByBoard,
  DashboardPlanUnplan
} from '@/types/Dashboard';

interface LoadingFn {
  (loading: boolean): void;
}

interface ActiveUser extends User {
  totalPlanUnplan: DashboardPlanUnplan;
  totalBoardsTasks: DashboardBoardsTasks;
  planUnplanByBoard: DashboardPlanAndUnPlanByBoard;
}

export default Vue.extend({
  components: {
    InfoUser,
    AdminUser
  },
  data() {
    return {
      loading: false,
      userOptions: [] as User[]
    };
  },
  computed: {
    selectOptions() {
      return {
        filterable: false,
        clearSearchOnBlur: () => false
      };
    },
    activeUserId(): string {
      return this.$route.query.user as string;
    }
  },
  mounted() {
    this.fetchUsers('', () => null);
  },
  methods: {
    fetchUsers(search: string, loading: LoadingFn) {
      loading(true);
      this.$nextTick(async function() {
        const config = {
          params: {
            q: search
          }
        };
        const { data } = await serviceApis.get('/search/users', config);
        this.userOptions = data.entities;
        loading(false);
      });
    },
    selectUser(user: User) {
      this.$router.push({
        query: {
          user: user.id.toString()
        }
      });
    }
  }
});
