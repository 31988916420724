import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import authModule from './auth';
import boards from './modules/boards';
import boardColumn from './modules/board-column';
import tasks from './modules/tasks';
import version from './modules/version';
import notifications from './modules/notifications';
import tags from './modules/tags';
import timeLog from './modules/time-log';
import members from './modules/members';
import folders from './modules/folders';
import dashboard from './modules/dashboard';
import sprint from './modules/sprint';
import teams from './modules/teams';
import subscription from './modules/subscription';
Vue.use(Vuex);

export const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth: authModule,
    boards,
    tasks,
    version,
    notifications,
    tags,
    'board-column': boardColumn,
    'time-log': timeLog,
    members,
    folders,
    dashboard,
    sprint,
    teams,
    subscription
  }
});
