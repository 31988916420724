import moment from 'moment';
import apis, { restFactory } from './apis';
import {
  PlannedTimesResponse,
  TimeLog,
  TimeLogCreate,
  TimeLogDateRangeResponse,
  TimeLogUpdate
} from '@/types/TimeLog';

interface StartEndDates {
  beginOfStartDate: Date;
  endOfEndDate: Date;
}

const startDay = (d: Date) =>
  moment(d)
    .startOf('day')
    .toISOString();

const weekStartEnd = (d: Date) =>
  [moment(d).startOf('week'), moment(d).endOf('week')].map(e =>
    e.toISOString()
  );

export default {
  ...restFactory<TimeLog, TimeLogCreate, TimeLogUpdate>('timelogs'),
  getTimelogByDay(day: Date) {
    return apis.get<TimeLog[]>('/timelogs/day', {
      params: { beginOfDay: startDay(day) }
    });
  },
  getTimelogByWeek(day: Date) {
    const [beginOfStartDate, endOfEndDate] = weekStartEnd(day);
    return apis.get<TimeLogDateRangeResponse[]>('/timelogs/week', {
      params: { beginOfStartDate, endOfEndDate }
    });
  },
  getTimelogByDateRange(params: StartEndDates) {
    return apis.get<TimeLogDateRangeResponse[]>('/timelogs/dateRange', {
      params
    });
  },
  getPlannedTimes(params: StartEndDates) {
    return apis.get<PlannedTimesResponse[]>('timelogs/planAndUnPlan', {
      params
    });
  }
};
