import Vue, { PluginObject } from 'vue';
import Firebase from '../services/firebase';

const FirebaseAnalytics: PluginObject<never> = {
  install() {
    Vue.prototype.$fana = Firebase.analytics();
  }
};

export default FirebaseAnalytics;
