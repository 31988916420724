




import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class Spinner extends Vue {
  @Prop({ type: Boolean, default: true }) active!: boolean;
  @Prop({ type: [String, Number], default: 30 }) size!: string | number;
  @Prop({ type: [String, Number], default: 3 }) thick!: string | number;
  @Prop({ type: String, default: null }) color!: string | null;

  get style() {
    const { size, thick } = this;
    const [width, height, border] = [size, size, thick].map(this.toPx);
    return { width, height, 'border-width': border };
  }

  get colorClass() {
    switch (this.color) {
      case 'primary':
        return 'border-brand-blue';
      default:
        return 'border-gray-600';
    }
  }

  toPx = (value: number | string) => `${value}px`;
}
