<template>
  <div class="flex my-12 ">
    <div class="flex ml-auto items-center gap-x-0.5 cursor-pointer">
      <fa-icon
        icon="chevron-left"
        class="mr-4 "
        @click="pageSelected > 1 ? (pageSelected -= 1) : ''"
      />
      <div v-for="index in getTotal" :key="index">
        <span
          v-if="index == 1"
          :class="{ 'bg-brand-blue text-white': index == pageSelected }"
          class="px-3 py-2 rounded "
          @click="pageSelected = index"
        >
          {{ index }}
        </span>

        <span
          v-if="index == pageSelected - 2 && index != 1 && index != getTotal"
          class="px-3 py-2 rounded "
          @click="pageSelected = index"
        >
          ...
        </span>
        <span
          v-if="index == pageSelected - 1 && index != 1 && index != getTotal"
          :class="{ 'bg-brand-blue text-white': index == pageSelected }"
          class="px-3 py-2 rounded "
          @click="pageSelected = index"
        >
          {{ index }}
        </span>
        <span
          v-if="index == pageSelected && index != 1 && index != getTotal"
          :class="{ 'bg-brand-blue text-white': index == pageSelected }"
          class="px-3 py-2 rounded "
          @click="pageSelected = index"
        >
          {{ index }}
        </span>

        <span
          v-if="index == pageSelected + 1 && index != 1 && index != getTotal"
          :class="{ 'bg-brand-blue text-white': index == pageSelected }"
          class="px-3 py-2 rounded "
          @click="pageSelected = index"
        >
          {{ index }}
        </span>
        <span
          v-if="index == pageSelected + 2 && index != 1 && index != getTotal"
          class="px-3 py-2 rounded "
          @click="pageSelected = index"
        >
          ...
        </span>

        <span
          v-if="index == getTotal && index != 1"
          :class="{ 'bg-brand-blue text-white': index == pageSelected }"
          class="px-3 py-2 rounded "
          @click="pageSelected = index"
        >
          {{ index }}
        </span>
      </div>
      <fa-icon
        icon="chevron-right"
        class="ml-4"
        @click="pageSelected < getTotal ? (pageSelected += 1) : ''"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Number,
      default: () => 10
    }
  },

  data() {
    return {
      //   pageTotal: 0,
      pageSelected: 1,
      member: [],
      dataPerPage: 10,
      workspceShow: 10,
      spaceUserSelected: []
    };
  },
  watch: {
    pageSelected(val) {
      this.$emit('pageCurrent', val);
    }
  },
  mounted() {
    this.$emit('perPage', this.dataPerPage);
    this.$emit('pageCurrent', 1);
  },
  computed: {
    getTotal() {
      const pageTotal = Math.ceil(this.list / this.dataPerPage);
      return pageTotal;
    }
  }
};
</script>
