




















































































import Vue, { PropType } from 'vue';
import VClamp from 'vue-clamp';
import { TaskAssign } from '@/types/Task';
import { BoardMember } from '@/types/Board';
import { User } from '@/types/User';
import QueryListMixin from '@/mixins/QueryListMixin';
import apis from '@/services/apis';
import { AxiosRequestConfig } from 'axios';
import BoardUtils from '@/mixins/BoardUtils';

export interface EmitSelectTaskAssign {
  userID: number;
  isAssigned: boolean;
}

export default Vue.extend({
  components: { VClamp },
  mixins: [QueryListMixin, BoardUtils],
  props: {
    assigns: {
      type: Array as PropType<TaskAssign[]>
    },
    value: {
      type: Array as PropType<BoardMember[]>
    },
    dropdownPosition: {
      type: String,
      default: null
    },
    disabled: Boolean
  },
  computed: {
    boardId(): number {
      return (
        parseInt(this.$route.params.id, 10) |
        this.$store.getters['boards/getActiveBoardId']
      );
    },
    members(): BoardMember[] {
      return this.$store.getters['members/getMembers'];
    },
    users(this: QueryListMixin<User>): User[] {
      return this.queryList;
    }
  },
  methods: {
    queryFetcher({ params }: AxiosRequestConfig) {
      return apis.get('/search/users', {
        params: {
          ...params,
          type: 2
        }
      });
    },
    isAssigned(user: User): boolean {
      const hasUser = (e: TaskAssign): boolean => {
        return e.userID === user.id;
      };
      const availableList = this.assigns || this.value || [];
      return availableList.some(hasUser);
    },
    selectMember(user: User) {
      const eventData = {
        userID: user.id,
        isAssigned: this.isAssigned(user)
      };
      this.select(eventData);
    },
    select(e: EmitSelectTaskAssign): EmitSelectTaskAssign | BoardMember[] {
      if (!this.value) {
        this.$emit('select', e);
        return e;
      }
      const updatedAssigns = e.isAssigned
        ? this.value.filter(val => val.userID !== e.userID)
        : [...this.value, e];
      const inputValue = updatedAssigns.map(
        assign =>
          this.members.find(m => m.userID === assign.userID) as BoardMember
      );
      this.$emit('input', inputValue);
      return inputValue;
    },
    atBottom(this: QueryListMixin<User>) {
      if (this.shouldQueryNextPage) {
        this.addQueryNextPage();
      }
    }
  }
});
