



















import { Vue, Watch, Prop, Component, Emit, Ref } from 'vue-property-decorator';

export interface EmitChange {
  text: string;
  isEmpty: boolean;
}

@Component
export default class ParagraphEditable extends Vue {
  @Prop({ type: String, default: '' }) readonly value!: string;
  @Prop({ type: Boolean }) readonly disabled!: boolean;
  @Prop() readonly placeholder!: string;
  @Prop({ type: Boolean }) readonly bordered!: boolean;
  @Prop({ type: Boolean }) readonly autofocus!: boolean;
  @Prop({ type: Boolean, default: true }) readonly enterToBlur!: boolean;
  @Ref('p') refP?: HTMLParagraphElement;

  mounted() {
    if (this.autofocus) {
      this.selectText();
      this.focused = true;
    }
    if (this.refP) {
      this.refP.addEventListener('paste', this.setPasteListener);
      this.refP.innerText = this.value;
    }
  }

  beforeUnmount() {
    this.removePasteListener();
  }

  text = '';

  @Watch('value', { immediate: true })
  onValue(val: string) {
    this.text = val;
  }

  get isEmpty(): boolean {
    return !this.text.replace(/\s/g, '');
  }

  @Emit('input')
  onInput(e: InputEvent) {
    const text = (e.target as HTMLParagraphElement).innerText;
    this.text = text;
    return text;
  }

  @Emit('change')
  onChange() {
    return {
      text: this.text,
      isEmpty: this.isEmpty
    };
  }

  focused = false;

  @Emit('blur')
  handleBlur() {
    if (this.text !== this.value) {
      this.onChange();
    }
    this.focused = false;
  }

  selectText() {
    const $el = this.$el as HTMLInputElement;
    const range = document.createRange();
    const sel = window.getSelection();
    if (!sel || !$el) return;

    range.selectNodeContents($el);

    sel.removeAllRanges();
    sel.addRange(range);
    $el.focus();
  }

  @Emit('focus')
  addOutline() {
    const p = this.refP;
    if (p) {
      // p.innerText = p.innerText.replace(/\n/g, '');
      p.classList.add('p-editable--focused');
      // this.setCursorAtLast();
    }
  }

  focus() {
    this.refP?.focus();
    this.setCursorAtLast();
  }

  blur() {
    this.refP?.blur();
  }

  setCursorAtLast() {
    const $el = this.$el as HTMLInputElement;
    const range = document.createRange();
    const sel = window.getSelection();
    if (!sel || !$el) return;
    const cursorPosition = this.isEmpty ? 0 : 1;

    range.setStart($el, cursorPosition);
    range.setEnd($el, cursorPosition);

    sel.removeAllRanges();
    sel.addRange(range);
    $el.focus();
  }

  // paste handle
  setPasteListener(ev: ClipboardEvent) {
    // cancel paste
    ev.preventDefault();
    // get text representation of clipboard
    const text = ev.clipboardData?.getData('text/plain');
    // insert text manually
    document.execCommand('insertHTML', false, text);
  }
  removePasteListener() {
    this.refP?.removeEventListener('paste', this.setPasteListener);
  }

  onKeypressEnter() {
    if (this.enterToBlur) this.refP?.blur();
  }

  @Emit('keypress')
  onKeypress(ev: KeyboardEvent) {
    return ev;
  }
}
