<template>
  <div class="bg-overlay">
    <div class="overlay-content">
      <p class="mb-4 text-2xl">{{ $t('title') }}</p>
      <div class="mb-6" v-html="$t('description')" />

      <base-button
        v-if="!isAdmin"
        class="mb-5 px-5 text-xs self-center"
        style="width: 269px;"
        @click="notiToOwner()"
        >{{ $t('noti_owner') }}</base-button
      >
      <p class="pointer" @click="$emit('closeViewOnly')">{{ $t('cancel') }}</p>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import { TeamMemberRole } from '@/types/Team';

export default {
  computed: {
    getTeamId() {
      return this.$route.params.id;
    },
    isAdmin() {
      const members = this.$store.getters['teams/getTeam'];
      return members.currentTeamMember.role === TeamMemberRole.Admin;
    }
  },
  methods: {
    notiToOwner: debounce(function() {
      this.$store.dispatch(
        'subscription/sendNotiSuscriptionOwner',
        this.getTeamId
      );
    }, 1000)
  }
};
</script>
<style scoped>
.bg-overlay {
  @apply h-full w-full fixed text-white;
  z-index: 30;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.16) 0%,
    rgba(0, 0, 0, 0.8) 89.66%
  );
}

.overlay-content {
  @apply flex flex-col fixed bottom-0 text-center mb-4;
  left: 45%;
}
</style>

<i18n>
{
  "en": {
    "title": "Your current permission is view only",
    "description": "You cannot create or edit the tasks,<br/>please contact the Workspace's owner to proceed",
    "cancel": "Cancel",
    "noti_owner": "Notify the owner for permission"
  },
  "th": {
    "title": "ตอนนี้สิทธิ์ของคุณคือ ดูได้อย่างเดียว",
    "description": "ไม่สามารถสร้างหรือแก้งานต่างๆได้ <br/>หากต้องการแก้ไขโปรดแจ้งเจ้าของเวิร์คสเปซเพื่อดำเนินการใช้ต่อ",
    "cancel": "ยกเลิก",
    "noti_owner": "แจ้งเตือนเจ้าของเวิร์คสเปซเพื่อปลดล็อก"
  }
}
</i18n>
