<template>
  <div
    class="h-full flex flex-col justify-between"
    data-testid="dialog-confirm"
  >
    <div class="p-5">
      <h2 class="text-xl mb-2 font-medium" v-html="title" />
      <p
        class="resize-none text-gray-600 text-sm break-words"
        v-html="description"
      />
    </div>

    <div class="flex justify-end p-5 pt-0 mt-4">
      <div class="pr-4">
        <button
          data-testid="cancel-dialog-btn"
          class="rounded mb-0 outline-none border border-gray-400 py-2 px-8"
          color="text"
          full
          @click="$emit('close')"
        >
          {{ $t('dashboard.cancel') }}
        </button>
      </div>
      <div>
        <button
          data-testid="confirm-dialog-btn"
          class="rounded mb-0 outline-none border border-red-600 text-red-600 py-2 px-8"
          @click="confirm"
        >
          {{ button }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const FnType = {
  type: Function,
  default: () => {
    return;
  }
};
export default {
  props: {
    confirmData: {
      type: [Number, String, Object],
      default: null
    },
    color: {
      type: String,
      default: 'text-brand-blue'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default() {
        return this.$t('timelog.confirm');
      }
    },
    confirmAction: {
      type: String,
      default: '',
      validator(val) {
        if (!val) return true;
        return /[a-z-]+\/[a-z]+/i.test(val);
      }
    },
    onConfirm: FnType,
    confirmCallback: FnType
  },
  methods: {
    async confirm() {
      this.$emit('confirm');
      this.onConfirm();
      try {
        if (this.confirmAction) {
          await this.$store.dispatch(this.confirmAction, this.confirmData);
        }
        await this.confirmCallback();
      } catch (error) {
        // this.$notify({
        //   group: 'app-noti',
        //   title: 'Oops!',
        //   text: error.response.data.message,
        //   type: 'error'
        // });
      }
      this.$emit('close');
    }
  }
};
</script>

<style></style>
