import Vue from 'vue';
import eventBus from '@/services/eventBus';

export default Vue.extend({
  data() {
    return {
      screen: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    };
  },
  mounted() {
    eventBus.$on('window-resize', this.windowResize);
  },
  beforeDestroy() {
    eventBus.$on('window-resize', this.windowResize);
  },
  methods: {
    windowResize(ev: UIEvent) {
      this.screen = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      this.windowResizeCallback(ev);
    },
    windowResizeCallback(ev: UIEvent) {
      return ev;
    }
  }
});
