import { Module } from 'vuex';
import { firestore } from 'firebase';
import moment from 'moment';
import { RootState, TimeLogState } from '@/types/Store';
import apisTimeLogs from '@/services/apis-timelog';
import firebase, { getCollections } from '@/services/firebase';
import { User } from '@/types/User';
import {
  TimeLogInput,
  TimeLog,
  TimeLogCreate,
  TimeLogType,
  TimeLogUpdate
} from '@/types/TimeLog';
import { getNewListWithUpdate, getRemovedItemArray } from './utils';

function todayStart(): Date {
  return moment()
    .startOf('day')
    .toDate();
}

function isSameDay(a: Date, b: Date): boolean {
  return moment(a).isSame(moment(b), 'day');
}

function getType(t: TimeLogCreate): TimeLogType {
  if (!t.boardID) return TimeLogType.USER;
  if (t.taskID) return TimeLogType.TASK;
  return TimeLogType.BOARD;
}

function sortTimelog(a: TimeLog, b: TimeLog): number {
  return b.startAt.localeCompare(a.startAt);
}

function isTimer(t: TimeLogCreate) {
  return t.input === TimeLogInput.TIMER;
}

function groupDateRangeResponse(
  prev: TimeLog[],
  curr: { logs: TimeLog[] }
): TimeLog[] {
  return [...prev, ...curr.logs];
}

function getStartEndRange(date: Date): Date[] {
  return [moment(date).startOf('day'), moment(date).endOf('day')].map(e =>
    e.toDate()
  );
}

function addKeyToTimelog(timelog: TimeLog) {
  const key = Symbol();
  return {
    ...timelog,
    key
  };
}

function hasStartOrEndToday(timelog: TimeLog) {
  const today = new Date();
  const startEndAt = [timelog.startAt, timelog.endAt].map(e => new Date(e));
  return startEndAt.some(e => isSameDay(e, today));
}

export default {
  namespaced: true,
  state: {
    activeDateRange: [],
    workingLog: null,
    unsubscribe: null,
    list: [],
    today: [],
    detailTimelogs: [],
    editTimelog: undefined,
    workingLogLoading: true
  },
  getters: {
    getWorkingLog(s) {
      return s.workingLog;
    },
    getLoadings(s) {
      return {
        workingLog: s.workingLogLoading
      };
    },
    getTimelogs(s) {
      return s.list;
    },
    getEditTimelog(s) {
      return s.editTimelog;
    },
    getCurrentActiveDay(s) {
      return s.list[0]?.startAt;
    },
    getToday(s) {
      return s.today;
    },
    getDetailTimelogs(s) {
      return s.detailTimelogs;
    }
  },
  mutations: {
    SET_WORKING(s, payload: TimeLog | null) {
      s.workingLog = payload;
      s.workingLogLoading = false;
    },
    SET_UNSUBSCRIBE(s, payload) {
      s.unsubscribe = payload;
    },
    SET_TIMELOGS_LIST(s, payload: TimeLog[]) {
      s.list = payload.map(addKeyToTimelog);
    },
    ADD_TIMELOG(s, timelog: TimeLog) {
      const isBetween = (input: Date) => {
        if (!s.activeDateRange.length) return false;
        const [startAt, endAt] = s.activeDateRange;
        return moment(input).isBetween(startAt, endAt);
      };
      const startEndAt = [timelog.startAt, timelog.endAt].map(e => new Date(e));

      if (startEndAt.some(e => isBetween(e))) {
        s.list.push(timelog);
        s.list.sort(sortTimelog);
      }
      if (hasStartOrEndToday(timelog)) {
        s.today.push(timelog);
        s.today.sort(sortTimelog);
      }
    },
    REMOVE_TIMELOG(s, id: number) {
      s.list = getRemovedItemArray(s.list, id);
      s.detailTimelogs = getRemovedItemArray(s.detailTimelogs, id);

      const timelog = s.today.find(lg => lg.id === id);
      if (timelog && hasStartOrEndToday(timelog)) {
        s.today = getRemovedItemArray(s.today, id);
      }
    },
    SET_EDIT_TIMELOG(s, timelog?: TimeLog) {
      s.editTimelog = timelog;
    },
    SET_TIMELOG(s, timelog: TimeLog) {
      const today = new Date();
      const isTimelogStartOrEndToday = [
        timelog.startAt,
        timelog.endAt
      ].some(d => isSameDay(today, new Date(d)));
      const updateToday = () => {
        const newToday = getNewListWithUpdate(s.today, timelog);
        s.today = newToday;
      };

      s.list = s.list.map(e => (e.id === timelog.id ? timelog : e));
      s.detailTimelogs = s.detailTimelogs.map(e =>
        e.id === timelog.id ? timelog : e
      );
      if (isTimelogStartOrEndToday) updateToday();
    },
    SET_ACTIVE_DATE_RANGE(s, dateRange: Date[]) {
      s.activeDateRange = dateRange;
    },
    SET_TIMELOGS_TODAY(s, payload: TimeLog[]) {
      s.today = payload;
    },
    SET_WORKING_LOAD(s) {
      s.workingLogLoading = true;
    },
    SET_DETAIL_TIMELOGS(s, payload: TimeLog[]) {
      s.detailTimelogs = payload;
    }
  },
  actions: {
    // timer feature
    subscribeWorking({ rootGetters, commit }) {
      const user = rootGetters['auth/getUser'] as User;
      const userDoc = firebase
        .firestore()
        .collection(getCollections().users)
        .doc(user.id.toString())
        .collection(getCollections().timeLogs)
        .orderBy('start_at', 'desc')
        .limit(1);

      const unsub = userDoc.onSnapshot(async snap => {
        let timelog;
        const doc = snap.docs[0];
        if (doc && !doc.get('end_at')) {
          const data = doc.data();
          // waiting api
          // const { data: initLog } = await apisTimeLogs.get(doc.id);
          timelog = Object.keys(data).reduce((p, key) => {
            const browserKey = key.replace(/_([a-z])/g, (_, p1) =>
              p1?.toUpperCase()
            );
            let currentValue = data[key];
            if (currentValue instanceof firestore.Timestamp)
              currentValue = currentValue.toDate();
            return { ...p, [browserKey]: currentValue };
          }, {}) as TimeLog;
          timelog.id = parseInt(doc.id, 10);
        }
        commit('SET_WORKING', timelog);
      });
      commit('SET_UNSUBSCRIBE', unsub);
    },
    unsubscribeWorking({ state }) {
      state.unsubscribe?.();
    },
    async startWorking({ dispatch, commit }, startTimeLog: TimeLogCreate) {
      const isTypeTimer = isTimer(startTimeLog);
      const type = isTypeTimer ? undefined : getType(startTimeLog);
      const newTimelog = await apisTimeLogs.create({ ...startTimeLog, type });

      if (isTypeTimer) {
        commit('SET_WORKING_LOAD');
        await dispatch('subscribeWorking');
      } else {
        commit('ADD_TIMELOG', newTimelog.data);
      }
    },
    async stopWorking({ state, dispatch, commit }) {
      const workingLog = state.workingLog as TimeLog;
      const { data: newTimelog } = await apisTimeLogs.update(workingLog.id, {
        endAt: new Date().toISOString()
      });
      commit('SET_WORKING', null);
      commit('ADD_TIMELOG', newTimelog);
      dispatch('unsubscribeWorking');
    },
    // fetching
    async fetchTimelogsByDateRange({ commit }, dateRange: Date[]) {
      const { data } = await apisTimeLogs.getTimelogByDateRange({
        beginOfStartDate: dateRange[0],
        endOfEndDate: dateRange[1]
      });
      const allTimelogs = data.reduce(groupDateRangeResponse, []);
      commit('SET_TIMELOGS_LIST', allTimelogs);
      commit('SET_ACTIVE_DATE_RANGE', dateRange);
    },
    async fetchTodayTimelogs({ commit }) {
      const { data } = await apisTimeLogs.getTimelogByDay(todayStart());
      commit('SET_TIMELOGS_TODAY', data);
    },
    async fetchTimelogsByDay({ commit }, day: Date) {
      const { data } = await apisTimeLogs.getTimelogByDay(day);
      commit('SET_TIMELOGS_LIST', data);
      commit('SET_ACTIVE_DATE_RANGE', getStartEndRange(day));
    },
    async fetchTimelogsByWeek({ commit }, day: Date) {
      const { data } = await apisTimeLogs.getTimelogByWeek(day);
      commit('SET_TIMELOGS_LIST', data.reduce(groupDateRangeResponse, []));
    },
    async fetchDetailTimelogs({ commit }, day: Date) {
      const { data } = await apisTimeLogs.getTimelogByDay(day);
      commit('SET_DETAIL_TIMELOGS', data);
    },
    // operations
    async updateTimelog({ commit, getters }, timelogUpdate: TimeLogUpdate) {
      const editTimelog = getters.getEditTimelog as TimeLog | undefined;
      if (editTimelog) {
        const { data } = await apisTimeLogs.update(
          editTimelog.id,
          timelogUpdate
        );
        commit('SET_TIMELOG', data);
      }
    },
    async deleteTimelog({ commit }, id: number) {
      await apisTimeLogs.delete(id);
      commit('REMOVE_TIMELOG', id);
    }
  }
} as Module<TimeLogState, RootState>;
