import { Module } from 'vuex';
import router from '@/router';
import { RootState } from '@/types/Store';
import firebase, { getCollections } from '@/services/firebase';
import apis, { boardsApi } from '@/services/apis';
import {
  Board,
  BoardMemberRole,
  BoardsResponse,
  BoardType,
  BoardUpdate,
  BoardCopyRequest
} from '@/types/Board';
import { PageInformation } from '@/types/Api';
import { NotFound } from '@/types/Utils';
import { Folder } from '@/types/Folder';
import { getNewListWithUpdate, updateArrays } from './utils';

interface EveryBoards {
  all: Board[];
  recent: Board[];
  starred: Board[];
  folders: Folder[];
  levels: Board[];
  boardID: number;
}

interface State extends EveryBoards {
  loading?: boolean;
  activeBoard: NotFound | Board;
  archives: Board[];
  pageInformation: PageInformation;
  archivesPage: PageInformation;
  unsubscribe: () => void;
  isEditLevel?: boolean;
  dataEditLevel: []
}

const boardsModule: Module<State, RootState> = {
  namespaced: true,
  state: {
    boardID: 0,
    levels: [],
    all: [],
    recent: [],
    starred: [],
    folders: [],
    activeBoard: { notFound: true },
    archives: [],
    pageInformation: {
      page: 0,
      count: 0,
      lastPage: 0
    },
    unsubscribe: () => undefined,
    archivesPage: {
      count: 0,
      lastPage: 1,
      page: 0
    },
    isEditLevel: false,
    dataEditLevel:[]
  },
  getters: {
    getLevels: s => s.levels,
    getBoards: s => ({ all: s.all, recent: s.recent, starred: s.starred }),
    getActiveBoard: s => s.activeBoard,
    getActiveBoardId: s => (s.activeBoard as Board).id,
    getMembers: (_s, _g, _rs, rootGetter) => rootGetter['members/getMembers'],
    getCurrentUserMember: (state, getter, rootState, rootGetter) => ({
      ...rootGetter['auth/getUser'],
      ...(state.activeBoard as Board).currentMember
    }),
    getArchives: s => s.archives,
    getNextPage: ({ pageInformation: { page, lastPage } }) =>
      page < lastPage ? page + 1 : 0,
    isBoardActive: s => !(s.activeBoard as NotFound).notFound,
    getFolders: s => s.folders,
    isUserViewer: (_st, { getCurrentUserMember }) =>
      getCurrentUserMember.role === BoardMemberRole.Viewer,
    isUserAdmin: (_st, { getCurrentUserMember }) =>
      getCurrentUserMember.role === BoardMemberRole.Admin,
    isEditLevel: s => s.isEditLevel,
    getDataEditLevel: s => s.dataEditLevel,
    isViewOnly: s => (s.activeBoard as Board).viewOnly
  },
  mutations: {
    SET_LEVEL: (s, payload) => {
      s.levels = payload;
    },
    SET_BOARDS: (s, payload: BoardsResponse) => {
      const {
        all,
        recentlyViewed,
        starred,
        pageInformation,
        folders
      } = payload;
      s.all = all;
      s.recent = recentlyViewed;
      s.starred = starred;
      s.pageInformation = pageInformation;
      s.folders = folders;
    },
    SET_BOARDS_NEXT(s, payload: BoardsResponse) {
      s.all = [...s.all, ...payload.all];
      s.pageInformation = payload.pageInformation;
    },
    SET_BOARDS_EMPTY(state) {
      state.all = [];
      state.pageInformation = {
        page: 0,
        count: 0,
        lastPage: 0
      };
      state.folders = [];
    },
    SET_BOARD: (state, payload?: Board) => {
      const { all, recent, starred } = state;
      if (payload) {
        state.all = getNewListWithUpdate(all, payload);
        state.recent = getNewListWithUpdate(recent, payload);
        state.starred = getNewListWithUpdate(starred, payload);
      }
      state.activeBoard = payload || { notFound: true };
    },
    UPDATE_BOARD(s, payload: Board) {
      s.activeBoard = { ...s.activeBoard, ...payload };
    },
    UPDATE_BOARDS(s, payload: Board[]) {
      s.all = updateArrays(s.all, payload);
    },
    ADD_BOARD: (s, newBoard: Board) => {
      s.all.push(newBoard);
    },
    SET_ARCHIVES: (s, archives) => {
      s.archives = archives;
    },
    SET_ARCHIVES_PAGE: (s, pages) => {
      s.archivesPage = pages;
    },
    SET_UNSUB(s, payload) {
      s.unsubscribe = payload;
    },
    SET_EDIT_LEVEL(s, payload) {
      s.isEditLevel = payload;
    },
    SET_DATA_EDIT_LEVEL(s, payload) {
      s.dataEditLevel = payload;
    }
  },
  actions: {
    async fetchLevels({ commit, state }, boardID: number) {
      state.boardID = boardID;
      const { data } = await boardsApi.fetchLevel({ boardID });
      commit('SET_LEVEL', data);
    },
    async createLevels({ dispatch }, { boardID, description, level, name }) {
      await boardsApi.createLevel({
        boardID,
        description,
        level,
        name
      });
      dispatch('fetchLevels', boardID);
    },
    async editLevels(
      { dispatch, state },
      { levelID, description, level, name }
    ) {
      await boardsApi.editLevel(levelID, {
        description,
        level,
        name
      });
      dispatch('fetchLevels', state.boardID);
    },
    async deleteLevels({ dispatch, state }, levelID: string) {
      await boardsApi.deleteLevel(levelID);
      dispatch('fetchLevels', state.boardID);
    },
    subscribeFirebase({ commit }, boardId: number) {
      const unsub = firebase
        .firestore()
        .collection(getCollections().boards)
        .doc(boardId.toString())
        .onSnapshot(function(obs) {
          if (obs.exists) {
            const board = { ...obs.data(), id: boardId };
            commit('UPDATE_BOARD', board);
          }
        });
      commit('SET_UNSUB', unsub);
    },
    unsubscribeFirebase({ state, commit }) {
      state.unsubscribe();
      commit('SET_BOARD', undefined);
    },
    fetchBoards: async ({ commit }) => {
      const res = await boardsApi.index();
      commit('SET_BOARDS', res.data);
    },
    fetchBoardsNext: async ({ commit, getters }) => {
      const params = {
        page: getters.getNextPage
      };
      const { data } = await boardsApi.index({ params });
      commit('SET_BOARDS_NEXT', data);
    },
    fetchBoard: async ({ commit, dispatch }, boardId: number) => {
      try {
        const { data: board } = await boardsApi.get(boardId);

        commit('SET_BOARD', board);
        dispatch('subscribeFirebase', boardId);
        dispatch('tags/fetchTags', '', { root: true });
        if (board.type !== BoardType.WATERFALL) {
          commit('board-column/SET_COLUMN_API', boardId, { root: true });
          dispatch('board-column/fetchColumns', null, { root: true });
        }
      } catch (error) {
        if (error.response.data.code === 1021) {
          commit('SET_BOARD', { notFound: true });
        }
      }
    },
    createBoard: async ({ commit }, createBoardRequest: Board) => {
      const res = await boardsApi.create(createBoardRequest);
      commit('ADD_BOARD', res.data);
      router.push({
        name: 'Boards-Id',
        params: { id: `${res.data.id}` }
      });
    },
    editBoard: async (
      { commit, getters, dispatch },
      edittedBoard: BoardUpdate & { id: number }
    ) => {
      const { id, ...updateReq } = edittedBoard;
      const res = await boardsApi.update(id, updateReq);
      const hasArchives = !!getters.getArchives.length;

      commit('SET_BOARD', res.data);
      if (hasArchives) dispatch('fetchArchives');

      if (router.currentRoute.name == 'Teams-Board') {
        await dispatch('teams/fetchBoards', null, { root: true });
      }
    },
    deleteBoard: async ({ dispatch }, id: number) => {
      await boardsApi.delete(id);
      await dispatch('fetchBoards');
      await dispatch('fetchArchives', true);

      if (router.currentRoute.name == 'Teams-Board') {
        await dispatch('teams/fetchBoards', null, { root: true });
      }
    },
    toggleBoardStarred: async ({ dispatch, commit }, board: Board) => {
      const starredBoard: Board = {
        ...board,
        isStarred: !board.isStarred
      };
      const postStarred = () => apis.post(`/boards/${board.id}/starred`);
      const postUnStarred = () => apis.post(`/boards/${board.id}/unStarred`);
      if (starredBoard.isStarred) await postStarred();
      else await postUnStarred();
      commit('SET_BOARD', starredBoard);
      await dispatch('fetchBoards');

      if (router.currentRoute.name == 'Teams-Board') {
        await dispatch('teams/fetchBoards', null, { root: true });
      }
    },
    archiveBoard: async ({ dispatch }, id) => {
      await boardsApi.archiveBoard(id);
      await dispatch('fetchBoards');
      await dispatch('fetchArchives', true);

      if (router.currentRoute.name == 'Teams-Board') {
        await dispatch('teams/fetchBoards', null, { root: true });
      }
    },
    restoreBoard: async ({ dispatch }, id) => {
      await boardsApi.unarchivedBoard(id);
      await dispatch('fetchBoards');
      await dispatch('fetchArchives', true);
    },
    fetchArchives: async ({ commit }) => {
      let datas: Board[] = [];
      const filter = { page: 1 };
      let pageInformation = { page: 1, lastPage: 1 };

      do {
        const res = await boardsApi.archives(filter);
        pageInformation = res.data.pageInformation;
        filter.page = pageInformation.page + 1;

        datas = datas.concat(res.data.entities);
      } while (pageInformation.page < pageInformation.lastPage);

      commit('SET_ARCHIVES', datas);
    },
    copyBoard(_, copy: BoardCopyRequest) {
      return boardsApi.copyBoard(copy);
    }
  }
};

export default boardsModule;
