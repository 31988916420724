<template>
  <section>
    <div>
      <div class="flex justify-between">
        <p class="text-xl">{{ $t('modelTask.task.level') }}</p>
        <base-button
          icon="plus"
          icon-size="16"
          color="brand"
          wide="px-3 py-2 text-sm"
          @click="create"
        >
          {{ $t('boardSetting.column.create') }}
        </base-button>
      </div>
      <div class="mt-3">
        <base-input-text
          v-model="search"
          type="text"
          :placeholder="$t('boardSetting.createLevel.search')"
          icon-fa="search"
          :icon-right="search ? 'IconClose' : null"
          @icon-right-click="search = ''"
        />
      </div>
      <div v-if="!filterLevel.length" class="level-text--not-found">
        {{ $t('boardSetting.createLevel.notFound') }}
      </div>
      <div
        v-for="data in filterLevel"
        :key="`level-${data.id}`"
        class="p-3 grid grid-cols-11 hover--dark cursor-pointer column-box"
      >
        <div class="col-span-10">
          <p class="text-xs pb-1">
            {{ `${$t('boardSetting.createLevel.level')} : ${data.level}` }}
          </p>
          <div class="flex justify-between pb-1">
            <p class=" text-base font-semibold truncate pr-2">
              {{ data.name }}
            </p>
          </div>
          <p class="text-sm pr-2 text-2-line">{{ data.description }}</p>
        </div>
        <div class="flex justify-center items-center">
          <div class="status-row__edit" @click="edit(data)">
            <base-icon size="24" class="align-text-top  ">
              <icon-edit />
            </base-icon>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import IconEdit from '@/components/icons/IconEdit';
import BoardDialogConfirm from '@/components/board/board-dialog-confirm';
export default {
  components: {
    IconEdit
  },
  data: () => ({
    search: '',
    localValue: 0,
    isEditLevel: false,
    isEdit: false,
    levelID: 0
  }),
  created() {
    this.$store.dispatch('boards/fetchLevels', this.boardID);
  },
  computed: {
    ...mapGetters('boards', ['getLevels']),
    filterLevel() {
      return this.getLevels.filter(
        e => e.name.toUpperCase().indexOf(this.search.toUpperCase()) > -1
      );
    },
    boardID() {
      return parseInt(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions('boards', ['createLevels', 'editLevels', 'deleteLevels']),
    selectLevel(level) {
      this.localValue = level;
      this.isEditLevel = false;
    },
    isDisabled(level) {
      const disabled = this.getLevels.filter(e => e.level == level);

      return disabled.length > 0 && this.editLevel != level;
    },
    selectColor(level) {
      if (this.isEditLevel) {
        return this.editLevel == level ? 'brand' : 'white';
      } else {
        return level == this.localValue ? 'brand' : 'white';
      }
    },
    create() {
      this.$store.commit('boards/SET_EDIT_LEVEL', false);
      this.$store.commit('boards/SET_DATA_EDIT_LEVEL', []);
      this.$modal.show('level-setting');
      this.$emit('createLevel');
      this.levelID = 0;
      this.isEdit = false;
      this.formDescription = '';
      this.levelname = '';

      const difficult = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      const allLevels = this.getLevels.map(e => e.level);
      const activeLevel = difficult.filter(val => !allLevels.includes(val));

      this.localValue = activeLevel.length ? Math.min(...activeLevel) : 0;
    },
    edit(data) {
      this.$store.commit('boards/SET_EDIT_LEVEL', true);
      this.$store.commit('boards/SET_DATA_EDIT_LEVEL', data);
      this.$modal.show('level-setting');
      this.$emit('createLevel');
      this.isEditLevel = true;
      this.isEdit = true;
    },

    resetValue() {
      this.isCreate = false;
      this.isEditLevel = false;
      this.editLevel = 0;
      this.localValue = 0;
    }
  }
};
</script>
<style scoped lang="scss">
.level-text--not-found {
  @apply flex items-end justify-center text-gray-500 text-sm h-48;
}
.shape-btn-level {
  width: 61px;
  height: 44px;
}
::placeholder {
  color: #7a869a;
}
.column-box {
  @apply bg-white rounded border-gray-main-dark-light border mt-5;
  &:hover {
    .status-row__edit {
      @apply inline-block;
    }
  }

  .status-row__edit {
    @apply hidden;
  }
}
</style>
