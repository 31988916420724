














































































































































































import Vue from 'vue';
import SelectTeamRole from '@/components/select/select-team-role.vue';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import InfoUser from '@/components/info/info-user.vue';
import PlusIcon from '@/components/icons/IconPlus.vue';
import ModalBox from './modal-box.vue';
import { TeamMemberStatus } from '@/types/Team';

import {
  BoardInvitationRequest,
  BoardMember,
  InviteReturnType
} from '@/types/Board';
import { Team, TeamMemberRole } from '@/types/Team';
import { User } from '@/types/User';
import apis from '@/services/apis';
import { debounce } from 'lodash';
import VClamp from 'vue-clamp';

interface ThisComponent extends Vue {
  email: string;
  loading: boolean;
  fetchLoading: boolean;
  suggestion: Array<User>;
  teamID: number;
}
export default Vue.extend({
  components: {
    ModalBox,
    SelectTeamRole,
    InfoUser,
    PlusIcon,
    VClamp
  },
  data() {
    return {
      userSelect: [] as User[],
      email: '',
      loading: false,
      fetchLoading: false,
      suggestion: [] as User[],
      inputExtended: false,
      editing: false
    };
  },
  computed: {
    defaultImageSrc() {
      return `https://storage.googleapis.com/workkami-files-dev/statics/f6dd2895acbe4cf4b6d2efa5f279855f_thumb.png`;
    },
    TeamMemberStatus() {
      return TeamMemberStatus;
    },
    members(): BoardMember[] {
      return this.$store.getters['teams/getMembers'];
    },
    isNextValid(): boolean {
      return this.$store.getters['teams/isMembersNextValid'];
    },
    unselectedSuggestion(): User[] {
      const userSelectIDs = this.userSelect.map(e => e.id);
      return this.suggestion.filter(
        option => !userSelectIDs.includes(option.id)
      );
    },
    team(): Team {
      return this.$store.getters['teams/getTeam'];
    },
    teamID(): number {
      return parseInt(this.$store.getters['teams/getTeam'].id);
    },

    isAdmin(): boolean {
      const currentMember = this.members.find(u => u.isCurrentUser);
      return currentMember?.role === TeamMemberRole.Admin;
    },
    invalidInvite(): boolean {
      const addingUser = { email: this.email } as User;
      const users = [...this.userSelect];
      if (this.email) users.push(addingUser);
      return users.some(u => !this.validateEmail(u)) || users.length == 0;
    },
    isOnlyOneAdmin(): boolean {
      const isAdmin = (member: BoardMember): boolean => member.role === 100;
      return this.members.filter(isAdmin).length === 1;
    }
  },
  methods: {
    reset() {
      this.userSelect = [];
      this.inputExtended = false;
      this.clearEmail();
    },
    clearEmail() {
      this.email = '';
    },
    deselect(user: User) {
      this.userSelect = this.userSelect.filter(u => u.id !== user.id);
    },
    async addInviteEmail() {
      const email = this.email;
      const addNewUser = () => {
        const tempId = new Date().getTime();
        this.userSelect.push({
          email,
          id: tempId
        } as User);
      };
      const canAdd = !this.suggestion.length;
      if (canAdd) addNewUser();
      await this.fetchUsers();
      this.$nextTick(() => {
        this.clearEmail();
      });
    },
    async onInvite(req: BoardInvitationRequest) {
      const group = 'invite-member';
      this.loading = true;
      this.$notify({
        group,
        clean: true
      });
      try {
        await this.$store.dispatch('teams/inviteMember', req);
        this.$notify({
          text: `${this.$t('notifications.popup.invite')}`,
          type: 'success',
          group
        });
      } catch {
        //
      }
      this.loading = false;
    },
    async submitEmail() {
      const emails = this.userSelect.map(e => e.email);
      if (this.email) emails.push(this.email);
      await this.onInvite({
        id: this.teamID,
        emails,
        role: 2,
        returnType: InviteReturnType.Email
      });
      this.userSelect = [];
      this.clearEmail();
    },
    onKickMember(member: BoardMember) {
      const teamName = this.team.name || '';
      const memberName = member.user?.name || member.inviteToEmail;
      this.$modal.show(
        DialogConfirm,
        {
          title: member.isCurrentUser
            ? this.$t('team.buttonConfirm.leave.title')
            : this.$t('team.buttonConfirm.remove.title'),
          description: member.isCurrentUser
            ? `${this.$t(
                'team.buttonConfirm.leave.description1'
              )}"<strong>${teamName}</strong>"${this.$t(
                'team.buttonConfirm.leave.desctiption2'
              )}`
            : `${this.$t(
                'team.buttonConfirm.remove.description1'
              )} "<strong>${memberName}</strong>"?`,
          color: 'text-red-600',
          button: member.isCurrentUser
            ? this.$t('team.buttonConfirm.leave.name')
            : this.$t('team.buttonConfirm.remove.name'),
          confirmAction: 'teams/kickMember',
          confirmData: member
        },
        {
          height: 'auto',
          width: 434,
          scrollable: true
        }
      );
    },
    fetchUsers: debounce(async function(this: ThisComponent, email = '') {
      const params = { q: email, teamID: this.teamID, type: 4 };
      const { data } = await apis.get('/search/users', { params });
      const { entities } = data;
      this.suggestion = entities;
      this.fetchLoading = false;
    }, 500),
    resizeWidth(ev: InputEvent) {
      const target = ev.target as HTMLTextAreaElement;
      const { scrollHeight, clientHeight, clientWidth } = target;
      const inputExtended = scrollHeight > clientHeight;
      if (clientWidth < 70) this.inputExtended = true;
      if (inputExtended) this.inputExtended = inputExtended;
      if (!target.value) this.inputExtended = false;
    },
    async onSearch() {
      this.fetchLoading = true;
      this.suggestion = [];
      this.$nextTick(() => this.fetchUsers(this.email));
    },
    onSelect(user: User) {
      this.email = user.email;
    },
    onBottom() {
      !this.$store.getters['teams/membersLoading'] &&
        this.isNextValid &&
        this.$store.dispatch('teams/fetchMembers');
    },
    filterBy(option: User, label: string, search: string) {
      return label?.toLowerCase().indexOf(search.toLowerCase()) > -1;
    },
    filter(options: User[], search: string) {
      return options.filter(option => {
        const label = `${option.name} ${option.email}`;
        return this.filterBy(option, label, search);
      });
    },
    validateEmail(user: User) {
      const username = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*/;
      const domain = /(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      const reValidEmail = RegExp(`${username.source}@${domain.source}`);
      return reValidEmail.test(user.email);
    },
    handleSelectMousedown(ev: MouseEvent) {
      const findParent = (
        selector: string,
        el: HTMLElement | null
      ): HTMLElement | null =>
        el
          ? el.parentElement?.classList.contains(selector)
            ? el.parentElement
            : findParent(selector, el.parentElement)
          : null;
      const target = ev.target as HTMLElement;
      const parent = findParent('vs__deselect', target);
      if (parent) ev.stopPropagation();
    },
    disabledSelectRole(member: BoardMember): boolean {
      if (member.isCurrentUser) return false;
      return !this.isAdmin || (member.isCurrentUser && this.isOnlyOneAdmin);
    }
  }
});
