<template>
  <div class="md:grid md:grid-cols-4 gap-4">
    <div class="col-span-1">
      <chart-doughnut
        class="table-plan__doughnut"
        :chart-data="doughnutData"
        :text-center="doughnutText"
        :options="doughnutOptions"
        text-center-with-description
        font-weight-semi-bold
      />
    </div>
    <div class="col-span-3 flex items-center">
      <table class="w-full table-plan" style="z-index: -10">
        <tbody class="text-sm">
          <tr>
            {{
              $t('timelog.plan.planned')
            }}
          </tr>

          <td class="full-width">
            <bar-progress
              :color="this.backgroundColors.plan"
              :value="this.numbers.plan"
              :maximum="this.numbers.total"
            />
          </td>
          <td class="table-data--text">
            {{ this.getPercentage(this.numbers.plan) }}%
          </td>
          <tr class="table-data--text">
            {{
              formatTime(this.numbers.plan, true)
            }}
          </tr>
          <br />
          <tr>
            {{
              $t('timelog.plan.unplanned')
            }}
          </tr>

          <td class="full-width">
            <bar-progress
              :color="this.backgroundColors.unplan"
              :value="this.numbers.unplan"
              :maximum="this.numbers.total"
            />
          </td>
          <td class="table-data--text">
            {{ this.getPercentage(this.numbers.unplan) }}%
          </td>
          <tr class="table-data--text">
            {{
              formatTime(this.numbers.unplan, true)
            }}
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BarProgress, { getPercentage } from '@/components/bar-progress';
import ChartDoughnut from '@/components/chart/chart-doughnut';
import apisTimelog from '@/services/apis-timelog';
import NumberUtils from '@/mixins/NumberUtilsMixin';

const PLAN_COLOR = '#168AE2';
const UNPLAN_COLOR = '#A2AEC2';
const MINUTES_IN_HOUR = 60;

function get2Decimal(num = 0) {
  return parseFloat(num.toFixed(2));
}
function getHr(min = 0) {
  return get2Decimal(min / MINUTES_IN_HOUR);
}
export default {
  props: {
    dates: {
      type: Object,
      validator(val) {
        const isValidDate = d => d instanceof Date && !isNaN(d);
        return [val.startAt, val.endAt].every(isValidDate);
      }
    },
    totalPlan: {
      type: Number,
      default: 0
    },
    totalUnPlan: {
      type: Number,
      default: 0
    }
  },
  mixins: [NumberUtils],
  components: {
    ChartDoughnut,
    BarProgress
  },
  data() {
    return {
      plannedTimes: {
        totalPlanInMinutes: 0,
        totalUnPlanInMinutes: 0
      }
    };
  },
  computed: {
    backgroundColors() {
      return {
        plan: PLAN_COLOR,
        unplan: UNPLAN_COLOR
      };
    },
    unplanPlanHours() {
      const { totalPlanInMinutes, totalUnPlanInMinutes } = this.plannedTimes;
      const isEmpty = !(totalPlanInMinutes + totalUnPlanInMinutes);
      const unplanPlanEmpty = [1, 0];
      const unplanPlanHours = [totalUnPlanInMinutes, totalPlanInMinutes].map(
        getHr
      );
      return isEmpty ? unplanPlanEmpty : unplanPlanHours;
    },
    doughnutData() {
      const { unplan: unplanBg, plan: planBg } = this.backgroundColors;
      const backgroundColor = [unplanBg, planBg];
      const unplanPlanData = {
        data: this.unplanPlanHours,
        backgroundColor
      };
      const labels = [
        this.$t('timelog.plan.unplanned'),
        this.$t('timelog.plan.planned')
      ];
      return {
        datasets: [unplanPlanData],
        labels
      };
    },
    doughnutOptions() {
      return {
        legend: {
          display: false
        },
        tooltips: {
          displayColors: false,
          enabled: false,
          callbacks: {
            label: e =>
              e.index
                ? this.$t('timelog.plan.planned')
                : this.$t('timelog.plan.unplanned'),
            labelColor() {
              return {
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
              };
            },
            afterLabel: e => {
              const timeSpent = this.formatTime(
                this.numbers[e.index ? 'plan' : 'unplan']
              );
              const [hour, minute] = timeSpent.split(':').map(Math.round);
              const hourString = this.getNumberToStringWithUnit(
                hour,
                this.$t('timelog.time.hour')
              );
              const minuteString = this.getNumberToStringWithUnit(
                minute,
                this.$t('timelog.time.min')
              );
              return `${hourString} ${minuteString}`;
            }
          },
          custom(tooltipModel) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table class=""></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }
            function getAfterBody(bodyItem) {
              return bodyItem.after;
            }

            // Set Text
            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              const bodyLines = tooltipModel.body.map(getBody);
              const afterBodyLines = tooltipModel.body.map(getAfterBody);

              let innerHtml = '<thead>';

              titleLines.forEach(function(title) {
                innerHtml += '<tr><th>' + title + '</th></tr>';
              });
              innerHtml += '</thead><tbody>';

              bodyLines.forEach(function(body, i) {
                const colors = tooltipModel.labelColors[i];
                let style = 'background:' + colors.backgroundColor;
                style += '; border-color:' + colors.borderColor;
                const labelBox = `
                  <div
                      class="chart-doughnut__tooltip"
                      style="${style}"
                    >
                      <p class="font-medium">${body}</p>
                      <p>${afterBodyLines[i]}</p>
                  </div>
                `;
                innerHtml += '<tr><td>' + labelBox + '</td></tr>';
              });
              innerHtml += '</tbody>';

              const tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            const position = this._chart.canvas.getBoundingClientRect();
            const tooltipHeight = tooltipEl.offsetHeight;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top =
              position.top +
              window.pageYOffset +
              tooltipModel.caretY -
              tooltipHeight / 2 +
              'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding =
              tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        },
        cutoutPercentage: 65
      };
    },
    numbers() {
      const {
        totalPlanInMinutes: planMins,
        totalUnPlanInMinutes: unplanMins
      } = this.plannedTimes;
      const [plan, unplan] = [planMins, unplanMins].map(getHr);
      return {
        plan,
        unplan,
        total: plan + unplan
      };
    },
    doughnutText() {
      const totalTime = Object.keys(this.numbers)
        .filter(e => e !== 'total')
        .map(e => this.numbers[e])
        .reduce((p, c) => p + c, 0);
      const [hourString, minuteString] = this.formatTime(totalTime).split(':');
      const [hour, minute] = [hourString, minuteString].map(e => parseInt(e));
      const timeString = `${hour}:${minuteString}/${this.$tc(
        'timelog.donutChart.hour',
        hour
      )}`;
      if (hour >= 1) {
        if (minute === 0) {
          return `${hour}/${this.$tc('timelog.donutChart.hour', hour)}`;
        }
        return timeString;
      }
      return this.$tc('timelog.donutChart.minute', minute);
    }
  },
  watch: {
    dates: {
      handler: 'fetchData',
      immediate: true
    },
    totalPlan(val) {
      this.plannedTimes.totalPlanInMinutes = val;
    },
    totalUnPlan(val) {
      this.plannedTimes.totalUnPlanInMinutes = val;
    }
  },
  methods: {
    async fetchData(dates) {
      const { data } = await apisTimelog.getPlannedTimes({
        beginOfStartDate: dates.startAt,
        endOfEndDate: dates.endAt
      });
      this.plannedTimes = data;
    },
    formatTime(hours = 0, isUnit) {
      const [hrString, decimalHrString] = hours
        .toFixed(2)
        .toString()
        .split('.');
      const decimalHour = parseInt(decimalHrString) / 100;
      const minute = Math.round(decimalHour * MINUTES_IN_HOUR);
      let minuteDigit = minute.toString().padStart(2, '0');
      if (isUnit) {
        const hr = this.$tc('hr', hrString);
        if (hrString == 0) {
          minuteDigit = minute;
        }
        const minuteDisplay = this.$tc('min', minuteDigit);
        if (hrString == 0 && minute == 0) {
          return `0 ${this.$t('hour')} 0 ${this.$t('minute')}`;
        } else {
          return [hr, minuteDisplay].join(' ');
        }
      } else {
        return [hrString, minuteDigit].join(':');
      }
    },

    getPercentage(number) {
      if (!this.numbers.total) return 0;
      return getPercentage(number, this.numbers.total);
    }
  }
};
</script>
<style>
.table-plan {
  border-collapse: separate;
}
.table-plan td {
  @apply text-right whitespace-no-wrap;
}
.table-plan td.full-width {
  width: 90%;
}
.table-data--text {
  @apply text-gray-500;
}
.table-plan__doughnut {
  @apply mx-auto;
  max-width: 160px;
  max-height: 160px;
}
</style>
<i18n>
{
  "en": {
    "min": " | {count} min | {count} mins",
    "hr": " | {count} hr | {count} hrs",
    "minute": "min",
    "hour": "hr"
  },
  "th": {
    "min": " | {count} นาที | {count} นาที",
    "hr": " | {count} ชม. | {count} ชม.",
    "minute": "นาที",
    "hour": "ชม."
  }
}
</i18n>
