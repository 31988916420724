




































































































































































































































































































































































import Vue from 'vue';
import { createNamespacedHelpers, mapGetters } from 'vuex';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { directive as onClickaway } from 'vue-clickaway';

import { Board, BoardMember, BoardType } from '@/types/Board';
import { User } from '@/types/User';
import { Task, TaskAssign, TaskFile, TaskPrivacy } from '@/types/Task';
import { Checklist } from '@/types/Checklist';
import { Avatar } from '../base/base-avatar.vue';

import TaskCommentActivityWrapper from '@/components/comments/task-comment-activity-wrapper.vue';
import SelectTaskAssign, {
  EmitSelectTaskAssign
} from '@/components/select/select-task-assign.vue';
import IconHeaderAdd from '@/components/icon-header-add.vue';
import SelectTaskTags from '@/components/select/select-task-tags.vue';
import TodoListTaskChecklist from '@/components/todo-list-task-checklist.vue';
import InputEditor from '@/components/input-editor-html.vue';
import IconPlus from '@/components/icons/IconPlus.vue';

import ModalTaskLink from './modal-task-link.vue';
import ModalTaskHeader from './modal-task-header.vue';
import ModalTaskSider, {
  AddToCard,
  AddToCardDates
} from './modal-task-sider.vue';
import ModalTaskAttachments from './modal-task-attachments.vue';
import ModalShareLink from './modal-share-link.vue';
import ModalTaskCopy from './modal-task-copy.vue';
import HumanDate from '@/components/date/human-date.vue';
import IconClose from '@/components/icons/IconClose.vue';
import SelectTaskLevel from '@/components/select/select-task-level.vue';
import TextToLinkMixin from '@/mixins/TextToLink';
import {
  OrderedList,
  BulletList,
  ListItem,
  Image,
  Bold,
  Italic,
  Strike,
  Heading,
  History
} from 'tiptap-extensions';

interface TaskUpdate {
  description?: string;
  endAt?: Date | string;
  name?: string;
  newColumnID?: number;
  position?: number;
  startAt?: Date | string;
  status?: number;
  storyPoint?: number;
  levelID?: number;
}

interface ModalTask {
  updateTask: (t: TaskUpdate) => void;
  saveStoryPoint: () => void;
  focusedInput: FocusedInput;
  isOpenStoryPoint: boolean;
  task: Task;
  storyPoints: string;
  showIconState: boolean;
  IconCloseLevel: boolean;
  taskStoryPoint: string;
}

interface ModalEvent {
  cancel(): void;
}

enum FocusedInput {
  None = '',
  Description = 'description',
  Story = 'story_points'
}

const isTask = (task: Task | undefined): task is Task => task !== undefined;

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('tasks');

export default Vue.extend({
  inheritAttrs: false,
  name: 'modal-task',
  mixins: [TextToLinkMixin],
  props: {
    disabled: Boolean,
    commentDisabled: Boolean,
    shareDisabled: Boolean,
    modalName: {
      type: String,
      default: 'task-detail'
    },
    format: {
      type: Number,
      default: 1
    },
    shareLinkId: Number
  },

  components: {
    ModalTaskLink,
    ModalTaskHeader,
    ModalTaskSider,
    ModalTaskCopy,
    ModalShareLink,
    TaskCommentActivityWrapper,
    SelectTaskAssign,
    ModalTaskAttachments,
    IconHeaderAdd,
    SelectTaskTags,
    TodoListTaskChecklist,
    InputEditor,
    IconPlus,
    IconClose,
    HumanDate,
    SelectTaskLevel
  },
  data() {
    return {
      IconCloseLevel: false,
      showIconState: false,
      taskName: '',
      taskDescription: '',
      taskDescriptionOrg: '',
      taskStartAt: '',
      taskEndAt: '',
      descriptionInactive: true,
      copying: false,
      comments: [],
      storyPoints: '-',
      focusedInput: '' as FocusedInput,
      isOpenStoryPoint: false,
      countModalShareCloses: new Date().getTime(),
      isDragOverFile: false,
      taskIsCompleteSprint: false
    };
  },
  watch: {
    task: {
      handler(t?: Task, prev?: Task) {
        if (isTask(t)) {
          this.taskUpdated(t);
          if (t.id !== prev?.id) {
            this.unsubscribeTask();
            this.subscribeTask({
              taskId: t.id,
              boardId: t.boardID,
              shareLinkId: this.shareLinkId
            });
          }
        }
      },
      immediate: true
    },
    copying: {
      handler(e) {
        if (e) this.$modal.show('task-copy');
        else this.$modal.hide('task-copy');
      },
      immediate: true
    }
  },
  directives: {
    onClickaway
  },
  computed: {
    ...mapState(['currentTask']),
    ...mapGetters({
      sprints: 'sprint/getSprints',
      isUser: 'auth/isUser',
      loading: 'tasks/isTaskLoading',
      isUserViewer: 'boards/isUserViewer',
      getActiveBoard: 'boards/getActiveBoard'
    }),
    isDisable(): boolean {
      return this.disabled || this.taskIsCompleteSprint;
    },
    sprintID(): number {
      return this.task?.sprintID || 0;
    },
    isViewOnly(): boolean {
      return (
        this.isUserViewer ||
        this.getActiveBoard.viewOnly ||
        this.taskIsCompleteSprint
      );
    },
    date(): string {
      return this.sameDay
        ? this.formatDate(this.taskEndAt)
        : `${this.formatDate(this.taskStartAt)} -
            ${this.formatDate(this.taskEndAt)}`;
    },
    sameDay(): boolean {
      return (
        this.formatDate(this.taskStartAt) == this.formatDate(this.taskEndAt)
      );
    },
    selectID(): number | undefined {
      return this.task?.levelID;
    },
    getLevel(): object | undefined {
      return this.task?.level;
    },
    taskStoryPoint(): string {
      return this.task?.storyPoint?.toString() || '';
    },
    clickToClose(): boolean {
      return !this.focusedInput;
    },
    task(): Task | undefined {
      return this.currentTask;
    },
    board(): Board | undefined {
      return this.task?.board;
    },
    isAgile(): boolean {
      return this.board?.type === BoardType.AGILE;
    },
    members(): BoardMember[] {
      return this.$store.getters['members/getMembers'];
    },
    creator(): User | undefined {
      return this.task?.creator;
    },
    formatCreatedAt(): string {
      const _date = new Date(this.task?.createdAt || new Date());
      const [month, date, year] = _date
        .toDateString()
        .split(' ')
        .slice(1);
      const [hour, minute] = _date
        .toTimeString()
        .split(' ')[0]
        .split(':');

      return `${date} ${month} ${year}, ${hour}:${minute}`;
    },
    taskAssigns(): TaskAssign[] {
      return this.task?.assigns || [];
    },
    assignedUsers(): Avatar[] {
      const getUser = (a: TaskAssign) => {
        return a.user;
      };
      return this.taskAssigns.map(getUser);
    },
    displayAssigns(): boolean {
      return this.taskAssigns?.length > 0;
    },
    displayTags(): boolean {
      return (this.task?.tags?.length ?? 0) > 0;
    },
    displayDueDate(): boolean {
      return !!this.task?.endAt;
    },
    isDue(): boolean {
      const endAt = this.task?.endAt;
      return moment()
        .locale(`${this.$t('notifications.language')}`)
        .isSameOrAfter(endAt);
    },
    checklists(): Checklist[] {
      return this.task?.checklists || [];
    },
    files(): TaskFile[] {
      return this.task?.files || [];
    },
    editDisabled(): boolean {
      return this.taskDescription == this.taskDescriptionOrg;
    },
    storyPointFocused(): boolean {
      return this.focusedInput === FocusedInput.Story;
    },
    isFocusDescription(): boolean {
      return this.focusedInput === FocusedInput.Description;
    },
    dueColor(): string {
      const colors = [
        'badge-danger',
        'badge-warning',
        'badge-gray',
        'badge-success'
      ];
      const dueDate = moment(this.taskEndAt).locale(
        `${this.$t('notifications.language')}`
      );
      const daysLeft = dueDate.diff(
        moment()
          .locale(`${this.$t('notifications.language')}`)
          .startOf('day'),
        'days'
      );
      const colorIndex =
        this.task?.status == 2 ? 3 : daysLeft > 1 ? 2 : daysLeft <= 0 ? 0 : 1;
      return colors[colorIndex];
    },
    inputExtensions() {
      // TODO: add Link when tiptap is v2
      return [
        new OrderedList(),
        new BulletList(),
        new ListItem(),
        new Image(),
        new Bold(),
        new Italic(),
        new Strike(),
        new Heading(),
        new History()
      ];
    },
    isChecklists(): boolean {
      return this.checklists?.length > 0;
    },
    isAssignedUsers(): boolean {
      return !!this.assignedUsers?.length;
    }
  },
  methods: {
    ...mapMutations({
      updateCurrentTask: 'UPDATE_CURRENT_TASK',
      setIsTaskOpen: 'SET_IS_TASK_OPEN'
    }),
    ...mapActions([
      'setTask',
      'addTaskAssign',
      'removeTaskAssign',
      'readTask',
      'subscribeTask',
      'unsubscribeTask',
      'addTaskTesterAssign',
      'removeTaskTesterAssign'
    ]),
    formatDate(date: string) {
      return moment(date)
        .locale(`${this.$t('notifications.language')}`)
        .format('DD MMM YYYY');
    },
    taskUpdated(task: Task) {
      if (!task) return;
      this.taskName = task.name;
      this.taskEndAt = task.endAt;
      this.taskStartAt = task.startAt;
      if (!this.isFocusDescription) {
        const { description = '' } = task;
        const parsedDescription = (this as any).parseTextToLink(description);
        this.taskDescription = parsedDescription;
        this.taskDescriptionOrg = parsedDescription;
      }
      if (!this.storyPointFocused) {
        this.storyPoints = this.taskStoryPoint || '-';
      }
    },
    updateTask(updates: TaskUpdate) {
      this.setTask({
        id: (this.task as Task).id,
        ...updates
      });
    },
    getUser(id?: number) {
      return this.members.find(m => m.user.id === id)?.user;
    },
    submitInputText(area: keyof Task) {
      const taskValue = this.task?.[area];
      const localValue = area === 'name' ? this.taskName : this.taskDescription;
      const valueChanged = localValue !== taskValue;
      if (valueChanged) {
        this.updateTask({
          [area]: localValue.trim()
        });
      }
    },
    addToCard(ev: AddToCard) {
      const { index } = ev;
      switch (index) {
        case 0: {
          ev.isTester
            ? this.assignTaskTester(ev.data as EmitSelectTaskAssign)
            : this.assignTask(ev.data as EmitSelectTaskAssign);
          break;
        }
        case 2: {
          this.updateTask(ev.data as AddToCardDates);
          break;
        }
        case 4: {
          const Attachments = this.$refs
            .taskAttachments as ModalTaskAttachments;
          Attachments.toggle();
          break;
        }
        default:
          break;
      }
    },
    assignTask(data: EmitSelectTaskAssign) {
      data.isAssigned
        ? this.removeTaskAssign(data.userID)
        : this.addTaskAssign(data.userID);
    },
    assignTaskTester(data: EmitSelectTaskAssign) {
      console.log(data);
      data.isAssigned
        ? this.removeTaskTesterAssign(data.userID)
        : this.addTaskTesterAssign(data.userID);
    },
    async onModalOpened() {
      const self = this;
      if (this.isUser) {
        if (this.task) this.readTask(this.task.id);
      }
      const modalElements: HTMLElement = document.getElementsByClassName(
        'vm--overlay'
      )[0] as HTMLElement;
      function onClick() {
        if (self.isFocusDescription) {
          self.saveDescriptionClick();
          self.resetFocusedInput();
          modalElements.removeEventListener('click', onClick);
        }
      }
      modalElements.addEventListener('click', onClick);

      const isAgileBoard = this.task?.board.type == BoardType.AGILE;
      if (isAgileBoard) {
        const sprintListID = this.sprints.map((s: { id: number }) => s.id);
        const sprintID = this.task?.sprintID || 0;
        this.taskIsCompleteSprint = !sprintListID.includes(sprintID);
      }

      this.setIsTaskOpen(true);
    },
    beforeClose(ev: ModalEvent) {
      this.cancelDescriptionClick();
      this.$emit('before-close', ev);
      this.unsubscribeTask();
    },
    descriptionClick({ target }: { target: HTMLElement }) {
      const isAnchor = target.tagName.toLowerCase() === 'a';
      const isFocusIgnored = this.disabled || this.isViewOnly || isAnchor;
      if (isFocusIgnored) return;
      this.focusDescription();
    },
    focusDescription() {
      this.setFocuseInput(FocusedInput.Description);
    },
    blurDescription() {
      const prevDescription = this.task?.description || '';
      const shouldSave =
        this.isFocusDescription && this.taskDescription !== prevDescription;
      if (shouldSave) this.saveDescriptionClick();
      this.resetFocusedInput();
    },
    cancelDescriptionClick() {
      this.resetFocusedInput();
      if (this.isFocusDescription) {
        this.updateTask({
          description: this.taskDescriptionOrg
        });
      }
    },
    saveDescriptionClick() {
      this.updateTask({
        description: this.taskDescription
      });
    },
    saveStoryPoint: debounce(function(this: ModalTask) {
      let storyPoint = 0;
      if (this.storyPoints !== this.taskStoryPoint) {
        if (this.storyPoints) {
          storyPoint = parseFloat(this.storyPoints);
        } else {
          this.storyPoints = '0';
        }
      } else {
        storyPoint = parseFloat(this.taskStoryPoint);
      }

      this.updateTask({ storyPoint });
    }, 200),
    onFocusStoryPoint() {
      if (!this.taskStoryPoint) {
        this.storyPoints = '';
      }
      this.setFocuseInput(FocusedInput.Story);
      this.isOpenStoryPoint = true;
    },
    onBlurStoryPoint() {
      if (!this.storyPoints && !this.taskStoryPoint) this.storyPoints = '-';
      else this.saveStoryPoint();
      this.isOpenStoryPoint = false;
      this.resetFocusedInput();
    },
    setFocuseInput(e: FocusedInput) {
      this.focusedInput = e;
    },
    resetFocusedInput: debounce(function(this: ModalTask) {
      this.focusedInput = FocusedInput.None;
    }, 200),
    onPrivacyChanged(privacy: TaskPrivacy) {
      this.updateCurrentTask({ privacy });
    },
    closeStoryPoint() {
      if (!this.taskStoryPoint) this.storyPoints = '-';
      else this.storyPoints = this.taskStoryPoint;
    },
    showIcon(state: boolean) {
      this.showIconState = state;
    },
    showIconCloseLevel(state: boolean) {
      this.IconCloseLevel = state;
    },
    clearDueDate() {
      const clearDate = { endAt: '', startAt: '' };
      this.updateTask(clearDate);
      this.resetShowIconState();
    },
    resetShowIconState: debounce(function(this: ModalTask) {
      this.showIconState = false;
      this.IconCloseLevel = false;
    }, 500),
    setLevel(id: number) {
      this.updateTask({ levelID: id });
    },
    clearLevel() {
      this.updateTask({ levelID: 0 });
      this.resetShowIconState();
    }
  }
});
