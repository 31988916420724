






































import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import TablePagination from './table-time-log-pagination.vue';
import TimeLogRow from './table-time-log-row.vue';
import FormTimelog from '@/components/forms/form-timelog.vue';

const { mapActions, mapGetters } = createNamespacedHelpers('time-log');

interface This {
  fetchTimelogsByDay(date: Date): Promise<void>;
}

export default Vue.extend({
  components: {
    TablePagination,
    TimeLogRow,
    FormTimelog
  },
  props: {
    logs: {
      type: Array,
      default: () => []
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    fetchTimelog: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentDate: new Date()
    };
  },
  computed: {
    ...mapGetters(['getTimelogs', 'deleteTimelog']),
    isEmpty() {
      return !this.getTimelogs.length;
    },

    timelogEditModal() {
      return {
        name: 'timelog-edit',
        height: 'auto',
        adaptive: true,
        width: '100%',
        maxWidth: 660,
        scrollable: true
      };
    }
  },
  watch: {
    currentDate: {
      handler(val: Date) {
        if (this.fetchTimelog) {
          this.fetchTimelogsByDay(val);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['fetchTimelogsByDay'])
  }
});
