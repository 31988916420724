import { AxiosPromise } from 'axios';
import apis from './apis';
import {
  DashboardBoardsTasks,
  DashboardInsight,
  DashboardPlanAndUnPlanByBoard,
  DashboardPlanUnplan,
  DashboardResources,
  DashboardSummary,
  DashboardTaskcount
} from '@/types/Dashboard';

interface DashboardFn<T> {
  (): AxiosPromise<T>;
}

interface DashboardBoard {
  summary: DashboardFn<DashboardSummary>;
  boardInsight: DashboardFn<DashboardInsight>;
  userResources: DashboardFn<DashboardResources[]>;
  taskCount: DashboardFn<DashboardTaskcount>;
}

interface DashboardUser {
  totalPlanAndUnPlan: DashboardFn<DashboardPlanUnplan>;
  totalBoardsAndTasks: DashboardFn<DashboardBoardsTasks>;
  planAndUnPlanByBoard: DashboardFn<DashboardPlanAndUnPlanByBoard>;
}

interface DashboardApiServices {
  board(id: number): DashboardBoard;
  user(id: number): DashboardUser;
}

const dashboardBoardApis = [
  'summary',
  'boardInsight',
  'userResources',
  'taskCount'
];
const dashboardUserApis = [
  'totalPlanAndUnPlan',
  'totalBoardsAndTasks',
  'planAndUnPlanByBoard'
];

const createDashboardApi = <T>(type: string, apiKeys: string[]) => (
  id: number
) => {
  const boardCaller = (pathSuffix: string) => () =>
    apis.get(`/dashboards/${type}/${id}/${pathSuffix}`);
  return apiKeys.reduce(
    (p, callerKey) => ({ ...p, [callerKey]: boardCaller(callerKey) }),
    {} as T
  );
};

const apisDashboards: DashboardApiServices = {
  board: createDashboardApi('board', dashboardBoardApis),
  user: createDashboardApi('user', dashboardUserApis)
};
export default apisDashboards;
