<template>
  <div class="kanban" style="height: calc(100% - 4rem);" @scroll="onScroll">
    <div class="flex-1 flex items-start px-8 min-w-full">
      <v-draggable
        v-model="columns"
        v-bind="draggableConfig"
        class="h-full flex space-x-5"
        group="dragging-columns"
        filter=".draggable-column--ignored"
        :scroll-sensitivity="296"
        :scroll-speed="30"
        :disabled="disabled || !draggable || viewOnly"
        :prevent-on-filter="false"
        @start="dragstart"
        @end="dragend"
        @change="onDragUpdate"
      >
        <board-column-item
          v-for="(column, i) in columns"
          :key="column.id"
          class="status-column--draggable"
          :class="{ placeholder: i === draggingIndex }"
          :column="column"
          :dragging="dragging"
          @toggle-add="disabled = $event"
          @name="setColumnName({ name: $event, id: column.id })"
          @archive="archiveColumn(column.id)"
          @remove="removeColumn(column.id)"
          @click.native="disabled = false"
        >
          <template #header="header">
            <slot name="header" v-bind="header" />
          </template>
        </board-column-item>
      </v-draggable>

      <board-column-item
        v-if="!isAgile && isAdding"
        ref="addColumnPlaceholder"
        class="mx-5 px-1 mt-1"
        @name="addNewColumn"
        @name-cancel="isAdding = false"
      />
      <div v-if="isAgile && isAdding" class="mx-5">
        <div class="create--agile mr-8">
          <form-agile-status
            v-model="form"
            class="column-box"
            is-create-column
            @confirm="addNewColumn"
            @cancel="isAdding = false"
          />
        </div>
      </div>

      <div
        v-if="!isUserViewer && allowAdding && !isAdding && !viewOnly"
        class="board-column pl-5 pr-8 mt-1"
        style="width: 340px"
      >
        <base-button
          v-if="!isAdding"
          :loading="loading"
          color="bg-gray-main"
          class="text-black shadow-none rounded-md"
          style="height: 58px;"
          full
          @click="toggleNewColumn"
        >
          + {{ $t('myBoard.add') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import VDraggable from 'vuedraggable';
import { createNamespacedHelpers } from 'vuex';
import { debounce } from 'lodash';
import Vuedraggable from '@/mixins/Vuedraggable';
import EventBus from '@/services/eventBus';
import BoardColumnItem from './board-column-item';
import FormAgileStatus from '../forms/form-agile-status.vue';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'board-column'
);

export default {
  mixins: [Vuedraggable],
  components: {
    BoardColumnItem,
    FormAgileStatus,
    VDraggable
  },
  props: {
    draggable: {
      type: Boolean,
      default: true
    },
    allowAdding: {
      type: Boolean,
      default: true
    },
    isAgile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      dragging: false,
      isAdding: false,
      disabled: false,
      draggingIndex: -1,
      form: {
        name: '',
        status: 0
      }
    };
  },
  provide() {
    const column = {};
    Object.defineProperty(column, 'dragging', {
      enumerable: true,
      get: () => this.dragging
    });
    return {
      column
    };
  },
  computed: {
    ...mapGetters(['getColumns', 'getNewColumnPosition']),
    viewOnly() {
      return this.$store.getters['boards/isViewOnly'];
    },
    columns: {
      get() {
        return this.getColumns;
      },
      set(val) {
        this.setColumns(val);
      }
    },
    isUserViewer() {
      return this.$store.getters['boards/isUserViewer'];
    }
  },
  methods: {
    ...mapMutations({
      setColumns: 'SET_COLUMNS'
    }),
    ...mapActions(['addColumn', 'setColumn', 'removeColumn', 'archiveColumn']),
    async addNewColumn($event) {
      if (!this.loading) {
        this.isAdding = false;
        this.loading = true;
        const lastItemPosition = this.columns[this.columns.length - 1]
          ?.position;

        if (this.isAgile) {
          await this.addColumn({
            ...this.form,
            position: this.positionCalc(lastItemPosition)
          });
          this.resetForm();
        } else {
          const name = $event;
          await this.addColumn({
            name,
            position: this.positionCalc(lastItemPosition)
          });
        }
        this.loading = false;
      }
    },

    toggleNewColumn() {
      if (!this.isAdding) this.isAdding = true;
      if (!this.isAgile)
        this.$nextTick(() => {
          this.$refs.addColumnPlaceholder.editName();
        });
    },
    setColumnName({ name, id }) {
      this.setColumn({ name, id });
    },
    onDragUpdate(evt) {
      if (!evt.moved) return;
      const positionItem = this.getDragPosition(this.columns, evt);
      this.setColumn(positionItem);
    },
    dragstart(e) {
      this.dragging = true;
      this.draggingIndex = e.oldIndex;
    },
    dragend() {
      this.dragging = false;
      this.draggingIndex = -1;
    },
    onScroll: debounce(
      function(e) {
        EventBus.$emit('kanban-scroll', e);
      },
      200,
      {
        leading: true,
        trailing: false
      }
    ),
    resetForm() {
      this.form = {
        name: '',
        status: 0
      };
    }
  }
};
</script>

<style lang="scss">
.kanban {
  @apply flex w-full overflow-y-hidden overflow-x-auto;
}
.status-column--draggable {
  @apply border-4 border-transparent border-dashed rounded-lg;
  opacity: 1 !important;

  &.placeholder {
    @apply border-brand-blue;

    .board-column__bg {
      @apply bg-gray-main-dark;
    }
  }
}
.create--agile {
  @apply flex-shrink-0 rounded-md h-auto mt-1 py-3 px-2 bg-gray-main;
  width: 288px;
}
</style>
