<template>
  <div class="bg-deeplink relative justify-center">
    <div>
      <base-icon size="64" class="mb-3"> <icon-workkami /> </base-icon>
      <p class="header-banner ">Workkami</p>
      <p class="description-banner">
        Make project management easier than ever. Manage everything here.
      </p>
      <div class="grid">
        <base-button class="mx-6 mr-6 mb-8" @click="opendMobilApp"
          >Open in the App</base-button
        >
        <base-button color="text" class="text-brand-blue" @click="opendBrowser">
          Working on Website
        </base-button>
        {{ schema }}
      </div>
    </div>
  </div>
</template>
<script>
import IconWorkkami from '@/components/icons/IconWorkkamiBgBlue.vue';
export default {
  components: {
    IconWorkkami
  },
  data() {
    return {
      url: '',
      schema: ''
    };
  },
  mounted() {
    if (window.location.href.includes('?to')) {
      this.url = window.location.href.replaceAll('%2F', '/');
    } else {
      this.url = window.location.href;
    }
  },
  computed: {
    isProd() {
      const isProd = location.hostname === 'www.workkami.com';
      return isProd;
    },
    taskId() {
      const taskPath = '/t/';
      const task = this.url.search(taskPath);
      return task == -1 ? false : this.url.slice(task + taskPath.length);
    },
    boardId() {
      const board = this.url.search('boards/');
      const kanban = this.url.search('/normal/');
      const sprint = this.url.search('/agile/');
      if (kanban != -1) {
        return this.url.slice(board, kanban);
      } else {
        return this.url.slice(board, sprint);
      }
    }
  },
  methods: {
    opendMobilApp() {
      const url = this.isProd ? 'workkami' : 'workkamidev';

      const path = this.taskId ? `tasks/${this.taskId}` : this.boardId;

      if (!this.taskId && !this.boardId) {
        this.schema = `${url}://boards`;
      } else {
        this.schema = `${url}://${path}`;
      }
      window.location.replace(this.schema);
      setTimeout(function() {
        window.location = 'https://link.workkami.com/install';
      }, 100);
    },
    opendBrowser() {
      localStorage.setItem('smart_banner', 'hide');
      const body = document.body;
      body.classList.remove('boby-scroll');
      this.$emit('hidebanner');
    }
  }
};
</script>

<style scoped>
.header-banner {
  @apply text-brand-blue font-medium mb-4;
  font-size: 28px;
}
.description-banner {
  @apply text-gray-caption mb-8;
}
.bg-deeplink {
  @apply text-center flex;
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(15px);
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  z-index: 1000;
  align-items: center;
}
</style>
