<template>
  <div class="base-input--control" :class="{ 'base-input--focused': focused }">
    <input
      ref="textInput"
      v-bind="$attrs"
      class="base-input placeholder-gray-600"
      :disabled="disabled"
      :type="type"
      maxlength="6"
      onkeypress="return /[a-z]/i.test(event.key)"
      v-on="inputListeners"
      @focus="focused = true"
      @blur="focused = false"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    autofocus: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    inputListeners() {
      const self = this;
      return Object.assign({}, this.$listeners, {
        input: function(event) {
          const upppercase = event.target.value.toUpperCase();
          self.$emit('update', upppercase);
        }
      });
    }
  },
  mounted() {
    if (this.autofocus) {
      this.focus();
    }
  },
  methods: {
    blur() {
      this.textInput.blur();
    },
    focus() {
      this.textInput.focus();
    }
  }
};
</script>
