
















import Vue from 'vue';
import VClamp from 'vue-clamp';
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { BoardColumn, BoardColumnAdd, Board, BoardType } from '@/types/Board';

export default Vue.extend({
  inheritAttrs: false,
  name: 'modal-task-link-priority',
  props: {
    boardColumnID: Number,
    column: Object
  },

  components: { VClamp },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters({
      isUser: 'auth/isUser',
      loading: 'tasks/isTaskLoading',
      isUserViewer: 'boards/isUserViewer',
      columns: 'board-column/getColumns',
      board: 'boards/getActiveBoard'
    }),
    isKanbanBoard() {
      return this.board.type === BoardType.KANBAN;
    }
  },
  methods: {}
});
