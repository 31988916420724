<template>
  <div>
    <div class="title">
      {{ $t('modelTask.task.level') }}
    </div>
    <base-dropdown-item
      v-for="option in getLevels"
      :key="option.id"
      class="flex dropdown-box "
      :class="isActiveClass(option.id)"
      @click="input(option.id)"
    >
      <div class="mr-3 ">
        <base-icon v-if="isActive(option.id)" size="15" view-box="0 0 15 15">
          <icon-radio-active />
        </base-icon>
        <base-icon v-else size="15" view-box="0 0 15 15">
          <icon-radio />
        </base-icon>
      </div>
      <div class="w-full pr-8">
        <p class=" pb-1 font-medium truncate">{{ option.name }}</p>
        <p class="font-normal text-xs text-gray-caption text-2-line">
          {{ option.description }}
        </p>
      </div>
    </base-dropdown-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import IconRadioActive from '@/components/icons/IconRadioActive.vue';
import IconRadio from '@/components/icons/IconRadio.vue';
export default {
  components: {
    IconRadioActive,
    IconRadio
  },
  props: {
    value: {
      type: Number
    },
    disabled: Boolean
  },

  computed: {
    ...mapGetters('boards', ['getLevels']),
    selectLevel() {
      return this.getLevels.find(o => o.id === this.value);
    }
  },
  methods: {
    input(id) {
      this.$emit('input', id);
    },
    isActive(id) {
      return this.value === id;
    },
    isActiveClass(id) {
      return this.isActive(id) ? 'priority-active' : '';
    }
  }
};
</script>

<style scoped>
.priority-active {
  @apply border-brand-blue;
}
.dropdown-box {
  @apply border-2 rounded;
  margin: 0.75rem !important;
}
.title {
  @apply text-base font-medium ml-3 my-1;
}
</style>
