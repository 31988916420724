<template>
  <div>
    <base-button
      v-if="!isForceSubscription"
      data-testid="close-btn"
      class="absolute top-0 right-0 mt-3 "
      icon="close"
      icon-size="18"
      color="text"
      @click="closeModal"
    />
    <div class="p-12 text-center flex flex-col row-gap-5">
      <div class="flex self-center">
        <span class="text-2xl">{{ textOnModal.header }}</span>
      </div>

      <div
        class="text-gray-caption"
        style="margin-left: 240px; margin-right: 240px"
      >
        {{ textOnModal.description }}
      </div>
      <div v-if="textOnModal.key === 'upgrade'" class="flex flex-col text-left">
        <span>{{ $t('subscription.upgradeWorkspace.member') }}</span>
        <base-select
          style="width:200px"
          full-width
          max-height="200"
          :options="optionSeat"
          :placeholder="seatNum.label"
          placeholder-classes="text-black"
          @change="onDurationChanged"
        />
      </div>
      <div class="flex space-x-12 self-center">
        <subscription-select-plan
          v-if="planId != 1 && activity == 'change'"
          class="flex-1"
          :data="cuurentPlan[0]"
          :activity="activity"
          @createWorkspace="toggleWorkspace"
          @buyPlan="togglePayment"
        />
        <div v-for="plan in dataPlanFliter" :key="plan.index" class="mt-auto">
          <subscription-select-plan
            class="flex-1"
            :data="plan"
            :activity="activity"
            @createWorkspace="toggleWorkspace"
            @buyPlan="togglePayment"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subscriptionSelectPlan from '@/components/subscription/subscriptionSelectPlan.vue';
import SelectOptionSeat from '@/mixins/OptionSeat';
import { mapGetters } from 'vuex';

export default {
  mixins: [SelectOptionSeat],
  components: {
    subscriptionSelectPlan
  },
  props: {
    activity: String
  },
  data() {
    return {
      seatNum: { label: '5 seat', value: 5 },
      dataPlan: [
        {
          key: 1,
          planName: this.$t('subscription.upgradeWorkspace.plan.freeHeader'),
          planDescription: this.$t(
            'subscription.upgradeWorkspace.plan.freeDescription'
          ),
          price: 0,
          priceDescription: 'freeDescription',
          planId: 1
        },
        {
          key: 2,
          planName: this.$t('subscription.upgradeWorkspace.plan.monthlyHeader'),
          planDescription: this.$t(
            'subscription.upgradeWorkspace.plan.monthlyDescription'
          ),
          price: 0,
          priceDescription: 'priceDescriptionMonthy',
          planId: 2
        },
        {
          key: 3,
          planName: this.$t(
            'subscription.upgradeWorkspace.plan.sixMonthHeader'
          ),
          planDescription: this.$t(
            'subscription.upgradeWorkspace.plan.sixMonthDescription'
          ),
          price: 0,
          priceDescription: 'priceDescriptionSix',
          planId: 3
        },

        {
          key: 4,
          planName: this.$t('subscription.upgradeWorkspace.plan.annualHeader'),
          planDescription: this.$t(
            'subscription.upgradeWorkspace.plan.annualDescription'
          ),
          price: 0,
          priceDescription: 'priceDescriptionAnnual',
          planId: 4
        }
      ],
      text: [
        {
          key: 'upgrade',
          header: this.$t('subscription.upgradeWorkspace.header'),
          description: this.$t('subscription.upgradeWorkspace.description')
        },
        {
          key: 'change',
          header: this.$t('subscription.change_plan.title'),
          description: this.$t('subscription.change_plan.description')
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch('subscription/planInfo');
    this.$store.commit('subscription/SET_CHANGE_PLAN', []);
    this.seatNum = this.optionSeat[0];
  },
  watch: {
    getPlanInfo(val) {
      this.dataPlan.map(plan => {
        plan.price = val.entities.filter(
          planA => planA.position === plan.planId
        )[0]?.pricePerSeat;

        const numberOfDays = val.entities.filter(
          planA => planA.position === plan.planId
        )[0].numberOfDays;
        const totalPrice = Math.floor(numberOfDays / 30) * plan.price;

        const savePrice = val.entities.filter(
          planA => planA.position === plan.planId
        )[0];
        plan.priceDescription = this.$t(
          `subscription.upgradeWorkspace.plan.${plan.priceDescription}`,
          {
            savePrice: savePrice.savePricePerSeat,
            totalPrice: totalPrice
          }
        );
      });
    }
  },
  computed: {
    ...mapGetters('subscription', ['getPlanInfo', 'getCurrentPlan']),
    textOnModal() {
      const text = this.text.find(t => t.key === this.activity);
      return text;
    },
    isChangePlan() {
      return this.activity == 'change';
    },
    planId() {
      return this.getCurrentPlan.payment
        ? this.getCurrentPlan.payment.subscriptionOrder.planID
        : 0;
    },
    cuurentPlan() {
      return this.dataPlan.filter(p => p.planId == this.planId);
    },
    dataPlanFliter() {
      const withoutFreePlan = this.dataPlan.filter(plan => plan.planId != 1);

      if (this.isChangePlan) {
        if (this.planId != 1) {
          const withoutPlanCurrent = withoutFreePlan.filter(
            p => p.planId != this.planId
          );
          return withoutPlanCurrent;
        } else {
          return this.dataPlan;
        }
      } else {
        return withoutFreePlan;
      }
    },
    suggessNumberMember() {
      return this.$store.getters['auth/getUser']
        .suggestionNumberOfSubscriptionMember;
    },
    optionSeat() {
      const numberSuggess = this.suggessNumberMember;
      const optionSuggess = this.SelectOptionSeat.filter(
        option => option.value >= numberSuggess || option.value == 0
      );
      return optionSuggess;
    },
    isForceSubscription() {
      return this.$store.getters['auth/getUser'].forceSubscription;
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    toggleWorkspace() {
      this.$modal.hide('plan-card');
      this.$modal.show('create-workspace');
    },
    async togglePayment(ev) {
      this.$modal.hide('plan-card');
      this.$modal.show('plan-payment');
      this.$store.commit('subscription/SET_NUMBER_MEMBER', this.seatNum);
      this.$store.commit('subscription/SET_PLAN_SELECT', ev);
      await this.$store.dispatch('subscription/subscriptionPlanPreview', {
        numberOfSeats: this.seatNum.value,
        planID: ev.planId
      });
    },
    onDurationChanged(value) {
      const seatSelect = this.SelectOptionSeat.find(x => x.value === value);
      this.seatNum = seatSelect;
      this.$store.commit('subscription/SET_NUMBER_MEMBER', this.seatNum.value);
    }
  }
};
</script>
