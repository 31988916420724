<template>
  <span class="dot-status" :class="[color, area]" />
</template>

<script>
export default {
  props: {
    status: Number,
    size: {
      type: String,
      default: 'small'
    }
  },
  computed: {
    allStatus: () => [
      'dot-status--todo',
      'dot-status--inprogress',
      'dot-status--done',
      'dot-status--other'
    ],
    color() {
      return this.allStatus[this.status];
    },
    area() {
      const sizes = {
        small: 'dot-status--small',
        medium: 'dot-status--medium'
      };
      return sizes[this.size];
    }
  }
};
</script>

<style lang="scss" scoped>
.dot-status {
  @apply inline-block rounded-full;
  @mixin get-dot-status($status, $bg-color) {
    &.dot-status--#{$status} {
      @apply #{$bg-color};
    }
  }
  @mixin get-dot-size($name, $size) {
    &.dot-status--#{$name} {
      width: $size;
      height: $size;
    }
  }

  @include get-dot-status('todo', 'bg-todo');
  @include get-dot-status('inprogress', 'bg-progress');
  @include get-dot-status('done', 'bg-done');
  @include get-dot-status('other', 'bg-other');

  @include get-dot-size('small', 10px);
  @include get-dot-size('medium', 13px);
}
</style>
