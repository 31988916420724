<template>
  <div ref="timelogChartTabs">
    <div class="flex justify-center mb-4">
      <tab-select v-model="selectedTab" :tabs="tabs">
        <template #default="{ tab }">
          <div class="px-2">
            <div
              class="px-5 py-2 rounded text-lg font-medium capitalize"
              :class="{
                'text-brand-blue hover:bg-transparent': tab.active,
                'hover:bg-gray-200': !tab.active
              }"
            >
              {{ $t(`timelog.tabs.${tab.item}`) }}
            </div>
          </div>
        </template>
      </tab-select>
    </div>
    <div>
      <timelog-pagination
        class="content--container border-b-0"
        :next-disabled="nextDateDisabled"
        @next="onActiveDatesNext"
        @prev="onActiveDatesPrev"
      >
        <span slot="prev">{{ prevDate }}</span>
        <div slot="middle" class="font-medium text-lg">
          {{ displayWeekDates(activeDates, true) }}
        </div>
        <span slot="next">{{ nextDate }}</span>
      </timelog-pagination>

      <div>
        <div v-if="isEmpty" class="content--container">
          <base-empty
            :description="$t('timelog.nolog')"
            :image="require('@/assets/image/time-log-empty.svg')"
          />
        </div>
        <div v-else>
          <div class="content--container border-b-0">
            <chart-plan
              :dates="{
                startAt: activeDates[0].toDate(),
                endAt: activeDates[1].toDate()
              }"
            />
          </div>
          <div v-if="activeTab != 'month'" class="content--container">
            <chart-day
              v-if="activeTab === 'day'"
              :timelogs="timelogs"
              @click="showTimelog"
            />
            <chart-week
              v-else-if="activeTab === 'week'"
              :timelogs="timelogs"
              @click="showTimelog"
            />
          </div>
          <table-time-log
            v-if="activeTab != 'month'"
            class="px-2 mt-8"
            :timelogs="timelogs"
            :show-pagination="false"
            :fetch-timelog="false"
          />
        </div>
        <div
          v-show="activeTab == 'month' && !isEmpty"
          class="content--container"
        >
          <calendar-chart
            :date="activeDates[0]"
            :timelogs="timelogs"
            @select="setActiveDay"
          />
        </div>
      </div>
    </div>

    <modal name="chart-detail" scrollable adaptive height="80%" :width="1024">
      <!-- TODO: Add triangle pointer -->
      <!-- <div
          class="chart-detail__pointer absolute"
          :style="{ left: pointerLeftPx }"
        /> -->
      <div class="relative bg-white rounded pb-10 overflow-y-auto h-full">
        <chart-detail
          :is-exact="activeTab === 'day'"
          :date="activeTimelogDay"
          @hide="$modal.hide('chart-detail')"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import TimelogPagination from './timelog-pagination';
import ChartDay from './timelog-chart-day';
import ChartWeek from './timelog-chart-week';
import ChartDetail from './timelog-chart-detail';
import ChartPlan from './timelog-chart-plan';
import CalendarChart from './timelog-calendar-chart';
import TabSelect from '@/components/tab/tab-select';
import TableTimeLog from '../table/table-time-log.vue';
const { mapActions, mapGetters } = createNamespacedHelpers('time-log');

const dateLast = (date, unit) => moment(date).subtract(1, unit);
const dateNext = (date, unit) => moment(date).add(1, unit);

export default {
  components: {
    TabSelect,
    TimelogPagination,
    ChartDay,
    ChartWeek,
    ChartDetail,
    ChartPlan,
    CalendarChart,
    TableTimeLog
  },
  data() {
    return {
      selectedTab: 0,
      activeDates: [
        moment()
          .locale(`${this.$t('notifications.language')}`)
          .startOf('week'),
        moment()
          .locale(`${this.$t('notifications.language')}`)
          .endOf('week')
      ],
      chartContainerStyle: {
        width: null,
        height: null
      },
      pointerLeft: 0,
      activeTimelogDay: null
    };
  },
  computed: {
    ...mapGetters({
      timelogs: 'getTimelogs'
    }),
    tabs() {
      return ['day', 'week', 'month'];
    },
    activeTab() {
      return this.tabs[this.selectedTab];
    },
    prevDate() {
      const activeDates = this.activeDates.map(e =>
        dateLast(e, this.activeTab)
      );
      return this.displayWeekDates(activeDates);
    },
    nextDate() {
      const activeDates = this.activeDates.map(e =>
        dateNext(e, this.activeTab)
      );
      return this.displayWeekDates(activeDates);
    },
    nextDateDisabled() {
      return dateNext(this.activeDates[0], this.activeTab).isAfter(
        moment().locale(`${this.$t('notifications.language')}`)
      );
    },
    pointerLeftPx() {
      return `${this.pointerLeft}px`;
    },
    isEmpty() {
      return !this.timelogs.length;
    },
    nextPrevFormat() {
      const tab = this.tabs[this.selectedTab];
      switch (tab) {
        case 'day':
          return 'DD MMM';
        case 'week':
          return 'DD MMM';
        case 'month':
          return 'MMMM';
        default:
          return '';
      }
    },
    displayYear() {
      return this.activeTab !== 'day'
        ? moment(this.activeDates[0])
            .locale(`${this.$t('notifications.language')}`)
            .format('YYYY')
        : '';
    },
    timelogsByTime() {
      return this.timelogs.filter(t =>
        [t.startAt, t.endAt].some(e =>
          moment(e)
            .locale(`${this.$t('notifications.language')}`)
            .isSame(this.activeTimelogDay)
        )
      );
    },
    setActiveTabsOn() {
      return this.selectedTab;
    },
    setLanguageOn() {
      return this.$i18n.locale;
    }
  },
  created() {
    this.selectedTab = this.tabs.findIndex(t => t === 'week');
  },
  watch: {
    setLanguageOn(locale) {
      this.activeDates = [
        moment(this.activeDates[0])
          .locale(locale)
          .startOf(this.selectedTab),
        moment(this.activeDates[1])
          .locale(locale)
          .endOf(this.selectedTab)
      ];
    },
    setActiveTabsOn(selectedTab) {
      const tab = this.tabs[selectedTab];
      this.activeDates = [moment().startOf(tab), moment().endOf(tab)];
    },
    activeDates: {
      handler(val) {
        const dates = val.map(e => e.toDate());
        const [startDate] = dates;
        switch (this.activeTab) {
          case 'day':
            this.fetchTimelogsByDay(startDate);
            break;
          case 'week':
            this.fetchTimelogsByWeek(startDate);
            break;
          case 'month':
            this.fetchTimelogsByDateRange(dates);
            break;
          default:
            break;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions([
      'fetchTimelogsByDay',
      'fetchTimelogsByWeek',
      'fetchTimelogsByDateRange'
    ]),
    displayWeekDates([startAt, endAt], withYr) {
      const dates = [startAt];
      if (this.activeTab === 'week') dates.push(endAt);
      return dates
        .map(e => e.format(`${this.nextPrevFormat} ${withYr ? 'YYYY' : ''}`))
        .join(' - ');
    },
    onActiveDatesPrev() {
      this.activeDates = this.activeDates.map(e => dateLast(e, this.activeTab));
    },
    onActiveDatesNext() {
      this.activeDates = this.activeDates.map(e => dateNext(e, this.activeTab));
    },
    showTimelog({ timelog, view }) {
      this.pointerLeft = view ? view.x : 0;
      if (timelog) {
        this.setActiveDay(timelog.startAt);
      }
    },
    setActiveDay(date) {
      this.activeTimelogDay = new Date(date);
      this.$modal.show('chart-detail');
    }
  }
};
</script>

<style scoped>
.content--container {
  @apply border px-5 py-4;
}
.content--container.border-b-0 {
  @apply border-b-0;
}
.timelog-chart__detail {
  @apply border-t-0;
  top: 100%;
}
.chart-detail__pointer::before {
  @apply border;
  position: absolute;
  bottom: 0;
  content: '';
  border: 1.25vw solid transparent;
  border-bottom-color: #e2e8f0;
}
.chart-detail__pointer::after {
  position: absolute;
  bottom: -1px;
  content: '';
  border: 1.25vw solid transparent;
  border-bottom-color: white;
}
</style>
