<template>
  <div v-on-clickaway="cancelEdit">
    <div class="flex flex-no-wrap items-start">
      <base-input-check
        boxed
        class="mr-2 mt-3"
        :checked="completed"
        :disabled="disabled"
        color="success"
        @change="onComplete"
      />
      <div class="flex-1 mr-4">
        <paragraph-editable
          ref="input"
          :key="renderForces"
          :class="{
            'border-red-danger': isEmpty,
            'hover:bg-light-gray': !disabled
          }"
          :value="todo.name"
          :placeholder="$t('modelTask.todo.placeholder')"
          :disabled="disabled"
          @click.native="editing = !disabled && true"
          @input="editingName = $event"
          @focus="isFocused = true"
          @blur="isFocused = false"
          @keypress.native.enter.prevent="onSave"
        />
        <p v-if="isEmpty" class="text-red-danger text-xs px-2 mt-1">
          {{ $t('modelTask.todo.title') }}
        </p>
      </div>
      <base-button
        v-if="!disabled"
        class="rounded-full mt-3"
        icon="close"
        icon-size="10"
        wide="p-2"
        color="light"
        shadowless
        @click="$emit('remove')"
      />
    </div>

    <div v-if="editing" class="flex justify-end py-4">
      <base-button
        wide="px-5"
        color="text"
        :disabled="disabled"
        @click="cancelEdit"
      >
        {{ $t('alert.button.cancel') }}
      </base-button>
      <base-button
        wide="px-5"
        :disabled="disabled || notChanged"
        @click="onSave"
      >
        {{ $t('alert.button.save') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import ParagraphEditable from './paragraph-editable.vue';
import { debounce } from 'lodash';

export default {
  components: {
    ParagraphEditable
  },
  props: {
    todo: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      completed: false,
      editing: false,
      editingName: '',
      isFocused: false,
      isBusy: false,
      renderForces: new Date().getTime()
    };
  },
  computed: {
    isEmpty() {
      return this.editingName === '';
    },
    notChanged() {
      return this.editingName === this.todo.name;
    }
  },
  mounted() {
    this.initValue();
  },
  watch: {
    'todo.completed'(completed) {
      if (completed !== this.completed && !this.isBusy) {
        this.completed = completed;
      }
    }
  },
  methods: {
    cancelEdit() {
      this.renderForces++;
      this.editingName = this.todo.name;
      this.editing = false;
    },
    onComplete(ev) {
      this.isBusy = true;
      this.editing = false;
      this.completed = ev;
      this.$nextTick(() => {
        this.$emit('completed', ev);
        this.freeBusy();
      });
    },
    onSave() {
      if (this.editingName) {
        this.$emit('save', this.editingName);
      }
      this.editing = false;
    },
    initValue() {
      this.editingName = this.todo.name;
      this.completed = this.todo.completed;
      this.editing = false;
      this.$refs.input?.blur();
    },
    freeBusy: debounce(function() {
      this.isBusy = false;
    }, 500)
  },
  directives: {
    onClickaway
  }
};
</script>

<style></style>
