
























































































import Vue, { PropType } from 'vue';
import { BoardMember, Role, BoardMemberRole } from '../../types/Board';
import { BaseSelectOption } from '@/types/BaseComponents';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import IconCheck from '@/components/icons/IconCheck.vue';
import roleMember from '@/mixins/role';

export default Vue.extend({
  mixins: [roleMember],
  components: { IconChevronDown, IconCheck },
  props: {
    member: {
      type: Object as PropType<BoardMember>,
      required: true
    },
    disabled: Boolean,
    isDotButton: {
      type: Boolean,
      default: false
    },
    showRole: {
      type: Boolean,
      default: true
    },
    showRemove: {
      type: Boolean,
      default: true
    },
    isOnlyOneAdmin: Boolean
  },
  computed: {
    active(): BaseSelectOption {
      return (
        this.userRoles.find(e => e.value === this.role) || {
          value: this.role,
          label: BoardMemberRole[this.role]
        }
      );
    },
    role: {
      get(): Role {
        return this.member.role;
      },
      set(this: any, newRole: Role) {
        if (parseInt(newRole.toString()) == -2) {
          this.$emit('remove', this.member);
        } else {
          const newRoleMember = this.setRole(this.member, newRole);
          this.$store.dispatch('members/setMemberRole', newRoleMember);
        }
      }
    }
  },
  data() {
    return {
      userRoles: (this.showRole ? [100, 2, 1, -2] : [-2]).map(value => ({
        value,
        label:
          value == -2
            ? this.member.isCurrentUser
              ? 'Leave Board'
              : 'Remove Member'
            : BoardMemberRole[value],
        class:
          value == -2 ? `user--text-red ${this.showRole ? 'border-t' : ''}` : ''
      }))
    };
  }
});
