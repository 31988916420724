







































import Vue, { PropType } from 'vue';
import moment from 'moment';
declare module 'vue/types/vue' {
  interface Vue {
    range: any;
    formatShowDate: {
      start: Date;
      end: Date;
    };
    date: any;
  }
}
export default Vue.extend({
  inheritAttrs: false,
  name: 'modal-task-due-date',
  components: {
    VDatePicker: () => import('v-calendar/lib/components/date-picker.umd')
  },
  props: {
    value: {
      type: Date
    },
    task: {
      type: Object
    },
    events: {
      type: Object as PropType<{ pick(e?: Date): void }>
    }
  },
  data(): {
    date?: Date;
    range: {
      start: Date;
      end: Date;
    };
  } {
    return {
      date: undefined,
      range: {
        start: moment()
          .startOf('week')
          .toDate(),
        end: moment()
          .endOf('week')
          .toDate()
      }
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(date: Date) {
        if (date) this.date = new Date(date);
      }
    },
    task: {
      immediate: true,
      handler(task: Record<string, any>) {
        this.range.start = task.startAt;
        this.range.end = task.endAt;
      }
    }
  },
  methods: {
    close() {
      this.$modal.hide('select-date');
    },
    onDone() {
      this.events.pick(this.range);
    }
  },
  computed: {
    formatShowDate() {
      const formatDateStart = (f: any) => moment(this.range.start).format(f);
      const formatDateEnd = (e: any) => moment(this.range.end).format(e);

      const monthStart = formatDateStart('MM');
      const monthEnd = formatDateEnd('MM');
      const yearStart = formatDateStart('YYYY');
      const yearEnd = formatDateEnd('YYYY');
      if (yearStart === yearEnd) {
        if (monthStart === monthEnd) {
          return {
            start: formatDateStart('DD'),
            end: formatDateEnd('DD MMM YYYY')
          };
        } else {
          return {
            start: formatDateStart('DD MMM'),
            end: formatDateEnd('DD MMM YYYY')
          };
        }
      } else {
        return {
          start: formatDateStart('DD MMM YYYY'),
          end: formatDateEnd('DD MMM YYYY')
        };
      }
    },
    disPlayDate() {
      return `${this.formatShowDate.start} - ${this.formatShowDate.end}`;
    }
  }
});
