export class Ticks {
  ticks: { [tick: string]: boolean } = {};

  start(callback: () => void, timeout?: number) {
    const currentTimeout = setTimeout(() => {
      callback();
      this.remove(currentTimeout.toString());
    }, timeout);
    this.ticks[currentTimeout] = true;
  }

  remove(tick: string) {
    clearTimeout(parseInt(tick, 10));
    delete this.ticks[tick];
  }

  cancelAll() {
    for (const tick in this.ticks) {
      this.remove(tick);
    }
  }
}
