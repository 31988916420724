<template>
  <main
    id="boards-home"
    v-close-popover.all
    class="grid"
    :class="{
      'md:grid-cols-content-boards-home': isUser
    }"
  >
    <sidebar-navigation v-if="isUser" class="fixed w-sidebar" />
    <router-view class="col-start-2" />
  </main>
</template>

<script>
import SidebarNavigation from '@/components/sidebar-navigation';

export default {
  name: 'Boards-Home',
  components: {
    SidebarNavigation
  },
  computed: {
    isUser() {
      return this.$store.getters['auth/isUser'];
    }
  },
  mounted() {
    this.$store.dispatch('teams/fetchTeams');
  }
};
</script>

<style>
#boards-home {
  grid-template-rows: auto 1fr;
}
@screen md {
  #boards-home {
    @apply grid-rows-1;
  }
}
</style>
