<template>
  <div>
    <base-button
      :disabled="disabled"
      full
      color="bt-facebook"
      icon="Facebook"
      :icon-size="26"
      data-testid="facebook-btn"
      @click="logInWithFacebook"
      >Login with Facebook</base-button
    >
  </div>
</template>

<script>
import firebase from '@/services/firebase';
import LoadingMixin from '@/mixins/LoadingMixin';

export default {
  inheritAttrs: false,
  components: {},
  mixins: [LoadingMixin],

  props: {
    disabled: Boolean
  },
  created() {
    this.checkLogin();
  },
  methods: {
    async logInWithFacebook() {
      this.$emit('click');

      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope('email');
      provider.addScope('public_profile');
      window.localStorage.setItem('facebook_signin', 1);
      firebase.auth().signInWithRedirect(provider);
    },
    async checkLogin() {
      const self = this;

      // check auth
      if (window.localStorage.getItem('facebook_signin')) {
        this.$emit('click');
      }

      firebase
        .auth()
        .getRedirectResult()
        .then(function(result) {
          window.localStorage.removeItem('facebook_signin');
          if (result.credential) {
            self.loginFacebookSuccess(result);
          }
        })
        .catch(error => {
          window.localStorage.removeItem('facebook_signin');
          this.offLoading();
          this.$emit('loginFail');
          this.$router.push({
            name: 'Landing',
            query: { error: error?.message }
          });
        });
    },
    async loginFacebookSuccess(facebookUser) {
      if (facebookUser.credential.accessToken) {
        const accessToken = facebookUser.credential.accessToken;
        const email = facebookUser.user.email;

        this.$emit('login', {
          idToken: accessToken,
          email: email,
          loginType: 2
        });
      }
    }
  }
};
</script>

<style>
.bt-facebook {
  background: #3a5998;
}
.bt-facebook svg {
  vertical-align: top;
}
</style>
