









































































































































































































































































































































import Vue, { PropType } from 'vue';
import { createNamespacedHelpers, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import SelectTaskStatus from '@/components/select/select-task-status.vue';
import SelectTaskAssign, {
  EmitSelectTaskAssign
} from '@/components/select/select-task-assign.vue';
import SelectTaskTags from '@/components/select/select-task-tags.vue';
import FormHourMinute from '@/components/forms/forms-hour-minute.vue';
import ClockTimer from '@/assets/icons/clock-timer.vue';
import TimelogTimer from '@/components/timelog/timelog-timer.vue';
import IconAssignees from '@/components/icons/IconAssignees.vue';
import IconTags from '@/components/icons/IconTags.vue';
import IconStartEndDate from '@/components/icons/IconStartEndDate.vue';
import IconChecklist from '@/components/icons/IconChecklist.vue';
import IconAttachments from '@/components/icons/IconAttachments.vue';
import IconStoryPoint from '@/components/icons/IconStoryPoint.vue';
import IconStartTimer from '@/components/icons/IconStartTimer.vue';
import IconLevel from '@/components/icons/IconLevel.vue';
import IconLink from '@/components/icons/IconLink.vue';
import SelectTaskLevel from '@/components/select/select-task-level.vue';
import ModalTaskLinkDropdown from './modal-task-link-dropdown.vue';

// types
import { Task, TaskAssign } from '@/types/Task';
import { debounce } from 'lodash';
import { TimeLog } from '@/types/TimeLog';
import BaseIcon from '../base/base-icon.vue';
import SelectSprints from '../select/select-sprints.vue';
import IconResponsibleTester from '../icons/IconResponsibleTester.vue';

const { mapActions } = createNamespacedHelpers('tasks');

interface StoryUpdate {
  storyPoint?: number;
}

interface ModalTaskSider {
  task: Task;
  updateTask<T>(payload: Partial<T>): void;
}

interface ChecklistDropdown extends Vue {
  toggleDropdown(): void;
}

interface SelectTaskLinkDropdown extends Vue {
  addTaskLinkClick(): void;
}

interface StorypointDropdown extends Vue {
  toggleDropdown(): void;
}
interface Dates {
  start: Date;
  end: Date;
}

enum AddToCardIndex {
  StartEnd = 2,
  Checklist,
  Attachment,
  Storypoints,
  LinkTask
}

export interface AddToCardDates {
  startAt: Date;
  endAt: Date;
}

export interface AddToCard {
  index: number;
  data?: AddToCardDates | StoryUpdate | EmitSelectTaskAssign | string | number;
  isTester?: boolean;
}

const SELECT_HEIGHT = 240;

export default Vue.extend({
  components: {
    SelectTaskStatus,
    SelectTaskAssign,
    SelectTaskTags,
    ModalTaskDueDate: () => import('./modal-task-due-date.vue'),
    ClockTimer,
    FormHourMinute,
    TimelogTimer,
    BaseIcon,
    SelectSprints,
    IconAssignees,
    IconTags,
    IconStartEndDate,
    IconChecklist,
    IconAttachments,
    IconStoryPoint,
    IconStartTimer,
    IconLevel,
    IconLink,
    SelectTaskLevel,
    ModalTaskLinkDropdown,
    IconResponsibleTester
  },
  props: {
    task: {
      type: Object as PropType<Task>,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      newChecklist: '',
      newLinkTask: '',
      storyPoints: 0,
      visibleEstimateTime: false,
      selectHeight: SELECT_HEIGHT,
      checkTaskSelect: true
    };
  },
  created() {
    this.$store.dispatch('boards/fetchLevels', this.task.boardID);
  },
  computed: {
    ...mapGetters({
      board: 'boards/getActiveBoard'
    }),
    ...mapState(['currentTask']),
    selectID(): number {
      return this.task.levelID;
    },
    isAgileBoard(): boolean {
      return this.board.type === 2;
    },
    taskAssigns(): TaskAssign[] {
      return this.task.assigns || [];
    },
    taskAssignsTester(): TaskAssign[] {
      return this.task.responsibles || [];
    },
    storyPointsInput(): Vue & { focus(): void } {
      return this.$refs.storyPointsInput as Vue & { focus(): void };
    },
    checklistInput(): Vue & { focus(): void } {
      return this.$refs.checklistInput as Vue & { focus(): void };
    },
    startTimelogOptions(): { boardID: number; taskID: number } {
      return {
        boardID: this.task.boardID,
        taskID: this.task.id
      };
    },
    linkTaskInput(): Vue & { focus(): void } {
      return this.$refs.selectLinkTask as Vue & { focus(): void };
    },
    timelog(): TimeLog | undefined {
      return this.$store.getters['time-log/getWorkingLog'];
    },
    showTimelog(): boolean {
      if (!this.timelog) return true;
      return this.timelog.taskId === this.task.id;
    },
    sprintID: {
      get(): number {
        return this.task.sprintID;
      },
      set(sprintID: number): void {
        this.updateTask({ sprintID });
      }
    }
  },
  watch: {
    'task.estimateTime': {
      immediate: true,
      handler(val: number) {
        this.visibleEstimateTime = val !== 0;
      }
    },
    'task.storyPoint': {
      immediate: true,
      handler(val: number) {
        this.storyPoints = val;
      }
    }
  },
  methods: {
    ...mapActions(['setTask']),
    updateTask(updated: Partial<Task>) {
      this.setTask({ id: this.task.id, ...updated });
    },
    checkListTask(value: number) {
      this.checkTaskSelect = value === 0;
    },
    addStoryPoint() {
      if (this.storyPoints !== this.task.storyPoint) {
        this.addToCard({ index: 2, data: { storyPoint: this.storyPoints } });
      }
      const dropdown = this.$refs.storypointDropdown as StorypointDropdown;
      dropdown.toggleDropdown();
    },
    onAddToCard(index: AddToCardIndex) {
      switch (index) {
        case AddToCardIndex.StartEnd:
          this.$fana.logEvent('task/select_dates');
          return this.$modal.show('modal-task-due-date');
        case AddToCardIndex.Checklist:
          this.$fana.logEvent('task/add_checklist');
          setTimeout(() => this.checklistInput.focus(), 50);
          break;
        case AddToCardIndex.Attachment:
          this.$fana.logEvent('task/add_attachment');
          break;
        case AddToCardIndex.Storypoints:
          setTimeout(() => this.storyPointsInput.focus(), 50);
          break;
        case AddToCardIndex.LinkTask:
          setTimeout(() => this.linkTaskInput.focus(), 50);
          break;
      }
      return this.addToCard({ index });
    },
    onDatesPick(dates: Dates) {
      const { start, end } = dates;
      const startAt = moment(start)
        .locale(`${this.$t('notifications.language')}`)
        .startOf('day')
        .toDate();
      const endAt = moment(end)
        .locale(`${this.$t('notifications.language')}`)
        .endOf('day')
        .toDate();
      const data = {
        startAt,
        endAt
      };
      this.addToCard({ index: 2, data });
    },
    onAssignSelect(taskAssign: EmitSelectTaskAssign) {
      this.addToCard({ index: 0, data: taskAssign, isTester: false });
    },
    onAssignTesterSelect(taskAssignsTester: EmitSelectTaskAssign) {
      this.addToCard({ index: 0, data: taskAssignsTester, isTester: true });
    },
    addToCard(e: AddToCard) {
      this.$emit('add-to-card', e);
    },
    addChecklist() {
      const dropdown = this.$refs.checklistDropdown as ChecklistDropdown;
      if (this.newChecklist.trim()) {
        this.$store.dispatch('tasks/addChecklist', this.newChecklist);
      }
      dropdown.toggleDropdown();
    },
    addTaskLink() {
      this.checkTaskSelect = true;
      const dropdown = this.$refs.selectLinkTask as SelectTaskLinkDropdown;
      dropdown.addTaskLinkClick();

      const taskLinkDropdown = this.$refs.taskLinkDropdown as ChecklistDropdown;
      taskLinkDropdown.toggleDropdown();
    },
    toggleVisibleEstimateTime() {
      this.$fana.logEvent('task/add_estimate_time');
      const focusInput = () => {
        (this.$refs.hourMinuteInput as HTMLInputElement).focus();
      };
      this.visibleEstimateTime = !this.visibleEstimateTime;
      if (this.visibleEstimateTime) this.$nextTick(focusInput);
    },
    onEstimateTimeChanged: debounce(function(
      this: ModalTaskSider,
      estimateTime: number
    ) {
      this.updateTask({ estimateTime });
    },
    500),
    setLevel(id: number) {
      this.updateTask({ levelID: id });
    },
    trackAssignees() {
      this.$fana.logEvent('task/add_assignee');
    },
    trackTags() {
      this.$fana.logEvent('task/select_tags');
    },
    trackStartTimer() {
      this.$fana.logEvent('task/start_timelog');
    }
  }
});
