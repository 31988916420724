import { Module } from 'vuex';
import { DashboardState, RootState } from '@/types/Store';
import {
  DashboardSummary,
  DashboardInsight,
  DashboardResources,
  DashboardTaskcount
} from '@/types/Dashboard';
import apisDashboards from '@/services/apis-dashboards';

const getBoardId = (rootGetters: any): number =>
  rootGetters['boards/getActiveBoardId'];

const moduleDashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state: {
    summary: {
      all: 0,
      createToday: 0,
      dueDateToday: 0,
      late: 0,
      upcoming: 0
    },
    insight: {
      boardID: 0,
      complete: 0,
      date: new Date(),
      expectTime: 1,
      late: 0,
      spendTime: 0,
      total: 1
    },
    resources: [],
    taskcount: {
      boardID: 0,
      date: new Date(),
      late: 0,
      none: 0,
      today: 0,
      upcoming: 0
    }
  },
  getters: {
    getSummary(state) {
      return state.summary;
    },
    getInsight(state) {
      return state.insight;
    },
    getResources(state) {
      return state.resources;
    },
    getTaskcount(state) {
      return state.taskcount;
    }
  },
  mutations: {
    SET_SUMMARY(state, payload: DashboardSummary) {
      state.summary = payload;
    },
    SET_INSIGHT(state, payload: DashboardInsight) {
      state.insight = payload;
    },
    SET_USERS_AND_RESOURCES(state, payload: DashboardResources[]) {
      state.resources = payload;
    },
    SET_TASKCOUNT(state, payload: DashboardTaskcount) {
      state.taskcount = payload;
    }
  },
  actions: {
    async fetchSummary({ commit, rootGetters }) {
      const boardId = getBoardId(rootGetters);
      const { data } = await apisDashboards.board(boardId).summary();
      commit('SET_SUMMARY', data);
    },
    async fetchInsight({ commit, rootGetters }) {
      const boardId = getBoardId(rootGetters);
      const { data } = await apisDashboards.board(boardId).boardInsight();
      commit('SET_INSIGHT', data);
    },
    async fetchResources({ commit, rootGetters }) {
      const boardId = getBoardId(rootGetters);
      const { data } = await apisDashboards.board(boardId).userResources();
      commit('SET_USERS_AND_RESOURCES', data);
    },
    async fetchTaskcount({ commit, rootGetters }) {
      const boardId = getBoardId(rootGetters);
      const { data } = await apisDashboards.board(boardId).taskCount();
      commit('SET_TASKCOUNT', data);
    }
  }
};

export default moduleDashboard;
