<template>
  <div>
    <div class="mt-3">
      <div class="flex justify-between">
        <div v-t="'boardSetting.column.status'" class="text-xl" />
        <div>
          <base-button
            v-if="isUserBoardAdmin(board) || isUserBoardEditor(board)"
            icon="plus"
            icon-size="16"
            color="brand"
            wide="px-3 py-2 text-sm"
            @click="toggleCreate"
          >
            {{ $t('boardSetting.column.create') }}
          </base-button>
        </div>
      </div>
      <div class="mt-3">
        <base-input-text
          v-model="search"
          type="text"
          :placeholder="$t('boardSetting.column.search.placeholder')"
          icon-fa="search"
          :icon-right="search ? 'IconClose' : null"
          @icon-right-click="search = ''"
        />
      </div>
    </div>
    <div v-if="creating" class="mt-3">
      <form-agile-status
        v-model="form"
        class="column-box"
        @confirm="beforeAddColumn"
        @cancel="closeForm"
      />
    </div>
    <v-draggable
      v-model="statusRows"
      v-bind="draggableConfig"
      :animation="150"
      direction="vertical"
      :disabled="dragDisabled"
      @start="dragging = true"
      @end="dragging = false"
      @change="dragChange"
    >
      <transition-group
        class="mt-3"
        tag="ul"
        type="transition"
        :name="!dragging ? 'flip-list' : null"
      >
        <li v-for="column in statusRows" :key="column.id" class="py-2">
          <form-agile-status
            v-if="isColumnEdit(column)"
            v-model="form"
            class="column-box"
            @confirm="beforeSetColumn"
            @cancel="closeForm"
          />

          <div v-else class="column-box draggable">
            <div class="status-row flex items-center justify-between">
              <div>
                <p class="font-medium mb-2">
                  <span>
                    <div class=" overflow-hidden">
                      <div class="truncate" style="width: 360px;">
                        {{ column.name }}
                      </div>
                    </div>
                  </span>
                </p>
                <div>
                  <div class="agile-badge">
                    <info-dot-agile-status
                      class="mr-1"
                      :status="column.status"
                    />
                    {{ $t('boardSetting.column.statusTypes')[column.status] }}
                  </div>
                  <div class="agile-badge badge--task-count">
                    {{ $tc('counts.task', countColumnTasks(column)) }}
                  </div>
                </div>
              </div>
              <div class="status-row__edit">
                <base-icon
                  v-if="isUserBoardAdmin(board) || isUserBoardEditor(board)"
                  class="cursor-pointer hover--dark text-black"
                  icon="edit"
                  size="24"
                  view-box="0 0 24 24"
                  @click.native="setColumnEdit(column)"
                />
              </div>
            </div>
          </div>
        </li>
      </transition-group>
    </v-draggable>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VDraggable from 'vuedraggable';
import VuedraggableConfig from '@/mixins/Vuedraggable';
import DragAndDrop from '@/mixins/DragAndDrop';
import FormAgileStatus from '../forms/form-agile-status.vue';
import InfoDotAgileStatus from '../info/info-dot-agile-status';
import BoardUtils from '@/mixins/BoardUtils';

export default {
  mixins: [DragAndDrop, VuedraggableConfig, BoardUtils],
  components: {
    VDraggable,
    InfoDotAgileStatus,
    FormAgileStatus
  },
  data() {
    return {
      creating: false,
      search: '',
      form: {
        name: '',
        status: 0
      },
      dragging: false
    };
  },
  computed: {
    ...mapGetters('board-column', ['getColumns']),
    ...mapGetters('tasks', ['getTasks']),
    statusRows: {
      get() {
        const byName = ({ name }) =>
          name.toLowerCase().includes(this.search.toLowerCase());
        return this.getColumns.filter(byName);
      },
      set(list) {
        this.SET_COLUMNS(list);
      }
    },
    options() {
      return ['Todo', 'Inprogress', 'Done'].map((label, value) => ({
        label,
        value
      }));
    },
    dragDisabled() {
      return !!this.form.id || this.search;
    },
    board() {
      return this.$store.getters['boards/getActiveBoard'];
    }
  },
  methods: {
    ...mapMutations('board-column', ['SET_COLUMNS']),
    ...mapActions('board-column', ['setColumn', 'addColumn']),
    toggleCreate() {
      this.creating = !this.creating;
      this.resetForm();
    },
    closeForm() {
      this.resetForm();
      this.creating = false;
    },
    resetForm() {
      this.form = {
        name: '',
        status: 0
      };
    },
    beforeAddColumn() {
      const lastItemIndex = this.getColumns.length;
      const position = this.getPosition(this.getColumns, lastItemIndex);
      const data = {
        ...this.form,
        position
      };
      this.addColumn(data);
      this.closeForm();
    },
    beforeSetColumn() {
      this.setColumn(this.form);
      this.closeForm();
    },
    countColumnTasks(column) {
      return this.getTasks.filter(t => t.boardColumnID === column.id).length;
    },
    setColumnEdit(column) {
      const initForm = () => {
        this.form = {
          id: column.id,
          name: column.name,
          status: column.status
        };
        this.creating = false;
      };
      if (column) initForm();
      else this.closeForm();
    },
    isColumnEdit(column) {
      return this.form.id === column.id;
    },
    dragChange(event) {
      const { moved } = event;
      const { element, newIndex } = moved;
      const position = this.getPosition(this.statusRows, newIndex);
      this.setColumn({ position, id: element.id });
    }
  }
};
</script>

<style scoped lang="scss">
.agile-badge {
  @apply text-sm;
}
.badge--task-count {
  @apply bg-white border ml-3 text-sm;
}
.column-box {
  @apply border bg-white rounded p-4;

  &.draggable:hover {
    @apply bg-light-gray cursor-pointer;
  }

  &:hover {
    .status-row__edit {
      @apply inline-block;
    }
  }

  .status-row__edit {
    @apply hidden;
  }
}
</style>

<style>
.base-select__option--active {
  @apply bg-gray-main;
}
</style>
