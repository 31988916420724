import apisFolders from '@/services/apis-folders';
import { Board } from '@/types/Board';
import {
  FolderState,
  Folder,
  FolderCreate,
  FolderUpdate
} from '@/types/Folder';
import { RootState } from '@/types/Store';
import { Module } from 'vuex';
import { getNewListWithUpdate } from './utils';

interface GetFolder extends Folder {
  boards: Board[];
}

const getPosition = (board: Board) => board.currentMember.position;
const sortBoardByPosition = (a: Board, b: Board) =>
  getPosition(a) - getPosition(b);

const groupBoardsByFolder = (boards: Board[], folders: Folder[]) => {
  const initFolderBoards = folders.reduce(
    (p, c) => ({ ...p, [c.id]: [] }),
    {} as { [folderID: number]: Board[] }
  );
  return boards.reduce((p, c) => {
    const { folderID } = c.currentMember;
    const result = { ...p, [folderID]: [...p[folderID], c] };
    return result;
  }, initFolderBoards);
};

const folderModule: Module<FolderState, RootState> = {
  namespaced: true,
  state: {
    folders: []
  },
  getters: {
    getFolders(s, _g, _rs, rootGetter): GetFolder[] {
      const boards = rootGetter['boards/getBoards'].all as Board[];
      const folderBoards = groupBoardsByFolder(boards, s.folders);
      return s.folders.map(folder => ({
        ...folder,
        boards: folderBoards[folder.id].sort(sortBoardByPosition)
      }));
    },
    getLastFolder: s => s.folders[s.folders.length - 1],
    getNoFolder: s => s.folders.find(f => f.isNoFolder)
  },
  mutations: {
    SET_FOLDERS(s, payload: Folder[]) {
      s.folders = payload;
    },
    ADD_FOLDER(s, payload: Folder) {
      s.folders.push(payload);

      setTimeout(function() {
        const bottomScroll = document.body.scrollHeight;
        window.scrollTo({ top: bottomScroll });
      }, 200);
    },
    UPDATE_FOLDER(s, payload: Folder) {
      s.folders = getNewListWithUpdate(s.folders, payload);
    },
    DELETE_FOLDER(s, id: number) {
      s.folders = s.folders.filter(f => f.id !== id);
    }
  },
  actions: {
    async fetchFolders({ commit }) {
      const res = await apisFolders.index();
      commit('SET_FOLDERS', res.data.entities);
    },
    async createFolder({ commit, getters }, req: FolderCreate) {
      const lastFolder = getters.getLastFolder;
      req.position = lastFolder.position * 2;

      const res = await apisFolders.create(req);
      commit('ADD_FOLDER', res.data);
    },
    async updateFolder({ commit }, req: FolderUpdate & { id: number }) {
      const { id, ...updateReq } = req;
      const res = await apisFolders.update(id, updateReq);
      commit('UPDATE_FOLDER', res.data);
    },
    async deleteFolder({ commit, getters }, id: number) {
      await apisFolders.delete(id);
      function updateBoards(boards: Board[]) {
        commit('boards/UPDATE_BOARDS', boards, { root: true });
      }
      const folder = (getters.getFolders as GetFolder[]).find(f => f.id === id);
      const noFolder = getters.getNoFolder as GetFolder;
      const noFolderBoards =
        folder?.boards.map(b => {
          b.currentMember.folderID = noFolder.id;
          return b;
        }) || [];

      updateBoards(noFolderBoards);
      commit('DELETE_FOLDER', id);
    }
  }
};

export default folderModule;
