<template>
  <div class="card" v-on="$listeners">
    <div v-if="hasHeader" class="rounded-t" :style="headerStyle">
      <slot name="header" />
    </div>
    <div
      class="card__body"
      :class="[bodyPadding, bodyClasses, { 'rounded-t': !hasHeader }]"
    >
      <slot />
    </div>
    <div class="rounded-b overflow-hidden">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bodyPadding: {
      type: String,
      default: 'p-4'
    },
    bodyClasses: {
      type: [Array, String],
      default: () => []
    },
    headerStyle: {
      type: [String, Object]
    }
  },
  computed: {
    hasHeader() {
      return this.$slots.header != null;
    }
  }
};
</script>

<style>
.card {
  @apply shadow rounded inline-flex flex-col;
}
.card__body {
  @apply flex-1 rounded-b bg-white;
}

.card:hover .card__body {
  @apply bg-transparent;
}
</style>
