
























import { Vue, Component, Prop } from 'vue-property-decorator';

import DragFile from '@/components/icons/DragFile.vue';

@Component({
  components: {
    DragFile,
  },
})
export default class ModalTaskFileDrag extends Vue {
  @Prop({ type: Boolean }) value?: boolean;

  mounted() {
    const self = this;
    document.addEventListener('dragover', (e) => {
      e.preventDefault();
      self.showOver = true;
      self.showOverView = true;
      self.$emit('input', true);
    });

    document.addEventListener('dragleave', () => {
      self.showOver = false;
    });

    document.addEventListener('drop', () => {
      self.showOver = false;
    });

    setInterval(() => {
      if (!self.showOver) {
        self.showOverView = false;
        self.$emit('input', false);
      }
    }, 500);
  }

  showOverView = false;
  showOver = false;
  isOverFocus = false;

  dragover(event: DragEvent) {
    event.preventDefault();
    this.isOverFocus = true;
  }
  dragleave() {
    this.isOverFocus = false;
  }
  drop(event: DragEvent) {
    event.preventDefault();

    this.$emit('upload', { files: (event.dataTransfer as DataTransfer).files });

    this.showOverView = false;
    this.showOver = false;
    this.$emit('input', false);
  }
}
