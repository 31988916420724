<template>
  <div class="relative overflow-hidden" :class="roundedClass">
    <div
      class="bg-gray-200 absolute inset-x-0 h-full"
      :class="[barHeight, roundedClass]"
    />

    <div
      class="transition-all duration-500 relative w-full"
      :class="[barHeight, roundedClass]"
      :style="[
        {
          'background-color': color,
          transform: getPercentage()
        }
      ]"
    />
  </div>
</template>

<script>
const HUNDRED_PERCENT = 100;
export const getPercentage = (value, maximum) =>
  Math.min(
    parseFloat(((value / maximum) * HUNDRED_PERCENT).toFixed(2)),
    HUNDRED_PERCENT
  );
export default {
  props: {
    color: {
      type: String
    },
    maximum: {
      type: Number,
      default: HUNDRED_PERCENT
    },
    value: {
      type: Number,
      default: 0
    },
    height: {
      type: [Number, String],
      default: 5
    },
    rounded: Boolean
  },
  computed: {
    barHeight() {
      return ['h-1', 'h-2', 'h-3', 'h-4', 'h-5'][this.height] || 'h-5';
    },
    roundedClass() {
      return {
        'rounded-full': this.rounded
      };
    },

    getProgressPercentage() {
      if (!this.maximum) return 0;
      return getPercentage(this.value, this.maximum);
    }
  },
  methods: {
    getPercentage() {
      return `translateX(${-100 + this.getProgressPercentage}%)`;
    }
  }
};
</script>

<style scoped>
.progress-move {
  transition: transform 0.5s;
}
</style>
