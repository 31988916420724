<template>
  <main>
    <modal
      ref="update-team"
      name="update-team"
      :width="764"
      :height="750"
      scrollable
      class="overflow-auto py-4"
      @closed="closeModalSetting"
    >
      <!-- <update-team-form class="overflow-y-auto" style="max-height: 90vh;" />-->
      <workspace-setting />
    </modal>

    <div class="border-l border-gray-300 min-h-screen">
      <div v-if="team">
        <subscripiton-view-only
          v-if="team.viewOnly && isShowViewOnly"
          @closeViewOnly="closeViewOnly"
        />

        <!-- team detail -->
        <section class="p-4 border border-gray-300 rounded-md flex space-x-6">
          <div class="w-32 h-32">
            <img
              v-if="team.imageLogo"
              class="w-full h-full object-cover rounded-md"
              :src="team.imageLogo"
            />

            <base-icon v-else class="w-32 h-32">
              <icon-team-image />
            </base-icon>
          </div>
          <div class="flex-1 overflow-ellipsis overflow-hidden">
            <div style="font-size: 20px; ">
              <v-clamp class="break-words" autoresize :max-lines="2">
                {{ team.name }}
              </v-clamp>
            </div>
            <div
              v-if="team.description"
              style="font-size: 16px;"
              class="text-sm"
            >
              {{ $t('team.description') }}
            </div>
            <div style="font-size: 16px;" class="text-gray-600">
              <v-clamp class="break-words" autoresize :max-lines="5">
                {{ team.description }}
              </v-clamp>
            </div>
            <div class="flex space-x-8 mt-4">
              <div>
                <div class="text-sm text-gray-600">
                  {{ $t('team.type') }}
                </div>
                <div class="text-sm">
                  {{
                    $i18n.locale === 'th'
                      ? team.teamType.valueTh
                      : team.teamType.valueEN
                  }}
                </div>
              </div>
              <div>
                <div class="text-sm text-gray-600">
                  {{ $t('team.website') }}
                </div>
                <div class="text-s">
                  <a
                    v-if="isWebsiteFormat(team.webSite)"
                    class="text-blue-700 hover:underline"
                    :href="webSite"
                    target="_blank"
                    >{{ team.webSite ? team.webSite : '-' }}</a
                  >
                  <span v-else>{{ team.webSite || '-' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <base-button
              v-if="isAdmin"
              class="text-gray-800"
              color="bg-gray-300 "
              icon="edit-team"
              responsive
              icon-size="24"
              @click="updateTeamClick"
            >
              {{ $t('team.teamDetails.button') }}
            </base-button>
          </div>
        </section>

        <!-- tab -->
        <div class="border-b border-gray-300 flex space-x-8 px-6 ">
          <div
            v-for="tab in tabs"
            :key="`tab-${tab.key}`"
            class=""
            :class="{ 'border-b-2 border-blue-500': tab.key == selectTab }"
          >
            <router-link :to="{ name: tab.key }">
              <div class="py-2 flex items-center">
                <base-icon size="16" class="mr-2">
                  <component :is="`icon-${tab.icon}`" />
                </base-icon>
                {{ $t(`${tab.name}`) }}
              </div>
            </router-link>
          </div>
          <!--     <router-link
            v-if="isAdmin"
            :to="{ name: 'Teams-Report' }"
            :class="{
              'border-b-2 border-blue-500': $route.name === 'Teams-Report'
            }"
          >
            <div class="py-2">
              {{ $t(`team.tabs.Report`) }}
            </div>
          </router-link> -->
        </div>

        <router-view />
      </div>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import UpdateTeamForm from '@/components/update-team-form';
import IconTeamImage from '@/components/icons/IconTeamImage.vue';
import { TeamMemberRole } from '@/types/Team';
import VClamp from 'vue-clamp';
import IconCreateBoard from '@/components/icons/IconCreateBoard';
import IconStarOutlined from '@/components/icons/IconStarOutlined';
import IconOverview from '@/components/icons/IconOverview';
import WorkspaceSetting from '@/components/workspace/workspace-setting';
import SubscripitonViewOnly from '@/components/subscription/subscription-view-only';
const { mapActions, mapGetters } = createNamespacedHelpers('boards');

export default {
  components: {
    UpdateTeamForm,
    IconTeamImage,
    VClamp,
    IconCreateBoard,
    IconStarOutlined,
    IconOverview,
    WorkspaceSetting,
    SubscripitonViewOnly
  },
  name: 'Teams-Id',
  data() {
    return {
      isShowViewOnly: true
    };
  },
  watch: {
    '$route.params.id'() {
      this.fetch();
    }
  },
  mounted() {
    this.fetch();
  },
  computed: {
    isAdmin() {
      const members = this.$store.getters['teams/getTeam'];
      return members.currentTeamMember.role === TeamMemberRole.Admin;
    },
    team() {
      return this.$store.getters['teams/getTeam'];
    },
    selectTab() {
      return this.$route.name;
    },
    tabs() {
      return [
        {
          key: 'Workspace-Star-Board',
          name: this.$t('tab_boardStar'),
          icon: 'star-outlined'
        },
        {
          key: 'Teams-Board',
          name: this.$t('tab_boards'),
          icon: 'create-board'
        },
        {
          key: 'Teams-Dashboard',
          name: this.$t('tab_overview'),
          icon: 'overview'
        }
        // { key: 'Teams-Members', name: 'Members' }
        // { key: 'Teams-Timeline', name: 'Timeline' },
        // { key: 'Teams-Calendar', name: 'Calendar' }
      ];
    },
    webSite() {
      if (
        this.team.webSite.startsWith('http://') ||
        this.team.webSite.startsWith('https://')
      )
        return this.team.webSite;
      else return `http://${this.team.webSite}`;
    }
  },
  methods: {
    closeModalSetting() {
      document.body.classList.remove('vm--block-scroll');
    },
    async fetch() {
      try {
        await this.$store.dispatch('teams/fetchTeam', this.$route.params.id);
      } catch (error) {
        this.$router.push({ name: 'Boards-List' });
      }
    },
    updateTeamClick() {
      this.$modal.show('update-team');
    },
    isWebsiteFormat(webSite) {
      const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      const regex = new RegExp(expression);
      return webSite.match(regex) ? true : false;
    },
    closeViewOnly() {
      this.isShowViewOnly = false;
    }
  }
};
</script>

<style></style>

<i18n>
{
  "en": {
    "tab_boardStar": "Stared Boards",
    "tab_boards": "Boards",
    "tab_overview": "Overview"
  },
  "th": {
    "tab_boardStar": "บอร์ดที่ติดดาว",
    "tab_boards": "บอร์ด",
    "tab_overview": "ภาพรวม"
  }
}
</i18n>
