<template>
  <div class="relative">
    <div class="flex items-center justify-between relative flex-wrap">
      <button
        :disabled="prevDisabled"
        class="button pagination-btn order-1"
        @click="onPrev"
      >
        <div class="has-centered-item">
          <fa-icon icon="chevron-left" size="lg" />
        </div>
        <span class="ml-4">
          <slot name="prev">
            Prev
          </slot>
        </span>
      </button>

      <div class="w-full sm:w-auto sm:order-2 order-3 my-2">
        <slot name="middle" />
      </div>

      <button
        class="button pagination-btn order-2 sm:order-3"
        :class="{ 'pagination-btn--disabled': nextDisabled }"
        :disabled="nextDisabled"
        @click="onNext"
      >
        <span class="mr-4">
          <slot name="next">
            Next
          </slot>
        </span>
        <div class="has-centered-item">
          <fa-icon icon="chevron-right" size="lg" />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nextDisabled: Boolean,
    prevDisabled: Boolean
  },
  methods: {
    onNext() {
      this.$emit('next');
    },
    onPrev() {
      this.$emit('prev');
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-btn {
  @apply text-gray-500 shadow-none items-center rounded py-2 uppercase;

  &--disabled {
    @apply invisible;
  }

  &:not(:disabled):hover {
    @apply text-brand-blue bg-gray-200;
  }

  &:not(:disabled):active,
  .pagination-datepicker:active {
    @apply bg-transparent;
  }
}
</style>
