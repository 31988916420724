import Vue from 'vue';

export default Vue.extend({
  data() {
    return {};
  },
  methods: {
    formatString(str: string) {
      return str.replace(/↵/g, '<br/>');
    }
  }
});
