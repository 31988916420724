import Vue from 'vue';

export default Vue.extend({
  methods: {
    time(timeSpent: number) {
      const hour = Math.floor(timeSpent / 60);
      const minute = timeSpent % 60;

      const formatHour =
        hour === 0 ? '' : `${hour} ${this.$t('timelog.time.hour')}`;
      const formatMin =
        minute === 0 ? '' : `${minute} ${this.$t('timelog.time.min')}`;
      return `${formatHour} ${formatMin} `;
    }
  }
});
