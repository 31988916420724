<template>
  <div>
    <h3 class="mb-3 text-2xl">{{ $t('timelog.timer.titleManually') }}</h3>
    <time-log-starter-inputs
      :disabled="loading"
      :description.sync="description"
      :board.sync="board"
      :task.sync="task"
    />
    <form-datetime :start.sync="start" :end.sync="end" />
    <div class="text-right">
      <base-button
        :disabled="addDisabled"
        :loading="loading"
        wide="px-8"
        @click="onAdd"
      >
        {{ $t('timelog.manual.button') }}
      </base-button>
      <p :class="{ invisible: !isSameTime }" class="text-sm text-gray-500 mt-2">
        {{ $t('timelog.manual.required') }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import TimeLogStarterInputs from './time-log-starter-inputs';
import FormDatetime from '@/components/forms/form-datetime';

export default {
  components: {
    TimeLogStarterInputs,
    FormDatetime
  },
  data() {
    return {
      loading: false,
      description: '',
      board: null,
      task: null,
      start: null,
      end: null
    };
  },
  computed: {
    isSameTime() {
      if (this.start && this.end) {
        return moment(this.start)
          .locale(`${this.$t('notifications.language')}`)
          .isSame(this.end);
      }
      return true;
    },
    addDisabled() {
      const noDescAndBoard = !this.description && !this.board;
      return this.isSameTime || noDescAndBoard;
    }
  },
  methods: {
    ...mapActions('time-log', ['startWorking']),
    async onAdd() {
      this.$fana.logEvent('timelog/add_timelog');
      const addValue = {
        input: this.tabActive === 0 ? 1 : 0,
        startAt: this.start,
        endAt: this.end,
        description: this.description,
        boardID: this.board?.id,
        taskID: this.task?.id
      };

      this.loading = true;
      await this.startWorking(addValue);
      this.resetData();
    },
    resetData() {
      this.loading = false;
      this.description = '';
      this.board = null;
      this.task = null;
      this.start = null;
      this.end = null;
    }
  }
};
</script>

<style></style>
