import { Board } from './Board';
import { Task } from './Task';
import { User } from './User';

export enum TimeLogInput {
  MANUAL,
  TIMER
}

export enum TimeLogType {
  TASK = 1,
  BOARD,
  USER
}

export interface TimeLogCreate {
  boardID?: number;
  description?: string;
  endAt?: string;
  input: TimeLogInput;
  startAt: string;
  taskID?: number;
  title?: string;
  type?: TimeLogType;
}

export interface TimeLogUpdate {
  description?: string;
  endAt?: string;
  startAt?: string;
  title?: string;
  boardID?: number | null;
  taskID?: number | null;
}

export interface TimeLog {
  boardId: number;
  createdAt: string;
  deletedAt: string;
  description: string;
  endAt: string;
  id: number;
  input: TimeLogInput;
  isTimeSpentMoreThanLimit: boolean;
  startAt: string;
  status: number;
  taskId: number;
  timeSpent: number;
  title: string;
  type: number;
  unplanned: boolean;
  updatedAt: string;
  user: User;
  board: Board;
  task: Task;
  color: string;
}

export interface TimeLogDateRangeResponse {
  date: string;
  logs: TimeLog[];
}

export interface PlannedTimesResponse {
  totalPlanInMinutes: number;
  totalUnPlanInMinutes: number;
  TotalTimeSpentInMinutes: number;
  totalPlanInPercent: number;
  totalUnPlanInPercent: number;
}
