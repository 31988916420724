import { Module } from 'vuex';
import router from '@/router';
import { RootState } from '@/types/Store';
import firebase, { getCollections } from '@/services/firebase';
import apis, { boardsApi, teamsApi } from '@/services/apis';
import {
  Team,
  TeamAdd,
  TeamsResponse,
  TeamMember,
  TeamMembersResponse,
  TeamMemberStatus,
  TeamMemberRole,
  TeamType
} from '@/types/Team';
import {
  Board,
  BoardsResponse,
  BoardInvitationRequest,
  BoardMemberSetRoleRequest
} from '@/types/Board';
import { PageInformation } from '@/types/Api';
import { Params } from '@fortawesome/fontawesome-svg-core';

interface State {
  team?: Team;
  loading?: boolean;
  teams: Team[];
  pageInformation: PageInformation;
  boards: Board[];
  boardsStarred: Board[];
  boardsYour: Board[];
  boardsPageInformation: PageInformation;
  members: TeamMember[];
  membersActive: TeamMember[];
  membersPending: TeamMember[];
  membersPageInformation: PageInformation;
  membersLoading: boolean;
  types: TeamType[];
}

const boardsModule: Module<State, RootState> = {
  namespaced: true,
  state: {
    team: undefined,
    teams: [],
    pageInformation: {
      page: 0,
      count: 0,
      lastPage: 0
    },
    boards: [],
    boardsStarred: [],
    boardsYour: [],
    boardsPageInformation: {
      page: 0,
      count: 0,
      lastPage: 0
    },
    members: [],
    membersActive: [],
    membersPending: [],
    membersPageInformation: {
      page: 0,
      count: 0,
      lastPage: 0
    },
    membersLoading: false,

    types: []
  },
  getters: {
    getTeam: s => s.team,
    getTeams: s => s.teams,
    getNextPage: ({ pageInformation: { page, lastPage } }) =>
      page < lastPage ? page + 1 : 0,
    getBoards: s => s.boards,
    getBoardsStarred: s => s.boardsStarred,
    getBoardsYour: s => s.boardsYour,
    getBoardsNextPage: ({ boardsPageInformation: { page, lastPage } }) =>
      page < lastPage ? page + 1 : 0,
    getMembers: s => s.members,
    getMembersActive: s => s.membersActive,
    getMembersPending: s => s.membersPending,
    getMembersNextPage: ({ membersPageInformation: { page, lastPage } }) =>
      page < lastPage ? page + 1 : 0,
    isMembersNextValid(s) {
      return s.membersPageInformation.page < s.membersPageInformation.lastPage;
    },
    membersLoading(s) {
      return s.membersLoading;
    },
    getTypes: s => s.types,
    isUserViewer: s => {
      if (s.team && s.team.currentTeamMember && s.team.currentTeamMember.role)
        return s.team.currentTeamMember.role === TeamMemberRole.Viewer;
      else return true;
    }
  },
  mutations: {
    SET_TEAM: (s, payload: Team) => {
      s.team = payload;
    },
    SET_TEAMS: (s, payload: TeamsResponse) => {
      const { entities, pageInformation } = payload;
      s.teams = entities;
      s.pageInformation = pageInformation;
    },
    SET_TEAM_NEXT(s, payload: TeamsResponse) {
      s.teams = [...s.teams, ...payload.entities];
      s.pageInformation = payload.pageInformation;
    },
    ADD_TEAM: (s, newTeam: Team) => {
      s.teams.push(newTeam);
    },
    SET_BOARDS: (s, payload: BoardsResponse) => {
      const { entities, pageInformation } = payload;
      s.boards = entities;
      s.boardsStarred = entities.filter(b => b.isStarred);
      s.boardsYour = entities.filter(b => b.isMemberJoined);
      s.boardsPageInformation = pageInformation;
    },
    SET_BOARDS_NEXT(s, payload: BoardsResponse) {
      s.boards = [...s.boards, ...payload.entities];
      s.boardsPageInformation = payload.pageInformation;
    },

    SET_MEMBERS: (s, payload: TeamMembersResponse) => {
      const { entities, pageInformation } = payload;
      s.members = entities;
      s.membersActive = entities.filter(
        b => b.status == TeamMemberStatus.Active
      );
      s.membersPending = entities.filter(
        b => b.status == TeamMemberStatus.Pending
      );
      s.membersPageInformation = pageInformation;
    },
    ADD_MEMBERS(s, payload: TeamMember[]) {
      s.members = [...s.members, ...payload];
      s.membersActive = [
        ...s.membersActive,
        ...payload.filter(b => b.status == TeamMemberStatus.Active)
      ];
      s.membersPending = [
        ...s.membersPending,
        ...payload.filter(b => b.status == TeamMemberStatus.Pending)
      ];
    },
    SET_MEMBERS_PAGE(s, payload: PageInformation) {
      s.membersPageInformation = payload;
    },
    SET_MEMBERS_LOADING(s, payload: boolean) {
      s.membersLoading = payload;
    },
    UPDATE_MEMBER(s, payload: TeamMember) {
      s.members = s.members.map(e => (e.id === payload.id ? payload : e));
      s.membersActive = s.membersActive.map(e =>
        e.id === payload.id ? payload : e
      );
      s.membersPending = s.membersPending.map(e =>
        e.id === payload.id ? payload : e
      );
    },
    REMOVE_MEMBER(s, id: number) {
      s.members = s.members.filter(e => e.id !== id);
      s.membersActive = s.membersActive.filter(e => e.id !== id);
      s.membersPending = s.membersPending.filter(e => e.id !== id);
    },
    SET_TYPES(s, types: TeamType[]) {
      s.types = types;
    },
    DELETE_TEAM(s, id: number) {
      s.teams = s.teams.filter(e => e.id !== id);
    }
  },
  actions: {
    fetchTeam: async ({ commit }, id) => {
      const res = await teamsApi.get(id);
      commit('SET_TEAM', res.data);
    },
    fetchTeams: async ({ commit }) => {
      const res = await teamsApi.fetchTeams({});
      commit('SET_TEAMS', res.data);
    },
    fetchTeamsNext: async ({ commit, getters }) => {
      const params = {
        page: getters.getNextPage
      };
      const { data } = await teamsApi.fetchTeams(params as Params);
      commit('SET_TEAM_NEXT', data);
    },

    createTeam: async ({ commit, getters }, createTeamRequest: TeamAdd) => {
      const res = await teamsApi.create(createTeamRequest);
      commit('ADD_TEAM', res.data);
      router.push({
        name: 'Teams-Board',
        params: { id: `${res.data.id}` }
      });
    },
    updateTeam: async ({ getters, commit, dispatch }, update: TeamAdd) => {
      const res = await teamsApi.updateTeam(getters['getTeam'].id, update);
      commit('SET_TEAM', res.data);
      dispatch('fetchTeams');
    },
    delete: async ({ getters, commit }) => {
      const idTeam = getters['getTeam'].id;
      await teamsApi.delete(idTeam);
      commit('SET_TEAM', {});
      if (router.currentRoute.name != 'Subscription-Space') {
        router.push({
          name: 'Boards-List'
        });
      }

      commit('DELETE_TEAM', idTeam);
    },

    fetchBoards: async ({ commit, getters }, id) => {
      const res = await teamsApi.fetchBoards(id || getters['getTeam'].id);
      commit('SET_BOARDS', res.data);
    },
    clearMembers: async ({ commit }) => {
      commit('SET_MEMBERS', { entities: [], pageInformation: {} });
    },
    fetchMembers: async ({ state, commit, getters }, id) => {
      commit('SET_MEMBERS_LOADING', true);
      const teamID = id || getters['getTeam'].id;
      const page = state?.membersPageInformation?.page || 0;
      const { data } = await teamsApi.fetchMembers(teamID, {
        page: page + 1
      });
      commit('ADD_MEMBERS', data.entities);
      commit('SET_MEMBERS_PAGE', data.pageInformation);
      commit('SET_MEMBERS_LOADING', false);
    },
    inviteMember: async ({ commit }, invitation: BoardInvitationRequest) => {
      const { data } = await teamsApi.inviteMember(invitation);
      if (Array.isArray(data)) {
        commit('ADD_MEMBERS', data);
      }
    },
    setMemberRole: async ({ getters, commit }, newRoleMember: TeamMember) => {
      const id = getters['getTeam']?.id ?? 0;
      const req: BoardMemberSetRoleRequest = {
        userID: newRoleMember.userID,
        role: newRoleMember.role,
        id,
        memberID: newRoleMember.memberID
      };
      await teamsApi.setMemberRole(req);
      commit('UPDATE_MEMBER', newRoleMember);
    },
    kickMember: async ({ commit, getters }, member: TeamMember) => {
      const teamID = getters['getTeam'].id ?? 0;
      await teamsApi.kickMember({
        id: teamID,
        memberID: member.id
      });
      commit('REMOVE_MEMBER', member.id);

      // is kick me redirect to home
      if (member.isCurrentUser) {
        router.push({ name: 'Boards-List' });
        commit('DELETE_TEAM', teamID);
      }
    },

    fetchTypes: async ({ commit }) => {
      const res = await teamsApi.fetchTypes();
      commit('SET_TYPES', res.data);
    }
  }
};

export default boardsModule;
