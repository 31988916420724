<template>
  <div>
    <div class="form-datetime form-wrapper--reset">
      <form-field
        class="form-datetime__field"
        :title="$t('timelog.manual.date.name')"
      >
        <!-- date -->
        <div class="flex-shrink-0">
          <select-date
            v-model="startDate"
            :modal="dateModal"
            toggle-prefix
            hide-today
            expanded
            class="form-datetime__input-height flex"
            :placeholder="$t('timelog.manual.date.start')"
            @input="onSelectDate"
          />
        </div>
      </form-field>
      <form-field class="form-datetime__field">
        <div class="flex items-start">
          <!-- time start -->
          <form-field :title="$t('timelog.manual.start')">
            <v-timepicker
              v-model="startTime"
              auto-scroll
              :placeholder="timepickerPlaceholder"
              :drop-direction="timeDropDirection"
              hide-clear-button
              input-width="110px"
              input-class="display-time--customized placeholder-gray-600"
              class="vue2-timepicker--custom"
              @input="inputStartTime"
            />
          </form-field>
          <div class="flex flex-col self-stretch w-4">
            <div class="invisible mb-2 text-sm">(dash)</div>
            <span
              class="text-gray-400 text-center flex-1 flex items-center justify-center"
            >
              -
            </span>
            <!-- <div class="invisible">(dash)</div> -->
          </div>
          <!-- time end -->
          <form-field :title="$t('timelog.manual.end')">
            <v-timepicker
              v-model="endTime"
              auto-scroll
              :placeholder="timepickerPlaceholder"
              :drop-direction="timeDropDirection"
              hide-clear-button
              input-width="110px"
              input-class="display-time--customized placeholder-gray-600"
              class="vue2-timepicker--custom"
              @input="inputEndTime"
            />
            <p v-if="overlapDate" slot="description">
              {{ overlapDate }}
            </p>
          </form-field>
        </div>
      </form-field>
      <form-field
        class="form-datetime__field"
        :title="$t('timelog.manual.title')"
      >
        <div class="grid grid-cols-2 gap-2">
          <form-field>
            <base-input-text
              v-model="hours"
              inner-class="spend-time--size"
              type="number"
              min="0"
            />
            <p slot="description">{{ $t('timelog.manual.time.hours') }}</p>
          </form-field>
          <form-field>
            <base-input-text
              v-model="minutes"
              inner-class="spend-time--size"
              type="number"
              min="0"
            />
            <p slot="description">{{ $t('timelog.manual.time.minutes') }}</p>
          </form-field>
        </div>
      </form-field>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import FormField from './form-field';

const getDefaultTime = () => ({ HH: null, mm: null });

export default {
  components: {
    FormField,
    SelectDate: () => import('@/components/select/select-date'),
    VTimepicker: () => import('vue2-timepicker')
  },
  props: {
    start: {
      type: Date
    },
    end: {
      type: Date
    },
    timeDropDirection: {
      type: String
    },
    dateModal: Boolean
  },
  data() {
    return {
      startDate: undefined,
      startTime: getDefaultTime(),
      endDate: undefined,
      endTime: getDefaultTime()
    };
  },
  computed: {
    hours: {
      get() {
        if (!this.startDate) return;
        return moment(this.endDate)
          .locale(`${this.$t('notifications.language')}`)
          .diff(this.startDate, 'hours');
      },
      set(val) {
        const start = this.startDate || new Date();
        const end = this.addHourMinute(start, val, this.minutes);
        if (!end) return;
        this.input({ start, end });
      }
    },
    minutes: {
      get() {
        if (!this.startDate) return;
        return (
          moment(this.endDate)
            .locale(`${this.$t('notifications.language')}`)
            .diff(this.startDate, 'minutes') % 60
        );
      },
      set(val) {
        const start = this.startDate || new Date();
        const end = this.addHourMinute(start, this.hours, val);
        if (!end) return;
        this.input({ start, end });
      }
    },
    overlapDate() {
      const mDate = moment(this.endDate).locale(
        `${this.$t('notifications.language')}`
      );
      if (
        mDate.isValid() &&
        !mDate.isSame(
          moment(this.startDate).locale(`${this.$t('notifications.language')}`),
          'day'
        )
      ) {
        return mDate.format('DD MMM YYYY');
      }
      return null;
    },
    timepickerPlaceholder() {
      return `${this.$t('hh')}:${this.$t('mm')}`;
    }
  },
  watch: {
    start: {
      handler(current) {
        this.startDate = current;
        this.startTime = this.getTimesString(current);
      },
      immediate: true
    },
    end: {
      handler(current) {
        this.endDate = current;
        this.endTime = this.getTimesString(current);
      },
      immediate: true
    }
  },
  methods: {
    inputStartTime({ HH, mm }) {
      if (HH && mm) {
        const start = this.getSetTime(this.startDate, {
          HH,
          mm
        });
        const isAfterEnd = moment(start).isAfter(this.endDate);
        if (isAfterEnd) this.input({ start, end: start });
        else this.input({ start });
      }
    },
    inputEndTime({ HH, mm }) {
      if (HH && mm) {
        const end = this.getSetTime(this.endDate, {
          HH,
          mm
        });
        const isBeforeStart = moment(end).isBefore(this.startDate);
        if (isBeforeStart) this.input({ start: end, end });
        else this.input({ end });
      }
    },
    onSelectDate(val) {
      const mEnd = moment(val)
        .set('hour', Number(this.endTime.HH))
        .set('minute', Number(this.endTime.mm));
      const end = mEnd.toDate();
      this.input({ start: val, end });
    },
    addHourMinute(date, hr = '0', min = '0') {
      const mDate = moment(date)
        .locale(`${this.$t('notifications.language')}`)
        .add(parseInt(hr, 10), 'hours')
        .add(parseInt(min, 10), 'minutes');
      return mDate.isValid() ? mDate.toDate() : null;
    },
    getArrayHourMinute(val) {
      const dateStr = new Date(val);
      return val ? [dateStr.getHours(), dateStr.getMinutes()] : [0, 0];
    },
    getTimesString(val) {
      if (!val) return getDefaultTime();
      const times = this.getArrayHourMinute(val)
        .map(e => e.toString())
        .map(e => this.fillZero(e));
      return { HH: times[0], mm: times[1] };
    },
    getSetTime(ref, { HH, mm }) {
      const date = new Date(ref ?? new Date());
      date.setHours(HH);
      date.setMinutes(mm);
      return date;
    },
    fillZero(str = '', expectLen = 2) {
      const totalTimes = expectLen - str.length;
      if (totalTimes < 1) return str;
      return this.fillZero(`0${str}`, expectLen - 1);
    },
    input({ start, end }) {
      if (start) this.updateStart(start);
      if (end) this.updateEnd(end);
    },
    updateStart(start) {
      this.$emit('update:start', start);
    },
    updateEnd(end) {
      this.$emit('update:end', end);
    }
  }
};
</script>

<style lang="scss">
$height: 2.5rem;
$dropdown-position: $height + 0.25rem !important;
.form-datetime__input-height {
  height: $height;
}
.spend-time--size {
  max-width: 76px;
  height: $height;
}
.vue__time-picker {
  input.display-time.display-time--customized {
    @apply px-4 py-2 rounded outline-none relative bg-transparent;
    @apply border border-gray-300 cursor-pointer text-black text-sm;
    height: $height;

    &:focus {
      @apply border-brand-blue;
    }

    &:hover {
      @apply bg-gray-100;
    }
  }
  .dropdown.drop-down {
    top: $dropdown-position;
  }
  .dropdown.drop-up {
    bottom: $dropdown-position;
  }
}
.form-datetime {
  @apply flex flex-wrap;
  &__field:not(:last-child) {
    @apply mr-6;
  }
}
</style>
