import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import TaskShortId from '../views/TaskShortId.vue';
import TaskOpen from '@/views/TaskOpen.vue';
import PageNotFound from '../views/PageNotFound.vue';
import RedirectBoards from '../views/RedirectBoards.vue';
import i18n from '../plugins/i18n';
import { updateMomentLocale, jwtValidate } from './utils';
import boardsChildren from './boards-childrens';
import Locale from '../storages/locale';
import Landing from '@/views/Landing.vue';

Vue.use(VueRouter);

const JUST_ROUTER_VIEW_TEMPLATE = '<router-view></router-view>';

const routes: Array<RouteConfig> = [
  {
    path: '/:locale',
    name: 'Locale-Base',
    component: {
      template: JUST_ROUTER_VIEW_TEMPLATE,
      beforeRouteUpdate(to, _1, next) {
        if (!to.params.locale) {
          next({
            path: '',
            replace: true
          });
        } else {
          updateMomentLocale(to.params.locale as 'th' | 'en');
          next();
        }
      }
    },
    beforeEnter: (to, _, next) => {
      const notFoundLocale = to.fullPath.includes('/:locale');
      const locale = to.params.locale;
      const supportedLocales = ['en', 'th'];
      if (!supportedLocales.includes(locale)) {
        const fallbackLocale =
          process.env.VUE_APP_I18N_LOCALE || Locale.getLocale();
        return next({
          path: fallbackLocale + to.fullPath,
          replace: true
        });
      }
      if (notFoundLocale) {
        return next({
          path: to.fullPath.replace('/:locale', ''),
          replace: true
        });
      }
      if (i18n.locale !== locale) {
        i18n.locale = locale;
      }
      updateMomentLocale(to.params.locale as 'th' | 'en');
      return next();
    },
    children: [
      {
        path: '',
        name: 'Landing',
        component: Landing,
        beforeEnter: (to, from, next) => {
          return jwtValidate(to, from, next);
        }
      },
      // {
      //   path: '',
      //   name: 'Home',
      //   beforeEnter: (to, _, next) => {
      //     next({
      //       path: to.params.locale + '/boards',
      //       query: to.query
      //     });
      //   }
      // },

      {
        path: 'forgot',
        name: 'Forgot',
        component: () => import('../views/Forgot.vue')
      },
      {
        path: 'signup',
        name: 'Signup',
        component: () => import('../views/Signup.vue')
      },
      {
        path: 'boards',
        component: () => import('@/views/Boards.vue'),
        meta: {
          authRequired: true
        },
        beforeEnter: (to, from, next) => {
          return jwtValidate(to, from, next);
        },
        children: boardsChildren
      },
      {
        path: 'terms',
        name: 'Terms',
        component: () => import('@/views/Terms.vue')
      },
      {
        path: 't/:sharelink_id',
        name: 'Task-Sharelink',
        component: TaskShortId
      },
      {
        path: 'tasks/:id',
        name: 'Task-Open',
        component: TaskOpen
      },
      {
        path: ':otpCode/:email/resetPassword',
        name: 'Reset-Password',
        component: () => import('@/views/ResetPassword.vue')
      },
      {
        path: ':code/:email/confirmEmail',
        name: 'ChangeEmail',
        component: () => import('@/views/ChangeEmail.vue')
      },
      {
        path: 'verify',
        name: 'VerifyEmail',
        component: () => import('@/views/ChangeEmail.vue')
      },
      {
        path: 'guest',
        name: 'Guest',
        component: () => import('@/views/Guest.vue')
      },
      {
        path: 'teams/:teamId',
        component: RedirectBoards
      },
      {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound
      }
    ]
  },
  {
    path: '*',
    redirect: {
      name: 'Locale-Base',
      replace: true
    }
  }
];

const router = new VueRouter({
  scrollBehavior(to, from) {
    const routesOriginalPosition = [
      'Board-Backlog-Task',
      'Board-Completed-Sprint-Task'
    ];
    const toRouteName = to.matched[to.matched.length - 1]?.name;
    const fromRouteName = from.matched[from.matched.length - 1]?.name;

    if (
      routesOriginalPosition.some(
        name => name === toRouteName || name === fromRouteName
      ) ||
      toRouteName == fromRouteName
    ) {
      return;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
