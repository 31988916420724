import Vue from 'vue';

type Position = number;

export interface PositionedItem {
  id: number;
  position: Position;
  name: string;
}

export default Vue.extend({
  methods: {
    positionCalc(before: Position, after: Position) {
      // when it just created
      if (!before && !after) return 1;
      // when drop move at top
      if (!before) before = 0;
      // when move to bottom or new item with existed list
      if (!after) after = before * 3;
      return (before + after) / 2;
    },
    getPosition(items: PositionedItem[], index: number) {
      const [beforeItem, afterItem] = [index - 1, index + 1].map(e => items[e]);
      const [beforePos, afterPos] = [beforeItem, afterItem].map(
        e => e?.position
      );
      return this.positionCalc(beforePos, afterPos);
    }
  }
});
