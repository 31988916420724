<template>
  <div class="grid grid-cols-2 gap-4">
    <div>
      <base-input-text
        ref="autofocus"
        v-model.number="hours"
        maxlength="5"
        inner-class="text-center"
        :disabled="disabled"
        @blur="blur"
        @keypress="ignoreKey"
        @keypress.enter.prevent="$event.target.blur()"
        @update="updateHour"
      />

      <div class="unit-label">
        {{ countUnits($t('modelTask.card.time.hours'), hours) }}
      </div>
    </div>
    <div>
      <base-input-text
        v-model.number="minutes"
        inner-class="text-center"
        :disabled="disabled"
        @blur="blur"
        @keypress="ignoreKey"
        @keypress.enter.prevent="$event.target.blur()"
        @update="updateMinute"
      />
      <div class="unit-label">
        {{ countUnits($t('modelTask.card.time.minutes'), minutes) }}
      </div>
    </div>
  </div>
</template>

<script>
import DateTime, { MINUTES_IN_HOUR } from '@/mixins/DateTimeMixin';
import NumberUtils from '@/mixins/NumberUtilsMixin';

export default {
  mixins: [DateTime, NumberUtils],
  props: {
    autofocus: Boolean,
    disabled: Boolean,
    value: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    hours: 0,
    minutes: 0
  }),
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.minutes = this.getRemainingMinutes(val);
        const setHours = val - this.minutes;
        this.hours = this.getHourFromMinutes(setHours);
      }
    }
  },
  computed: {
    valueExcludedMinutes() {
      return this.value - this.getRemainingMinutes(this.value);
    }
  },
  mounted() {
    if (this.autofocus) this.focus();
  },
  methods: {
    ignoreKey(evt) {
      const KEY_E = 101;
      const KEY_MINUS = 45;
      const charCode = evt.which;
      if (charCode === KEY_MINUS || charCode === KEY_E) {
        evt.preventDefault();
      }
    },
    focus() {
      this.$refs.autofocus.focus();
    },
    blur(ev) {
      this.$emit('blur', ev);
    },
    input(val) {
      this.$emit('input', val);
    },
    updateHour() {
      const fullHours = Math.round(this.hours) * MINUTES_IN_HOUR;
      this.input(fullHours + this.minutes);
    },
    updateMinute() {
      const maxValue = Math.min(MINUTES_IN_HOUR - 1, this.minutes);
      const minValue = Math.max(0, maxValue);
      const payload = this.valueExcludedMinutes + minValue;
      this.input(payload);
    }
  }
};
</script>

<style scoped>
.unit-label {
  @apply mt-2 text-gray-caption text-center text-xs;
}
</style>
