import { Module } from 'vuex';
import { RootState } from '@/types/Store';
import {
  CardList,
  SubscriptionPlan,
  CurrentPlan,
  // SelectPlan,
  PaymentHistory,
  PlanSelected,
  Dashboard,
  PlanPreview
} from '@/types/Subscription';
import subscriptionApi from '@/services/apis-subscription';
import router from '@/router';

interface State {
  cardList?: CardList[];
  cardDefault: CardList[];
  currentPlan: CurrentPlan[];
  selectPlan: any[];
  paymentHistory: PaymentHistory[];
  numberMember: number;
  planSelected: PlanSelected[];
  seatMember: any[];
  memberRemove: any[];
  dashboardDetail: any[];
  summaryOverview: any[];
  planInfo: any[];
  myWorkspace: any[];
  planChange: any[];
  planPreview: PlanPreview[];
  seatPreview: PlanPreview[];
  planCancel: any[];
}

const subscriptionModule: Module<State, RootState> = {
  namespaced: true,
  state: {
    cardList: [],
    cardDefault: [],
    currentPlan: [],
    selectPlan: [],
    paymentHistory: [],
    numberMember: 5,
    planSelected: [],
    seatMember: [],
    memberRemove: [],
    dashboardDetail: [],
    summaryOverview: [],
    planInfo: [],
    myWorkspace: [],
    planChange: [],
    planPreview: [],
    planCancel: [],
    seatPreview: []
  },
  getters: {
    getSeatPreview(s) {
      return s.seatPreview;
    },
    getCurrentPlan(s) {
      return s.currentPlan;
    },
    getCardList(s) {
      return s.cardList;
    },
    getCardDefault(s) {
      return s.cardDefault;
    },
    getSelectPlan(s) {
      return s.selectPlan;
    },
    getPaymentHistory(s) {
      return s.paymentHistory;
    },
    getNumberMember(s) {
      return s.numberMember;
    },
    getPlanSelected(s) {
      return s.planSelected;
    },
    getSeatMember(s) {
      return s.seatMember;
    },
    getMemberRemove(s) {
      return s.memberRemove;
    },
    getSummaryOverview(s) {
      return s.summaryOverview;
    },
    getPlanInfo(s) {
      return s.planInfo;
    },
    getMyWorkspace(s) {
      return s.myWorkspace;
    },
    getPlanChange(s) {
      return s.planChange;
    },
    getPlanPreview(s) {
      return s.planPreview;
    },
    getPlanCancel(s) {
      return s.planCancel;
    }
  },
  mutations: {
    SET_PLAN(s, payload) {
      s.currentPlan = payload;
    },
    SET_PLAN_INFO(s, payload) {
      s.planInfo = payload;
    },
    SET_CARDS(s, payload) {
      s.cardList = payload;
      s.cardDefault = payload.filter(
        (card: { is_default_card: boolean }) => card.is_default_card
      )[0];
    },
    SET_SELECTPLAN(s, payload) {
      s.selectPlan = payload;
    },
    SET_PAYMENT_HISTORY(s, payload) {
      s.paymentHistory = payload;
    },
    SET_NUMBER_MEMBER(s, payload) {
      s.numberMember = payload;
    },
    SET_PLAN_SELECT(s, payload) {
      s.planSelected = payload;
    },
    SET_SEAT_MEMBER(s, payload) {
      s.seatMember = payload;
    },
    SET_MEMBER_REMOVE(s, payload) {
      s.memberRemove = payload;
    },
    SET_OVERVIEW_SUMMARY(s, payload) {
      s.summaryOverview = payload;
    },
    SET_MY_WORKSPACE(s, payload) {
      s.myWorkspace = payload;
    },
    SET_CHANGE_PLAN(s, payload) {
      s.planChange = payload;
    },
    SET_PREVIEW_PLAN_SELECT(s, payload) {
      s.planPreview = payload;
    },
    SET_PREVIEW_SEAT_SELECT(s, payload) {
      s.seatPreview = payload;
    },
    SET_PLAN_CANCEL(s, payload) {
      s.planCancel = payload;
    }
  },
  actions: {
    // plan
    async fetchCurrentPlan({ commit }) {
      const res = await subscriptionApi.getSubscriptionCurrentPlan();
      commit('SET_PLAN', res.data);
    },
    subscriptionPlanPreview: async ({ commit }, plan: SubscriptionPlan) => {
      const res = await subscriptionApi.subscriptionPlanPreview(plan);
      commit('SET_PREVIEW_PLAN_SELECT', res.data);
    },
    subscriptionSeatPreview: async (
      { commit },
      numberOfSeats: SubscriptionPlan
    ) => {
      console.log(numberOfSeats);

      const res = await subscriptionApi.seatPreview(numberOfSeats);
      commit('SET_PREVIEW_SEAT_SELECT', res.data);
    },
    async cancelSubscription({ dispatch, commit }, feedback) {
      const res = await subscriptionApi.cancelPlan(feedback);
      dispatch('fetchCurrentPlan');
      commit('SET_PLAN_CANCEL', []);
    },
    subscriptionChangePlan: async ({ dispatch }, plan: SubscriptionPlan) => {
      const res = await subscriptionApi.changeSubscriptionPlan(plan);
      dispatch('fetchCurrentPlan');
    },
    async planInfo({ commit }) {
      const res = await subscriptionApi.getPlansInfo();
      commit('SET_PLAN_INFO', res.data);
    },
    subscriptionPlanConfirm: async (
      { commit, dispatch },
      plan: SubscriptionPlan
    ) => {
      const res = await subscriptionApi.subscriptionConfirm(plan);
      commit('SET_PREVIEW_PLAN_SELECT', []);
      dispatch('fetchCurrentPlan');
    },

    // payment card
    async fetchCards({ commit }) {
      const res = await subscriptionApi.listCard();
      commit('SET_CARDS', res.data);
    },
    saveCards: async ({ commit, dispatch }, tokenId: string) => {
      const token = {
        token_id: tokenId
      };
      const res = await subscriptionApi.saveCard(token);
      dispatch('fetchCards');
    },
    deleteCards: async ({ commit, dispatch }, cardId: string) => {
      const res = await subscriptionApi.deleteCard(cardId);

      dispatch('fetchCards');
    },
    setCardDefault: async ({ commit, dispatch }, cardId: string) => {
      const res = await subscriptionApi.setCardDefault(cardId);

      dispatch('fetchCards');
    },
    async fetchPaymentHistory({ commit }) {
      const res = await subscriptionApi.paymentHistory();
      commit('SET_PAYMENT_HISTORY', res.data);
    },

    //seat
    reduceTotalSeats: async ({ commit, dispatch }, numberOfSeats: number) => {
      const res = await subscriptionApi.reduceTotalSeats(numberOfSeats);
      dispatch('fetchCurrentPlan');
      dispatch('fetchSeatMembers');
    },
    increaseTotalSeats: async ({ commit, dispatch }, numberOfSeats: number) => {
      const res = await subscriptionApi.increaseTotalSeats(numberOfSeats);
      dispatch('fetchCurrentPlan');
      dispatch('fetchSeatMembers');
    },
    inviteMember: async ({ commit, dispatch }, email: string[]) => {
      const emailInvite = { emails: email, returnType: 0 };
      await subscriptionApi.inviteMember(emailInvite);
      dispatch('fetchSeatMembers');
      dispatch('fetchCurrentPlan');
    },
    fetchSeatMembers: async ({ commit, dispatch }, teamIds: any) => {
      const res = await subscriptionApi.getSeatMembers(teamIds);
      commit('SET_SEAT_MEMBER', res.data);
    },
    removeMember: async ({ commit, dispatch }, memberId: number) => {
      await subscriptionApi.removeMember(memberId);
      dispatch('fetchSeatMembers');
      dispatch('fetchCurrentPlan');
    },

    // overview
    fetchDashboard: async ({ commit, dispatch }, dashboard: Dashboard) => {
      const res = await subscriptionApi.getOverview(dashboard);
      commit('SET_OVERVIEW_SUMMARY', res.data);
    },
    clearDashboardOverview({ commit }) {
      commit('SET_OVERVIEW_SUMMARY', []);
    },

    // workspace
    async fetchMyWorkspace({ commit }) {
      const res = await subscriptionApi.getMyWorkspace();
      commit('SET_MY_WORKSPACE', res.data);
    },

    sendNotiSuscriptionOwner: async ({ commit }, id: number) => {
      const res = await subscriptionApi.sendSubscriptionOwner(id);
    }
  }
};

export default subscriptionModule;
