import { NotificationSetting } from '@/types/Notification';
import { UserRole, UserLanguage } from '@/types/User';

interface User {
  role: UserRole;
  language: UserLanguage;
  notificationSetting: NotificationSetting;
}

interface StorageUser {
  getItem(): User | null;
  setItem(u: User): User;
  getRole(): UserRole;
}

const storageUser: StorageUser = {
  getItem() {
    const stringValue = localStorage.getItem('user');
    if (stringValue) return JSON.parse(stringValue);
    return null;
  },
  setItem({ role, language, notificationSetting }) {
    const user = { role, language, notificationSetting };
    localStorage.setItem('user', JSON.stringify(user));
    return user;
  },
  getRole() {
    const user = this.getItem();
    return user?.role || UserRole.COMMON;
  }
};

export default storageUser;
