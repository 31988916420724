














































































































import Vue, { PropType } from 'vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import { BaseSelectOption } from '@/types/BaseComponents';
import IconCheck from '@/components/icons/IconCheck.vue';

const colorClasses = {
  default: '',
  brand: 'bg-brand-blue text-white',
};

type ToggleColor = keyof typeof colorClasses;

export default Vue.extend({
  components: {
    IconChevronDown,
    IconCheck,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    options: {
      type: Array as PropType<BaseSelectOption[]>,
      default: (): BaseSelectOption[] => [],
    },
    value: {
      type: [String, Number],
      default: null,
    },
    position: {
      type: String,
      default: 'bottom-right',
    },
    toggleClasses: {
      type: String,
      default: '',
    },

    toggleColor: {
      type: String as PropType<ToggleColor>,
      default: 'default',
    },
    disabled: Boolean,
    fullWidth: Boolean,
    noEmptyContent: Boolean,
    borderless: Boolean,
    customItem: Boolean,
    placeholder: {
      type: String,
      default: 'Click to select',
    },
    placeholderClasses: {
      type: [Array, String],
      default: 'text-gray-400',
    },
    clearable: Boolean,
    isDotButton: {
      type: Boolean,
      default: false,
    },
    centered: Boolean,
    check: {
      type: Boolean,
      default: false,
    },
    optionClasses: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasNoItem(): boolean {
      return this.options.length < 1;
    },
    active(): BaseSelectOption {
      return (
        this.options.find((e) => e.value === this.value) || {
          value: -1,
          label: '',
        }
      );
    },
    toggleClass(): (string | { [e: string]: boolean })[] {
      const toggleHover = {
        default: 'hover:bg-gray-main',
        brand: 'hover--dark',
      };
      const selectedColorHover = this.disabled
        ? ''
        : toggleHover[this.toggleColor as ToggleColor];
      const selectedColor = colorClasses[this.toggleColor as ToggleColor];
      const baseColors = {
        'cursor-pointer': !this.disabled,
        borderless: this.borderless,
      };
      return [
        baseColors,
        selectedColor,
        selectedColorHover,
        this.toggleClasses,
      ];
    },
    showPlaceholder(): boolean {
      return this.value == null;
    },
  },
});
