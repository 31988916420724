<template>
  <div
    class="base-collapse"
    :class="{ 'base-collapse--collapsed': isCollapsed }"
  >
    <div>
      <slot name="collapser" v-bind="{ isCollapsed, toggle }">
        <collapse-toggle :active="isCollapsed" @click="toggle">
          <div class="flex w-5/6 items-center">
            <v-clamp class="font-medium v-clamp-word-break mr-2" :max-lines="1">
              {{ title }}
            </v-clamp>
            <div
              v-if="title == $t('dashboard.recently') && isBoardList"
              class="text-sm text-blue-700 hover:underline"
            >
              <router-link :to="{ name: 'Recently-Boards' }" target="_blank">{{
                $t('viewMore')
              }}</router-link>
            </div>
          </div>
        </collapse-toggle>
      </slot>
    </div>
    <div v-show="!isCollapsed">
      <slot />
    </div>
  </div>
</template>

<script>
import CollapseToggle from '@/components/collapse/collapse-toggle';
import VClamp from 'vue-clamp';

export default {
  props: {
    collapsed: Boolean,
    title: {
      type: String,
      default: 'Collapser'
    },
    defaultCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapsed: this.defaultCollapsed
    };
  },

  computed: {
    ignoreSelector() {
      return 'collapse--ignore';
    },
    isBoardList() {
      return this.$route.name === 'Boards-List';
    }
  },
  watch: {
    collapsed: {
      handler(val) {
        this.isCollapsed = val;
      },
      immediate: true
    }
  },
  methods: {
    toggle(ev) {
      const element = ev.target;
      if (this.hasIgnoreCollapse(element)) return;
      this.isCollapsed = !this.isCollapsed;
      this.$emit('toggle', this.isCollapsed);
    },
    hasIgnoreCollapse(el) {
      if (!el) return null;
      const ignoreSelector = this.ignoreSelector;
      const parentEl = el.parentElement;
      const isContainedParent = parentEl?.classList.contains(ignoreSelector);
      return isContainedParent
        ? parentEl
        : this.hasIgnoreCollapse(parentEl, ignoreSelector);
    }
  },
  components: {
    CollapseToggle,
    VClamp
  }
};
</script>

<style>
.base-collapse {
  /* @apply px-4; */
}
/* .base-collapse.base-collapse--collapsed:not(:only-of-type):not(:last-of-type) {
  @apply mb-4;
} */
</style>
