import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';
import {
  LoginResponse,
  LoginRequest,
  RegisterRequest,
  PasswordResetRequest,
  ValidateEmailRequest
} from '@/types/Auth';
import { RootState } from '@/types/Store';
import apis from '@/services/apis';
import localJwt from '@/services/local-jwt';
import { User, UserRole } from '@/types/User';
import storageUser from '@/storages/user';
import firebase from '@/services/firebase';

interface State {
  user: User | null;
  guest: Guest | null;
}

interface Guest {
  uuid: string;
  name: string;
  profilePicture: string;
}

const state = (): State => {
  return {
    user: null,
    guest: null
  };
};

const mutations: MutationTree<State> = {
  SET_USER(state: State, payload: User) {
    state.user = payload;
  },
  SET_GUEST(state: State, payload: Guest) {
    state.guest = payload;
  },
  SET_TO(state: State, to: string) {
    localStorage.setItem('to', to);
  }
};

const actions: ActionTree<State, RootState> = {
  async login(_, payload: LoginRequest) {
    localJwt.removeTokens();
    const res = await apis.post<LoginResponse>('/guest/login', payload);
    localJwt.setTokens(res.data);
  },
  async forgot(_, payload: LoginRequest) {
    const res = await apis.post('/guest/forgotPassword', payload);
    return res;
  },
  async resetPassword(_, payload: User) {
    const res = await apis.post('/guest/resetPassword', payload);
    return res;
  },
  async changePassword(_, payload: PasswordResetRequest) {
    const res = await apis.put('/me/changePassword', payload);
    return res;
  },
  async validateEmailChange(_, payload: ValidateEmailRequest) {
    const res = await apis.put('/guest/confirmChangeEmail', payload);
    localJwt.setTokens(res.data);
    return res;
  },
  async verifyEmail(_, email: string) {
    const res = await apis.post('/guest/verify', { email: email });
    localJwt.setTokens(res.data);
    return res;
  },
  async resendVerifyEmail() {
    const res = await apis.post('/guest/verify/email/resend');
    return res;
  },
  async fetchMe({ commit }) {
    const fAuth = firebase.auth();
    try {
      const { data: user } = await apis.get<User>('/me');
      if (fAuth.currentUser === null) {
        await fAuth.signInAnonymously();
      }
      commit('SET_USER', user);
      firebase.analytics().setUserProperties({ name: user?.name });
      firebase.analytics().setUserProperties({ email: user?.email });
      storageUser.setItem(user);
    } catch (error) {
      localJwt.removeTokens();
    }
  },
  async register(_, registration: RegisterRequest) {
    localJwt.removeTokens();
    const res = await apis.post<LoginResponse>('/guest/register', registration);
    localJwt.setTokens(res.data);
  },
  async logout({ dispatch }) {
    const { refreshToken } = localJwt.getTokens();
    dispatch('notifications/stopListenFirestore', null, { root: true });
    await apis.post('/me/logout', { refreshToken });
    firebase.analytics().setUserProperties({ name: '' });
    firebase.analytics().setUserProperties({ email: '' });
    localJwt.removeTokens();
  }
};

const getters: GetterTree<State, RootState> = {
  getUser(state) {
    if (!state.user) return {};
    if (![UserRole.COMMON, UserRole.PRIVILEGE].includes(state.user.role)) {
      return { ...state.user, role: UserRole.COMMON };
    }
    return state.user;
  },
  isUserAppOwner(state) {
    return state.user?.role === UserRole.PRIVILEGE;
  },
  isUser(state) {
    return Boolean(state.user);
  },
  isGuest(state) {
    return Boolean(state.guest);
  },
  getTo() {
    return localStorage.getItem('to');
  }
};

const authModule: Module<State, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};

export default authModule;
