import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBold,
  faItalic,
  faStrikethrough,
  faListOl,
  faListUl,
  faUndo,
  faRedo,
  faChevronDown,
  faSearch,
  faInfoCircle,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faTimesCircle,
  faEllipsisV,
  faExternalLinkAlt,
  faPencilAlt,
  faCalendar as faCalendarSolid,
  faUser,
  faTasks,
  faPaperclip,
  faTags,
  faArrowDown,
  faEllipsisH,
  faCheck,
  faExclamationTriangle,
  faChevronUp,
  faPlay,
  faInfo,
  faUserPlus,
  faArrowLeft,
  faStop,
  faFlask,
  faGripVertical,
  faCompress,
  faSlidersH
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendar,
  faClock,
  faFileAlt,
  faStopCircle,
  faTimesCircle as farTimesCircle
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const chevrons = [faChevronDown, faChevronLeft, faChevronRight, faChevronUp];
const infos = [faInfo, faInfoCircle, faExclamationTriangle];
const music = [faPlay, faStop, faStopCircle];
const arrows = [faArrowLeft, faArrowDown];

library.add(
  ...chevrons,
  ...infos,
  ...arrows,
  ...music,
  faExternalLinkAlt,
  faBold,
  faItalic,
  faStrikethrough,
  faListOl,
  faListUl,
  faUndo,
  faRedo,
  faSearch,
  faPlus,
  faUserPlus,
  faTimes,
  faTimesCircle,
  farTimesCircle,
  faCalendarSolid,
  faCalendar,
  faEllipsisV,
  faEllipsisH,
  faPencilAlt,
  faClock,
  faUser,
  faTasks,
  faPaperclip,
  faTags,
  faCheck,
  faFileAlt,
  faFlask,
  faGripVertical,
  faCompress,
  faSlidersH
);

Vue.component('fa-icon', FontAwesomeIcon);
