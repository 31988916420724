











































































































































import Vue, { PropType } from 'vue';
import { mapActions, createNamespacedHelpers } from 'vuex';
import { Board } from '@/types/Board';
import BoardStar from './board-star.vue';
import BoardHeaderFilter from './board-header-filter.vue';
import EventBus from '@/services/eventBus';
import { boardsApi } from '@/services/apis';
import TabSelect from '@/components/tab/tab-select.vue';
import VueI18n from 'vue-i18n';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import { ApiReturnError } from '@/types/Api';
const { mapGetters } = createNamespacedHelpers('boards');
const { mapGetters: sprintGetters } = createNamespacedHelpers('sprint');
const { mapState } = createNamespacedHelpers('time-log');

interface BoardUpdate {
  description?: string;
  isStarred?: boolean;
}
type Input = Vue & {
  textareaInput: { focus: () => void };
};
export default Vue.extend({
  components: {
    BoardStar,
    BoardHeaderFilter,
    TabSelect,
    IconChevronDown
  },
  props: {
    loading: Boolean,
    tab: {
      type: Number,
      default: 0
    },
    board: {
      type: Object as PropType<Board | null>,
      validator(board: Board) {
        return !!board.id;
      }
    }
  },
  watch: {
    board: {
      handler(board) {
        if (board) this.name = board.name;
      },
      immediate: true
    },
    isEditingBoardName(val) {
      if (val) {
        this.$nextTick(() => {
          this.focusEditBoard();
        });
      }
    },
    workingLog() {
      this.setWidthHeader();
      this.onResize();
    }
  },
  data() {
    return {
      selectTypeReport: ['planning', 'progress'],
      isEditingBoardName: false,
      isEditDescription: false,
      name: '',
      tabIcons: [
        ['tab-board', 'tab-dashboard', 'tab-timeline', 'report-export'],
        [''],
        [
          'tab-active',
          'tab-backlog',
          'tab-completed',
          'tab-timeline',
          'report-export'
        ]
      ],
      headerWidth: 0,
      tabWidth: 0,
      checkWidth: false
    };
  },
  mounted() {
    this.setWidthHeader();
    this.setWidthTab();
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  computed: {
    ...mapState(['workingLog']),
    ...mapGetters(['getCurrentUserMember']),
    ...sprintGetters(['getActiveSprint']),
    exportType(): string {
      return this.$route.params?.type || '';
    },
    isStarred(): boolean {
      return !!this.board?.isStarred;
    },
    description(): string {
      return this.board?.description || '';
    },
    isAdmin(): boolean {
      if (this.getCurrentUserMember) {
        return this.getCurrentUserMember.role === 100;
      }
      return false;
    },
    activeTab: {
      get(): number {
        return this.tab;
      },
      set(val) {
        this.$emit('update:tab', val);
      }
    },
    tabs(): VueI18n.TranslateResult[] {
      const kanbanTabs = [
        'myBoard.board',
        'myBoard.dashboard',
        'myBoard.timeline',
        'report.export'
      ];
      const waterfallTabs = ['waterfall'];
      const agileTabs = [
        'sprint.active',
        'sprint.backlog',
        'sprint.completed',
        'myBoard.timeline',
        'report.export'
      ];
      const allTabs = [kanbanTabs, waterfallTabs, agileTabs];
      if (!this.board) return [];
      return allTabs[this.board.type];
    },
    allowCompleteSprint(): boolean {
      return this.$route.name === 'Board-Sprint' && this.getActiveSprint;
    }
  },
  methods: {
    ...mapActions('boards', ['editBoard', 'toggleBoardStarred']),
    typeReport(type: string, tab: number) {
      this.$emit('tab-changed', tab, type);
    },
    onResize() {
      const MARGIN_HEADER_AND_TAB = 70;
      this.checkWidth =
        this.headerWidth + this.tabWidth + MARGIN_HEADER_AND_TAB >
        window.innerWidth;
    },
    setWidthHeader() {
      const getWidth = this.$refs.headerFilter as HTMLElement;
      const { clientWidth } = getWidth;
      this.headerWidth = clientWidth;
    },
    setWidthTab() {
      const getWidth = this.$refs.tabSelect as HTMLElement;
      const { clientWidth } = getWidth;
      this.tabWidth = clientWidth;
    },
    updateBoard(value: BoardUpdate) {
      if (!this.board) return;
      return this.editBoard({
        id: this.board.id,
        ...value
      });
    },
    editDescription() {
      if (this.isAdmin) {
        this.isEditDescription = !this.isEditDescription;
      }
    },
    async updateDescription(description: string) {
      try {
        await this.updateBoard({
          description
        });
      } catch (error) {
        const { response } = error as ApiReturnError;
        this.$notify({
          type: 'error',
          group: 'app-noti',
          title: `${this.$t('notifications.popup.description')}`,
          text: response?.data.message
        });
      }
      this.isEditDescription = false;
    },
    starBoard() {
      this.toggleBoardStarred(this.board);
    },
    async titleUpdate() {
      this.isEditingBoardName = false;
      if (this.board) {
        if (this.board.name == this.name) return;
        await boardsApi.update(this.board.id, {
          name: this.name
        });
        EventBus.$emit('UPDATE_BOARD', {});
      }
    },
    focusEditBoard() {
      (this.$refs.boardNameInput as Input).textareaInput.focus();
    }
  }
});
