<template>
  <div>
    <p>งานอื่นๆ</p>
    <p class="text-gray-caption">
      งานที่ถูกบันทึกโดยไม่มีการเลือกบอร์ดและงาน
      ดังนั้นงานเหล่านี้จึงนับว่าเป็นงานอื่นๆ
    </p>

    <div class="w-full mb-4 mt-4">
      <div class="text-sm font-normal text-left">
        <div class="text-sm text-gray-caption mb-2 text-right flex px-4">
          <div class="text-left w-8/12">หัวข้องาน</div>
          <div class="w-2/12">วันเริ่ม/สิ้นสุด</div>
          <div class="w-2/12">บันทึกเวลาทั้งหมด</div>
        </div>
        <div class="border text-right rounded px-4">
          <div
            v-for="(task, index) in tasks"
            :key="index"
            class="flex"
            :class="{ 'border-b': index < tasks.length - 1 }"
          >
            <div class="align-top py-2 text-left w-8/12">
              {{ task.description || '-' }}
            </div>
            <div class="align-top py-2 w-2/12">
              {{ formatShowDate(task.startAt, task.endAt) }}
            </div>
            <div class="align-top py-2 w-2/12">
              {{ time(task.TimeSpent) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormatStartEndDate from '@/mixins/FormatStartEndDate.ts';
import FormatTimeSpent from '@/mixins/FormatTimeSpent.ts';

export default {
  mixins: [FormatStartEndDate, FormatTimeSpent],
  components: {},
  props: { tasks: [] },
  data() {
    return {
      boardFilter: [],
      groupBord: []
    };
  }
};
</script>
