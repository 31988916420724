
























































































































































































































































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import VClamp from 'vue-clamp';
import { AxiosError } from 'axios';

import { TaskFile } from '@/types/Task';
import { FileType } from '@/types/File';
import { ErrorResponse } from '@/types/Api';

import IconHeaderAdd from '@/components/icon-header-add.vue';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import DragFile from '@/components/icons/DragFile.vue';

import TaskFileDrag from '@/components/modal/modal-task-file-drag.vue';

import ModalPreview from '@/components/modal/modal-preview.vue';

const { mapActions } = createNamespacedHelpers('tasks');

interface UploadFile {
  (file: File): void;
}

interface WithUrlFile extends TaskFile {
  url: string;
}

@Component({
  components: {
    IconHeaderAdd,
    DialogConfirm,
    VClamp,
    DragFile,
    TaskFileDrag,
    ModalPreview
  },
  methods: mapActions(['addAttachment', 'removeAttachment'])
})
export default class ModalTaskAttachments extends Vue {
  addAttachment!: (files: FileList | File[]) => void;
  removeAttachment!: (fileID: number) => void;

  @Prop({ default: () => [], type: Array }) files!: TaskFile[];
  @Prop({ type: Boolean }) readonly disabled?: boolean;

  @Ref('input') readonly input!: HTMLInputElement;

  fileToDelete: WithUrlFile | null = null;
  viewFile: WithUrlFile | null = null;
  viewFileID = `${Math.random()}`;
  uploading = false;
  showOverView = false;

  get withUrlFiles(): WithUrlFile[] {
    const mapUrlToFile = ({ file, ...taskFile }: TaskFile): WithUrlFile => {
      let url = '';
      switch (file.typeUpload) {
        case FileType.Image:
          url = file.originalImageUrl ?? '';
          break;
        case FileType.Video:
        case FileType.Document:
          url = file.fileUrl ?? '';
          break;
        default:
          break;
      }
      return {
        ...taskFile,
        file,
        url
      };
    };
    return this.files.map(mapUrlToFile);
  }

  protected confirmRemove() {
    const removeAttachment = async (file: WithUrlFile) => {
      await this.removeAttachment(file.fileID);
      this.fileToDelete = null;
    };
    if (this.fileToDelete) removeAttachment(this.fileToDelete);
  }

  private async onInput(target: HTMLInputElement) {
    const uploadFiles: File[] = [];
    const fileList = (target as HTMLInputElement).files as FileList;
    this.toggleLoad();
    try {
      await Promise.all(
        Array.from(fileList).map(async file => {
          const item = this.isFileImage(file)
            ? await this.loadImage(file)
            : file;
          uploadFiles.push(item);
        })
      );
      await this.addAttachment(uploadFiles);
      /*
       * *  Hacking: setting value = '' will help Safari to detech @change
       * *  when upload the same file as bug NEDU-823
       */
      this.input.value = '';
    } catch (error) {
      console.error(error);
      // this.notifyUploadError(error);
    }
    this.toggleLoad();
  }

  notifyUploadError(error: AxiosError<ErrorResponse> | string) {
    this.$notify({
      group: 'app-noti',
      title: `${this.$t('notifications.popup.during')}`,
      text: typeof error === 'string' ? error : error.response?.data.message,
      type: 'error'
    });
  }

  private promptDelete(file: WithUrlFile) {
    this.fileToDelete = file;
    this.$modal.show('confirm-delete');
  }

  private confirmClose() {
    this.$modal.hide('confirm-delete');
  }

  async downloadAttachment(file: WithUrlFile, index: number) {
    if (!file.url) return;

    let interval = 0;
    const million = Math.pow(10, 6);
    const fileSize = file.file.fileSize;
    const forceStop = () => {
      clearInterval(interval);
      this.uploading = false;
    };
    const loadingLargeFile = () => {
      let working = true;
      let sec = 0;
      const sparedTime = (fileSize / million) * 2; // 1Mb = 1s
      const uploading = () => {
        this.uploading = true;
        working = false;
      };

      const handleUploading = () => {
        if (working) uploading();
        else if (sec > sparedTime) forceStop();
        else sec += 1;
      };

      interval = setInterval(handleUploading, 500);
    };

    if (fileSize > million) loadingLargeFile();

    const res = await fetch(this.fileDownload(file)).then(e => e.blob());
    forceStop();
    const a = (this.$refs.downloadA as HTMLAnchorElement[])[index];
    const url = URL.createObjectURL(res);
    const fileName = file.file.fileName || 'Download';
    a.href = url;
    a.download = fileName;

    //  that releases the object URL after the element has been clicked
    // This is required for one-off downloads of the blob content
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };

    // Add the click event listener on the anchor element
    // Comment out this line if you don't want a one-off download of the blob content
    a.addEventListener('click', clickHandler, false);

    a.click();
  }

  openNewTab(url: string) {
    window.open(url, '__blank');
  }

  private toggleViewFile(file: WithUrlFile) {
    const self = this;
    const {
      file: { typeUpload, fileUrl }
    } = file;

    const isDocument = typeUpload === FileType.Document;

    if (isDocument) return this.openNewTab(fileUrl as string);
    this.viewFile = file;

    setTimeout(() => {
      self.$modal.toggle(`view-file-${self.viewFileID}`);
    }, 100);
  }

  toggleLoad() {
    this.uploading = !this.uploading;
  }

  @Watch('uploading')
  onUploading(curr: boolean) {
    if (curr) this.$modal.show('uploading');
    else this.$modal.hide('uploading');
  }

  public toggle() {
    this.input.click();
  }

  // file filters

  thumbnail = ({ file }: WithUrlFile): string =>
    file.thumbnailImageUrl || file.fileUrl || '';
  fileName = ({ file }: WithUrlFile): string => file.fileName;
  fileTitle = ({ file }: WithUrlFile): string =>
    file.fileName.replace(/\.\w+$/i, '').replace(/\W/gi, ' ');
  fileSize = ({ file }: WithUrlFile): string => {
    if (!file.fileSize) return '0 Bytes';
    const bytes = file.fileSize;
    const DECIMALS = 2;
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = DECIMALS < 0 ? 0 : DECIMALS;
    const sizes = ['Bytes', 'KB', 'MB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  fileDownload = ({ fileID }: WithUrlFile): string => `/api/v1/file/${fileID}`;
  creatorName = ({ createdByUser }: WithUrlFile): string => createdByUser.name;
  formatUploadedAt = ({ createdAt }: WithUrlFile): string => {
    const mDate = moment(createdAt).locale(
      `${this.$t('notifications.language')}`
    );
    const isPassOneDay =
      mDate.diff(
        moment().locale(`${this.$t('notifications.language')}`),
        'days'
      ) > 1;
    if (isPassOneDay) return mDate.format('MMM DD, YYYY');
    return mDate.fromNow();
  };
  detailedUploadedAt = ({ createdAt }: WithUrlFile): string =>
    moment(createdAt)
      .locale(`${this.$t('notifications.language')}`)
      .format('LLLL');
  fileExtension = ({ file }: WithUrlFile) =>
    file.fileName.split('.').reverse()[0] || '';
  fileTypeTitle = (e?: WithUrlFile): string => {
    if (!e) return '';
    const { typeUpload } = e.file;
    return FileType[typeUpload].toLowerCase();
  };
  isVideo = ({ file }: WithUrlFile): boolean => {
    return file.typeUpload === FileType.Video;
  };
  isFileImage(file: File) {
    return file.type.includes('image');
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName);
  };

  async loadImage(fileImage: File): Promise<File> {
    return window
      .loadImage(fileImage, {
        canvas: true,
        orientation: true,
        meta: true,
        pixelRatio: window.devicePixelRatio
      })
      .then(res => {
        const { image } = res;
        return new Promise((resolve, reject) => {
          if ('type' in image && image.type === 'error') return reject(res);
          image.toBlob(
            blob => {
              resolve(this.blobToFile(blob as Blob, fileImage.name));
            },
            fileImage.type,
            1
          );
        });
      });
  }
}
