<template>
  <div
    class="h-auto px-4 md:pl-8 md:pr-4 sidebar border-gray-border"
    :class="{ block: forceShow, 'hidden md:block border-r': !forceShow }"
  >
    <ul
      v-for="sidebar in sidebars"
      :key="sidebar.key"
      class="list-none py-2 mt-6"
    >
      <li
        v-for="sidebarChild in sidebar.children"
        :key="sidebarChild.key"
        class="sidebar__item"
        :class="{
          'sidebar__item--active': sidebarChild.key === activeSidebar
        }"
      >
        <router-link
          v-if="sidebarChild.link"
          :data-testid="`${sidebarChild.name}-btn`"
          :to="{ name: sidebarChild.key }"
        >
          <base-icon
            view-box="0 0 24 24"
            width="24"
            class="mr-2"
            style="margin-bottom: 2px;"
            :color="iconActive(sidebarChild.key)"
          >
            <component v-bind:is="sidebarChild.icon" />
          </base-icon>
          <span>{{ sidebarChild.name }}</span>
        </router-link>
        <div v-else class="px-4 py-2 outline-none" @click="sidebarChild.click">
          <base-icon
            view-box="0 0 20 24"
            class="mr-2"
            style="margin-top: 5px;"
            :color="iconActive(sidebarChild.key)"
          >
            <component v-bind:is="sidebarChild.icon" />
          </base-icon>
          <span> {{ sidebarChild.name }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import IconOverview from '@/components/icons/IconOverview';
import IconWorkspace from '@/components/icons/IconWorkspace';
import IconPayment from '@/components/icons/IconPayment';
import IconSeatManagement from '@/components/icons/IconSeatManagement';

export default {
  components: { IconOverview, IconWorkspace, IconPayment, IconSeatManagement },
  props: {
    forceShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sidebars() {
      const commonUserMenus = {
        key: 'common',
        children: [
          {
            name: this.$t('sidebar.overview'),
            link: '/',
            key: 'Subscription-Overview',
            icon: 'IconOverview'
          },
          {
            name: this.$t('sidebar.workspace'),
            link: '/space',
            key: 'Subscription-Space',
            icon: 'IconWorkspace'
          },
          {
            name: this.$t('sidebar.payment'),
            link: '/payment',
            key: 'Subscription-Payment',
            icon: 'IconPayment'
          },
          {
            name: this.$t('sidebar.seatmanagement'),
            link: '/seatmanagement',
            key: 'Subscription-SeatManagement',
            icon: 'IconSeatManagement'
          }
        ]
      };

      if (this.isUserAppOwner) return [commonUserMenus];
      return [commonUserMenus];
    },
    activeSidebar() {
      return this.$route.name;
    }
  },
  methods: {
    iconActive(sidebar) {
      return sidebar === this.activeSidebar ? '#168AE2' : '#000000';
    }
  }
};
</script>

<style scoped>
.sidebar__item a {
  @apply px-4 py-2 rounded-lg;
}
.sidebar__item:hover {
  @apply bg-gray-400 cursor-pointer;
  @apply rounded;
}
.sidebar__item:not(:last-of-type) {
  @apply mb-3;
}
.sidebar_team_item:not(:last-of-type) {
  @apply mb-0;
}
.sidebar__item--active {
  @apply bg-gray-200;
  @apply rounded text-brand-blue;
}
.sidebar__item a {
  display: block;
}
.sidebar__header {
  @apply text-sm uppercase mb-2;
  color: #5e6c84;
}
.sidebar {
  height: calc(100vh - 65px);
}
</style>

<i18n>
{
  "en": {
    "sidebar":{
      "overview": "Overview",
      "workspace": "Workspace",
      "payment":"Payment",
      "seatmanagement":"Seat Management"
    }
  },

  "th": {
    "sidebar":{
      "overview": "ภาพรวม",
      "workspace": "เวิร์คสเปซ",
      "payment":"การชำระเงิน",
      "seatmanagement":"การจัดการที่นั่ง"
    }
  }
}
</i18n>
