







































import Vue from 'vue';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import MarkdownTextMixin from '@/mixins/MarkdownText';
import apis from '@/services/apis';
import { TaskActivity, TaskActivityResponse } from '@/types/Task';
import HumanDate from '@/components/date/human-date.vue';

export default Vue.extend({
  mixins: [DateTimeMixin, MarkdownTextMixin],
  props: {
    taskId: {
      type: Number,
      default: 0
    }
  },
  components: {
    HumanDate
  },
  data() {
    return {
      activities: [] as TaskActivity[],
      page: 1,
      pageInformation: {
        page: 1,
        lastPage: 1
      }
    };
  },
  computed: {
    currentPage(): number {
      return this.pageInformation.page;
    },
    lastPage(): number {
      return this.pageInformation.lastPage;
    }
  },
  watch: {
    page: {
      handler() {
        this.fetchActivities();
      },
      immediate: true
    }
  },
  methods: {
    async fetchActivities() {
      const {
        data: { pageInformation, entities }
      } = await apis.get<TaskActivityResponse>(
        `tasks/${this.taskId}/activityLogs`,
        {
          params: {
            page: this.page,
            shareLinkCode: this.$route.params.linkId
          }
        }
      );
      this.activities.push(...entities);
      this.pageInformation = pageInformation;
    },
    loadmore(): void {
      this.page++;
    }
  }
});
