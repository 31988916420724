<template>
  <div class="flex items-center">
    <div v-for="(item, index) in tabs" :key="`tab-${index}`">
      <slot
        v-if="isDropdownTab"
        name="dropdown"
        v-bind:tab="{ item, index, active: index === value }"
      />
      <button v-else class="inline-block" @click="changeTab(index)">
        <slot v-bind:tab="{ item, index, active: index === value }" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: null
    },
    isDropdownTab: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeTab(tab) {
      this.$emit('input', tab);
    }
  }
};
</script>

<style>
.tab-select {
  @apply flex items-center;
}
</style>
