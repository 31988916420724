<template>
  <div class="">
    <div
      :class="open ? ' bg-gray-900 opacity-25 bg-open' : 'bg-close'"
      class="absolute top-0 left-0 h-screen w-screen"
      @click="closeClick"
    />

    <nav
      :class="open ? 'navbar-open' : 'navbar-close'"
      class="navbar w-64 max-w-full absolute overflow-y-scroll bg-white top-0 h-screen"
    >
      <div>
        <slot />
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeClick() {
      this.$emit('change', false);
    }
  }
};
</script>

<style scoped>
.navbar {
  transition: all 330ms ease-out;
}

.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}

.bg-open {
  display: block;
}
.bg-close {
  display: none;
}
</style>
