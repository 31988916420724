<template>
  <div
    ref="BoardColumns"
    class="flex-1 overflow-hidden max-w-screen pb-2"
    :style="styles"
  >
    <header-task-filters
      :placeholder="$t('myTasks.search')"
      :search="{ label: false, width: 295 }"
      class="py-0 px-8"
      @loading-task="loadingTask"
    />
    <div v-if="loading" class="has-centered-item h-full ">
      <base-spinner size="40" />
    </div>
    <board-column-drag-list v-else />
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MembersVisible from '@/mixins/MembersVisible';
import BoardColumnDragList from '@/components/board-column/board-column-drag-list';
import HeaderTaskFilters from '@/components/header/header-task-filters.vue';

export default {
  mixins: [MembersVisible],
  components: {
    BoardColumnDragList,
    HeaderTaskFilters
  },
  data() {
    return {
      height: 0,
      loading: false
    };
  },
  computed: {
    styles() {
      return {
        'max-height': `${this.height}px`
      };
    },
    activeBoard() {
      return this.$store.getters['boards/getActiveBoard'];
    }
  },
  watch: {
    activeBoard(b) {
      // check trashed_at archived_at
      const self = this;
      setTimeout(function() {
        const routerName = self.$route.name;
        if (
          routerName === 'Board-Columns' &&
          (b.trashed_at != null || b.archived_at != null)
        ) {
          self.$notify({
            title: this.$t('notifications.popup.service'),
            text:
              '<p class="text-base">Sorry, this board is archived or deleted</p>',
            type: 'error',
            group: 'board-error',
            duration: 8000
          });
          self.$router.push({ name: 'Boards-List' });
        }
      }, 300);
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.onResize();
    });
    window.addEventListener('resize', this.onResize);
  },
  beforeRouteLeave(_to, _form, next) {
    this.resetTaskFilters();
    next();
  },
  methods: {
    ...mapActions({
      resetTaskFilters: 'resetTaskFilters'
    }),
    onOpenTaskCopy(props) {
      props.close();
      this.$modal.hide('task-detail');
      setTimeout(() => {
        this.$router.push({
          name: 'Board-Columns-Task',
          params: { task_id: props.item.data.task.id }
        });
      }, 500);
    },
    onResize() {
      try {
        const { top } = this.$refs.BoardColumns.getBoundingClientRect();
        this.height = window.innerHeight - top;
      } catch (error) {
        //
      }
    },
    loadingTask(e) {
      this.loading = e;
    }
  }
};
</script>

<style></style>
