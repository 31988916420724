<template>
  <div class="flex z-0">
    <base-dropdown
      ref="selectDateDropdown"
      max-width="429"
      class="flex"
      :class="{ 'w-full': isTeamReport }"
      position="bottom-right"
    >
      <template #toggle>
        <base-button :color="color" class="w-full" wide="px-3" justify="start">
          <span class="flex">
            <div v-if="!isOverview">
              <span v-if="selectTabDate === 'This Week'">
                {{ $t(`options.this_week`) }}
              </span>
              <span v-if="selectTabDate !== 'This Week'">
                {{ selectTabDate }}
              </span>
            </div>

            <span v-if="range.start !== null">
              ({{ formatShowDate.start }}
              -
              {{ formatShowDate.end }})</span
            >
          </span>
          <div class="toggle-icon inline-flex has-centered-item px-2">
            <base-icon
              name="select-toggle"
              size="16"
              :class="{
                'absolute right-0 mr-5': isTeamReport
              }"
            >
              <icon-chevron-down />
            </base-icon>
          </div>
        </base-button>
      </template>

      <template #default>
        <slot>
          <div class="flex h-52">
            <div class="w-32 border-r-2 text-left">
              <div
                v-for="(option, index) in selectOptions"
                :key="index"
                :class="{
                  'bg-brand-blue text-white': selectDate === option.value
                }"
                class="my-3"
                @click="chooseOptionDate(option.value, option.label)"
              >
                <button class="mx-4 text-sm">{{ option.label }}</button>
              </div>
            </div>
            <div class="w-5/6">
              <v-date-picker
                v-if="selectTabDate !== 'Custom'"
                ref="calendars"
                v-model="range"
                :available-dates="{ start: range.start, end: range.end }"
                class="border-0"
                title-position="left"
                is-range
                is-expanded
                v-bind="datePickerAttrs"
                :locale="$t('notifications.language')"
                @drag="changeDate($event)"
              />
              <div v-else>
                <v-date-picker
                  ref="calendars"
                  v-model="range"
                  class="border-0"
                  title-position="left"
                  is-range
                  is-expanded
                  :model-config="modelConfig"
                  v-bind="datePickerAttrs"
                  :locale="$t('notifications.language')"
                  @drag="changeDate($event)"
                />
              </div>
            </div>
          </div>
        </slot>
      </template>
      <template #footer>
        <div class="flex justify-between items-center">
          <p v-if="range">
            <span>
              {{ range.start | moment_format('ddd DD MMM') }}
              -
              {{ range.end | moment_format('ddd DD MMM') }}
            </span>
            <span class="text-gray-500">
              <span>
                {{ diffDateRange }}
              </span>
            </span>
          </p>
          <span />
          <div>
            <base-button wide="px-5" @click="pickDateRange()">
              {{ $t('timelog.manual.done') }}</base-button
            >
          </div>
        </div>
      </template>
    </base-dropdown>
    <div v-if="isSelectTime">
      <div class="ml-5">
        <base-select
          v-model="typeTime"
          class="flex-shrink-0 bg-white inline-flex"
          :options="selectOptionsTime"
          @change="$emit('changed', $event)"
        >
          <template #toggle="{ active }">
            <div class="h-6 flex items-center">
              <div class="pl-2 text-base">
                {{ active.label }}
              </div>
            </div>
          </template>
        </base-select>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  props: {
    isSelectTime: {
      type: Boolean,
      default: false
    },
    defaultDateThisWeekCalendar: Object,
    color: {
      type: String,
      default: 'brand'
    },
    isTeamReport: {
      type: Boolean,
      default: false
    },
    selectDateFromTeam: {
      type: Number,
      default: 3
    },
    selectTabDateFromTeam: {
      type: String,
      default: 'This Week'
    },
    isOverview: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VDatePicker,
    IconChevronDown
  },
  data() {
    return {
      range: {
        start: this.defaultDateThisWeekCalendar.start,
        end: this.defaultDateThisWeekCalendar.end
      },
      selectDate: this.selectDateFromTeam,
      selectTabDate: this.selectTabDateFromTeam,
      typeTime: 1,
      selectOptionsTime: [
        { label: this.$t('estimate'), value: 1 },
        { label: this.$t('timelog'), value: 2 }
      ],
      selectOptions: [
        { label: this.$t('options.today'), value: 1 },
        { label: this.$t('options.yesterday'), value: 2 },
        { label: this.$t('options.this_week'), value: 3 },
        { label: this.$t('options.last_week'), value: 4 },
        { label: this.$t('options.this_month'), value: 5 },
        { label: this.$t('options.last_month'), value: 6 },
        { label: this.$t('options.custom'), value: 7 }
      ],
      modelConfig: {
        start: {
          timeAdjust: '00:00:00'
        },
        end: {
          timeAdjust: '23:59:59'
        }
      }
    };
  },
  computed: {
    datePickerAttrs() {
      return {
        attributes: [
          {
            key: 'today',
            dot: 'orange',
            popover: {
              label: 'Today',
              fillMode: 'light'
            },
            dates: new Date()
          }
        ],
        theme: {
          container: {
            light: 'vc-text-gray-900 vc-bg-white'
          }
        }
      };
    },
    formatShowDate() {
      const formatDateStart = f => moment(this.range.start).format(f);
      const formatDateEnd = e => moment(this.range.end).format(e);

      const monthStart = formatDateStart('MM');
      const monthEnd = formatDateEnd('MM');
      const yearStart = formatDateStart('YYYY');
      const yearEnd = formatDateEnd('YYYY');
      if (yearStart === yearEnd) {
        if (monthStart === monthEnd) {
          return {
            start: formatDateStart('DD'),
            end: formatDateEnd('DD MMM YYYY')
          };
        } else {
          return {
            start: formatDateStart('DD MMM'),
            end: formatDateEnd('DD MMM YYYY')
          };
        }
      } else {
        return {
          start: formatDateStart('DD MMM YYYY'),
          end: formatDateEnd('DD MMM YYYY')
        };
      }
    },
    diffDateRange() {
      if (this.range.start !== null) {
        const end = moment(this.range.end);
        const start = moment(this.range.start);
        const diffDay = end.diff(start, 'days');
        return `(${this.$tc('report.date', diffDay + 1)})`;
      } else return '';
    }
  },
  methods: {
    changeDate(e) {
      this.range.start = e.start;
      this.selectDate = 7;
      this.selectTabDate = this.$t('options.custom');
    },
    pickDateRange() {
      const dropdown = this.$refs.selectDateDropdown;
      this.range.start === null
        ? ''
        : this.$emit('datePick', {
            dateRange: this.range,
            selectDate: this.selectDate,
            selectTabDate: this.selectTabDate
          });

      dropdown.toggleDropdown();
    },
    chooseOptionDate(value, label) {
      this.selectDate = value;
      this.selectTabDate = label;
      const today = new Date();
      const formatPastDate = s =>
        moment()
          .add(-s, 'days')
          .toDate();

      const month = moment().format('MM');
      const year = moment().format('YYYY');

      const endOfLastMonth = s =>
        moment()
          .subtract(s, 'months')
          .endOf('month')
          .format('DD');

      const weekStartAt = s =>
        moment()
          .subtract(s, 'weeks')
          .startOf('week')
          .toDate();
      const weekEndAt = s =>
        moment()
          .subtract(s, 'weeks')
          .endOf('week')
          .toDate();

      const getDate = {
        1: {
          start: moment().toDate(),
          end: today
        },
        2: {
          start: formatPastDate(1),
          end: formatPastDate(1)
        },
        3: {
          start: weekStartAt(0),
          end: weekEndAt(0)
        },
        4: {
          start: weekStartAt(1),
          end: weekEndAt(1)
        },
        5: {
          start: new Date(year, month - 1, 1),
          end: new Date(year, month - 1, endOfLastMonth(0))
        },
        6: {
          start: new Date(year, month - 2, 1),
          end: new Date(year, month - 2, endOfLastMonth(1))
        },
        7: {
          start: null,
          end: null
        }
      };

      this.range = getDate[value];
      this.$refs.calendars.$refs.calendar.showPageRange({
        from: this.range.start
      });
    }
  }
};
</script>

<style></style>
<i18n>
{
  "en": {
    "options": {
      "today": "Today",
      "yesterday": "Yesterday",
      "this_week": "This Week",
      "last_week": "Last Week",
      "this_month": "This Month",
      "last_month": "Last Month", 
      "custom": "Custom"
    },
    "estimate": "Estimate Time",
    "timelog": "Time Log",
    "days" : "days"
  },
  "th": {
     "options": {
      "today": "วันนี้",
      "yesterday": "เมื่อวาน",
      "this_week": "สัปดาห์นี้",
      "last_week": "สัปดาห์ที่แล้ว",
      "this_month": "เดือนนี้",
      "last_month": "เดือนที่แล้ว", 
      "custom": "กำหนดเอง"
    },
    "estimate": "เวลาจากการประเมิณ",
    "timelog": "เวลาจากการบันทึก",
    "days" : "วัน"
  }
}
</i18n>
