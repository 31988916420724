import moment, { RelativeTimeSpec } from 'moment';
import { MetaInfoComputed } from 'vue-meta/types/vue-meta';
import { NavigationGuard } from 'vue-router';
import { UserRole } from '@/types/User';
import storageUser from '@/storages/user';
import i18n from '../plugins/i18n';
import localJwt from '@/services/local-jwt';

interface RelativeTimeMap {
  th: RelativeTimeSpec;
  en: RelativeTimeSpec;
}

const momentRelativeTimes: RelativeTimeMap = {
  th: {
    future: 'ใน %s',
    past: '%s ที่ผ่านมา',
    s: '1 วินาที',
    ss: '%d วินาที',
    m: '1 นาที',
    mm: '%d นาที',
    h: '1 ชั่วโมง',
    hh: '%d ชั่วโมง',
    d: '1 วัน',
    dd: '%d วัน',
    M: '1 เดือน',
    MM: '%d เดือน',
    y: '1 ปี',
    yy: '%d ปี'
  },
  en: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
};

export const updateMomentLocale = (locale: 'th' | 'en') => {
  moment.updateLocale(locale, {
    relativeTime: momentRelativeTimes[locale]
  });
};

export const getAppLinkMeta: MetaInfoComputed = function(this: Vue) {
  const getAppLink = (text: string) => {
    const starterTexts = ['app-id=1529647285'];
    if (text) {
      starterTexts.push('app-argument=workkami://board/' + text);
    }
    return starterTexts.join(', ');
  };

  const boardId = this.$route.params.id || '';
  return {
    meta: [
      {
        property: 'app-link',
        vmid: 'app-link',
        name: 'apple-itunes-app',
        content: boardId,
        template: getAppLink
      }
    ]
  };
};

const handleRouteWithAccessibleRoles: NavigationGuard<Vue> = (to, _, next) => {
  if (!to.meta?.accessibleRoles) return next();
  const accessibleRoles: UserRole[] = to.meta.accessibleRoles || [];
  const userRole = storageUser.getRole();
  const privilegeUser = accessibleRoles.includes(userRole);
  if (privilegeUser) next();
  else next({ path: `/${i18n.locale}/boards` });
};

// router.beforeEach((to, _, next) => {
export const jwtValidate: NavigationGuard<Vue> = (to, _, next) => {
  const isLoggedIn = localJwt.existed();
  const homePage = to.matched[1];
  const isUnauthToAuthPage = homePage.meta.authRequired && !isLoggedIn;
  const toLinkPage = to.name === 'Boards-Link-Task';
  const isNotOpenLinkPageWithUUID = !toLinkPage && localJwt.hasUUID();
  if (!homePage) {
    return next();
  }
  const params = { locale: i18n.locale };

  if (isUnauthToAuthPage || isNotOpenLinkPageWithUUID) {
    const loginTo = to.query.to ?? to.fullPath;
    next({ name: 'Landing', query: { to: loginTo }, params });
  } else if (to.name === 'Landing' && isLoggedIn) {
    next({
      name: 'Boards-List',
      params
    });
  } else {
    handleRouteWithAccessibleRoles(to, _, next);
  }
};
