<template>
  <v-popover
    placement="bottom-start"
    popover-arrow-class="tooltip-arrow popover-arrow invisible"
  >
    <button class="text-sm text-gray-600">
      {{ $t('personalReport.tag.moreTag') }}
      <base-icon name="chevron-down" size="10" color="#7A869A">
        <icon-chevron-down />
      </base-icon>
    </button>
    <div slot="popover" class="popover">
      <div v-for="board in boards" :key="board.id" class="pt-2 px-4">
        <p class="text-sm font-medium truncate">
          {{ board.Name }}
        </p>
        <div
          class="grid grid-cols-12 font-medium text-xs uppercase py-1 font-grey"
        >
          <div class="col-span-6">
            <p class="uppercase">{{ $t('personalReport.tag.tagName') }}</p>
          </div>
          <div class="col-span-6 text-right">
            <p class="uppercase">{{ $t('personalReport.tag.numberOfTask') }}</p>
          </div>
        </div>
        <hr class="mb-2" />
        <div v-for="tag in board.Tags" :key="tag.id" class="flex mb-2">
          <div class="self-end" style="min-width: 40%">
            <div
              :style="{ 'background-color': tag.Color }"
              class="tag text-white"
            >
              <p class="truncate text-center" :class="{ invisible: !tag.Name }">
                {{ tag.Name || '(empty)' }}
              </p>
            </div>
          </div>
          <div class="w-full text-right text-xs font-grey">
            <p>{{ tag.TotalTasks }}</p>
          </div>
        </div>
      </div>
    </div>
  </v-popover>
</template>
<script>
import IconChevronDown from '@/components/icons/IconChevronDown';

export default {
  props: {
    boards: {}
  },
  components: {
    IconChevronDown
  }
};
</script>
<style scoped lang="scss">
.popover {
  @apply relative overflow-y-auto;
  max-height: 383px;
  width: 278px;
}
.font-grey {
  color: #7a869a;
}
</style>
