<template>
  <div>
    <base-button
      :disabled="disabled"
      full
      href="#"
      color="bt-google"
      icon="Google"
      :icon-size="26"
      data-testid="google-btn"
      @click="logInWithGoogle"
      >Login with Google</base-button
    >

    <!-- <GoogleLogin
      ref="btGoogleLogin"
      class="hide"
      :params="{
        client_id:
          '827661701883-6i99sh9efmi6163hihigibd6dng18ipi.apps.googleusercontent.com',
        ux_mode: 'redirect'
      }"
      :onSuccess="loginGoogleSuccess"
      :onFailure="loginGoogleFailure"
    ></GoogleLogin> -->
  </div>
</template>

<script>
// import GoogleLogin from 'vue-google-login';
import firebase from '@/services/firebase';
import apis from '../services/apis';

const STORAGE_BUSY = 'is-google-login-busy';
const IS_BUSY = 'on';
const IS_FREE = '';

export default {
  inheritAttrs: false,
  components: {},
  props: {
    disabled: Boolean
  },
  created() {
    this.checkLogin();
  },
  computed: {
    callbackUrl() {
      return `${window.location.protocol}//${window.location.hostname}/${this.$i18n.locale}`;
    },
    clientId() {
      return '827661701883-6i99sh9efmi6163hihigibd6dng18ipi.apps.googleusercontent.com';
    }
  },
  methods: {
    getUrlParameter(url, name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(url);
      return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    async checkLogin() {
      // const id_token = this.getUrlParameter(this.$route.fullPath, 'id_token');
      // if (id_token) {
      //   this.loginGoogleSuccess({
      //     wc: {
      //       id_token: id_token,
      //       access_token: ''
      //     }
      //   });
      // }

      const state = this.getUrlParameter(this.$route.fullPath, 'state');
      const code = this.getUrlParameter(this.$route.fullPath, 'code');
      const isBusy = localStorage.getItem(STORAGE_BUSY);
      if (!isBusy && state == 'google_signin') {
        this.$emit('click');
        localStorage.setItem(STORAGE_BUSY, IS_BUSY);

        try {
          const form = new FormData();
          form.append('code', code);
          form.append('client_id', this.clientId);
          form.append('client_secret', 'hV-JaL2xueJoeVw54o1yK4tg');
          form.append('redirect_uri', `${this.callbackUrl}`);
          form.append('grant_type', 'authorization_code');
          const { data } = await apis.post(
            `https://oauth2.googleapis.com/token`,
            form,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          );
          if (data.id_token) {
            this.loginGoogleSuccess({
              wc: {
                id_token: data.id_token,
                access_token: data.access_token
              }
            });
          }
        } catch (error) {
          window.location.replace(this.callbackUrl);
        }
      }
      localStorage.setItem(STORAGE_BUSY, IS_FREE);
    },
    async logInWithGoogle() {
      this.$emit('click');
      // try {
      //   const googleUser = await this.$gAuth.signIn();
      //   this.loginGoogleSuccess(googleUser);
      // } catch (err) {
      //   this.loginGoogleFailure();
      // }

      window.location.replace(
        `https://accounts.google.com/o/oauth2/v2/auth?scope=profile email&response_type=code&state=google_signin&redirect_uri=${this.callbackUrl}&client_id=${this.clientId}`
      );

      // this.$refs.btGoogleLogin.$el.click();

      // const provider = new firebase.auth.GoogleAuthProvider();
      // provider.addScope('profile');
      // provider.addScope('email');
      // firebase
      //   .auth()
      //   .signInWithPopup(provider)
      //   .then(({ user }) => {
      //     return firebase
      //       .auth()
      //       .currentUser.getIdToken(true)
      //       .then(idToken => ({ idToken, email: user.email, loginType: 3 }));
      //   })
      //   .then(loginInfo => {
      //     this.$emit('login', loginInfo);
      //   })
      //   .catch(e => {
      //     this.$emit('error', e);
      //   });
    },
    async loginGoogleFailure() {
      this.$emit('error', { message: 'login with googl is cancel.' });
    },
    async loginGoogleSuccess(googleUser) {
      if (googleUser.wc && googleUser.wc.id_token) {
        this.$emit('click');

        const idToken = googleUser.wc.id_token;
        const accessToken = googleUser.wc.access_token;
        const provider = new firebase.auth.GoogleAuthProvider();
        const credential = provider.credential(idToken, accessToken);
        firebase
          .auth()
          .signInWithCredential(credential)
          .then(({ user }) => {
            return firebase
              .auth()
              .currentUser.getIdToken(true)
              .then(idToken => ({ idToken, email: user.email, loginType: 3 }));
          })
          .then(loginInfo => {
            this.$emit('login', loginInfo);
          })
          .catch(e => {
            this.$emit('error', e);
          });
      }
    }
  }
};
</script>

<style>
.bt-google {
  background: #dd4b39;
}
.bt-google svg {
  vertical-align: top;
}
</style>
