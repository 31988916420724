<template>
  <div>
    <div class="bg-progress w-full h-2" />
    <div class="my-4 flex">
      <base-icon class="mt-1">
        <icon-report-inprogress />
      </base-icon>
      <div>
        <span>
          งานที่กำลังทำอยู่ (จำนวน {{ board.task.length }} งานจาก
          {{ board.length }} บอร์ด)
          <p class="text-gray-caption text-sm">
            งานทั้งหมดในช่วงเวลา
            {{ formatShowDate(dateSelect.start, dateSelect.end) }}
            ที่ไม่มีสถานะเสร็จ
          </p>
        </span>
      </div>
    </div>
    <div v-for="bord in groupBord" :key="bord.index">
      <div class="w-full mb-4">
        <div class="mb-2">
          <base-icon name="board" view-box="0 2 18 14" size="20">
            <icon-board />
          </base-icon>
          <span>บอร์ด: {{ bord[0].board.name }}</span>
        </div>
        <div class="text-sm text-gray-caption mb-2 text-right px-4 flex">
          <div class="text-left w-8/12">หัวข้องาน</div>
          <div class="w-2/12">วันเริ่ม/สิ้นสุด</div>
          <div class="w-2/12">บันทึกเวลาทั้งหมด</div>
        </div>
        <div class="text-sm font-normal text-right border rounded px-4">
          <div
            v-for="(tasks, index) in bord"
            :key="index"
            class="flex"
            :class="{ 'border-b': index + 1 < bord.length }"
          >
            <div class="py-2 text-left w-8/12">
              {{ tasks.task.name }}
            </div>
            <div class="py-2 w-2/12">
              {{ formatShowDate(tasks.startAt, tasks.endAt) }}
            </div>
            <div class="py-2 w-2/12">
              {{ time(tasks.timeSpent) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconReportInprogress from '@/components/icons/IconReportInprogress.vue';
import IconBoard from '@/components/icons/IconBoard.vue';
import FormatStartEndDate from '@/mixins/FormatStartEndDate.ts';
import FormatTimeSpent from '@/mixins/FormatTimeSpent.ts';

export default {
  mixins: [FormatStartEndDate, FormatTimeSpent],
  components: { IconReportInprogress, IconBoard },
  props: {
    board: []
  }
};
</script>
