<template>
  <v-popover
    placement="bottom-start"
    popover-arrow-class="tooltip-arrow popover-arrow invisible"
    class="grid"
    :disabled="hideSelectMember"
    @show="active = true"
    @hide="active = false"
  >
    <slot :active="active">
      <base-button>{{ $t('members') }}</base-button>
    </slot>
    <div slot="popover" class="select-board-report--popover divide-y">
      <div>
        <base-dropdown-item :hoverable="false" keep>
          <div class="flex mb-2">
            <p class="font-medium">{{ $t('members') }}</p>
          </div>
          <base-input-text
            v-model="queryText"
            inner-class="base-input--gray"
            :placeholder="$t('search')"
            icon-fa="search"
            :icon-right="queryText ? 'IconClose' : null"
            @icon-right-click="queryText = ''"
          />
        </base-dropdown-item>
        <base-dropdown-item
          v-for="(member, index) in members"
          :key="index"
          keep
          @click="toggleSelectMember(member)"
        >
          <div class="flex items-center overflow-hidden">
            <base-checkbox :checked="isMemberSelect(member)" />
            <base-avatar class="flex-shrink-0" :avatar="member.user" />
            <div class="rounded-full flex-1 px-4 py-1  font-medium truncate">
              <div class="">
                <p class="flex-1  truncate">{{ member.user.name }}</p>
                <p class="text-gray-caption v-clamp-word-break text-sm">
                  {{ member.user.email }}
                </p>
              </div>
            </div>
          </div>
        </base-dropdown-item>
        <base-dropdown-item v-if="!members.length" :hoverable="false">
          <div class="text-center text-gray-500 text-sm p-2">
            {{ $t('team.report.NoMember') }}
          </div>
        </base-dropdown-item>
      </div>
    </div>
  </v-popover>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('teams');
export default {
  props: {
    memberTeamSelected: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active: false,
      queryText: '',
      memberSelected: this.memberTeamSelected,
      hideSelectMember: false
    };
  },
  computed: {
    ...mapGetters(['getMembersActive']),
    members() {
      const byName = ({ user }) =>
        user.name.toLowerCase().includes(this.queryText.toLowerCase());
      return this.getMembersActive.filter(byName);
    }
  },
  methods: {
    isMemberSelect(member) {
      return member.id === this.memberSelected;
    },
    toggleSelectMember(member) {
      this.hideSelectMember = true;
      setTimeout(() => (this.hideSelectMember = false), 100);
      if (!this.isMemberSelect(member)) {
        this.memberSelected = member.id;
        this.$emit('memberSelected', member);
      } else {
        this.memberSelected = '';
        this.$emit('memberSelected', '');
      }
    }
  }
};
</script>
<style>
.select-board-report--popover {
  @apply relative overflow-y-auto;
  max-height: 383px;
  width: 461px;
}
</style>
<i18n>
{
  "en": {
    "members": "Members",
    "search": "Search"
  },
  "th": {
     "members": "สมาชิก",
     "search": "ค้นหา"
  }
}
</i18n>
