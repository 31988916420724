<template>
  <div>
    <base-card
      style="width: 297px"
      class="flex text-sm text-left cursor-pointer"
      :class="{
        'card_border-color':
          (isChangePlan && data.key == planId) ||
          (!isChangePlan && data.key == 2)
      }"
    >
      <div
        v-if="data.key == 2 && !isChangePlan"
        slot="header"
        class="border-b border-brand-blue"
        :class="{ header: data.key == 2 }"
      >
        <span> {{ $t('recommend_plan') }} </span>
      </div>
      <div
        v-if="isChangePlan && data.key == planId"
        slot="header"
        class="header border-b border-brand-blue"
      >
        <span> แผนปัจจุบัน </span>
      </div>
      <base-icon size="80">
        <component :is="`IconPlanSubPer${iconPlan[data.key]}`"
      /></base-icon>
      <div>
        <p class="text-2xl font-semibold">{{ data.planName }}</p>
        <p class="text-gray-caption pr-6">
          {{ data.planDescription }}
        </p>
        <hr class="mt-3" />
      </div>
      <div slot="footer" class="flex p-3 flex-col row-gap-4">
        <div class="flex font-semibold self-center" style="font-size: 32px">
          {{ data.price || 0 }} {{ $t('subscription.thai_bath') }}
          <span class="ml-2 text-xs self-center  text-gray-caption"
            >{{ $t('subscription.upgradeWorkspace.plan.perSeat') }}
            <br />
            / {{ $t('subscription.upgradeWorkspace.plan.perMonth') }}</span
          >
        </div>

        <p class="text-gray-caption pl-3" style="min-height:42px">
          {{ data.priceDescription }}
        </p>
        <div v-if="activity == 'upgrade'" class="mx-4 text-center">
          <base-button class="w-full" @click="buyPlan(data)">
            {{ $t('subscription.upgradeWorkspace.plan.buyPlan') }}
          </base-button>
          <p v-if="canFreePlan" class="self-center" @click="trialPlan(data)">
            {{ $t('subscription.upgradeWorkspace.plan.or') }}
            <span class="text-brand-blue">{{
              $t('subscription.upgradeWorkspace.plan.trial')
            }}</span>
          </p>
        </div>
        <div v-else>
          <base-button
            v-if="isChangePlan && data.key == planId"
            class="w-full"
            color="transparent"
            :disabled="true"
          >
            แผนปัจจุบัน
          </base-button>
          <base-button
            v-else
            class="w-full"
            color="transparent"
            @click="changePlan(data)"
          >
            เลือกแผนนี้
          </base-button>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import IconPlanSubPerMonth from '@/components/icons/IconPlanSubPerMonth';
import IconPlanSubPerYear from '@/components/icons/IconPlanSubPerYear';
import IconPlanSubPerSixMonth from '@/components/icons/IconPlanSubPerSixMonth';
import IconPlanSubPerFree from '@/components/icons/IconPlanSubPerFree';

import { mapGetters } from 'vuex';
import ConfirmModal from '@/components/board/board-dialog-confirm.vue';

export default {
  data() {
    return {
      iconPlan: ['Free', 'Month', 'SixMonth', 'Year']
    };
  },
  components: {
    IconPlanSubPerMonth,
    IconPlanSubPerYear,
    IconPlanSubPerSixMonth,
    IconPlanSubPerFree,
    ConfirmModal
  },
  props: {
    data: {
      type: Object
    },
    activity: String
  },
  computed: {
    ...mapGetters('subscription', [
      'getCardDefault',
      'getPlanSelected',
      'getCurrentPlan',
      'getNumberMember'
    ]),
    canFreePlan() {
      return this.profile.canFreeTrial;
    },
    isChangePlan() {
      return this.activity == 'change';
    },
    planId() {
      return this.getCurrentPlan.payment.subscriptionOrder.planID;
    },
    profile() {
      return this.$store.getters['auth/getUser'];
    },
    suggessNumberMember() {
      return this.profile.suggestionNumberOfSubscriptionMember;
    }
  },
  methods: {
    buyPlan(data) {
      this.$emit('buyPlan', data);
    },
    async trialPlan(data) {
      const numberMember =
        this.suggessNumberMember === 5 ? 5 : this.suggessNumberMember;
      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('free_plan'),
          description: this.$t('free_plan_description'),
          button: this.$t('confirm'),
          confirmAction: 'subscription/subscriptionPlanConfirm',
          confirmData: {
            numberOfSeats: numberMember,
            planID: 1
          },
          onConfirm: () => {
            this.$modal.hide('plan-card');
          },
          confirmCallback: () => {
            this.$modal.show('alert-freetrial');
            this.$store.dispatch('auth/fetchMe');
          }
        },
        {
          height: 'auto',
          maxWidth: 450,
          adaptive: true
        }
      );
    },
    async changePlan(data) {
      await this.$store.dispatch('subscription/subscriptionPlanPreview', {
        numberOfSeats: this.suggessNumberMember,
        planID: data.planId
      });
      const changePlan = {
        cardToken: this.getCardDefault?.card_id,
        numberOfSeats: 5,
        planID: data.planId
      };
      this.$store.commit('subscription/SET_CHANGE_PLAN', changePlan);
      this.$modal.show('plan-payment-change');
    }
  }
};
</script>

<style scoped>
.header {
  @apply p-1 pl-6;
  background: #e8f4fd;
}

.card_border-color {
  border: 1.5px solid #168ae2;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.13);
}
</style>
<i18n>
{
  "en": {
    "recommend_plan": "Recommended 🎉",
    "free_plan": "Begin your free trial",
    "free_plan_description": "You can activate your one-time free trial for 90 days without adding credit/debit card to your account. Please click “Confirm” to start your free trial",
    "confirm": "Confirm"
  },
  "th": {
    "recommend_plan": "แผนแนะนำ 🎉",
    "free_plan": "ยืนยันทดลองใช้งานฟรี 90 วัน",
    "free_plan_description": "คุณสามารถทดลองใช้ฟังก์ชัน workspace ได้ฟรี 1 ครั้ง ภายในระยะเวลา 90 วัน โดยไม่จำเป็นต้องกรอกบัตรเดรคิต/เดบิต โปรดกดยืนยันเพื่อเริ่มทดลองใช้งานฟรี",
    "confirm": "ยืนยัน"
  }
}
</i18n>
