<template>
  <div class="flex overflow-hidden" :class="align">
    <div
      class="has-centered-item flex-shrink-0"
      :class="isTiny ? 'mr-2' : 'mr-4'"
    >
      <slot name="before">
        <base-avatar
          :avatar="{ profilePicture: defaultImageSrc, ...user }"
          :size-smaller="isTiny"
          :size-small="isSmall"
          :size="size"
        />
      </slot>
    </div>
    <div
      v-if="user.status > 1 || !user.status"
      :class="textSize"
      class="flex-1 inline-flex flex-col overflow-hidden"
    >
      <slot name="user" :user="user">
        <p class="truncate">
          {{ userName }}
        </p>
      </slot>
      <v-clamp v-if="withEmail" :class="{ 'info-user__email': !hasSingleInfo }">
        {{ userEmail }}
      </v-clamp>
      <v-clamp
        v-if="withBio"
        class="truncate"
        :class="{ 'info-user__email': !hasSingleInfo }"
      >
        {{ userBio }}
      </v-clamp>
      <slot />
    </div>
    <div
      v-else
      :class="textSize"
      class="flex-1 inline-flex flex-col overflow-hidden"
    >
      <slot name="user" :user="user">
        <p class="truncate text-gray-600">
          {{ user.inviteToEmail }}
        </p>
        <p class="text-gray-600 text-sm">
          {{ $t('invite.pending') }}
        </p>
      </slot>

      <slot />
    </div>
  </div>
</template>

<script>
import VClamp from 'vue-clamp';
export default {
  components: {
    VClamp
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    withEmail: Boolean,
    withBio: Boolean,
    size: {
      type: [String, Number],
      default: null
    },
    shortenEmail: Boolean,
    limitNameLength: {
      type: Number
    },
    align: {
      type: String,
      default: 'items-center'
    }
  },
  computed: {
    isTiny() {
      return this.size === 'tiny';
    },
    isSmall() {
      return this.size === 'small';
    },
    hasSingleInfo() {
      return (
        (this.userName && !this.userEmail) || (!this.userName && this.userEmail)
      );
    },
    firstChar() {
      return this.userName[0] || this.userEmail[0];
    },
    defaultImageSrc() {
      return this.user.status > 1 || !this.user.status
        ? this.user.user?.profilePicture || this.user.profilePicture
        : `https://storage.googleapis.com/workkami-files-dev/statics/f6dd2895acbe4cf4b6d2efa5f279855f_thumb.png`;
    },
    textSize() {
      if (this.isTiny) return 'text-xs';
      if (this.small) return 'text-sm';
      return null;
    },
    userName() {
      return this.user.user?.name || this.user.name || '';
    },
    userEmail() {
      return (
        this.user.user?.email || this.user.email || this.user.inviteToEmail
      );
    },
    userBio() {
      return this.user.bio || '';
    },
    emailName() {
      const email = this.userEmail;
      const atPosition = this.whereAt(email);
      return email.slice(0, atPosition);
    },
    emailDomain() {
      const email = this.userEmail;
      const atPosition = this.whereAt(email);
      return email.slice(atPosition);
    },
    email() {
      if (!this.shortenEmail) return this.userEmail;
      return this.truncateMiddle(this.emailName, 24) + this.emailDomain;
    },
    shortenName() {
      if (!this.limitNameLength) return this.userName;
      if (this.userName.length < this.limitNameLength) return this.userName;
      return this.userName.slice(0, this.limitNameLength) + '...';
    }
  },
  methods: {
    whereAt(text = '') {
      return text.indexOf('@');
    },
    truncateMiddle(text = '', maxLen = 0) {
      const middleText = '...';
      const totalMaxLen = maxLen + middleText.length;
      if (!maxLen || text.length <= totalMaxLen) return text;
      const cutLen = Math.floor(text.length - totalMaxLen) / 2;
      const middle = Math.floor(text.length / 2);
      const splitTexts = [
        text.slice(0, middle - cutLen),
        text.slice(middle + cutLen)
      ];
      return splitTexts.join(middleText);
    }
  }
};
</script>

<style scoped>
.info-user__email {
  @apply text-gray-600;
  @apply text-sm;
}
</style>
