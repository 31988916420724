<template>
  <base-dropdown width="full">
    <div
      slot="toggle"
      class=":hover:bg-transparent px-2 py-2 base-select__toggle cursor-pointer"
    >
      <div class=" base-select__active flex items-center pl-2">
        <div class="text-sm truncate" style=" max-width: 358px">
          {{ showTeamName }}
        </div>
      </div>
      <div class="toggle-icon inline-flex has-centered-item px-2">
        <base-icon size="16">
          <icon-chevron-down />
        </base-icon>
      </div>
    </div>
    <v-scroller style="max-height:200px" @bottom="scrollBottom">
      <base-dropdown-item v-for="option in teamOptions" :key="option.value">
        <div class="flex" @click="changeTeam(option.value)">
          <div class="w-6 mr-2">
            <base-icon
              v-if="showTeamID === option.value"
              size="16"
              class="align-text-top w-full"
            >
              <icon-check />
            </base-icon>
          </div>
          <div class="flex-1 truncate">
            {{ option.label }}
          </div>
        </div>
      </base-dropdown-item>
    </v-scroller>
  </base-dropdown>
</template>

<script>
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import VScroller from '@/components/scroller.vue';
import IconCheck from '@/components/icons/IconCheck.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    IconChevronDown,
    IconCheck,
    VScroller
  },
  data() {
    return {
      showIcon: 'ไม่ระบุ',
      teamID: '',
      teamname: ''
    };
  },
  props: {
    board: {}
  },
  computed: {
    ...mapGetters({
      teams: 'teams/getTeams',
      nextPage: 'teams/getNextPage'
    }),
    showTeamID() {
      return this.board.team ? this.board.team.id : 0;
    },
    showTeamName() {
      return this.board.team ? this.board.team.name : this.$t('team.noSelect');
    },
    teamOptions() {
      const list = this.$store.getters['teams/getTeams'].map(e => {
        return { value: e.id, label: e.name };
      });
      return [{ value: 0, label: this.$t('team.noSelect') }, ...list];
    }
  },

  methods: {
    ...mapActions('boards', ['deleteBoard', 'editBoard']),

    async changeTeam(id) {
      await this.$store.dispatch('boards/editBoard', {
        ...this.board,
        teamID: id
      });
      this.teamID = id;
      this.$store.dispatch('boards/fetchBoards');
    },
    async scrollBottom() {
      if (this.nextPage) {
        this.$store.dispatch('teams/fetchTeamsNext');
      }
    }
  }
};
</script>

<style scoped>
.base-select__toggle {
  @apply border rounded;
}
</style>
