<template>
  <div
    class="sidebar h-auto px-4 md:px-8"
    :class="{ block: forceShow, 'hidden md:block': !forceShow }"
  >
    <div class="divide-y flex flex-col h-full">
      <div class="ml-4">
        <base-collapse collapsed>
          <template #collapser="{ isCollapsed, toggle }">
            <div
              class="flex justify-between items-center cursor-pointer mb-2 mt-4"
              :class="{ 'mb-2': !isCollapsed }"
              @click="toggle"
            >
              <base-icon width="24" class="mr-2">
                <icon-left-home />
              </base-icon>
              <div class="flex-1 flex flex-col overflow-hidden">
                <v-clamp class="truncate" autoresize :max-lines="1">
                  <span slot="after">
                    {{ $t('sidebar.home') }}
                  </span>
                </v-clamp>
              </div>
              <base-icon name="menu-more" size="16">
                <icon-chevron-right v-if="isCollapsed" />
                <icon-chevron-down v-else />
              </base-icon>
            </div>
          </template>
          <base-dropdown-item
            v-for="menu in menuHome"
            :key="`tab-${menu.key}`"
            class="ml-4"
            :class="{ 'sidebar__item--active': menu.key == activeSidebar }"
          >
            <router-link :to="{ name: menu.key }">
              <div
                class="flex items-center"
                :class="{ 'text-brand-blue': menu.key == activeSidebar }"
              >
                <base-icon size="16">
                  <component :is="`icon-${menu.icon}`" />
                </base-icon>
                <div>{{ menu.name }}</div>
              </div>
            </router-link>
          </base-dropdown-item>
        </base-collapse>
      </div>
      <ul
        v-for="sidebar in sidebars"
        :key="sidebar.key"
        class="list-none py-2"
        style="border-top-width: 0 !important;"
      >
        <li
          v-for="sidebarChild in sidebar.children"
          :key="sidebarChild.key"
          class="sidebar__item"
          :class="{
            'sidebar__item--active': sidebarChild.key === activeSidebar
          }"
        >
          <router-link
            v-if="sidebarChild.link"
            :data-testid="`${sidebarChild.name}-btn`"
            :to="{ name: sidebarChild.key }"
          >
            <base-icon width="24" class="mr-2">
              <component v-bind:is="sidebarChild.icon" />
            </base-icon>
            {{ sidebarChild.name }}
          </router-link>
          <div
            v-else
            class="px-4 py-2 outline-none"
            @click="sidebarChild.click"
          >
            <base-icon view-box="0 0 20 24" class="mr-2">
              <component v-bind:is="sidebarChild.icon" />
            </base-icon>
            {{ sidebarChild.name }}
          </div>
        </li>
      </ul>
      <div
        v-if="shouldShowTimelog"
        data-testid="should-show-timelog"
        class="py-4"
      >
        <div class="sidebar__header">
          {{ $t('sidebar.timelog') }}
        </div>
        <timelog-timer :timelog="workingLog" no-start />
      </div>

      <!-- Team -->
      <v-scroller
        class="py-3 overflow-y-auto pr-2"
        data-testid="team"
        @bottom="scrollBottom"
      >
        <div class="flex justify-between mb-2">
          <div class="sidebar__header">
            {{ $t('sidebar.team') }}
          </div>
          <div data-testid="icon-create-team-btn" @click="createTeamClick">
            <base-icon
              :width="16"
              :height="16"
              name="plus"
              data-testid="icon-create-team"
              class="align-top cursor-pointer"
            >
              <icon-plus />
            </base-icon>
          </div>
        </div>

        <ul class="list-none pb-4">
          <base-collapse
            v-for="team in teams"
            :key="team.id"
            collapsed
            default-collapsed
          >
            <template #collapser="{ isCollapsed, toggle }">
              <div
                class="flex justify-between items-center cursor-pointer my-4"
                :class="{ 'mb-2': !isCollapsed }"
                @click="toggle"
              >
                <div class="mr-2">
                  <base-icon v-if="!team.imageLogo" class="w-6 text-center">
                    <icon-team />
                  </base-icon>
                  <img
                    v-else
                    class="w-6 h-6 object-cover rounded "
                    :src="team.imageLogo"
                  />
                </div>
                <div class="flex-1 flex flex-col overflow-hidden">
                  <v-clamp class="truncate" autoresize :max-lines="1">
                    <span slot="after" :data-testid="`${team.name}-btn`">
                      {{ team.name }}
                    </span>
                  </v-clamp>
                </div>
                <base-icon name="menu-more" size="16">
                  <icon-chevron-right v-if="isCollapsed" />
                  <icon-chevron-down v-else />
                </base-icon>
              </div>
            </template>
            <base-dropdown-item
              v-for="menu in menuWorkspace"
              :key="`tab-${menu.key}`"
              class="ml-4"
              :class="{ 'sidebar__item--active': menu.key == activeSidebar }"
            >
              <router-link :to="{ name: menu.key, params: { id: team.id } }">
                <div class="flex items-center">
                  <base-icon size="16">
                    <component :is="`icon-${menu.icon}`" />
                  </base-icon>
                  <div>{{ menu.name }}</div>
                </div>
              </router-link>
            </base-dropdown-item>
          </base-collapse>
        </ul>
      </v-scroller>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconLeftHome from '@/components/icons/IconLeftHome';
import IconLeftMyTasks from '@/components/icons/IconLeftMyTasks';
import IconLeftArchiveBoards from '@/components/icons/IconLeftArchiveBoards';
import IconLeftTimeLog from '@/components/icons/IconLeftTimeLog';
import IconTeam from '@/components/icons/IconTeam';
import IconPlus from '@/components/icons/IconPlus';
import IconFeedback from '@/components/icons/IconFeedback';
import IconReportExport from '@/components/icons/IconReportExport';
import SidebarAdminIcon from '@/components/icons/SidebarAdminIcon';
import SidebarAdminIconVue from './icons/SidebarAdminIcon.vue';
import VClamp from 'vue-clamp';
import TimelogTimer from './timelog/timelog-timer.vue';
import VScroller from '@/components/scroller';
import IconChevronRight from '@/components/icons/IconChevronRight';
import IconChevronDown from '@/components/icons/IconChevronDown';
import IconCreateBoard from '@/components/icons/IconCreateBoard';
import IconStarOutlined from '@/components/icons/IconStarOutlined';
import IconOverview from '@/components/icons/IconOverview';
import IconRecentlyView from '@/components/icons/IconRecentlyView';

export default {
  components: {
    IconLeftHome,
    IconLeftMyTasks,
    IconLeftArchiveBoards,
    IconLeftTimeLog,
    IconTeam,
    IconPlus,
    IconFeedback,
    IconReportExport,
    SidebarAdminIcon,
    VClamp,
    TimelogTimer,
    VScroller,
    IconChevronRight,
    IconChevronDown,
    IconCreateBoard,
    IconStarOutlined,
    IconOverview,
    IconRecentlyView
  },
  props: {
    forceShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isUserAppOwner: 'auth/isUserAppOwner',
      teams: 'teams/getTeams',
      workingLog: 'time-log/getWorkingLog',
      nextPage: 'teams/getNextPage'
    }),
    menuWorkspace() {
      return [
        {
          key: 'Workspace-Star-Board',
          name: this.$t('starred_board'),
          icon: 'star-outlined'
        },
        { key: 'Teams-Board', name: this.$t('boards'), icon: 'create-board' },
        { key: 'Teams-Dashboard', name: this.$t('overview'), icon: 'overview' }
      ];
    },
    menuHome() {
      return [
        {
          key: 'Boards-List',
          name: this.$t('boards'),
          icon: 'create-board',
          link: '/'
        },
        {
          key: 'Stared-Boards',
          name: this.$t('starred_board'),
          icon: 'star-outlined',
          link: '/stared-boards'
        },
        {
          key: 'Recently-Boards',
          name: this.$t('recently_board'),
          icon: 'recently-view',
          link: '/recently-boards'
        }
      ];
    },
    activeSidebar() {
      return this.$route.name;
    },
    activeSidebarTeamId() {
      return this.$route.params ? this.$route.params.id : '';
    },
    sidebars() {
      const commonUserMenus = {
        key: 'common',
        children: [
          // {
          //   name: this.$t('sidebar.home'),
          //   link: '/',
          //   key: 'Boards-List',
          //   icon: 'IconLeftHome'
          // },
          {
            name: this.$t('sidebar.task'),
            link: '/my-tasks',
            key: 'My-Tasks',
            icon: 'IconLeftMyTasks'
          },
          {
            name: this.$t('sidebar.timelog'),
            link: '/time-log',
            key: 'Time-Log',
            icon: 'IconLeftTimeLog'
          },
          {
            name: this.$t('sidebar.personalReport'),
            link: '/personal-report',
            key: 'Personal-Report',
            icon: 'IconReportExport'
          },
          {
            name: this.$t('sidebar.archive'),
            link: '/archive-boards',
            key: 'Archive-Boards',
            icon: 'IconLeftArchiveBoards'
          },
          {
            name: this.$t('nav.feedback'),
            click: this.openFeedbackForm,
            key: 'Feedback-Form',
            icon: 'IconFeedback'
          }
        ]
      };
      const adminUserMenus = {
        key: 'app-owner',
        children: [
          {
            key: 'board-admin',
            link: '/admin',
            name: this.$t('sidebar.admin'),
            icon: SidebarAdminIconVue
          }
        ]
      };
      if (this.isUserAppOwner) return [commonUserMenus, adminUserMenus];
      return [commonUserMenus];
    },
    shouldShowTimelog() {
      return this.$route.name !== 'Time-Log' && this.workingLog;
    },
    profile() {
      return this.$store.getters['auth/getUser'];
    }
  },
  methods: {
    createTeamClick() {
      if (
        this.profile.haveActiveSubscription ||
        this.profile.subscriptionExempted
      ) {
        this.$modal.show('create-workspace');
      } else {
        this.$modal.show('plan-card');
      }
    },
    scrollBottom() {
      if (this.nextPage) {
        this.$store.dispatch('teams/fetchTeamsNext', this.nextPage);
      }
    },
    openFeedbackForm() {
      this.$modal.show('feedback');
    }
  }
};
</script>

<style scoped>
.sidebar__item a {
  @apply px-4 py-2 rounded-lg;
}
.sidebar__item:hover {
  @apply bg-gray-400 cursor-pointer;
  @apply rounded;
}
.sidebar__item:not(:last-of-type) {
  @apply mb-3;
}
.sidebar_team_item:not(:last-of-type) {
  @apply mb-0;
}
.sidebar__item--active {
  @apply bg-gray-main;
  @apply rounded;
}
.sidebar__item a {
  display: block;
}
.sidebar__header {
  @apply text-sm uppercase mb-2;
  color: #5e6c84;
}
.sidebar {
  height: calc(100vh - 65px);
  z-index: 1;
}
</style>
<i18n>
{
  "en": {
    "boards": "Boards",
    "starred_board": "Starred Boards",
    "recently_board": "Recently Boards",
    "overview":"Overview"
  },
  "th": {
    "boards": "บอร์ด",
    "starred_board": "บอร์ด ที่ติดดาว",
    "recently_board": "บอร์ด ที่เพิ่งเปิดดู",
    "overview":"ภาพรวม"
  }
}
</i18n>
