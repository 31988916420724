























































































































































































import Vue from 'vue';
import IconChevronRight from '@/components/icons/IconChevronRight.vue';
import IconClose from '@/components/icons/IconClose.vue';
import VueI18n from 'vue-i18n';

interface Filter {
  label: VueI18n.TranslateResult;
  value: number;
}

export default Vue.extend({
  components: { IconChevronRight, IconClose },
  props: {
    taskCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      q: '',
      qTasks: [],
      groupBy: { label: 'myTasks.group.title', value: 1 },
      filter: { label: 'myTasks.filter.Incomplete', value: 2 }
    };
  },
  computed: {
    groupBys(): Filter[] {
      return [
        { label: 'myTasks.group.date', value: 1 },
        { label: 'myTasks.group.boards', value: 2 },
        { label: 'myTasks.group.none', value: 3 }
      ];
    },
    filters(): Filter[] {
      return [
        { label: 'myTasks.filter.Incomplete', value: 2 },
        { label: 'myTasks.filter.done', value: 3 },
        { label: 'myTasks.filter.all', value: 1 }
      ];
    }
  },
  watch: {
    'filter.value'() {
      this.$emit('filter', this.filter);
    },
    'groupBy.value'() {
      this.$emit('groupBy', this.groupBy);
    }
  },
  methods: {
    async qChange() {
      this.$emit('q', this.q);
      // const filter = {
      //   q: this.q
      // };
      // const res = await boardsApi.tasks(filter);
      // this.qTasks = res.data;
    },
    clearClick() {
      this.q = '';
      this.qChange();
    },
    getQ() {
      return this.q;
    },
    getGroupBy() {
      return this.groupBy;
    },
    getFilter() {
      return this.filter;
    }
  }
});
