<template>
  <base-box id="total-working-time">
    <div slot="header" :class="{ 'text-gray-label': !boardCount }">
      {{ $t('admin.totaltimelog') }} ({{ boardCount }})
    </div>
    <div ref="inner" class="flex flex-col h-full justify-between">
      <div :class="{ 'has-centered-item': boardCount }">
        <div v-if="!boardCount" class="grid justify-items-center py-10">
          <base-icon :height="135" :width="134">
            <icon-doughnut-chart />
          </base-icon>
          <div class="pt-8 pb-1 text-xl font-medium text-gray-label">
            Not found total working hours
          </div>
          <div class="text-empty">
            Your work data is insufficient, please run time log. Your work data
            will be displayed in total here.
          </div>
        </div>

        <chart-doughnut
          v-else
          class="relative"
          text-center-with-description
          :text-center="formatHourMinuteFromMinutes(totalWorkingHours)"
          :chart-data="chartData"
          :options="chartOptions"
          :styles="{ height: '180px', width: '184px' }"
        />
      </div>
      <div v-if="boardCount" class="mt-3 hidden sm:block">
        <div
          v-for="(row, index) in workingTimeRows"
          :key="row.key"
          class="grid grid-cols-3 divide-x p-4"
          :class="{ 'border-t': index > 0 }"
        >
          <div v-for="item in row.items" :key="item.boardID" class="px-4">
            <div class="flex items-baseline">
              <div
                class="rounded-full w-2 h-2 flex-shrink-0 mr-2"
                :style="{ 'background-color': item.boardColor }"
              />
              <div class="overflow-hidden">
                <div class="truncate text-sm">
                  {{ item.boardName }}
                </div>
                <div class="text-xl">
                  {{
                    formatHourMinuteFromMinutes(item.TotalTimeSpentInMinutes)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="boardCount" class="sm:hidden">
        <div
          v-for="(timelog, index) in mobileTimelogs"
          :key="timelog.boardID"
          class="py-1"
          :class="{ 'border-t': index > 0 }"
        >
          <div class="flex items-baseline">
            <div
              class="rounded-full w-2 h-2 flex-shrink-0 mr-2"
              :style="{ 'background-color': timelog.boardColor }"
            />
            <div class="overflow-hidden">
              <div class="truncate">
                {{ timelog.boardName }}
              </div>
              <div>
                {{
                  formatHourMinuteFromMinutes(timelog.TotalTimeSpentInMinutes)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isBoardsOverLimit && boardCount" class="-mx-4 -mb-2">
        <button
          class="w-full bg-gray-f5 border-t py-2"
          @click="this.toggleShowAll"
        >
          <span class="mr-2">
            {{ showAll ? $t('admin.less') : $t('admin.more') }}</span
          >
          <fa-icon :icon="showAll ? 'chevron-up' : 'chevron-down'" size="xs" />
        </button>
      </div>
    </div>
  </base-box>
</template>

<script>
import ChartDoughnut from '@/components/chart/chart-doughnut';
import DateTime from '@/mixins/DateTimeMixin';
import IconDoughnutChart from '@/components/icons/IconDoughnutChart.vue';

const ITEMS_PER_ROW = 3;
const MAX_ROWS = 2;
const PARTIAL_VISIBLE_ITEM_MAX = MAX_ROWS * ITEMS_PER_ROW;

export default {
  components: { ChartDoughnut, IconDoughnutChart },
  mixins: [DateTime],
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showAll: false
    };
  },
  computed: {
    isBoardsOverLimit() {
      return this.boardCount > MAX_ROWS * ITEMS_PER_ROW;
    },
    hiddenValue() {
      return this.value.slice(0, PARTIAL_VISIBLE_ITEM_MAX);
    },
    boardCount() {
      return this.value.length;
    },
    chartData() {
      const getTotalHour = timelog => timelog.TotalTimeSpentInMinutes;
      const getBoardName = timelog => timelog.boardName;
      const getBackgroundColor = timelog => timelog.boardColor;
      const data = this.value.map(getTotalHour);
      const labels = this.value.map(getBoardName);
      const backgroundColor = this.value.map(getBackgroundColor);
      return {
        datasets: [{ data, backgroundColor }],
        labels
      };
    },
    chartOptions() {
      return {
        cutoutPercentage: 75,
        legend: {
          display: false
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        tooltips: {
          callbacks: {
            label: e => {
              const MAX_VISIBLE = 25;
              const { boardName } = this.value[e.index];
              const boardNameLength = boardName.length;
              if (boardNameLength < MAX_VISIBLE) return boardName;
              return `${boardName.slice(0, MAX_VISIBLE)}...`;
            },
            afterLabel: e => {
              const { TotalTimeSpentInMinutes } = this.value[e.index];
              return this.formatHourMinuteFromMinutes(TotalTimeSpentInMinutes);
            }
          }
        }
      };
    },
    workingTimeRows() {
      const timelogs =
        !this.isBoardsOverLimit || this.showAll ? this.value : this.hiddenValue;
      const totalRows = Math.ceil(timelogs.length / ITEMS_PER_ROW);
      const rowList = [...Array(totalRows)];
      return rowList.map((_, i) => {
        const items = [...Array(ITEMS_PER_ROW)];
        return {
          key: `working-time-row-${i}`,
          items: items
            .map((_, j) => {
              const index = i * ITEMS_PER_ROW + j;
              return timelogs[index];
            })
            .filter(e => e != null)
        };
      });
    },
    mobileTimelogs() {
      if (this.showAll) return this.value;
      const mobileMaxRows = ITEMS_PER_ROW * 2;
      const visibleTimelogs = this.value.slice(0, mobileMaxRows);
      return visibleTimelogs;
    },
    totalWorkingHours() {
      return this.value.reduce(
        (prev, timelog) => prev + timelog.TotalTimeSpentInMinutes,
        0
      );
    }
  },
  mounted() {
    this.$emit('show-all');
  },
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
      this.$emit('show-all', this.showAll);
    },
    getOtherItem(sliceFrom) {
      const sumOtherItemWorkingTime = (total, timelog) =>
        total + timelog.TotalTimeSpentInMinutes;
      const TotalTimeSpentInMinutes = this.value
        .slice(sliceFrom)
        .reduce(sumOtherItemWorkingTime, 0);
      return {
        boardName: 'other',
        boardColor: '#f5f5f5',
        TotalTimeSpentInMinutes
      };
    }
  }
};
</script>

<style scoped>
.text-empty {
  @apply text-sm font-normal text-center;
  width: 26rem;
  color: #a2aec2;
}
</style>
