import { ApiPagination, PageInformation, ApiCommonFields } from './Api';
import { BoardMember } from './Board';

export interface TeamAdd {
  name: string;
  description: string;
  type: number;
  webSite: string;
  imageLogo: string;
}

export interface TeamType {
  type: number;
  valueEN: string;
  valueTh: string;
}

export enum TeamMemberStatus {
  Pending = 1,
  Active = 2,
  Leave = 3,
  Deactived = 4
}

export enum TeamMemberRole {
  Viewer = 1,
  Editor = 2,
  Admin = 100
}

export interface Team extends TeamAdd {
  id: number;
  status: number;
  teamType?: TeamType;
  currentTeamMember?: BoardMember;
}

export interface TeamMember extends Omit<BoardMember, 'status'> {
  status: TeamMemberStatus;
}

export interface TeamsResponse extends ApiPagination<Team> {
  entities: Team[];
  pageInformation: PageInformation;
}

export interface TeamMembersResponse extends ApiPagination<TeamMember> {
  entities: TeamMember[];
  pageInformation: PageInformation;
}
