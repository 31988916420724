<template>
  <div class="mb-5">
    <div class="mb-2">
      <base-skeleton-body v-if="loading" />
      <div v-else-if="disabled" class="text-lg">
        <div v-if="board" class="timelog-selector">
          <v-clamp :max-lines="2"
            >{{ $t('timelog.timer.board') }} : {{ board.name || ' ' }}</v-clamp
          >
        </div>
        <div v-if="task" class="timelog-selector">
          <v-clamp :max-lines="2"
            >{{ $t('timelog.timer.task') }} : {{ task.name || ' ' }}</v-clamp
          >
        </div>
      </div>
      <div v-else>
        <select-board-task
          v-model="modelBoardTask"
          modal-name="timelog-starter-select-board"
          :disabled="disabled"
          :is-list-task="true"
        />
      </div>
    </div>
    <div v-if="!board" class="relative has-centered-item py-2 mb-3">
      <div class="absolute inset-0 flex items-center">
        <div class="border-b w-full" />
      </div>
      <div v-if="!disabled" class="px-4 relative bg-white">
        {{ $t('timelog.or') }}
      </div>
    </div>
    <div v-if="disabled" v-show="readonlyDescription" class="text-gray-600">
      <span>{{ descriptionLabel }}</span>
      {{ readonlyDescription }}
    </div>
    <div v-else>
      <p class="mb-1 text-lg font-medium">{{ descriptionLabel }}</p>
      <base-input-text v-model="modelDescription" v-bind="descriptionProps" />
    </div>
  </div>
</template>

<script>
import VClamp from 'vue-clamp';
import SelectBoardTask from '@/components/select/select-board-task';
export default {
  components: {
    VClamp,
    SelectBoardTask
  },
  props: {
    disabled: Boolean,
    loading: Boolean,
    description: {
      type: String,
      default: ''
    },
    board: {
      type: Object
    },
    task: {
      type: Object
    }
  },
  watch: {
    'board.name'() {
      this.modelDescription = '';
    }
  },
  computed: {
    modelDescription: {
      get() {
        return this.description;
      },
      set(val) {
        this.$emit('update:description', val);
      }
    },
    modelBoardTask: {
      get() {
        return {
          board: this.board,
          task: this.task
        };
      },
      set(val) {
        this.$emit('update:board', val.board);
        this.$emit('update:task', val.task);
      }
    },
    btnSelectedText() {
      return [this.board, this.task]
        .filter(e => e != null)
        .map(e => e.name)
        .join(' - ');
    },
    boardTaskNames() {
      const arrStr = [];
      if (this.board) arrStr.push(this.board.name);
      if (this.task) arrStr.push(this.task.name);
      return arrStr.join(' - ');
    },
    descriptionProps() {
      const placeholder = this.board
        ? this.$t('timelog.example.meeting')
        : this.$t('timelog.example.watering');
      return {
        placeholder,
        disabled: this.loading || this.disabled
      };
    },
    descriptionLabel() {
      if (this.disabled)
        return this.board ? `${this.$t('timelog.note')} :` : '';
      return this.board
        ? this.$t('timelog.note')
        : this.$t('timelog.timer.note');
    },
    readonlyDescription() {
      if (this.board) return this.modelDescription;
      return this.modelDescription || this.$t('timelog.no');
    }
  }
};
</script>

<style></style>
