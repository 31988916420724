























































































import Vue, { PropType } from 'vue';
import { BoardMember, Role } from '../../types/Board';
import { TeamMemberRole } from '../../types/Team';
import { BaseSelectOption } from '@/types/BaseComponents';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import IconCheck from '@/components/icons/IconCheck.vue';
import roleMember from '@/mixins/role';

export default Vue.extend({
  mixins: [roleMember],
  components: { IconChevronDown, IconCheck },
  props: {
    member: {
      type: Object as PropType<BoardMember>,
      required: true
    },
    disabled: Boolean,
    isDotButton: {
      type: Boolean,
      default: false
    },
    showRole: {
      type: Boolean,
      default: true
    },
    iconBtn: {
      type: String,
      default: 'dots-vertical'
    }
  },
  computed: {
    active(): BaseSelectOption {
      return (
        this.userRoles.find(e => e.value === this.role) || {
          value: this.role,
          label: TeamMemberRole[this.role]
        }
      );
    },
    role: {
      get(): Role {
        return this.member.role;
      },
      set(this: any, newRole: number) {
        if (newRole == -2) {
          this.$emit('removeUser');
        } else {
          const newRoleMember = this.setRole(this.member, newRole);

          this.$store.dispatch('teams/setMemberRole', newRoleMember);
        }
      }
    }
  },
  data() {
    return {
      userRoles: (this.showRole ? [100, 2, 1, -2] : [-2]).map(value => ({
        value,
        label:
          value == -2
            ? this.member.isCurrentUser
              ? 'Leave Team'
              : 'Remove Member'
            : TeamMemberRole[value],
        class:
          value == -2 ? `text-red-500 ${this.showRole ? 'border-t' : ''}` : ''
      }))
    };
  }
});
