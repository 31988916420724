<template>
  <v-scroller style="min-height: calc(100vh - 64px);">
    <section class="pb-10 px-4 mt-4 board-list--width">
      <div v-if="loading" class="h-screen has-centered-item">
        <base-spinner :size="36" />
      </div>
      <base-empty
        v-else-if="shouldEmpty"
        data-testid="empty-page"
        :image="require('@/assets/image/BoardEmpty.svg')"
        :title="$t('emptyBoard.title')"
        :description="$t('emptyBoard.description')"
      >
        <base-button
          slot="action"
          class="btn--empty"
          full
          @click="openCreateBoard"
        >
          {{ $t('dashboard.create') }}
        </base-button>
      </base-empty>
      <div v-else>
        <base-collapse
          v-if="getBoards.starred.length && isBoardStarred"
          data-testid="starred-board"
          :title="starredBoardTitle"
          class="py-2"
          :class="{ 'collapse--ignore': isBoardStarred && !isBoardList }"
          :default-collapsed="isCollapsed"
        >
          <board-list :boards="getBoards.starred" />
        </base-collapse>
        <div
          v-if="!getBoards.starred.length && !isBoardList && !isBoardRecently"
        >
          <div class="flex justify-center">
            <div>
              <div class="mt-48 flex justify-center">
                <IconEmptyStaredBoard />
              </div>
              <p
                class="text-xl font-medium text-center"
                style="margin-top:15px"
              >
                {{ $t('board.stared.empty.title') }}
              </p>
              <div class="mt-3 text-center">
                <p class="font-light max-w-md text-gray-caption text-sm">
                  {{ $t('board.stared.empty.description') }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <base-collapse
          v-if="getBoards.recent.length && isBoardRecently"
          data-testid="recently-board"
          :title="$t('dashboard.recently')"
          class="py-2"
          :class="{ 'collapse--ignore': isBoardRecently && !isBoardList }"
          :default-collapsed="isCollapsed"
        >
          <board-list :boards="getBoards.recent" />
        </base-collapse>
        <list-folders
          v-if="isBoardList"
          :folders.sync="folders"
          @item-change="updateFolder"
          @item-delete="$modal.show('delete-folder', { folder: $event })"
        />
      </div>
      <modal
        name="delete-folder"
        adaptive
        scrollable
        height="auto"
        :width="500"
        @before-open="beforeModalOpen"
      >
        <dialog-confirm
          :title="$t('dashboard.task.title')"
          confirm-action="folders/deleteFolder"
          :description="deleteFolderDesc"
          :confirm-data="deletingFolder"
          :button="$t('alert.button.delete')"
          @close="$modal.hide('delete-folder')"
        />
      </modal>
    </section>
  </v-scroller>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import EventBus from '@/services/eventBus';
import BoardList from '@/components/board/board-list';
import ListFolders from '@/components/list/list-folders';
import DialogConfirm from '@/components/board/board-dialog-confirm';
import VScroller from '@/components/scroller';
import MembersVisible from '@/mixins/MembersVisible';
import IconEmptyStaredBoard from '@/components/icons/IconEmptyStaredBoard.vue';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'boards'
);
const {
  mapActions: foldersMapActions,
  mapGetters: foldersMapGetters,
  mapMutations: foldersMapMutations
} = createNamespacedHelpers('folders');

export default {
  mixins: [MembersVisible],
  components: {
    BoardList,
    ListFolders,
    DialogConfirm,
    VScroller,
    IconEmptyStaredBoard
  },
  name: 'Boards-List',
  data() {
    return {
      loading: false,
      scrolledToBottom: false,
      deletingFolder: null,
      isCollapsed: false
    };
  },
  computed: {
    ...mapGetters(['getBoards', 'getNextPage']),
    ...foldersMapGetters(['getFolders']),
    boardTitle() {
      if (this.getBoards.all.length > 1) {
        return 'Boards';
      } else if (this.getBoards.all.length === 1) {
        return 'Board';
      }
      return '';
    },
    starredBoardTitle() {
      if (this.getBoards.starred.length > 1) {
        return this.$t('dashboard.star');
      } else if (this.getBoards.starred.length === 1) {
        return `${this.$t('dashboard.starone')}`;
      }
      return '';
    },
    fakeBoards() {
      const times = Math.floor(2000 / this.getBoards.all.length);
      return [...Array(times)]
        .map((_, i) => [
          ...this.getBoards.all.map(e => ({ ...e, id: `${i}${e.id}` }))
        ])
        .reduce((p, c) => [...p, ...c], []);
    },
    folders: {
      get() {
        return this.getFolders;
      },
      set(val) {
        this.setFolders(val);
      }
    },
    deleteFolderDesc() {
      return `${this.$t('dashboard.task.description')}`;
    },
    shouldEmpty() {
      const { starred, recent } = this.getBoards;
      const folders = this.folders.filter(e => !e.isNoFolder);
      return [starred, recent, folders].every(e => e.length === 0);
    },
    isBoardList() {
      return this.$route.name === 'Boards-List';
    },
    isBoardStarred() {
      return this.$route.name === 'Stared-Boards' || this.isBoardList;
    },
    isBoardRecently() {
      return this.$route.name === 'Recently-Boards' || this.isBoardList;
    },
    profile() {
      return this.$store.getters['auth/getUser'];
    }
  },
  mounted() {
    EventBus.$on('UPDATE_BOARDS', async () => {
      this.loading = true;
      await this.fetchBoards();
      this.loading = false;
    });
    this.initFetch();
    this.scroll();
  },
  destroyed() {
    this.SET_BOARDS_EMPTY();
  },
  watch: {
    scrolledToBottom(val) {
      if (val == true) {
        this.onNextPage();
      }
    },
    '$i18n.locale': {
      handler() {
        this.fetchFolders();
      },
      immediate: true
    },
    $route(val) {
      this.isCollapsed = false;
    }
  },
  methods: {
    ...mapMutations(['SET_BOARDS_EMPTY']),
    ...mapActions(['fetchBoards', 'fetchBoardsNext']),
    ...foldersMapActions(['fetchFolders', 'updateFolder', 'deleteFolder']),
    ...foldersMapMutations({ setFolders: 'SET_FOLDERS' }),
    async initFetch() {
      this.loading = true;
      await this.fetchFolders();

      await this.fetchBoards();
      this.loading = false;
    },
    openCreateBoard() {
      this.$modal.show('create-board');
    },
    async onNextPage() {
      if (this.getNextPage) {
        this.loading = true;
        await this.fetchBoardsNext();
        this.loading = false;
        this.scrolledToBottom = false;
      }
    },
    scroll() {
      window.onscroll = () => {
        const bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >
          document.documentElement.offsetHeight - 50;
        if (bottomOfWindow) {
          this.scrolledToBottom = true;
        }
      };
    },
    mapBoardsToFolder(folderId) {
      const getPosition = board => board.currentMember.position;
      const sortBoardByPosition = (a, b) => getPosition(a) - getPosition(b);
      return this.getBoards.all
        .filter(board => board.currentMember.folderID === folderId)
        .sort(sortBoardByPosition);
    },
    beforeModalOpen({ params, cancel }) {
      const { folder } = params;
      if (folder.boards.length) {
        this.deletingFolder = params.folder.id;
      } else {
        cancel();
        this.deleteFolder(folder.id);
      }
    }
  }
};
</script>

<style>
.btn--empty {
  @apply text-xl py-4 px-8 bg-brand-blue font-light;
  max-width: 16rem;
}
.board-list--width {
}
</style>
