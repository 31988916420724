import { Module } from 'vuex';
import { Unsubscribe } from 'firebase';
import { RootState } from '@/types/Store';
import {
  Task,
  TaskAdd,
  TaskComment,
  TaskCommentAdd,
  TaskCommentEdit,
  TaskCommentDelete,
  TaskCommentResponse,
  TaskCopyRequest,
  TaskFilters
} from '@/types/Task';
import apis, { tasksApi, filesApi, checklistApi } from '@/services/apis';
import firebase, { getCollections } from '@/services/firebase';
import { PageInformation } from '@/types/Api';
import { ChecklistUpdate, Checklist } from '@/types/Checklist';
import {
  getBottomPositionOfGroup,
  getById,
  getNewListWithUpdate,
  getNewListWithUpdatedItem,
  isIncluded
} from './utils';
import { ColumnStatus } from '@/types/Board';
import EventBus from '@/services/eventBus';
import { Ticks } from './utils/ticks-manager';
import router from '@/router';

interface State {
  entities: Task[];
  entitiesLoading: boolean;
  currentTask?: Task;
  comments: TaskComment[];
  commentsPageInfo: PageInformation;
  firestoreListener?: Unsubscribe;
  filters: TaskFilters;
  latestUpdated: Task | null;
  latestRemoved: Task | null;
  taskFilterCount: number;
  unsubscribeTask?: () => void;
  taskLoading: boolean;
  isTicking: boolean;
  isTaskOpen: boolean;
}

interface Columns {
  [columnID: string]: Task[];
}

interface FetchTasksParams {
  q?: string;
  assigneeIDs?: number[];
  tagIDs?: number[];
  boardID: number;
}

interface SortPosition {
  (a: Task, b: Task): number;
}
interface AttachmentInComment {
  commentID: number;
  fileID: number;
}

const ticks = new Ticks();

const BOTTOM_MULTIPLIER = 2;

const sortPosition: SortPosition = ({ position: a }, { position: b }) => a - b;

export const filterAssigned = (
  assignIds: number[],
  taskAssignIds: number[]
) => {
  let isMatch = false;
  for (let index = 0; index < taskAssignIds.length; index++) {
    const id = taskAssignIds[index];
    if (assignIds.includes(id)) isMatch = true;
  }
  if (assignIds.includes(-1)) {
    if (taskAssignIds.length < 1) isMatch = true;
  }
  return isMatch;
};

export const filterTag = (tagIds: number[], taskTagIds: number[]) => {
  let isMatch = false;
  for (let index = 0; index < taskTagIds.length; index++) {
    const id = taskTagIds[index];
    if (tagIds.includes(id)) isMatch = true;
  }
  if (tagIds.includes(-1)) {
    if (taskTagIds.length < 1) isMatch = true;
  }
  return isMatch;
};
const getTask = (id: number) => tasksApi.get(id).then(e => e.data);

type TaskUpdated = Partial<Task> & {
  id: number;
};

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    entities: [],
    entitiesLoading: true,
    currentTask: undefined,
    comments: [],
    commentsPageInfo: {
      count: 0,
      page: 1,
      lastPage: 1
    },
    filters: {},
    latestUpdated: null,
    latestRemoved: null,
    taskFilterCount: 0,
    taskLoading: false,
    isTicking: false,
    isTaskOpen: false
  },
  getters: {
    getTasks({ entities, filters }) {
      let tasks = [...entities];
      if (filters.sprintId) {
        tasks = tasks.filter(t => t.sprintID === filters.sprintId);
      }
      return tasks;
    },
    getTaskCounts(state) {
      return state.entities.length;
    },
    getSortedTasks({ entities }) {
      return [...entities].sort(sortPosition);
    },
    getUnsortedTaskByColumns: (_, getters) =>
      (getters.getTasks as Task[]).reduce(
        (prev, task) => ({
          ...prev,
          [task.boardColumnID]: [...(prev[task.boardColumnID] || []), task]
        }),
        {} as Columns
      ),
    getTaskByColumns: (_1, getters) => {
      const unsortedColumns = getters.getUnsortedTaskByColumns as Columns;
      const sortedColumns = Object.keys(unsortedColumns).reduce(
        (prev, columnId) => ({
          ...prev,
          [columnId]: unsortedColumns[columnId].sort(sortPosition)
        }),
        unsortedColumns
      );
      return sortedColumns;
    },
    getCurrentTask: s => s.currentTask,
    getComments: s => {
      return s.comments;
    },
    getCommentsPageInfo: s => {
      return s.commentsPageInfo;
    },
    getLatestUpdated: ({ latestUpdated }) => latestUpdated,
    getLatestRemoved: ({ latestRemoved }) => latestRemoved,
    isTaskLoading: s => s.taskLoading,
    isEntitiesLoading: s => s.entitiesLoading
  },
  mutations: {
    SET_TASKS(state, payload: Task[]) {
      state.entities = payload;
    },
    SET_TASK(s, payload: Task) {
      payload.groupID = payload.groupID || 0;
      s.entities = getNewListWithUpdate(s.entities, payload);
      s.latestUpdated = payload;
    },
    UPDATE_TASK(s, payload: TaskUpdated) {
      s.entities = getNewListWithUpdatedItem(s.entities, payload);
      const task = getById(s.entities, payload.id);
      if (task) s.latestUpdated = task;
    },
    UPDATE_TASKS(s, payload: TaskUpdated[]) {
      s.entities = s.entities.map(item => {
        const updated = getById(payload, item.id);
        if (!updated) return item;
        return {
          ...item,
          ...updated
        };
      });
    },
    SET_CURRENT_TASK(s, payload: Task) {
      s.currentTask = payload;
    },
    UPDATE_CURRENT_TASK(state, payload: Partial<Task>) {
      if (state.currentTask) {
        state.currentTask = { ...state.currentTask, ...payload };
      }
    },
    CLOSE_TASK(s) {
      s.currentTask = undefined;
    },
    ADD_TASK(s, payload: Task) {
      if (!isIncluded(s.entities, payload)) s.entities.push(payload);
      s.latestUpdated = payload;
    },
    ADD_TASKS(s, payload: Task[]) {
      s.entities.push(...payload);
    },
    SET_TICKING(s, payload = false) {
      s.isTicking = payload;
    },
    REMOVE_TASK: (s, taskID) => {
      s.latestRemoved = getById(s.entities, taskID);
      s.entities = s.entities.filter(e => e.id !== taskID);
    },
    SET_TASK_COMMENTS(s, payload: TaskCommentResponse) {
      if (payload.pageInformation.page != 1) {
        s.comments.push(...payload.entities);
      } else {
        s.comments = payload.entities;
      }
      s.commentsPageInfo = payload.pageInformation;
    },
    ADD_TASK_COMMENT(s, payload: TaskComment) {
      s.comments.unshift(payload);
    },
    UPDATE_TASK_COMMENT(s, payload: TaskComment) {
      s.comments = s.comments.map(comment =>
        comment.id == payload.id ? payload : comment
      );
    },
    REMOVE_TASK_COMMENT(s, commentId: number) {
      s.comments = s.comments.filter(c => c.id !== commentId);
    },
    SET_FIRESTORE_LISTENER(s, unsub?: Unsubscribe) {
      s.firestoreListener = unsub;
    },
    SET_FILTERS(s, filters = {}) {
      s.filters = filters;
    },
    SET_TASKS_SPRINT(state, { nextSprint, prevSprint }) {
      const setTaskSprintId = (task: Task) => {
        const isInActiveSprint = task.sprintID === prevSprint.id;

        const shouldMove = task.boardColumn.status !== ColumnStatus.Done;
        if (isInActiveSprint && shouldMove) {
          task.sprint = nextSprint;
          task.sprintID = nextSprint.id;
        }
        return task;
      };

      state.entities = state.entities.map(setTaskSprintId);
    },
    SET_TASK_LOADING(state, payload: boolean) {
      state.taskLoading = payload;
    },
    SET_ENTITIES_LOADING(state, loading: boolean) {
      state.entitiesLoading = loading;
    },
    SET_IS_TASK_OPEN(state, payload: boolean) {
      state.isTaskOpen = payload;
    }
  },
  actions: {
    async onSnapshotFirestore({ commit, state, getters }, boardID) {
      const firestoreListener = firebase
        .firestore()
        .collection(getCollections().boards)
        .doc(boardID.toString())
        .collection(getCollections().tasks)
        .onSnapshot(e => {
          e.docChanges().map(async val => {
            if (state.isTicking) return;

            const taskID = parseInt(val.doc.id);
            const isExisted = state.entities.some(task => taskID === task.id);

            switch (val.type) {
              case 'added': {
                if (!isExisted) {
                  commit('ADD_TASK', await getTask(taskID));
                }
                break;
              }
              case 'modified': {
                const task = await getTask(taskID);
                commit('SET_TASK', task);
                if (getters.getCurrentTask?.id == taskID) {
                  commit('SET_CURRENT_TASK', task);
                }
                break;
              }
              case 'removed': {
                if (isExisted) commit('REMOVE_TASK', taskID);
                break;
              }
              default:
                break;
            }
          });
        });
      commit('SET_FIRESTORE_LISTENER', firestoreListener);
    },
    async fetchTasks({ commit, dispatch }, request: FetchTasksParams) {
      const { boardID, ...params } = request;
      const startAt = new Date().getTime();

      ticks.cancelAll();

      if (!boardID) return;
      commit('SET_ENTITIES_LOADING', true);

      const { data: tasks } = await apis.get(`/boards/${boardID}/tasks`, {
        params
      });

      const paramKeys = Object.keys(params) as Array<keyof typeof params>;
      const hasParams = paramKeys.filter(t => params[t]).length > 0;

      commit('SET_TICKING', true);
      commit('SET_TASKS', tasks);

      if (!hasParams) dispatch('onSnapshotFirestore', boardID);

      const MIN_WAIT = 1200;
      const processingTime = new Date().getTime() - startAt;
      ticks.start(() => {
        commit('SET_ENTITIES_LOADING', false);
        commit('SET_TICKING', false);
      }, Math.max(0, MIN_WAIT - processingTime)); // for better ux
    },
    addTask({ state }, taskAdd: Partial<TaskAdd>) {
      const { filters } = state;

      if (filters.sprintId) taskAdd.sprintID = filters.sprintId;
      return tasksApi.create(taskAdd as TaskAdd);
    },
    fetchTaskLocal({ commit, getters }, id: number) {
      const existingTask = getById(getters.getTasks as Task[], id);

      if (existingTask) {
        commit('SET_TASK', existingTask);
        commit('SET_CURRENT_TASK', existingTask);
      }
      return existingTask;
    },
    async fetchTask({ commit, dispatch }, id: number) {
      commit('SET_TASK_LOADING', true);

      dispatch('fetchTaskLocal', id);

      const startTime = new Date().getTime();
      const A_SECOND = 1000;
      const { data: task } = await tasksApi.get(id);
      const spentTime = new Date().getTime() - startTime;
      const toWait = A_SECOND - spentTime;

      commit('SET_TASK', task);
      commit('SET_CURRENT_TASK', task);

      setTimeout(() => commit('SET_TASK_LOADING', false), toWait);
    },
    subscribeTask(
      { commit, state },
      {
        taskId,
        boardId,
        shareLinkId
      }: { taskId: number; boardId: number; shareLinkId?: number }
    ) {
      const {
        tasks: tasksCollection,
        boards: boardsCollection
      } = getCollections();
      state.unsubscribeTask = firebase
        .firestore()
        .collection(boardsCollection)
        .doc(boardId.toString())
        .collection(tasksCollection)
        .doc(taskId.toString())
        .onSnapshot(async () => {
          const task = await (shareLinkId
            ? tasksApi.getTaskBySharelinkId(shareLinkId).then(s => s.data)
            : getTask(taskId));
          commit('SET_TASK', task);
          commit('SET_CURRENT_TASK', task);
        });
    },
    unsubscribeTask({ state }) {
      state.unsubscribeTask?.();
    },
    fetchTaskLink({ commit }, linkId: number) {
      const fetchData = async () => {
        const { data } = await tasksApi.getTaskBySharelinkId(linkId);
        commit('SET_CURRENT_TASK', data);
      };

      fetchData();
    },
    refreshCurrentTask({ dispatch, state }) {
      const taskID = (state.currentTask as Task).id;
      dispatch('fetchTask', taskID);
    },
    async setTask({ commit, getters }, req: Task) {
      if (req.sprintID && !req.position) {
        req.position =
          getBottomPositionOfGroup(
            'sprintID',
            req.sprintID,
            getters.getSortedTasks as Task[]
          ) * BOTTOM_MULTIPLIER;
      }
      const { data } = await tasksApi.update(req.id, req);
      commit('SET_TASK', data);
      commit('SET_CURRENT_TASK', data);

      EventBus.$emit('MY_TASK_UPDATES', {});
    },
    async addTaskAssign({ commit, state }, userID: number) {
      const res = await apis.post(
        `/tasks/${state.currentTask?.id}/assign/${userID}`
      );
      commit('SET_TASK', res.data);
    },
    async removeTaskAssign({ commit, state }, userID: number) {
      const res = await apis.delete(
        `/tasks/${state.currentTask?.id}/assign/${userID}`
      );
      commit('SET_TASK', res.data);
    },
    async addTaskTesterAssign({ commit, state }, userID: number) {
      const res = await apis.post(
        `/tasks/${state.currentTask?.id}/assign/responsible/${userID}`
      );
      commit('SET_TASK', res.data);
    },
    async removeTaskTesterAssign({ commit, state }, userID: number) {
      const res = await apis.delete(
        `/tasks/${state.currentTask?.id}/assign/responsible/${userID}`
      );
      commit('SET_TASK', res.data);
    },
    async archiveTask({ commit }, taskID: number) {
      await apis.post(`/tasks/${taskID}/archive`);
      commit('REMOVE_TASK', taskID);
    },
    async removeTask({ commit }, taskID: number) {
      await tasksApi.delete(taskID);
      commit('REMOVE_TASK', taskID);
    },
    async fetchTaskComments(
      { commit },
      comment: { taskID: number; page: number; shareLinkCode: number }
    ) {
      const res = await apis.get(`tasks/${comment.taskID}/comments`, {
        params: {
          page: comment.page,
          shareLinkCode: comment.shareLinkCode
        }
      });
      commit('SET_TASK_COMMENTS', res.data);
    },
    async setTaskComment({ commit }, payload: TaskCommentAdd) {
      const res = await apis.post(`/tasks/${payload.taskID}/comments`, payload);
      commit('ADD_TASK_COMMENT', res.data);
    },
    async editTaskComment({ commit }, payload: TaskCommentEdit) {
      const res = await apis.put(
        `tasks/${payload.taskID}/comments/${payload.commentID}`,
        { fileIDs: payload.fileIDs, message: payload.message }
      );
      commit('UPDATE_TASK_COMMENT', res.data);
    },
    async deleteTaskComment({ commit }, req: TaskCommentDelete) {
      const res = await apis.delete(
        `tasks/${req.taskID}/comments/${req.commentID}`
      );
      const { message } = res.data;
      const success = message === `Success` || message === `สำเร็จ`;
      if (res.data && success) {
        commit('REMOVE_TASK_COMMENT', req.commentID);
      }
    },
    clearTasks({ commit }) {
      commit('SET_TASKS', []);
    },
    offSnapshotFirestore({ state, dispatch }, e = { clear: true }) {
      if (e.clear) dispatch('clearTasks');
      state.firestoreListener?.();
    },
    async addAttachment({ state }, files: FileList) {
      if (!state.currentTask) return;
      const { data } = await filesApi.upload('tasks', files);
      return filesApi.taskUpload(
        state.currentTask.id,
        data.map(e => e.id)
      );
    },
    removeAttachment({ state }, fileID) {
      if (!state.currentTask) return;
      return tasksApi.removeAttachment(state.currentTask.id, fileID);
    },
    removeAttachmentInComment({ state }, params: AttachmentInComment) {
      const { commentID, fileID } = params;
      if (!state.currentTask) return;
      return tasksApi.removeAttachmentInComment(
        state.currentTask.id,
        commentID,
        fileID
      );
    },
    addTag({ rootGetters, getters }, tagID: number) {
      const taskID: number =
        getters.getCurrentTask?.id || router.currentRoute.query.t;
      const boardID: number = rootGetters['boards/getActiveBoard'].id;
      return tasksApi.addTag({
        taskID,
        boardID,
        tagID
      });
    },
    removeTag({ rootGetters, getters }, tagID: number) {
      const taskID: number =
        getters.getCurrentTask?.id || router.currentRoute.query.t;
      const boardID: number = rootGetters['boards/getActiveBoard'].id;
      return tasksApi.removeTag({
        taskID,
        boardID,
        tagID
      });
    },
    addChecklist({ getters }, name: string) {
      const currentTask: Task = getters.getCurrentTask;
      const checklists = currentTask.checklists || [];
      const position =
        (checklists[checklists.length - 1]?.position || 0.5) *
        BOTTOM_MULTIPLIER;
      return checklistApi(currentTask.id).create({ name, position });
    },
    setChecklist({ getters }, params: { id: number } & ChecklistUpdate) {
      const { id, ...payload } = params;
      const taskID: number = getters.getCurrentTask.id;
      return checklistApi(taskID).setChecklist(id, payload);
    },
    removeChecklist({ getters }, id: number) {
      const taskID: number = getters.getCurrentTask.id;
      return checklistApi(taskID).delete(id);
    },
    toggleCompleteChecklist({ getters }, checklist: Checklist) {
      const taskID: number = getters.getCurrentTask.id;
      const initApi = checklistApi(taskID);
      if (checklist.isCompleted) return initApi.unsetComplete(checklist.id);
      return initApi.setComplete(checklist.id);
    },
    copyTask(_, copy: TaskCopyRequest) {
      return tasksApi.copyTask(copy);
    },
    readTask(_, id: number) {
      return tasksApi.readTask(id);
    },
    removeTagFilter({ getters, commit }, tagId: number) {
      const tasks = getters.getTasks as Task[];

      for (const property in tasks) {
        const task: Task = tasks[property];
        const result = task.tags?.filter(tag => tag.tagID == tagId);
        if (result?.length) {
          const tags = task.tags?.filter(tag => {
            return tag.tagID != tagId;
          });
          const id = task.id;
          commit('UPDATE_TASK', { id, tags });
        }
      }
    }
  }
};

export default module;
