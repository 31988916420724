<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.875 2.13883H3.12496C2.93157 2.13883 2.74611 2.21566 2.60936 2.3524C2.47262 2.48915 2.39579 2.67461 2.39579 2.868V8.70133C2.39579 8.89472 2.47262 9.08019 2.60936 9.21693C2.74611 9.35368 2.93157 9.4305 3.12496 9.4305H4.94788C5.17428 9.4305 5.39757 9.48321 5.60006 9.58446C5.80256 9.68571 5.9787 9.83271 6.11454 10.0138L7.49996 11.8608L8.88538 10.0138C9.02122 9.83271 9.19736 9.68571 9.39986 9.58446C9.60235 9.48321 9.82564 9.4305 10.052 9.4305H11.875C12.0683 9.4305 12.2538 9.35368 12.3906 9.21693C12.5273 9.08019 12.6041 8.89472 12.6041 8.70133V2.868C12.6041 2.67461 12.5273 2.48915 12.3906 2.3524C12.2538 2.21566 12.0683 2.13883 11.875 2.13883ZM3.12496 1.40967C2.73819 1.40967 2.36725 1.56331 2.09376 1.8368C1.82027 2.11029 1.66663 2.48123 1.66663 2.868L1.66663 8.70133C1.66663 9.08811 1.82027 9.45904 2.09376 9.73253C2.36725 10.006 2.73819 10.1597 3.12496 10.1597H4.94788C5.06108 10.1597 5.17272 10.186 5.27397 10.2366C5.37522 10.2873 5.46329 10.3608 5.53121 10.4513L6.91663 12.2983C6.98455 12.3889 7.07262 12.4624 7.17387 12.513C7.27512 12.5636 7.38676 12.59 7.49996 12.59C7.61316 12.59 7.7248 12.5636 7.82605 12.513C7.9273 12.4624 8.01537 12.3889 8.08329 12.2983L9.46871 10.4513C9.53663 10.3608 9.6247 10.2873 9.72595 10.2366C9.8272 10.186 9.93884 10.1597 10.052 10.1597H11.875C12.2617 10.1597 12.6327 10.006 12.9062 9.73253C13.1796 9.45904 13.3333 9.08811 13.3333 8.70133V2.868C13.3333 2.48123 13.1796 2.11029 12.9062 1.8368C12.6327 1.56331 12.2617 1.40967 11.875 1.40967H3.12496Z"
  />
</template>

<script>
export default {};
</script>
