































































































import Vue, { PropType } from 'vue';
import VClamp from 'vue-clamp';
import { ObserveVisibility } from 'vue-observe-visibility';

import BoardListItemMenu from './board-list-item-menu.vue';
import { Board } from '@/types/Board';
import BoardStar from '@/components/board/board-star.vue';
import BoardUtils from '@/mixins/BoardUtils';
import { mapActions } from 'vuex';

type ParentProps = {
  dragging: boolean;
  isStatic: boolean;
  isArchive: boolean;
  isCallbackClick: boolean;
};

type ParentPropsRecord = Record<keyof ParentProps, boolean>;

export default Vue.extend({
  components: {
    BoardListItemMenu,
    BoardStar,
    VClamp
  },
  mixins: [BoardUtils],
  directives: { ObserveVisibility },
  props: {
    board: {
      type: Object as PropType<Board>
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    isCreateArchive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    parentProps(): ParentProps {
      return this.$parent.$parent.$props as ParentPropsRecord;
    },
    progagationPreventor(): string {
      return 'prevent-propagation';
    }
  },
  methods: {
    ...mapActions('boards', ['toggleBoardStarred']),
    toBoard(board: Board, ev: MouseEvent) {
      if (this.parentProps.dragging) return;
      if (this.parentProps.isStatic) return this.$emit('click', board);
      if (this.parentProps.isArchive) {
        return;
      }
      const findParentByClass = (
        el: EventTarget | null,
        targetClass: string
      ): boolean => {
        const parent = (el as HTMLElement)?.parentElement;
        if (parent) {
          if (parent.classList.contains(targetClass)) return true;
          return findParentByClass(parent, targetClass);
        }
        return false;
      };
      if (findParentByClass(ev.target, this.progagationPreventor)) return;

      if (this.parentProps.isCallbackClick) return this.$emit('click', board);
      else
        this.$router.push({
          name: 'Boards-Id',
          params: {
            id: board.id.toString()
          }
        });
    },
    getBoardIcon(board: Board) {
      return ['board-kanban', 'board-waterfall', 'board-agile'][board.type];
    },
    getBoardIconName(board: Board) {
      return ['Kanban Board', 'Waterfall Board', 'Agile Board'][board.type];
    }
  }
});
