<template>
  <div v-if="countTask > 0">
    <div class="bg-green-success w-full h-2" />
    <div class="my-3 flex">
      <base-icon class="mt-1">
        <icon-report-done />
      </base-icon>
      <div>
        <span
          >งานที่เสร็จแล้ว (จำนวน {{ countTask }} งานจาก
          {{ boardTaskDone.length }} บอร์ด)</span
        >
        <p class="text-gray-caption">
          งานทั้งหมดในช่วงวันที่
          {{ formatShowDate(dateSelect.start, dateSelect.end) }}
          ที่ผ่านมา
        </p>
      </div>
    </div>

    <div v-for="board in boardTaskDone" :key="board.id" class="py-4">
      <div class="mb-4">
        <base-icon class="ml-5 " name="board" view-box="0 2 18 14" size="20">
          <icon-board />
        </base-icon>
        <span class="font-medium">
          {{ board.Name }}
        </span>
      </div>
      <div class="border px-4 py-4 rounded">
        <div
          v-for="(task, index) in board.Tasks"
          :key="index"
          class="px-4 mb-4"
          :class="{ 'border-b': index + 1 < board.Tasks.length }"
        >
          <div class="break-words">
            {{ task.Name }}
          </div>
          <div class="flex mt-2 text-sm">
            <div class="grid w-2/12">
              <span class="table--header">เสร็จสิ้นเมื่อ</span>
              <span>{{ task.CompletedAt }}</span>
            </div>
            <div class="grid w-2/12">
              <span class="table--header">บันทึกเวลาทั้งหมด</span>
              <span>{{ task.TotalTimeSpentString }}</span>
            </div>
            <div class="grid w-2/12">
              <span class="table--header">คะแนน</span>
              <span>{{ task.StoryPoint }}</span>
            </div>
          </div>
          <div class="grid mb-2 text-sm mt-2">
            <span class="table--header">รายละเอียด</span>
            <span class="text-10-line" v-html="task.Description || '-'" />
          </div>
          <span class="table--header">ผู้รับผิดชอบ</span>
          <div
            v-for="(assignees, index) in task.Assignees"
            :key="index"
            class="grid mt-2"
          >
            <div class="flex text-sm mb-2">
              <div class="mr-2">
                <img class="profile-img" :src="assignees.ProfilePicture" />
              </div>
              <div class="w-5/12">
                <p>{{ assignees.Name }}</p>
                <p>{{ assignees.Email }}</p>
              </div>
              <div class="grid">
                <p class="table--header">บันทึกเวลา</p>
                <p>{{ assignees.TotalTimeSpentString }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconReportDone from '@/components/icons/IconReportDone.vue';
import MarkdownTextMixin from '@/mixins/MarkdownText';
import IconBoard from '@/components/icons/IconBoard.vue';
import FormatStartEndDate from '@/mixins/FormatStartEndDate.ts';

export default {
  mixins: [MarkdownTextMixin, FormatStartEndDate],
  components: {
    IconReportDone,
    IconBoard
  },
  props: {
    boardTaskDone: [],
    isTeamReport: {
      type: Boolean,
      default: false
    },
    dateSelect: []
  },
  data() {
    return {
      boardFilter: [],
      groupBord: [],
      countTask: 0,
      user: {
        profilePicture: ''
      }
    };
  },
  watch: {
    boardTaskDone(val) {
      this.countTask = 0;
      val.forEach(element => {
        this.countTask += element.TotalDoneTasks;
      });
    }
  }
};
</script>

<style scoped>
.table--header {
  @apply text-gray-caption underline text-sm;
}

.profile-img {
  @apply rounded-full;
  width: 35px;
  height: 35px;
}
</style>
