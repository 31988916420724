<template>
  <div class="mt-4" :class="{ 'pb-24': filterPerPage.length < 10 }">
    <p class="text-xlpt-1">{{ $t('team.members.members') }}</p>
    <div class="flex items-centers py-4">
      <div class="relative">
        <base-input-text
          v-model="q"
          inner-class="base-input--gray"
          :placeholder="$t('team.placeholder')"
          icon-fa="search"
          type="text"
          class="input-pr-8"
          style="background: #F2F3F4;"
        />
        <div v-if="q" class="icon--clear" @click="q = ''">
          <base-icon size="14" name="close">
            <icon-close />
          </base-icon>
        </div>
      </div>
      <div class="flex-1 text-right">
        <base-button
          v-if="!isUserViewer"
          wide="py-2"
          icon="UserPlus"
          responsive
          class="text-brand-blue bg-white border-brand-blue"
          color="transparent"
          @click="openMemberInvite"
          >{{ $t('team.members.invite') }}</base-button
        >
      </div>
    </div>

    <table class="w-full my-3">
      <thead class="text-gray-caption text-sm uppercase">
        <tr>
          <th class="font-normal" />
          <th class="font-normal text-left">{{ $t('team.members.name') }}</th>
          <th class="font-normal">{{ $t('team.members.role') }}</th>
          <th class="font-normal">{{ $t('team.members.more') }}</th>
        </tr>
      </thead>
      <tbody class="border border-gray-border">
        <tr
          v-for="u in filterPerPage"
          :key="`member-${u.id}`"
          class="border-b border-gray-border py-2"
        >
          <td class="p-2 w-16">
            <img
              :src="(u.user && u.user.profilePicture) || defaultImageSrc"
              class="w-10 h-10 object-cover rounded-full"
            />
          </td>
          <td>
            <div
              v-if="u.status == TeamMemberStatus.Pending"
              class="flex float-left"
            >
              <v-clamp autoresize :max-lines="1" class="max-w-xs">
                {{ u.inviteToEmail }}
              </v-clamp>
              <div
                class="ml-2 px-2 status--awaiting-response py-1 rounded-full text-xs mx-auto"
              >
                {{ $t('subscription.members.pending') }}
              </div>
            </div>
            <div v-else>
              <v-clamp autoresize :max-lines="1" class="max-w-xs">
                {{ u.user.name }}
              </v-clamp>
              <v-clamp
                autoresize
                :max-lines="1"
                class="max-w-xs text-sm text-gray-500"
              >
                {{ u.user.email }}
              </v-clamp>
            </div>
          </td>
          <td class="text-center text-sm">
            <div v-if="u.role == TeamMemberRole.Editor">
              {{ $t('team.members.editor') }}
            </div>
            <div v-if="u.role == TeamMemberRole.Admin">
              {{ $t('team.members.admin') }}
            </div>
            <div v-if="u.role == TeamMemberRole.Viewer">
              {{ $t('team.members.viewer') }}
            </div>
          </td>
          <td>
            <select-team-role
              v-if="
                (u.isCurrentUser && !isAdmin) ||
                  (isAdmin && countAdmin > 1) ||
                  (!u.isCurrentUser && isAdmin)
              "
              :key="`select-role-${u.id}-${countAdmin}`"
              :icon-btn="'dots-horizontal'"
              :member="u"
              :is-dot-button="true"
              :show-role="
                (isAdmin && !u.isCurrentUser) || (isAdmin && countAdmin > 1)
              "
              @removeUser="onKickMember(u)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <base-pagination
      v-if="numOfMember > 10"
      :list="numOfMember"
      @pageCurrent="setPageCurrent"
    />
    <modal-team-invite />
  </div>
</template>

<script>
import VClamp from 'vue-clamp';
import ModalTeamInvite from '@/components/modal/modal-team-invite';
import { TeamMemberStatus, TeamMemberRole } from '@/types/Team';
import SelectTeamRole from '@/components/select/select-team-role';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import IconClose from '@/components/icons/IconClose';

export default {
  components: {
    VClamp,
    ModalTeamInvite,
    SelectTeamRole,
    IconClose
  },
  name: 'Workspace-Member',
  data() {
    return {
      loading: false,
      q: '',
      pageSelected: 1,
      dataPerPage: 10
    };
  },
  watch: {
    '$route.params.id'() {
      this.q = '';
      this.fetch();
    },
    q: {
      handler(val) {
        this.pageSelected = 1;
      },
      deep: true
    }
  },
  async mounted() {
    this.fetch();
  },
  computed: {
    numOfMember() {
      const numAll = this.$store.getters['teams/getMembers'].length;
      const numFiltered = this.members.length;
      return numFiltered != numAll ? numFiltered : numAll;
    },
    TeamMemberStatus() {
      return TeamMemberStatus;
    },
    TeamMemberRole() {
      return TeamMemberRole;
    },
    members() {
      if (this.q)
        return this.$store.getters['teams/getMembers'].filter(
          f =>
            (f.user &&
              f.user.name.toLowerCase().search(this.q.toLowerCase()) >= 0) ||
            (f.user &&
              f.user.email.toLowerCase().search(this.q.toLowerCase()) >= 0) ||
            (f.inviteToEmail &&
              f.inviteToEmail.toLowerCase().search(this.q.toLowerCase()) >= 0)
        );
      else return this.$store.getters['teams/getMembers'];
    },
    isAdmin() {
      const currentMember = this.members.find(u => u.isCurrentUser);
      return currentMember?.role === TeamMemberRole.Admin;
    },
    team() {
      return this.$store.getters['teams/getTeam'];
    },
    defaultImageSrc() {
      return `https://storage.googleapis.com/workkami-files-dev/statics/f6dd2895acbe4cf4b6d2efa5f279855f_thumb.png`;
    },
    isUserViewer() {
      return this.$store.getters['teams/isUserViewer'];
    },
    countAdmin() {
      const users = this.$store.getters['teams/getMembers'];
      const admins = users.filter(u => u.role == TeamMemberRole.Admin);
      return admins.length;
    },
    filterPerPage() {
      const queryResultPerPage = this.members.slice(
        (this.pageSelected - 1) * this.dataPerPage,
        this.pageSelected * this.dataPerPage
      );
      return queryResultPerPage;
    }
  },
  methods: {
    setPageCurrent(e) {
      this.pageSelected = e;
    },
    setPerPage(e) {
      this.dataPerPage = e;
    },
    async fetch() {
      await this.$store.dispatch('teams/clearMembers');
      await this.$store.dispatch('teams/fetchMembers', this.$route.params.id);
    },
    openMemberInvite() {
      this.$modal.show('invite-team');
    },
    onKickMember(member) {
      const teamName = this.team.name || '';
      this.$modal.show(
        DialogConfirm,
        {
          title: member.isCurrentUser
            ? this.$t('team.buttonConfirm.leave.title')
            : this.$t('team.buttonConfirm.remove.title'),
          description: member.isCurrentUser
            ? `${this.$t(
                'team.buttonConfirm.leave.description1'
              )}"<strong>${teamName}</strong>"${this.$t(
                'team.buttonConfirm.leave.desctiption2'
              )}`
            : `${this.$t('team.buttonConfirm.remove.description1')} "<strong>${
                member.user ? member.user.name : member.inviteToEmail
              }</strong>"?`,
          color: 'text-red-600',
          button: member.isCurrentUser
            ? this.$t('team.buttonConfirm.leave.name')
            : this.$t('team.buttonConfirm.remove.name'),
          confirmAction: 'teams/kickMember',
          confirmData: member
        },
        {
          height: 'auto',
          width: 434,
          scrollable: true
        }
      );
    }
  }
};
</script>

<style scoped>
.icon--clear {
  @apply px-3 absolute right-0 inset-y-0 cursor-pointer z-10 inline-flex place-items-center;
}
.status--active {
  color: #88b864;
  background-color: #eaf5e3;
}
.status--awaiting-response {
  color: #e86b1a;
  background-color: #fef0e3;
}
.text-pending {
  @apply text-center font-medium text-3xl;
}
</style>
