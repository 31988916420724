import { AxiosPromise } from 'axios';

export enum FileType {
  Image = 1,
  Video,
  Document
}

export interface FileUploadFn {
  (
    prefix: 'users' | 'boards' | 'tasks' | 'comments' | 'statics' | 'teams',
    formData: FileList
  ): AxiosPromise<FileUpload[]>;
}

export interface FileUpload {
  createdAt: string;
  deletedAt: string;
  fileName: string;
  fileSize: number;
  id: number;
  typeUpload: FileType;
  updatedAt: string;
  thumbnailImageUrl?: string;
  originalImageUrl?: string;
  fileUrl?: string;
}
