<template>
  <div class="border grid rounded border-gray-border">
    <div class="p-3">
      <div class="flex border-b pb-3 border-gray-border">
        <base-icon> <icon-payment-cards /> </base-icon>
        <p>{{ $t('payment') }}</p>
        <p
          v-if="getCardList.length"
          class="text-brand-blue cursor-pointer ml-auto"
          @click="allCard"
        >
          {{ $t('view_all') }}
        </p>
      </div>
      <div v-if="getCardList.length" class="grid h-full grid-rows-3">
        <div
          v-for="(card, index) in getCardList"
          :key="index"
          :class="{
            'border-b border-gray-border': index <= 1 && getCardList.length > 1
          }"
          style="max-height: 92px;"
          class="self-center"
        >
          <payment-card-current
            v-if="index < 3"
            :is-active="index == activeCard"
            :card="card"
          />
        </div>
      </div>
      <div v-else class="grid justify-items-center mt-16">
        <p class="mb-2">{{ $t('add_card') }}</p>
        <div
          class="text-sm text-gray-caption text-center"
          v-html="$t('add_card_description')"
        />
        <base-button
          color="brand"
          style="margin-top:18px"
          wide="px-10"
          @click="addCard"
          >{{ $t('add_card_btn') }}</base-button
        >
      </div>
    </div>
    <div
      v-if="getCardList.length"
      class="border-t p-3 mt-auto border-gray-border"
      style="max-height: 46px"
    >
      <base-button
        class="p-0 text-sm"
        color="text-brand"
        icon="Plus"
        icon-size="14"
        type="submit"
        @click="addCard"
        >{{ $t('add_card_btn') }}</base-button
      >
    </div>
    <modal
      ref="card-form"
      name="card-form"
      scrollable
      :width="400"
      height="auto"
    >
      <div class="border-b padding-title-modal">
        {{ $t('subscription.payment.add_method') }}
      </div>
      <payment-card-form :is-add-card="true" />
    </modal>
    <modal-payment-all-card
      v-if="showModalAllCard"
      :width="900"
      scrollable
      @close="showModalAllCard = false"
    />
  </div>
</template>
<script type="text/javascript" src="https://cdn.omise.co/omise.js"></script>

<script>
import IconPaymentCards from '@/components/icons/IconPaymentCards';
import paymentCardCurrent from '@/components/payment/payment-card-current.vue';
import paymentCardForm from '@/components/payment/payment-card-form';
import modalPaymentAllCard from '@/components/modal/modal-payment-all-card';
import { mapGetters } from 'vuex';

export default {
  components: {
    IconPaymentCards,
    paymentCardCurrent,
    paymentCardForm,
    modalPaymentAllCard
  },
  data() {
    return {
      activeCard: 1,
      showModalAllCard: false
    };
  },
  computed: {
    ...mapGetters('subscription', ['getCardList', 'getCardDefault'])
  },
  methods: {
    addCard() {
      this.$modal.show('card-form');
    },
    allCard() {
      this.showModalAllCard = true;
      this.$modal.show('all-card');
    }
  },
  async mounted() {
    await this.$store.dispatch('subscription/fetchCards');
    this.getCardDefault;
  }
};
</script>
<i18n>
{
  "en": {
    "payment": "Payment",
    "view_all": "View all",
    "add_card": "Add Card",
    "add_card_description": "Please add your card to proceed with payment.<br/>You can add more than one card and set your card as default.",
    "add_card_btn": "Add Card"
  },
  "th": {
    "payment": "การชำระเงิน",
    "view_all": "ดูทั้งหมด",
    "add_card": "ดำเนินการเพิ่มบัตร",
    "add_card_description": "กรุณาเพิ่มบัตรเพื่อดำเนินการชำระเงิน<br/>โดยท่านสามารถเพิ่มบัตรได้มากกว่าหนึ่งใบและตั้งค่าเพื่อ<br />กำหนดบัตรหลักได้",
    "add_card_btn": "เพิ่มบัตร"
  }
}
</i18n>
