<template>
  <span>{{ formatDate }}</span>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    date: String
  },
  computed: {
    formatStoreDate() {
      const dateFormat = moment(this.date).format('YYYYMMDD HH:mm:ss');
      return dateFormat;
    },
    differentTime() {
      const currentDate = moment().format('YYYYMMDD HH:mm:ss');
      const setDateNow = moment(currentDate, 'YYYYMMDD HH:mm:ss');
      const setDateChange = moment(this.formatStoreDate, 'YYYYMMDD HH:mm:ss');
      const diffDate = setDateNow.diff(setDateChange, 'minute');
      return diffDate;
    },
    checkYear() {
      const currentDate = moment().format('YYYY');
      const dateFormat = moment(this.date).format('YYYY');
      if (currentDate !== dateFormat) {
        return true;
      } else {
        return false;
      }
    },
    formatDate() {
      const minute = this.differentTime;
      const A_MINUTE = 1;
      const AN_HOUR = A_MINUTE * 60;
      const A_DAY = AN_HOUR * 24;
      const TWO_DAYS = A_DAY * 2;
      const A_WEEK = A_DAY * 7;

      const format = f =>
        moment(this.date)
          .locale(this.$i18n.locale)
          .format(this.$t('humanTime.' + f));

      if (this.checkYear === true) {
        return format('year');
      } else {
        if (minute > A_DAY) {
          if (minute > A_WEEK) {
            return format('week');
          } else {
            if (minute > TWO_DAYS) {
              return format('twoday');
            } else {
              return format('yesterday');
            }
          }
        } else {
          if (minute < A_MINUTE) {
            return this.$t('humanTime.justnow');
          } else if (minute < AN_HOUR) {
            return this.$tc('humanTime.minute', minute);
          } else {
            const checkHour = Math.floor(minute / AN_HOUR);
            return this.$tc('humanTime.hour', checkHour);
          }
        }
      }
    }
  }
};
</script>

<style></style>
