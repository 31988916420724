import DragAndDrop, { PositionedItem } from './DragAndDrop';

interface DraggableConfig {
  'delay-on-touch-only': boolean;
  delay: number;
  forceFallback: boolean;
  fallbackOnBody?: boolean;
  bubbleScroll?: boolean;
  scrollSensitivity?: number;
}

interface DragEventData {
  newIndex: number;
  element: PositionedItem;
}

interface DragEvent {
  moved: DragEventData;
  added: DragEventData;
}

interface GetDragPosition {
  (list: PositionedItem[], event: DragEvent): Partial<PositionedItem>;
}

const APPLE_VENDOR = 'Apple Computer, Inc.';

export default DragAndDrop.extend({
  computed: {
    $is_safari(): boolean {
      const result = navigator.vendor === APPLE_VENDOR;
      return result;
    },
    draggableConfig(): DraggableConfig {
      return {
        'delay-on-touch-only': true,
        delay: 200,
        forceFallback: true,
        bubbleScroll: true
      };
    }
  },
  methods: {
    getDragPosition(
      list: PositionedItem[],
      event: DragEvent
    ): Partial<PositionedItem> {
      const { element, newIndex } = event.moved || event.added;
      const position = this.getPosition(list, newIndex);
      return {
        id: element.id,
        position
      };
    },
    sortPosition: (a: PositionedItem, b: PositionedItem) =>
      a.position - b.position
  }
});
