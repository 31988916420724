<template>
  <vue-slider
    v-model="localValue"
    tooltip="none"
    :rail-style="{
      'background-color': '#E9EBF0'
    }"
    :process-style="{
      'background-color': '#168AE2'
    }"
    :dot-style="{
      'box-shadow': '0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32)'
    }"
    :height="baseSize"
    :dot-size="baseSize + 10"
  />
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'base-input-progress',
  components: {
    VueSlider
  },
  props: {
    value: {
      type: Number,
      default: 40
    }
  },
  data() {
    return {
      startMousePosition: 0
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    baseSize() {
      return 15;
    }
  }
};
</script>

<style scoped>
.input-progress__background {
  @apply w-full h-full bg-brand-blue;
}
.input-progress__drag {
  @apply absolute bg-white w-6 h-6  rounded-full border;
  top: calc(-0.25rem / 2);
}
</style>
