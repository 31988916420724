import { Vue, Component } from 'vue-property-decorator';
import { ChartOptions } from 'chart.js';

@Component
export default class ChartBarStacked extends Vue {
  get chartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      elements: {
        rectangle: {
          borderWidth: 1,
          borderColor: 'white'
        }
      },
      tooltips: {
        cornerRadius: 2,
        bodySpacing: 4,
        titleMarginBottom: 8,
        xPadding: 12,
        yPadding: 12
      },
      scales: {
        xAxes: [{ stacked: true }],
        yAxes: [
          {
            stacked: true,
            ticks: {
              display: true,
              beginAtZero: true,
              stepSize: 1,
              callback: val => `${val} h`
            }
          }
        ]
      }
    };
  }
}
