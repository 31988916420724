<template>
  <component
    :is="draggable ? 'v-draggable' : 'div'"
    ref="container"
    data-testid="board-list"
    class="board-list"
    :class="{ 'overflow-auto': isOneRow, 'flex-wrap': !isOneRow }"
    keep-alive
    v-bind="componentProps"
    :style="{ 'min-height': height }"
    v-on="componentEvents"
  >
    <wrapper-observe-visibility
      v-for="board in list"
      :key="board.id"
      :data-testid="`board-card-${board.name}`"
      class="card-wrapper board-list__item"
      :once="true"
      :throttle="300"
      style="min-width: 265px"
    >
      <template #default="{ visible }">
        <board-list-item
          :is-create-archive="isArchive"
          :is-visible="visible"
          :board="board"
          @click="$emit('click', $event)"
        />
      </template>
    </wrapper-observe-visibility>
    <div
      v-if="addOn"
      v-show="isAddShown && !hideList"
      slot="footer"
      key="board-list-add-on"
      class="card-wrapper"
    >
      <div
        class="board-list__card board-list__card--ghost"
        style="min-height: 125px;"
        data-testid="create-board-btn"
        @click="openCreateBoard"
      >
        <base-icon name="plus">
          <icon-plus />
        </base-icon>
        <p>{{ $t('dashboard.new') }}</p>
      </div>
    </div>
  </component>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import VClamp from 'vue-clamp';
import VDraggable from 'vuedraggable';
import Vuedraggable from '@/mixins/Vuedraggable';
import BoardUtils from '@/mixins/BoardUtils';
import RecalculatePosition from '@/mixins/RecalculatePosition';

import BoardStar from '@/components/board/board-star';
import IconPlus from '@/components/icons/IconPlus';
import HumanDate from '@/components/date/human-date.vue';
import BoardListItem from './board-list/board-list-item.vue';
import WrapperObserveVisibility from '../wrapper/wrapper-observe-visibility.vue';

const { mapActions, mapMutations } = createNamespacedHelpers('boards');

const pullPosition = board => ({
  ...board,
  position: board.currentMember.position
});
const pushPosition = ({ position, currentMember, ...board }) => ({
  ...board,
  currentMember: { ...currentMember, position }
});

export default {
  mixins: [Vuedraggable, BoardUtils, RecalculatePosition],
  components: {
    IconPlus,
    BoardStar,
    VClamp,
    VDraggable,
    HumanDate,
    BoardListItem,
    WrapperObserveVisibility
  },
  props: {
    addOn: Boolean,
    boards: {
      type: Array,
      default: () => []
    },
    isArchive: {
      type: Boolean,
      default: false
    },
    isStatic: Boolean,
    hideList: Boolean,
    isCallbackClick: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    folder: {
      type: Object,
      default: null
    },
    frozen: {
      type: Boolean,
      default: false
    },
    isOneRow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalConfig: {
        height: 'auto',
        maxWidth: 420,
        adaptive: true
      },
      dragging: false,
      list: [],
      parentScrollEl: true,
      isAddShown: true,
      height: null
    };
  },
  computed: {
    options() {
      return { disabled: !this.draggable };
    },
    listDependency() {
      return [this.boards, this.hideList];
    },
    componentProps() {
      if (!this.draggable) return null;
      return {
        ...this.draggableConfig,
        ...this.options,
        draggable: '.board-list__item',
        group: 'board-list',
        dragClass: 'board-list__item--drag',
        value: this.list,
        scroll: this.parentScrollEl,
        'scroll-sensitivity': 64 * 2, // use nav height * 2
        'scroll-speed': 30
      };
    },
    componentEvents() {
      if (!this.draggable) return null;
      return {
        input: value => {
          this.list = value;
        },
        start: this.dragStart,
        change: this.onDragChange,
        end: this.dragend
      };
    },
    visibilityRoot() {
      return document.body;
    }
  },
  watch: {
    listDependency: {
      immediate: true,
      handler(val) {
        const [list, hideList] = val;
        this.list = hideList ? [] : [...list];
      }
    },
    async frozen(frozen) {
      this.height = frozen
        ? this.$refs.container.$el.clientHeight + 'px'
        : null;
      await this.$nextTick().then(() => {
        this.isAddShown = !frozen;
      });
    }
  },
  mounted() {
    this.findScrollParentEl();
  },
  methods: {
    ...mapMutations({
      updateBoards: 'UPDATE_BOARDS'
    }),
    ...mapActions(['editBoard']),
    openCreateBoard() {
      if (this.isCallbackClick) return this.$emit('addClick');
      else this.$modal.show('create-board', { folder: this.folder });
    },

    dragStart(ev) {
      this.dragging = true;
      this.$emit('dragstart', ev);
    },
    dragend(ev) {
      this.$emit('dragend', ev);
      setTimeout(() => {
        this.dragging = false;
      }, 200);
    },
    getPosition(index) {
      // index could be the last adding card so it exceeds the last index
      const actualIndex = this.list.length
        ? Math.min(this.list.length - 1, index)
        : 0;
      const getPos = boardIdx => {
        return this.list[boardIdx]?.currentMember.position;
      };
      const prevPos = getPos(actualIndex - 1) ?? 0;
      const nextPos = getPos(actualIndex + 1) ?? prevPos * 3;
      const position = (prevPos + nextPos) / 2 || 1;
      return position;
    },
    onDragChange(evt) {
      this.dragging = false;
      if (evt.removed) return;
      const context = evt.added || evt.moved;
      const index = context.newIndex;
      const position = this.getPosition(index);
      const request = {
        position,
        id: context.element.id
      };
      const boards = this.boards.map(pullPosition);
      if (this.shouldRecalculatePostion(position, boards)) {
        const recalPosBoards = this.recalculateItemsPosition(boards, request);
        this.updateBoards(recalPosBoards.map(pushPosition));
      }
      if (evt.added) {
        request.folderID = this.folder.id;
      }
      this.editBoard(request);
    },
    findScrollParentEl() {
      const el = document.body;
      this.scrollParentEl = el;
    }
  }
};
</script>

<style lang="scss">
.board-list {
  @apply flex items-start content-start;
}
.card-wrapper {
  @apply p-2;
  width: min(265px, 50%);

  & > div {
    @apply w-full h-full;
  }

  @screen sm {
    @apply p-4;
  }
}

.board-list__card--ghost {
  @apply border-dashed rounded border-2 flex flex-col items-center justify-center text-gray-500 cursor-pointer;

  &:hover {
    @apply text-gray-800 border-gray-800;
  }
}
</style>
