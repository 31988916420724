






























































































































































import Vue from 'vue';
import FormCreateBoard from '@/components/forms/form-create-board.vue';
import FormFolder from '@/components/forms/form-folder.vue';
import CreateTeamForm from '@/components/create-team-form.vue';
import CreateWorkspaceForm from '@/components/create-workspace-form.vue';
import { createNamespacedHelpers } from 'vuex';
import { BoardAdd } from './types/Board';
import { Folder, FolderCreate, FolderUpdate } from './types/Folder';
import eventBus from './services/eventBus';
import subscriptionPlanCard from '@/components/subscription/subscriptionPlanCard.vue';
import createWorkspaceForm from '@/components/create-workspace-form.vue';
import subscriptionPlanPayment from '@/components/subscription/subscriptionPlanPayment.vue';
import paymentSuccess from '@/components/payment/payment-success.vue';
import modalFreetrial from '@/components/modal/modal-freetrial.vue';
import modalSubscriptionExpired from '@/components/modal/modal-subscription-expired.vue';
import ModalPlanInvite from '@/components/modal/modal-plan-invite.vue';
import banner from '@/views/banner.vue';
const { mapActions } = createNamespacedHelpers('boards');
const { mapActions: mapFolderActions } = createNamespacedHelpers('folders');

interface FolderForm {
  create?: FolderCreate;
  edit?: FolderUpdate & { id: number };
  delete?: number;
}

interface ComponentData {
  boardCreating: boolean;
  folder?: Folder;
  boardCreateFolder?: Folder;
  teamId?: number;
  isLoading: boolean;
  isbannerShow: boolean;
}

export default Vue.extend({
  components: {
    FormCreateBoard,
    FormFolder,
    CreateTeamForm,
    subscriptionPlanCard,
    createWorkspaceForm,
    subscriptionPlanPayment,
    paymentSuccess,
    modalFreetrial,
    modalSubscriptionExpired,
    ModalPlanInvite,
    banner
  },
  data(): ComponentData {
    return {
      boardCreating: false,
      folder: undefined,
      boardCreateFolder: undefined,
      teamId: undefined,
      isLoading: false,
      isbannerShow: false
    };
  },
  mounted() {
    // check localStorage denied
    try {
      if (!localStorage) this.$modal.show('error-storage');
    } catch (err) {
      this.$modal.show('error-storage');
    }

    this.setWindowHeight();

    window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.onScroll);

    if (this.isMobile) {
      const body = document.body;
      body.classList.add('boby-scroll');
    }
  },
  computed: {
    isMobile() {
      const openOnMoile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      const noLocalStorage = localStorage.getItem('smart_banner') == null;
      openOnMoile ? this.opendOnMobile() : '';
      return openOnMoile;
    },
    showSmartbanner() {
      return localStorage.getItem('smart_banner');
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    ...mapActions(['createBoard']),
    ...mapFolderActions(['createFolder', 'updateFolder', 'deleteFolder']),
    async newBoardSubmit(newBoardReq: BoardAdd) {
      this.boardCreating = true;
      this.isLoading = true;
      try {
        await this.createBoard(newBoardReq);
        this.$modal.hide('create-board');
      } catch (error) {
        //
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.boardCreating = false;
        }, 500);
      });
      this.isLoading = false;
    },
    async handleFolderForm(form: FolderForm) {
      await (() => {
        if (form.create) this.createFolder(form.create);
        else if (form.edit) {
          form.edit.id = (this.folder as Folder).id;
          this.updateFolder(form.edit);
        } else if (form.delete) {
          this.$modal.show('delete-folder', { folder: form.delete });
        }
      })();
      this.$modal.hide('form-folder');
    },
    setBoardCreateFolder(ev: { params?: { folder: Folder; teamId: number } }) {
      this.teamId = ev.params?.teamId;
      this.boardCreateFolder = ev.params?.folder;
    },
    setWindowHeight() {
      // mobile height fit
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    onResize(ev: UIEvent) {
      eventBus.$emit('window-resize', ev);

      this.setWindowHeight();
    },
    onScroll(ev: Event) {
      const { scrollHeight } = document.body;
      const bottomPosition = window.scrollY + window.innerHeight;
      const isBottom = bottomPosition >= scrollHeight;
      if (isBottom) {
        ev.preventDefault();
        this.triggerScrollBottom(ev);
      }
    },
    triggerScrollBottom(ev: Event) {
      eventBus.$emit('scroll-bottom', ev);
    },
    closeModalPayment() {
      this.$modal.hide('plan-payment');
      this.$modal.hide('plan-payment-change');
      this.$modal.hide('plan-seat');
    },
    opendOnMobile(this: any) {
      localStorage.setItem('smart_banner', 'show');
      this.isbannerShow = true;
    },
    hidebanner(this: any) {
      this.isbannerShow = false;
    }
  }
});
