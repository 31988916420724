import Vue from 'vue';
import moment, { Moment } from 'moment';

const INTERVAL = 1000;
const A_MINUTE = 60;
const AN_HOUR = A_MINUTE * 60;
const UNIT = 'seconds';

export default Vue.extend({
  beforeDestroy() {
    clearInterval(this.timer_interval);
  },
  data() {
    return {
      timer_interval: 0,
      timer_seconds: 0,
      start_at_moment: undefined as Moment | undefined
    };
  },
  computed: {
    displayTimer(): string {
      // FORMAT => hh:mm:ss

      const fillZero = (str = '', expectLen = 0): string => {
        const totalTimes = expectLen - str.length;
        if (totalTimes < 1) return str;
        return fillZero(`0${str}`, expectLen - 1);
      };
      const floorNumToString = (num: number) => Math.floor(num).toString();
      const remainingMin = this.timer_seconds % AN_HOUR;
      const ss = remainingMin % A_MINUTE;
      const hh = this.timer_seconds / AN_HOUR;
      const mm = remainingMin / A_MINUTE;
      return [hh, mm, ss].map(e => fillZero(floorNumToString(e), 2)).join(':');
    },
    canStartTimer(): boolean {
      return !this.start_at_moment;
    }
  },
  methods: {
    startTimer(startTime: Date) {
      this.start_at_moment = moment(startTime).locale(
        `${this.$t('notifications.language')}`
      );
      this.timer_seconds = moment()
        .locale(`${this.$t('notifications.language')}`)
        .diff(startTime, UNIT);
      this.timer_interval = setInterval(this.updateTimeCount, INTERVAL);
    },
    stopTimer() {
      clearInterval(this.timer_interval);
      this.timer_seconds = 0;
      this.timer_interval = 0;
      this.start_at_moment = undefined;
    },
    updateTimeCount() {
      this.timer_seconds = moment()
        .locale(`${this.$t('notifications.language')}`)
        .diff(
          moment(this.start_at_moment).locale(
            `${this.$t('notifications.language')}`
          ),
          UNIT
        );
    }
  }
});
