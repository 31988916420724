import { Task } from '@/types/Task';
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapMutations, mapActions } = createNamespacedHelpers('tasks');

export default Vue.extend({
  props: {
    modalName: {
      type: String,
      default: 'task-detail'
    }
  },
  methods: {
    ...mapMutations({ setCurrentTask: 'SET_CURRENT_TASK' }),
    ...mapActions(['fetchTaskLocal']),
    async openTask(task: Task) {
      this.setCurrentTask(task);
      this.fetchTaskLocal(task.id);
      this.$modal.show(this.modalName);
    }
  }
});
