














































































































































































































import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { BoardAdd, BoardTemplate, BoardType } from '@/types/Board';
import { Team } from '@/types/Team';
import { Folder } from '@/types/Folder';
import { BaseSelectOption } from '@/types/BaseComponents';
import IconCreateBoardBasic from '@/components/icons/CreateBoardBasic.vue';
import IconCreateBoardWeekday from '@/components/icons/CreateBoardWeekday.vue';
import IconCreateBoardBlank from '@/components/icons/CreateBoardBlank.vue';
import IconCreateBoardBasicPreview from '@/components/icons/CreateBoardBasicPreview.vue';
import IconCreateBoardWeekdayPreview from '@/components/icons/CreateBoardWeekdayPreview.vue';
import IconCreateBoardBlankPreview from '@/components/icons/CreateBoardBlankPreview.vue';

import SelectColors, {
  ColorOption
} from '@/components/select/select-board-colors.vue';
import SelectBoardType from '../select/select-board-type.vue';

type TemplateOption = keyof typeof BoardTemplate;

interface ComponentData {
  showName: boolean;
  template: TemplateOption;
  formName: string;
  formDescription: string;
  formKey: string;
  formFolder: number;
  formType: BoardType.KANBAN;
  formColor: ColorOption.Yellow;
  formTeam?: number;
}

export default Vue.extend({
  props: {
    loading: Boolean,
    defaultFolder: {
      type: Object as PropType<Folder | undefined>
    },
    defaultTeam: {
      type: Number || undefined
    }
  },
  components: {
    SelectColors,
    IconCreateBoardBasic,
    IconCreateBoardWeekday,
    IconCreateBoardBlank,
    IconCreateBoardBasicPreview,
    IconCreateBoardWeekdayPreview,
    IconCreateBoardBlankPreview,
    SelectBoardType
  },
  data(): ComponentData {
    return {
      showName: true,
      template: 'Basic' as TemplateOption,
      formName: '',
      formDescription: '',
      formKey: '',
      formFolder: 0,
      formType: 0 as BoardType.KANBAN,
      formColor: ColorOption.Yellow,
      formTeam: undefined
    };
  },
  computed: {
    ...mapGetters('folders', ['getFolders', 'getNoFolder']),
    noFolder(): Folder {
      return this.getNoFolder;
    },
    folderOptions(): BaseSelectOption[] {
      return (this.getFolders as Folder[]).map(e => ({
        label: e.name,
        value: e.id
      }));
    },
    teamOptions(): Team[] {
      return [
        { id: undefined, name: this.$t('team.noSelect') },
        ...this.$store.getters['teams/getTeams']
      ];
    },
    disabledNext(): boolean {
      return !this.formName;
    },
    templateOptions(): TemplateOption[] {
      return ['Basic', 'Weekday', 'Blank'];
    },
    folderDeps(): [Folder?, Folder?] {
      return [this.noFolder, this.defaultFolder];
    },
    isKanban(): boolean {
      return this.formType === BoardType.KANBAN;
    },
    firstStepButton(): string {
      return this.isKanban
        ? `${this.$t('create.next')}`
        : `${this.$t('create.create')}`;
    }
  },
  mounted() {
    this.formTeam = this.defaultTeam;

    this.fetchFolders();
  },
  watch: {
    folderDeps(deps: [Folder?, Folder?]) {
      const [noFolder, defaultFolder] = deps;
      this.formFolder = defaultFolder?.id || noFolder?.id || 0;
    }
  },
  methods: {
    ...mapActions('folders', ['fetchFolders']),
    onEnterNext() {
      (this.$refs['board-description-input'] as HTMLTextAreaElement).blur();
      if (this.formName) {
        this.onNext();
      }
    },
    onNext() {
      if (this.isKanban) this.showName = false;
      else this.createButtonClick();
    },
    onEnterDescription() {
      (this.$refs['board-description-input'] as HTMLTextAreaElement).blur();
    },
    createButtonClick() {
      this.submit({
        template: BoardTemplate[this.template],
        name: this.formName,
        key: this.formKey,
        description: this.formDescription,
        folderID: !this.defaultTeam ? this.formFolder : 0,
        backgroundCover: this.formColor,
        type: this.formType,
        teamID: this.formTeam
      });
      const boardTypes = ['kanban', 'waterfall', 'agile'];
      const typeofBoard = boardTypes[this.formType];
      this.$fana.logEvent('board/create/select_create', { type: typeofBoard });
    },
    submit(createBoard: BoardAdd) {
      this.$emit('submit', createBoard);
    }
  }
});
