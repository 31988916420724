<template>
  <div>
    <!-- link task -->
    <div v-if="links.length">
      <icon-header-add icon="link">
        {{ $t('modelTask.card.link.title') }}
      </icon-header-add>

      <div class="border border-gray-200 rounded-md overflow-hidden">
        <div
          class="border-b border-gray-200 bg-gray-100 text-gray-600 text-xs py-1 px-10"
        >
          {{ $t('modelTask.card.link.tracking') }}
        </div>
        <!-- link -->

        <div
          v-for="(link, i) in links"
          :key="`link-${i}`"
          class=" py-2 px-2 flex items-center space-x-2 link-task-hover justify-between"
          :class="{ 'border-b border-gray-200': i < links.length - 1 }"
        >
          <!--<div class="w-8">
            <div class="self-center radio pointer-events-none">
              <input
                class="mr-2 leading-tight"
                type="radio"
                :checked="link.status == 2"
              />
              <span class="checkmark" />
              <span class="pr-8" />
            </div>
          </div>-->
          <div class="flex">
            <div class="flex-1">
              <div class="text-sm" style="color: #7A869A">
                {{ link.code }}
              </div>
              <div class="flex justify-between">
                <div
                  class="text-sm flex"
                  :class="{ 'opacity-50': link.status === 2 }"
                >
                  <base-icon
                    v-if="link.status === 2"
                    class="self-center"
                    size="15"
                  >
                    <icon-completed />
                  </base-icon>
                  <router-link
                    :to="{ name: 'Task-Open', params: { id: link.id } }"
                    target="_blank"
                  >
                    <v-clamp :max-lines="1" autoresize>
                      {{ link.name }}
                    </v-clamp>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="self-center">
              <!-- remove -->
              <div
                v-if="!isUserViewer"
                class="mx-2 bg-gray-200 rounded p-1 flex items-center justify-center border border-gray-300 cursor-pointer hover-remove"
                @click="confirmRemove(link.id)"
              >
                <base-icon class="w-3 h-3">
                  <icon-close />
                </base-icon>
              </div>
            </div>
          </div>
          <div class="flex items-center space-x-1">
            <!-- priority -->
            <modal-task-link-priority :priority="link.priority" />

            <modal-task-link-column :column="link.boardColumn" />
            <!-- avatar -->
            <div>
              <base-avatar
                v-if="link.assigns"
                :list="getUsersAvatar(link.assigns)"
                popup
                size-small
                list-max-height="400"
              />
              <div v-else />
            </div>
          </div>
        </div>
      </div>

      <modal-task-link-dropdown @update="$emit('update')" />
    </div>
    <modal name="confirm-link-remove" height="auto" width="380">
      <dialog-confirm
        :title="$t('modelTask.card.link.remove.title')"
        :description="$t('modelTask.card.link.remove.description')"
        :button="$t('modelTask.card.link.remove.title')"
        @close="$modal.hide('confirm-link-remove')"
        @confirm="onLinkRemove"
      />
    </modal>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { boardsApi } from '@/services/apis';

import IconHeaderAdd from '@/components/icon-header-add.vue';
import IconClose from '@/components/icons/IconClose';
import { BoardType } from '@/types/Board';
import VClamp from 'vue-clamp';
import ModalTaskLinkPriority from './modal-task-link-priority.vue';
import ModalTaskLinkColumn from './modal-task-link-column.vue';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import ModalTaskLinkDropdown from './modal-task-link-dropdown.vue';
import IconCompleted from '@/components/icons/IconCompleted.vue';
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('tasks');

export default {
  name: 'modal-task',
  components: {
    IconClose,
    IconHeaderAdd,
    VClamp,
    ModalTaskLinkPriority,
    ModalTaskLinkColumn,
    DialogConfirm,
    ModalTaskLinkDropdown,
    IconCompleted
  },
  data() {
    return {
      toRemove: 0
    };
  },
  watch: {},
  computed: {
    ...mapState(['currentTask']),
    ...mapGetters({
      isUser: 'auth/isUser',
      loading: 'tasks/isTaskLoading',
      isUserViewer: 'boards/isUserViewer',
      columns: 'board-column/getColumns',
      board: 'boards/getActiveBoard'
    }),
    task() {
      return this.currentTask;
    },
    isKanbanBoard() {
      return this.board.type === BoardType.KANBAN;
    },
    taskAssigns() {
      return this.task.assigns || [];
    },
    links() {
      return this.task.linkedTasks;
    }
  },
  methods: {
    ...mapMutations(['UPDATE_CURRENT_TASK']),
    ...mapActions([
      'setTask',
      'addTaskAssign',
      'removeTaskAssign',
      'readTask',
      'subscribeTask',
      'unsubscribeTask'
    ]),

    getUsersAvatar(list) {
      return list.map(e => e.user);
    },

    confirmRemove(id) {
      this.$modal.show('confirm-link-remove');
      this.toRemove = id;
    },
    async onLinkRemove() {
      await boardsApi.linkTaskRemove(this.task.id, this.toRemove);
      this.$emit('update');
      this.toRemove = 0;
    }
  }
};
</script>

<style scoped>
.tag--low {
  background-color: #bee3ff;
}
.tag--medium {
  @apply text-white;
  background-color: #168ae2;
}
.tag--high {
  @apply text-white;
  background-color: #10588e;
}
.link-task-hover .hover-remove {
  display: none;
}
.link-task-hover:hover .hover-remove {
  display: flex;
}
</style>
