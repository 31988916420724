












































import { Emit, Component, Prop, Watch } from 'vue-property-decorator';
import VDraggable from 'vuedraggable';
import VuedraggableConfig from '@/mixins/Vuedraggable';
import TodoListItem from './todo-list-item.vue';
import { mixins } from 'vue-class-component';
import DragAndDrop from '@/mixins/DragAndDrop';

export interface TodoListItem {
  name: string;
  id: number;
  completed: boolean;
  position: number;
}

export interface TodoListCompleted {
  id: number;
  completed: boolean;
}

export interface EmitCompleted {
  (complete: TodoListCompleted): void;
}

export interface EmitName {
  id: number;
  title: string;
}

interface DragEventData {
  newIndex: number;
  oldIndex: number;
  element: TodoListItem;
}

interface DragEvent {
  moved: DragEventData;
}

@Component({
  mixins: [VuedraggableConfig],
  components: {
    TodoListItem,
    VDraggable
  }
})
export default class TodoList extends mixins(DragAndDrop) {
  @Prop({ type: Array, default: () => [] }) readonly list!: TodoListItem[];
  @Prop({ type: Boolean }) readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false }) readonly draggable!: boolean;

  editing: number | null = null;

  cancelEdit() {
    this.editing = null;
  }

  @Emit()
  completed(val: TodoListCompleted): TodoListCompleted {
    return val;
  }

  @Emit()
  remove(id: number) {
    return id;
  }

  @Emit()
  name(value: EmitName): EmitName {
    return value;
  }

  dragging = false;
  draggingList: TodoListItem[] = [];
  getListWithPosition(list: TodoListItem[]): TodoListItem[] {
    const newList: TodoListItem[] = [];
    list.forEach((item, index) => {
      const newItem = {
        ...item,
        position: item.position || (newList[index - 1]?.position ?? 0.5) * 2
      };
      newList.push(newItem);
    });
    return newList;
  }
  @Watch('list', { immediate: true })
  onListChanged(list: TodoListItem[] = []) {
    this.draggingList = this.getListWithPosition(list);
  }
  dragChange(event: DragEvent) {
    const { moved } = event;
    const { element, newIndex } = moved;
    const position = this.getPosition(this.draggingList, newIndex);
    this.draggingList = this.draggingList.map(dragItem =>
      dragItem.id === element.id ? { ...dragItem, position } : dragItem
    );
    this.$emit('drag', { position, id: element.id });
  }
}
