<template>
  <div
    v-on-clickaway="hide"
    class=" base-dropdown"
    :class="{
      relative: !notrelative,
      'sm-right': smRight,
      'sm-left': !smRight
    }"
    @mouseenter="hoverable ? toggleDropdown() : null"
    @mouseleave="hoverable ? toggleDropdown() : null"
  >
    <div
      ref="dropdownToggle"
      class="relative flex-1"
      :disabled="disabled"
      @click="toggleDropdown"
    >
      <slot name="toggle" :events="{ toggle: toggleDropdown }">
        <button
          class="button text-black block focus:outline-none focus:border-white"
        >
          <span>Click</span>
        </button>
      </slot>
    </div>

    <v-scroller
      v-if="!disabled"
      v-show="showDropdown"
      ref="dropdownContent"
      class="base-dropdown__content mt-1"
      :class="contentClasses"
      :style="contentStyle"
      @bottom="$emit('bottom', $event)"
    >
      <div class="bg-white">
        <base-dropdown-item
          v-if="header"
          class="border-b sticky top-0 bg-white z-20"
          :hoverable="false"
          keep
        >
          <div class="flex justify-between items-center ">
            <slot name="header" />
            <base-button
              v-if="isShowIconClose"
              data-testid="icon-close"
              icon="close"
              icon-size="16"
              wide="p-2"
              color="text"
              @click="hide"
            />
          </div>
        </base-dropdown-item>
        <slot />
        <base-dropdown-item
          v-if="footer"
          :hoverable="false"
          keep
          :class="`sticky bottom-0 bg-white ${footerClass}`"
        >
          <slot name="footer" :events="{ hide }" />
        </base-dropdown-item>
      </div>
    </v-scroller>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import VScroller from '@/components/scroller';

export default {
  components: {
    VScroller
  },
  provide() {
    return {
      parent: this
    };
  },
  props: {
    isShowIconClose: {
      type: Boolean,
      default: true
    },
    notrelative: {
      type: Boolean
    },
    smRight: {
      type: Boolean
    },
    position: {
      type: String,
      default: 'bottom-left'
    },
    width: {
      type: String,
      default: 'medium'
    },
    maxWidth: {
      type: [Number, String],
      default: 0
    },
    maxHeight: {
      type: [Number, String],
      default: 0
    },
    contentClass: {
      type: String,
      default: ''
    },
    footerClass: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    hoverable: Boolean
  },
  data() {
    return {
      showDropdown: false,
      contentFixed: {},
      clouldTop: false
    };
  },
  computed: {
    positionClass() {
      const positionClasses = {
        'bottom-left': `on--bottom-left`,
        'bottom-right': `on--bottom-right`,
        'top-left': `on--top-left`,
        'top-right': `on--top-right`,
        'on-right': `on-right`,
        'right-end': `on--right-end`
      };
      return (
        (this.clouldTop
          ? positionClasses[this.position].replace('bottom', 'top')
          : positionClasses[this.position]) || positionClasses['bottom-left']
      );
    },
    widthClass() {
      const widthClasses = {
        medium: `is--md`,
        small: `is--sm`,
        large: `is--lg`,
        xlarge: `is--xlg`,
        full: 'w-full'
      };
      return widthClasses[this.width] || widthClasses.medium;
    },
    header() {
      return this.$slots.header;
    },
    footer() {
      return this.$scopedSlots.footer;
    },
    dropdownToggle() {
      return this.$refs.dropdownToggle;
    },
    dropdownContent() {
      return this.$refs.dropdownContent;
    },
    contentClasses() {
      const paddingClass = {
        'pt-2': !this.header,
        'pb-2': !this.footer
      };
      const scrollable = 'overflow-y-auto';
      return [
        this.positionClass,
        this.widthClass,
        paddingClass,
        scrollable,
        this.contentClass
      ];
    },
    contentStyle() {
      const style = {
        ...this.contentFixed
      };
      if (this.maxWidth) {
        style['max-width'] = `${this.maxWidth}px`;
      }
      if (this.maxHeight) {
        style['max-height'] = `${this.maxHeight}px`;
      }
      return style;
    }
  },
  mounted() {
    this.popupItem = this.$el;
  },
  watch: {
    showDropdown(val) {
      if (!val) this.$emit('closed');
      else this.$emit('opened');
    }
  },
  methods: {
    hide() {
      this.showDropdown = false;
      this.$emit('hide');
    },
    show() {
      if (!this.showDropdown) {
        this.toggleDropdown();
      }
    },
    toggleDropdown(event) {
      if (event) {
        const centerScreen = window.screen.height / 2;
        const { clientY } = event;
        if (clientY && centerScreen < clientY) this.clouldTop = true;
        else this.clouldTop = false;
      }
      this.showDropdown = !this.showDropdown;
    },
    getDropdownContentFixedPosition() {
      // WIP: dicussing with Tester
      const isPositionRight = this.position.includes('right');
      if (this.showDropdown && this.isContentFixed) {
        const boundingClientRect = this.dropdownContent.getBoundingClientRect();
        const toggleRect = this.dropdownToggle.getBoundingClientRect();
        const offsetRight = isPositionRight ? boundingClientRect.width : 0;

        if (this.contentFixed) {
          // if the content of dropdown is overflow off either the height or width of window
          // it will use the edge position of window
          // or otherwise, it will use the positon rect of toggle
          const getPosition = ({ inner, outer }) => {
            const contentBoxOuter = boundingClientRect[outer];
            const windowEdge =
              window[inner === 'top' ? 'innerHeight' : 'innerWidth'];
            return contentBoxOuter < windowEdge
              ? toggleRect[outer]
              : windowEdge -
                  boundingClientRect[inner === 'top' ? 'height' : 'width'];
          };

          this.contentFixed = {
            top: `${getPosition({ inner: 'top', outer: 'bottom' })}px`,
            left: `${getPosition({ inner: 'left', outer: 'right' }) -
              offsetRight}px`,
            position: 'fixed'
          };
        }
      }
    }
  },
  directives: {
    onClickaway
  }
};
</script>

<style lang="scss" scoped>
.base-dropdown__content {
  &.is--sm {
    max-width: min(12rem, calc(100vw - 48px));
  }
  &.is--md {
    max-width: min(20rem, calc(100vw - 48px));
  }
  &.is--lg {
    max-width: min(26rem, calc(100vw - 48px));
  }
  &.is--xlg {
    max-width: min(30rem, calc(100vw - 48px));
  }
  &.w-full {
    max-width: 100%;
  }
  &.on--bottom-left {
    left: 0;
    top: 100%;
  }

  &.on--bottom-right {
    right: 0;
    top: 100%;
  }

  &.on--top-left {
    left: 0;
    bottom: 100%;
  }

  &.on--top-right {
    right: 0;
    bottom: 100%;
  }

  &.on-right {
    left: 110%;
    top: -35%;
  }
  &.on--right-end {
    left: 104px;
    bottom: -12px;
  }

  @apply absolute bg-white rounded-lg shadow-lg z-40 border w-screen max-h-screen;
}

@media only screen and (max-width: 640px) {
  .sm-left {
    .base-dropdown__content {
      left: 0;
      right: auto;
    }
  }
  .sm-right {
    .base-dropdown__content {
      right: 0;
      left: auto;
    }
  }
}

.base-dropdown__separator {
  @apply border-b my-2;
}
</style>
