var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-dropdown',{staticClass:"base-select",attrs:{"width":"small","position":"bottom-right","is-dot-button":_vm.isDotButton,"disabled":_vm.disabled},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},[(!_vm.isDotButton)?_c('div',{staticClass:"px-2 py-2 base-select__toggle cursor-pointer",attrs:{"slot":"toggle"},slot:"toggle"},[_c('div',{staticClass:"mr-2 base-select__active flex items-center px-2"},[_c('div',{staticClass:"text-sm"},[_vm._t("toggle",function(){return [_vm._v(" "+_vm._s(_vm.$t(("team.invite." + (_vm.active.label))))+" ")]},{"active":_vm.active})],2)]),_c('div',{staticClass:"toggle-icon inline-flex has-centered-item px-2"},[_c('base-icon',{attrs:{"name":"select-toggle","size":"16"}},[_c('icon-chevron-down')],1)],1)]):_c('div',{staticClass:"has-centered-item",attrs:{"slot":"toggle"},slot:"toggle"},[_c('base-button',{attrs:{"color":"white","wide":"px-1","icon":_vm.iconBtn,"icon-size":"20","shadowless":"","borderless":"","rounded":"sm"}})],1),_vm._l((_vm.userRoles),function(option){return _c('base-dropdown-item',{key:option.value,staticClass:"flex items-center",class:option.class,on:{"click":function($event){_vm.role = option.value}}},[_vm._t("label",function(){return [_c('base-icon',{staticClass:"align-text-top",attrs:{"size":"16","margin-next":false,"color":"#168AE2"}},[(_vm.active.value === option.value)?_c('icon-check'):_vm._e()],1),_c('div',{staticClass:"flex-1",class:{
            'ml-8': option.value != -2,
            'text-center': option.value == -2
          }},[_vm._v(" "+_vm._s(_vm.$t(("team.invite." + (option.label))))+" ")])]},{"props":{
          active: _vm.active.value === option.value,
          label: option.label,
          value: option.value
        }})],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }