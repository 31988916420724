import { Board, BoardColumn } from './Board';
import { User } from './User';
import { PageInformation, ApiPagination, ApiCommonFields } from './Api';
import { FileUpload } from './File';
import { Tag } from './Tag';
import { Checklist } from './Checklist';
import { Sprint } from './Sprint';

interface TaskStatistic {
  commentCount?: number;
  fileCount?: number;
}

export enum TaskStatus {
  InComplete = 1,
  Done
}

export enum TaskType {
  Group = 1,
  Task
}

export enum TaskPrivacy {
  Public = 1,
  Private
}

export interface TaskFile {
  createdAt: string;
  createdByUserID: number;
  createdByUser: User;
  deletedAt: string;
  file: FileUpload;
  fileID: number;
  id: number;
  taskID: number;
  updatedAt: string;
}

export interface CommentFile extends TaskFile {
  commentID: string;
}

export interface TaskAssign {
  createdAt: string;
  deletedAt: string;
  id: number;
  taskID: number;
  updatedAt: string;
  user: User;
  userID: number;
  UserID: number;
  unread: boolean;
}

export interface TaskAdd {
  boardID: number;
  columnID: number;
  name: string;
  position: number;
  startAt?: string;
  endAt?: string;
  progressInPercent?: number;
  type?: TaskType;
  sprintID?: number;
  storyPoint?: number;
  tagIDs?: number[];
  assigneeIDs?: number[];
}

export interface TaskComment {
  id: number;
  taskID: number;
  message: string;
  commentFiles: CommentFile[];
  fileIDs: number[];
  user: User;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
  userId: string;
}

export interface TaskCommentAdd {
  taskID: number;
  fileIDs: number[];
  message: string;
}

export interface TaskCommentDelete {
  taskID: number;
  commentID: number;
}

export interface TaskCommentEdit {
  taskID: number;
  commentID: number;
  message: string;
  fileIDs: number[];
}
export interface TaskCommentResponse extends ApiPagination<TaskComment> {
  comments: TaskComment[];
  pageInformation: PageInformation;
}

export interface TaskActivity {
  id: number;
  createdAt: string;
  updatedAt: string;
  descriptionTH: string;
  descriptionEN: string;
  boardID: number;
  taskID: number;
  userDisplayname: string;
  userAvatarPath: string;
}

export type TaskActivityResponse = ApiPagination<TaskActivity>;

export interface TaskTag extends ApiCommonFields {
  tagID: number;
  taskID: number;
  tag: Tag;
}

export interface Task extends TaskAdd, ApiCommonFields {
  level: object;
  levelID: number;
  priority: number;
  archivedAt: string;
  archivedByUserID: number;
  boardColumnID: number;
  createdByUserID: number;
  code: string;
  deletedByUserID: number;
  description: string;
  estimateTime: number;
  progressInPercent: number;
  startAt: string;
  endAt: string;
  type: TaskType;
  status: TaskStatus;
  statistic?: TaskStatistic;
  board: Board;
  boardColumn: BoardColumn;
  comments?: TaskComment[];
  checklists?: Checklist[];
  tags?: TaskTag[];
  files: TaskFile[];
  assigns?: TaskAssign[];
  sprint: Sprint;
  sprintID: number;
  storyPoint: number;
  groupID?: number;
  privacy: TaskPrivacy;
  doneAt?: string;
  currentMember?: any;
  creator: User;
  responsibles?: TaskAssign[];
}
export interface TaskCopyRequest {
  isAssignUser?: boolean;
  isChecklist?: boolean;
  isFile?: boolean;
  isTag?: boolean;
  name: string;
  id: number;
}

export interface TaskFilters {
  sprintId?: number;
}

export interface ShareLinkTask extends Omit<Task, 'board'> {
  board?: Board;
}
