
























import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { BoardColumn } from '@/types/Board';
import { BaseSelectOption } from '@/types/BaseComponents';
import { Task } from '@/types/Task';

export default Vue.extend({
  inheritAttrs: false,
  computed: {
    ...mapGetters({
      currentTask: 'tasks/getCurrentTask',
      getColumns: 'board-column/getSortedColumns'
    }),
    task(): Task {
      return this.currentTask;
    },
    status: {
      get(): number {
        return this.task?.boardColumnID;
      },
      set(newColumnID: number) {
        if (this.task.boardColumnID != newColumnID)
          this.setTask({
            id: this.task?.id,
            newColumnID
          });
      }
    },
    columns(): BoardColumn[] {
      return this.getColumns;
    },
    options(): BaseSelectOption[] {
      return this.columns.map(e => ({ label: e.name, value: e.id }));
    }
  },
  methods: {
    ...mapActions({
      setTask: 'tasks/setTask'
    })
  }
});
