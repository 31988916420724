import Vue from 'vue';
import VModal from 'vue-js-modal';
import VNotification from 'vue-notification';
import { VClosePopover, VPopover } from 'v-tooltip';
import VueMeta from 'vue-meta';
import VSelect from 'vue-select';
import 'vue2-timepicker/dist/VueTimepicker.css';
import '@lottiefiles/lottie-player';

import App from './App.vue';
import router from './router';
import { store } from './store';

import './assets/styles/index.css';
import './assets/styles/style.css';
import 'croppie/croppie.css';
import './services/firebase';
import './services/global-component-register';
import './filters/moment';
import './filters/text';
import './plugins/font-awesome';
import i18n from './plugins/i18n';
import FirebaseAnalytics from './plugins/firebase-analytics';
import TextareaAutosize from 'vue-textarea-autosize';
import Skeleton from 'vue-loading-skeleton';
import VueCardFormat from 'vue-credit-card-validation';

function loadScript(scriptName: string) {
  const loadImageScript = document.createElement('script');
  loadImageScript.setAttribute('src', `/js/${scriptName}`);
  document.head.appendChild(loadImageScript);
}

Vue.config.productionTip = false;

Vue.use(VModal, { dialog: true, dynamicDefaults: { height: 'auto' } });
Vue.use(VNotification);
Vue.use(VueMeta);
Vue.use(FirebaseAnalytics);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);
Vue.component('v-select', VSelect);
Vue.use(TextareaAutosize);
Vue.use(Skeleton);
Vue.use(VueCardFormat);

const loadImageScripts = ['load-image.all.min.js'];
loadImageScripts.forEach(loadScript);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
