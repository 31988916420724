<template>
  <draggable
    ref="draggable"
    v-model="dragTasks"
    filter=".draggable-task--ignored"
    draggable=".card--draggable"
    group="dragging-tasks"
    drag-class="dragging-task"
    ghost-class="dragging-task--ghost"
    chosen-class="dragging-task--chosen"
    class="board-columns__cards rounded-b-md"
    direction="vertical"
    v-bind="config"
    :disabled="column.dragging || viewOnly"
    :animation="0"
    @start="dragstart"
    @end="dragend"
    @change="change"
    @scroll.native="onScrollDelayed"
  >
    <wrapper-click-ignored
      v-for="task in dragTasks"
      :key="task.id"
      class="task-card--dragging card--draggable my-1 rounded"
      ignored-class="open-task--ignore"
      @click="onTaskClick(task)"
    >
      <card-task :task="task" />
    </wrapper-click-ignored>

    <div
      v-if="addingActive"
      slot="footer"
      class="task-card draggable-column--ignored"
    >
      <base-card class="bg-white task-card my-1" body-padding="p-2">
        <div>
          <textarea
            ref="addingTaskTextarea"
            v-model="addTaskName"
            class="w-full resize-none p-2"
            :placeholder="$t('create.task')"
            @keydown.enter="submit"
          />
          <div class="mt-2 flex justify-end">
            <base-button
              icon="close"
              icon-size="16"
              wide="px-3"
              color="light"
              shadowless
              @click="onCreateCancel"
            />
            <base-button
              wide="p-2 px-5"
              :disabled="!addTaskName"
              @click="submit"
            >
              {{ $t('alert.button.create') }}
            </base-button>
          </div>
        </div>
      </base-card>
    </div>
  </draggable>
</template>

<script>
import Draggable from 'vuedraggable';
import Vuedraggable from '@/mixins/Vuedraggable';
import EventBus from '@/services/eventBus';
import WrapperClickIgnored from '@/components/wrapper/wrapper-click-ignored';
import CardTask from '@/components/card-task';
import { debounce } from 'lodash';
import OpenTask from '@/mixins/OpenTask';

// My current display height is 750px, the highest amount of shown tasks(only task name) are 5 tasks;
const DISPLAY_STARTER = 5;
const AT_SCREEN_HEIGHT = 750;

export default {
  mixins: [Vuedraggable, OpenTask],
  components: {
    Draggable,
    CardTask,
    WrapperClickIgnored
  },
  props: {
    tasks: {
      type: Array,
      default: () => []
    },

    addingActive: Boolean
  },
  data() {
    return {
      addTaskName: '',
      dragTasks: [],
      isDragging: false,
      onScrollDelayed: () => {
        return;
      }
    };
  },
  inject: ['column'],
  computed: {
    viewOnly() {
      return this.$store.getters['boards/isViewOnly'];
    },
    openTaskIgnored() {
      return 'open-task--ignored';
    },
    config() {
      return {
        ...this.draggableConfig,
        scrollSensitivity: 90,
        scrollSpeed: 20
      };
    }
  },
  watch: {
    tasks: {
      handler(tasks) {
        this.setTasks(tasks);
      },
      immediate: true
    },
    addingActive(val) {
      if (val) {
        this.$nextTick(() => {
          this.scrollToBottom();
          this.textareaFocus();
        });
      }
    }
  },
  mounted() {
    this.onScrollDelayed = debounce(this.onScroll, 200, {
      leading: true,
      trailing: false
    });
  },
  methods: {
    onTaskClick: debounce(
      async function(task) {
        this.resetToBoardColumn();
        const to = this.$router.resolve({
          path: `${this.$route.path}/t/${task.id}`,
          query: this.$route.query
        });
        if (to.route.name !== 'PageNotFound') {
          this.openTask(task);
          this.$router.push(to.route).catch(err => {
            if (
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes(
                'Avoided redundant navigation to current location'
              )
            ) {
              this.$router.push(to.route);
            }
          });
        }
      },
      500,
      { leading: true }
    ),
    optimizeTasks() {
      const numberPerHeight = DISPLAY_STARTER / AT_SCREEN_HEIGHT;
      const byVisible = (_, i) => i < numberPerHeight * window.innerHeight;
      this.setTasks(this.tasks.filter(byVisible));
    },
    setTasks(tasks = []) {
      this.dragTasks = tasks;
    },
    resetToBoardColumn() {
      /*
       *  Task url is sometimes active but modal is not displayed
       *  so there will be reset route to board column before open task modal

       * As above comment, we can reproduce by opening task modal and edit crucial part in the file(modal-task.vue),
       * the modal will be closed down but remained url

        TODO: In order to prevent further unknown self closing issue, there will be required refactoring
       */
      const openPrevMatch = () => {
        const prevMatch = this.$route.matched[this.$route.matched.length - 2];
        this.$router.replace(prevMatch);
      };
      if (this.$route.params.task_id) {
        openPrevMatch();
      }
    },
    submit() {
      this.$emit('submit', this.addTaskName);
      this.addTaskName = '';
    },
    change(ev) {
      if (ev.removed) return;
      const ref = ev.added || ev.moved;
      const newIndex = ref.newIndex;
      let before = this.dragTasks[newIndex - 1]?.position;
      let after = this.dragTasks[newIndex + 1]?.position;

      ref.position = 0;

      // when it just created
      if (!before && !after) ref.position = 1;
      else {
        // when drop move at top
        if (!before) before = 0;
        // when move to bottom or new item with existed list
        else if (!after) after = before * 3;

        ref.position = (before + after) / 2;
      }

      this.$emit('drag', ev);
    },
    dragstart() {
      this.isDragging = true;
    },
    dragend() {
      this.isDragging = false;
    },
    onScroll(e) {
      EventBus.$emit('scroll', e);
    },
    scrollToBottom() {
      const { draggable } = this.$refs;
      const { scrollHeight } = draggable.$el;
      draggable.$el.scrollTo({ top: scrollHeight });
    },
    textareaFocus() {
      this.$refs.addingTaskTextarea.focus();
    },
    onCreateCancel() {
      this.addTaskName = '';
      this.$emit('add-cancel');
    }
  }
};
</script>

<style lang="scss">
.board-columns__cards {
  @apply flex-1 flex flex-col items-center overflow-hidden overflow-y-auto max-h-full;
}
.task-card {
  width: 264px;
}
.task-card--dragging {
  @apply flex justify-center;
  flex: 0 0 auto;

  &:not(.dragging-task):last-child {
    @apply mb-4;
  }
}
.dragging-task--ghost {
  @apply invisible;
  & > .task-card,
  .task-card .card__body {
    @apply bg-gray-200;
  }
}

.dragging-task {
  @apply bg-white;
  opacity: 1 !important;

  &.task-card--dragging {
    @apply bg-transparent;
  }
}
</style>
