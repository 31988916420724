<template>
  <div>
    <notifications
      class="mt-2"
      group="task-error"
      classes="vue-notification text-lg"
      @click.native="goToBoard"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  props: {
    modalName: {
      type: String,
      default: 'task-detail'
    }
  },
  computed: {
    ...mapState('tasks', ['isTaskOpen'])
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.$notify({ group: 'task-error', clean: true });
    });
  },
  beforeRouteUpdate(_to, _from, next) {
    this.$notify({ group: 'task-error', clean: true });
    next();
  },
  beforeRouteLeave(_to, _from, next) {
    this.$modal.hide(this.modalName);
    next();
  },
  watch: {
    '$route.params.task_id': {
      handler(taskId) {
        if (taskId) this.openTaskDetail(taskId);
      },
      immediate: true
    },

    '$route.params.linkId': {
      handler(linkId) {
        if (linkId) this.openTaskFromLink(linkId);
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('tasks', ['fetchTask', 'fetchTaskLink']),
    goToBoard() {
      this.$router.replace({
        name: 'Boards-Id',
        params: {
          id: this.$route.params.id
        }
      });
    },
    async openTaskDetail(taskID) {
      try {
        await this.fetchTask(taskID);
      } catch (error) {
        this.handleError();
      }
      if (!this.isTaskOpen) this.$modal.show(this.modalName);
    },
    async openTaskFromLink(linkId) {
      try {
        await this.fetchTaskLink(linkId);
        this.$modal.show(this.modalName);
      } catch (error) {
        this.handleError();
      }
    },
    handleError() {
      this.$notify({
        title: this.$t('notifications.popup.service'),
        text:
          '<p class="text-base">Click here to hide this message. If you cannot proceed, try to refresh.</p>',
        type: 'error',
        group: 'task-error',
        duration: -1
      });

      this.goToBoard();
    }
  }
};
</script>

<style></style>
