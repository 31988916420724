var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar h-auto px-4 md:px-8",class:{ block: _vm.forceShow, 'hidden md:block': !_vm.forceShow }},[_c('div',{staticClass:"divide-y flex flex-col h-full"},[_c('div',{staticClass:"ml-4"},[_c('base-collapse',{attrs:{"collapsed":""},scopedSlots:_vm._u([{key:"collapser",fn:function(ref){
var isCollapsed = ref.isCollapsed;
var toggle = ref.toggle;
return [_c('div',{staticClass:"flex justify-between items-center cursor-pointer mb-2 mt-4",class:{ 'mb-2': !isCollapsed },on:{"click":toggle}},[_c('base-icon',{staticClass:"mr-2",attrs:{"width":"24"}},[_c('icon-left-home')],1),_c('div',{staticClass:"flex-1 flex flex-col overflow-hidden"},[_c('v-clamp',{staticClass:"truncate",attrs:{"autoresize":"","max-lines":1}},[_c('span',{attrs:{"slot":"after"},slot:"after"},[_vm._v(" "+_vm._s(_vm.$t('sidebar.home'))+" ")])])],1),_c('base-icon',{attrs:{"name":"menu-more","size":"16"}},[(isCollapsed)?_c('icon-chevron-right'):_c('icon-chevron-down')],1)],1)]}}])},_vm._l((_vm.menuHome),function(menu){return _c('base-dropdown-item',{key:("tab-" + (menu.key)),staticClass:"ml-4",class:{ 'sidebar__item--active': menu.key == _vm.activeSidebar }},[_c('router-link',{attrs:{"to":{ name: menu.key }}},[_c('div',{staticClass:"flex items-center",class:{ 'text-brand-blue': menu.key == _vm.activeSidebar }},[_c('base-icon',{attrs:{"size":"16"}},[_c(("icon-" + (menu.icon)),{tag:"component"})],1),_c('div',[_vm._v(_vm._s(menu.name))])],1)])],1)}),1)],1),_vm._l((_vm.sidebars),function(sidebar){return _c('ul',{key:sidebar.key,staticClass:"list-none py-2",staticStyle:{"border-top-width":"0 !important"}},_vm._l((sidebar.children),function(sidebarChild){return _c('li',{key:sidebarChild.key,staticClass:"sidebar__item",class:{
          'sidebar__item--active': sidebarChild.key === _vm.activeSidebar
        }},[(sidebarChild.link)?_c('router-link',{attrs:{"data-testid":((sidebarChild.name) + "-btn"),"to":{ name: sidebarChild.key }}},[_c('base-icon',{staticClass:"mr-2",attrs:{"width":"24"}},[_c(sidebarChild.icon,{tag:"component"})],1),_vm._v(" "+_vm._s(sidebarChild.name)+" ")],1):_c('div',{staticClass:"px-4 py-2 outline-none",on:{"click":sidebarChild.click}},[_c('base-icon',{staticClass:"mr-2",attrs:{"view-box":"0 0 20 24"}},[_c(sidebarChild.icon,{tag:"component"})],1),_vm._v(" "+_vm._s(sidebarChild.name)+" ")],1)],1)}),0)}),(_vm.shouldShowTimelog)?_c('div',{staticClass:"py-4",attrs:{"data-testid":"should-show-timelog"}},[_c('div',{staticClass:"sidebar__header"},[_vm._v(" "+_vm._s(_vm.$t('sidebar.timelog'))+" ")]),_c('timelog-timer',{attrs:{"timelog":_vm.workingLog,"no-start":""}})],1):_vm._e(),_c('v-scroller',{staticClass:"py-3 overflow-y-auto pr-2",attrs:{"data-testid":"team"},on:{"bottom":_vm.scrollBottom}},[_c('div',{staticClass:"flex justify-between mb-2"},[_c('div',{staticClass:"sidebar__header"},[_vm._v(" "+_vm._s(_vm.$t('sidebar.team'))+" ")]),_c('div',{attrs:{"data-testid":"icon-create-team-btn"},on:{"click":_vm.createTeamClick}},[_c('base-icon',{staticClass:"align-top cursor-pointer",attrs:{"width":16,"height":16,"name":"plus","data-testid":"icon-create-team"}},[_c('icon-plus')],1)],1)]),_c('ul',{staticClass:"list-none pb-4"},_vm._l((_vm.teams),function(team){return _c('base-collapse',{key:team.id,attrs:{"collapsed":"","default-collapsed":""},scopedSlots:_vm._u([{key:"collapser",fn:function(ref){
        var isCollapsed = ref.isCollapsed;
        var toggle = ref.toggle;
return [_c('div',{staticClass:"flex justify-between items-center cursor-pointer my-4",class:{ 'mb-2': !isCollapsed },on:{"click":toggle}},[_c('div',{staticClass:"mr-2"},[(!team.imageLogo)?_c('base-icon',{staticClass:"w-6 text-center"},[_c('icon-team')],1):_c('img',{staticClass:"w-6 h-6 object-cover rounded ",attrs:{"src":team.imageLogo}})],1),_c('div',{staticClass:"flex-1 flex flex-col overflow-hidden"},[_c('v-clamp',{staticClass:"truncate",attrs:{"autoresize":"","max-lines":1}},[_c('span',{attrs:{"slot":"after","data-testid":((team.name) + "-btn")},slot:"after"},[_vm._v(" "+_vm._s(team.name)+" ")])])],1),_c('base-icon',{attrs:{"name":"menu-more","size":"16"}},[(isCollapsed)?_c('icon-chevron-right'):_c('icon-chevron-down')],1)],1)]}}],null,true)},_vm._l((_vm.menuWorkspace),function(menu){return _c('base-dropdown-item',{key:("tab-" + (menu.key)),staticClass:"ml-4",class:{ 'sidebar__item--active': menu.key == _vm.activeSidebar }},[_c('router-link',{attrs:{"to":{ name: menu.key, params: { id: team.id } }}},[_c('div',{staticClass:"flex items-center"},[_c('base-icon',{attrs:{"size":"16"}},[_c(("icon-" + (menu.icon)),{tag:"component"})],1),_c('div',[_vm._v(_vm._s(menu.name))])],1)])],1)}),1)}),1)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }