<template>
  <div class="p-6 text-center">
    <base-button
      data-testid="close-btn"
      class="absolute top-0 right-0 mt-3 mr-2"
      icon="close"
      icon-size="18"
      color="text"
      @click="$modal.hide('payment-success')"
    />
    <lottie-player
      src="https://assets3.lottiefiles.com/packages/lf20_uemoq57r.json"
      background="transparent"
      speed="1"
      style="width: 180px; height: 180px;"
      autoplay
      class="mx-auto"
    />
    <div v-if="!isChangePlan">
      <p class="text-xl">{{ $t('header_modal') }}</p>
      <p class="text-sm text-gray-caption mt-3">
        {{
          $t('description_modal', {
            email: profile.email
          })
        }}
      </p>
      <base-button
        class="mt-6"
        icon="plus"
        icon-size="16"
        style="margin-right: 0px"
        @click="createWorkspace"
      >
        {{ $t('create.workspace') }}
      </base-button>
      <br />
      <base-button
        icon="UserPlusOutline"
        class="text-brand-blue bg-white mt-2"
        color="text"
        icon-size="16"
        @click="openMemberInvite"
      >
        {{ $t('myBoard.invite') }}
      </base-button>
    </div>
    <div v-else>
      <p class="text-xl">ยอดเงินจะถูกปรับในบิลหน้า</p>
      <p class="text-sm text-gray-caption mt-3">
        การคำนวนยอดเงินหากยอดเงินตํ่ากว่า 20 บาท
        <br />
        ยอดเงินจะถูกคิดในบิลถัดไป
      </p>
      <base-button class="mt-4" @click="$modal.hide('payment-success')">
        ปิด
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isChangePlan: false
    };
  },
  computed: {
    ...mapGetters('subscription', ['getPlanChange']),
    profile() {
      return this.$store.getters['auth/getUser'];
    }
  },

  mounted() {
    this.isChangePlan = this.getPlanChange.length != 0 ? true : false;
  },
  methods: {
    createWorkspace() {
      this.$modal.hide('payment-success');
      this.$modal.hide('plan-payment');
      this.$modal.show('create-workspace');
    },
    openMemberInvite() {
      this.$modal.show('invite-plan');
    }
  }
};
</script>

<i18n>
{
  "en": {
    "header_modal": "Payment Susccessful",
    "description_modal":"Your payment is successful. The system has sent your purchase detail via {email}. Please check the detail for confirmation."
  },
  "th": {
    "header_modal": "การชำระเงินสำเร็จ",
    "description_modal":"การชำระเงินสำเร็จระบบได้ทำการส่งรายละเอียดคำสั่งซื้อไปยังอีเมล {email} โปรดตรวจสอบรายละเอียดอีกครั้ง"
  }
}
</i18n>
