<template>
  <div
    class="base-dropdown__item"
    :class="{ hoverable, active, danger }"
    @click="click"
  >
    <slot />
  </div>
</template>

<script>
export default {
  inject: {
    parent: {
      default: null
    }
  },
  props: {
    hoverable: {
      type: Boolean,
      default: true
    },
    danger: Boolean,
    active: Boolean,
    keep: Boolean
  },
  methods: {
    click(ev) {
      if (this.keep) {
        ev.stopPropagation();
      } else {
        this.parent?.hide();
      }
      this.$emit('click', ev);
    }
  }
};
</script>

<style>
.base-dropdown__item {
  @apply px-4 py-2 outline-none;
}
.base-dropdown__item.hoverable:not(.active):hover {
  @apply bg-gray-main cursor-pointer;
}
.base-dropdown__item.active {
  @apply bg-brand-blue text-white;
}
.base-dropdown__item.danger {
  @apply text-red-600;
}
.base-dropdown__item.danger.hoverable:hover {
  @apply bg-red-200;
}
</style>
