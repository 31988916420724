<template>
  <div>
    <div class="grid gap-y-4">
      <div>
        <p v-if="!isRenew">{{ $t('subscription.payment.summary.header') }}</p>
        <span v-else>{{ $t('payment_exprie_header') }}</span>
        <p class="text-sm text-gray-caption">
          {{
            $t('subscription.payment.summary.description', {
              price: priceFormat(previewPlanOrder.pricePerSeat)
            })
          }}
        </p>
      </div>
      <div v-if="!isRenew">
        <span>{{ $t('subscription.upgradeWorkspace.member') }}</span>
        <base-select
          style="width:200px;"
          full-width
          max-height="300"
          :options="optionSeat"
          :placeholder="seatNum.label"
          placeholder-classes="text-black"
          @change="onDurationChanged"
        />
      </div>
      <div v-if="!isRenew">
        <p style="margin-bottom: 5px">{{ $t('selected_plan') }}</p>
        <div class="border p-3 rounded">
          <div class="font-medium">
            {{ previewPlanOrder.name }}
          </div>
          <div>
            <span class="font-medium text-2xl"
              >฿{{ priceFormat(previewPlanOrder.pricePerSeat) }}
            </span>
            <span class="text-sm">{{
              $t('subscription.payment.seat_per_month')
            }}</span>
          </div>
          <!--  <div class="text-sm text-gray-caption">
            {{ getPlanSelected.priceDescription || '' }}
          </div> -->
        </div>
      </div>
      <div
        v-if="getCardDefault"
        class="grid grid-cols-3 text-sm border-b-1 border-t py-2"
      >
        <div class="auto-rows-auto border-r-2 mr-4">
          <p class="text-sm text-gray-caption">
            {{ $t('subscription.payment.summary.paymentDate') }}
          </p>
          <p>{{ paymentDate }}</p>
        </div>
        <div class="auto-rows-auto border-r-2 mr-4">
          <p class="text-sm text-gray-caption">
            {{ $t('bank_name') }}
          </p>
          <p class="truncate">{{ getCardDefault.bank_name }}</p>
        </div>
        <div class="auto-rows-auto mr-4">
          <p class="text-sm text-gray-caption">
            {{ $t('subscription.payment.summary.paymentMethod') }}
          </p>
          <p>
            <base-icon>
              <component :is="`icon${getCardDefault.brand}`" /> </base-icon
            ><span> {{ getOnlyNumber(getCardDefault.card_no) }}</span>
          </p>
        </div>
      </div>
      <div class="auto-rows-auto text-sm	gap-y-1 grid">
        <p>{{ $t('subscription.payment.summary.details') }}</p>
        <div class="flex justify-between">
          <span
            >{{ getSeat }} {{ $t('subscription.payment.summary.seat') }}</span
          >
          <span
            >({{ priceFormat(previewPlanOrder.pricePerSeat) }} x
            {{ getSeat }}
            {{ $t('subscription.payment.summary.seat') }}) ฿
            {{ priceFormat(getPrice) }}
            {{ $t('subscription.thai_bath') }}</span
          >
        </div>
        <div class="flex justify-between">
          <span>{{ $t('subscription.payment.summary.period') }}</span>
          <div>
            <p>{{ periodDate }}</p>
          </div>
        </div>
        <div class="flex justify-between">
          <span>{{ $t('subscription.payment.summary.tax') }}</span>
          <span>฿ {{ getTaxes }} {{ $t('subscription.thai_bath') }}</span>
        </div>
      </div>
      <div class="total-box">
        <div class="flex justify-between">
          <p>{{ $t('total') }}:</p>
          <p class="text-lg">
            ฿ {{ priceFormat(getPriceTotal) }}
            {{ $t('subscription.thai_bath') }}
          </p>
        </div>
        <div class="flex my-2">
          <p class="text-gray-caption ml-auto">
            แผน: เรียกเก็บรายเดือน เริ่มชำระเงินในวันที่ {{ paymentDate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconMasterCard from '@/components/icons/IconMasterCard';
import IconVisa from '@/components/icons/IconVisa';
import { mapGetters } from 'vuex';
import SelectOptionSeat from '@/mixins/OptionSeat';
import moment from 'moment';
import FormatStartEndDate from '@/mixins/FormatStartEndDate.ts';

export default {
  props: {
    isRenew: { type: Boolean, default: false }
  },
  mixins: [SelectOptionSeat, FormatStartEndDate],
  components: {
    IconVisa,
    IconMasterCard
  },
  data() {
    return {
      seatNum: { label: '5 seats', value: 5 }
    };
  },
  mounted() {
    this.seatNum = this.optionSeat[0];
  },
  computed: {
    ...mapGetters('subscription', [
      'getSelectPlan',
      'getNumberMember',
      'getPlanSelected',
      'getPlanPreview',
      'getPlanChange',
      'getCardDefault',
      'getCurrentPlan',
      'getSeatPreview'
    ]),
    suggessNumberMember() {
      return this.$store.getters['auth/getUser']
        .suggestionNumberOfSubscriptionMember;
    },
    paymentDate() {
      return moment(this.getPlanPreview.createdAt).format('D MMM YY');
    },
    periodDate() {
      return this.formatShowDate(
        this.getPlanPreview.startAt,
        this.getPlanPreview.endAt
      );
    },
    previewPlanOrder() {
      return this.getPlanPreview.subscriptionOrder;
    },
    optionSeat() {
      const numberSuggess =
        this.getPlanChange[0]?.numberOfSeats || this.suggessNumberMember || 5;
      const optionSuggess = this.SelectOptionSeat.filter(
        option => option.value >= numberSuggess || option.value == 0
      );
      return optionSuggess;
    },
    planId() {
      return this.previewPlanOrder.planID;
    },
    getPrice() {
      return this.getPlanChange[0]?.changeSeat
        ? this.getSeatPreview.sub_total
        : this.previewPlanOrder.pricePerSeat *
            this.previewPlanOrder.numberOfSeats;
    },
    getPriceTotal() {
      return this.getPlanChange[0]?.changeSeat
        ? this.getSeatPreview.total
        : this.previewPlanOrder.total;
    },
    getTaxes() {
      return this.getPlanChange[0]?.changeSeat
        ? this.getSeatPreview.taxes
        : this.previewPlanOrder.taxes;
    },
    getSeat() {
      return this.getPlanChange[0]?.changeSeat
        ? this.getSeatPreview.number_of_increase_seats
        : this.previewPlanOrder.numberOfSeats;
    }
  },
  methods: {
    async onDurationChanged(value) {
      const seatSelect = this.SelectOptionSeat.find(x => x.value === value);
      this.seatNum = seatSelect;
      this.$store.commit('subscription/SET_NUMBER_MEMBER', this.seatNum.value);
      this.$emit('seatSelect', this.seatNum);

      if (this.getPlanChange[0]?.changeSeat) {
        this.$store.dispatch('subscription/subscriptionSeatPreview', {
          numberOfSeats: parseInt(this.seatNum.value)
        });
      } else {
        await this.$store.dispatch('subscription/subscriptionPlanPreview', {
          numberOfSeats: this.seatNum.value,
          planID: this.planId
        });
      }
    },
    getOnlyNumber(card) {
      return card?.slice(-4);
    },
    priceFormat(price) {
      return new Intl.NumberFormat().format(price);
    }
  }
};
</script>

<style scope>
.total-box {
  @apply auto-rows-auto px-4 py-2 my-2 text-sm;
  background: #f4f5f7;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>
<i18n>
{
  "en": {
    "payment_exprie_header": "$tชำระเงินก่อนกำหนดหมดอายุ",
    "selected_plan": "Plan Selected",
    "bank_name": "Bank Name",
    "total": "Total"
  },
  "th": {
    "payment_exprie_header": "ชำระเงินก่อนกำหนดหมดอายุ",
    "selected_plan": "แผนที่ที่เลือก",
    "bank_name": "ธนาคารผู้ออกบัตร",
    "total": "รวม"
  }
}
</i18n>
