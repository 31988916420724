





























import Vue, { PropType } from 'vue';

export default Vue.extend({
  data() {
    return {
      opened: false
    };
  },
  methods: {
    async closeModal() {
      this.$modal.hide('alert-freetrial');
    },
    async createWorkspace() {
      this.$modal.hide('alert-freetrial');
      this.$modal.show('create-workspace');
    }
  }
});
