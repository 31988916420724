import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

interface Messages {
  [key: string]: {};
}

function loadLocaleMessages() {
  const locales = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages: Messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
});

// change language

// <button @click="$i18n.locale = $i18n.locale == 'en' ? 'th' : 'en'">
//     {{ $i18n.locale }}
// </button>
// <div>{{ $t('lang') }}</div>

// if (this.$i18n.locale !== locale) {
//   this.$i18n.locale = locale;
//   const to = this.$router.resolve({ params: { locale } }); // <--------------------
//   this.$router.push(to.location); // <--------------------
// }
