import apisSprints from '@/services/apis-sprints';
import { Sprint, SprintCreate, SprintStartParams } from '@/types/Sprint';
import { RootState } from '@/types/Store';
import { Task } from '@/types/Task';
import { Module } from 'vuex';
import { getNewListWithUpdate, getRemovedItemArray, isIncluded } from './utils';

interface StateSprint extends Sprint {
  tasks: Task[];
  allowStart: boolean;
  isBacklog: boolean;
  points: number;
}

interface SelectedTask {
  id: number;
}
interface SprintState {
  sprints: StateSprint[];
  isShowSelect: boolean;
  selectedTask: SelectedTask[];
}

interface StartSprintParams {
  id: number;
  data: SprintStartParams;
}

const BACKLOG_ID = 0;

const sortSprint = (a: StateSprint, b: StateSprint) =>
  isIncluded([a, b], BACKLOG_ID) ? -1 : a.id - b.id;
const findActiveSprint = (sprints: Sprint[]) => sprints.find(s => s.isActive);
const createStateSprint = (sprint: Sprint): StateSprint => ({
  ...sprint,
  tasks: [],
  isBacklog: !sprint.id,
  id: sprint.id ? sprint.id : BACKLOG_ID,
  allowStart: false,
  points: 0
});
const sumTaskPoints = (points: number, task: Task) => {
  const storyPoint = task.storyPoint || 0;
  return points + storyPoint;
};

const sprintModule: Module<SprintState, RootState> = {
  namespaced: true,
  state: {
    sprints: [],
    isShowSelect: false,
    selectedTask: []
  },
  getters: {
    getSelectedTask(state) {
      return state.selectedTask;
    },
    getShowSelect(state) {
      return state.isShowSelect;
    },
    getSortedSprintById({ sprints }): Sprint[] {
      return sprints.map(e => e).sort(sortSprint);
    },
    getSprints(_state, getters, _rootState, rootGetters): StateSprint[] {
      const sprints = getters.getSortedSprintById as Sprint[];
      const tasks = rootGetters['tasks/getSortedTasks'] as Task[];
      const getSprintTasks = (sprint: Sprint) =>
        tasks.filter(t => (t.sprintID || BACKLOG_ID) === sprint.id);
      const shouldStart = !sprints.some(sprint => sprint.isActive);
      const taskSprints = sprints.map((e, index) => {
        const sprint = createStateSprint(e);
        const isFirstItem = index === 0;
        const isSprint = sprint.id !== BACKLOG_ID;
        sprint.tasks = getSprintTasks(sprint);
        sprint.points = sprint.tasks.reduce(sumTaskPoints, 0);
        sprint.allowStart = isSprint && isFirstItem && shouldStart;
        return sprint;
      });
      return taskSprints;
    },
    getActiveSprint(_, getters): Sprint | undefined {
      return findActiveSprint(getters.getSprints);
    },
    isSprintsEmpty({ sprints }): boolean {
      return sprints.length === 1;
    },
    canStartSprint(_, getters): boolean {
      return (getters.getReadyToStartSprint as StateSprint | undefined) != null;
    },
    getReadyToStartSprint(_, getters): StateSprint | undefined {
      return (getters.getSprints as StateSprint[]).find(s => s.allowStart);
    },
    countTasks(_, getters) {
      const sprints = getters.getSprints as StateSprint[];
      return sprints.reduce((p, c) => c.tasks.length + p, 0);
    }
  },
  mutations: {
    RESET_SELECTED_TASK(state) {
      state.selectedTask = [];
    },
    SET_SELECTED_TASK(state, value: { id: number }) {
      const task_id = value.id;
      const filterSameId = state.selectedTask.filter(t => t.id === task_id);

      if (filterSameId.length) {
        state.selectedTask = state.selectedTask.filter(t => t.id !== task_id);
      } else state.selectedTask = [...state.selectedTask, value];
    },
    SET_SHOW_SELECT_TASK(state, value) {
      state.isShowSelect = value;
    },
    SET_SPRINTS(state, sprints: StateSprint[]) {
      state.sprints = sprints;
    },
    SET_SPRINT(state, sprint: StateSprint) {
      state.sprints = getNewListWithUpdate(state.sprints, sprint);
    },
    SET_SPRINT_START(state, sprintStart: StartSprintParams) {
      const {
        id,
        data: { startAt, endAt }
      } = sprintStart;
      let sprint = state.sprints.find(s => s.id === id) as StateSprint;
      sprint = {
        ...sprint,
        startAt,
        endAt,
        isActive: true
      };
      state.sprints = getNewListWithUpdate(state.sprints, sprint);
    },
    SET_SPRINT_COMPLETE(state, target: Sprint) {
      state.sprints = getRemovedItemArray(state.sprints, target.id);
    },
    ADD_SPRINT(state, sprint: StateSprint) {
      const beforeBacklog = state.sprints.length - 2;
      state.sprints.splice(beforeBacklog, 0, sprint);
    },
    REMOVE_SPRINT(state, sprintId: number) {
      state.sprints = getRemovedItemArray(state.sprints, sprintId);
    }
  },
  actions: {
    fetchSprints: async ({ commit, rootGetters }, boardID) => {
      const boardModalId = boardID
        ? boardID
        : rootGetters['boards/getActiveBoardId'];
      const params = {
        boardID: boardModalId
      };
      const res = await apisSprints.indexList({ params });
      commit('SET_SPRINTS', res.data);
    },
    createSprint: async ({ commit }, value: SprintCreate) => {
      const res = await apisSprints.create(value);
      commit('ADD_SPRINT', res.data);
    },
    updateSprint: async ({ commit }, value: Sprint) => {
      commit(
        'SET_SPRINT',
        await apisSprints.update(value.id, value).then(e => e.data)
      );
    },
    deleteSprint: async ({ commit }, id) => {
      await apisSprints.delete(id);
      commit('REMOVE_SPRINT', id);
    },
    startSprint: async ({ commit }, startEnd: StartSprintParams) => {
      await apisSprints.setStart(startEnd.id, startEnd.data);
      commit('SET_SPRINT_START', startEnd);
    },
    setSprintCompleted: async ({ commit, getters }, targetSprintID: number) => {
      const activeSprint = getters.getActiveSprint as Sprint;
      const nextSprint = getters.getSprints.find(
        (s: Sprint) => s.id === targetSprintID
      );
      const taskUpdate = {
        nextSprint,
        prevSprint: activeSprint
      };
      await apisSprints.setComplete({ id: activeSprint.id, targetSprintID });
      commit('SET_SPRINT_COMPLETE', activeSprint);
      commit('tasks/SET_TASKS_SPRINT', taskUpdate, { root: true });
    }
  }
};

export default sprintModule;
