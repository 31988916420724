<template>
  <base-avatar
    ref="avatarList"
    :list="getMembers"
    :list-total="getCountMembers"
    :list-max="userMax"
    :list-more-all="false"
    :loading="isNextValid"
    popup
    dropdown-position="bottom-end"
    list-max-height="460"
    :selected="getFilterIds"
    @scroll-bottom="loadMoreUser"
    @click="avatarClick"
  >
    <div slot="popover">
      <div class="sticky top-0 z-20 bg-white">
        <div class="flex text-lg mb-2 px-4 justify-between items-center">
          <span> {{ $t('myTasks.moreInvite.name') }} </span>
          <div v-close-popover class="cursor-pointer">
            <base-icon size="20">
              <IconClose />
            </base-icon>
          </div>
        </div>
        <div class="mb-4 border-b border-gray-200" />
      </div>
      <div class="relative px-4 pb-4">
        <base-input-text
          v-model="q"
          :placeholder="$t('myTasks.moreInvite.placeholder')"
          icon-fa="search"
          type="text"
          class="input-pr-8"
          inner-class="base-input--gray"
          style="background: #F2F3F4;"
        />
        <div v-if="q" class="icon--clear" @click="q = ''">
          <base-icon size="14" name="close" class="mr-3">
            <icon-close />
          </base-icon>
        </div>
      </div>
      <div v-if="!q">
        <!-- unassigned -->
        <board-members-item
          :unassigned="true"
          :selected="getFilterIds.includes(-1)"
          @select="selectUser(-1, $event)"
        />

        <!-- me -->
        <board-members-item
          :user="$store.getters['auth/getUser']"
          :me="true"
          :selected="getFilterIds.includes(meId)"
          @select="selectUser(meId, $event)"
        />
      </div>

      <div
        v-if="isSearchMemberEmpty"
        class="text-gray-500 text-center p-4 text-sm"
      >
        {{ $t('myTasks.moreInvite.error') }}
      </div>

      <div v-if="admins.length" class="board-members__heading">
        {{ $t('myTasks.moreInvite.admin') }}
      </div>
      <board-members-item
        v-for="(u, i) in admins"
        :key="`admin-${i}`"
        :user="u"
        :selected="getFilterIds.includes(u.user.id)"
        @select="selectUser(u.userID, $event)"
      />

      <div v-if="editors.length" class="board-members__heading">
        {{ $t('myTasks.moreInvite.editor') }}
      </div>
      <board-members-item
        v-for="(u, i) in editors"
        :key="`editor-${i}`"
        :user="u"
        :selected="getFilterIds.includes(u.user.id)"
        @select="selectUser(u.userID, $event)"
      />

      <div v-if="viewers.length" class="board-members__heading">
        {{ $t('myTasks.moreInvite.viewer') }}
      </div>
      <board-members-item
        v-for="(u, i) in viewers"
        :key="`viewer-${i}`"
        :user="u"
        :selected="getFilterIds.includes(u.user.id)"
        @select="selectUser(u.userID, $event)"
      />
    </div>
  </base-avatar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BoardMembersItem from './board-members-item.vue';
const { mapGetters, mapActions } = createNamespacedHelpers('members');
import IconClose from '@/components/icons/IconClose';
import { BoardMemberRole } from '@/types/Board';

export default {
  components: { BoardMembersItem, IconClose },
  data() {
    return {
      loading: false,
      q: '',
      userMax: 4
    };
  },
  computed: {
    ...mapGetters([
      'getMembers',
      'isNextValid',
      'getFilterIds',
      'getCountMembers'
    ]),
    meId() {
      return this.$store.getters['auth/getUser'].id;
    },
    users() {
      return this.getMembers.map(e => e.user);
    },
    admins() {
      return this.getMembers.filter(
        u =>
          u.status > 1 &&
          u.role == BoardMemberRole.Admin &&
          u.user.id != this.meId &&
          (this.q == ''
            ? true
            : u.user.name.toLowerCase().indexOf(this.q.toLowerCase()) > -1)
      );
    },
    editors() {
      return this.getMembers.filter(
        u =>
          u.status >= 1 &&
          u.role == 2 &&
          u.user.id != this.meId &&
          (this.q == ''
            ? true
            : u.user.name.toLowerCase().indexOf(this.q.toLowerCase()) > -1)
      );
    },
    viewers() {
      return this.getMembers.filter(
        u =>
          u.status >= 1 &&
          u.role == BoardMemberRole.Viewer &&
          u.user.id != this.meId &&
          (this.q == ''
            ? true
            : u.user.name.toLowerCase().indexOf(this.q.toLowerCase()) > -1)
      );
    },
    isSearchMemberEmpty() {
      const isMemberNotFound = [this.admins, this.editors, this.viewers].every(
        e => e.length < 1
      );
      return isMemberNotFound && this.q;
    }
  },
  created() {
    const { assigneeIDs } = this.$route.query;
    const assigneeIDsArr = assigneeIDs
      ? assigneeIDs.split(',').map(e => parseInt(e))
      : [];
    this.setFilter(assigneeIDsArr);
  },
  methods: {
    ...mapActions(['fetchMembers', 'setFilter']),
    async loadMoreUser() {
      if (this.isNextValid) {
        this.loading = true;
        await this.fetchMembers();
      }
      this.loading = false;
    },
    selectUser(uid, select) {
      let ids = [...this.getFilterIds];
      if (select) {
        ids.push(uid);
      } else {
        ids = this.getFilterIds.filter(id => id != uid);
      }

      this.setFilter(ids);
      this.$emit('change', [...ids]);
    },
    clearSelected() {
      this.setFilter([]);
    },
    avatarClick() {
      this.$refs.avatarList.dropdown();
    }
  }
};
</script>

<style scoped>
.board-members__heading {
  @apply text-sm text-gray-500 mt-3 mb-1 px-4;
}
</style>
