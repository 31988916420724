<template>
  <bar-chart
    :width="1000"
    :height="200"
    :chart-data="chartData"
    :options="chartOptions"
    :styles="chartStyles"
  />
</template>
<script>
import BarChart from '@/components/chart/chart-bar';
export default {
  components: {
    BarChart
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          '<= 1 hrs': 0,
          '2 hrs': 0,
          '3 hrs': 0,
          '4 hrs': 0,
          '5 hrs': 0,
          '6 hrs': 0,
          '7 hrs': 0,
          '>= 7 hrs': 0
        };
      }
    }
  },
  computed: {
    sortedKey() {
      const sorter = {
        '<= 1 hrs': 1,
        '2 hrs': 2,
        '3 hrs': 3,
        '4 hrs': 4,
        '5 hrs': 5,
        '6 hrs': 6,
        '7 hrs': 7,
        '>= 7 hrs': 8
      };

      const sortedKey = Object.keys(this.data).sort(function(a, b) {
        return sorter[a] - sorter[b];
      });
      return sortedKey;
    },
    chartData() {
      const data = [];
      const color = [];

      for (let i = 0; i < this.sortedKey.length; i++) {
        data.push(this.data[this.sortedKey[i]]);
        color.push('#168AE2');
      }
      return {
        datasets: [
          {
            data: data,
            backgroundColor: color
          }
        ]
      };
    },
    chartOptions() {
      const tickPadding = 14;
      const drawTicks = false;
      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              type: 'category',
              labels: this.sortedKey,
              gridLines: {
                drawTicks,
                drawOnChartArea: false,
                color: '#E9EBF0'
              },
              maxBarThickness: 40,
              ticks: {
                padding: tickPadding,
                fontColor: 'text-black'
              },
              scaleLabel: {
                display: true,
                labelString: `${this.$t('personalReport.timelog.time')}`,
                fontColor: '#BFBFC3',
                fontSize: 12
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                display: true,
                precision: true,
                fontColor: '#C1C3C3',
                beginAtZero: true,
                padding: tickPadding,
                maxTicksLimit: 4
              },
              gridLines: {
                drawTicks,
                zeroLineColor: '#E9EBF0',
                drawBorder: false,
                color: '#E9EBF0'
              },
              scaleLabel: {
                display: true,
                labelString: `${this.$t('personalReport.timelog.task')}`,
                fontColor: '#BFBFC3',
                fontSize: 12
              }
            }
          ]
        }
      };
    },
    chartStyles() {
      return {
        paddingTop: '1rem'
      };
    }
  }
};
</script>
<style scoped lang="scss"></style>
