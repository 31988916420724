import axios, { AxiosError, AxiosPromise } from 'axios';
import localJwt from './local-jwt';
import { LoginResponse } from '@/types/Auth';
import { ProfileResponse, GenericRestApiFactory } from '@/types/Api';
import { UserUpdate } from '@/types/User';
import { NotificationSetting } from '@/types/Notification';
import { FileUploadFn } from '@/types/File';
import { Task } from '@/types/Task';
import { ChecklistAdd, Checklist, ChecklistUpdate } from '@/types/Checklist';
import getBoardsApi from './apis-boards';
import getTasksApi from './apis-tasks';
import getTeamApi from './apis-teams';
import Vue from 'vue';
import i18n from '../plugins/i18n';
import getIgnoreNotification from './ignore-error-notifications';
interface FilesApi {
  upload: FileUploadFn;
  taskUpload(id: number, fileIDs: number[]): AxiosPromise<Task>;
}
let isRefreshing = false;
const shareLinkRegex = /\/sharelink\/[a-z0-9]+/i;

const tokens = localJwt.getTokens();
const apis = axios.create({
  baseURL: '/api/v1'
});

const notiError = (text = `${i18n.t('notifications.popup.unknow')}`) =>
  Vue.notify({
    text,
    title: `${i18n.t('notifications.popup.cannot')}`,
    type: 'error',
    group: 'app-noti'
  });
function setHeaders(target: string, value: string): void {
  apis.defaults.headers.common[target] = value;
}
if (tokens.accessToken)
  setHeaders('Authorization', `Bearer ${tokens.accessToken}`);
if (tokens.uuid) setHeaders('UUID', tokens.uuid);
apis.interceptors.request.use(
  function(config) {
    config.headers['Accept-Language'] = i18n.locale;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

apis.interceptors.response.use(
  res => res,
  async function(err: AxiosError) {
    const { response, config } = err;
    const ignoreNotificaition = getIgnoreNotification(config);
    // 2 notify error conditions
    const isStatusOk = response?.status == 200;
    const isSharelinkApi = shareLinkRegex.test(config.url as string);
    // 2 refresh token conditions
    const is401 = response?.status === 401;
    const { refreshToken } = localJwt.getTokens();

    if (is401 && refreshToken) {
      if (!isRefreshing) {
        isRefreshing = true;
        const { refreshToken } = localJwt.getTokens();
        try {
          const res = await apis.post<LoginResponse>('/guest/refreshToken', {
            refreshToken
          });
          config.headers['Authorization'] = `Bearer ${res.data.accessToken}`;
          localJwt.setTokens(res.data);
          // apiQueues.map(cb => cb(res.data.accessToken));
          // apiQueues = [];
          return apis(config);
        } catch (error) {
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      }
      // const retryRequest = new Promise(resolve => {
      //   addQueues(newToken => {
      //     resolve(apis(config));
      //   });
      // });
      // return retryRequest;
    } else if (!isStatusOk && !isSharelinkApi) {
      if (!isRefreshing && !ignoreNotificaition() && !config.headers.hideNotify)
        notiError(response?.data?.message);
    }
    return Promise.reject(err);
  }
);

export const restFactory: GenericRestApiFactory = base => {
  const baseURL = `/${base}`;
  const baseId = (id: number) => `${baseURL}/${id}`;
  return {
    index: () => apis.get(baseURL),
    create: c => apis.post(baseURL, c),
    get: (id: number, config) => apis.get(baseId(id), config),
    update: (id: number, u) => apis.patch(baseId(id), u),
    delete: (id: number) => apis.delete(baseId(id)),
    indexList: config => apis.get(baseURL, config)
  };
};

export const profilesApi = {
  updateProfile: (d: UserUpdate) => apis.patch<ProfileResponse>(`/me`, d),
  updateNotification: (d: NotificationSetting) =>
    apis.patch<ProfileResponse>(`me/settings/notification`, d)
};

export const filesApi: FilesApi = {
  upload: (prefix, files: FileList) => {
    const formDataFile = new FormData();
    Array.from(files).forEach(file => {
      formDataFile.append('files', file);
    });
    return apis.post(`/files/${prefix}`, formDataFile);
  },
  taskUpload: async function(taskID, fileIDs) {
    return apis.post<Task>(`/tasks/${taskID}/files`, { fileIDs });
  }
};

export const checklistApi = (taskID: number) => ({
  ...restFactory<Checklist, ChecklistAdd>(`tasks/${taskID}/checklist`),
  setChecklist: (id: number, req: ChecklistUpdate) =>
    apis.put<Checklist>(`tasks/${taskID}/checklist/${id}`, req),
  setComplete: (checklistID: number) =>
    apis.post<Checklist>(`/tasks/${taskID}/checklist/${checklistID}/complete`),
  unsetComplete: (checklistID: number) =>
    apis.delete<Checklist>(`/tasks/${taskID}/checklist/${checklistID}/complete`)
});

export const boardsApi = getBoardsApi(restFactory);
export const tasksApi = getTasksApi(restFactory);
export const teamsApi = getTeamApi(restFactory);

export default apis;
