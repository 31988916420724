import Vue from 'vue';

export default Vue.extend({
  methods: {
    markdownText(textOrigin: string) {
      let text = textOrigin;
      const regexp = new RegExp(/\*\*([^**]*)\*\*/g);
      const res = text ? text.match(regexp) : [];
      if (res) {
        res.forEach(e => {
          text = text.replace(
            e,
            `<b class="break-words">${e.slice(2, e.length - 2)}</b>`
          );
        });
      }
      text = text.replace('mentions', 'mentioned');
      return text;
    }
  }
});
