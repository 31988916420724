import { Board, BoardMemberRole } from '@/types/Board';
import { User } from '@/types/User';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    boardId() {
      return parseInt(this.$route.params.id, 10);
    }
  },
  methods: {
    $get_role(board: Board) {
      return board.currentMember?.role;
    },
    isUserBoardAdmin(board: Board) {
      return this.$get_role(board) === BoardMemberRole.Admin;
    },
    isUserBoardEditor(board: Board) {
      return this.$get_role(board) === BoardMemberRole.Editor;
    },
    isUserBoardViewer(board: Board) {
      return this.$get_role(board) === BoardMemberRole.Viewer;
    },
    getBoardColor(board: Board) {
      return board.backgroundCover;
    },
    getBoardBackgroundColor(board: Board) {
      return {
        'background-color': this.getBoardColor(board)
      };
    },
    isMemberDeactivated(user: User): boolean {
      return Boolean(user.deactivatedAt);
    }
  }
});
