

















































































import Vue from 'vue';
import { RawLocation } from 'vue-router';
import { LoginRequest, Providers, ShareCodeType } from '@/types/Auth';
import { tasksApi } from '@/services/apis';
import localJwt from '@/services/local-jwt';
import BackgroundAuthLeft from '@/components/icons/BackgroundAuthLeft.vue';
import BackgroundAuthRight from '@/components/icons/BackgroundAuthRight.vue';
import LogoWorkkamiApp from '@/components/icons/LogoWorkkamiApp.vue';
import { baseTaskRouteNames } from './constants';
import { getTaskRouter } from './utils';
import { Task } from '@/types/Task';

interface TaskShortId extends Vue {
  showBackToHome: boolean;
}

interface OpenShareOption {
  type: string;
  title: string;
  description?: string;
  image: NodeRequire;
  primaryAction: {
    text: string;
    click: () => void;
  };
  secondaryAction?: {
    text: string;
    to?: RawLocation;
    pre?: string;
  };
}

const getTaskBySharelinkId = tasksApi.getTaskBySharelinkId;
const getTask = tasksApi.get;

const openShareOptions: OpenShareOption[] = [
  {
    type: 'guest',
    title: 'Guest',
    image: require('@/assets/image/share-task-guest.svg'),
    primaryAction: {
      text: 'Open Task',
      click: () => undefined
    }
  },
  {
    type: 'account',
    title: 'Have an account?',
    image: require('@/assets/image/share-task-account.svg'),
    primaryAction: {
      text: 'Login',
      click: () => undefined
    },
    secondaryAction: {
      pre: `Don't have an account yet?`,
      text: 'Sign Up'
    }
  }
];
export default Vue.extend({
  components: { LogoWorkkamiApp, BackgroundAuthLeft, BackgroundAuthRight },
  data() {
    return {
      taskRoutes: baseTaskRouteNames,
      openShareOptions,
      showBackToHome: false
    };
  },
  computed: {
    sharelinkId(): string {
      return this.$route.params.sharelink_id;
    },
    toOpenTaskPath(): string {
      return this.$route.path;
    }
  },
  async beforeRouteEnter(to, _from, next) {
    const linkId = to.params.sharelink_id;
    const locale = to.params.locale || 'en';
    const isSharelinkId = /[a-z]/i.test(linkId);

    // To previously support opening task from email link about task update,
    // this views was used to open task while sharelink_id was also task ID
    // by calling getTask(linkId) to get task detail.
    const task = isSharelinkId
      ? await getTaskBySharelinkId(linkId)
          .then(s => s.data)
          .catch(() => null)
      : await getTask(linkId)
          .then(s => s.data)
          .catch(() => null);

    const handleIsLoggedIn = () => {
      // This page was used to open the board email update as well
      // The defined variable "task" could be either board or task previously
      if (!task) {
        if (localJwt.hasAccessToken()) {
          next(vm => {
            (vm as TaskShortId).showBackToHome = true;
          });
        } else {
          localJwt.removeTokens();
          next();
        }
        return;
      }
      // * when shared task privately, board cannot be displayed;
      let openTaskRoute;
      if (task.board) {
        openTaskRoute = getTaskRouter(to, task as Task);
      } else {
        openTaskRoute = {
          name: 'Boards-Link-Task',
          params: {
            locale,
            linkId
          }
        };
      }
      next(openTaskRoute);
    };

    if (localJwt.existed()) handleIsLoggedIn();
    else if (isSharelinkId) next();
    else next({ name: 'landing', query: { to: `/${locale}/t/${linkId}` } });
  },
  created() {
    this.setOptionPrimaryClick();
    this.setLoginClick();
  },
  methods: {
    setOptionPrimaryClick() {
      [this.openTaskAsGuest, this.rightCardAction].forEach((e, i) => {
        this.openShareOptions[i].primaryAction.click = e;
      });
    },
    setLoginClick() {
      const loginClickAction = this.openShareOptions[1];
      if (loginClickAction.secondaryAction) {
        loginClickAction.secondaryAction.to = {
          name: 'Signup',
          query: {
            to: this.toOpenTaskPath
          }
        };
      }
    },
    login(req: LoginRequest) {
      return this.$store.dispatch('auth/login', req);
    },
    async openTaskAsGuest() {
      const { sharelink_id: linkId } = this.$route.params;
      try {
        await this.login({
          loginType: Providers.GUEST,
          shareCode: this.sharelinkId,
          shareCodeType: ShareCodeType.Task
        });
        this.$router.push({
          name: 'Boards-Link-Task',
          params: {
            linkId: linkId
          }
        });
      } catch (error) {
        this.showBackToHome = true;
      }
    },
    rightCardAction() {
      this.$router.push({
        name: 'Landing',
        query: {
          to: this.toOpenTaskPath
        }
      });
    },
    homeClick() {
      if (localJwt.hasAccessToken()) this.$router.push({ name: 'Boards-List' });
      else this.$router.push({ name: 'Landing' });
    }
  }
});
