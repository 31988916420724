<template>
  <chart-bar-stacked
    ref="barChart"
    :width="400"
    :height="170"
    :chart-data="chartData"
    :options="options"
  />
</template>

<script>
import moment from 'moment';
import TimelogChartMixin from '@/mixins/TimelogChart';
import ChartBarStacked from '@/components/chart/chart-bar-stacked';

const DAYS_IN_WEEK = 7;
const DATE_FORMAT = 'ddd D';

export default {
  components: {
    ChartBarStacked
  },
  mixins: [TimelogChartMixin],
  props: {
    timelogs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    options() {
      return {
        ...this.timelogChartOptions,
        aspectRatio: 2,
        onClick: this.createOnClick(this.onBarClick)
      };
    },
    chartData() {
      const getTimeDiffInHour = (end, start) =>
        parseFloat(this.getTimeDiff({ start, end }, 'hours').toFixed(2));
      const firstItem = this.timelogs[0];
      const labels = firstItem
        ? [...Array(DAYS_IN_WEEK)].map((_, i) =>
            moment(firstItem.startAt)
              .locale(`${this.$t('notifications.language')}`)
              .startOf('week')
              .add(i, 'day')
              .format(DATE_FORMAT)
          )
        : [];
      /*
       * *  reduce timelgos to chartData stucture { data: [7], bgcolor: string }
       * *  by putting a timelog into array
       * *  according to its startAt matched on a day in active week
       */
      const datasets = this.timelogs.reduce((prev, tl) => {
        const x = moment(tl.startAt)
          .locale(`${this.$t('notifications.language')}`)
          .format(DATE_FORMAT);
        const y = getTimeDiffInHour(tl.endAt, tl.startAt);
        // array position and prefix, suffix offset to put in 7 days of week
        const dayIndex = Math.max(
          0,
          labels.findIndex(e => e === x)
        );
        const prefix = Array(dayIndex);
        const suffix = Array(DAYS_IN_WEEK - (dayIndex + 1));
        const data = {
          y,
          x
        };
        return [
          ...prev,
          {
            backgroundColor: tl.color,
            data: [...prefix, data, ...suffix]
          }
        ];
      }, []);

      return { datasets, labels };
    }
  },
  watch: {
    timelogs: {
      immediate: true,
      handler(val) {
        this.chartTimelogs = val;
      }
    }
  },
  methods: {
    onBarClick(e) {
      const timelog = this.timelogs[e.bar];
      this.$emit('click', {
        timelog,
        view: e.view
      });
    }
  }
};
</script>

<style></style>
