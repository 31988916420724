import Vue from 'vue';

interface PositionItem {
  id: number;
  position: number;
}

const MINIMUM_POSITION = 1e-6; // 0.000001;

const isDecimalMin = (position: number): boolean => {
  //    2.324234324324 % 1
  // -> 0.3242343243239998
  const pureDecimal = position % 1;
  return pureDecimal !== 0 && pureDecimal < MINIMUM_POSITION;
};
const isPositionExisted = <T extends PositionItem>(
  tasks: T[],
  position: number
): boolean => {
  return tasks.findIndex(task => task.position === position) > -1;
};

export default Vue.extend({
  methods: {
    shouldRecalculatePostion<T extends PositionItem>(
      position: number,
      tasks: T[] = []
    ): boolean {
      return isDecimalMin(position) || isPositionExisted(tasks, position);
    },
    getNewOrderItems<T extends PositionItem>(items: T[]): T[] {
      return items.map((item, i) => ({ ...item, position: 2 ** i }));
    },
    getPositionSortedItems<T extends PositionItem>(items: T[]): T[] {
      return [...items].sort(({ position: a }, { position: b }) => a - b);
    },
    recalculateItemsPosition<T extends PositionItem>(
      items: T[],
      { position, id }: PositionItem
    ): T[] {
      const newItems = items.map(e => (e.id === id ? { ...e, position } : e));
      return this.getNewOrderItems(this.getPositionSortedItems(newItems));
    }
  }
});
