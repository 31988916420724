<template>
  <div>
    <div class="p-4 space-y-4">
      <div>
        <p>{{ $t('subscription.payment.payVia') }}</p>
        <div class="gap-x-4 flex my-3">
          <base-icon>
            <icon-master-card />
          </base-icon>
          <base-icon> <icon-visa /></base-icon>
        </div>
      </div>
      <div class="text-sm">
        <p class="mb--row__title">
          {{ $t('subscription.payment.card.number') }}
        </p>

        <base-input-text-only-number
          v-model="cardNo"
          placeholder="•••• •••• •••• ••••"
          type="text"
          class="base-input placeholder-gray-600"
          maxlength="19"
          pattern="[0-9]*"
          inputmode="numeric"
        />
        <small v-if="errorCard != ''" class="text-red-danger">{{
          errorCard
        }}</small>
      </div>
      <div class="text-sm">
        <p class="mb--row__title">
          {{ $t('subscription.payment.card.holder') }}
        </p>
        <base-input-text
          v-model="cardName"
          :placeholder="$t('subscription.payment.card.holder')"
          type="text"
        />
      </div>

      <div class="flex">
        <div class="text-sm mr-4">
          <p class="mb--row__title">
            {{ $t('subscription.payment.card.expireDate') }}
          </p>
          <div>
            <input
              v-model="cardExpiry"
              v-cardformat:formatCardExpiry
              class="base-input"
              maxlength="5"
            />
          </div>
        </div>
        <div class="text-sm">
          <p class="mb--row__title">CVV</p>
          <base-input-text-only-number
            v-model="cardCode"
            placeholder="CVV"
            maxlength="3"
            style="width: 118px;"
          />
        </div>
      </div>

      <div v-if="isAddCard" class="flex">
        <base-button
          color="transparent"
          class="ml-auto"
          @click="
            $modal.hide('card-form');
            $emit('cancel');
          "
        >
          {{ $t('subscription.payment.cancel') }}
        </base-button>
        <base-button :disabled="!disabledAddCard" @click="addCard()">
          {{ $t('subscription.payment.addCard') }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script src="http://code.jquery.com/jquery-1.12.1.min.js"></script>
<script src="https://cdn.omise.co/omise.js"></script>

<script>
import IconMasterCard from '@/components/icons/IconMasterCard.vue';
import IconVisa from '@/components/icons/IconVisa.vue';
import Vue, { PropType } from 'vue';
import apis from '@/services/apis';
import modalSubscriptionConfirm from '@/components/modal/modal-subscription-confirm';
import debounce from 'lodash/debounce';
import CardType from '@/mixins/CardType.ts';

export default Vue.extend({
  mixins: [CardType],
  components: {
    IconMasterCard,
    IconVisa,
    modalSubscriptionConfirm
  },
  props: {
    isAddCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cardName: '',
      cardNo: '',
      expiration_month: '',
      expiration_year: '',
      cardCode: '',
      cardId: '',
      realNumber: '',
      cardExpiry: null,
      errorCard: ''
    };
  },
  computed: {
    disabledAddCard() {
      const isEmpty = Value => Value != '';
      const cardNotuse = ['JCB', undefined].includes(
        this.creditCardType(this.realNumber)
      );
      if (this.realNumber.length == 16) {
        this.errorCard = !cardNotuse ? '' : 'บัตรนี้ไม่สามารถใช้งานได้';
      } else {
        this.errorCard = '';
      }

      const isDisabled =
        [
          this.cardName,
          this.cardNo,
          this.expiration_month,
          this.expiration_year,
          this.cardCode
        ].every(isEmpty) && !cardNotuse;
      return isDisabled;
    }
  },
  watch: {
    cardNo() {
      this.realNumber = this.cardNo.replace(/ /gi, '');
      let dashedNumber = this.realNumber.match(/.{1,4}/g);
      this.cardNo = dashedNumber.join(' ');
      //check card type icon
      // this.realNumber.length == 16 ? this.creditCardType(this.realNumber) : '';
    },
    disabledAddCard(val) {
      this.$emit('emptyCard', val);
    },
    cardExpiry(val) {
      this.cardExpiry =
        val.length > 7 ? this.cardExpiry.slice(0, -1) : this.cardExpiry;
      this.expiration_month = this.cardExpiry.slice(0, 2);
      this.expiration_year = this.cardExpiry.slice(-2);
    }
  },
  mounted() {
    const isProd = location.hostname === 'www.workkami.com';
    const omiseKey = isProd
      ? 'skey_test_5qpeycpj7grk8n3t87l'
      : 'pkey_test_5qpeycpiymhxyg049hx';
    Omise.setPublicKey(omiseKey);
  },
  methods: {
    addCard: debounce(async function() {
      const card = {
        name: this.cardName,
        number: this.realNumber,
        expiration_month: parseInt(this.expiration_month),
        expiration_year: parseInt(this.expiration_year),
        security_code: this.cardCode
      };
      const self = this;
      await Omise.createToken('card', card, function(statusCode, response) {
        if (statusCode == 200) {
          self.$store.dispatch('subscription/saveCards', response.id);
          self.$modal.hide('card-form');
          self.$modal.show(modalSubscriptionConfirm, {
            title: 'เพิ่มการชำระเงินสำเร็จ',
            description:
              'การเพิ่มเงินสำเร็จแล้ว สามารถตั้งเป็นค่าเริ่มต้นได้หรือลบทีหลังได้เช่นกัน',
            animation:
              'https://assets3.lottiefiles.com/packages/lf20_uemoq57r.json'
          });
        } else {
          self.$modal.hide('card-form');
          self.$modal.show(
            modalSubscriptionConfirm,
            {
              title: 'เพิ่มการชำระเงินไม่สำเร็จ',
              description:
                'กรุณาตรวจสอบรายละเอียดการชำระเงิน<br /> หรือตรวจสอบบัญชีให้ถูกต้อง',
              icon: 'IconAddCardFail'
            },
            {
              width: 330,
              height: 330
            }
          );
        }
      });
    })
  }
});
</script>

<style scoped>
.mb--row__title {
  margin-bottom: 5px;
}
</style>
