<template>
  <div
    class="editor input-editor rounded"
    :class="{ 'hover:shadow border': !disabled }"
  >
    <EditorMenuBar
      v-if="menu"
      ref="editor"
      v-slot="{ commands, isActive }"
      :editor="editor"
      class="px-4"
    >
      <div v-if="uploadImage">
        <input
          ref="browseFiles"
          class="hidden"
          type="file"
          @change="browseFile($event, commands.image)"
        />
      </div>
      <div v-else class="bg-gray-600 flex items-center">
        <base-button
          v-if="isActive.bold"
          v-bind="getBtnAttrs(isActive.bold())"
          @mousedown.native.prevent="commands.bold"
        >
          <fa-icon icon="bold" />
        </base-button>
        <base-button
          v-if="isActive.italic"
          v-bind="getBtnAttrs(isActive.italic())"
          @mousedown.native.prevent="commands.italic"
        >
          <fa-icon icon="italic" />
        </base-button>
        <base-button
          v-if="isActive.strike"
          v-bind="getBtnAttrs(isActive.strike())"
          @mousedown.native.prevent="commands.strike"
        >
          <fa-icon icon="strikethrough" />
        </base-button>
        <base-button
          v-if="isActive.bullet_list"
          v-bind="getBtnAttrs(isActive.bullet_list())"
          text="Bullet List"
          @mousedown.native.prevent="commands.bullet_list"
        >
          <fa-icon icon="list-ul" />
        </base-button>
        <base-button
          v-if="isActive.ordered_list"
          v-bind="getBtnAttrs(isActive.ordered_list())"
          text="Ordered List"
          @mousedown.native.prevent="commands.ordered_list"
        >
          <fa-icon icon="list-ol" />
        </base-button>
        <base-button
          v-bind="getBtnAttrs()"
          @mousedown.native.prevent="commands.undo"
        >
          <fa-icon icon="undo" />
        </base-button>
        <base-button
          v-bind="getBtnAttrs()"
          @mousedown.native.prevent="commands.redo"
        >
          <fa-icon icon="redo" />
        </base-button>
        <base-dropdown v-model="textSize" class="inline-block ml-2">
          <base-button
            ref="headingDrop"
            slot="toggle"
            v-bind="getBtnAttrs()"
            icon-size="16"
            @mousedown.native.prevent
          >
            <div class="flex items-center">
              <span class="text-sm pr-2">{{
                getTextStyleActiveLabel(isActive)
              }}</span>
              <fa-icon icon="chevron-down" />
            </div>
          </base-button>
          <base-dropdown-item
            v-for="textStyle in textStyles"
            :key="textStyle.value"
            :value="textStyle.value"
            aria-role="menuitem"
            class="py-0"
            @mousedown.native.prevent
            @click="textStyle.command(commands)"
          >
            <div :class="textStyle.value.toLowerCase()">
              {{ textStyle.label }}
            </div>
          </base-dropdown-item>
        </base-dropdown>
      </div>
    </EditorMenuBar>

    <base-dropdown
      ref="qDropdown"
      width="full"
      max-height="340"
      position="bottom-right"
      :disabled="!filteredUsers.length"
    >
      <EditorContent
        slot="toggle"
        ref="editor"
        class="editor__content rounded"
        :editor="editor"
        :class="[
          focused ? 'border-gray-500' : 'cursor-text border-gray-300',
          {
            'border-t-0 rounded-t-none': menu && !mentions,
            'border-2 p-4': !disabled
          }
        ]"
        :style="{ border: isBorder ? '' : 'none' }"
      />
      <div v-if="filteredUsers.length">
        <base-dropdown-item
          v-for="(user, i) in filteredUsers"
          :key="`memtion-${i}`"
          @click="selectUser(user)"
        >
          <info-user
            class="pr-5 mention-icon-magin"
            align="text-left"
            :user="user"
            :with-email="true"
          />
        </base-dropdown-item>
      </div>
    </base-dropdown>

    <div v-if="isLoading" class="dark-overlay">
      <div class="lds-ripple">
        <div />
        <div />
      </div>
    </div>
    <notifications
      group="files-comment"
      class="mt-2"
      :duration="3000"
      classes="vue-notification mr-2 text-base shadow"
    />
  </div>
</template>

<script>
import InfoUser from '@/components/info/info-user.vue';

import { mapGetters, mapActions } from 'vuex';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  CodeBlock,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Code,
  Strike,
  Underline,
  History,
  Placeholder,
  HardBreak,
  Mention,
  Image
} from 'tiptap-extensions';
import { directive as onClickaway } from 'vue-clickaway';
import Link from '@/tiptap-extensions/CustomLink';
import { filesApi } from '@/services/apis';

import TextToLinkMixin from '@/mixins/TextToLink';

const getTextStyle = (value, label, command) => ({ value, label, command });
const headingLevels = 6;
const getHeadingCommand = ({ commands, level }) => commands.heading({ level });
const getHeadingStyles = () =>
  [...Array(headingLevels)]
    .map((_, i) => i + 1)
    .map(level =>
      getTextStyle(`H${level}`, `Heading ${level}`, commands =>
        getHeadingCommand({ commands, level })
      )
    );

const filterNotIncluded = (arr, query) => {
  return arr.filter(function(el) {
    return query.toLowerCase().indexOf(el.toLowerCase()) === -1;
  });
};

// https://newbedev.com/find-difference-between-two-strings-in-javascript
const getDifference = (a, b) => {
  let i = 0;
  let j = 0;
  let result = '';

  while (j < b.length) {
    if (a[i] != b[j] || i == a.length) result += b[j];
    else i++;
    j++;
  }
  return result;
};
export default {
  components: { EditorContent, EditorMenuBar, InfoUser },
  directives: { onClickaway },
  mixins: [TextToLinkMixin],
  props: {
    disabled: Boolean,
    uploadImage: {
      type: Boolean,
      default: false
    },
    mentions: Boolean,
    menu: Boolean,
    autoFocus: Boolean,
    content: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Write something ...'
    },
    extensions: {
      type: Array
    },
    isBorder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      editor: null,
      textSize: 'Normal',
      focused: false,
      filteredUsers: [],
      query: '',
      suggestionRange: null,
      isLoading: false,
      anchorPosition: '',
      videoTag: [],
      videoUrl: [],
      isPasteFile: false
    };
  },
  computed: {
    ...mapGetters({
      getMembers: 'members/getMembers'
    }),

    users() {
      this.fetchMembers();
      return this.getMembers.filter(e => e.status > 1 && !e.isCurrentUser);
    },
    textStyles: () => [
      getTextStyle('Normal', 'Normal Text', commands => commands.paragraph()),
      ...getHeadingStyles()
    ],
    btnWide: () => 'px-3 py-2'
  },
  mounted() {
    const self = this;
    const content = this.parseTextToLink(this.content);
    const isMention = this.content.includes('</span><span');
    if (isMention) {
      this.content = this.content.replaceAll('</span><span', '</span> <span');
    }
    const defaultExtensions = [
      new Blockquote(),
      new CodeBlock(),
      new BulletList(),
      new OrderedList(),
      new ListItem(),
      new Bold(),
      new Code(),
      new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
      new HardBreak(),
      new Strike(),
      new Underline(),
      new History()
      // new Image()
    ];
    const mentionConfig = {
      items: () => this.users,
      onEnter: ({ items, query, range, command }) => {
        this.query = query;
        this.filteredUsers = items;
        this.suggestionRange = range;
        // save the command for inserting a selected mention
        this.insertMention = command;
      },
      onChange: ({ items, query, range }) => {
        this.query = query;
        this.filteredUsers = items;
        this.suggestionRange = range;
      },
      onExit: () => {
        this.$refs.qDropdown.hide();
        this.query = '';
        this.filteredUsers = [];
        this.suggestionRange = null;
      },
      onFilter: (items, query) => {
        this.$refs.qDropdown.show();
        if (!query) {
          return items;
        }
        return items.filter(e =>
          [e.user.name, e.user.email].some(value =>
            new RegExp(query, 'i').test(value)
          )
        );
      }
    };
    /*
      TODO: move Link to extensions
      Waiting tiptap v2 for better typescript integration
      when add extensions from parent, it will stop asking for declaration .d.ts
     */
    const requiredExtension = [
      new Link(),
      new Placeholder({
        emptyEditorClass: 'is-editor--empty',
        emptyNodeClass: 'is-node--empty',
        emptyNodeText: this.placeholder,
        showOnlyWhenEditable: true,
        showOnlyCurrent: true
      })
    ];
    if (this.mentions) {
      requiredExtension.push(new Mention(mentionConfig));
      this.fetchMembers();
    }
    const extensions = [
      ...requiredExtension,
      ...(this.extensions || defaultExtensions)
    ];
    this.editor = new Editor({
      content,
      extensions,
      editable: !this.disabled,
      autoFocus: this.autoFocus,
      onUpdate: this.onUpdate,
      onFocus() {
        self.focused = !self.disabled && true;
        self.$emit('focus', self.editor);
      },
      onBlur: this.blur,
      onTransaction: ({ state }) => {
        this.anchorPosition = state.selection.anchor;
      },
      onPaste: this.paste,
      onInit: () => {
        const element = this.$refs.editor.$el;
        element.addEventListener('copy', this.onCopy);
        this.$on('hook:beforeDestroy', () => {
          element.removeEventListener('copy', this.onCopy);
        });
      }
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  watch: {
    content(newVal, oldVal) {
      if (!this.editor.focused) {
        let content = this.parseTextToLink(newVal);
        const isMention = newVal.includes('</span><span');
        if (isMention) {
          content = newVal.replaceAll('</span><span', '</span> <span');
        }
        this.editor.setContent(content);
      }

      const urlNotIncluded = filterNotIncluded(this.videoUrl, newVal);
      if (urlNotIncluded[0] != undefined) {
        const indexRemove = this.videoUrl.indexOf(urlNotIncluded[0]);
        this.videoTag.splice(indexRemove, 1);
        this.videoUrl.splice(indexRemove, 1);
      }

      if (this.isPasteFile) {
        const replaceOldContent = oldVal
          .replaceAll('<p>', '')
          .replaceAll('</p>', '');
        const replaceNewContent = newVal
          .replaceAll('<p>', '')
          .replaceAll('</p>', '');
        const paste = getDifference(replaceOldContent, replaceNewContent);
        const replaceFormatContent = this.content.replaceAll(paste, '');
        this.editor.setContent(replaceFormatContent);
        this.isPasteFile = false;
        this.onUpdate(this.editor);
      }
    }
  },
  methods: {
    ...mapActions('members', ['fetchMembers']),
    selectUser(user) {
      const id = user.user?.id || user.id;
      const name = user.user?.name || user.user?.email;
      // function of mention libary
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id,
          label: ' ' + name
        }
      });

      this.editor.focus();
    },

    getTextStyleActiveLabel(isActive) {
      if (isActive.paragraph()) return this.textStyles[0].label;
      return this.textStyles.find((_, i) => isActive.heading({ level: i }))
        ?.label;
    },
    blur(ev) {
      if (this.focused) {
        this.focused = false;
        this.$emit('blur', ev);
      }
    },
    paste(view, event) {
      let hasFiles = false;
      const reader = new FileReader();

      Array.from(event.clipboardData.files)
        .filter(item => item.type.startsWith('image'))
        .forEach(item => {
          reader.readAsDataURL(item);
          hasFiles = true;
        });
      if (hasFiles) {
        this.isPasteFile = true;
        const files = event.clipboardData.files;
        const { editor } = this.$refs.editor;
        this.browseFile(files, editor.commands.image);
      }
    },
    onUpdate(e) {
      const validateEmpty = content => {
        const isEmpty = /^(<p><\/p>)+$/;
        return isEmpty.test(content) ? '' : content;
      };
      const content = validateEmpty(e.getHTML());
      this.$emit('update:content', content);
    },
    getBtnAttrs(isActive) {
      return {
        color: this.focused
          ? isActive
            ? 'dark'
            : 'gray-outlined'
          : 'gray-outlined',
        borderless: true,
        rounded: null,
        shadowless: true,
        wide: 'px-3 py-2'
      };
    },
    selectFiles(files) {
      const { editor } = this.$refs.editor;
      this.browseFile(files, editor.commands.image);
    },
    async browseFile(eventOrFiles, command) {
      const isEvent = !(eventOrFiles instanceof FileList);
      const files = isEvent ? eventOrFiles.target.files : eventOrFiles;
      // this.isLoading = true;

      const group = 'files-comment';
      try {
        this.$emit('files', files);
      } catch (error) {
        this.$notify({
          text:
            error.response?.data.message ||
            this.$t('notifications.popup.wrong'),
          type: 'error',
          group
        });
        this.isLoading = false;
      }
      if (isEvent) eventOrFiles.target.value = '';
    },
    clickImg() {
      this.$refs.browseFiles.click();
    },
    onCopy(event) {
      const selection = document.getSelection();
      const trimRegExp = /\n+/g;
      const text = selection.toString();
      const trimText = text.replace(trimRegExp, '\n');
      event.clipboardData.setData('text/plain', trimText);
      event.preventDefault();
    }
  }
};
</script>

<style lang="scss">
.editor p.is-editor--empty:first-child::before {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity));
  content: attr(data-empty-text);
  float: left;
  pointer-events: none;
  height: 0;
}
.ProseMirror {
  img {
    max-width: 300px;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }
}
.mention {
  @apply text-black font-bold px-1;
  // display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mention-icon-magin .has-centered-item {
  @apply mr-2;
}

.ProseMirror-separator {
  display: initial;
}

.ProseMirror img {
  cursor: pointer;
}

.ProseMirror img.ProseMirror-selectednode {
  outline: none;
}
</style>
