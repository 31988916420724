export enum PlanStatus {
  Idle,
  Active,
  Cancel
}

export interface PaymentCard {
  card_no: string;
  expiration_month: number;
  expiration_year: number;
  name: string;
  security_code: string;
}

export interface SubscriptionPlan {
  cardToken?: string;
  numberOfSeats: number;
  planID: number;
}

export interface SaveCard {
  token_id: string;
}

export interface CardList {
  bank_name: string;
  brand: string;
  card_id: string;
  card_no: string;
  financing: string;
  is_default_card: boolean;
}
export interface CurrentPlan {
  payment: any;
  totalSeats: number;
  usedSeats: number;
}

export interface PaymentHistory {
  cardBrand: string;
  cardNo: string;
  chargeID: string;
  createdAt: string;
  endAt: string;
  id: number;
  nextPaymentAt: string;
  startAt: string;
  status: number;
  subscriptionOrder: any;
  subscriptionOrderID: number;
  updatedAt: string;
  userID: number;
}

export interface PlanSelected {
  key: number;
  planName: string;
  planDescription: string;
  price: string;
  priceDescription: string;
  planId: number;
}

export interface EmailInvite {
  emails: string[];
  returnType: number;
}

export interface Dashboard {
  endAt: string;
  startAt: string;
  teamID: number;
}

export interface PlanPreview {
  Taxes: number;
  createdAt: string;
  id: number;
  name: string;
  numberOfDays: number;
  numberOfSeats: number;
  planID: number;
  pricePerSeat: number;
  status: number;
  subTotal: number;
  total: number;
  updatedAt: string;
  userID: number;
}

export interface FeedbackCancelPlan {
  subscriptionOrderID: number;
  usabilityRating: number;
  willComeBackToSub: boolean;
}
