import { Module } from 'vuex';
import firebase, { getCollections } from '@/services/firebase';
import { NotificationState, RootState } from '@/types/Store';
import { NotificationItem } from '@/types/Notification';
import { notificationApis } from '@/services/notifications-apis';
import apis from '@/services/apis';
import { User } from '@/types/User';
import { Unsubscribe } from 'firebase';
import { PageInformation } from '@/types/Api';

const notificationModule: Module<NotificationState, RootState> = {
  namespaced: true,
  state: {
    entities: [],
    pageInformation: {
      count: 0,
      page: 1,
      lastPage: 1
    },
    unreads: 0,
    firestoreListener: () => {
      return;
    },
    isShowSearch: false
  },
  getters: {
    getNotifications(s): NotificationItem[] {
      return s.entities;
    },
    getPageInformation(s): PageInformation {
      return s.pageInformation;
    },
    getUnreads(s): number {
      return s.unreads;
    },
    getIsShowSearch(s): boolean {
      return s.isShowSearch;
    }
  },
  mutations: {
    SET_NOTIFICATIONS_ENTITIES(s, entities: NotificationItem[]) {
      s.entities = entities;
    },
    SET_NOTIFICATIONS_PAGINATION(s, pageInformation: PageInformation) {
      s.pageInformation = pageInformation;
    },
    SET_NOTIFICATIONS_UNREADS(s, payload = 0) {
      s.unreads = payload;
    },
    SET_NOTI_READ(s, id: number) {
      const mapRead = (e: NotificationItem): NotificationItem =>
        e.id === id ? { ...e, unread: false } : e;
      s.entities = s.entities.map(mapRead);
    },
    RESET_UNREADS(s) {
      s.entities = s.entities.map(entity => {
        entity.unread = false;
        return entity;
      });
    },
    SET_UNREADS_LISTENER(s, payload) {
      s.firestoreListener = payload;
    },
    SET_IS_SHOW_SEARCH(s, payload) {
      s.isShowSearch = payload;
    }
  },
  actions: {
    async fetchNotifications(s, params) {
      const isReset = s.state.pageInformation.page > (params?.page || 0);
      async function fetchData() {
        const { data } = await notificationApis.fetchNotifications(params);
        s.commit('SET_NOTIFICATIONS_ENTITIES', [
          ...s.state.entities,
          ...data.entities
        ]);
        s.commit('SET_NOTIFICATIONS_PAGINATION', data.pageInformation);
      }

      if (isReset) s.commit('SET_NOTIFICATIONS_ENTITIES', []);
      await fetchData();

      // clear unread
      await apis.post(`/notifications/clearBadge`);
      s.commit('SET_NOTIFICATIONS_UNREADS');
    },
    async fetchNotificationsUnreads(s) {
      const user = s.rootGetters['auth/getUser'] as User;
      const getListener = (): Unsubscribe =>
        firebase
          .firestore()
          .collection(getCollections().users)
          .doc(user.id.toString())
          .onSnapshot(snap => {
            const unreads = snap.get('number_of_unread_notifications');
            s.commit('SET_NOTIFICATIONS_UNREADS', unreads);
          });

      s.commit('SET_UNREADS_LISTENER', getListener());
    },
    async setRead(s, id: number) {
      await notificationApis.setRead(id);
      s.commit('SET_NOTI_READ', id);
    },
    async clearNotifications(s) {
      await notificationApis.clearNotifications();
    },
    async readAll({ commit }) {
      await notificationApis.readAll();
      commit('RESET_UNREADS');
    },
    stopListenFirestore(s) {
      s.state.firestoreListener();
    }
  }
};

export default notificationModule;
