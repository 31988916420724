















































































































































































































import Vue, { PropType } from 'vue';
import { TaskFile } from '@/types/Task';
import { FileType } from '@/types/File';
import moment from 'moment';
import BaseIcon from '../base/base-icon.vue';
import IconPreviewDownload from '@/components/icons/PreviewDownload.vue';
import IconPreviewDelete from '@/components/icons/PreviewDelete.vue';
import IconPreviewSizing from '@/components/icons/PreviewSizing.vue';
import IconPreviewSizeSmall from '@/components/icons/PreviewSizeSmall.vue';
import IconPreviewSizeBig from '@/components/icons/PreviewSizeBig.vue';
import IconPreviewLeft from '@/components/icons/PreviewLeft.vue';
import IconPreviewRight from '@/components/icons/PreviewRight.vue';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import { directive as onClickaway } from 'vue-clickaway';

interface UploadFile {
  (file: File): void;
}

import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('tasks');

interface WithUrlFile extends TaskFile {
  url: string;
  commentID: number;
}

export default Vue.extend({
  components: {
    BaseIcon,
    IconPreviewDownload,
    IconPreviewDelete,
    IconPreviewSizing,
    IconPreviewSizeSmall,
    IconPreviewSizeBig,
    IconPreviewLeft,
    IconPreviewRight,
    DialogConfirm
  },
  props: {
    files: {
      type: Array as PropType<Array<WithUrlFile>>,
      default: () => []
    },
    f: {
      type: Object as PropType<WithUrlFile>
    },
    id: {
      type: String
    },
    taskId: Number
  },
  watch: {
    f() {
      this.file = this.f;
      this.getMeta((this.file as WithUrlFile).url);
    }
  },
  directives: { onClickaway },
  computed: {
    index() {
      let index = 0;
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].id == (this.file as WithUrlFile).id) index = i;
      }
      return index;
    }
  },
  data() {
    return {
      active: false,
      file: {},
      uploading: false,
      zoom: 100,
      width: 0
    };
  },

  methods: {
    ...mapActions([
      'addAttachment',
      'removeAttachmentInComment',
      'removeAttachment',
      'fetchTaskComments'
    ]),
    isVideo: ({ file }: WithUrlFile): boolean => {
      return file.typeUpload === FileType.Video;
    },
    fileName: ({ file }: WithUrlFile): string => {
      return file.fileName;
    },
    fileSize: ({ file }: WithUrlFile): string => {
      if (!file.fileSize) return '0 Bytes';
      const bytes = file.fileSize;
      const DECIMALS = 2;
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = DECIMALS < 0 ? 0 : DECIMALS;
      const sizes = ['Bytes', 'KB', 'MB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    formatUploadedAt({ createdAt }: WithUrlFile) {
      const mDate = moment(createdAt).locale(
        `${this.$t('notifications.language')}`
      );
      const isPassOneDay =
        mDate.diff(
          moment().locale(`${this.$t('notifications.language')}`),
          'days'
        ) > 1;
      if (isPassOneDay) return mDate.format('MMM DD, YYYY');
      return mDate.fromNow();
    },
    fileTypeTitle: (e?: WithUrlFile): string => {
      if (!e) return '';
      const { typeUpload } = e.file;
      return FileType[typeUpload].toLowerCase();
    },
    isFileImage(file: File) {
      return file.type.includes('image');
    },
    getMeta(url: string) {
      const self = this;
      const img = new Image();
      img.addEventListener('load', function() {
        if (this.naturalWidth < 900 && this.naturalHeight < 600)
          self.width = this.naturalWidth;
        else {
          const rw = this.naturalWidth / 900;
          const rh = this.naturalHeight / 600;
          self.width =
            rw > rh ? this.naturalWidth / rw : this.naturalWidth / rh;
        }
      });
      img.addEventListener('error', function(err) {
        self.width = 600;
      });
      img.src = url;
    },
    hide() {
      this.$modal.hide(`view-file-${this.id}`);
    },
    promptDelete(file: WithUrlFile) {
      this.$modal.show(`confirm-delete-${file.id}`);
    },
    confirmClose(file: WithUrlFile) {
      this.$modal.hide(`confirm-delete-${file.id}`);
    },

    confirmRemove() {
      const { commentID } = this.f;
      const removeAttachmentInComment = async (file: WithUrlFile) => {
        const params = {
          commentID,
          fileID: file.fileID
        };
        await this.removeAttachmentInComment(params);

        // fetch comments
        const shareLinkCode = this.$route.params.linkId;
        this.fetchTaskComments({ taskID: this.taskId, shareLinkCode });
      };
      const removeAttachment = async (file: WithUrlFile) => {
        await this.removeAttachment(file.fileID);
      };

      if (this.file && commentID) {
        removeAttachmentInComment(this.file as WithUrlFile);
      } else {
        removeAttachment(this.file as WithUrlFile);
      }

      this.$modal.hide(`view-file-${this.id}`);
    },

    fileDownload: ({ fileID }: WithUrlFile): string => `/api/v1/file/${fileID}`,

    async downloadAttachment(file: WithUrlFile) {
      if (!file.url) return;

      let interval = 0;
      const million = Math.pow(10, 6);
      const fileSize = file.file.fileSize;
      const forceStop = () => {
        clearInterval(interval);
        this.uploading = false;
      };
      const loadingLargeFile = () => {
        let working = true;
        let sec = 0;
        const sparedTime = (fileSize / million) * 2; // 1Mb = 1s
        const uploading = () => {
          this.uploading = true;
          working = false;
        };

        const handleUploading = () => {
          if (working) uploading();
          else if (sec > sparedTime) forceStop();
          else sec += 1;
        };

        interval = setInterval(handleUploading, 500);
      };

      if (fileSize > million) loadingLargeFile();

      const res = await fetch(this.fileDownload(file)).then(e => e.blob());
      forceStop();
      const a = this.$refs.downloadA as HTMLAnchorElement;
      const url = URL.createObjectURL(res);
      const fileName = file.file.fileName || 'Download';
      a.href = url;
      a.download = fileName;

      //  that releases the object URL after the element has been clicked
      // This is required for one-off downloads of the blob content
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };

      // Add the click event listener on the anchor element
      // Comment out this line if you don't want a one-off download of the blob content
      a.addEventListener('click', clickHandler, false);

      a.click();
    },
    hidePreview(e: PointerEvent) {
      if ((e.target as Element).classList.contains('preview-space')) {
        this.$modal.hide(`view-file-${this.id}`);
      }
    },

    gotoLeftRight(left: boolean) {
      let index = 0;
      if (left) {
        if (this.index < 1) index = this.files.length - 1;
        else index = this.index - 1;
      } else {
        if (this.index >= this.files.length - 1) index = 0;
        else index = this.index + 1;
      }
      this.file = this.files[index];
    }
  }
});
