<template>
  <div class="time-log mb-20">
    <div class="time-log__top -mx-5">
      <div class="px-5 md:mx-auto" style="max-width: 400px;">
        <h2 class="text-3xl mb-5">{{ $t('timelog.title') }}</h2>
        <div>
          <timelog-chart-today />
        </div>
      </div>
      <tab-time-log-starter class="px-5 mt-3 w-full" />
    </div>
    <tab-time-logged :active.sync="viewActive" />
    <div>
      <table-time-log v-if="viewActive === 0" />
      <timelog-chart-tabs v-if="viewActive === 1" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ChartBarStacked from '@/mixins/ChartBarStacked';
import TabTimeLogStarter from '@/components/tab/tab-time-log-starter';
import TabTimeLogged from '@/components/tab/tab-time-logged';
import TableTimeLog from '@/components/table/table-time-log';
import TimelogChartToday from '@/components/timelog/timelog-chart-today';
import TimelogChartTabs from '@/components/timelog/timelog-chart-tabs';

const { mapActions } = createNamespacedHelpers('time-log');

export default {
  mixins: [ChartBarStacked],
  components: {
    TabTimeLogged,
    TableTimeLog,
    TabTimeLogStarter,
    TimelogChartToday,
    TimelogChartTabs
  },
  data() {
    return {
      loading: true,
      viewActive: 0
    };
  },
  mounted() {
    this.fetchTodayTimelogs();
  },
  methods: {
    ...mapActions(['fetchTodayTimelogs'])
  }
};
</script>

<style scoped>
.time-log {
  @apply flex-1 h-full px-8 py-4;
}
.time-log__top {
  @apply mb-5 grid -mx-4;
  grid-template-columns: 400px minmax(0, 1fr);
}

@media (max-width: 1024px) {
  .time-log__top {
    @apply grid-cols-1;
  }
}
</style>
