import apis from './apis';
import { ShareLinkTask, Task, TaskAdd, TaskCopyRequest } from '@/types/Task';
import { GenericRestApiFactory, AxiosApis } from '@/types/Api';
import { AxiosPromise } from 'axios';

type PromiseTask = AxiosPromise<Task>;

interface DataIds {
  boardID: number;
  taskID: number;
  tagID: number;
}

interface TaskApis extends AxiosApis<Task, TaskAdd> {
  removeAttachment(id: number, fileID: number): PromiseTask;
  removeAttachmentInComment(
    taskId: number,
    commentId: number,
    fileID: number
  ): AxiosPromise;
  addTag(data: DataIds): AxiosPromise;
  removeTag(data: DataIds): AxiosPromise;
  getTaskBySharelinkId(id: string | number): AxiosPromise<ShareLinkTask>;
  copyTask(request: TaskCopyRequest): PromiseTask;
  readTask(id: number): AxiosPromise;
}

export default function (restFactory: GenericRestApiFactory): TaskApis {
  return {
    ...restFactory<Task, TaskAdd>('tasks'),
    removeAttachment(id, fileID) {
      return apis.delete(`/tasks/${id}/file/${fileID}`);
    },
    removeAttachmentInComment(id, commentId, fileID) {
      return apis.delete(`/tasks/${id}/comments/${commentId}/file/${fileID}`);
    },
    addTag(data) {
      const { boardID, taskID, tagID } = data;
      return apis.post(`/tags/${tagID}/board/${boardID}/task/${taskID}`);
    },
    removeTag(data) {
      const { boardID, taskID, tagID } = data;
      return apis.delete(`/tags/${tagID}/board/${boardID}/task/${taskID}`);
    },
    getTaskBySharelinkId(sharelinkId) {
      return apis.get(`/tasks/sharelink/${sharelinkId}`);
    },
    copyTask({ id, ...copying }) {
      return apis.post(`/tasks/${id}/copy`, copying);
    },
    readTask(id) {
      return apis.post(`/tasks/${id}/read`);
    }
  };
}
