import apis from './apis';
import {
  Board,
  BoardAdd,
  BoardsResponse,
  BoardInvitationRequest,
  BoardKickMemberRequest,
  BoardMemberSetRoleRequest,
  MyTasks,
  BoardUpdate,
  BoardMember,
  TaskLinkUpdate,
  BoardCopyRequest
} from '@/types/Board';
import {
  CommandResponse,
  ApiPagination,
  GenericRestApiFactory,
  ApiPaginationParams,
  ApiLevel
} from '@/types/Api';
import { User } from '@/types/User';
import { TagAdd, Tag, TagEdit } from '@/types/Tag';
import { AxiosRequestConfig } from 'axios';

const apisBoards = (restFactory: GenericRestApiFactory) => ({
  ...restFactory<Board, BoardAdd, BoardUpdate>('boards'),
  index: (config?: AxiosRequestConfig) =>
    apis.get<BoardsResponse>('/me/boards', config),
  archiveBoard: (id: number) =>
    apis.post<CommandResponse>(`/boards/${id}/archive`),
  restoreArchivedBoard: (id: number) =>
    apis.post<CommandResponse>(`/boards/${id}/restore`),
  unarchivedBoard: (id: number) =>
    apis.delete<CommandResponse>(`/boards/${id}/archive`),
  fetchMembers: (
    boardId: number,
    params?: ApiPaginationParams & { includePendingMember?: boolean }
  ) => apis.get<ApiPagination<User>>(`/boards/${boardId}/members`, { params }),
  inviteMember: ({ id, ...invitation }: BoardInvitationRequest) =>
    apis.post<CommandResponse | BoardMember[]>(
      `/boards/${id}/invite`,
      invitation
    ),
  kickMember: ({ id, memberID }: BoardKickMemberRequest) =>
    apis.delete<CommandResponse>(`/boards/${id}/leave`, { data: { memberID } }),
  setMemberRole: ({ id, ...req }: BoardMemberSetRoleRequest) =>
    apis.post<CommandResponse>(`/boards/${id}/permission`, req),
  trash: (params: ApiPaginationParams) =>
    apis.get<BoardsResponse>('/boards/trash', { params: params }),
  tasks: ({ q, filterType }: MyTasks) =>
    apis.get('/me/tasks', { params: { q, filterType } }),
  archiveTask: (id: number) =>
    apis.post<CommandResponse>(`/tasks/${id}/archive`),
  logs: (boardId: number, params: ApiPaginationParams) =>
    apis.get(`/boards/${boardId}/activityLogs`, { params: params }),
  search: ({ q }: MyTasks) => apis.get('/search/global', { params: { q } }),
  archives: (params?: ApiPaginationParams) =>
    apis.get<BoardsResponse>(`/archive/boards`, { params: params }),
  tags: (params?: { boardID: number; page: number; q?: string }) =>
    apis.get<ApiPagination<Tag>>('/tags', { params }),
  createTag: (newTag: TagAdd) => apis.post<Tag>('/tags', newTag),
  setTag: ({ id, boardID, ...tagEdit }: TagEdit) =>
    apis.patch<Tag>(`/tags/${id}/board/${boardID}`, tagEdit),
  removTag: (id: number, boardID: number) =>
    apis.delete(`/tags/${id}/board/${boardID}`),
  searchLinks: (taskId: number, params: ApiPaginationParams) =>
    apis.get(`/tasks/${taskId}/link/tasks`, { params: params }),
  linkTask: (id: number, form: TaskLinkUpdate) =>
    apis.post<CommandResponse>(`/tasks/${id}/link`, form),
  linkTaskRemove: (id: number, linkId: number) =>
    apis.delete<CommandResponse>(`/tasks/${id}/link/${linkId}`),
  archivedItems: (id: number, params: ApiPaginationParams) =>
    apis.get(`/boards/${id}/archiveTasks`, { params: params }),
  archivedColumns: (id: number, params: ApiPaginationParams) =>
    apis.get(`/boards/${id}/archiveColumns`, { params: params }),
  unarchivedColumn: (boardID: number, id: number) =>
    apis.delete(`/boards/${boardID}/columns/${id}/archive`),
  unarchivedItem: (id: number) => apis.delete(`/tasks/${id}/archive`),
  tasksByBoard: (boardID: number, config: AxiosRequestConfig) =>
    apis.get(`/boards/${boardID}/tasks`, config),
  joinBoard: (id: number) => apis.post<CommandResponse>(`/boards/${id}/join`),
  createLevel: (create: ApiLevel) => apis.post(`/levels`, create),
  fetchLevel: (params: ApiLevel) => apis.get(`/levels`, { params: params }),
  deleteLevel: (levelID: string) => apis.delete(`/levels/${levelID}`),
  editLevel: (levelID: string, params: ApiLevel) =>
    apis.patch(`/levels/${levelID}`, params),
  copyBoard({ id, ...name }: BoardCopyRequest) {
    return apis.post(`/boards/${id}/copy`, name);
  }
});

export default apisBoards;
