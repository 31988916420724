<template>
  <div>
    <div v-if="optionsOnly">
      <div class="ml-3 my-1 title">
        {{ $t('modelTask.priority.task') }}
      </div>
      <base-dropdown-item
        v-for="option in options"
        :key="option.key"
        class="flex dropdown-box"
        :class="isActiveClass(option.value)"
        @click="input(option.value)"
      >
        <div class="mr-3 has-centered-item">
          <span class="tag" :class="option.css">
            {{ option.text }}
          </span>
        </div>
        <span class=" capitalize "
          >{{ option.key }} {{ $t('modelTask.priority.priority') }}</span
        >
      </base-dropdown-item>
    </div>
    <span v-if="readonly" class="tag" :class="selectedOption.css">
      {{ selectedOption.text }}
    </span>
    <base-dropdown
      v-if="!optionsOnly && !readonly"
      :disabled="disabled"
      width="small"
      :style="`cursor: ${disabled ? 'default' : 'pointer'};`"
    >
      <div slot="toggle">
        <div class="flex items-center">
          <span class="tag" :class="selectedOption.css">
            {{ selectedOption.text }}
          </span>
          <span v-if="label" class="ml-2 capitalize">{{
            selectedOption.key
          }}</span>
        </div>
      </div>
      <div class="ml-3 my-1 title">
        {{ $t('modelTask.priority.task') }}
      </div>
      <base-dropdown-item
        v-for="option in options"
        :key="option.key"
        class="flex dropdown-box "
        :class="isActiveClass(option.value)"
        @click="input(option.value)"
      >
        <div class="mr-3 has-centered-item">
          <span class="tag" :class="option.css">
            {{ option.text }}
          </span>
        </div>
        <span class=" capitalize "
          >{{ option.key }} {{ $t('modelTask.priority.priority') }}</span
        >
      </base-dropdown-item>
    </base-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 2
    },
    disabled: Boolean,
    readonly: Boolean,
    label: {
      type: Boolean,
      default: true
    },
    optionsOnly: Boolean
  },
  data() {
    return {
      options: [
        {
          key: `${this.$t('modelTask.priority.hight')}`,
          value: 3,
          text: 'H',
          css: 'tag--high'
        },
        {
          key: `${this.$t('modelTask.priority.medium')}`,
          value: 2,
          text: 'M',
          css: 'tag--medium'
        },
        {
          key: `${this.$t('modelTask.priority.low')}`,
          value: 1,
          text: 'L',
          css: 'tag--low'
        }
      ]
    };
  },
  computed: {
    selectedOption() {
      if (this.readonly && !this.value) return {};
      return this.options.find(o => o.value === this.value);
    }
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
    isActive(value) {
      return this.value === value;
    },
    isActiveClass(value) {
      return this.isActive(value) ? 'priority-active' : '';
    }
  }
};
</script>

<style scoped>
.priority-active {
  background: #dfdfdf !important;
}
.dropdown-box {
  font-size: 13px;
}
.title {
  font-weight: 500;
  font-size: 13px;
}
.tag {
  @apply font-semibold;
}
.tag--low {
  background-color: #bee3ff;
}
.tag--medium {
  @apply text-white;
  background-color: #168ae2;
}
.tag--high {
  @apply text-white;
  background-color: #10588e;
}
</style>
