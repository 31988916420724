import { Task } from '@/types/Task';
import { Location, Route } from 'vue-router';
import { baseTaskRouteNames } from './constants';

export const getTaskRouter = (
  route: Route,
  { board, id, sprintID, doneAt, currentMember }: Task
): Location => {
  const { locale } = route.params;
  let name = baseTaskRouteNames[board.type];
  const params = {
    locale,
    id: board.id.toString(),
    task_id: id.toString(),
    sprint_id: ''
  };

  if (doneAt && currentMember.role != 0) {
    name = 'Board-Completed-Sprint-Task';
    params.sprint_id = sprintID.toString();
  }
  return {
    name,
    params
  };
};
