import apis, { restFactory } from './apis';
import { AxiosApis, CommandResponse } from '@/types/Api';
import { Sprint, SprintCreate, SprintStartParams } from '@/types/Sprint';
import { AxiosPromise } from 'axios';

interface SprintApis extends AxiosApis<Sprint, SprintCreate> {
  setStart(id: number, e: SprintStartParams): AxiosPromise<CommandResponse>;
  getComplete(e: { boardID: number; q: string }): AxiosPromise<Sprint[]>;
  setComplete(e: {
    id: number;
    targetSprintID: number;
  }): AxiosPromise<CommandResponse>;
}

const apisSprints: SprintApis = {
  ...restFactory('sprints'),
  setStart(sprintId, startEnd) {
    return apis.patch(`/sprints/${sprintId}/start`, startEnd);
  },
  getComplete(params) {
    return apis.get(`/sprints/complete`, { params });
  },
  setComplete({ id, targetSprintID }) {
    return apis.patch(`/sprints/${id}/complete`, { targetSprintID });
  }
};

export default apisSprints;
