<template>
  <v-draggable
    v-model="dragFolders"
    ghost-class="drag--ghost"
    drag-class="drag--dragging"
    chosen-class="drag--chosen"
    filter=".folder--drag-ignored"
    direction="vertical"
    v-bind="draggableConfig"
    :delay="80"
    :delay-on-touch-only="false"
    :move="onMove"
    :scroll-sensitivity="64"
    :scroll-speed="20"
    @choose="collapseFolder"
    @end="dragEnd"
    @change="dragChange"
  >
    <div
      v-for="folder in dragFolders"
      :key="folder.id"
      class="folder-list__item flex px-4"
    >
      <div class="pt-5">
        <fa-icon class="cursor-move text-gray-700" icon="grip-vertical" />
      </div>

      <base-collapse
        class="w-full flex-shrink-0"
        :collapsed="isFolderCollapsed(folder)"
        @toggle="collapsedFolders[folder.id] = $event"
      >
        <template #collapser="{ isCollapsed, toggle }">
          <collapse-toggle
            :active="isCollapsed"
            class="list-item__collapser"
            @click="toggle"
          >
            <div class="flex-1 flex items-center">
              <div
                class="inline-flex overflow-hidden items-center"
                style="max-width: 90%;"
              >
                <base-icon
                  class="flex-shrink-0"
                  name="folder"
                  size="40"
                  :color="folder.color"
                >
                  <folder-icon />
                </base-icon>
                <div class="font-medium truncate" style="padding-left:1px">
                  {{ folder.name }}
                </div>
                <div class="text-gray-500 text-sm ml-2 flex-shrink-0">
                  {{ folder.boards.length }} {{ $t('dashboard.folder.board') }}
                </div>
              </div>
              <base-dropdown
                v-if="!folder.isNoFolder"
                class="ml-1 collapse--ignore folder-toggle text-base"
                width="small"
                position="bottom-right"
              >
                <base-button
                  slot="toggle"
                  color="text"
                  class="hover:bg-light-gray"
                  fa-icon="ellipsis-h"
                />

                <base-dropdown-item
                  @click="$modal.show('form-folder', { folder })"
                >
                  {{ $t('dashboard.folder.edit') }}
                </base-dropdown-item>
                <base-dropdown-item
                  danger
                  @click="$emit('item-delete', folder)"
                >
                  {{ $t('dashboard.folder.delete') }}
                </base-dropdown-item>
              </base-dropdown>
            </div>
          </collapse-toggle>
        </template>
        <board-list
          class="folder--drag-ignored"
          draggable
          :frozen="boardDragging"
          add-on
          :boards="folder.boards"
          :folder="folder"
          @dragstart="toggleBoardDragging"
          @dragend="toggleBoardDragging"
        />
      </base-collapse>
    </div>
  </v-draggable>
</template>

<script>
import VDraggable from 'vuedraggable';
import DraggableMixin from '@/mixins/Vuedraggable';
import CollapseToggle from '@/components/collapse/collapse-toggle';
import BoardList from '@/components/board/board-list';
import FolderIcon from '@/components/icons/Folder';

export default {
  mixins: [DraggableMixin],
  components: {
    VDraggable,
    CollapseToggle,
    BoardList,
    FolderIcon
  },
  props: {
    folders: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      collapsedFolders: {},
      draggedFolderId: -1,
      boardDragging: false
    };
  },
  computed: {
    dragFolders: {
      get() {
        return this.folders;
      },
      set(folders) {
        this.$emit('update:folders', folders);
      }
    }
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    toggleBoardDragging() {
      this.boardDragging = !this.boardDragging;
    },
    collapseFolder(ev) {
      const targetFolder = this.folders[ev.oldIndex];
      this.draggedFolderId = targetFolder.id;
    },
    dragEnd() {
      this.draggedFolderId = -1;
    },
    isFolderCollapsed(folder) {
      return (
        this.draggedFolderId === folder.id || this.collapsedFolders[folder.id]
      );
    },
    scrollIntoView(ev) {
      const { item } = ev;
      setTimeout(() => {
        const { offsetTop } = item;
        const { scrollY, innerHeight } = window;
        const isBetweenScroll =
          scrollY < offsetTop && offsetTop < scrollY + innerHeight;
        if (!isBetweenScroll)
          window.scrollTo({
            top: offsetTop - 150,
            behavior: 'smooth'
          });
      }, 200);
    },
    dragChange(ev) {
      const context = ev.moved;
      if (!context) return;
      const newIdx = context.newIndex;
      const folders = this.dragFolders;
      const getPosition = folder => folder?.position || 0;
      const prevFolder = folders[newIdx - 1];
      const nextFolder = folders[newIdx + 1];
      const prevPos = getPosition(prevFolder);
      const nextPos = getPosition(nextFolder) || prevPos * 3;
      const position = (prevPos + nextPos) / 2;

      const folderChanges = { position, id: folders[newIdx].id };
      this.$emit('item-change', folderChanges);
    },
    onMove(ev) {
      this.scrollIntoView({ item: ev.dragged });
    }
  }
};
</script>

<style>
.folder-toggle {
  z-index: 1 !important;
}
</style>
