<template>
  <div>
    <!-- add -->
    <div class="mt-3 mb-6">
      <base-input-text
        v-if="!isUserViewer"
        ref="linkTaskInput"
        v-model="q"
        data-testid="text-field-add-link-search"
        :loading="false"
        icon-fa="search"
        :placeholder="$t('modelTask.card.link.search')"
        type="text"
        class=""
        @keyup="qChange"
        @click="inputClick"
      />

      <base-dropdown
        ref="qDropdown"
        :position="qDropdownPosition"
        class="link-task-dropdown"
        width="full"
        max-height="360"
      >
        <div slot="toggle" />
        <div v-if="searchLinks.length">
          <base-dropdown-item
            v-for="(link, i) in searchLinks"
            :key="`search-link-${i}`"
            :hoverable="true"
            class="py-2 px-2"
            @click="addTaskLink(link)"
          >
            <div class="flex items-center">
              <div class="flex-1">
                <div class="flex justify-between">
                  <div class="text-sm">
                    <v-clamp :max-lines="2" autoresize>
                      {{ link.name }}
                    </v-clamp>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full mt-2 flex space-x-2">
              <!-- priority -->
              <modal-task-link-priority :priority="link.priority" />
              <modal-task-link-column :column="link.boardColumn" />
            </div>
          </base-dropdown-item>
        </div>
        <div v-else class="text-center py-8 px-6 text-gray-500">
          {{ $t('modelTask.card.link.not_found') }}
        </div>
      </base-dropdown>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers, mapGetters } from 'vuex';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { boardsApi } from '@/services/apis';

import IconHeaderAdd from '@/components/icon-header-add.vue';
import IconClose from '@/components/icons/IconClose';
import { Avatar } from '../base/base-avatar.vue';
import { Task, TaskAssign, TaskFile, TaskPrivacy } from '@/types/Task';
import {
  BoardColumn,
  BoardColumnAdd,
  Board,
  TaskLink,
  TaskLinkUpdate
} from '@/types/Board';
import VClamp from 'vue-clamp';
import ModalTaskLinkPriority from './modal-task-link-priority.vue';
import ModalTaskLinkColumn from './modal-task-link-column.vue';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('tasks');

export default {
  name: 'modal-task-link-dropdown',
  components: {
    VClamp,
    ModalTaskLinkPriority,
    ModalTaskLinkColumn
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      q: '',
      searchLinks: [],
      filter: { q: '' },
      qDropdownPosition: 'bottom-right',
      toRemove: 0,
      selectId: 0
    };
  },
  watch: {
    selectId(value) {
      this.$emit('checkTask', value);
    }
  },
  computed: {
    ...mapState(['currentTask']),
    ...mapGetters({
      isUser: 'auth/isUser',
      loading: 'tasks/isTaskLoading',
      isUserViewer: 'boards/isUserViewer',
      columns: 'board-column/getColumns'
    }),
    task() {
      return this.currentTask;
    },

    taskAssigns() {
      return this.task.assigns || [];
    },
    links() {
      return this.task.linkedTasks;
    }
  },
  methods: {
    ...mapMutations(['UPDATE_CURRENT_TASK']),
    ...mapActions([
      'setTask',
      'addTaskAssign',
      'removeTaskAssign',
      'readTask',
      'subscribeTask',
      'unsubscribeTask'
    ]),
    focus() {
      this.$refs.linkTaskInput.focus();
    },

    qChange: debounce(function(e) {
      if (e.key === 'Backspace') {
        this.selectId = 0;
      }
      if (!this.q) {
        this.$refs.qDropdown.hide();
        return;
      }
      this.filter = {
        q: this.q
      };

      boardsApi.searchLinks(this.task.id, this.filter).then(res => {
        this.searchLinks = res.data;
        this.showSearchDropdown(e);
      });
    }, 500),

    inputClick(e) {
      if (this.filter.q) {
        this.showSearchDropdown();
      }
    },
    showSearchDropdown(e) {
      if (
        e.target &&
        window.innerHeight / 2 < e.target.getBoundingClientRect().top
      )
        this.qDropdownPosition = 'top-right';
      else this.qDropdownPosition = 'bottom-right';

      this.$refs.qDropdown.show();
    },
    async addTaskLink(link) {
      if (this.selected) {
        this.q = link.name;
        this.selectId = link.id;
      } else {
        await boardsApi.linkTask(this.task.id, {
          toTaskID: link.id
        });
        this.q = '';
        this.$emit('update');
      }
    },
    async addTaskLinkClick() {
      const res = await boardsApi.linkTask(this.task.id, {
        toTaskID: this.selectId
      });

      this.q = '';
      this.$emit('update', res.data);
    },
    getUsersAvatar(list) {
      return list.map(e => e.user);
    }
  }
};
</script>

<style>
.link-task-dropdown .base-dropdown__content.on--top-right {
  transform: translateY(-40px);
  z-index: 100;
}
</style>
