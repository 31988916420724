<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 4">
    <circle cx="2" cy="2" r="2" />
    <circle cx="9" cy="2" r="2" />
    <circle cx="16" cy="2" r="2" />
  </svg>
</template>

<script>
export default {};
</script>
