<template>
  <base-select
    v-model="selected"
    borderless
    class="w-full"
    contentClass="w-full"
    toggle-color="brand"
    placeholder="Sprint"
    :options="options"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selected: 0
    };
  },
  computed: {
    ...mapGetters('sprint', ['getSprints']),
    options() {
      return this.getSprints.map(e => ({ label: e.name, value: e.id }));
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(payload) {
        if (this.selected !== payload) this.selected = payload;
      }
    },
    selected(payload) {
      this.$emit('input', payload);
    }
  }
};
</script>

<style></style>
