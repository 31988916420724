import Vue from 'vue';
import moment from 'moment-timezone';
// import moment from 'moment';

interface ListItem {
  createdAt: string;
}

interface GroupedItems {
  [keyDate: string]: ListItem[];
}

export const MINUTES_IN_HOUR = 60;

function get2Decimal(num = 0) {
  return parseFloat(num.toFixed(2));
}

export default Vue.extend({
  methods: {
    fromNow(date: Date, lang?: string) {
      if (lang) console.warn('fromNow(date, lang); lang was deprecated');
      return moment(date)
        .locale(`${this.$t('notifications.language')}`)
        .tz('Asia/Bangkok')
        .fromNow();
    },
    getDate(a: string) {
      const dateA = new Date(a);
      moment.locale(`${this.$t('notifications.language')}`);
      return (
        moment([dateA.getFullYear(), dateA.getMonth(), dateA.getDate()])
          .locale(`${this.$t('notifications.language')}`)
          .format('D MMM') + ` ${String(dateA.getFullYear() + 543).slice(2, 4)}`
      );
    },
    dayDiff(a: string) {
      const dateA = new Date(a);
      const dateB = new Date();

      const momentA = moment([
        dateA.getFullYear(),
        dateA.getMonth(),
        dateA.getDate()
      ]).locale(`${this.$t('notifications.language')}`);
      const momentB = moment([
        dateB.getFullYear(),
        dateB.getMonth(),
        dateB.getDate()
      ]).locale(`${this.$t('notifications.language')}`);
      return momentA.diff(momentB, 'days');
    },
    getHourFromMinutes(minutes = 0) {
      return get2Decimal(minutes / MINUTES_IN_HOUR);
    },
    formatHourMinuteFromMinutes(
      minutes = 0,
      units = { hour: 'h', minute: 'm' }
    ) {
      const { hour, minute } = units;
      const [hrString, decimalHrString] = this.getHourFromMinutes(minutes)
        .toFixed(2)
        .toString()
        .split('.');
      const decimalHour = parseInt(decimalHrString) / 100;
      const decimalHourInMinute = Math.round(decimalHour * MINUTES_IN_HOUR);
      return `${hrString}${hour} ${decimalHourInMinute}${minute}`;
    },
    getRemainingMinutes(minutes = 0) {
      return minutes % MINUTES_IN_HOUR;
    },
    groupItemByCreatedAt<T extends ListItem>(initList: T[]): GroupedItems {
      const groupedItems = initList.reduce((list, item) => {
        const mCreatedAt = moment(item.createdAt).locale(
          `${this.$t('notifications.language')}`
        );
        const dayKey = mCreatedAt
          .startOf('day')
          .valueOf()
          .toString();
        if (!list[dayKey]) list[dayKey] = [];
        const ref = list[dayKey];
        ref.push(item);
        return list;
      }, {} as GroupedItems);
      return Object.keys(groupedItems)
        .sort((a, b) => b.localeCompare(a))
        .reduce(
          (list, key) => ({ ...list, [key]: groupedItems[key] }),
          {} as GroupedItems
        );
    },
    getCalendarDate(groupKey: string) {
      const mDate = moment(parseInt(groupKey, 10)).locale(
        `${this.$t('notifications.language')}`
      );
      return mDate.calendar({
        sameDay: `${this.$t('notifications.date.today')}`,
        lastDay: `${this.$t('notifications.date.lastday')}`,
        lastWeek: `${this.$t('notifications.date.lastweek')}`,
        sameElse: `${this.$t('notifications.date.sameElse')}`
      });
    },
    getDateFormat(date: string, format?: string) {
      return moment(date).format(format);
    }
  }
});
