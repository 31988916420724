<template>
  <component
    :is="baseContainer"
    v-bind="scrollerAttrs"
    class="v-scroller"
    @scroll="scroll"
  >
    <slot />
    <component :is="loadingContainer" v-if="loading">
      <slot name="loading">
        <div class="px-5 py-2 text-center">
          <base-spinner color="primary" />
        </div>
      </slot>
    </component>
  </component>
</template>

<script>
import { throttle } from 'lodash';
export default {
  props: {
    loading: Boolean,
    height: {
      type: String,
      default: 'auto'
    },
    isList: Boolean
  },
  computed: {
    baseContainer() {
      return this.isList ? 'ul' : 'div';
    },
    loadingContainer() {
      return this.isList ? 'li' : 'div';
    },
    scrollerAttrs() {
      return {
        style: {
          height: this.height
        }
      };
    }
  },
  methods: {
    scroll: throttle(function(ev) {
      this.$emit('scroll', ev);
      this.bottom(ev);
    }, 200),
    bottom(ev) {
      const target = ev.target;
      const { scrollTop, clientHeight, scrollHeight } = target;
      const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      if (isBottom) {
        ev.preventDefault();
        this.$emit('bottom', ev);
      }
    }
  }
};
</script>

<style scoped>
.v-scroller {
  @apply overflow-hidden overflow-y-auto;
}
</style>
