<template>
  <div
    v-observe-visibility="{
      callback: setVisible,
      ...$attrs
    }"
  >
    <slot :visible="visible" />
  </div>
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility';

export default {
  inheritAttrs: false,
  directives: { ObserveVisibility },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    setVisible(visible) {
      this.visible = visible;
    }
  }
};
</script>

<style></style>
