<template>
  <div class="base-input--control" :class="{ 'base-input--focused': focused }">
    <input
      ref="textInput"
      v-bind="$attrs"
      :class="
        `base-input placeholder-gray-600 ${
          hasIcon && iconPosition === 'left' ? 'input--has-icon' : ''
        } ${innerClass}`
      "
      :disabled="disabled"
      :type="type"
      v-on="inputListeners"
      @focus="focused = true"
      @blur="focused = false"
    />

    <span
      v-if="icon || iconFa"
      :class="[
        iconPosition === 'left'
          ? 'base-input__icon-left '
          : 'base-input__icon-right'
      ]"
    >
      <div class="has-centered-item h-full" :class="iconClass">
        <fa-icon v-if="iconFa" :icon="iconFa" size="xs" />
        <base-icon v-else size="18">
          <component :is="Icon" />
        </base-icon>
      </div>
    </span>
    <span
      v-if="iconRight"
      class="base-input__icon-right"
      :class="{ 'cursor-pointer': iconRightClick }"
      @click="$emit('icon-right-click', $event)"
    >
      <div class="has-centered-item h-full" :class="iconClass">
        <base-icon size="18">
          <component :is="IconRight" />
        </base-icon>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'email',
          'number',
          'password',
          'search',
          'tel',
          'text',
          'url'
        ].includes(value);
      }
    },
    autofocus: Boolean,
    disabled: Boolean,
    icon: {
      type: String,
      default: ''
    },
    iconFa: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    iconRight: {
      type: String,
      default: ''
    },
    innerClass: {
      type: String,
      default: ''
    },
    iconClass: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    Icon() {
      return () => import(`@/components/icons/${this.icon}.vue`);
    },
    inputListeners() {
      const self = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function(event) {
            self.$emit('update', event.target.value);
          }
        }
      );
    },
    hasIcon() {
      return this.icon || this.iconFa;
    },
    textInput() {
      return this.$refs.textInput;
    },
    IconRight() {
      return () => import(`@/components/icons/${this.iconRight}.vue`);
    },
    iconRightClick() {
      return this.$listeners['icon-right-click'];
    }
  },
  mounted() {
    if (this.autofocus) {
      this.focus();
    }
  },
  methods: {
    blur() {
      this.textInput.blur();
    },
    focus() {
      this.textInput.focus();
    }
  }
};
</script>
