<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size || width"
    :height="size || height"
    :view-box="viewBox"
    :aria-labelledby="name"
    class="base-icon"
    role="presentation"
    :class="{
      'base-icon--no-margin': !marginNext
    }"
  >
    <title :id="name" lang="en" />
    <g :fill="color">
      <slot>
        <component :is="Icon" />
      </slot>
    </g>
  </svg>
</template>

<script>
const capitalName = str => `${str[0].toUpperCase()}${str.slice(1)}`;
const replaceDash = str =>
  str.replace(/-([a-z])/, (match, p1) => p1.toUpperCase());

export default {
  props: {
    name: {
      type: String,
      default: 'box'
    },
    icon: {
      type: String
    },
    marginNext: {
      default: true
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    size: {
      type: [Number, String],
      default: undefined
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  },
  data() {
    const Icon = this.icon
      ? () =>
          import(
            `@/components/icons/Icon${replaceDash(capitalName(this.icon))}`
          )
      : null;
    return {
      Icon
    };
  },
  computed: {
    viewBox() {
      const x = this.size || this.width;
      const y = this.size || this.height;
      return `0 0 ${x} ${y}`.replace(/px/, '');
    }
  }
};
</script>

<style>
.base-icon {
  @apply inline-block;
}
.base-icon:not(.base-icon--no-margin) + * {
  @apply ml-2;
}
</style>
