





























import Vue, { PropType } from 'vue';
import SelectColors from '@/components/select/select-colors.vue';

export enum ColorOption {
  Yellow = '#F7CE15',
  Lightning = '#F89A28',
  Orange = '#EF602C',
  Red = '#EF4130',
  Rose = '#ED407B',
  Mulberry = '#C1559B',
  Tanos = '#704A9B',
  Navy = '#27549E',
  Blue = '#0675BB',
  BlueSky = '#1EB0E6',
  Mantis = '#86C548',
  Teal = '#33B7B6',
  Jungle = '#27AD7D',
  Green = '#40B346',
  Pear = '#C5D92F'
}

export default Vue.extend({
  components: {
    SelectColors
  },
  props: {
    value: {
      type: String as PropType<ColorOption>
    },
    size: {
      type: String
    },
    padding: {
      type: String,
      default: 'p-2'
    }
  },
  computed: {
    colorOptions(): ColorOption[] {
      return Object.values(ColorOption);
    },
    colorValue: {
      get(): ColorOption {
        return this.value;
      },
      set(selectColor: ColorOption) {
        this.$emit('input', selectColor);
      }
    },
    sizes() {
      let background = 40;
      let check = '1x';
      switch (this.size) {
        case 'small':
          background = 20;
          check = 'xs';
          break;

        default:
          break;
      }
      return {
        background: `${background}px`,
        check
      };
    }
  }
});
