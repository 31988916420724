import Vue from 'vue';

export default Vue.extend({
  methods: {
    creditCardType(card: string) {
      const amex = new RegExp('^3[47][0-9]{13}$');
      const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
      const cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
      const cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

      const mastercard = new RegExp('^5[1-5][0-9]{14}$');
      const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

      const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
      const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
      const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

      const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
      const jcb = new RegExp('^35[0-9]{14}[0-9]*$');

      if (visa.test(card)) {
        return 'VISA';
      }
      if (amex.test(card)) {
        return 'AMEX';
      }
      if (mastercard.test(card) || mastercard2.test(card)) {
        return 'MASTERCARD';
      }
      if (disco1.test(card) || disco2.test(card) || disco3.test(card)) {
        return 'DISCOVER';
      }
      if (diners.test(card)) {
        return 'DINERS';
      }
      if (jcb.test(card)) {
        return 'JCB';
      }
      if (cup1.test(card) || cup2.test(card)) {
        return 'CHINA_UNION_PAY';
      }
      return undefined;
    }
  }
});
