<template>
  <div
    class="w-screen h-screen bg-contain bg-no-repeat bg-center"
    :style="{
      'background-image': `url(${image})`,
      'background-color': '#ecfbfe'
    }"
  >
    <!-- <img src="@/assets/image/404.jpg" alt="Not found page" /> -->
    <base-button color="transparent" borderless class="m-4" @click="goBack">
      <div class="text-brand-blue">
        <fa-icon icon="arrow-left" />
        <span class="ml-2">Go Back</span>
      </div>
    </base-button>
  </div>
</template>

<script>
import apis from '@/services/apis';
export default {
  data() {
    return {
      image: require('@/assets/image/404.jpg')
    };
  },
  computed: {
    getUserId() {
      return this.$store.getters['auth/getUser'].id;
    }
  },
  async mounted() {
    let page_url = this.$route.path;
    try {
      const { data } = await apis.get('/me');
      page_url += `?user_id=${data.id}`;
    } catch (err) {
      console.error(err);
    }
    this.$fana.logEvent('page_not_found', {
      page_url
    });
  },
  methods: {
    goBack() {
      return this.$router.back();
    }
  }
};
</script>
