<template>
  <div class="user-info__box">
    <base-icon v-if="isNotfound" :height="108" :width="339">
      <icon-total-time />
    </base-icon>

    <div v-else class="flex flex-wrap">
      <div>
        <chart-doughnut
          :chart-data="planUnplanChartData"
          :options="planUnplanChartOptions"
          :styles="{ height: '120px', width: '120px' }"
        />
      </div>
      <div class="flex-1 pl-4" style="min-width: 260px;">
        <div class="font-medium">
          {{ $t('admin.totaltimelog') }}
        </div>
        <plan-progress-bar :plan-unplan="planUnplan" />
        <plan-unplan-legend class="mt-3" />
      </div>
    </div>
  </div>
</template>

<script>
import ChartDoughnut from '@/components/chart/chart-doughnut';
import DateTime from '@/mixins/DateTimeMixin';
import PlanProgressBar from './components/PlanProgressBar';
import PlanUnplanLegend from './components/PlanUnplanLegend';
import IconTotalTime from '@/components/icons/IconTotalTime.vue';

const PLAN_COLOR = '#168AE2';
const UNPLAN_COLOR = '#A2AEC2';

export default {
  props: {
    value: {
      type: Object
    }
  },
  mixins: [DateTime],
  components: {
    ChartDoughnut,
    PlanProgressBar,
    PlanUnplanLegend,
    IconTotalTime
  },
  computed: {
    isNotfound() {
      const { totalPlanInMinutes, totalUnPlanInMinutes } = this.value;
      return !totalPlanInMinutes && !totalUnPlanInMinutes;
    },
    planUnplan() {
      const { totalPlanInMinutes, totalUnPlanInMinutes } = this.value;
      return {
        plan: totalPlanInMinutes,
        unplan: totalUnPlanInMinutes
      };
    },
    orderedPlanUnplan() {
      const {
        totalPlanInMinutes,
        totalUnPlanInMinutes,
        totalPlanInPercent,
        totalUnPlanInPercent
      } = this.value;

      const getDisplayTime = ({ value, ...planUnplan }) => {
        return Object.assign(planUnplan, {
          displayTime: this.formatHourMinuteFromMinutes(value),
          value
        });
      };
      return [
        {
          label: this.$t('timelog.plan.planned'),
          value: totalPlanInMinutes,
          color: PLAN_COLOR,
          percentage: totalPlanInPercent,
          displayTime: ''
        },
        {
          label: this.$t('timelog.plan.unplanned'),
          value: totalUnPlanInMinutes,
          color: UNPLAN_COLOR,
          percentage: totalUnPlanInPercent,
          displayTime: ''
        }
      ].map(getDisplayTime);
    },
    planUnplanChartData() {
      const labels = this.orderedPlanUnplan.map(e => e.label);
      const data = this.orderedPlanUnplan.map(e => e.value);
      const dataset = {
        data,
        backgroundColor: [PLAN_COLOR, UNPLAN_COLOR]
      };
      return {
        datasets: [dataset],
        labels
      };
    },
    planUnplanChartOptions() {
      return {
        legend: {
          display: false
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        tooltips: {
          displayColors: false,
          enabled: false,
          callbacks: {
            label: e =>
              e.index
                ? this.$t('timelog.plan.unplanned')
                : this.$t('timelog.plan.planned'),
            labelColor() {
              return {
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
              };
            },
            afterLabel: e => {
              const { displayTime } = this.orderedPlanUnplan[e.index];
              return displayTime;
            }
          },
          custom(tooltipModel) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table class=""></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }
            function getAfterBody(bodyItem) {
              return bodyItem.after;
            }

            // Set Text
            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              const bodyLines = tooltipModel.body.map(getBody);
              const afterBodyLines = tooltipModel.body.map(getAfterBody);

              let innerHtml = '<thead>';

              titleLines.forEach(function(title) {
                innerHtml += '<tr><th>' + title + '</th></tr>';
              });
              innerHtml += '</thead><tbody>';

              bodyLines.forEach(function(body, i) {
                const colors = tooltipModel.labelColors[i];
                let style = 'background:' + colors.backgroundColor;
                style += '; border-color:' + colors.borderColor;
                const labelBox = `
                  <div
                      class="chart-doughnut__tooltip"
                      style="${style}"
                    >
                      <p class="font-medium">${body}</p>
                      <p>${afterBodyLines[i]}</p>
                  </div>
                `;
                innerHtml += '<tr><td>' + labelBox + '</td></tr>';
              });
              innerHtml += '</tbody>';

              const tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            const position = this._chart.canvas.getBoundingClientRect();
            const tooltipHeight = tooltipEl.offsetHeight;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top =
              position.top +
              window.pageYOffset +
              tooltipModel.caretY -
              tooltipHeight / 2 +
              'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding =
              tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        },
        cutoutPercentage: 70
      };
    }
  }
};
</script>

<style>
.chart-doughnut__tooltip {
  @apply p-2 shadow rounded-lg text-white relative;
}
.chart-doughnut__tooltip::before {
  @apply absolute;
  content: '';
  right: 100%;
  top: calc(50% - 0.5rem);
  border: 0.5rem solid transparent;
  border-right: 0.5rem solid rgba(0, 0, 0, 0.7);
}
</style>
