<template>
  <div class="input-password">
    <input
      v-bind="$attrs"
      :type="isShowPassword ? 'text' : 'password'"
      class="placeholder-gray-600 base-input"
      :class="innerClass"
      @input="$emit('update', $event.target.value)"
      v-on="$listeners"
    />
    <span class="show-password" @click="isShowPassword = !isShowPassword">{{
      isShowPassword ? $t('login.hide') : $t('login.show')
    }}</span>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  data() {
    return {
      isShowPassword: false
    };
  },
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['password'].includes(value);
      }
    },
    innerClass: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
input::-webkit-credentials-auto-fill-button {
  margin-right: 50px;
}
.input-password .show-password {
  z-index: 15;
  display: flex;
  place-items: center;
  bottom: 0;
  right: 0;
  padding-right: 1rem;
  transform: none;
}
</style>
