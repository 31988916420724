<script>
export default {
  beforeRouteEnter(to, _, next) {
    const addBoardsToPath = () => {
      const locale = to.params.locale || '';
      const localePath = `/${locale}`;
      const noLocalePath = locale
        ? to.fullPath.replace(localePath, '')
        : to.fullPath;
      const path = localePath + '/boards' + noLocalePath;
      next({
        path
      });
    };
    if (to.path.includes('/boards')) next();
    else addBoardsToPath();
  },
  render(h) {
    return h('div', this.$slots.default);
  }
};
</script>
