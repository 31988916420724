




















































































































































































































import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { TeamAdd, TeamType } from '@/types/Team';
import { Folder } from '@/types/Folder';
import { FileUpload } from '@/types/File';
import { BaseSelectOption } from '@/types/BaseComponents';
import LogoCreateTeam from '@/components/icons/LogoCreateTeam.vue';
import IconGallery from '@/components/icons/IconGallery.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';

import { filesApi } from '@/services/apis';

export default Vue.extend({
  props: {
    loading: Boolean,
    defaultFolder: {
      type: Object as PropType<Folder | undefined>
    }
  },

  components: { LogoCreateTeam, IconGallery, IconChevronDown },
  data() {
    return {
      formName: '',
      formType: 0,
      formDescription: '',
      formWebSite: '',
      formImageLogo: '',
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('folders', ['getFolders', 'getNoFolder']),
    noFolder(): Folder {
      return this.getNoFolder;
    },
    typeOptions(): BaseSelectOption[] {
      return this.types.map(e => {
        return {
          label: this.$t('lang') == 'en' ? e.valueEN : e.valueTh,
          value: e.type
        };
      });
    },
    types(): TeamType[] {
      return this.$store.getters['teams/getTypes'];
    },
    disabledNext(): boolean {
      return !this.formName;
    }
  },
  async mounted() {
    this.$store.dispatch('teams/fetchTypes');
  },
  watch: {},
  methods: {
    ...mapActions('folders', ['fetchFolders']),
    async createClick() {
      this.isLoading = true;
      const form: TeamAdd = {
        name: this.formName,
        type: this.formType,
        description: this.formDescription,
        webSite: this.formWebSite,
        imageLogo: this.formImageLogo
      };
      this.$store.dispatch('teams/createTeam', form);

      this.$modal.hide('create-team');
      this.isLoading = false;
    },

    async browsePhoto(files: FileList) {
      this.isLoading = true;
      if (files && files.length) {
        const { data } = await filesApi.upload('teams', files);
        if (data.length) {
          const img = data[0] as FileUpload;
          this.formImageLogo = img.thumbnailImageUrl as string;
        }
      }
      this.isLoading = false;
    },

    /*submit(createBoard: BoardAdd) {
      this.$emit('submit', createBoard);
    },*/
    close() {
      this.$modal.hide('create-team');
    }
  }
});
