<template>
  <base-popup
    multiline
    position="top"
    size="is--wide"
    :disabled="!headerClamped && !textClamped"
  >
    <v-clamp
      :max-lines="1"
      :max-height="26"
      autoresize
      @clampchange="textClamped = $event"
    >
      <template #before>
        <v-clamp
          class="text-clamp__header hover:underline"
          :max-lines="1"
          autoresize
          :max-height="22"
          @clampchange="headerClamped = $event"
        >
          <slot name="header" />
        </v-clamp>
      </template>
      <slot v-if="!headerClamped" />
      <template #after>
        <div v-if="$slots.edit" class="text-clamp__header tag ">
          <slot name="edit" />
        </div>
      </template>
    </v-clamp>
    <div slot="content" class="text-left">
      <strong><slot name="header"/></strong>
      <slot />
    </div>
  </base-popup>
</template>

<script>
import VClamp from 'vue-clamp';

export default {
  components: { VClamp },

  data() {
    return {
      textClamped: false,
      headerClamped: false
    };
  }
};
</script>

<style>
.text-clamp__header {
  @apply inline font-semibold align-middle;
}
</style>

<style scoped>
.tag {
  @apply border text-sm px-2 rounded border-brand-blue text-brand-blue ml-2;
}
</style>
