import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  methods: {
    formatShowDate(start: string, end: string) {
      if (!start || !end) {
        return '-';
      }
      const formatDateStart = (f: string) => moment(start).format(f);
      const formatDateEnd = (e: string) => moment(end).format(e);

      const monthStart = formatDateStart('MM');
      const monthEnd = formatDateEnd('MM');
      const yearStart = formatDateStart('YYYY');
      const yearEnd = formatDateEnd('YYYY');
      if (yearStart === yearEnd) {
        if (monthStart === monthEnd) {
          return `${formatDateStart('D')} - ${formatDateEnd('D MMM YYYY')}`;
        } else {
          return `${formatDateStart('D MMM')} - ${formatDateEnd('D MMM YYYY')}`;
        }
      } else {
        return `${formatDateStart('D MMM YYYY')} - ${formatDateEnd(
          'D MMM YYYY'
        )}`;
      }
    }
  }
});
