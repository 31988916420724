

































import Vue, { PropType } from 'vue';

enum ColorOption {
  LIME = '#FFC107',
  YELLOW = '#FFA000',
  ORANGE = '#FF7043',
  AMBER = '#FF7A7A',
  RED = '#F6403D',
  PINK = '#EC407A',
  PURPLE = '#AB47BC',
  DEEP_PURPLE = '#7E57C2',
  INDIGO = '#5C6BC0',
  BLUE = '#1073B9',
  LIGHT_BLUE = '#29B6F6',
  SCOOTER = '#26C6DA',
  CYAN = '#26A69A',
  TEAL = '#66BB6A',
  GREEN = '#9CCC65',
  LIGHT_GREEN = '#B6C339',
  BROWN = '#8D6E63',
  BLUE_GREY = '#28587B',
  OURET_SPACE = '#263238',
  GREY = '#BDBDBD'
}

enum ColorOptionFolder {
  red = '#E8384F',
  orange = '#F2994A',
  yellow = '#F2C94C',
  green = '#6FCF97',
  blue_sky = '#56C3F2',
  blue = '#2F80ED',
  blue_gray = '#28587B',
  purple = '#645DD7',
  pink = '#FF88DC',
  black = '#403A3D'
}
type ColorOptionsString = keyof typeof ColorOption;

const colors = Object.values(ColorOption);
const colorFolder = Object.values(ColorOptionFolder);

export { ColorOption, colors, colorFolder };

export default Vue.extend({
  props: {
    value: {
      type: String as PropType<ColorOption>,
      default: null
    },
    colors: {
      type: Array as PropType<ColorOption[]>
    },
    label: Boolean,
    isFolder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colorOptions(): ColorOption[] | ColorOptionFolder[] {
      const selectColor = this.colors || colors;
      return this.isFolder ? colorFolder : selectColor;
    }
  },
  methods: {
    select(color: ColorOption) {
      if (this.value !== color) this.$emit('input', color);
    }
  }
});
