










































































































import Vue from 'vue';
import { mapActions, mapMutations } from 'vuex';
import IconDotsHorizontal from '@/components/icons/IconDotsHorizontal.vue';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import DateTimeMixin from '@/mixins/DateTimeMixin';

import MyTaskTaskTime from '@/components/my-task/my-task-task-time.vue';

import { Task, TaskAssign } from '@/types/Task';
import WrapperObserveVisibility from '../wrapper/wrapper-observe-visibility.vue';

interface TaskUpdate {
  status?: number;
}

export default Vue.extend({
  components: { IconDotsHorizontal, MyTaskTaskTime, WrapperObserveVisibility },
  props: {
    task: {
      type: Object,
      validator(task) {
        return !!task.id;
      }
    },
    isStatic: {
      type: Boolean
    }
  },
  mixins: [DateTimeMixin],
  computed: {},
  data() {
    return {
      modalConfig: {
        height: 'auto',
        maxWidth: 420,
        adaptive: true
      }
    };
  },
  methods: {
    ...mapMutations({
      resetMembers: 'members/RESET_STATE',
      resetTags: 'tags/RESET_TAGS'
    }),
    ...mapActions({
      removeTask: 'tasks/removeTask',
      setTask: 'tasks/setTask',
      archiveTask: 'tasks/archiveTask',
      fetchTasks: 'tasks/fetchTasks'
    }),
    confirmDeleteTask(taskId: number) {
      const self = this;
      this.showModal({
        taskId,
        title: this.$t('alert.deleteTask.name'),
        description: this.$t('alert.deleteTask.title'),
        button: this.$t('alert.deleteTask.button'),
        color: 'text-red-600',
        confirmAction: 'tasks/removeTask',
        confirmData: taskId,
        confirmCallback: function() {
          self.$emit('changeTasks', true);
        }
      });
    },
    confirmArchiveTask(taskId: number) {
      const self = this;
      this.showModal({
        taskId,
        title: this.$t('alert.archivedTask.name'),
        description: this.$t('alert.archivedTask.title'),
        button: this.$t('alert.archivedTask.button'),
        confirmAction: 'tasks/archiveTask',
        confirmData: taskId,
        confirmCallback: function() {
          self.$emit('changeTasks', true);
        }
      });
    },
    showModal(data: object) {
      this.$modal.show(DialogConfirm, data, this.modalConfig);
    },
    updateTask(updates: TaskUpdate) {
      this.setTask({
        id: this.task.id,
        ...updates
      });
    },
    async toggleTaskComplete() {
      const status = this.task.status == 2 ? 1 : 2;
      await this.updateTask({
        status: status
      });

      this.task.status = status;
    },
    assignedUsers(task: Task) {
      const assigns = task.assigns || [];
      const getUser = (a: TaskAssign) => {
        return a.user;
      };
      return assigns.map(getUser);
    },
    async showModalTask() {
      const self = this;
      const { boardID: boardId, id: taskId, board: board } = self.task;
      board.type == 2
        ? await this.$store.dispatch('sprint/fetchSprints', boardId)
        : '';
      try {
        await this.$store.dispatch('boards/fetchBoard', boardId);
        await this.$store.dispatch('tasks/fetchTask', taskId);
        self.$modal.show('task-detail');
      } catch (error) {
        const MESSAGE_DEFAULT = `Click here to hide this message. If you cannot proceed, try to refresh.`;
        const message = error.toString() || MESSAGE_DEFAULT;
        self.$notify({
          title: `${this.$t('notifications.popup.service')}`,
          text: `<p class="text-base">${message}</p>`,
          type: 'error',
          group: 'task-error',
          duration: -1
        });
      }
    }
  }
});
