







































































































































import Vue, { PropType } from 'vue';
import VClamp from 'vue-clamp';
import VScroller from '@/components/scroller.vue';
import { User } from '@/types/User';

export interface Avatar {
  userAvatarPath?: string;
  id: number;
  email?: string;
  name?: string;
  profilePicture?: string;
  user?: unknown;
  userID?: number;
}

const NO_IMAGE =
  'https://storage.googleapis.com/workkami-files-dev/statics/f6dd2895acbe4cf4b6d2efa5f279855f_thumb.png';

export default Vue.extend({
  components: { VClamp, VScroller },
  props: {
    hoverable: {
      type: Boolean,
      default: true
    },
    avatar: {
      type: Object as PropType<Avatar>,
      default: undefined
    },
    listItemClass: {
      type: [Object, Array, String]
    },
    list: {
      type: Array as PropType<Avatar[]>,
      default: (): Avatar[] => []
    },
    listMax: {
      type: Number,
      default: 3
    },
    listTotal: {
      type: Number
    },
    listMaxHeight: {
      type: [String, Number],
      default: '100vh'
    },
    listMoreAll: Boolean,
    dropdownWidth: {
      type: String
    },
    size: {
      type: [String, Number]
    },
    sizeSmall: Boolean,
    sizeSmaller: Boolean,
    loading: Boolean,
    popup: Boolean,
    popupPlacement: {
      type: String,
      default: 'auto'
    },
    dropdownPosition: {
      type: String,
      default: 'auto-end'
    },
    selected: {
      type: [Array, Boolean] as PropType<number[]>,
      default: false
    },
    listAll: {}
  },
  computed: {
    visibleList(): Avatar[] {
      if (this.list.length > this.listMax) {
        return this.list.slice(0, this.listMax - 1);
      }
      return this.list.slice(0, this.listMax);
    },
    moreItemsList(): Avatar[] {
      return this.list.slice(this.listMoreAll ? 0 : this.listMax - 1);
    },
    moreItems(): number {
      return (this.listTotal || this.list.length) - this.visibleList.length;
    },
    isMoreSelected(): boolean {
      return this.moreItemsList.some(this.isSelected);
    },
    userInfo(): unknown {
      return this.avatar.user || this.avatar;
    },
    getPath(): string | undefined {
      // user coming from outside /members will access by userAvatarPath
      const getPath = () => {
        const user = this.avatar.user as User & {
          userAvatarPath: Avatar['userAvatarPath'];
        };
        const urls = [
          this.avatar.profilePicture,
          this.avatar.userAvatarPath,
          user?.profilePicture,
          user?.userAvatarPath,
          NO_IMAGE
        ];
        const imageUrl = urls.find(item => Boolean(item));
        return imageUrl;
      };
      return getPath() || '';
    },
    listMaxHeightPx(): string {
      return `${this.listMaxHeight}px`;
    },
    slotPopover(): boolean {
      return !!this.$slots.popover;
    }
  },
  methods: {
    isSelected(avatar: Avatar) {
      if (typeof this.selected === 'boolean') return this.selected;
      return this.selected.find(s => s === avatar.id) ? true : false;
    },
    onclick(item: Avatar) {
      this.$emit('click', item);
    },
    dropdown() {
      const toggle = this.$refs.tooltipToggle as HTMLElement;
      toggle.click();
    }
  }
});
