<template>
  <div
    class="base-popup"
    :class="[
      {
        'base-popup--disabled': disabled,
        'content--top': isTop
      },
      colorClass
    ]"
  >
    <slot />
    <div class="base-popup__content" :class="contentClasses">
      <slot name="content">{{ label }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    multiline: Boolean,
    position: {
      type: String,
      default: 'bottom-left'
    },
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'is--medium',
      validator(val = '') {
        return ['is--medium', 'is--short', 'is--wide'].includes(val);
      }
    },
    color: {
      type: String
    }
  },
  computed: {
    contentClasses() {
      const classes = [];
      switch (this.position) {
        case 'bottom-right':
          classes.push('content--bottom-right');
          break;
        case 'right':
          classes.push('content--right');
          break;
        default:
          break;
      }
      if (this.multiline) classes.push('multiline');
      if (this.breakLine) classes.push('break-word');
      if (this.size) classes.push(this.size);
      return classes;
    },
    breakLine() {
      return !this.label.includes(' ');
    },
    isTop() {
      return this.position === 'top';
    },
    colorClass() {
      if (this.color === 'danger') return 'base-popup--danger';
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.base-popup {
  $top: 0.75rem;
  $wide: 300px;
  $pointer-width: 0.5rem;
  @apply relative;
  &__content {
    @apply hidden absolute bg-gray-800 text-white py-2 px-4 z-10 shadow rounded text-sm whitespace-no-wrap overflow-hidden;
    margin-top: $top;
    width: auto;
    left: -0.25rem;

    &.multiline {
      @apply whitespace-normal text-center;

      &.is--short {
        width: 180px;
      }
      &.is--medium {
        width: 240px;
      }
      &.is--wide {
        width: clamp(100%, 300px, 100vw);
      }
    }

    &.content--bottom-right {
      @apply right-0;
      left: unset;
    }
    &.content--right {
      @apply top-0;
      left: 100%;
    }
  }

  &:not(.base-popup--disabled):hover {
    .base-popup__content {
      @apply block;
      @apply z-50;

      &.multiline {
        display: flexbox;
      }
    }

    &::before {
      @apply border-gray-800 absolute left-0;
      content: ' ';
      border-style: solid;
      border-bottom-width: $pointer-width;
      border-left: $pointer-width solid transparent;
      border-right: $pointer-width solid transparent;
      bottom: -($top);
      left: calc(50% - 0.5rem);
    }

    &.content--top {
      .base-popup__content {
        bottom: calc(100% + 0.75rem);
      }

      &::before {
        top: -($top);
        transform: rotate(180deg);
      }
    }
  }

  &--danger {
    .base-popup__content {
      @apply bg-red-danger;
    }
    &:not(.base-popup--disabled):hover::before {
      @apply border-red-danger;
      border-left: $pointer-width solid transparent;
      border-right: $pointer-width solid transparent;
    }
  }
}
</style>
