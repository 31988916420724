
































































import Vue, { PropType } from 'vue';
import ParagraphEditable from '@/components/paragraph-editable.vue';
import { Task, TaskCopyRequest } from '@/types/Task';
import { AxiosPromise } from 'axios';

interface Includes {
  [key: string]: boolean;
}

const initIncludes = (): Includes => ({
  attachments: true,
  tags: true,
  checklist: true,
  assignees: true
});

export default Vue.extend({
  components: {
    ParagraphEditable
  },
  props: {
    task: {
      type: Object as PropType<Task>
    },
    modalName: {
      type: String,
      default: 'task-copy'
    }
  },
  data() {
    return {
      opened: false,
      name: '',
      includes: initIncludes(),
      loading: false
    };
  },
  methods: {
    onClosed() {
      this.$emit('closed');
      this.name = '';
      this.opened = false;
      this.includes = initIncludes();
    },
    onOpened() {
      this.opened = true;
    },
    onChecks(ev: { id: number; checked: boolean }) {
      const { id, checked } = ev;
      const prev = { ...this.includes };
      prev[id] = checked;
      this.includes = prev;
    },
    setName() {
      this.name = `Copy of ${this.task.name}`;
    },
    async onSumbit() {
      this.loading = true;

      try {
        const { data } = await this.copyTask({
          id: this.task.id,
          name: this.name,
          isChecklist: this.includes.checklist,
          isFile: this.includes.attachments,
          isTag: this.includes.tags,
          isAssignUser: this.includes.assignees
        });

        this.$notify({
          group: 'task-copy',
          data: {
            task: data
          }
        });
      } catch (error) {
        this.$notify({
          group: 'app-noti',
          type: 'error',
          title: error?.response?.data.message
        });
      }

      this.loading = false;
      this.onClosed();
    },
    copyTask(copy: TaskCopyRequest): AxiosPromise<Task> {
      return this.$store.dispatch('tasks/copyTask', copy);
    }
  }
});
