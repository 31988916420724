<template>
  <main
    id="boards-home"
    v-close-popover.all
    class="grid"
    :class="{
      'md:grid-cols-content-boards-home': isUser
    }"
  >
    <subscription-sidebar v-if="isUser" class="fixed w-sidebar" />
    <router-view class="board-detail-subscription" />
  </main>
</template>

<script>
import SubscriptionSidebar from '@/components/subscription/subscription-sidebar';

export default {
  name: 'Boards-Home',
  components: {
    SubscriptionSidebar
  },
  computed: {
    isUser() {
      return this.$store.getters['auth/isUser'];
    }
  },
  async created() {
    await this.$store.dispatch('teams/fetchTeams');
  }
};
</script>

<style>
#boards-home {
  grid-template-rows: auto 1fr;
}
@screen md {
  #boards-home {
    @apply grid-rows-1;
  }
}

.board-detail-subscription {
  @apply col-start-2;
  margin: 17px 28px;
}
</style>
