








import Vue from 'vue';
import { tasksApi as api } from '@/services/apis';
import { getTaskRouter } from './utils';
import { NavigationGuardNext, Route } from 'vue-router';
import localJwt from '@/services/local-jwt';

export default Vue.extend({
  beforeRouteEnter(to, _, next) {
    next(vm => {
      (vm as {
        handleRouteEnter(
          to: Route,
          next: NavigationGuardNext
        ): Promise<unknown>;
      } & Vue).handleRouteEnter(to, next);
    });
  },
  methods: {
    async handleRouteEnter(
      to: Route,
      next: NavigationGuardNext
    ): Promise<void> {
      try {
        const getTask = await api.get(to.params.id);
        const taskRoute = getTaskRouter(to, getTask.data);
        taskRoute.replace = true;
        next(taskRoute);
      } catch (error) {
        if (localJwt.hasAccessToken()) {
          next({ name: 'Boards-List', replace: true });
        } else {
          next({
            name: 'Landing',
            query: {
              to: to.fullPath
            }
          });
        }
      }
    }
  }
});
