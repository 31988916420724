<template>
  <v-popover
    placement="bottom-start"
    popover-base-class="tooltip popover select-boards--popover"
    popover-arrow-class="tooltip-arrow popover-arrow invisible"
    @show="active = true"
    @hide="active = false"
  >
    <slot :active="active">
      <base-button>Select Board</base-button>
    </slot>
    <div slot="popover" class="select-board-report--popover divide-y">
      <div>
        <base-dropdown-item :hoverable="false" keep>
          <div class="flex mb-2">
            <p class="font-medium">{{ $t('personalReport.board.board') }}</p>
            <div class="ml-auto text-brand-blue">
              <button v-if="value.length" @click="clearAllBoard">
                {{ $t('personalReport.board.unselectAll') }}
              </button>
              <button v-else @click="selectAllBoard">
                {{ $t('personalReport.board.selectAll') }}
              </button>
            </div>
          </div>
          <base-input-text
            v-model="queryText"
            inner-class="base-input--gray"
            :placeholder="$t('personalReport.search')"
            icon-fa="search"
            :icon-right="queryText ? 'IconClose' : null"
            @icon-right-click="queryText = ''"
          />
        </base-dropdown-item>
        <base-dropdown-item
          v-for="board in boards"
          :key="`tag-${board.id}`"
          keep
          @click="toggleActiveBoard(board)"
        >
          <div class="flex items-center overflow-hidden">
            <base-checkbox :checked="isBoardActive(board)" />
            <div
              class="rounded-full flex-1 px-4 py-1  text-white font-medium truncate"
            >
              <div class="flex">
                <div
                  class="rounded mr-2"
                  style="width:32px; height:32px;"
                  :style="getBoardBackgroundColor(board)"
                >
                  <base-icon size="32" :icon="getBoardIcon(board)" />
                </div>
                <span class="flex-1 text-black truncate">{{ board.name }}</span>
              </div>
            </div>
          </div>
        </base-dropdown-item>
        <base-dropdown-item v-if="!boards.length" :hoverable="false">
          <div class="text-center text-gray-500 text-sm p-2">
            {{ $t('personalReport.board.noBoard') }}
          </div>
        </base-dropdown-item>
      </div>
    </div>
  </v-popover>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import BoardUtils from '@/mixins/BoardUtils';

const { mapGetters } = createNamespacedHelpers('boards');
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: false,
      queryText: ''
    };
  },
  mixins: [BoardUtils],
  computed: {
    ...mapGetters(['getBoards']),
    boards() {
      const byName = ({ name }) =>
        name.toLowerCase().includes(this.queryText.toLowerCase());
      return this.getBoards.all.filter(byName);
    }
  },
  methods: {
    isBoardActive(board) {
      return this.value.some(boardId => boardId === board.id);
    },
    toggleActiveBoard(board) {
      const value = this.isBoardActive(board)
        ? this.value.filter(boardId => boardId !== board.id)
        : [...this.value, board.id];
      this.$emit('input', value);
    },
    deleteBoard(boardID) {
      const value = this.value.filter(boardId => boardId !== boardID);
      this.$emit('input', value);
    },
    getBoardIcon(board) {
      return ['board-kanban', 'personal-waterfall', 'personal-agile'][
        board.type
      ];
    },
    selectAllBoard() {
      const value = [];
      this.boards.forEach(board => {
        value.push(board.id);
      });
      this.$emit('input', value);
    },
    clearAllBoard() {
      this.$emit('input', []);
    }
  }
};
</script>
<style>
.select-boards--popover.tooltip.popover {
  z-index: 10;
}
.select-board-report--popover {
  @apply relative overflow-y-auto;
  max-height: 383px;
  width: 461px;
}
</style>
