<template>
  <chart-bar-stacked
    ref="barChart"
    :width="400"
    :height="170"
    :chart-data="chartData"
    :options="options"
  />
</template>

<script>
import TimelogChartMixin from '@/mixins/TimelogChart';
import ChartBarStacked from '@/components/chart/chart-bar-stacked';
export default {
  components: {
    ChartBarStacked
  },
  mixins: [TimelogChartMixin],
  props: {
    timelogs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    options() {
      return {
        ...this.timelogChartOptions,
        aspectRatio: 2,
        onClick: this.createOnClick(this.onClick)
      };
    },
    chartData() {
      const timelogLen = this.timelogs.filter(e => e.timeSpent).length;
      const initialData = {
        datasets: [],
        labels: [...Array(timelogLen)].map(() => '')
      };
      return this.timelogs
        .filter(timelog => timelog.timeSpent)
        .reduce((p, timelog, i) => {
          const data = [...Array(timelogLen)].map(() => 0);
          const { hour } = this.getTimeUnits(timelog.timeSpent);
          data[i] = hour;
          return {
            ...p,
            datasets: [
              ...p.datasets,
              {
                data,
                backgroundColor: timelog.color,
                maxBarThickness: 40
              }
            ]
          };
        }, initialData);
    }
  },
  watch: {
    timelogs: {
      handler: 'setChartTimelogs',
      immediate: true
    }
  },
  methods: {
    setChartTimelogs(timelogs) {
      this.chartTimelogs = timelogs;
    },
    onClick(e) {
      const timelog = this.timelogs[e.bar];
      this.$emit('click', {
        timelog,
        view: e.view
      });
    }
  }
};
</script>

<style></style>
