<template>
  <base-card class="board-list__card" body-padding="py-2 px-4">
    <div slot="header" class="h-16" :style="getBoardColor(board.Color)" />
    <div
      class="absolute top-0 inset-x-0 flex items-start justify-between pt-3 px-3"
    >
      <img :src="board.Icon" :class="{ 'board-list--img': board.Type == 2 }" />
      <div class="flex items-center">
        <base-button
          v-show="showDeleteBoard"
          data-testid="option-board"
          color="white"
          wide="p-1"
          icon="trash"
          icon-size="20"
          shadowless
          rounded="sm"
          class="align-middle"
          borderless
          @click="$emit('delete', board.BoardID)"
        />
      </div>
    </div>
    <div class="board-list__title">
      <div>
        <p class="font-medium truncate">{{ board.Name }}</p>
        <p class="text-sm font-medium">
          {{ board.TotalDoneTasks }}
          {{ $t('personalReport.board.task') }}
        </p>
      </div>
    </div>
  </base-card>
</template>
<script>
export default {
  props: {
    board: {},
    showDeleteBoard: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getBoardColor(color) {
      return {
        'background-color': color
      };
    }
  }
};
</script>

<style scoped lang="scss">
.board-list--img {
  width: 31px;
  height: 31px;
}
.board-list {
  &__card {
    @apply relative;
  }

  &__title {
    @apply bg-white flex flex-col justify-between;
    height: 44px;
  }
}
</style>
