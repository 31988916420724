import { Module } from 'vuex';
import { AxiosPromise } from 'axios';
import { Unsubscribe } from 'firebase';
import { BoardColumn, BoardColumnAdd, Board } from '@/types/Board';
import { AxiosApis, CommandResponse } from '@/types/Api';
import { RootState } from '@/types/Store';
import apis, { restFactory } from '@/services/apis';
import firebase, { getCollections } from '@/services/firebase';
import { getNewListWithUpdate, isIncluded } from './utils';

interface BoardColumnApi extends AxiosApis<BoardColumn, BoardColumnAdd> {
  archiveColumn(id: number): AxiosPromise<CommandResponse>;
}

interface State {
  columns: Array<BoardColumn>;
  api: BoardColumnApi;
  loading: boolean;
  unsubscribe?: Unsubscribe;
}

const columnApiFactory = (id: number): BoardColumnApi => {
  return {
    ...restFactory(`boards/${id}/columns`),
    archiveColumn: (columnId: number) =>
      apis.post(`/boards/${id}/columns/${columnId}/archive`)
  };
};

const colSorter = (a: BoardColumn, b: BoardColumn) => a.position - b.position;

const boardColumnModule: Module<State, RootState> = {
  namespaced: true,
  state: (): State => ({
    columns: [],
    api: columnApiFactory(0),
    loading: false
  }),
  getters: {
    getColumns: s => s.columns,
    getSortedColumns: s => [...s.columns].sort(colSorter),
    getNewColumnPosition: s =>
      (s.columns[s.columns.length - 1]?.position || 1) * 1
  },
  mutations: {
    SET_COLUMNS(s, payload) {
      s.columns = payload;
      s.loading = false;
    },
    SET_COLUMN_API(s, id: number) {
      s.api = columnApiFactory(id);
    },
    ADD_COLUMN(s, payload: BoardColumn) {
      if (!isIncluded(s.columns, payload)) {
        s.columns.push(payload);
      }
      s.loading = false;
    },
    SET_COLUMN(s, payload: BoardColumn) {
      /*
       * * The below lines are merged first because firebase updating does not have all our backend properties
       * * then it is needed to keep previous properties
       */
      const updateColumn = {
        ...s.columns.find(col => col.id === payload.id),
        ...payload
      };
      const updatedColumns = getNewListWithUpdate(s.columns, updateColumn);
      s.columns = updatedColumns.sort(colSorter);
      s.loading = false;
    },
    REMOVE_COLUMN(s, id) {
      s.columns = s.columns.filter(e => e.id !== id);
      s.loading = false;
    },
    TOGGLE_LOADING(s, forcedLoading?: boolean) {
      if (forcedLoading == null) s.loading = !s.loading;
      else s.loading = forcedLoading;
    },
    SET_UNSUB(s, unsub?: Unsubscribe) {
      s.unsubscribe = unsub;
    }
  },
  actions: {
    fetchColumns: async ({ commit, state, dispatch, rootGetters }) => {
      commit('TOGGLE_LOADING', true);
      const res = await state.api.indexList();

      const activeBoard = rootGetters['boards/getActiveBoard'] as Board;
      const { boards, boardColumns } = getCollections();
      const boardDoc = firebase
        .firestore()
        .collection(boards)
        .doc(activeBoard.id.toString());
      const columnCollection = boardDoc.collection(boardColumns);
      const listening = columnCollection.onSnapshot(snap =>
        dispatch('firestoreSubscribe', snap)
      );
      commit('SET_UNSUB', listening);
      commit('SET_COLUMNS', res.data);
    },
    addColumn: async ({ commit, state }, payload: BoardColumnAdd) => {
      commit('TOGGLE_LOADING', true);
      const res = await state.api.create(payload);
      commit('ADD_COLUMN', res.data);
    },
    setColumn: async ({ commit, state }, payload: BoardColumn) => {
      commit('TOGGLE_LOADING', true);
      await state.api.update(payload.id, payload);
      // commit('SET_COLUMN', res.data);
    },
    async removeColumn({ commit, state }, id: number) {
      commit('TOGGLE_LOADING', true);
      await state.api.delete(id);
      commit('REMOVE_COLUMN', id);
    },
    archiveColumn: async ({ commit, state }, id: number) => {
      commit('TOGGLE_LOADING', true);
      await state.api.archiveColumn(id);
      commit('REMOVE_COLUMN', id);
    },
    firestoreSubscribe(
      { commit, state },
      snap: firebase.firestore.QuerySnapshot
    ) {
      snap.docChanges().map(async val => {
        const activeId = parseInt(val.doc.id);
        const data = {
          ...val.doc.data(),
          id: activeId
        } as BoardColumn;
        switch (val.type) {
          case 'added': {
            if (isIncluded(state.columns, data)) commit('SET_COLUMN', data);
            else commit('ADD_COLUMN', data);
            break;
          }
          case 'modified': {
            commit('SET_COLUMN', data);
            break;
          }
          case 'removed': {
            commit('REMOVE_COLUMN', activeId);
            break;
          }
          default:
            break;
        }
      });
    },
    firestoreUnsubscribe({ state, commit }) {
      state.unsubscribe?.();
      commit('SET_UNSUB');
    }
  }
};

export default boardColumnModule;
