<template>
  <v-draggable
    v-show="!loading"
    ref="list-tasks"
    v-model="tasks"
    v-bind="draggableConfig"
    :disabled="disabled || viewOnly"
    group="sprint-tasks"
    direction="vertical"
    draggable=".item-draggable"
    drag-class="item--drag"
    ghost-class="item--ghost"
    class="list-tasks h-full"
    :empty-insert-threshold="80"
    @start="dragstart"
    @end="dragend"
    @change="onChange"
  >
    <div slot="header">
      <div
        class="list-tasks__item item--heading"
        :class="{ 'list-tasks__no_description': hideDescription }"
      >
        <div>
          {{ $t('task.title_task') }}
        </div>
        <div>
          {{ $t('task.assignee') }}
        </div>
        <div>
          {{ $t('modelTask.status.name') }}
        </div>
        <div>
          {{ $t('input.end_date') }}
        </div>
        <div v-if="!hideDescription">
          {{ $t('modelTask.task.description') }}
        </div>
      </div>
    </div>
    <wrapper-observe-visibility
      v-for="task in tasks"
      :key="task.id"
      class="item-draggable"
    >
      <template #default="{visible}">
        <div
          v-if="visible"
          class="list-tasks__item"
          :class="{ 'list-tasks__no_description': hideDescription }"
          @click="$emit('click', task)"
        >
          <div class="item__cell border-r">
            <div v-if="isShowSelect" class="w-8 cursor-pointer">
              <div class="radio">
                <input type="radio" :checked="checkedTask(task.id)" />
                <span
                  class="checkmark"
                  @click.prevent.stop="selectTask(task)"
                />
              </div>
            </div>
            <div :class="{ 'pl-8': isShowSelect }">
              <div class="flex items-center">
                <div class="text-xs text-gray-caption">
                  {{ task.code }}
                </div>
                <div
                  v-if="task.level"
                  class="badge-gray task-level truncate ml-2"
                  style="max-width: 200px; "
                >
                  <span class="mr-2 bg-white px-1 rounded">
                    {{ task.level.level }}
                  </span>
                  <span class="truncate max-w-10">{{ task.level.name }} </span>
                </div>
              </div>

              <div class="truncate">
                {{ task.name }}
              </div>
              <div class="flex items-center -mb-1 margin-top--6px">
                <select-priority
                  readonly
                  class="mr-2 mb-1"
                  :value="task.priority"
                  disabled
                  :label="false"
                />
                <span
                  v-if="task.storyPoint >= 0"
                  class="tag tag--story-point mr-2 mb-1"
                >
                  {{ task.storyPoint }}
                </span>
                <span v-else class="tag tag--story-point mr-2 mb-1">
                  -
                </span>
                <div
                  v-if="task.tags"
                  class="flex flex-1 items-center overflow-hidden space-x-1"
                >
                  <span class="text-sm mb-1 whitespace-no-wrap"
                    >{{ $t('agile.backlog.tag') }}:
                  </span>
                  <select-task-tags
                    :key="screen.width"
                    :lines="1"
                    class="flex-1"
                    no-add
                    tag-max-width="100%"
                    :hidden-at="3"
                    :task="task"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="item__cell">
            <base-avatar
              class="inline-flex"
              :list="getUsersFromAssigns(task.assigns)"
              size-small
              popup
              @click.native.stop
            />
          </div>
          <div class="item__cell">
            <div class="agile-badge whitespace-no-wrap truncate">
              <info-dot-agile-status
                :status="column(task).status"
                class="mr-1"
              />

              <span class="truncate">
                {{ column(task).name }}
              </span>
            </div>
          </div>
          <div class="item__cell">
            <div>
              <my-task-task-time
                class="text-center inline-block"
                :end-at="task.endAt"
                :diff-date="task.endAt && dayDiff(task.endAt)"
                :status="task.status"
              />
            </div>
          </div>
          <div v-if="!hideDescription" class="item__cell">
            <div
              class="task-description text-sm text-2-line"
              v-html="task.description"
            />
          </div>
        </div>
        <div v-else style="height: 103px;" />
      </template>
    </wrapper-observe-visibility>
  </v-draggable>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import VDraggable from 'vuedraggable';
import Vuedraggable from '@/mixins/Vuedraggable';
import WindowResize from '@/mixins/WindowResize';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import MyTaskTaskTime from '../my-task/my-task-task-time.vue';
import InfoDotAgileStatus from '../info/info-dot-agile-status.vue';
import SelectPriority from '../select/select-priority.vue';
import SelectTaskTags from '../select/select-task-tags.vue';
import WrapperObserveVisibility from '@/components/wrapper/wrapper-observe-visibility.vue';

export default {
  mixins: [Vuedraggable, WindowResize, DateTimeMixin],
  components: {
    VDraggable,
    MyTaskTaskTime,
    InfoDotAgileStatus,
    SelectPriority,
    SelectTaskTags,
    WrapperObserveVisibility
  },
  props: {
    loading: Boolean,
    disabled: Boolean,
    value: {
      type: Array
    },
    freeze: Boolean,
    hideDescription: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dragging: false,
      height: 'unset',
      rowHeights: {},
      checked: false
    };
  },
  computed: {
    ...mapGetters({
      isShowSelect: 'sprint/getShowSelect',
      getSelectedTask: 'sprint/getSelectedTask'
    }),
    tasks: {
      get() {
        return this.value;
      },
      set(tasks) {
        this.$emit('input', tasks);
      }
    },
    hasFooter() {
      return !!this.$slots.footer;
    },
    viewOnly() {
      return this.$store.getters['boards/isViewOnly'];
    }
  },
  watch: {
    freeze(freeze) {
      this.height = freeze
        ? this.$refs['list-tasks'].$el.offsetHeight + 'px'
        : 'unset';
      // this.$refs.tableRows.forEach((row, i) => {
      //   this.rowHeights[this.tasks[i].id] = freeze
      //     ? row.$el.offsetHeight + 'px'
      //     : 'unset';
      // });
    }
  },
  methods: {
    ...mapMutations({
      set_selected_task: 'sprint/SET_SELECTED_TASK'
    }),
    checkedTask(taskId) {
      const findTaskId = this.getSelectedTask.filter(t => t.id === taskId);
      return !!findTaskId.length;
    },
    selectTask(task) {
      this.set_selected_task(task);
    },
    dragstart() {
      this.dragging = true;
      this.$emit('dragstart');
    },
    dragend() {
      this.dragging = false;
      this.$emit('dragend');
    },
    getUsersFromAssigns(assigns = []) {
      return assigns.map(ass => ass.user);
    },
    onChange(ev) {
      if (ev.added && ev.added.newIndex !== 0) {
        ev.added.newIndex = Math.min(this.tasks.length - 1, ev.added.newIndex);
      }
      this.$emit('change', ev);
    },
    column(task) {
      return task.boardColumn;
    }
  }
};
</script>

<style lang="scss" scoped>
.list-tasks {
  $title-w: 440px;
  $assignees-w: 130px;
  $status-w: 90px;
  $end-at-w: 120px;
  $desc-w: 200px;
  $table-w: var($title-w) + var($assignees-w) + var($status-w) + var($end-at-w) +
    var($desc-w);
  $widths: (
    'title': $title-w,
    'assignees': $assignees-w,
    'status': $status-w,
    'end-at': $end-at-w,
    'desc': $desc-w,
    'table': $table-w,
    'no_description': 1046px
  );

  @apply overflow-x-auto;

  .list-tasks__item {
    @apply grid bg-white cursor-pointer;

    &.item--ghost {
      @apply bg-gray-300;
    }

    &:hover:not(.item--ghost) {
      @apply bg-light-gray transition-colors duration-200;
    }

    &:last-of-type {
      .item__cell {
        @apply border-b;
        .radio input + .checkmark {
          top: 25px;
        }
        .radio input:checked + .checkmark {
          @apply bg-primary;
        }
      }
    }

    grid-template-columns:
      minmax($title-w, 1fr) $assignees-w minmax($status-w, 140px)
      $end-at-w minmax($desc-w, 1fr);
    .item__cell {
      @apply py-4 px-2 border-t;

      &:first-child {
        @apply border-l;
      }
      &:last-child {
        @apply border-r;
      }
    }

    &.item--heading {
      @apply text-left text-gray-caption text-xs uppercase whitespace-no-wrap border-0;

      & > div {
        @apply p-2;
      }

      &:hover {
        @apply bg-transparent cursor-default;
      }
    }
  }

  .list-tasks__no_description {
    grid-template-columns: 500px 182px 182px 182px;
  }

  @each $name, $width in $widths {
    .list-tasks__#{$name} {
      width: $width;
    }
  }
  .margin-top--6px {
    margin-top: 6px;
  }
}
</style>

<style>
.item--drag {
  @apply shadow;
  opacity: 1 !important;
}
.item--ghost .item__cell {
  @apply opacity-0;
}
</style>
