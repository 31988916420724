<template>
  <div class="form-field" :class="{ 'form-field--row': row }">
    <p
      v-if="title"
      class="form-field__title"
      :class="{ 'mb-2': title }"
      :style="titleStyles"
    >
      {{ title }}
    </p>
    <slot />
    <p class="form-field__description">
      <slot name="description" />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    row: Boolean,
    titleStyles: Object
  }
};
</script>

<style lang="scss">
.form-wrapper--reset {
  .form-field {
    @apply px-0;
  }
}
.form-wrapper--vertical {
  @apply flex flex-col;

  & > .form-field + .form-field {
    @apply mt-5;
  }
}
.form-field {
  @apply px-2 py-1 flex flex-col relative;

  & & {
    @apply py-0;
  }

  &__title {
    @apply text-sm text-gray-label;
  }
  &__description {
    @apply text-xs text-gray-500 text-center;
  }

  &.form-field--row {
    @apply flex-row items-center;

    .form-field__title {
      @apply mb-0 mr-5;
    }
  }
}
.space-x {
  &-5,
  &-6 {
    .form-field {
      @apply px-0;

      &.form-field--row {
        .form-field__title:empty {
          @apply m-0;
        }
      }
    }
  }
}
</style>
