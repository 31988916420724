<template>
  <div class="skeleton-body">
    <div
      v-for="l in parseInt(lines, 10)"
      :key="l"
      class="skeleton-body__line"
      :class="getSize"
    />
  </div>
</template>

<script>
export default {
  props: {
    lines: {
      type: [Number, String],
      default: 1
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    getSize() {
      switch (this.size) {
        case 'small':
          return 'line--small';
        case 'large':
          return 'line--large';
        default:
          return 'line--medium';
      }
    }
  }
};
</script>

<style scoped>
.skeleton-body__line {
  @apply relative;
}

.line--small {
  @apply h-3;
}
.line--medium {
  @apply h-5;
}
.line--large {
  @apply h-8;
}
.skeleton-body__line + .skeleton-body__line {
  @apply mt-4;
}
.skeleton-body__line:last-child {
  @apply w-3/4;
}
.skeleton-body__line::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: #dfe3e8;
  border-radius: 3px;
}
</style>
