<template>
  <div class="rounded flex flex-col">
    <div
      v-if="hasHeader"
      class="rounded-t bg-gray-f5 px-4 py-2 border font-semibold"
    >
      <slot name="header" />
    </div>
    <div
      class="rounded-b px-4 py-2 border flex-1"
      :class="{ 'rounded-t': !hasHeader, 'border-t-0': hasHeader }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    hasHeader() {
      return !!this.$slots.header;
    }
  }
};
</script>

<style></style>
