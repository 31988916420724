<template>
  <div>
    <v-scroller class="relative" height="100%">
      <div class="p-5">
        <div class="flex mb-3">
          <base-icon class="self-center" size="24">
            <icon-report-export />
          </base-icon>
          <p class="flex-1 text-xl font-semibold self-center">
            {{ $t('personalReport.header') }}
          </p>
          <div class="flex">
            <div v-if="isTeamReport">
              <select-member-teams
                class="mr-4"
                :member-team-selected="memberTeamSelected.id"
                @memberSelected="getMemberSelected"
              >
                <base-button
                  class="w-full "
                  wide="px-10 "
                  style="max-width: 200px;"
                >
                  <span v-if="!member || memberTeamSelected === ''"
                    >Member</span
                  >
                  <span v-else class="truncate">
                    {{ memberTeamSelected.user.name }}</span
                  >
                  <div class="toggle-icon inline-flex has-centered-item px-2 ">
                    <base-icon
                      name="select-toggle"
                      size="16"
                      class="absolute mr-5 right-0"
                    >
                      <icon-chevron-down />
                    </base-icon>
                  </div>
                </base-button>
              </select-member-teams>
            </div>
            <div v-else>
              <select-boards
                ref="selectBoards"
                v-model="selectedBoard"
                @input="getBoard"
              >
                <base-button class="mr-4" wide="px-10">
                  {{ $t('personalReport.board.board') }}
                  <span
                    v-if="countActiveBoards"
                    class="bg-white rounded-xl px-3 ml-2 text-brand-blue"
                    >{{ countActiveBoards }}</span
                  >
                  <base-icon
                    class="ml-2"
                    name="chevron-down"
                    size="16"
                    color="white"
                  >
                    <icon-chevron-down />
                  </base-icon>
                </base-button>
              </select-boards>
            </div>

            <report-due-date
              class="mr-4"
              color="white"
              :default-date-this-week-calendar="defaultDateThisWeekCalendar"
              :select-date-from-team="
                isTeamReport && dateTeamReport != null
                  ? dateTeamReport.selectDate
                  : 3
              "
              :select-tab-date-from-team="
                isTeamReport && dateTeamReport != null
                  ? dateTeamReport.selectTabDate
                  : 'This Week'
              "
              @datePick="range"
            />
            <base-button
              wide="px-10"
              :disabled="loading || !hasBoardTaskDone || profile === undefined"
              :loading="loading"
              @click="exportPDF(selectedBoard)"
              ><a>{{ $t('personalReport.exportPDF') }}</a>
            </base-button>
          </div>
        </div>
        <div v-if="profile != undefined" class="border-outer p-4 mb-5">
          <div class="flex">
            <div>
              <img class="profile-img" :src="profileImg" />
            </div>
            <div class="overflow-hidden flex-1 leading-6 px-6">
              <p class="text-xl font-semibold truncate">{{ profile.name }}</p>
              <p class="text-sm text-gray-caption">{{ profile.email }}</p>
              <p class="text-sm font-medium uppercase">
                {{ $t('personalReport.bio') }}
              </p>
              <p class="text-sm text-2-line">{{ profile.bio || '-' }}</p>
            </div>
          </div>
        </div>

        <div
          v-if="
            selectedBoard.length ||
              (isTeamReport && hasBoardTaskDone && profile != undefined)
          "
        >
          <div class="flex mb-5">
            <base-box class="flex-1 mr-5">
              <p slot="header">
                {{ $t('personalReport.totalCompletedTasks.header') }}
              </p>
              <span
                class="font-semibold"
                style="font-size:32px; padding-right:10px"
                >{{ report.TotalAllDoneTasks || 0 }}</span
              >
              <span class="text-xl font-semibold">{{
                $t('personalReport.totalCompletedTasks.task')
              }}</span>
              <p class="text-sm text-gray-caption">
                {{ $t('personalReport.totalCompletedTasks.description') }}
              </p>
            </base-box>
            <base-box class="flex-1">
              <div slot="header" class="flex">
                <span>{{ $t('personalReport.tag.header') }}</span>
                <show-more-tag
                  v-if="visibleTags.length"
                  :boards="boardHasTag"
                  class="ml-auto"
                />
              </div>
              <!-- Tags -->
              <div v-if="visibleTags.length === 0" class="py-5 text-center">
                <p class="text-sm text-gray-caption font-medium">
                  {{ $t('personalReport.tag.noTag') }}
                </p>
              </div>
              <div v-else class="flex flex-wrap mt-3">
                <div
                  v-for="tag in visibleTags"
                  :key="tag.id"
                  class="mb-2 mr-1"
                  :style="tagItemStyle"
                >
                  <div
                    :style="{ 'background-color': tag.Color }"
                    class="tag text-white"
                  >
                    <p class="truncate text-center">
                      {{ tag.Name }} = {{ tag.TotalTasks }}
                    </p>
                  </div>
                </div>
                <div v-if="hasTagLeft">
                  <div :style="{ 'background-color': '#EAECF0' }" class="tag">
                    <p>+ {{ hasTagLeft }}</p>
                  </div>
                </div>
              </div>
            </base-box>
          </div>
          <base-box class="mb-5">
            <p slot="header">{{ $t('personalReport.timelog.header') }}</p>
            <!-- chart -->
            <personal-report-chart :data="report.GraphData" />
          </base-box>
          <base-box class="flex-1 mb-5">
            <p slot="header">
              Planned / Unplanned tasks
            </p>
            <chart-plan
              :total-plan="report.TotalPlanTimeLogs"
              :total-un-plan="report.TotalUnPlanTimeLogs"
            />
          </base-box>
          <div v-if="boardTaskDone.length">
            <base-icon name="board" view-box="0 2 18 14" size="20">
              <icon-board />
            </base-icon>
            <span class="font-medium pr-1" style="font-size:18px">
              {{ $t('personalReport.board.board') }}
            </span>
            <span v-if="isTeamReport" class="text-sm text-gray-caption">
              {{ boardTaskDone.length }} {{ $t('personalReport.board.board') }}
            </span>
            <span v-else class="text-sm text-gray-caption">
              {{ countActiveBoards }} {{ $t('personalReport.board.board') }}
            </span>
            <p class="board-description">
              {{ $t('show_board_description') }}
            </p>
            <!-- board -->
            <div class="flex overflow-auto mb-2">
              <div
                v-for="board in boardTaskDone"
                :key="board.id"
                class="card-wrapper"
                style="min-width: 265px"
              >
                <board-list-personal-report
                  :board="board"
                  :show-delete-board="!isTeamReport"
                  @delete="deleteSelectedBoard"
                />
              </div>
            </div>
          </div>
        </div>

        <base-empty
          v-if="taskInprogress && taskUnplan && taskOther == 0"
          :image="require('@/assets/image/no-board-report.svg')"
          :title="
            isTeamReport
              ? $t('team.report.noSelectedMember.header')
              : $t('personalReport.noSelectedBoard.header')
          "
          :description="
            isTeamReport
              ? $t('team.report.noSelectedMember.description')
              : $t('personalReport.noSelectedBoard.description')
          "
        />

        <div>
          <report-task-inprogress
            v-if="!isTeamReport && report.taskInprogress"
            :tasks="report.taskInprogress"
            class="mb-8"
            :date-select="date.dateRange"
          />

          <report-task-done
            :board-task-done="boardTaskDone"
            :is-team-report="isTeamReport"
            class="mb-8"
            :date-select="date.dateRange"
          />
          <report-task-unplan
            v-if="!isTeamReport && hasBoardTaskUnplan"
            :board="board"
            :date-select="date.dateRange"
            class="mb-8"
            :boards="boardTaskUnplan"
          />

          <report-task-other
            v-if="!isTeamReport && hasBoardTaskOther"
            :tasks="report.NoBoardTimeLogs"
          />
        </div>
      </div>
    </v-scroller>
    <modal-task v-if="isTeamReport" disabled comment-disabled />
  </div>
</template>
<script>
import apis from '@/services/apis';
import IconReportExport from '@/components/icons/IconReportExport';
import IconChevronDown from '@/components/icons/IconChevronDown';
import VScroller from '@/components/scroller';
import SelectBoards from '@/components/select/select-boards';
import BoardListPersonalReport from '@/components/board/board-list/board-list-personal-report.vue';
import ShowMoreTag from '@/components/show-more-tag.vue';
import LoadingMixin from '@/mixins/LoadingMixin';
import ReportDueDate from '@/components/report/report-due-date';
import moment from 'moment';
import personalReportChart from '@/components/personal-report-chart.vue';
import SelectMemberTeams from '@/components/select/select-member-teams.vue';
import modalTask from '@/components/modal/modal-task.vue';
import ChartPlan from '@/components/timelog/timelog-chart-plan';
import reportTaskInprogress from '@/components/report/report-task-inprogress';
import reportTaskUnplan from '@/components/report/report-task-unplan';
import reportTaskOther from '@/components/report/report-task-other';
import reportTaskDone from '@/components/report/report-task-done';
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('time-log');

export default {
  props: {
    isTeamReport: {
      type: Boolean,
      default: false
    },
    member: {
      type: Object,
      default: null
    },
    dateTeamReport: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      report: {},
      selectedBoard: [],
      hiddenAt: 8,
      board: [],
      date: { dateRange: [] },
      memberTeamSelected: this.member,
      taskInprogress: 0,
      taskUnplan: 0,
      taskOther: 0
    };
  },
  mixins: [LoadingMixin],
  components: {
    IconReportExport,
    IconChevronDown,
    VScroller,
    SelectBoards,
    BoardListPersonalReport,
    ShowMoreTag,
    ReportDueDate,
    personalReportChart,
    SelectMemberTeams,
    modalTask,
    ChartPlan,
    reportTaskInprogress,
    reportTaskUnplan,
    reportTaskOther,
    reportTaskDone
  },

  mounted() {
    this.$store.dispatch('boards/fetchBoards');

    if (this.isTeamReport) {
      this.getBoard();
    }
  },
  watch: {
    '$i18n.locale'() {
      this.getBoard(this.board);
    }
  },
  computed: {
    profile() {
      if (this.isTeamReport) return this.memberTeamSelected.user;
      else return this.$store.getters['auth/getUser'];
    },
    profileImg() {
      return (
        this.profile?.profilePicture ||
        'https://www.pngarts.com/files/5/User-Avatar-PNG-Image.png'
      );
    },
    countActiveBoards() {
      return this.selectedBoard.length;
    },
    defaultDateThisWeekCalendar() {
      return {
        start:
          this.dateTeamReport != null
            ? this.dateTeamReport.dateRange.start
            : moment()
                .startOf('week')
                .toDate(),
        end:
          this.dateTeamReport != null
            ? this.dateTeamReport.dateRange.end
            : moment()
                .endOf('week')
                .toDate()
      };
    },
    boardTaskDone() {
      const boards = this.report.Boards?.filter(
        board => board.TotalDoneTasks > 0
      );
      return boards;
    },
    hasBoardTaskDone() {
      return this.boardTaskDone?.length > 0;
    },
    boardTaskUnplan() {
      const boards = this.report.Boards?.filter(
        board => board.UnPlanTimeLogs != null
      );
      return boards;
    },
    hasBoardTaskUnplan() {
      return this.boardTaskUnplan?.length > 0;
    },
    hasBoardTaskOther() {
      return this.report.NoBoardTimeLogs?.length > 0;
    },
    boardHasTag() {
      const boards = this.boardTaskDone?.filter(tag => tag.Tags !== null);
      return boards;
    },
    tags() {
      const tags = [];
      this.boardTaskDone?.forEach(board => {
        board.Tags?.forEach(tag => {
          const index = tags.findIndex(
            _tag => _tag.Color === tag.Color && _tag.Name === tag.Name
          );
          if (index === -1) {
            tags.push({ ...tag });
          } else {
            tags[index].TotalTasks += tag.TotalTasks;
          }
        });
      });
      return tags;
    },
    visibleTags() {
      if (this.hasTagLeft) {
        return this.tags.slice(0, this.hiddenAt);
      }
      return this.tags;
    },
    hasTagLeft() {
      if (this.tags.length <= this.hiddenAt) {
        return 0;
      }
      return this.tags.length - this.hiddenAt;
    },
    tagItemStyle() {
      return {
        minWidth: '40px',
        maxWidth: '30%'
      };
    }
  },
  methods: {
    ...mapActions(['fetchTimelogsByDateRange']),

    async exportPDF(BoardID) {
      if (!BoardID.length && !this.isTeamReport) {
        return;
      }
      this.onLoading();
      if (!this.isTeamReport) {
        try {
          const res = await apis.get(
            `user/${this.profile.id}/reports/personal/export`,
            {
              params: {
                boardIDs: BoardID,
                startAt: this.date.dateRange.start,
                endAt: this.date.dateRange.end
              },
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
              }
            }
          );
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Personal-report.pdf`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          this.error = error?.response?.data?.message;
        }
      } else if (this.isTeamReport && this.profile != undefined) {
        try {
          const res = await apis.get(
            `/teams/${this.memberTeamSelected.teamID}/users/${this.memberTeamSelected.userID}/reports/personal/export`,
            {
              params: {
                boardIDs: BoardID,
                startAt: this.date.dateRange.start,
                endAt: this.date.dateRange.end
              },
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
              }
            }
          );
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Personal-report.pdf`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          this.error = error?.response?.data?.message;
        }
      }
      this.offLoading();
      this.close();
    },
    deleteSelectedBoard(boardID) {
      this.$refs.selectBoards.deleteBoard(boardID);
    },
    async getBoard(boards) {
      if (!this.isTeamReport) {
        this.board = boards;
        if (this.date.dateRange.length === 0) {
          this.date.dateRange = this.defaultDateThisWeekCalendar;
        }

        if (this.board.length > 0) {
          try {
            const dates = [this.date.dateRange.start, this.date.dateRange.end];
            await this.fetchTimelogsByDateRange(dates);

            const res = await apis.post(
              `user/${this.profile.id}/reports/personal`,
              {
                boardIDs: this.board,
                startAt: this.date.dateRange.start,
                endAt: this.date.dateRange.end,
                language: this.$i18n.locale
              }
            );
            this.report = res.data;
          } catch (error) {
            this.error = error?.response?.data?.message;
          }
        } else {
          this.report = {};
        }
      } else if (this.isTeamReport && this.profile != undefined) {
        if (this.date.dateRange.length === 0) {
          this.date.dateRange = this.defaultDateThisWeekCalendar;
        }
        try {
          const res = await apis.get(
            `/teams/${this.memberTeamSelected.teamID}/users/${this.memberTeamSelected.userID}/reports/personal`,
            {
              params: {
                startAt: this.date.dateRange.start,
                endAt: this.date.dateRange.end
              }
            }
          );
          this.report = res.data;
        } catch (error) {
          this.error = error?.response?.data?.message;
        }
      }
    },

    getMemberSelected(member) {
      this.memberTeamSelected = member;
      this.getBoard();
    },
    range(date) {
      const lastDay = moment(date.dateRange.start).subtract(1, 'day');
      date.dateRange.start = moment(lastDay).format('YYYY-MM-DD[T17:00:00Z]');
      date.dateRange.end = moment(date.dateRange.end).format(
        'YYYY-MM-DD[T16:59:59Z]'
      );
      this.date = date;
      return this.getBoard(this.board);
    }
  }
};
</script>

<style scoped lang="scss">
.border-outer {
  @apply rounded border border-gray-300;
}
.profile-img {
  @apply rounded;
  width: 126px;
  height: 126px;
}
.ml--0 {
  margin-left: 0px;
}
.personal-table-description {
  @apply max-w-sm;
  padding-left: 30px;
}
.board-description {
  padding-left: 33px;
  font-size: 14px;
  color: #7a869a;
}
</style>

<i18n>
{
  "en": {
    "show_board_description": "Board shown only boards with completed status."
  },
  "th": {
    "show_board_description": "บอร์ดที่แสดงเป็นบอร์ดที่มีสถานะเสร็จสิ้นเท่านั้น"
  }
}
</i18n>
