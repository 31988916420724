<template>
  <div class="grid overflow-y-auto mb-4">
    <update-team-form />
  </div>
</template>
<script>
import UpdateTeamForm from '@/components/update-team-form';
import { TeamMemberRole } from '@/types/Team';

export default {
  components: {
    UpdateTeamForm
  },
  mounted() {
    document.body.classList.add('vm--block-scroll');
  },
  methods: {}
};
</script>

<style></style>
