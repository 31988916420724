<template>
  <div class="form-wrapper--vertical h-full p-4">
    <h2 class="text-2xl font-medium mb-5">{{ $t('timelog.log') }}</h2>
    <form-field :title="$t('timelog.note')">
      <base-input-text
        v-model="description"
        :placeholder="$t('timelog.note')"
      />
    </form-field>
    <!-- TODO -->
    <form-field>
      <select-board-task
        v-model="boardTaskSelect"
        modal-name="form-timelog-board"
      />
    </form-field>
    <form-field v-if="!editing">
      <form-datetime
        date-modal
        time-drop-direction="up"
        :start.sync="startAt"
        :end.sync="endAt"
      />
    </form-field>
    <form-field class="flex-1">
      <div class="flex flex-col sm:flex-row sm:justify-between sm:items-end">
        <div class="mb-4 sm:mb-0">
          <base-button
            v-if="!loading"
            full
            wide="px-8"
            color="danger-outline"
            @click="toggleDelete"
          >
            {{ $t('timelog.button.delete') }}
          </base-button>
        </div>
        <div class="flex">
          <base-button
            v-if="!loading"
            class="w-1/2"
            wide="px-6"
            color="transparent"
            @click="closeModal"
          >
            {{ $t('timelog.button.cancel') }}
          </base-button>
          <base-button
            :loading="loading"
            class="w-1/2"
            wide="px-8"
            @click="onSave"
          >
            {{ $t('timelog.button.save') }}
          </base-button>
        </div>
      </div>
    </form-field>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import LoadingMixin from '@/mixins/LoadingMixin';
import FormField from './form-field';
import FormDatetime from './form-datetime';
import BoardDialogConfirm from '../board/board-dialog-confirm';
import SelectBoardTask from '@/components/select/select-board-task';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'time-log'
);

const EMPTY_ID = 0;

export default {
  components: {
    FormField,
    FormDatetime,
    SelectBoardTask
  },
  mixins: [LoadingMixin],
  props: {
    modalName: {
      type: String,
      default: 'timelog-edit'
    },
    editing: Boolean
  },
  data() {
    return {
      description: '',
      startAt: null,
      endAt: null,
      board: null,
      task: null
    };
  },
  computed: {
    ...mapGetters(['getEditTimelog']),
    boardID() {
      return this.board?.id || EMPTY_ID;
    },
    taskID() {
      return this.task?.id || EMPTY_ID;
    },
    timelog() {
      return this.getEditTimelog;
    },
    changes() {
      let changed = {};

      function isDiff(local, prop) {
        return local !== prop;
      }
      function isTimeDiff(localVal, propVal) {
        return isDiff(
          new Date(localVal).getTime(),
          new Date(propVal).getTime()
        );
      }
      const setChanged = newValRef => {
        changed = { ...changed, [newValRef]: this[newValRef] };
      };
      const { startAt, endAt, board, task, description } = this.timelog;
      if (isTimeDiff(this.startAt, startAt)) setChanged('startAt');
      if (isTimeDiff(this.endAt, endAt)) setChanged('endAt');
      if (isDiff(this.description, description)) setChanged('description');
      if (isDiff(this.boardID, board?.id)) setChanged('boardID');
      if (isDiff(this.taskID, task?.id)) {
        setChanged('taskID');
        if (changed.taskID === EMPTY_ID && this.board != null) {
          setChanged('boardID');
        }
      }
      return changed;
    },
    isChanged() {
      return !!Object.keys(this.changes).length;
    },
    boardTaskSelect: {
      get() {
        return {
          board: this.board,
          task: this.task
        };
      },
      set({ board, task }) {
        this.board = board;
        this.task = task;
      }
    }
  },
  watch: {
    timelog: {
      immediate: true,
      handler(val) {
        if (val) {
          const { description, endAt, startAt, board, task } = val;
          this.description = description;
          this.startAt = new Date(startAt);
          this.endAt = new Date(endAt);
          this.board = board;
          this.task = task;
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setEditTimelog: 'SET_EDIT_TIMELOG'
    }),
    ...mapActions(['updateTimelog', 'deleteTimelog']),
    async onSave() {
      if (this.isChanged) {
        this.onLoading();
        try {
          await this.updateTimelog(this.changes);
          this.closeModal();
        } catch (error) {
          //
        }
      }
      await this.$nextTick();
      this.offLoading();
    },
    closeModal() {
      this.setEditTimelog();
      this.$modal.hide(this.modalName);
      this.offLoading();
    },
    toggleDelete() {
      this.$modal.show(
        BoardDialogConfirm,
        {
          title: this.$t('timelog.delete.title'),
          description: `${this.$t('timelog.delete.description')}`,
          onConfirm: async () => {
            this.onLoading();
            await this.deleteTimelog(this.timelog.id);
            this.closeModal();
          }
        },
        {
          height: 'auto',
          maxWidth: 320,
          adaptive: true
        }
      );
    }
  }
};
</script>

<style scoped>
.select-toggle {
  @apply text-black shadow-none border-2 rounded flex px-4 py-2 m-0;
  height: min(44px);
}
.select-toggle:hover {
  @apply bg-gray-100;
}
</style>
