









































import Vue from 'vue';
import DateTimeMixin from '@/mixins/DateTimeMixin';

export default Vue.extend({
  components: {},
  props: {
    endAt: {
      type: String
    },
    diffDate: {
      type: Number
    },
    status: {
      type: Number
    }
  },
  computed: {},
  mixins: [DateTimeMixin],
  data() {
    return {};
  },
  methods: {}
});
