<template>
  <wrapper-time-counter :start-at="startAt">
    <template #default="{counter}">
      <div v-if="counter.active" :class="{ 'timer--separated': separated }">
        <div
          v-if="separated"
          class="counter-time flex-shrink-0"
          :class="[counterSize]"
        >
          {{ counter.display }}
        </div>
        <base-dropdown v-if="dropdown" v-bind="dropdown">
          <template #toggle>
            <slot
              name="stop"
              :attributes="stopButtonAttrs"
              :click="toggleStop"
              :display="counter.display"
            >
              <base-button
                v-bind="stopButtonAttrs"
                justify="justify-start"
                wide="pl-6 pr-4 py-3"
                class="leading-4 text-sm"
              >
                <fa-icon class="mr-3" icon="stop" />
                <span v-if="separated" class="px-4">
                  {{ $t('timelog.timer.log') }}
                </span>
                <span
                  v-else
                  class="inline-flex divide-x"
                  style=" height: 18px;"
                >
                  <span
                    v-if="timelogTitle"
                    class="truncate pr-1"
                    style="max-width:120px"
                  >
                    {{ timelogTitle }}
                  </span>
                  <span class="flex-shrink-0 pl-1">
                    {{ counter.display }}
                  </span>
                </span>
              </base-button>
            </slot>
          </template>
          <base-dropdown-item @click="showEditModal">
            <fa-icon icon="sliders-h" />
            <span class="ml-4">{{ $t('timelog.button.edit') }}</span>
          </base-dropdown-item>
          <base-dropdown-item danger @click="toggleStop">
            <fa-icon icon="stop" />
            <span class="ml-4">{{ $t('timelog.button.stop') }}</span>
          </base-dropdown-item>
        </base-dropdown>

        <slot
          v-else
          name="stop"
          :attributes="stopButtonAttrs"
          :click="toggleStop"
          :display="counter.display"
        >
          <base-button
            v-bind="stopButtonAttrs"
            justify="justify-start"
            wide="pl-6 pr-4 py-3"
            class="leading-4 text-sm"
          >
            <fa-icon class="mr-3" icon="stop" />
            <span v-if="separated" class="px-4">
              {{ $t('timelog.timer.log') }}
            </span>
            <span v-else class="inline-flex divide-x" style=" height: 18px;">
              <span
                v-if="timelogTitle"
                class="truncate pr-1"
                style="max-width:120px"
              >
                {{ timelogTitle }}
              </span>
              <span class="flex-shrink-0 pl-1">
                {{ counter.display }}
              </span>
            </span>
          </base-button>
        </slot>
      </div>
      <div v-else-if="!noStart" :class="{ 'timer--separated': separated }">
        <span v-if="separated" class="counter-time" :class="[counterSize]">
          {{ counter.display }}
        </span>
        <div v-if="!isUserViewer" @click="trackTimerTimelog">
          <slot name="start" :attributes="startButtonAttrs" :click="onStart">
            <base-button
              v-bind="startButtonAttrs"
              :responsive="responsive"
              @click="onStart"
            >
              <span :class="startButton.wide">
                {{ $t('timelog.timer.button') }}
              </span>
            </base-button>
          </slot>
        </div>
      </div>
      <modal v-bind="timelogEditModal">
        <form-timelog editing :modal-name="timelogEditModal.name" />
      </modal>
      <modal name="timelog-stop" height="auto" width="400px" scrollable>
        <div class="p-4">
          <h2 class="text-xl font-medium">
            {{ $t('timelog.stopTimelog.header') }}
          </h2>
          <p class="text-sm text-gray-caption">
            {{ $t('timelog.stopTimelog.description') }}
          </p>
          <div class="flex justify-end pt-3">
            <base-button wide="board" color="transparent" @click="toggleStop">
              {{ $t('timelog.stopTimelog.cancel') }}
            </base-button>
            <base-button wide="board" color="red-outline" @click="onStop">
              {{ $t('timelog.stopTimelog.stop') }}
            </base-button>
          </div>
        </div>
      </modal>
    </template>
  </wrapper-time-counter>
</template>

<script>
import { mapActions } from 'vuex';
import WrapperTimeCounter from '@/components/wrapper/wrapper-time-counter';
import FormTimelog from '../forms/form-timelog.vue';
import { boardsApi, tasksApi } from '@/services/apis';
export default {
  components: {
    WrapperTimeCounter,
    FormTimelog
  },
  props: {
    timelog: Object,
    startOptions: Object,
    disabled: Boolean,
    separated: Boolean,
    startButton: {
      type: Object,
      default: () => ({})
    },
    responsive: {
      type: Boolean,
      default: false
    },
    counterSize: {
      type: String,
      default: 'text-base'
    },
    noStart: Boolean,
    hideTitle: Boolean,
    dropdown: {
      type: [Object, Boolean],
      default() {
        return {
          width: 'full'
        };
      }
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    startAt() {
      return this.timelog?.startAt;
    },
    startButtonAttrs() {
      return {
        loading: this.loading,
        full: !this.separated,
        shadowless: true,
        disabled: this.disabled,
        ...this.startButton
      };
    },
    stopButtonAttrs() {
      return {
        loading: this.loading,
        full: !this.separated,
        shadowless: true,
        color: 'red',
        wide: 'px-8'
      };
    },
    timelogTitle() {
      const timelog = this.timelog;
      const titleKey = ['taskName', 'boardName', 'description'].find(
        key => timelog[key]
      );
      const title = timelog[titleKey];
      return !this.hideTitle && title;
    },
    isUserViewer() {
      return (
        this.$store.getters['boards/isUserViewer'] ||
        this.$store.getters['boards/isViewonly']
      );
    },
    timelogEditModal() {
      return {
        name: 'timelog-timer-edit',
        height: 'auto',
        adaptive: true,
        width: '100%',
        maxWidth: 660,
        scrollable: true
      };
    }
  },
  methods: {
    ...mapActions('time-log', ['startWorking', 'stopWorking']),
    getDefaultOptions() {
      return {
        input: 1,
        startAt: new Date().toISOString()
      };
    },
    onStart() {
      if (this.loading) return;
      this.toggleLoading();
      this.$nextTick(async () => {
        await this.startWorking({
          ...this.getDefaultOptions(),
          ...this.startOptions
        });
        this.toggleLoading();
      });
    },
    onStop() {
      if (this.loading) return;
      this.toggleLoading();
      this.$nextTick(async () => {
        await this.stopWorking();
        this.toggleLoading();
        this.toggleStop();
      });
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
    trackTimerTimelog() {
      this.$fana.logEvent('timelog/start_timelog');
    },
    async showEditModal() {
      this.$modal.show(this.timelogEditModal.name);
      const timelog = { ...this.timelog };
      if (this.timelog.boardId) {
        const { data: board } = await boardsApi.get(this.timelog.boardId);
        timelog.board = board;
      }
      if (this.timelog.taskId) {
        const { data: task } = await tasksApi.get(this.timelog.taskId);
        timelog.task = task;
      }
      this.$store.commit('time-log/SET_EDIT_TIMELOG', timelog);
    },
    toggleStop() {
      this.$modal.toggle('timelog-stop');
    }
  }
};
</script>

<style scoped>
.timer--separated {
  @apply flex flex-wrap justify-between items-center flex-1;
}
.counter-time {
  @apply text-center;
}
</style>
