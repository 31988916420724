import { Module } from 'vuex';
import { MembersState, RootState } from '@/types/Store';
import {
  BoardInvitationRequest,
  BoardMember,
  BoardMemberSetRoleRequest
} from '@/types/Board';
import { PageInformation } from '@/types/Api';
import { boardsApi } from '@/services/apis';
import router from '@/router';

const membersModule: Module<MembersState, RootState> = {
  state: {
    entities: [],
    page: {
      count: 0,
      lastPage: 1,
      page: 0
    },
    loading: false,
    filterIds: []
  },
  mutations: {
    ADD_MEMBERS(s, payload: BoardMember[]) {
      s.entities = [...s.entities, ...payload];
      s.page = { ...s.page, count: s.page.count + payload.length };
    },
    SET_PAGE(s, payload: PageInformation) {
      s.page = payload;
    },
    SET_LOADING(s, payload: boolean) {
      s.loading = payload;
    },
    UPDATE_MEMBER(s, payload: BoardMember) {
      s.entities = s.entities.map(e => (e.id === payload.id ? payload : e));
    },
    REMOVE_MEMBER(s, id: number) {
      s.entities = s.entities.filter(e => e.id !== id);
      s.page = { ...s.page, count: s.page.count - 1 };
    },
    RESET_STATE(s) {
      s.entities = [];
      s.page = {
        page: 0,
        count: 0,
        lastPage: 1
      };
      s.loading = false;
    },
    SET_FILTER_IDS(s, payload: number[]) {
      s.filterIds = payload;
    }
  },
  getters: {
    getMembers(s) {
      return s.entities;
    },
    getNumberMembers(s) {
      return s.entities.filter(e => e.status != 1).length;
    },
    getUserMember: s => s.entities.find(e => e.isCurrentUser),
    isNextValid(s) {
      return s.page.page < s.page.lastPage;
    },
    isLoading(s) {
      return s.loading;
    },
    getFilterIds(s) {
      return s.filterIds;
    },
    getCountMembers(s) {
      return s.page.count;
    }
  },
  actions: {
    fetchMembers: async ({ commit, rootGetters, state }) => {
      commit('SET_LOADING', true);
      const boardId = rootGetters['boards/getActiveBoardId'] ?? 0;
      const { data } = await boardsApi.fetchMembers(boardId, {
        page: state.page.page + 1,
        includePendingMember: true
      });
      if (state.page.page < data.pageInformation.lastPage) {
        commit('ADD_MEMBERS', data.entities);
        commit('SET_PAGE', data.pageInformation);
        commit('SET_LOADING', false);
      }
    },
    inviteMember: async (
      { commit, state },
      invitation: BoardInvitationRequest
    ) => {
      const { data } = await boardsApi.inviteMember(invitation);
      if (Array.isArray(data)) {
        commit('ADD_MEMBERS', data);
      }
    },
    setMemberRole: async (
      { rootGetters, commit, dispatch },
      newRoleMember: BoardMember
    ) => {
      const id = rootGetters['boards/getActiveBoardId'] ?? 0;
      const req: BoardMemberSetRoleRequest = {
        userID: newRoleMember.userID,
        role: newRoleMember.role,
        id,
        memberID: newRoleMember.memberID
      };
      await boardsApi.setMemberRole(req);
      commit('UPDATE_MEMBER', newRoleMember);
    },

    kickMember: async (
      { commit, dispatch, rootGetters },
      member: BoardMember
    ) => {
      const boardId = rootGetters['boards/getActiveBoardId'] ?? 0;
      await boardsApi.kickMember({
        id: boardId,
        memberID: member.id
      });
      commit('REMOVE_MEMBER', member.id);

      if (router.currentRoute.name == 'Teams-Board') {
        await dispatch('teams/fetchBoards', null, { root: true });
      }

      // is kick me redirect to home
      if (member.isCurrentUser) {
        router.push({ name: 'Boards-List' });
      }
    },
    kickMemberBoard: async ({ dispatch }, { boardId, memberId }) => {
      await boardsApi.kickMember({
        id: boardId,
        memberID: memberId
      });

      if (router.currentRoute.name == 'Teams-Board') {
        await dispatch('teams/fetchBoards', null, { root: true });
      }
      // commit('REMOVE_MEMBER', memberId);
    },
    setFilter({ commit }, ids: number[] = []) {
      commit('SET_FILTER_IDS', ids);
    }
  },
  namespaced: true
};

export default membersModule;
