<template>
  <div class="grid gap-y-4" style="height:auto">
    <p class="text-xl">{{ $t('header') }}</p>
    <div>
      <p class="mb-2">
        {{ $t('title1') }}
      </p>
      <p class="text-sm text-gray-caption">
        <span style="color: #E53E3E">{{ $t('warning') }}:</span>
        <span>{{ $t('you_have') }}</span>
        <span class="text-black">{{ $t('board') }}</span>
        {{ $t('description1') }}
      </p>
    </div>

    <div>
      <p class="mb-2">{{ $t('title2') }}</p>
      <p class="text-sm text-gray-caption">
        {{ $t('description2') }}
      </p>
    </div>

    <div class="mb-6">
      <p class="mb-2">{{ $t('title3') }}</p>
      <p class="text-sm text-gray-caption">
        {{ $t('description3') }}
      </p>
    </div>

    <base-button
      wide="px-8 py-2"
      type="button"
      color="gray2"
      class="hover:bg-red-600 hover:text-white"
      style="width: fit-content;"
      @click="deleteTeamClick()"
    >
      {{ $t('btn') }}
    </base-button>
  </div>
</template>

<script>
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';

export default {
  mounted() {
    document.body.classList.add('vm--block-scroll');
  },
  methods: {
    deleteTeamClick() {
      this.$modal.show(
        DialogConfirm,
        {
          title: this.$t('team.buttonConfirm.delete.titledeal'),
          description: this.$t('team.buttonConfirm.delete.description'),
          color: 'text-red-600',
          button: this.$t('team.buttonConfirm.delete.name'),
          onConfirm: async () => {
            this.$store.dispatch('teams/delete');
          },
          confirmData: this.team
        },
        {
          height: 'auto',
          width: 440
        }
      );
    }
  }
};
</script>

<i18n>
{
  "en": {
    "header":"Delete Workspace",
    "title1":"Would you like to delete your Workkami account: workspce name?",
    "warning":"Warning",
    "you_have":" You have", 
    "board":" 3 board ",
    "description1":"You will not be able to recover all tasks and boards if you press the delete account button.",
    "title2":"Deleting your account",
    "description2":"You are about to delete your Workkami account. Your profile and email will no longer be viewable on Workkami. This process cannot be reversed.",
    "title3":"What else you should know",
    "description3":"You cannot restore your Workkami account again. If you just want to change your profile picture, name, bio or email, you should not delete your account. This can be done by editing in Account -> Profile.",
    "btn":"Delete workspace"
  },
  "th": {
    "header":"ลบบัญชีเวิร์คสเปซ",
    "title1":"คุณต้องการลบพื้นที่การทำงานของคุณ: ....หรือไม่",
    "":"คำเตือน",
    "you_have":" คุณมี ", 
    "board":" 3 บอร์ด ",
    "description1":" คุณจะไม่สามารถกู้คืนงานและบอร์ดทั้งหมดได้หากคุณกดปุ่มลบบัญชี",
    "title2":"การลบบัญชีของคุณ",
    "description2":"คุณกำลังจะลบเวิร์คสเปซของคุณ บอร์ดหรืองานของคุณจะไม่สามารถดูได้บน WORKKAMI หากคุณลบจะไม่สามารถย้อนกลับได้",
    "title3":"มีอะไรอีกบ้างที่คุณควรรู้",
    "description3":"คุณไม่สามารถกู้คืนเวิร์คสเปซของคุณได้อีกครั้ง หากคุณต้องการแค่จัดการที่นั่งหรือเปลี่ยนรายละเอียดชื่อบอร์ดคุณสามารถแก้ไขได้ใน การตั้งค่าเวิร์คสเปซ",
    "btn":"ลบเวิร์คสเปซ"
  }
}
</i18n>
