<template>
  <div class="flex p-4 border rounded-sm">
    <base-icon :width="35" class="mr-2">
      <component :is="`icon${getCardDefault.brand}`" />
    </base-icon>
    <span class="font-medium"> {{ getCardDefault.card_no }} </span>
    <span
      class="ml-auto text-brand-blue text-sm cursor-pointer"
      @click="changPayment"
      >{{ $t('subscription.payment.changeMethod') }}</span
    >
    <modal
      ref="card-form"
      name="card-form"
      scrollable
      :width="400"
      height="auto"
    >
      <div class="border-b padding-title-modal">
        {{ $t('subscription.payment.add_method') }}
      </div>
      <payment-card-form :is-add-card="true" />
    </modal>
    <modal-payment-all-card :width="400" scrollable :is-change-payment="true" />
  </div>
</template>
<script>
import IconMasterCard from '@/components/icons/IconMasterCard';
import IconVisa from '@/components/icons/IconVisa';
import { mapGetters } from 'vuex';
import paymentCardForm from '@/components/payment/payment-card-form';
import modalPaymentAllCard from '@/components/modal/modal-payment-all-card';

export default {
  components: {
    IconMasterCard,
    IconVisa,
    paymentCardForm,
    modalPaymentAllCard
  },
  props: {},
  computed: {
    ...mapGetters('subscription', ['getCardDefault'])
  },
  methods: {
    changPayment() {
      this.$modal.show('all-card');
    }
  }
};
</script>

<style>
.status {
  @apply bg-green-success text-white p-1 ml-2 self-center rounded text-xs;
}
</style>
