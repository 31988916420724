<template>
  <div class="relative">
    <div class="flex items-center justify-between mb-3 relative flex-wrap">
      <button class="button pagination-btn order-1" @click="onPrev">
        <div class="has-centered-item">
          <fa-icon icon="chevron-left" size="lg" />
        </div>
        <span class="ml-4">{{ prevDay }}</span>
      </button>
      <select-date
        v-model="currentDate"
        class="w-full sm:w-auto sm:order-2 order-3 my-2"
        position="bottom-right"
        is-one-day
      />

      <button
        class="button pagination-btn order-2 sm:order-3"
        :disabled="disabledNext"
        @click="onNext"
      >
        <span class="mr-4">{{ nextDay }}</span>
        <div class="has-centered-item">
          <fa-icon icon="chevron-right" size="lg" />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import SelectDate from '@/components/select/select-date.vue';
export default {
  components: {
    SelectDate
  },
  props: {
    value: {
      type: Date,
      default: () => new Date()
    }
  },
  computed: {
    currentDate: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    today() {
      return moment().locale(`${this.$t('notifications.language')}`);
    },
    prevDay() {
      return moment(this.currentDate)
        .locale(`${this.$t('notifications.language')}`)
        .subtract(1, 'days')
        .format('DD MMM');
    },
    nextDay() {
      return moment(this.currentDate)
        .locale(`${this.$t('notifications.language')}`)
        .add(1, 'days')
        .format('DD MMM');
    },
    disabledNext() {
      return moment(this.currentDate)
        .locale(`${this.$t('notifications.language')}`)
        .add(1, 'days')
        .isAfter(this.today);
    }
  },
  methods: {
    onNext() {
      this.currentDate = moment(this.currentDate)
        .locale(`${this.$t('notifications.language')}`)
        .add(1, 'days')
        .toDate();
    },
    onPrev() {
      this.currentDate = moment(this.currentDate)
        .locale(`${this.$t('notifications.language')}`)
        .subtract(1, 'days')
        .toDate();
    }
  }
};
</script>

<style scoped>
.pagination-btn {
  @apply text-gray-500 shadow-none items-center rounded py-2;
}
.pagination-btn:disabled {
  @apply invisible;
}
.pagination-btn:not(:disabled):hover {
  @apply text-brand-blue bg-gray-200;
}

.pagination-btn:not(:disabled):active,
.pagination-datepicker:active {
  @apply bg-transparent;
}
</style>
