<template>
  <base-box id="user-timelogs">
    <div slot="header" :class="{ 'text-gray-label': !count }">
      {{ $t('admin.timelogs') }} ({{ count }})
    </div>
    <div v-if="!count" class="grid justify-items-center py-10">
      <base-icon :height="135" :width="319">
        <icon-user-time-log />
      </base-icon>
      <div class="pt-8 pb-1 text-xl font-medium text-gray-label">
        The time log was not found
      </div>
      <div class="text-empty">
        Time log data not found. for each board the sum of all time records for
        each board is show here.
      </div>
    </div>
    <div v-else class="overflow-y-auto -mx-4 -my-2" :style="{ height }">
      <ul class="p-4">
        <li
          v-for="timelog in timelogs"
          :key="timelog.id"
          class="border rounded p-2 mt-3 flex -mx-1 flex-col md:flex-row"
        >
          <div class="px-1 md:w-5/12 flex justify-between flex-col">
            <div class="flex overflow-hidden">
              <div class="font-medium truncate mr-1">
                {{ timelog.boardName }}
              </div>
              <board-star :active="timelog.isStarred" disabled />
            </div>
            <plan-unplan-legend />
          </div>
          <div class="px-1 md:w-7/12">
            <plan-progress-bar
              class="-mt-3"
              :plan-unplan="getPlaUnplan(timelog)"
            />
          </div>
        </li>
      </ul>
    </div>
  </base-box>
</template>

<script>
import BoardStar from '@/components/board/board-star';
import PlanProgressBar from './components/PlanProgressBar.vue';
import PlanUnplanLegend from './components/PlanUnplanLegend.vue';
import IconUserTimeLog from '@/components/icons/IconUserTimeLog.vue';

export default {
  components: { BoardStar, PlanUnplanLegend, PlanProgressBar, IconUserTimeLog },
  props: {
    timelogs: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: 'unset'
    }
  },
  computed: {
    count() {
      return this.timelogs.length;
    }
  },
  methods: {
    getPlaUnplan(timelog) {
      const { totalPlanInMinutes, totalUnPlanInMinutes } = timelog;
      return {
        plan: totalPlanInMinutes,
        unplan: totalUnPlanInMinutes
      };
    }
  }
};
</script>

<style scoped>
.text-empty {
  @apply text-sm font-normal text-center;
  width: 26rem;
  color: #a2aec2;
}
</style>
