import Vue from 'vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { Dictionary } from 'vue-router/types/router';

interface FetchTaskMixin extends Vue {
  isFetchTasksInit: boolean;
  fetchTasksString: string;
  fetchTasksInclusiveParams: { [e: string]: string };
  fetchTasksTags: number[];
  shouldShowSearchResult: boolean;
  fetchTasksHasSomeFilters: boolean;
  splitQueryString(query: string): number[] | undefined;
  unsubTasks(context: { clear: boolean }): void;
  fetchTasks(params: FetchRequest): Promise<void>;
  loadingTask: boolean;
}

type Query = Dictionary<string>;

interface Params extends Query {
  q: string;
  tagIDs: string;
  assigneeIDs: string;
}

interface FetchRequest {
  q: string;
  tagIDs?: number[];
  assigneeIDs?: number[];
  boardID?: string;
}

interface FetchData {
  (params: Params): void;
}

const halfSecond = (callback: FetchData) => debounce(callback, 500);

export default Vue.extend({
  data() {
    const { q, tagIDs } = this.$route.query as {
      q?: string | null;
      tagIDs?: string | null;
    };
    const fetchTasksString = q || '';
    const fetchTasksTags = tagIDs
      ? tagIDs.split(',').map(e => parseInt(e))
      : [];
    return {
      isFetchTasksInit: false, // this variable should be used only in this file.
      fetchTasksString,
      fetchTasksTags,
      shouldShowSearchResult: false,
      fetchTasksInclusiveParams: {}
    };
  },
  computed: {
    ...mapGetters('members', ['getFilterIds']),
    ...mapGetters('tasks', {
      loadingTask: 'isEntitiesLoading'
    }),
    fetchTasksHasSomeFilters(): boolean {
      const filters = [
        this.fetchTasksTags,
        this.getFilterIds,
        this.fetchTasksString
      ];
      const hasSelected = <T extends { length: number }>(e: T): boolean =>
        e.length > 0;
      return filters.some(hasSelected);
    },
    queryDeps(): FetchRequest {
      return {
        ...this.fetchTasksInclusiveParams,
        q: this.fetchTasksString,
        tagIDs: this.fetchTasksTags,
        assigneeIDs: this.getFilterIds
      };
    },
    showResultDeps(): Record<string, boolean> {
      return {
        hasSomeFilters: this.fetchTasksHasSomeFilters,
        loading: this.loadingTask
      };
    }
  },
  watch: {
    queryDeps: {
      handler(val, prev) {
        this.setRouterQuery(val, prev);
      }
    },
    showResultDeps: {
      handler({ loading, hasSomeFilters }, { loading: prevLoading }) {
        const allFalse = (value: boolean) => value === false;
        const isLoadingToCompleted = prevLoading === true && loading === false;
        const isLoadingInactive = [prevLoading, loading].every(allFalse);
        if (isLoadingToCompleted || isLoadingInactive) {
          this.shouldShowSearchResult = hasSomeFilters;
        }
      }
    }
  },
  beforeDestroy() {
    this.unsubTasks({ clear: false });
  },
  methods: {
    ...mapActions({
      fetchTasks: 'tasks/fetchTasks',
      unsubTasks: 'tasks/offSnapshotFirestore',
      setMembersFilter: 'members/setFilter'
    }),
    fetchData: halfSecond(async function(this: FetchTaskMixin, p) {
      const { tagIDs, assigneeIDs, ...params } = p;
      const fetchRequest: FetchRequest = {
        ...params,
        ...this.fetchTasksInclusiveParams
      };

      fetchRequest.boardID = this.$route.params.id;
      fetchRequest.tagIDs = this.splitQueryString(tagIDs);
      fetchRequest.assigneeIDs = this.splitQueryString(assigneeIDs);

      this.unsubTasks({ clear: false });
      await this.fetchTasks(fetchRequest);
      this.shouldShowSearchResult = this.fetchTasksHasSomeFilters;
    }),
    refreshTasks() {
      this.fetchData(this.$route.query as Params);
    },
    splitQueryString(str: string) {
      if (!str) return;
      return str.split(',').map(e => parseInt(e));
    },
    setRouterQuery: debounce(async function(
      this: FetchTaskMixin,
      current: FetchRequest,
      prevQuery?: FetchRequest
    ) {
      const { q = '', tagIDs = [], assigneeIDs = [] } = current;
      const {
        q: prevQ = '',
        tagIDs: prevTags = [],
        assigneeIDs: prevAssigneeIDs = []
      } = prevQuery || {};
      const isChanged =
        q !== prevQ ||
        tagIDs.length !== prevTags.length ||
        assigneeIDs.length !== prevAssigneeIDs.length;
      if (!isChanged) return;
      const query = {
        ...this.fetchTasksInclusiveParams,
        q,
        tagIDs: tagIDs.join(','),
        assigneeIDs: assigneeIDs.join(',')
      };
      this.$router.replace({ query });
    },
    200),
    clearFilters() {
      this.fetchTasksString = '';
      this.fetchTasksTags = [];
      this.setMembersFilter([]);
      this.shouldShowSearchResult = false;
    }
  }
});
