


















































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { createNamespacedHelpers } from 'vuex';
import TodoList, {
  TodoListItem,
  EmitCompleted,
  EmitName
} from '@/components/todo-list.vue';
import { Checklist, ChecklistUpdate } from '@/types/Checklist';
import { ApiReturnError } from '@/types/Api';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';

const { mapActions } = createNamespacedHelpers('tasks');

@Component({
  components: {
    TodoList,
    DialogConfirm
  },
  methods: mapActions([
    'addChecklist',
    'setChecklist',
    'removeChecklist',
    'toggleCompleteChecklist'
  ])
})
export default class TodoListTaskChecklist extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly checklists!: Checklist[];
  @Prop({ type: Boolean }) readonly disabled?: boolean;

  // get list():  {
  //   return (this.checklists || []).map(e => ({
  //     ...e,
  //     completed: e.isCompleted
  //   }));
  // }

  list: TodoListItem[] = [];

  get dones(): TodoListItem[] {
    return this.list.filter(e => e.completed);
  }

  @Watch('checklists', { immediate: true })
  setList(checklists: Checklist[] = []) {
    this.list = checklists.map(e => ({
      ...e,
      completed: e.isCompleted
    }));
  }

  @Watch('list')
  onListChanged() {
    // this.loading = false;
  }

  get progressNumber() {
    return `${this.dones.length}/${this.list.length}`;
  }
  get progressPercentNumber() {
    return ((this.dones.length / this.list.length) * 100).toFixed(0);
  }

  // addding feature
  @Ref('addInput') addInput?: HTMLInputElement;

  addChecklist!: {
    (name: string): Promise<Checklist>;
  };

  isAdding = false;
  newChecklist = '';

  afterAdd() {
    this.newChecklist = '';
  }

  toggleIsAdding() {
    this.isAdding = !this.isAdding || this.newChecklist.trim() !== '';
  }

  async handleAddChecklist() {
    const TEMP_ID = 999_999_999_999;
    const name = this.newChecklist;
    if (!name.trim()) return;

    this.list.push({
      name,
      position: 1,
      id: TEMP_ID,
      completed: false
    });
    this.afterAdd();
    await this.addChecklist(name);
  }

  cancelAdding() {
    this.newChecklist = '';
    this.isAdding = false;
  }

  // change checklist
  toRemove = 0;
  toggleCompleteChecklist!: {
    (listCompleted: Checklist): Promise<Checklist>;
  };
  removeChecklist!: {
    (id: number): Promise<Checklist>;
  };
  setChecklist!: {
    (value: { id: number } & ChecklistUpdate): Promise<Checklist>;
  };

  onChecklistCompleted: EmitCompleted = toggleItem => {
    const checklist = this.checklists.find(c => c.id === toggleItem.id);
    try {
      if (checklist) this.toggleCompleteChecklist(checklist);
    } catch (error) {
      const { response } = error as ApiReturnError;
      this.$notify({
        group: 'app-noti',
        title: `${this.$t('notifications.popup.checklist')}`,
        text: response?.data.message
      });
    }
  };

  confirmRemove(id: number) {
    this.$modal.show('confirm-checklist-remove');
    this.toRemove = id;
  }

  async onChecklistRemove() {
    this.list = this.list.filter(e => e.id !== this.toRemove);
    await this.removeChecklist(this.toRemove);
    this.toRemove = 0;
  }

  onChecklistNameChanged({ id, title }: EmitName) {
    this.setChecklist({ id, name: title });
  }

  // loading
  loading = false;
  toggleLoading() {
    this.loading = !this.loading;
  }
}
