<template>
  <div>
    <div
      class="board-detail board-detail--fixed"
      :class="{ active: isShowDetail }"
    >
      <div class="content flex flex-col">
        <v-scroller
          v-on-clickaway="hide"
          class="pt-4 flex-1"
          @bottom="scrollBottom"
        >
          <div>
            <div class="flex flex-no-wrap px-5">
              <v-popover
                :disabled="!isUserBoardAdmin(board)"
                placement="bottom-start"
                class="flex items-center mr-1"
                popover-arrow-class="tooltip-arrow popover-arrow invisible"
              >
                <div
                  class="w-8 h-8 rounded"
                  :style="getBoardBackgroundColor(board)"
                />
                <div
                  slot="popover"
                  class="px-2"
                  :style="{ 'max-width': `${5 * 32}px` }"
                >
                  <select-board-colors
                    :value="getBoardColor(board)"
                    padding="p-1"
                    size="small"
                    @input="
                      editBoard({ id: board.id, backgroundCover: $event })
                    "
                  />
                </div>
              </v-popover>
              <div class="flex-grow mr-4 relative description-hover">
                <span
                  v-if="!isFocusTitle"
                  class="title-box mb-3 pl-3 pt-3"
                  @click="isFocusTitle = true"
                  >{{ title }}</span
                >

                <base-input-text-growing
                  v-else
                  ref="autofocus"
                  v-model="title"
                  class="fond-bold text-2xl leading-tight"
                  rows="1"
                  borderless
                  :disabled="!isAdmin"
                  @blur="titleUpdate"
                />
              </div>
              <div class="self-start py-1">
                <base-dropdown
                  class="text-sm"
                  position="bottom-right"
                  width="small"
                >
                  <base-icon slot="toggle" color="gray" class="pointer">
                    <icon-dots-horizontal />
                  </base-icon>
                  <base-dropdown-item
                    class="flex items-center"
                    @click="notificationBoard"
                  >
                    <base-icon :width="20" :height="20" class="mr-2">
                      <icon-notification-bold />
                    </base-icon>
                    {{ $t('boardSetting.dropdown.notification') }}
                  </base-dropdown-item>

                  <base-dropdown-item
                    v-if="isAdmin"
                    class="flex items-center"
                    @click="toggleCopyBoardModal(board)"
                  >
                    <base-icon size="20" class="pl-1 pt-1">
                      <icon-copy-board />
                    </base-icon>
                    <span>{{ $t('board.copy') }}</span>
                  </base-dropdown-item>

                  <base-dropdown-item
                    v-if="isUserBoardAdmin(board)"
                    class="flex items-center"
                    @click="confirmArchivedBoard(board.id)"
                  >
                    <base-icon :width="20" :height="20" class="mr-2">
                      <icon-archive-setting />
                    </base-icon>
                    {{ $t('boardSetting.dropdown.archive') }}
                  </base-dropdown-item>
                  <base-dropdown-item
                    v-if="isUserBoardAdmin(board)"
                    class="text-red-danger flex items-center"
                    @click="confirmDeleteBoard(board.id)"
                  >
                    <base-icon :width="20" :height="20" class="mr-2">
                      <icon-trash-setting />
                    </base-icon>
                    {{ $t('boardSetting.dropdown.delete') }}</base-dropdown-item
                  >
                </base-dropdown>
              </div>
            </div>

            <p class="mx-4 text-gray-600 font-light text-sm pl-2 pr-2">
              {{ $t('boardSetting.by') }}
              {{ board.createdByUsername ? board.createdByUsername : '' }}
              {{ $t('boardSetting.on') }}
              <human-date :date="board.createdAt" />
            </p>

            <div class="mx-4 setting-tab">
              <ul class="profile-tab -mx-4 mt-4 flex">
                <li
                  v-for="tab in tabs"
                  :key="tab.value"
                  class="whitespace-no-wrap text-sm"
                  :class="{ active: tabIndex === tab.value }"
                  @click="changeTab(tab)"
                >
                  {{ $t(tab.locale) }}
                </li>
              </ul>
            </div>
          </div>
          <div
            class="px-4 board-setting-content"
            :class="{
              'bg-gray-main': [1, 3, 4].includes(tabIndex) && !isCreateLevel
            }"
          >
            <!-- Settings -->
            <div v-show="tabIndex == 0" class="px-4 py-3">
              <form-short-name
                :is-admin="isUserBoardAdmin(board)"
                :value="board.key"
                @save="saveShortName"
              />
              <!-- Description -->
              <div class="flex mt-3">
                <div class="mr-2">
                  <base-icon size="13">
                    <icon-description />
                  </base-icon>
                </div>
                <div>{{ $t('boardSetting.setting.description') }}</div>
              </div>
              <div>
                <div class="relative description-hover">
                  <textarea
                    ref="description-setting"
                    v-model="description"
                    type="textarea"
                    rows="4"
                    :disabled="!isAdmin"
                    class="w-full mt-2 py-2 pl-4 pr-8 resizeable-textarea fond-bold leading-tight rounded bg-input-gray"
                    @focus="isFocusDescription = true"
                    @keypress.enter.prevent="saveDescritption"
                  />
                  <div
                    v-if="isAdmin"
                    class="absolute top-0 right-0 px-2 py-3 operation"
                  >
                    <base-icon color="#555" :width="18" :height="18">
                      <icon-edit />
                    </base-icon>
                  </div>
                </div>
                <div v-if="isFocusDescription" class="flex justify-end mt-2">
                  <base-button
                    wide="px-8 py-2"
                    color="white"
                    type="button"
                    class="no-shadown"
                    @click="cancelDescritption"
                    >{{ $t('boardSetting.setting.cancel') }}</base-button
                  >
                  <base-button
                    wide="px-8 py-2"
                    color="brand"
                    type="button"
                    :disabled="description == board.description"
                    @click="saveDescritption"
                    >{{ $t('boardSetting.setting.save') }}</base-button
                  >
                </div>
              </div>

              <!-- Team -->
              <div class="flex mt-4 pb-1">
                <div class="mr-1">
                  <base-icon
                    color="#555"
                    view-box="0 0 15 15"
                    :width="18"
                    :height="21"
                  >
                    <icon-users />
                  </base-icon>
                </div>
                <div>{{ $t('agile.sitting.workspace') }}</div>
              </div>

              <div v-if="isUserBoardAdmin(board)">
                <select-team-setting :board="board" />
              </div>
              <base-input-text
                v-else
                :disabled="true"
                inner-class="base-input--gray"
                :value="board.team ? board.team.name : ''"
              />

              <!-- Members -->
              <div class="flex mt-4 border-b-1 pb-1">
                <div class="mr-1">
                  <base-icon
                    color="#555"
                    view-box="0 0 18 18"
                    :width="22"
                    :height="22"
                  >
                    <icon-user />
                  </base-icon>
                </div>
                <div>{{ $t('boardSetting.setting.member') }}</div>
              </div>
              <div class="pt-3">
                <div class="relative">
                  <base-input-text
                    v-model="memberQ"
                    inner-class="base-input--gray"
                    :loading="loading"
                    :placeholder="$t('boardSetting.setting.search')"
                    icon-fa="search"
                    type="text"
                    class="input-pr-8"
                  />
                  <div
                    class="mx-3 my-2 absolute top-0 right-0 cursor-pointer z-10"
                    @click="memberQ = ''"
                  >
                    <base-icon
                      v-if="memberQ"
                      :width="14"
                      :height="14"
                      name="close"
                    >
                      <icon-close />
                    </base-icon>
                  </div>
                </div>
              </div>

              <div
                v-if="isNotfound"
                class="text-gray-500 text-center p-4 text-sm"
              >
                {{ $t('myTasks.moreInvite.error') }}
              </div>

              <div
                v-for="(g, i) in userGroups"
                :key="`user-group-${i}`"
                class="mt-3"
              >
                <div class="text-sm text-gray-600">{{ g.title }}</div>
                <div
                  v-for="(u, j) in g.users"
                  :key="`user-group-${i}-${j}`"
                  class="flex py-1 member-hover items-center"
                >
                  <div>
                    <img
                      class="w-8 h-8 object-cover rounded-full flex-shrink-0"
                      :src="u.user.profilePicture || defaultImageSrc"
                    />
                  </div>
                  <div v-if="u.status > 1" class="flex-1">
                    <v-clamp
                      class="pl-3 text-gray-800 v-clamp-word-break"
                      :max-lines="1"
                      >{{ u.user.name }}</v-clamp
                    >
                    <v-clamp
                      class="pl-3 text-gray-600 v-clamp-word-break text-sm"
                      :max-lines="1"
                      >{{ u.user.email }}</v-clamp
                    >
                  </div>
                  <div v-else class="flex-1">
                    <v-clamp
                      class="pl-3 text-gray-600 v-clamp-word-break"
                      :max-lines="1"
                      >{{ u.inviteToEmail }}</v-clamp
                    >
                    <v-clamp class="pl-3 text-gray-600 text-sm">{{
                      $t('boardSetting.setting.pending')
                    }}</v-clamp>
                  </div>
                  <div class="pl-4 flex items-center">
                    <!-- <div
                      v-if="isAdmin || u.isCurrentUser"
                      class="round w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center pointer mr-2"
                      @click="removeUser(u)"
                    >
                      <base-icon class color="#555" :width="10" :height="10">
                        <icon-close />
                      </base-icon>
                    </div> -->

                    <!-- <div
                      v-if="u.isCurrentUser"
                      class="bg-blue-200 rounded-full px-3 mr-2 text-sm"
                    >
                      {{ $t('boardSetting.setting.you') }}
                    </div> -->

                    <select-member-role-setting
                      :member="u"
                      :disabled="
                        !(
                          (isAdmin && adminUsersCount > 1) ||
                          (!isAdmin && u.isCurrentUser) ||
                          (i != 0 && isAdmin)
                        )
                      "
                      :show-role="isAdmin"
                      @remove="onKickMember"
                    />
                  </div>
                </div>
              </div>
              <div v-if="isNextValid" class="py-2 text-center">
                <base-spinner />
              </div>
            </div>

            <!-- Column Status -->
            <div v-if="tabIndex == 3" class="px-4 py-3">
              <board-agile-column-setting />
            </div>
            <!-- End Column Status -->

            <!-- level Status -->
            <div v-if="tabIndex == 4" class="px-4 py-6 ">
              <board-agile-level-setting
                @createLevel="isCreateLevel = true"
                @cancel="isCreateLevel = false"
              />
            </div>

            <!-- Archived -->
            <div v-show="tabIndex == 1" class="px-4 py-3">
              <div class="mt-2 flex justify-between">
                <div class="text-xl">
                  {{
                    isArchivedItems
                      ? $t('boardSetting.archived.title.item')
                      : $t('boardSetting.archived.title.column')
                  }}
                </div>
                <div>
                  <base-button
                    color="brand"
                    wide="px-3 py-2 text-sm"
                    @click="toggleArchived"
                  >
                    {{ $t('boardSetting.archived.button.switch')
                    }}{{
                      isArchivedItems
                        ? $t('boardSetting.archived.button.column')
                        : $t('boardSetting.archived.button.item')
                    }}
                  </base-button>
                </div>
              </div>
              <!-- ITEMS -->
              <div v-if="isArchivedItems">
                <div>
                  <base-input-text
                    v-model="archivedItemsQ"
                    class="my-4"
                    type="text"
                    :placeholder="$t('boardSetting.archived.search.item')"
                    icon-fa="search"
                    :icon-right="archivedItemsQ ? 'IconClose' : null"
                    @icon-right-click="
                      () => {
                        archivedItemsQ = '';
                        archivedItemsQChange();
                      }
                    "
                    @update="archivedItemsQChange"
                  />
                </div>
                <div>
                  <div v-if="archivedItems.length">
                    <div
                      v-for="(item, i) in archivedItems"
                      :key="`archived-item-${i}`"
                    >
                      <router-link
                        :to="{ name: 'Task-Open', params: { id: item.id } }"
                        target="_blank"
                      >
                        <div class="archive-box">
                          <span>{{ item.name }}</span>

                          <div class="text-sm text-gray-caption">
                            <base-icon :height="17">
                              <icon-archive-at /> </base-icon
                            >{{ $t('team.archived') }}
                            {{
                              item.archivedAt
                                | moment_format('DD MMM YYYY, HH:mm')
                            }}
                          </div>
                        </div>
                      </router-link>

                      <div v-if="!isUserBoardViewer(board)" class="flex mb-2">
                        <div
                          class="text-gray-600 text-sm underline cursor-pointer"
                          @click="archivedItemUnarchivedClick(item.id)"
                        >
                          <base-icon name="refresh" :width="24">
                            <icon-refresh /> </base-icon
                          >{{ $t('boardSetting.archived.button.send') }}
                        </div>
                        <div class="mx-3 my-2">
                          <div class="dot" />
                        </div>
                        <div
                          class="text-red-500 text-sm underline cursor-pointer"
                          @click="archivedItemDeleteClick(item.id)"
                        >
                          {{ $t('boardSetting.archived.button.delete') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="flex justify-center align-middle">
                    <div class="px-12 py-8">
                      <div class="flex justify-center">
                        <logo-archived-not-found />
                      </div>

                      <div>
                        <div class="my-2 text-center text-2xl">
                          {{ $t('boardSetting.archived.no') }}
                        </div>
                        <div class="text-center text-sm text-gray-600">
                          {{ $t('boardSetting.archived.alert') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- COLUMN -->
              <div v-else>
                <div>
                  <base-input-text
                    v-model="archivedColumnQ"
                    class="my-4"
                    :placeholder="$t('boardSetting.archived.search.column')"
                    icon-fa="search"
                    type="text"
                    :icon-right="archivedColumnQ ? 'IconClose' : null"
                    @icon-right-click="
                      () => {
                        archivedColumnQ = '';
                        archivedColumnQChange();
                      }
                    "
                    @update="archivedColumnQChange"
                  />
                </div>
                <div>
                  <div v-if="archivedColumns.length">
                    <div
                      v-for="(column, i) in archivedColumns"
                      :key="`archived-column-${i}`"
                    >
                      <div
                        class="archive-box rounded"
                        :style="{ 'border-left': '6px solid' + column.color }"
                      >
                        <div class="self-center truncate">
                          {{ column.name }}
                        </div>
                        <div
                          class="text-xs box-border rounded-2xl	py-1 px-2 my-2"
                          style="border: 1px solid #DFE1E6;width: fit-content;"
                        >
                          {{ $tc('counts.task', countColumnTasks(column)) }}
                        </div>
                        <div class="flex justify-between">
                          <div class="text-sm text-gray-caption">
                            <base-icon :height="17">
                              <icon-archive-at /> </base-icon
                            >{{ $t('team.archived') }}
                            {{
                              column.archivedAt
                                | moment_format('DD MMM YYYY, HH:mm')
                            }}
                          </div>
                          <div v-if="!isUserBoardViewer(board)">
                            <div
                              class="text-gray-600 text-sm underline cursor-pointer"
                              @click="archivedColumnUnarchivedClick(column)"
                            >
                              <base-icon name="refresh" :width="24">
                                <icon-refresh /> </base-icon
                              >{{ $t('boardSetting.archived.button.send') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="flex justify-center align-middle">
                    <div class="px-12 py-8">
                      <div class="flex justify-center">
                        <logo-archived-not-found />
                      </div>

                      <div>
                        <div class="my-2 text-center text-2xl">
                          {{ $t('boardSetting.archived.no') }}
                        </div>
                        <div class="text-center text-sm text-gray-600">
                          {{ $t('boardSetting.archived.alert') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Activities -->
            <div v-show="tabIndex == 2" class="px-4">
              <div
                v-for="(activities, key) in activityDates"
                :key="key"
                class="pt-5"
              >
                <p class="px-4 text-sm font-medium">
                  {{ getCalendarDate(key) }}
                </p>
                <div
                  v-for="(a, i) in activities"
                  :key="`history-${i}`"
                  class="flex pt-2"
                >
                  <div class="flex-shrink-0">
                    <img
                      class="w-8 h-8 object-cover rounded-full"
                      :src="a.userAvatarPath"
                    />
                  </div>
                  <div class="px-2 mt-1 w-full">
                    <p class="text-black text-sm font-semibold truncate">
                      {{ a.userDisplayName }}
                    </p>
                    <div class="mt-1">
                      <p
                        class="text-black text-sm font-medium break-words"
                        v-html="
                          markdownText(
                            $i18n.locale === 'en'
                              ? a.descriptionEN
                              : a.descriptionTH
                          )
                        "
                      />
                    </div>
                    <p
                      class="text-right px-1 mt-1 text-gray-600 text-xs flex-shrink-0"
                    >
                      <human-date :date="a.updatedAt" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-scroller>
        <div
          v-if="tabIndex === 0"
          class="sticky bottom-0 right-0 flex justify-end"
        >
          <div
            v-if="adminTotal > 1"
            class="flex-1 bg-white py-4 px-8"
            style="max-width: calc(480px);"
          >
            <base-button
              full
              color="brand-outline"
              class="border-2"
              @click="removeUser(getUserMember)"
            >
              {{ $t('boardSetting.setting.button') }}
            </base-button>
          </div>
        </div>
      </div>
    </div>

    <modal
      ref="notificationBoard"
      name="notification-board"
      :width="460"
      adaptive
      height="auto"
      scrollable
    >
      <div class="px-6 py-4">
        <div class="text-lg font-bold">
          {{ $t('boardSetting.notification.title') }}
        </div>

        <div class="flex my-4 space-x-2">
          <div class="flex-grow-0 pr-2">
            <label class="switch">
              <input
                v-model="isNotificationOn"
                type="checkbox"
                @change="notificationChange"
              />
              <span class="slider round" />
            </label>
          </div>
          <div class="flex-grow">
            <div class="text-lg mb-1">
              {{ $t('boardSetting.notification.board') }}
            </div>
            <p class="text-gray-600 text-sm">
              {{ $t('boardSetting.notification.des') }}
            </p>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { directive as onClickaway } from 'vue-clickaway';
import VClamp from 'vue-clamp';
// services & mixins
import { boardsApi } from '@/services/apis';
import EventBus from '@/services/eventBus';
import LoadingMixin from '@/mixins/LoadingMixin';
import MarkdownTextMixin from '@/mixins/MarkdownText';
import BoardUtils from '@/mixins/BoardUtils';
import DateTime from '@/mixins/DateTimeMixin';
// components
import DialogConfirm from '@/components/board/board-dialog-confirm';
import SelectBoardColors from '@/components/select/select-board-colors';
import VScroller from '@/components/scroller';
import BoardAgileColumnSetting from './board-agile-column-setting';
import BoardAgileLevelSetting from './board-agile-level-setting';
import ModalBoardCopy from '@/components/modal/modal-board-copy.vue';
// Icons
import IconDescription from '@/components/icons/IconDescription';
import IconEdit from '@/components/icons/IconEdit';
import IconUser from '@/components/icons/IconUser';
import IconClose from '@/components/icons/IconClose';
import IconRefresh from '@/components/icons/IconRefresh';
import IconUsers from '@/components/icons/IconUsers';
import LogoArchivedNotFound from '@/components/icons/LogoArchivedNotFound';
import IconDotsHorizontal from '@/components/icons/IconDotsHorizontal';
import IconNotificationBold from '@/components/icons/IconNotificationBold';
import IconArchiveSetting from '@/components/icons/IconArchiveSetting';
import IconTrashSetting from '@/components/icons/IconTrashSetting';
import SelectTeamSetting from '@/components/select/select-team-setting.vue';
import SelectMemberRoleSetting from '@/components/select/select-member-role-setting.vue';
import HumanDate from '@/components/date/human-date.vue';
import BaseInputText from '../base/base-input-text.vue';
import FormShortName from '@/components/forms/form-short-name.vue';
import IconCopyBoard from '@/components/icons/IconCopyBoard';
import IconArchiveAt from '@/components/icons/IconArchiveAt';

const WATERFALL = 1;
const AGILE = 2;

export default {
  directives: {
    onClickaway
  },
  components: {
    IconDotsHorizontal,
    IconDescription,
    IconEdit,
    IconUser,
    IconClose,
    IconRefresh,
    IconUsers,
    IconNotificationBold,
    LogoArchivedNotFound,
    VClamp,
    VScroller,
    SelectBoardColors,
    IconArchiveSetting,
    IconTrashSetting,
    BoardAgileColumnSetting,
    SelectMemberRoleSetting,
    SelectTeamSetting,
    HumanDate,
    BaseInputText,
    BoardAgileLevelSetting,
    FormShortName,
    IconCopyBoard,
    IconArchiveAt
  },
  props: {
    board: {
      type: Object
    }
  },
  data() {
    return {
      isNotificationOn: true,
      isCreateLevel: false,
      isFocusTitle: false,
      isShowDetail: false,
      tabIndex: 0,
      isFocusDescription: false,
      title: '',
      description: '',
      teamID: 0,
      userGroups: [],
      activities: [],
      activitiesPage: {
        page: 1,
        lastPage: 1
      },

      isArchivedItems: true,
      archivedItemsQ: '',
      archivedColumnQ: '',
      archivedItemsOrg: [],
      archivedItems: [],
      archivedItemsPage: {
        page: 0,
        lastPage: 1
      },
      archivedColumnsOrg: [],
      archivedColumns: [],
      archivedColumnsPage: {
        page: 0,
        lastPage: 1
      },

      memberQ: '',
      kickUser: { user: {} },
      modalConfig: {
        height: 'auto',
        maxWidth: 420,
        adaptive: true
      },

      notification: false
    };
  },
  computed: {
    ...mapGetters('members', [
      'getLoading',
      'getMembers',
      'getUserMember',
      'isNextValid'
    ]),
    isTeamMember() {
      const teamMemberId =
        this.getUserMember.board.team?.currentTeamMember.id || 0;
      const role = this.getUserMember.role;

      return teamMemberId > 0 || role == 100;
    },
    isNotfound() {
      return this.memberQ && this.userGroups.length === 0;
    },
    tabs() {
      const defaultVisible = [0, 1, 2];
      const byOnlyVisibleAt = ({ onlyVisibleAt = defaultVisible }) =>
        onlyVisibleAt.includes(this.board.type);
      const tabs = [
        { locale: 'boardSetting.setting.name', value: 0 },
        { locale: 'boardSetting.level.name', value: 4 },
        { locale: 'boardSetting.column.status', value: 3, onlyVisibleAt: [2] },
        { locale: 'boardSetting.archived.name', value: 1 },
        { locale: 'boardSetting.activities.name', value: 2 }
      ];
      return tabs.filter(byOnlyVisibleAt);
    },
    owner() {
      return this.getMembers.find(e => e.role == 100) || {};
    },
    isAdmin() {
      if (this.getUserMember) {
        return this.getUserMember.role === 100;
      }
      return false;
    },
    adminUsersCount() {
      if (this.userGroups) {
        return this.userGroups[0]?.users?.length;
      }
      return 0;
    },
    isWaterfall() {
      return this.board.type === WATERFALL;
    },
    isAgile() {
      return this.board.type === AGILE;
    },
    teamOptions() {
      const list = this.$store.getters['teams/getTeams'].map(e => {
        return { value: e.id, label: e.name };
      });
      return [{ value: 0, label: 'ไม่ระบุ' }, ...list];
    },
    defaultImageSrc() {
      return `https://storage.googleapis.com/workkami-files-dev/statics/f6dd2895acbe4cf4b6d2efa5f279855f_thumb.png`;
    },
    activityDates() {
      return this.groupItemByCreatedAt(this.activities);
    },
    adminTotal() {
      if (this.userGroups) {
        return this.userGroups.filter(
          g => g.title == 'ADMIN' || g.title == 'ผู้ดูแล'
        )[0]?.users.length;
      }
      return 0;
    }
  },
  mixins: [LoadingMixin, MarkdownTextMixin, BoardUtils, DateTime],
  watch: {
    getMembers() {
      this.fetchMember();
    },
    memberQ() {
      this.fetchMember();
    },
    isShowDetail: {
      immediate: true,
      handler() {
        this.toggleBodyScroll();
      }
    },
    isFocusTitle(val) {
      if (val) {
        this.$nextTick(() => {
          this.focusEditBoard();
        });
      }
    },
    'board.currentMember.isMute': {
      handler(value) {
        this.isNotificationOn = !value;
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('members', ['fetchMembers']),
    ...mapActions('boards', ['deleteBoard', 'editBoard']),
    ...mapActions({
      fetchTasks: 'tasks/fetchTasks'
    }),
    changeTab(tab) {
      this.tabIndex = tab.value;
      this.isCreateLevel = false;
    },
    loadMoreMembers() {
      const isMemberTab = this.tabIndex === 0;
      if (!this.getLoading && isMemberTab) {
        this.fetchMembers();
      }
    },
    focusEditBoard() {
      this.$refs.autofocus.$refs.textareaInput.$el.focus();
    },

    async changeTeam(id) {
      await this.$store.dispatch('boards/editBoard', {
        ...this.board,
        teamID: id
      });
      this.teamID = id;

      this.$store.dispatch('boards/fetchBoards');
    },
    fetchMember() {
      const self = this;
      const userGroups = [];

      let allMembers = [];
      if (this.memberQ) {
        allMembers = this.getMembers.filter(e => {
          if (e.user.name.toLowerCase().search(self.memberQ.toLowerCase()) > -1)
            return true;
          else return false;
        });
      } else {
        allMembers = this.getMembers;
      }
      const adminUsers = allMembers.length
        ? allMembers.filter(e => e.role == 100)
        : [];
      if (adminUsers.length)
        userGroups.push({
          title: this.$t('boardSetting.setting.admin'),
          users: adminUsers
        });

      const editorUsers = allMembers.length
        ? allMembers.filter(e => e.role == 2)
        : [];
      if (editorUsers.length)
        userGroups.push({
          title: this.$t('boardSetting.setting.editor'),
          users: editorUsers
        });

      const viewUsers = allMembers.length
        ? allMembers.filter(e => e.role == 1)
        : [];
      if (viewUsers.length)
        userGroups.push({
          title: this.$t('boardSetting.setting.viewer'),
          users: viewUsers
        });

      this.userGroups = userGroups;
    },
    onKickMember(member) {
      const boardName = this.board.name || '';
      const memberName = member.user?.name || member.inviteToEmail;
      this.$modal.show(
        DialogConfirm,
        {
          title: member.isCurrentUser
            ? this.$t('invite.alert.leave.name')
            : this.$t('invite.alert.remove.name'),
          description: member.isCurrentUser
            ? `${this.$t(
                'invite.alert.leave.title1'
              )} <strong>${boardName}</strong>?<br/>${this.$t(
                'invite.alert.leave.title2'
              )}`
            : `
          ${this.$t(
            'invite.alert.remove.title1'
          )} "<strong>${memberName}</strong>"? `,
          color: 'text-red-600',
          button: member.isCurrentUser
            ? this.$t('invite.alert.leave.button')
            : this.$t('invite.alert.remove.button'),
          confirmAction: 'members/kickMember',
          confirmData: member
        },
        {
          height: 'auto',
          width: 440
        }
      );
    },
    async scrollBottom() {
      if (this.tabIndex == 0) {
        this.fetchMembers();
      } else if (this.tabIndex == 1) {
        if (this.isArchivedItems) {
          // items
          this.fetchArchived(2);
        } else {
          this.fetchArchived(1);
        }
      } else if (this.tabIndex == 2) {
        if (this.activitiesPage.page < this.activitiesPage.lastPage) {
          // fetch activities
          const { data } = await boardsApi.logs(this.board.id, {
            page: this.activitiesPage.page + 1,
            size: 20
          });
          this.activities = this.activities.concat(data.entities);
          this.activitiesPage = {
            page: data.pageInformation.page,
            lastPage: data.pageInformation.lastPage
          };
        }
      }
    },
    confirmArchivedBoard(boardId) {
      this.showModal({
        boardId,
        title: this.$t('boardSetting.alert.archived.name'),
        description: this.$t('boardSetting.alert.archived.title'),
        button: this.$t('boardSetting.alert.archived.button'),
        color: 'text-red-600',
        confirmAction: 'boards/archiveBoard',
        confirmData: boardId,
        confirmCallback: () => {
          this.$router.push({
            name: 'Boards-List'
          });
        }
      });
    },
    confirmDeleteBoard(boardId) {
      this.showModal({
        boardId,
        title: this.$t('boardSetting.alert.deleteBoard.name'),
        description: this.$t('boardSetting.alert.deleteBoard.title'),
        button: this.$t('boardSetting.alert.deleteBoard.button'),
        color: 'text-red-600',
        confirmAction: 'boards/deleteBoard',
        confirmData: boardId,
        confirmCallback: () => {
          this.$router.push({
            name: 'Boards-List'
          });
        }
      });
    },
    notificationBoard() {
      this.$modal.show('notification-board');
    },
    async notificationChange() {
      await this.$store.dispatch('boards/editBoard', {
        id: this.board.id,
        isMute: !this.isNotificationOn
      });
    },
    async fetchArchived(type) {
      if (type == 1) {
        if (
          this.archivedColumnsPage.page + 1 >
          this.archivedColumnsPage.lastPage
        )
          return;
        const response = await boardsApi.archivedColumns(this.board.id, {
          page: this.archivedColumnsPage.page + 1
        });
        this.archivedColumns =
          this.archivedColumnsPage.page > 0
            ? this.archivedColumns.concat(response.data.entities)
            : response.data.entities;
        this.archivedColumnsOrg = this.archivedColumns;
        this.archivedColumnsPage = {
          page: response.data.pageInformation.page,
          lastPage: response.data.pageInformation.lastPage
            ? response.data.pageInformation.lastPage
            : 1
        };
      } else {
        if (this.archivedItemsPage.page + 1 > this.archivedItemsPage.lastPage)
          return;

        const response = await boardsApi.archivedItems(this.board.id, {
          page: this.archivedItemsPage.page + 1
        });
        this.archivedItems =
          this.archivedItemsPage.page > 0
            ? this.archivedItems.concat(response.data.entities)
            : response.data.entities;
        this.archivedItemsOrg = this.archivedItems;
        this.archivedItemsPage = {
          page: response.data.pageInformation.page,
          lastPage: response.data.pageInformation.lastPage
            ? response.data.pageInformation.lastPage
            : 1
        };
      }
    },
    onEnterDescription() {
      this.$refs['description-setting'].blur();
    },
    async show() {
      this.title = this.board.name;
      this.description = this.board.description;
      this.fetchMember();

      this.archivedColumnsPage.page = 0;
      this.archivedItemsPage.page = 0;
      await this.fetchArchived(1);
      await this.fetchArchived(2);

      this.isShowDetail = true;

      // fetch activities
      const { data } = await boardsApi.logs(this.board.id, {
        page: 1,
        size: 20
      });
      this.activities = data.entities;
      this.activitiesPage = {
        page: data.pageInformation.page,
        lastPage: data.pageInformation.lastPage
      };
    },
    hide(e) {
      if (!e.target.classList.contains('board-detail')) {
        return;
      }
      this.isShowDetail = false;
    },
    archivedItemsQChange() {
      const self = this;
      if (self.archivedItemsQ) {
        self.archivedItems = self.archivedItemsOrg.filter(
          item =>
            item.name
              .toUpperCase()
              .indexOf(self.archivedItemsQ.toUpperCase()) >= 0
        );
      } else {
        self.archivedItems = self.archivedItemsOrg;
      }
    },
    archivedColumnQChange() {
      const self = this;
      if (self.archivedColumnQ) {
        self.archivedColumns = self.archivedColumnsOrg.filter(
          item =>
            item.name
              .toUpperCase()
              .indexOf(self.archivedColumnQ.toUpperCase()) >= 0
        );
      } else {
        self.archivedColumns = self.archivedColumnsOrg;
      }
    },
    async toggleArchived() {
      this.isArchivedItems = !this.isArchivedItems;
      // if (this.isArchivedItems) await this.fetchArchived(2);
      // else await this.fetchArchived(1);
      this.archivedItemsQ = '';
      this.archivedColumnQ = '';

      this.archivedItemsQChange();
      this.archivedColumnQChange();
    },
    async archivedColumnUnarchivedClick(column) {
      await boardsApi.unarchivedColumn(this.board.id, column.id);
      this.archivedColumnsPage.page = 0;
      await this.fetchArchived(1);
    },
    async archivedItemUnarchivedClick(taskId) {
      await boardsApi.unarchivedItem(taskId);
      this.archivedItemsPage.page = 0;
      await this.fetchArchived(2);
      this.$store.dispatch('board-column/fetchColumns');
    },
    async archivedItemDeleteClick(taskId) {
      const self = this;
      this.showModal({
        taskId,
        title: this.$t('boardSetting.alert.deleteTask.name'),
        description: this.$t('boardSetting.alert.deleteTask.title'),
        button: this.$t('boardSetting.alert.deleteTask.button'),
        color: 'text-red-600',
        confirmAction: 'tasks/removeTask',
        confirmData: taskId,
        confirmCallback: () => {
          self.archivedItemsPage.page = 0;
          self.fetchArchived(2);
        }
      });
    },
    showModal(data) {
      this.$modal.show(DialogConfirm, data, this.modalConfig);
    },
    async titleUpdate() {
      this.isFocusTitle = false;
      if (this.board.name == this.title) return;

      this.onLoading();
      await boardsApi.update(this.board.id, {
        name: this.title
      });
      EventBus.$emit('UPDATE_BOARD', {});
      this.offLoading();
    },
    cancelDescritption() {
      this.description = this.board.description;
      this.isFocusDescription = false;
    },
    async saveDescritption() {
      this.onLoading();
      await boardsApi.update(this.board.id, {
        description: this.description
      });
      EventBus.$emit('UPDATE_BOARD', {});
      this.offLoading();
      this.isFocusDescription = false;
    },
    async saveShortName(e) {
      this.onLoading();
      await boardsApi.update(this.board.id, {
        key: e
      });
      EventBus.$emit('UPDATE_BOARD', {});
      const fetchRequest = {
        ...this.fetchTasksInclusiveParams,
        boardID: this.board.id
      };
      await this.fetchTasks(fetchRequest);
      this.offLoading();
    },
    removeUser(u) {
      this.kickUser = u;
      const boardName = this.board.name || '';
      this.$modal.show(
        DialogConfirm,
        {
          title: u.isCurrentUser
            ? this.$t('boardSetting.alert.leave.name')
            : this.$t('boardSetting.alert.remove.name'),
          description: u.isCurrentUser
            ? `${this.$t(
                'boardSetting.alert.leave.title1'
              )} <strong>${boardName}</strong>? ${this.$t(
                'boardSetting.alert.leave.title2'
              )}`
            : `
          ${this.$t('boardSetting.alert.remove.title1')} "<strong>${
                u.user.name
              }</strong>"? `,
          color: 'text-red-600',
          button: u.isCurrentUser
            ? this.$t('boardSetting.alert.leave.button')
            : this.$t('boardSetting.alert.remove.button'),
          confirmAction: 'members/kickMember',
          confirmData: u
        },
        {
          height: 'auto',
          width: 440
        }
      );
    },
    toggleBodyScroll() {
      if (this.isShowDetail) document.body.classList.add('vm--block-scroll');
      else document.body.classList.remove('vm--block-scroll');
    },
    toggleCopyBoardModal(board) {
      this.$modal.show(ModalBoardCopy, { board });
    },
    countColumnTasks(column) {
      return this.archivedItems.filter(t => t.boardColumnID === column.id)
        .length;
    }
  }
};
</script>

<style>
.setting-tab .profile-tab li {
  padding: 0 0.7rem;
}
.setting-tab .profile-tab li.active::before {
  width: calc(100% - 1rem);
  margin: 0 0.5rem;
}
.bg-archived {
  @apply bg-gray-main;
}
.board-setting-content {
  min-height: calc(100% - 126px);
}
.board-detail.board-detail--fixed {
  @apply fixed;
}
.title-box {
  @apply break-words text-lg leading-tight;
  width: 347px;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.archive-box {
  @apply bg-white border border-gray-300 rounded-sm px-3 py-2 truncate mb-2;
}
</style>
