<template>
  <div>
    <base-input-text
      v-model="form.name"
      autofocus
      :placeholder="
        isCreateColumn
          ? $t('agile.create.createPlaceholder')
          : $t('agile.sitting.placeholderCreate')
      "
    />
    <base-select
      v-model="form.status"
      class="mt-4"
      :class="{ 'bg-white': isCreateColumn }"
      full-width
      custom-item
      option-classes="text-sm my-2"
      :options="options"
    >
      <template #toggle="{ active }">
        <info-dot-agile-status :status="active.value" class="mr-2" />
        {{ active.label }}
      </template>
      <template #label="{ props }">
        <info-dot-agile-status :status="props.value" class="mr-2" />
        {{ props.label }}
      </template>
    </base-select>
    <layout-confirm
      class="mt-4"
      :wide="isCreateColumn ? 'px-6 py-2' : 'px-8 py-2'"
      right
      :disabled="!form.name.trim()"
      :confirm-text="
        isCreateColumn ? $t('agile.create.create') : $t('agile.create.save')
      "
      :cancel-text="$t('agile.create.cancel')"
      @confirm="$emit('confirm')"
      @cancel="$emit('cancel')"
    />
  </div>
</template>

<script>
import InfoDotAgileStatus from '../info/info-dot-agile-status.vue';
import layoutConfirm from '../layout/layout-confirm.vue';
export default {
  components: { layoutConfirm, InfoDotAgileStatus },
  props: {
    value: {
      type: Object,
      default() {
        return {
          name: '',
          status: 0
        };
      }
    },
    isCreateColumn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    options() {
      return this.$t('boardSetting.column.statusTypes').map((label, value) => ({
        label,
        value
      }));
    }
  }
};
</script>

<style></style>
