<template>
  <div>
    <div
      v-for="(timeValue, index) in planUnplanArray"
      :key="planUnplanOrder[index]"
      class="mt-3 flex flex-wrap"
    >
      <div class="pr-4 w-3/4">
        <bar-progress
          :value="timeValue"
          :maximum="totalTime"
          :color="planUnplanColor[index]"
        />
      </div>
      <p class="text-sm whitespace-no-wrap">
        {{ formatHourMinuteFromMinutes(timeValue) }}
      </p>
    </div>
  </div>
</template>

<script>
import BarProgress from '@/components/bar-progress';
import DateTime from '@/mixins/DateTimeMixin';

const PLAN_COLOR = '#168AE2';
const UNPLAN_COLOR = '#A2AEC2';

export default {
  mixins: [DateTime],
  components: {
    BarProgress
  },
  props: {
    planUnplan: {
      type: Object,
      default() {
        return {
          plan: 0,
          unplan: 0
        };
      }
    }
  },
  computed: {
    totalTime() {
      const { plan, unplan } = this.planUnplan;
      return plan + unplan;
    },
    planUnplanColor() {
      return [PLAN_COLOR, UNPLAN_COLOR];
    },
    planUnplanOrder() {
      return ['plan', 'unplan'];
    },
    planUnplanArray() {
      return this.planUnplanOrder.map(key => this.planUnplan[key]);
    }
  }
};
</script>

<style></style>
