










































































































































import Vue, { PropType } from 'vue';
import VClamp from 'vue-clamp';
import ConfirmModal from '@/components/board/board-dialog-confirm.vue';
import { Task, TaskStatus, TaskAssign } from '@/types/Task';
import IconSplit from '@/components/icons/IconSplit.vue';
import IconSeen from '@/components/icons/IconSeen.vue';
import IconUnseen from '@/components/icons/IconUnseen.vue';
import IconUnwatched from '@/components/icons/IconUnwatched.vue';
import IconMark from '@/components/icons/IconMark.vue';
import { User } from '@/types/User';
import SelectPriority from '../select/select-priority.vue';
import { mapGetters } from 'vuex';

interface TaskUpdate {
  description?: string;
  endAt?: Date | string;
  name?: string;
  newColumnID?: number;
  position?: number;
  startAt?: Date | string;
  status?: number;
  priority?: number;
}

export default Vue.extend({
  components: {
    VClamp,
    IconSplit,
    IconSeen,
    IconUnseen,
    IconUnwatched,
    IconMark,
    SelectPriority
  },
  props: {
    task: {
      type: Object as PropType<Task>,
      required: true
    },
    disabled: Boolean,
    format: {
      type: Number,
      default: 2
    },
    shareDisabled: Boolean
  },
  computed: {
    ...mapGetters({
      isUserViewer: 'boards/isUserViewer',
      isViewOnly: 'boards/isViewOnly'
    }),
    isTaskViewOnly(): boolean {
      return this.isUserViewer || this.isViewOnly;
    },
    isCompleted(): boolean {
      return this.task.status === TaskStatus.Done;
    },
    assigned(): Array<TaskAssign> {
      if (this.task.assigns) {
        return this.task.assigns;
      }
      return [];
    },

    watched(): Array<User> {
      if (this.assigned) {
        return this.assigned.filter(e => e.unread == false).map(e => e.user);
      }
      return [];
    },
    priority(): number {
      return this.task.priority;
    },
    privacyIcon(): string {
      if (!this.task) return '';
      return ['', 'public-user', 'lock'][this.task.privacy];
    }
  },
  methods: {
    change(ev: TaskUpdate) {
      this.$emit('change', ev);
    },
    toggleTaskComplete() {
      if (!this.isCompleted) {
        this.$fana.logEvent('task/toggle_done');
      }
      const status =
        this.task.status == TaskStatus.Done
          ? TaskStatus.InComplete
          : TaskStatus.Done;
      this.change({
        status: status
      });
    },
    showModalShareTask() {
      this.$modal.show('share-task', { defaultValue: this.task.privacy });
    },
    promptArchive() {
      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('alert.archivedTask.name'),
          description: this.$t('alert.archivedTask.title'),
          button: this.$t('alert.archivedTask.button'),
          confirmAction: 'tasks/archiveTask',
          confirmData: this.$store.getters['tasks/getCurrentTask'].id,
          onConfirm: this.closeTask
        },
        {
          height: 'auto',
          maxWidth: 320,
          adaptive: true
        }
      );
    },
    setPriority(priority: number) {
      if (priority != this.priority) {
        this.change({
          priority: priority
        });
      }
    },
    promptDelete() {
      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('alert.deleteTask.name'),
          description: this.$t('alert.deleteTask.title'),
          button: this.$t('alert.deleteTask.button'),
          confirmAction: 'tasks/removeTask',
          confirmData: this.$store.getters['tasks/getCurrentTask'].id,
          onConfirm: this.closeTask
        },
        {
          height: 'auto',
          maxWidth: 320,
          adaptive: true
        }
      );
    },
    closeTask() {
      this.$emit('close');
    },
    trackWatchPerson() {
      this.$fana.logEvent('task/view_watched');
    }
  }
});
