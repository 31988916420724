import { Module } from 'vuex';
import { RootState } from '@/types/Store';

import { version, latestUpdate } from '../../../package.json';

interface State {
  number: string;
  latestUpdate: string;
}

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    number: version,
    latestUpdate: latestUpdate
  },
  getters: {
    getVersion: s => s,
    getNumber: s => s.number
  },
  mutations: {},
  actions: {}
};

export default module;
