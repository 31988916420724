









































































































import Vue, { PropType, VueConstructor } from 'vue';
import moment from 'moment';
import NumberUtils from '@/mixins/NumberUtilsMixin';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import HeaderTextClamp from '@/components/header-text-clamp.vue';

import { TimeLog } from '@/types/TimeLog';
import { Board } from '@/types/Board';
import { Task } from '@/types/Task';

type WithMixins = VueConstructor<Vue & InstanceType<typeof NumberUtils>>;

export default (Vue as WithMixins).extend({
  components: { HeaderTextClamp },
  mixins: [NumberUtils],
  props: {
    log: {
      type: Object as PropType<TimeLog>,
      required: true
    },
    menuPosition: {
      type: String
    }
  },

  computed: {
    boardTaskName(): string {
      const getName = (boardTask: Board | Task | undefined) => boardTask?.name;
      return [this.log.board, this.log.task]
        .filter(e => e)
        .map(getName)
        .join(' - ');
    },
    board() {
      return this.log.board;
    },
    task() {
      return this.log.task;
    },
    breakAllReg() {
      return /\S/;
    },
    timelogEditModal() {
      return {
        name: 'timelog-edit',
        height: 'auto',
        adaptive: true,
        width: '100%',
        maxWidth: 660,
        scrollable: true
      };
    }
  },

  methods: {
    // filters
    getTime(date: string): string {
      return moment(date)
        .locale(`${this.$t('notifications.language')}`)
        .format('HH:mm');
    },
    getTimeSpent(minutes: number): string {
      const HOUR_MINS = 60;
      const DAY_HOURS = 24 * HOUR_MINS;
      const days = Math.floor(minutes / DAY_HOURS);
      const hrs = Math.floor((minutes % DAY_HOURS) / HOUR_MINS);
      const mins = minutes % 60;
      const defaultDisplay = '0 min';

      return (
        [
          { val: days, unit: `${this.$t('timelog.time.day')}` },
          { val: hrs, unit: `${this.$t('timelog.time.h')}` },
          { val: mins, unit: `${this.$t('timelog.time.min')}` }
        ]
          .filter(e => e.val)
          .map(e => this.getNumberToStringWithUnit(e.val, e.unit))
          .join(' ') || defaultDisplay
      );
    },
    // modal confirm
    toggleDelete() {
      this.$modal.show(
        DialogConfirm,
        {
          title: this.$t('timelog.button.action.delete.title'),
          description: this.$t('timelog.button.action.delete.description'),
          confirmAction: 'time-log/deleteTimelog',
          button: this.$t('timelog.confirm'),
          confirmData: this.log.id
        },
        {
          height: 'auto',
          maxWidth: 320,
          adaptive: true
        }
      );
    },
    showEditModal(timelog: TimeLog) {
      this.$store.commit('time-log/SET_EDIT_TIMELOG', timelog);
      this.$modal.show('timelog-edit');
      // this.$modal.show(EditForm, {}, this.timelogEditModal);
    }
  }
});
