





























































































































































import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Team, TeamAdd, TeamType } from '@/types/Team';
import { Folder } from '@/types/Folder';
import { FileUpload } from '@/types/File';
import { BaseSelectOption } from '@/types/BaseComponents';
import IconCamera from '@/components/icons/IconCamera.vue';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import IconTeamImage from '@/components/icons/IconTeamImage.vue';

import { filesApi, teamsApi } from '@/services/apis';

export default Vue.extend({
  props: {
    loading: Boolean,
    defaultFolder: {
      type: Object as PropType<Folder | undefined>
    }
  },
  components: { IconCamera, IconTeamImage },
  data() {
    return {
      formName: '',
      formType: 0,
      formDescription: '',
      formWebSite: '',
      formImageLogo: '',
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('folders', ['getFolders', 'getNoFolder']),
    noFolder(): Folder {
      return this.getNoFolder;
    },
    typeOptions(): BaseSelectOption[] {
      return this.types.map(e => {
        return {
          label: this.$t('lang') == 'en' ? e.valueEN : e.valueTh,
          value: e.type
        };
      });
    },
    types(): TeamType[] {
      return this.$store.getters['teams/getTypes'];
    },
    disabledNext(): boolean {
      return !this.formName;
    },
    team(): Team {
      return this.$store.getters['teams/getTeam'];
    },
    isChange(): boolean {
      if (this.formName != this.team.name) return true;
      else if (this.formType != this.team.teamType?.type) return true;
      else if (this.formDescription != this.team.description) return true;
      else if (this.formImageLogo != this.team.imageLogo) return true;
      else if (this.formWebSite != this.team.webSite) return true;
      return false;
    }
  },
  mounted() {
    const team = this.team;
    this.formName = team.name;
    this.formType = team.teamType?.type ? team.teamType?.type : 0;
    this.formDescription = team.description;
    this.formWebSite = team.webSite;
    this.formImageLogo = team.imageLogo;

    this.$store.dispatch('teams/fetchTypes');
  },
  watch: {},
  methods: {
    ...mapActions('folders', ['fetchFolders']),
    cancelClick() {
      this.$modal.hide('update-team');
    },
    async saveClick() {
      if (!this.isChange) return;

      this.isLoading = true;
      const form: TeamAdd = {
        name: this.formName,
        type: this.formType,
        description: this.formDescription,
        webSite: this.formWebSite,
        imageLogo: this.formImageLogo
      };
      await this.$store.dispatch('teams/updateTeam', form);
      this.$modal.hide('update-team');
      this.$store.dispatch('subscription/fetchMyWorkspace');
      this.isLoading = false;
    },

    async browsePhoto(files: FileList) {
      this.isLoading = true;
      if (files && files.length) {
        const { data } = await filesApi.upload('teams', files);
        if (data.length) {
          const img = data[0] as FileUpload;
          this.formImageLogo = img.thumbnailImageUrl as string;
        }
      }
      this.isLoading = false;
    },
    deleteTeamClick() {
      this.$modal.show(
        DialogConfirm,
        {
          title: this.$t('team.buttonConfirm.delete.titledeal'),
          description: this.$t('team.buttonConfirm.delete.description'),
          color: 'text-red-600',
          button: this.$t('team.buttonConfirm.delete.name'),
          onConfirm: async () => {
            this.$store.dispatch('teams/delete');
          },
          confirmData: this.team
        },
        {
          height: 'auto',
          width: 440
        }
      );
    }
  }
});
