<template>
  <div class="flex ">
    <div class="flex-1">
      <div class="">
        <div class="text-lg font-medium">{{ $t('landing.nav.login') }}</div>
        <div class="mt-2">
          <facebook-login
            :disabled="loading"
            @click="
              loading = true;
              $emit('loading', true);
            "
            @login="submit"
            @error="onProviderError"
            @loginFail="$emit('loginFail')"
          />
        </div>
        <div class="mt-2">
          <google-login
            :disabled="loading"
            @click="
              loading = true;
              $emit('loading', true);
            "
            @login="submit"
            @error="onProviderError"
          />
        </div>
        <div class="or-dash mt-3">{{ $t('login.or') }}</div>

        <div class="mt-4 mb-0">
          <form :disabled="loading" class="mb-0" @submit.prevent="submit()">
            <div>
              <div class="mb-3">
                <div>{{ $t('login.email') }}</div>
                <base-input-text
                  v-model="email"
                  :disabled="loading"
                  type="email"
                  :placeholder="$t('login.email')"
                  name="email"
                  data-testid="email-input"
                />
              </div>
              <div class="mb-3">
                <div>{{ $t('login.password') }}</div>
                <base-input-password
                  v-model="password"
                  :disabled="loading"
                  type="password"
                  :placeholder="$t('login.password')"
                  data-testid="password-input"
                />
              </div>
              <div class="mb-3">
                <div class="flex">
                  <div class="flex-grow text-right">
                    <router-link
                      class="text-gray-500 text-sm"
                      :to="`/${$i18n.locale}/forgot`"
                      data-testid="forgot-link"
                    >
                      {{ $t('login.forget') }} ?
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="error" class="mt-2 login-message--error">
              {{ error }}
            </p>
            <div class="mt-6 pb-4 sm:py-0">
              <base-button
                full
                color="brand"
                type="submit"
                data-testid="login-btn"
              >
                {{ $t('login.name') }}
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacebookLogin from '../components/facebook-login';
import GoogleLogin from '../components/google-login';
import LoadingMixin from '@/mixins/LoadingMixin';

import firebase from '@/services/firebase';
import { mapActions } from 'vuex';

export default {
  components: { FacebookLogin, GoogleLogin },
  mixins: [LoadingMixin],
  name: 'Login',
  data() {
    return {
      loginType: 1,
      email: '',
      password: '',
      error: '',

      sliderIndex: 0
    };
  },
  mounted() {
    if (this.$route.query.error) {
      this.error = this.$route.query.error;
    }

    if (!this.$route.query.code)
      this.$store.commit(
        'auth/SET_TO',
        this.$route.query.to ? this.$route.query.to : ''
      );
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    async submit(payload = {}) {
      this.onLoading();
      const { loginType = 1, idToken, email = this.email } = payload;
      const loginRequest = {
        loginType,
        idToken,
        email,
        password: this.password
      };
      let isFirebaseSuccess = false;
      try {
        await firebase.auth().signInAnonymously();
        isFirebaseSuccess = true;
        await this.login(loginRequest);

        const to = this.$store.getters['auth/getTo'];
        this.$router.replace(to || { name: 'Boards-List' });
      } catch (error) {
        const defaultError = this.$t('fail.try_again', {
          do: this.$t('login.name').toLowerCase()
        });
        if (isFirebaseSuccess) {
          await firebase.auth().signOut();
          isFirebaseSuccess = false;
        }
        this.error = error?.response?.data?.message || defaultError;
        this.offLoading();
      }
    },
    onProviderError(error) {
      console.log(error);
      // this.error = error?.message;
      this.offLoading();
    }
  }
};
</script>

<style scoped>
.login-message--error:not(:empty) {
  @apply px-4 py-2 bg-red-300 text-red-700 rounded mb-3;
}

.or-dash {
  position: relative;
  text-align: center;
  color: #c4c4c4;
  font-size: 14px;
}
.or-dash::before {
  content: '';
  position: absolute;
  left: 0;
  top: 12px;
  width: calc(50% - 18px);
  height: 1px;
  background: #ececec;
}
.or-dash::after {
  content: '';
  position: absolute;
  right: 0;
  top: 12px;
  width: calc(50% - 18px);
  height: 1px;
  background: #ececec;
}
</style>
