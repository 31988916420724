<template>
  <base-input-text
    v-model="numberValue"
    v-bind="$attrs"
    v-on="$listeners"
    @keypress="onlyNumber"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
      validator(value) {
        return !isNaN(value) || value === '';
      }
    }
  },
  computed: {
    numberValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    }
  }
};
</script>

<style></style>
