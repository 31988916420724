<template>
  <div>
    <div class="flex divide-x -mx-4">
      <div v-if="isClearVisible" class="px-4">
        <base-button
          wide="py-2 px-4"
          class="text-brand-blue bg-white border-brand-blue flex items-center"
          color="transparent"
          @click="clearFilters"
        >
          <span class="mr-2">
            {{ countTasks }}
            {{ $t('agile.boardComplete.result') }}</span
          >
          <icon-x />
        </base-button>
      </div>

      <div v-if="getShowFliter" class="flex px-4 space-x-3">
        <select-tags v-model="fetchTasksTags">
          <base-button color="transparent" wide="py-2 px-4">
            {{ $t('agile.backlog.tag') }}
            <span v-if="countActiveTags">
              :
              <span class="rounded-full px-3 badge--brand text-sm">
                {{ countActiveTags }}
              </span>
            </span>
            <base-icon class="ml-1" icon="chevron-down" size="14" />
          </base-button>
        </select-tags>

        <board-members />
      </div>
      <div class="flex-shrink-0 md:px-4">
        <div class="flex -mx-2">
          <div class="px-2 flex">
            <!-- <span
              class="inline-flex items-center text-black border rounded px-2 hover:bg-light-gray hover--dark cursor-pointer"
            >
            </span> -->
            <timelog-timer
              class="max-w-button"
              responsive
              :hide-title="isBoardActiveTimelog"
              :timelog="timelog"
              :start-options="{
                boardID: board.id
              }"
              :start-button="{
                faIcon: 'play',
                color: 'transparent',
                wide: 'pl-3 py-2'
              }"
            />
          </div>
          <div v-if="invite">
            <base-button
              wide="py-2 px-1 md:px-2"
              icon="UserPlus"
              responsive
              class="text-brand-blue bg-white border-brand-blue"
              color="transparent"
              @click="openMemberInvite"
            >
              {{ $t('myBoard.invite') }}
            </base-button>
          </div>
          <div v-if="board.team" class="px-1 md:px-2">
            <div
              class="text-black bg-white py-2 px-3 border rounded truncate no-drop"
              style="max-width: 220px;"
            >
              <base-icon
                color="#555"
                view-box="0 0 15 15"
                :width="18"
                :height="21"
                class="mr-1"
              >
                <icon-users />
              </base-icon>
              {{ $t('subscription.workspace') }}: {{ board.team.name }}
            </div>
          </div>

          <div class="px-1 md:px-2">
            <base-button
              wide="max-w-button"
              icon="BoardSetting"
              responsive
              class="text-black bg-white"
              color="transparent"
              @click="$refs.headerSetting.show()"
            >
              {{ $t('myBoard.setting') }}
            </base-button>
          </div>
        </div>
      </div>
    </div>

    <board-header-setting ref="headerSetting" :board="board" />
    <modal-member-invite />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ModalMemberInvite from '@/components/modal/modal-member-invite';
import BoardHeaderSetting from '@/components/board/board-header-setting';
import TimelogTimer from '../timelog/timelog-timer.vue';
import BoardMembers from './board-members.vue';
import IconUsers from '@/components/icons/IconUsers';
import IconX from '@/components/icons/IconX';
import SelectTags from '../select/select-tags';
import FetchTasks from '@/mixins/FetchTasks';
export default {
  mixins: [FetchTasks],
  components: {
    BoardHeaderSetting,
    ModalMemberInvite,
    TimelogTimer,
    BoardMembers,
    IconUsers,
    IconX,
    SelectTags
  },
  props: {
    board: {
      type: Object
    },
    invite: Boolean
  },
  data() {
    return {
      selectTypeReport: [
        { label: 'planning', value: 1 },
        { label: 'progress', value: 2 }
      ]
    };
  },

  computed: {
    ...mapState(['memberVisible']),
    ...mapGetters({
      timelog: 'time-log/getWorkingLog',
      teams: 'teams/getTeams'
    }),
    isBoardActiveTimelog() {
      return !this.timelog?.taskId && this.board?.id === this.timelog?.boardId;
    },
    userFilterIds() {
      return this.$store.getters['members/getFilterIds'];
    },
    isClearVisible() {
      return this.memberVisible && this.shouldShowSearchResult;
    },
    countTasks() {
      const columns = this.$store.getters['tasks/getTaskByColumns'];
      let count = 0;
      Object.keys(columns).forEach(k => {
        count += columns[k].length;
      });
      return count;
    },
    countActiveTags() {
      return this.fetchTasksTags.length;
    },
    isVisible() {
      return [
        'export-report',
        'Board-Sprint',
        'Board-Columns',
        'Boards-Id'
      ].every(name => name !== this.$route.name);
    },
    getShowFliter() {
      const WATERFALL = 1;
      return this.board.type === WATERFALL;
    }
  },
  methods: {
    openMemberInvite() {
      this.$modal.show('invite-member');
    }
  }
};
</script>
<style></style>
