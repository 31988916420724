<template>
  <div>
    <notifications
      class="mt-2"
      group="card-payment"
      classes="vue-notification text-base leading-tight"
    />
    <div v-if="loading" class="dark-overlay">
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube" />
        <div class="sk-cube2 sk-cube" />
        <div class="sk-cube4 sk-cube" />
        <div class="sk-cube3 sk-cube" />
      </div>
    </div>
    <div class="border-b relative">
      <p class="p-4 text-xl">{{ $t('subscription.paymentText') }}</p>
      <base-button
        data-testid="close-btn"
        class="absolute top-0 right-0 mt-3 mr-2"
        icon="close"
        icon-size="18"
        color="text"
        @click="closeModal"
      />
    </div>
    <div
      class="p-5 grid grid-cols-1 gap-x-8"
      :class="{ 'grid-cols-2': getPlanChange.length === 0 }"
    >
      <div v-if="getPlanChange.length === 0">
        <div v-if="getCardDefault">
          <p class="font-semibold mb-2">{{ $t('payment_method') }}</p>
          <paymentCardDefault class="mb-4 h-20" />
          <div
            v-if="!showFormCard"
            class="add_card"
            @click="showFormCard = !showFormCard"
          >
            <base-icon>
              <icon-plus />
            </base-icon>
            <p>{{ $t('add_card') }}</p>
          </div>
          <payment-card-form
            v-else
            ref="cardForm"
            is-add-card
            @emptyCard="checkCard"
            @cancel="showFormCard = !showFormCard"
          />
        </div>
        <payment-card-form v-else ref="cardForm" @emptyCard="checkCard" />
      </div>
      <div>
        <payment-summary
          ref="summary"
          :is-renew="isRenew"
          @close="closeModal"
          @seatSelect="seatSelected"
        />
        <div class="col-start-2">
          <div class="text-sm text-gray-caption flex items-center">
            <div @click="checkAccept = !checkAccept">
              <base-checkbox :checked="checkAccept" />
            </div>
            {{ $t('subscription.payment.summary.accept') }}
          </div>
          <div class="flex mt-4">
            <base-button class="ml-auto" color="white" @click="$emit('close')">
              {{ $t('subscription.payment.cancel') }}
            </base-button>
            <base-button :disabled="!isCanConfirm" @click="confrimPayment">
              {{ $t('subscription.payment.proceed') }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="http://code.jquery.com/jquery-1.12.1.min.js"></script>
<script src="https://cdn.omise.co/omise.js"></script>
<script>
import paymentCardForm from '@/components/payment/payment-card-form.vue';
import paymentSummary from '@/components/payment/payment-summary.vue';
import { mapGetters } from 'vuex';
import paymentCardDefault from '@/components/payment/payment-card-default';
import IconPlus from '@/components/icons/IconPlus.vue';
import modalSubscriptionConfirm from '@/components/modal/modal-subscription-confirm';
import { PlanStatus } from '@/types/Subscription.ts';

export default {
  props: {
    isRenew: { type: Boolean, default: false }
  },
  components: {
    paymentCardForm,
    paymentSummary,
    paymentCardDefault,
    IconPlus,
    modalSubscriptionConfirm
  },
  data() {
    return {
      loading: false,
      checkAccept: false,
      showFormCard: false,
      isSuscriptionPlan: false,
      numberSeat: 5,
      inputCard: false
    };
  },
  computed: {
    ...mapGetters('subscription', [
      'getCardDefault',
      'getPlanChange',
      'getPlanPreview',
      'getCurrentPlan'
    ]),
    isCanConfirm() {
      const haveCard = this.getCardDefault !== undefined;
      const card = haveCard || this.inputCard;
      const noCharge = this.getCurrentPlan.payment?.status == PlanStatus.Active;
      return this.checkAccept && (card || noCharge);
    }
  },
  async created() {
    await this.$store.dispatch('subscription/fetchCards');
  },
  async mounted() {
    await this.getCardDefault;
  },
  watch: {
    getCardDefault(val) {
      this.isSuscriptionPlan ? this.subscriptionPlan() : '';
    }
  },
  methods: {
    checkCard(e) {
      this.inputCard = e;
    },

    seatSelected(e) {
      this.numberSeat = e.value;
    },
    closeModal() {
      this.$emit('close');
    },
    async confrimPayment() {
      this.loading = true;
      const cardForm = this.$refs.cardForm;
      let card;
      const group = 'card-payment';
      const updateSeat = this.getPlanChange[0]?.changeSeat
        ? this.getPlanChange[0]
        : this.getPlanPreview;
      if (updateSeat.changeSeat) {
        const group = 'change-seat';
        try {
          await this.$store.dispatch('subscription/increaseTotalSeats', {
            numberOfSeats: parseInt(this.getPlanChange[0].numberOfSeats)
          });
          this.$notify({
            text: 'แก้ไขที่นั่งสำเร็จ',
            type: 'success',
            group
          });
        } catch (error) {
          this.$notify({
            text: error.response.data.message,
            type: 'error',
            group
          });
        }

        this.$modal.hide('plan-seat');
        this.loading = false;
      } else {
        if (this.getPlanChange.length != 0) {
          this.getPlanChange.numberOfSeats = this.numberSeat;
          this.$store
            .dispatch('subscription/subscriptionChangePlan', this.getPlanChange)
            .then(response => {
              this.$modal.hide('plan-card');
              this.$modal.show('payment-success');
              this.$store.dispatch('subscription/fetchCurrentPlan');
            });
          this.closeModal();
        } else {
          if (this.getCardDefault) {
            card = this.getCardDefault;
            this.$modal.hide('plan-payment');
            this.subscriptionPlan();
          } else {
            const card = {
              name: cardForm.cardName,
              number: cardForm.realNumber,
              expiration_month: parseInt(cardForm.expiration_month),
              expiration_year: parseInt(cardForm.expiration_year),
              security_code: cardForm.cardCode
            };
            const self = this;

            await Omise.createToken('card', card, function(
              statusCode,
              response
            ) {
              if (statusCode == 200) {
                self.$store.dispatch('subscription/saveCards', response.id);
                self.isSuscriptionPlan = true;
              } else {
                self.loading = false;
                self.$modal.show(modalSubscriptionConfirm, {
                  title: 'เพิ่มการชำระเงินไม่สำเร็จ',
                  description:
                    'กรุณาตรวจสอบรายละเอียดการชำระเงิน<br/> หรือตรวจสอบบัญชีให้ถูกต้อง',
                  icon: 'IconAddCardFail'
                });
              }
            });
          }
        }
      }
    },
    async subscriptionPlan() {
      this.isSuscriptionPlan = false;
      await this.$store
        .dispatch('subscription/subscriptionPlanConfirm', {
          cardToken: this.getCardDefault.card_id,
          numberOfSeats: this.getPlanPreview.subscriptionOrder.numberOfSeats,
          planID: this.getPlanPreview.subscriptionOrder.planID
        })
        .then(
          response => {
            this.closeModal();
            this.$modal.hide('plan-card');
            this.$modal.hide('change-plan');
            this.$modal.show('payment-success');
            this.$store.dispatch('auth/fetchMe');
          },
          error => {
            this.closeModal();
          }
        );
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.add_card {
  @apply border border-dashed items-center justify-center
          text-gray-caption cursor-pointer h-20 flex text-sm;
}
</style>
<i18n>
{
  "en": {
    "payment_method": "Payment",
    "add_card": "Add card"
  },
  "th": {
    "payment_method": "วิธีการชำระเงิน",
    "add_card": "เพิ่มบัตร"
  }
}
</i18n>
