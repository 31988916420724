<template>
  <div class="text-center">
    <lottie-player
      v-if="!IconModal"
      :src="animation"
      background="transparent"
      speed="1"
      style="width: 180px; height: 180px;"
      autoplay
      class="mx-auto"
    />
    <base-icon v-else size="170">
      <component :is="IconModal" />
    </base-icon>
    <p class="text-xl mb-2">{{ title }}</p>
    <p class="text-sm text-gray-caption" v-html="description" />
    <base-button class="my-4 px-8" @click="$emit('close')">
      {{ button[0].name }}
    </base-button>
  </div>
</template>

<script>
import Vue, { PropType } from 'vue';
import Icon from '@/components/icons/IconAddCardFail';
export default Vue.extend({
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    animation: {
      type: String,
      default: ''
    },
    button: {
      type: Array,
      default: () => [{ icon: '', name: 'ปิด' }]
    }
  },
  data() {
    return {
      opened: false,
      loading: false,
      IconModal: null
    };
  },
  watch: {
    icon: {
      immediate: true,
      handler(icon) {
        if (icon) this.IconModal = () => import(`@/components/icons/${icon}`);
      }
    }
  },
  methods: {
    async onSumbit() {
      this.loading = true;

      this.loading = false;
    }
  }
});
</script>
