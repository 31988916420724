export enum Providers {
  EMAIL = 1,
  FACEBOOK,
  GOOGLE,
  APPLE,
  GUEST
}

export enum ShareCodeType {
  Board,
  Task
}

interface LoginCommonRequest {
  loginType: Providers;
}

export interface LoginWithUserRequest extends LoginCommonRequest {
  idToken: string;
  email: string;
  password: string;
}

export interface LoginWithGuestRequest extends LoginCommonRequest {
  shareCode: string;
  shareCodeType: ShareCodeType;
}

export type LoginRequest = LoginWithUserRequest | LoginWithGuestRequest;

export interface LoginResponse {
  accessToken: string;
  expirationSecond: number;
  expirationTime: string;
  refreshToken: string;
}

export interface RegisterRequest {
  accessToken: string;
  email: string;
  name: string;
  password: string;
  registerType: Providers;
}

export interface PasswordResetRequest {
  password: string;
  passwordToken: string;
}

export interface ValidateEmailRequest {
  code: string;
  email: string;
}
