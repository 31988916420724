































import Vue from 'vue';
import WorkspaceSettingOverview from '@/components/workspace/workspace-setting-overview.vue';
import WorkspaceSettingPayment from '@/components/workspace/workspace-setting-payment.vue';
import WorkspaceSettingSeat from '@/components/workspace/workspace-setting-seat.vue';
import WorkspaceDelete from '@/components/workspace/workspace-delete.vue';

export default Vue.extend({
  components: {
    WorkspaceSettingOverview,
    WorkspaceSettingPayment,
    WorkspaceSettingSeat,
    WorkspaceDelete
  },
  data() {
    return {
      email: '',
      loading: false,
      tabIndex: 0,
      tabs: [
        { label: this.$t('tab.overview'), component: 'setting-overview' },
        // { label: `Payment`, component: 'setting-payment' },
        { label: this.$t('tab.member'), component: 'setting-seat' },
        { label: this.$t('tab.delete'), component: 'delete' }
      ]
    };
  }
});
