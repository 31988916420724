<template>
  <div class="board-column">
    <div class="board-column__bg">
      <div class="p-2 relative">
        <div
          v-if="statusbar"
          class="absolute inset-x-0 top-0 rounded-t"
          style="height: 6px;"
          :class="statuColors[column.status]"
        />
        <div v-if="isEditingName" class="draggable-column--ignored">
          <base-input-text
            ref="nameInput"
            v-model="name"
            autofocus
            :placeholder="$t('myBoard.enter')"
            @keypress.enter="blurNameInput"
            @blur="submitName"
          />
        </div>
        <div v-else class="flex justify-between items-center space-x-2">
          <div
            class="flex-1 flex items-center overflow-hidden cursor-pointer"
            @click="editName"
          >
            <slot name="header" :classes="headerClasses" :column="column">
              <div :class="headerClasses">
                {{ column.name }}
              </div>
            </slot>
            <span
              v-if="totalTasks"
              class="font-semibold text-gray-700 flex-shrink-0"
              >/ {{ totalTasks }}</span
            >
          </div>

          <div class="flex items-center draggable-column--ignored">
            <base-button
              v-if="!isUserViewer && !isViewOnly"
              icon="plus"
              icon-size="18"
              wide="p-2"
              color="transparent"
              borderless
              @click="toggleAddingTask"
            />
            <base-dropdown
              v-if="!isUserViewer && !isViewOnly"
              width="small"
              position="bottom-right"
            >
              <base-button
                slot="toggle"
                color="transparent"
                borderless
                icon="dots-horizontal"
                icon-size="18"
                wide="p-2"
                class="colum-bottom__icon"
              />
              <base-dropdown-item @click="confirmArchiveColumn">
                {{ $t('myBoard.alert.archived.button') }}
              </base-dropdown-item>
            </base-dropdown>
          </div>
        </div>
      </div>
      <board-column-tasks
        :adding-active="isAddingTask"
        :tasks="tasks"
        @submit="submitAddTask"
        @drag="drag"
        @add-cancel="toggleAddingTask"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import RecalculatePosition from '@/mixins/RecalculatePosition';
import DialogConfirm from '@/components/board/board-dialog-confirm';
import BoardColumnTasks from './board-column-tasks';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'tasks'
);

export default {
  mixins: [RecalculatePosition],
  components: { BoardColumnTasks },
  inject: {
    statusbar: {
      default: false
    }
  },
  props: {
    column: {
      type: Object,
      default: () => ({
        name: ''
      })
    }
  },
  data() {
    return {
      isAddingTask: false,
      isEditingName: false,
      name: '',
      modalConfig: {
        height: 'auto',
        maxWidth: 420,
        adaptive: true
      },
      visible: false
    };
  },
  watch: {
    'column.name': {
      handler(val) {
        this.name = val;
      },
      immediate: true
    }
    // '$i18n.locale'() {
    //   this.name = this.name;
    // }
  },
  computed: {
    ...mapGetters(['getTaskByColumns']),
    isViewOnly() {
      return this.$store.getters['boards/isViewOnly'];
    },
    tasks() {
      const tasks = this.getTaskByColumns[this.column.id] || [];
      return tasks;
    },
    totalTasks() {
      return this.tasks.length;
    },
    hideRadius() {
      return this.totalTasks > 0 || this.isAddingTask;
    },
    isNameChanged() {
      return this.name !== this.column.name;
    },
    headerClasses() {
      return 'px-2 border border-transparent text-left truncate hover--dark';
    },
    statuColors() {
      return ['bg-todo', 'bg-progress', 'bg-done', 'bg-other'];
    },
    isUserViewer() {
      return this.$store.getters['boards/isUserViewer'];
    }
  },
  methods: {
    ...mapActions(['addTask', 'setTask']),
    ...mapMutations(['UPDATE_TASKS', 'ADD_TASK']),
    editName() {
      this.isEditingName = true;
      this.toggleEdit(true);
    },
    submitName() {
      if (!this.name.trim()) this.$emit('name-cancel');
      else if (this.isNameChanged) this.$emit('name', this.name);
      this.isEditingName = false;
      this.toggleEdit(false);
    },
    confirmArchiveColumn() {
      this.showModal({
        title: this.$t('myBoard.alert.archived.name'),
        description: this.$t('myBoard.alert.archived.title'),
        button: this.$t('myBoard.alert.archived.button'),
        confirmAction: 'board-column/archiveColumn',
        confirmData: this.column.id
      });
    },
    showModal(data) {
      this.$modal.show(DialogConfirm, data, this.modalConfig);
    },
    toggleAddingTask() {
      this.isAddingTask = !this.isAddingTask;
    },
    splitToArray(routeValue) {
      return routeValue ? routeValue.split(',').map(e => parseInt(e)) : [];
    },
    submitAddTask(name) {
      if (name) {
        const { id: columnID } = this.column;
        const boardID = parseInt(this.$route.params.id, 10);
        const { assigneeIDs, tagIDs } = this.$route.query;

        const fetchTasksTags = this.splitToArray(tagIDs);
        const fetchTasksAssign = this.splitToArray(assigneeIDs);
        name = name.replaceAll('\0', '');
        this.addTask({
          boardID,
          columnID,
          name,
          assigneeIDs: fetchTasksAssign,
          tagIDs: fetchTasksTags
        }).then(res => {
          this.ADD_TASK(res.data);
        });
      }
      this.toggleAddingTask();
    },
    drag(ev) {
      const { added, moved } = ev;
      const ref = added || moved;
      const { position, element } = ref;
      if (this.shouldRecalculatePostion(position, this.tasks)) {
        const tasks = this.recalculateItemsPosition(this.tasks, {
          id: element.id,
          position
        });
        this.UPDATE_TASKS(tasks);
      }
      const taskSetData = {
        id: element.id,
        position
      };

      if (added) {
        taskSetData.newColumnID = this.column.id;
      }

      this.setTask(taskSetData);
    },
    blurNameInput() {
      this.$refs.nameInput.blur();
    },
    toggleEdit(status) {
      this.$emit('toggle-add', status);
    }
  }
};
</script>

<style lang="scss">
.board-column {
  @apply flex flex-shrink-0 h-full;
  width: 296px;
}
.scroll-hidden {
  scrollbar-width: none;
}
.scroll-hidden::-webkit-scrollbar {
  display: none;
}
.board-column__bg {
  @apply flex flex-col bg-gray-main flex-1 rounded overflow-hidden;
}
</style>
