<template>
  <div v-if="getPaymentHistory.length">
    <p class="text-lg mb-2">{{ $t('my_purchase') }}</p>
    <div class="rounded">
      <table class="w-full">
        <tbody class="border border-gray-border">
          <tr
            v-for="(bill, index) in filterPerPage"
            :key="index"
            :class="{
              'border-b border-gray-border': index < filterPerPage.length
            }"
          >
            <td style="padding: 14px">
              <div class="flex flex-col">
                <v-clamp autoresize :max-lines="1" class="max-w-xs">
                  {{ $t('plan') }} - {{ bill.subscriptionOrder.name }}
                </v-clamp>
                <v-clamp
                  autoresize
                  :max-lines="1"
                  class="max-w-xs text-gray-500 text-sm"
                >
                  {{ $t('date') }}
                  {{ formatDue(bill.createdAt) }}
                </v-clamp>
                <v-clamp
                  autoresize
                  :max-lines="1"
                  class="max-w-xs text-gray-500 text-sm"
                >
                  {{ $t('period') }}
                  {{ formatShowDate(bill.startAt, bill.endAt) }}
                </v-clamp>
              </div>
            </td>
            <td>
              <div class="flex flex-col text-sm font-medium">
                <v-clamp autoresize :max-lines="1">
                  {{ bill.cardNo }}
                </v-clamp>
                <base-icon>
                  <component :is="`icon${bill.cardBrand}`" />
                </base-icon>
              </div>
            </td>
            <td class="text-center p-2">
              <div class="status--active py-1 rounded-full text-xs mx-auto">
                {{ $t('pay') }}
              </div>
            </td>
            <td class="text-sm">
              <div class="flex flex-col">
                <v-clamp autoresize :max-lines="1" class="max-w-xs">
                  ฿{{ priceFormat(bill.total) }}
                </v-clamp>
                <v-clamp autoresize :max-lines="1" class="max-w-xs">
                  {{ bill.subscriptionOrder.numberOfSeats }}
                  {{ $t('subscription.payment.seat_per_month') }}
                </v-clamp>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <base-pagination
        v-if="getPaymentHistory.length > 10"
        :list="getPaymentHistory.length"
        @pageCurrent="setPageCurrent"
      />
    </div>
  </div>
</template>

<script>
import IconMasterCard from '@/components/icons/IconMasterCard';
import IconVisa from '@/components/icons/IconVisa';
import VClamp from 'vue-clamp';
import { mapGetters } from 'vuex';
import FormatStartEndDate from '@/mixins/FormatStartEndDate.ts';
import moment from 'moment';

export default {
  mixins: [FormatStartEndDate],
  components: {
    IconMasterCard,
    VClamp,
    IconVisa
  },
  data() {
    return {
      pageSelected: 1,
      dataPerPage: 10
    };
  },
  computed: {
    ...mapGetters('subscription', ['getPaymentHistory']),
    filterPerPage() {
      const queryResultPerPage = this.getPaymentHistory.slice(
        (this.pageSelected - 1) * this.dataPerPage,
        this.pageSelected * this.dataPerPage
      );
      return queryResultPerPage;
    }
  },
  mounted() {
    this.$store.dispatch('subscription/fetchPaymentHistory');
  },
  methods: {
    setPageCurrent(e) {
      this.pageSelected = e;
    },
    setPerPage(e) {
      this.dataPerPage = e;
    },
    formatDue(date) {
      return moment(date)
        .locale(`${this.$t('notifications.language')}`)
        .format('DD MMM YYYY');
    },
    priceFormat(price) {
      return new Intl.NumberFormat().format(price.toFixed(2));
    }
  }
};
</script>
<style scoped>
.status--active {
  @apply text-center px-4;
  max-width: fit-content;

  color: #88b864;
  background-color: #eaf5e3;
}
</style>
<i18n>
{
  "en": {
    "my_purchase": "My Purchase",
    "plan": "Plan",
    "date": "Date",
    "period": "Period",
    "pay": "pay"
  },
  "th": {
    "my_purchase": "ประวัติบิล",
    "plan": "แผน",
    "date": "วันที่",
    "period": "ระยะเวลา",
    "pay": "จ่าย"
  }
}
</i18n>
