<template>
  <div
    class="py-2 cursor-pointer flex"
    :class="alignTop ? 'items-start' : 'items-center'"
    @click="$emit('click', $event)"
  >
    <div
      class="transform transition duration-200 align-middle ml-4 flex-shrink-0 pt-1"
      :class="{ 'rotate-90': !active && !isHideChevron }"
    >
      <base-icon
        v-if="!isHideChevron"
        class="flex-shrink-0"
        name="chevron-right"
        size="20"
      >
        <icon-chevron-right />
      </base-icon>
      <base-icon
        v-else
        class="flex-shrink-0"
        size="18"
        style="margin-bottom: 5px;"
      >
        <components :is="icon" />
      </base-icon>
    </div>
    <div
      class="flex-1 pl-3 text-lg"
      style="max-width: calc(100% - 20px - 1rem);"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import IconChevronRight from '@/components/icons/IconChevronRight';
import IconRecentlyView from '@/components/icons/IconRecentlyView';
import IconStarOutlined from '@/components/icons/IconStarOutlined';

export default {
  components: { IconChevronRight, IconRecentlyView, IconStarOutlined },
  props: {
    active: Boolean,
    alignTop: Boolean
  },
  computed: {
    isBoardList() {
      return this.$route.name === 'Boards-List';
    },
    isHideChevron() {
      return ['Recently-Boards', 'Stared-Boards'].includes(this.$route.name);
    },
    icon() {
      const icons = {
        'Recently-Boards': IconRecentlyView,
        'Stared-Boards': IconStarOutlined
      };
      return icons[this.$route.name];
    }
  }
};
</script>

<style></style>
