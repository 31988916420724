<template>
  <v-popover
    placement="bottom-start"
    popover-arrow-class="tooltip-arrow popover-arrow invisible"
    @show="active = true"
    @hide="
      active = false;
      clearStep();
    "
  >
    <slot :active="active">
      <base-button>Select tag</base-button>
    </slot>
    <div slot="popover" class="select-tag__popover divide-y">
      <div
        v-if="step == ''"
        class="flex items-center base-dropdown__item justify-between"
      >
        <span class="font-medium">{{ $t('modelTask.card.tags.name') }}</span>
        <base-button
          v-close-popover
          color="transparent"
          borderless
          icon="close"
          icon-size="18"
        />
      </div>
      <div v-else class="flex items-center base-dropdown__item justify-between">
        <span class="font-medium"
          >{{ $t(`modelTask.card.tags.${step}`) }}

          {{ $t('modelTask.card.tags.singular') }}</span
        >
        <base-button
          color="transparent"
          borderless
          icon="close"
          icon-size="18"
          @click="step = ''"
        />
      </div>
      <div v-if="step == ''" class="relative">
        <base-dropdown-item :hoverable="false" keep>
          <base-input-text
            v-model="queryText"
            inner-class="base-input--gray"
            :placeholder="$t('modelTask.card.tags.search')"
            icon-fa="search"
          />
        </base-dropdown-item>
        <base-dropdown-item
          v-for="tag in tags"
          :key="`tag-${tag.id}`"
          keep
          @click="toggleActiveTag(tag)"
        >
          <div class="flex items-center overflow-hidden">
            <base-checkbox :checked="isTagActive(tag)" />
            <div
              class="rounded-full flex-1 px-4 py-1  text-white font-medium truncate"
              :style="{ background: tag.color }"
            >
              <span :class="{ invisible: !tag.name }">
                {{ tag.name || '(empty)' }}
              </span>
            </div>
            <div class="pl-2">
              <base-button
                icon="setting"
                icon-size="15"
                wide="p-2"
                color="text"
                class="hover:bg-gray-200"
                @click="editTag(tag)"
              />
            </div>
          </div>
        </base-dropdown-item>
        <base-dropdown-item v-if="!tags.length" :hoverable="false">
          <div class="text-center text-gray-500 text-sm p-2">
            {{ $t('modelTask.card.tags.no') }}
          </div>
        </base-dropdown-item>
        <base-dropdown-item v-if="getNextPage" keep>
          <base-button
            class="mb-0"
            full
            color="link"
            :loading="loading"
            @click="fetchNext"
          >
            {{ $t('modelTask.card.tags.more') }}
          </base-button>
        </base-dropdown-item>

        <div
          class="flex items-center base-dropdown__item justify-between sticky bottom-0 bg-white"
        >
          <base-button full wide="py-2" class="text-sm" @click="createTag()">
            {{ $t('modelTask.card.tags.createnew') }}
          </base-button>
        </div>
      </div>
      <div v-else>
        <div v-if="step == 'edit'">
          <base-input-text
            v-model="tagSelect.name"
            inner-class="base-input--gray"
            :placeholder="$t('modelTask.card.tags.tag')"
            class="px-4 my-2"
          />
          <select-colors v-model="tagSelect.color" class="mb-4 px-4" />
          <div class="text-right">
            <base-button
              color="transparent"
              wide="py-2"
              class="text-sm text-red-600 border-red-600"
              @click="step = 'remove'"
            >
              {{ $t('modelTask.card.tags.delete') }}
            </base-button>
            <base-button
              wide="py-2"
              class="text-sm mr-2"
              @click="confirmEditTag(tagSelect)"
            >
              {{ $t('modelTask.card.tags.save') }}
            </base-button>
          </div>
        </div>
        <div v-else-if="step == 'remove'" class="p-4">
          <p class="text-sm mb-4">
            {{ $t('modelTask.card.tags.detail') }}
          </p>
          <base-button
            color="red"
            full
            wide="py-2"
            class="text-sm"
            @click="deleteTag(tagSelect)"
          >
            {{ $t('modelTask.card.tags.delete') }}
          </base-button>
        </div>
        <div v-if="step == 'create'">
          <base-input-text
            v-model="tagNameCreate"
            inner-class="base-input--gray"
            :placeholder="$t('modelTask.card.tags.tag')"
            class="px-4 my-2"
          />
          <select-colors v-model="tagCreateColor" class="mb-4 px-4" />
          <div class="text-right">
            <base-button
              color="transparent"
              wide="py-2"
              class="text-sm text-red-600 border-red-600"
              @click="step = ''"
            >
              {{ $t('modelTask.card.tags.cancel') }}
            </base-button>
            <base-button
              wide="py-2"
              class="text-sm mr-2"
              @click="confirmCreate"
            >
              {{ $t('modelTask.card.tags.save') }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </v-popover>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import SelectColors, { colors } from '@/components/select/select-colors';
const { mapActions, mapGetters } = createNamespacedHelpers('tags');

export default {
  components: {
    SelectColors
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: false,
      queryText: '',
      loading: false,
      step: '',
      tagSelect: '',
      tagNameCreate: '',
      tagCreateColor: colors[0]
    };
  },
  computed: {
    ...mapGetters(['getTags', 'getNextPage']),
    tags() {
      const byName = ({ name }) =>
        name.toLowerCase().includes(this.queryText.toLowerCase());
      return this.getTags.filter(byName);
    }
  },
  methods: {
    ...mapActions(['fetchTags'], {
      taskAddTag: 'tasks/addTag',
      taskRemoveTag: 'tasks/removeTag',
      createTag: 'tags/createTag'
    }),
    fetchNext() {
      this.loading = true;
      this.$nextTick(async () => {
        await this.fetchTags();
        this.loading = false;
      });
    },
    isTagActive(tag) {
      return this.value.some(tagId => tagId === tag.id);
    },
    toggleActiveTag(tag) {
      const value = this.isTagActive(tag)
        ? this.value.filter(tagId => tagId !== tag.id)
        : [...this.value, tag.id];
      this.$emit('input', value);
    },
    editTag(tag) {
      this.tagSelect = tag;
      this.step = 'edit';
    },
    createTag() {
      this.step = 'create';
    },
    clearStep() {
      this.step = '';
      this.tagSelect = '';
      this.tagCreateColor = colors[0];
      this.tagNameCreate = '';
    },
    async confirmCreate() {
      const newTag = {
        boardID: parseInt(this.$route.params.id),
        color: this.tagCreateColor,
        name: this.tagNameCreate
      };
      const tag = await this.$store.dispatch('tags/createTag', newTag);
      this.clearStep();
    },

    async deleteTag(tag) {
      await this.$store.dispatch('tags/removeTag', tag.id);
      await this.$store.dispatch('boards/fetchBoards');
      this.clearStep();
    },

    async confirmEditTag(tag) {
      const editTag = {
        id: tag.id,
        boardID: parseInt(this.$route.params.id),
        color: this.tagSelect.color,
        name: this.tagSelect.name
      };
      await this.$store.dispatch('tags/setTag', editTag);
      await this.$store.dispatch('boards/fetchBoards');
      this.clearStep();
    }
  }
};
</script>

<style>
.select-tag__popover {
  @apply relative overflow-y-auto;
  max-height: 383px;
  width: 300px;
}
</style>
