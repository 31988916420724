import Vue from 'vue';
import { BoardMember } from '@/types/Board';

export default Vue.extend({
  methods: {
    setRole(member: BoardMember, newRole: number) {
      const roleMember = { ...member, role: newRole };
      return member.status === 1
        ? { ...roleMember, memberID: member.id }
        : roleMember;
    }
  }
});
