















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

type Color = 'danger' | 'prime' | 'success';

@Component({
  inheritAttrs: false
})
export default class BaseInputRadio extends Vue {
  @Prop({
    type: String,
    default: 'radio',
    validator(value) {
      return ['radio', 'checkbox'].includes(value);
    }
  })
  readonly type!: string;
  @Prop({ type: String, default: 'prime' }) readonly color!: Color;
  @Prop({ type: Boolean }) readonly disabled?: boolean;
  @Prop({ type: Boolean }) readonly boxed!: boolean;
  @Prop({ type: Boolean }) readonly checked!: boolean;
  @Emit()
  change() {
    return !this.checked;
  }
}
