






























import Vue, { PropType } from 'vue';
import { BoardType } from '@/types/Board';

interface TypeSelect {
  value: BoardType;
  title: 'Kanban' | 'Waterfall' | 'Agile';
}

export default Vue.extend({
  props: {
    value: {
      type: Number as PropType<BoardType>,
      default: BoardType.KANBAN
    }
  },
  data() {
    return {
      localValue: BoardType.KANBAN
    };
  },
  computed: {
    boardTypes(): TypeSelect[] {
      return [
        { value: 0, title: 'Kanban' },
        { value: 1, title: 'Waterfall' },
        { value: 2, title: 'Agile' }
      ];
    }
  },
  watch: {
    value: {
      handler(val) {
        if (this.localValue !== val) this.localValue = val;
      },
      immediate: true
    }
  },
  methods: {
    getBoardIcon(typeSelect: TypeSelect) {
      const icons = ['board-kanban', 'board-waterfall', 'board-agile'];
      return icons[typeSelect.value];
    },
    isSelected(typeSelect: TypeSelect) {
      return typeSelect.value === this.localValue;
    },
    selectType(typeSelect: TypeSelect) {
      this.localValue = typeSelect.value;
      this.$emit('input', typeSelect.value);
    }
  }
});
