















import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: false,
  name: 'modal-task-link-priority',
  props: {
    priority: Number
  },

  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {}
});
