<template>
  <div
    class="user-info__box divide-x grid grid-cols-2 text-center"
    style="padding: 2rem; padding-top: 3rem;"
  >
    <div
      v-for="item in orderedBoardsTasks"
      :key="item.key"
      class="pb-1 has-centered-item"
    >
      <div>
        <div class="relative inline-block">
          <div
            class="absolute inset-x-0 text-gray-500 text-sm left-0"
            style="top: -1rem;"
          >
            {{ item.key }}{{ $t('admin.s') }}
          </div>

          <div
            class="text-5xl mr-2 font-medium"
            :class="{ 'text-gray-label': !item.value }"
          >
            {{ item.value }}
          </div>
        </div>
        <span class="text-lg" :class="{ 'text-gray-label': !item.value }">
          {{ item.key }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    orderedBoardsTasks() {
      const { totalBoards, totalTasks } = this.value;
      return [
        {
          key: this.$t('admin.boards'),
          value: totalBoards
        },
        {
          key: this.$t('admin.tasks'),
          value: totalTasks
        }
      ];
    }
  }
};
</script>

<style></style>
