<template>
  <div
    class="
      h-32
      w-auto
      rounded-md
      border border-gray-200
      bg-gray-100
      relative
      m-1
      inline-block
      align-text-top
      overflow-hidden
    "
  >
    <img
      v-if="file.url || file.file"
      :src="file.url || file.file.thumbnailImageUrl"
      class="w-48 h-full rounded-md object-cover"
    />
    <div v-else class="w-48 flex flex-col h-full">
      <div class="flex-1 flex items-center justify-center">
        <base-icon class="" name="file" size="32"> <icon-file /></base-icon>
      </div>
      <div
        class="
          border-t border-gray-200
          bg-white
          text-sm text-gray-800
          px-2
          py-1
          truncate
        "
      >
        {{ file.name }}
      </div>
    </div>

    <!-- remove -->
    <div
      class="
        absolute
        top-0
        right-0
        rounded-full
        bg-gray-200
        mt-2
        mr-2
        flex
        items-center
        justify-center
        cursor-pointer
      "
      @click="$emit('remove')"
    >
      <base-icon class="px-1 py-1" name="close" size="18">
        <icon-close
      /></base-icon>
    </div>
  </div>
</template>

<script >
import Vue from 'vue';
import StringMixin from '@/mixins/StringMixin';
import IconFile from '@/components/icons/IconFile.vue';
import IconClose from '@/components/icons/IconClose.vue';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import BoardUtils from '@/mixins/BoardUtils';

export default Vue.extend({
  mixins: [StringMixin, DateTimeMixin, BoardUtils],
  components: {
    IconFile,
    IconClose,
  },
  props: {
    file: {
      type: [Object, File],
      default: () => {
        return {};
      },
    },
  },
  computed: {},
  methods: {},
});
</script>

<style lang="scss" scoped>
</style>
