<template>
  <div>
    <h3 class="mb-3 text-2xl">{{ $t('timelog.timer.titleTimer') }}</h3>
    <time-log-starter-inputs
      :disabled="isWorking"
      :loading="loading"
      :description.sync="description"
      :board.sync="board"
      :task.sync="task"
    />
    <timelog-timer
      :dropdown="{ width: 'small', position: 'bottom-right' }"
      :start-options="startOptions"
      :timelog="getWorkingLog"
      separated
      counter-size="text-4xl"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TimeLogStarterInputs from './time-log-starter-inputs';
import TimelogTimer from '@/components/timelog/timelog-timer';

export default {
  components: {
    TimeLogStarterInputs,
    TimelogTimer
  },
  data() {
    return {
      description: '',
      task: null,
      board: null,
      debouncer: () => null
    };
  },
  computed: {
    ...mapGetters('time-log', ['getWorkingLog', 'getLoadings']),
    isWorking() {
      return !!this.getWorkingLog;
    },
    disabled() {
      return !this.board && !this.description;
    },
    loading() {
      return this.getLoadings.workingLog;
    },
    startOptions() {
      return {
        description: this.description,
        boardID: this.board?.id,
        taskID: this.task?.id
      };
    }
  },
  watch: {
    getWorkingLog: {
      immediate: true,
      handler: 'onWorkingLogChanged'
    }
  },
  methods: {
    onWorkingLogChanged(val) {
      if (val) {
        this.description = val.description || '';
        this.board = val.boardName
          ? {
              name: val.boardName
            }
          : null;
        this.task = val.taskName
          ? {
              name: val.taskName
            }
          : null;
      } else {
        this.board = null;
        this.task = null;
        this.description = '';
      }
    }
  }
};
</script>

<style></style>
