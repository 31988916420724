<template>
  <div class="flex-1 flex w-full" :class="{ 'overflow-hidden': isBoardColumn }">
    <div v-if="loading" />
    <div
      v-else-if="board.notFound"
      class="has-centered-item flex-1 flex justify-center align-middle"
    >
      <h1 class="text-2xl">{{ $t('modelTask.copy.noti.no') }}</h1>
    </div>

    <div v-else class="flex-1 flex flex-col w-full">
      <board-header
        :tab.sync="activeTab"
        :loading="loading"
        :board="board"
        @tab-changed="onActiveTabChanged"
      />
      <router-view />
      <notifications
        group="invite-member"
        class="mt-2"
        :duration="5000"
        classes="vue-notification mr-2 text-base shadow"
      />
      <notifications group="task-copy" class="m-2" position="bottom right">
        <template slot="body" slot-scope="props">
          <div class="bg-green-success p-4 rounded">
            <p class="text-white text-lg mb-3">
              {{ $t('modelTask.copy.noti.new') }}
            </p>
            <base-button
              full
              color="white"
              wide="py-2"
              @click="onOpenTaskCopy(props)"
            >
              {{ $t('modelTask.copy.noti.see') }}
            </base-button>
          </div>
        </template>
      </notifications>
    </div>
    <modal-task :disabled="disabledTask" @closed="closeTask" />
    <modal
      ref="level-setting"
      name="level-setting"
      scrollable
      height="auto"
      width="627"
    >
      <form-agile-level-setting />
    </modal>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapActions, mapMutations } from 'vuex';
import BoardHeader from '@/components/board/board-header';
import eventBus from '@/services/eventBus';
import ModalTask from '@/components/modal/modal-task.vue';
import { boardsApi } from '@/services/apis';
import { debounce } from 'lodash';
import FormAgileLevelSetting from '@/components/forms/form-agile-level-setting';

const { mapGetters } = createNamespacedHelpers('boards');
const kanbanNames = [
  'Board-Columns',
  'Board-Dashboard',
  'Board-Timeline',
  'export-report'
];
const waterfallNames = ['Board-Waterfall'];
const agileNames = [
  'Board-Sprint',
  'Board-Backlog',
  'Board-Completed-Sprint',
  'Board-Agile-Timeline',
  'export-report'
];
const tabRouteNames = [kanbanNames, waterfallNames, agileNames];

const halfSecond = callback => debounce(callback, 1000);
export default {
  components: {
    BoardHeader,
    ModalTask,
    FormAgileLevelSetting
  },
  data() {
    return {
      loading: false,
      activeTab: 0
    };
  },
  computed: {
    ...mapGetters({
      board: 'getActiveBoard'
    }),
    tabRouteNames() {
      return tabRouteNames[this.board.type] || [];
    },
    isBoardColumn() {
      const routeName = 'Board-Columns';
      return this.$route.matched.some(r => r.name === routeName);
    },
    disabledTask() {
      const { meta } = this.$route;
      if (
        this.$route.name === 'Board-Timeline-Task' ||
        this.$route.name === 'Board-Agile-Timeline-Task'
      ) {
        return this.$route.meta(this.$route).disableTask;
      }
      return meta.disabledTask;
    },
    fetchTasksDeps() {
      return {
        ...this.$route.query,
        boardID: this.$route.params.id
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      const { tab, name } = await vm.getActiveTabFromRoute(to);
      vm.activeTab = tab;
      next({ name, query: to.query, params: to.params, replace: true });
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const [boardId, prevBoardId] = [to, from].map(e => e.params.id.toString());
    const IS_EQUAL = 0;
    const boardCompared = boardId.localeCompare(prevBoardId);
    const isTabMatchWithRoute = await this.tabMatchWithRoute(to);
    if (boardCompared !== IS_EQUAL) {
      this.activeTab = 0;
    }
    if (!isTabMatchWithRoute) {
      const { tab } = await this.getActiveTabFromRoute(to);
      this.activeTab = tab;
    }
    next();
  },
  beforeDestroy() {
    this.$store.dispatch('tasks/offSnapshotFirestore', { clear: true });
  },
  destroyed() {
    eventBus.$off('UPDATE_BOARD');
    this.unsubscribeFirebaseBoard();
    this.resetData();
  },
  mounted() {
    eventBus.$on('UPDATE_BOARD', () => {
      this.fetchBoard(this.$route.params.id);
    });
  },
  watch: {
    '$route.params.id': {
      async handler(id, prev) {
        if (prev == id) return;
        const boardId = parseInt(id, 10);
        this.loading = true;
        this.resetData();
        await this.fetchBoard(boardId);
        if (!this.board.notFound) await this.fetchMembers();
        if (this.board.notFound) {
          this.$router.push({ name: 'Boards-List' });
        }
        this.loading = false;
      },
      immediate: true
    },
    fetchTasksDeps: {
      handler(e, prev) {
        const changed = this.compareQuery(e, prev);
        if (changed.key || !prev) this.fetchTasks(e);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      resetMembers: 'members/RESET_STATE',
      resetTags: 'tags/RESET_TAGS'
    }),
    ...mapActions({
      fetchBoard: 'boards/fetchBoard',
      fetchMembers: 'members/fetchMembers',
      unsubscribeFirebaseBoard: 'boards/unsubscribeFirebase',
      boardColumnsUnsubscribe: 'board-column/firestoreUnsubscribe'
    }),
    fetchTasks: halfSecond(async function(p) {
      if (!p.boardID) return;
      const { tagIDs, assigneeIDs, ...params } = p;
      const fetchRequest = {
        ...params
      };
      const splitQueryString = str => {
        if (!str) return;
        return str.split(',').map(e => parseInt(e));
      };

      fetchRequest.tagIDs = splitQueryString(tagIDs);
      fetchRequest.assigneeIDs = splitQueryString(assigneeIDs);

      // this.unsubTasks({ clear: false });
      await this.$store.dispatch('tasks/fetchTasks', fetchRequest);
      // this.loadingTask = false;
      // this.shouldShowSearchResult = this.fetchTasksHasSomeFilters;
    }),
    compareQuery(query, prev = {}) {
      const changed = [
        'q',
        'tagIDs',
        'assigneeIDs',
        'boardID',
        'isTimeline'
      ].find(key => query[key] !== prev[key]);
      let result = {
        key: '',
        value: '',
        prev: ''
      };
      if (changed) {
        result = {
          key: changed,
          value: query[changed],
          prev: prev[changed]
        };
      }
      return result;
    },
    resetData() {
      this.boardColumnsUnsubscribe();
      this.resetTags();
      this.resetMembers();
    },
    async getActiveTabFromRoute(to) {
      const getBoard = () =>
        boardsApi
          .get(to.params.id, { headers: { hideNotify: true } })
          .then(e => e.data);
      const board = this.board.notFound ? await getBoard() : this.board;
      const currentMatch = to.matched[to.matched.length - 1];
      const boardTabs = tabRouteNames[board.type];
      const isMatchCurrentTab = tabName => {
        return to.matched.some(item => item.name?.includes(tabName));
      };
      const tab = boardTabs.findIndex(isMatchCurrentTab);
      // incorrect match between url and board tab
      const invalidUrlAndBoard = tab < 0;
      if (currentMatch?.name === 'Boards-Id' || invalidUrlAndBoard) {
        const baseBoardRoutes = [
          'Board-Normal',
          'Board-Waterfall',
          'Board-Agile'
        ];
        const name = baseBoardRoutes[board.type];
        return { tab: 0, name };
      }
      return { tab, name: to.name };
    },
    onActiveTabChanged(tab, type) {
      const name = this.tabRouteNames[tab];
      const isSameTypeReport = this.$route.params?.type === type;
      const isSameRoute = this.$route.name === name && isSameTypeReport;
      if (isSameRoute) return;
      if (type) this.$router.push({ name, params: { type } });
      else {
        this.$router.replace({ name }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes(
              'Avoided redundant navigation to current location'
            )
          ) {
            this.$router.replace({ name });
          }
        });
      }
    },
    async tabMatchWithRoute(route) {
      const { tab } = await this.getActiveTabFromRoute(route);
      return this.activeTab === tab;
    },
    onOpenTaskCopy(props) {
      const { query, path: originPath } = this.$route;
      const copiedTask = props.item.data.task;
      const taskPath = `/t/${copiedTask.id}`;
      const path = originPath.replace(/\/t\/\d+/, '') + taskPath;
      const route = {
        path,
        query
      };
      this.$router.replace(route);
      // call to close() notification
      props.close();
    },
    closeTask() {
      const { matched } = this.$route;
      const parentRoute = matched[matched.length - 1].parent;
      this.$router.replace({
        ...parentRoute,
        query: this.$route.query
      });
      this.$store.commit('tasks/CLOSE_TASK');
    }
  }
};
</script>
<style></style>
