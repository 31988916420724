import { Module } from 'vuex';
import { RootState } from '@/types/Store';
import { Tag, TagAdd, TagEdit, ColorOptionsOrder } from '@/types/Tag';
import { boardsApi } from '@/services/apis';

interface State {
  entities: Tag[];
  nextPage: number;
  filterIds: number[];
  query: string;
}

interface TagSorter {
  (a: Tag, b: Tag): number;
}

const sortTagByColor: TagSorter = ({ color: a }, { color: b }) => {
  return ColorOptionsOrder[a] - ColorOptionsOrder[b];
};

const sortTagByName: TagSorter = ({ name: a }, { name: b }) => {
  return a.localeCompare(b);
};

const sortTags = (tags: Tag[]) =>
  [...tags].sort(sortTagByName).sort(sortTagByColor);

const TagsModule: Module<State, RootState> = {
  namespaced: true,
  state: {
    entities: [],
    nextPage: 1,
    filterIds: [],
    query: ''
  },
  getters: {
    getTags: s => s.entities,
    getNextPage: s => s.nextPage,
    getBoardId: (_0, _1, _2, rootGetters) =>
      rootGetters['boards/getActiveBoardId'],
    getFilterIds(s) {
      return s.filterIds;
    }
  },
  mutations: {
    SET_TAGS(s, payload: Tag[]) {
      s.entities = payload;
    },
    SET_NEXT: (s, nextPage: number) => (s.nextPage = nextPage),
    ADD_TAG(s, tag) {
      s.entities = sortTags([...s.entities, tag]);
    },
    SET_TAG(s, tag: Tag) {
      s.entities = sortTags(s.entities.map(e => (e.id === tag.id ? tag : e)));
    },
    REMOVE_TAG: (s, id: number) =>
      (s.entities = s.entities.filter(t => t.id !== id)),
    RESET_TAGS(s) {
      s.entities = [];
      s.nextPage = 1;
    },
    SET_FILTER_IDS(s, payload: number[]) {
      s.filterIds = payload;
    }
  },
  actions: {
    fetchTags: async ({ commit, getters, state }, q: string) => {
      const { getBoardId: boardID, getNextPage: nextPage, getTags } = getters;

      const params = {
        boardID,
        page: nextPage,
        q
      };

      if (!nextPage) return;
      const { data } = await boardsApi.tags(params);

      const {
        entities,
        pageInformation: { lastPage, page }
      } = data;
      // with -1,-2 to prevent undefined === undefined

      const prevTags = getTags;
      const tags = state.query !== q ? entities : [...prevTags, ...entities];
      const next = page < lastPage ? nextPage + 1 : 0;
      if (state.query !== q) {
        state.query = q;
      }
      commit('SET_TAGS', tags);
      commit('SET_NEXT', next);
    },

    createTag: async ({ commit }, newTag: TagAdd) => {
      const { data } = await boardsApi.createTag(newTag);
      commit('ADD_TAG', data);
      return data;
    },
    setTag: async ({ commit, getters }, tag: TagEdit) => {
      tag.boardID = getters.getBoardId;
      const { data } = await boardsApi.setTag(tag);
      commit('SET_TAG', data);
      // dispatch('tasks/refreshCurrentTask', null, { root: true });
    },
    removeTag: async ({ commit, getters }, tagID: number) => {
      const boardID = getters.getBoardId;
      await boardsApi.removTag(tagID, boardID);
      commit('REMOVE_TAG', tagID);
      // dispatch('tasks/refreshCurrentTask', null, { root: true });
    }
  }
};

export default TagsModule;
