type Locale = 'en' | 'th';
const FALLBACK_LOCALE: Locale = 'en';

export default {
  getLocale(): Locale {
    return (localStorage.getItem('locale') as Locale | null) || FALLBACK_LOCALE;
  },
  setLocale(locale = FALLBACK_LOCALE): Locale {
    localStorage.setItem('locale', locale);
    return locale;
  }
};
