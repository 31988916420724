import apis from './apis';
import {
  SubscriptionPlan,
  SaveCard,
  EmailInvite,
  Dashboard,
  FeedbackCancelPlan
} from '@/types/Subscription';
import { CommandResponse } from '@/types/Api';

export default {
  //   Plan
  getPlansInfo() {
    return apis.get(`/guest/subscription/plans`);
  },
  getSubscriptionCurrentPlan() {
    return apis.get(`/me/currentPlan`);
  },
  subscriptionPlanPreview: (plan: SubscriptionPlan) =>
    apis.get<CommandResponse>('/subscription', { params: plan }),
  cancelPlan(feedback: FeedbackCancelPlan) {
    return apis.delete<CommandResponse>(`/subscription`, { params: feedback });
  },
  changeSubscriptionPlan: (plan: SubscriptionPlan) =>
    apis.patch<CommandResponse>('/subscription', plan),
  paidPlan: (plan: any) =>
    apis.patch<CommandResponse>(`/subscription/${plan.orderID}/paid`, {
      cardToken: plan.cardToken
    }),
  subscriptionConfirm: (plan: SubscriptionPlan) =>
    apis.post<CommandResponse>('/subscription', plan),

  //   card payment
  listCard() {
    return apis.get(`/payments/cards`);
  },
  saveCard: (tokenId: SaveCard) =>
    apis.post<CommandResponse>('/payments/cards', tokenId),
  deleteCard: (cardId: string) =>
    apis.delete<CommandResponse>(`/payments/cards/${cardId}`),
  setCardDefault: (cardId: string) =>
    apis.post<CommandResponse>(`/payments/cards/${cardId}/default_card`),
  paymentHistory() {
    return apis.get(`/me/paymentHistory`);
  },

  //seat
  reduceTotalSeats: (numberOfSeats: number) =>
    apis.patch<CommandResponse>('/seats', numberOfSeats),
  increaseTotalSeats: (numberOfSeats: number) =>
    apis.post<CommandResponse>('/seats', numberOfSeats),
  inviteMember: (email: EmailInvite) =>
    apis.post<CommandResponse>('/seats/members', email),
  getSeatMembers: (teamIds: any) =>
    apis.get<CommandResponse>('/seats/members', teamIds),
  removeMember: (memberId: number) =>
    apis.delete<CommandResponse>(`/seats/members/${memberId}`),
  seatPreview: (numberOfSeats: any) =>
    apis.get<CommandResponse>('/seats/preview', { params: numberOfSeats }),

  // dashboard
  getOverview: (dashboard: Dashboard) =>
    apis.get<CommandResponse>('/teams/overviews', { params: dashboard }),

  // workspace
  getMyWorkspace() {
    return apis.get(`/me/workspaces`);
  },

  sendSubscriptionOwner(id: number) {
    return apis.post(`/teams/${id}/subscription`);
  }
};
