import apis from './apis';
import { NotificationItem } from '@/types/Notification';
import { AxiosPromise } from 'axios';
import {
  CommandResponse,
  ApiPagination,
  ApiPaginationParams
} from '@/types/Api';

interface NotificationApis {
  fetchNotifications(
    params?: ApiPaginationParams
  ): AxiosPromise<ApiPagination<NotificationItem>>;
  clearNotifications(): AxiosPromise<CommandResponse>;
  setRead(id: number): AxiosPromise<NotificationItem>;
  readAll(): AxiosPromise<CommandResponse>;
}

export const notificationApis: NotificationApis = {
  fetchNotifications(params) {
    return apis.get(`/notifications`, { params });
  },
  setRead(id) {
    return apis.get(`/notifications/${id}`);
  },
  clearNotifications() {
    return apis.post(`/notifications/clearBadge`);
  },
  readAll() {
    return apis.post('/notifications/readAll');
  }
};
