<template>
  <v-scroller style="height: calc(100vh - 64px);">
    <main>
      <section class="px-8 flex-1">
        <div class="my-tasks mt-4">
          <my-task-header
            ref="header"
            :task-count="taskCount"
            @q="qChange"
            @filter="filterChange"
            @groupBy="groupByChange"
          />

          <div v-if="!taskCount">
            <div v-if="!q" class="flex justify-center align-middle">
              <div class="px-4 py-32">
                <div class="flex justify-center">
                  <logo-task-not-assign class />
                </div>

                <div>
                  <div class="mt-4 mb-4 text-center text-xl font-medium	">
                    {{ $t('myTasks.no.task') }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="flex justify-center align-middle">
              <div class="px-4 py-32">
                <div class="flex justify-center">
                  <logo-task-search-not-found class />
                </div>

                <div>
                  <div class="mt-4 mb-4 text-center text-xl font-medium	">
                    {{ $t('myTasks.no.task') }}
                  </div>
                  <div class="text-center text-gray-600">
                    {{ $t('myTasks.filter.message') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="groupBy == 1 || groupBy == 2">
              <div
                v-for="(group, i) in groups"
                :key="`group-${i}-${group.isShowMore}`"
                class="mt-6 mb-6"
              >
                <base-collapse :title="group.title">
                  <div
                    v-if="group.tasks.length < 1"
                    class="p-8 text-gray-500 text-center"
                  >
                    {{ $t('myTasks.no.name') }}
                  </div>
                  <div v-else class="task">
                    <my-task-title />

                    <div
                      v-for="(task, i) in group.showMore && !group.isShowMore
                        ? group.tasks.slice(0, 3)
                        : group.tasks"
                      :key="`task-${i}`"
                      class="pb-4"
                    >
                      <my-task-task :task="task" @changeTasks="fetch" />
                    </div>
                    <div
                      v-if="group.showMore"
                      class="see-more py-2 text-gray-600 text-center pointer"
                      @click="
                        group.isShowMore = group.isShowMore ? false : true
                      "
                    >
                      {{
                        group.isShowMore
                          ? $t('myTasks.less')
                          : $t('myTasks.more')
                      }}
                    </div>
                  </div>
                </base-collapse>
              </div>
            </div>

            <div v-else class="mt-6 mb-6">
              <div
                v-if="tasks.length < 1"
                class="p-8 text-gray-500 text-center"
              >
                {{ $t('myTasks.no.name') }}
              </div>
              <div v-else>
                <my-task-title />
                <div class="task">
                  <div
                    v-for="(task, i) in tasks"
                    :key="`task-${i}`"
                    class="pb-4"
                  >
                    <my-task-task :task="task" @changeTasks="fetch" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <modal-task @closed="closeTask" />
    </main>
    <notifications group="task-copy" class="m-2" position="bottom right">
      <template slot="body" slot-scope="props">
        <div class="bg-green-success p-4 rounded">
          <p class="text-white text-lg mb-3">
            {{ $t('modelTask.copy.noti.new') }}
          </p>
          <base-button
            full
            color="white"
            wide="py-2"
            @click="onOpenTaskCopy(props)"
          >
            {{ $t('modelTask.copy.noti.see') }}
          </base-button>
        </div>
      </template>
    </notifications>
  </v-scroller>
</template>

<script>
import MyTaskHeader from '@/components/my-task/my-task-header';
import MyTaskTitle from '@/components/my-task/my-task-title';
import MyTaskTask from '@/components/my-task/my-task-task';

import LogoTaskSearchNotFound from '@/components/icons/LogoTaskSearchNotFound';
import LogoTaskNotAssign from '@/components/icons/LogoTaskNotAssign';

import ModalTask from '@/components/modal/modal-task.vue';
import VScroller from '@/components/scroller';

import { boardsApi } from '@/services/apis';
import LoadingMixin from '@/mixins/LoadingMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';

import EventBus from '@/services/eventBus';

export default {
  components: {
    MyTaskHeader,
    MyTaskTitle,
    MyTaskTask,
    LogoTaskSearchNotFound,
    LogoTaskNotAssign,
    ModalTask,
    VScroller
  },
  name: 'My-Tasks',
  mixins: [LoadingMixin, DateTimeMixin],
  data() {
    return {
      tasks: [],
      originTasks: [],
      groups: [],

      groupBy: 1,
      filter: 2,
      q: '',
      taskCount: 0,

      maxItemShowMore: 5
    };
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.fetch();
      },
      immediate: true
    }
  },
  async mounted() {
    this.groupBy = this.$refs.header.getGroupBy().value;
    this.filter = this.$refs.header.getFilter().value;

    EventBus.$on('MY_TASK_UPDATES', () => {
      this.fetch();
    });
  },
  methods: {
    async fetch() {
      const self = this;
      self.onLoading();

      const filter = {
        filterType: self.filter
      };
      const res = await boardsApi.tasks(filter);
      // diff date
      const list = res.data;
      list.forEach(e => {
        if (e.endAt) e.diffDate = self.dayDiff(e.endAt);
        else e.diffDate = undefined;
      });
      self.originTasks = res.data.sort((a, b) => (a.id > b.id ? -1 : 1));
      await self.group();

      self.offLoading();
    },
    async group() {
      const self = this;
      let originTasks = [];
      if (self.q) {
        originTasks = this.originTasks.filter(t => {
          return t.name
            ? t.name.toUpperCase().indexOf(self.q.toUpperCase()) >= 0
            : false || t.description
            ? t.description.toUpperCase().indexOf(self.q.toUpperCase()) >= 0
            : false;
        });
      } else {
        originTasks = this.originTasks;
      }

      if (this.groupBy == 1) {
        const g = [];

        const tasksNoDue = originTasks.filter(e => !e.endAt);
        g.push({
          title: this.$t('myTasks.no.date'),
          tasks: tasksNoDue,
          showMore: tasksNoDue.length > this.maxItemShowMore,
          isShowMore: this.groups[0] ? this.groups[0].isShowMore : false
        });

        const tasksDue = originTasks.filter(e => e.endAt);

        // overdue
        let tasksOver = tasksDue.filter(e => e.diffDate < 0);
        tasksOver = tasksOver.sort((a, b) => (a.endAt > b.endAt ? 1 : -1));
        g.push({
          title: this.$t('myTasks.overdue'),
          tasks: tasksOver,
          showMore: tasksOver.length > this.maxItemShowMore,
          isShowMore: this.groups[1] ? this.groups[1].isShowMore : false
        });

        // today
        let tasksToday = tasksDue.filter(e => e.diffDate == 0);
        tasksToday = tasksToday.sort((a, b) => (a.endAt > b.endAt ? 1 : -1));
        g.push({
          title: this.$t('myTasks.today'),
          tasks: tasksToday,
          showMore: tasksToday.length > this.maxItemShowMore,
          isShowMore: this.groups[2] ? this.groups[2].isShowMore : false
        });

        // upcoming
        let tasksUpcoming = tasksDue.filter(e => e.diffDate > 0);
        tasksUpcoming = tasksUpcoming.sort((a, b) =>
          a.endAt > b.endAt ? 1 : -1
        );
        g.push({
          title: this.$t('myTasks.upcoming'),
          tasks: tasksUpcoming,
          showMore: tasksUpcoming.length > this.maxItemShowMore,
          isShowMore: this.groups[3] ? this.groups[3].isShowMore : false
        });

        this.groups = g;
        this.taskCount = tasksNoDue.length + tasksDue.length;
      } else if (this.groupBy == 2) {
        // find borads
        const boardIds = [];
        const boards = [];
        originTasks.forEach(e => {
          if (!boardIds.includes(e.board.id)) {
            boards.push(e.board);
            boardIds.push(e.board.id);
          }
        });

        const groups = [];
        let taskCount = 0;
        boards.forEach((b, i) => {
          const tasks = originTasks.filter(e => e.board.id == b.id);
          groups.push({
            title: b.name,
            tasks: tasks,
            showMore: tasks.length > this.maxItemShowMore,
            isShowMore: this.groups[i] ? this.groups[i].isShowMore : false
          });
          taskCount += tasks.length;
        });

        this.groups = groups;
        this.taskCount = taskCount;
      } else {
        this.groups = {};
        this.tasks = originTasks;
        this.taskCount = originTasks.length;
      }
    },
    async filterChange(f) {
      this.filter = f.value;

      await this.fetch();
    },
    async groupByChange(g) {
      this.groupBy = g.value;

      await this.group();
    },
    async qChange(q) {
      this.q = q;

      await this.group();
    },
    closeTask() {
      this.$store.commit('tasks/CLOSE_TASK');
      this.$store.dispatch('tasks/unsubscribeTask');
    },
    async onOpenTaskCopy(props) {
      const { boardID: boardId, id: taskId } = props.item.data.task;
      try {
        await this.$store.dispatch('boards/fetchBoard', boardId);
        await this.$store.dispatch('tasks/fetchTask', taskId);
        this.$modal.show('task-detail');
      } catch (error) {
        const MESSAGE_DEFAULT = `Click here to hide this message. If you cannot proceed, try to refresh.`;
        const message = error.toString() || MESSAGE_DEFAULT;
        this.$notify({
          title: `${this.$t('notifications.popup.service')}`,
          text: `<p class="text-base">${message}</p>`,
          type: 'error',
          group: 'task-error',
          duration: -1
        });
      }
    }
  }
};
</script>
