import { AxiosRequestConfig } from 'axios';

interface IgnoreNotification {
  (config: AxiosRequestConfig): boolean;
}
const ignoreSignUp: IgnoreNotification = c => {
  return (
    (c.url as string).includes('register') &&
    JSON.parse(c.data).registerType === 1
  );
};
const ignoreResetPassword: IgnoreNotification = c => {
  return (c.url as string).includes('resetPassword');
};
const ignoreForgotPassword: IgnoreNotification = c => {
  return (c.url as string).includes('forgotPassword');
};
const ignoreGuestLogin: IgnoreNotification = c => {
  return (
    (c.url as string).includes('login') && JSON.parse(c.data).loginType === 5
  );
};
const ignoreMemberLogin: IgnoreNotification = c => {
  return (
    (c.url as string).includes('login') && JSON.parse(c.data).loginType === 1
  );
};
const getIgnoreErrorNotification = (config: AxiosRequestConfig) => {
  const list = [
    ignoreGuestLogin,
    ignoreMemberLogin,
    ignoreResetPassword,
    ignoreSignUp,
    ignoreForgotPassword
  ];
  return () => list.some(getShouldIgnore => getShouldIgnore(config));
};

export default getIgnoreErrorNotification;
