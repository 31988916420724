<template>
  <div class="my-3 rounded-md overflow-hidden border border-gray-200 shadow-md">
    <div v-show="isToggle" class="h-1 bg-primary"></div>
    <div class="flex items-center">
      <base-icon :icon="icon" class="mx-3" height="22" width="22" />
      <div class="flex-1 py-2">{{ title }}</div>
      <div class="px-3 cursor-pointer" @click="isToggle = !isToggle">
        <base-icon
          width="16"
          class=" transform "
          :class="{ 'rotate-180': isToggle }"
        >
          <icon-chevron-down />
        </base-icon>
      </div>
    </div>
    <div v-show="isToggle" class="px-3 pb-2 text-gray-500">
      <slot />
    </div>
  </div>
</template>

<script>
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
export default {
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    icon: {
      type: String
    }
  },
  components: { IconChevronDown },
  computed: {},
  data() {
    return {
      isToggle: this.toggle
    };
  }
};
</script>

<style></style>
