<template>
  <div class="flex flex-wrap -mx-2" :class="[justify]">
    <div class="px-2">
      <base-button
        :wide="wide"
        color="transparent"
        type="button"
        @click="cancel"
      >
        {{ cancelText || $t('alert.button.cancel') }}
      </base-button>
    </div>
    <div class="px-2">
      <slot name="confirm" :attributes="confirmButtonAttributes">
        <base-button v-bind="confirmButtonAttributes" @click="confirm">
          {{ confirmText }}
        </base-button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    right: Boolean,
    disabled: Boolean,
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String
    },
    wide: {
      type: String,
      default: 'px-8 py-2'
    }
  },
  computed: {
    justify() {
      return this.right ? 'justify-end' : '';
    },
    confirmButtonAttributes() {
      return {
        disabled: this.disabled,
        wide: this.wide,
        color: this.disabled ? 'disabled' : 'brand',
        capitalized: true
      };
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    }
  }
};
</script>

<style></style>
