






































import Vue, { PropType } from 'vue';
import ParagraphEditable from '@/components/paragraph-editable.vue';
import { AxiosPromise } from 'axios';
import { Board, BoardCopyRequest } from '@/types/Board';

export default Vue.extend({
  components: {
    ParagraphEditable
  },
  props: {
    board: {
      type: Object as PropType<Board>
    }
  },
  data() {
    return {
      name: '',
      loading: false
    };
  },
  created() {
    this.setName();
  },
  methods: {
    setName() {
      this.name = `[Copy] ${this.board.name}`;
    },
    onClosed() {
      this.$emit('close');
      this.name = '';
    },
    async onSumbit() {
      this.loading = true;
      try {
        const { data } = await this.copyBoard({
          id: this.board.id,
          name: this.name
        });
        await this.$store.dispatch('boards/fetchBoards');
        this.$notify({
          group: 'board-copy',
          data: {
            board: data
          }
        });
      } catch (error) {
        this.$notify({
          group: 'app-noti',
          type: 'error',
          title: error?.response?.data.message
        });
      }

      this.loading = false;
      this.onClosed();
    },
    copyBoard(copy: BoardCopyRequest): AxiosPromise<Board> {
      return this.$store.dispatch('boards/copyBoard', copy);
    }
  }
});
