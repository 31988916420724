<template>
  <div class="grid">
    <workspace-member />
  </div>
</template>

<script>
import WorkspaceMember from '@/components/workspace/workspace-member';

export default {
  components: {
    WorkspaceMember
  },
  async created() {
    await this.$store.dispatch('subscription/fetchCurrentPlan');
  },
  mounted() {
    document.body.classList.add('vm--block-scroll');
  },
  methods: {}
};
</script>
