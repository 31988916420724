<template>
  <div>
    <div class="bg-other w-full h-2" />
    <div class="my-3 flex">
      <base-icon class="mt-1">
        <icon-report-unplan />
      </base-icon>
      <div>
        <span>งานที่ไม่ได้วางแผน</span>
        <p class="text-gray-caption">
          งานที่มีการเลือกบอร์ดแต่ไม่มีการเลือกงาน
          ดังนั้นงานเหล่านี้จึงถูกนับว่าเป็นงานที่ไม่ได้วางแผน
        </p>
      </div>
    </div>
    <div v-for="(board, index) in boards" :key="index" class="w-full mb-4">
      <base-icon class="ml-5" name="board" view-box="0 2 18 14" size="20">
        <icon-board />
      </base-icon>
      <span>บอร์ด: {{ board.Name }}</span>
      <div class="text-sm font-normal text-left">
        <div class="border px-4 rounded">
          <div
            v-for="(task, index) in board.UnPlanTimeLogs"
            :key="index"
            class="flex"
            :class="{ 'border-b': index < board.UnPlanTimeLogs.length - 1 }"
          >
            <div>
              <div class="align-top py-2 text-left">
                {{ task.Description }}
              </div>
              <div class="flex text-sm mb-2">
                <div class="flex mr-12">
                  <div class="mr-2 self-center">
                    <img class="profile-img" :src="task.User.profilePicture" />
                  </div>
                  <div>
                    <p>{{ task.User.name }}</p>
                    <p class="text-gray-caption">{{ task.User.email }}</p>
                  </div>
                </div>

                <div class="align-top mr-12">
                  <div class="text-sm text-gray-caption underline">
                    วันเริ่ม/สิ้นสุด
                  </div>
                  <span> {{ formatShowDate(task.startAt, task.endAt) }} </span>
                </div>
                <div class="align-top">
                  <div class="text-sm text-gray-caption underline">
                    บันทึกเวลาทั้งหมด
                  </div>
                  <span> {{ task.TimeSpentString }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconReportUnplan from '@/components/icons/IconReportUnplan.vue';
import IconBoard from '@/components/icons/IconBoard.vue';
import FormatStartEndDate from '@/mixins/FormatStartEndDate.ts';
import FormatTimeSpent from '@/mixins/FormatTimeSpent.ts';

export default {
  mixins: [FormatStartEndDate, FormatTimeSpent],
  components: { IconReportUnplan, IconBoard },
  props: { boards: [] }
};
</script>
<style scoped>
.profile-img {
  @apply rounded-full;
  width: 30px;
  height: 30px;
}
</style>
