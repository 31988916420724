<template>
  <button
    class="button"
    :class="classes"
    :disabled="loading || disabled"
    @click="click"
  >
    <div v-show="loading" class="button__loader opacity-100">
      <div class="button__loader--spinning" />
    </div>
    <div class="button__body" :class="[justify, { invisible: loading }]">
      <base-icon
        v-if="Icon"
        :data-testid="`icon-${icon}`"
        :name="icon"
        :view-box="iconViewBox"
        :size="iconSize"
      >
        <component :is="Icon" />
      </base-icon>
      <fa-icon v-if="faIcon" :icon="faIcon" />
      <span
        v-if="hasSlot || hasText"
        class="flex items-center overflow-hidden"
        :class="[
          justify,
          {
            'hidden sm:inline-block': responsive,
            capitalize
          }
        ]"
      >
        <slot />
      </span>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    to: {
      type: [Object, String],
      default: ''
    },
    full: Boolean,
    hasText: Boolean,
    color: {
      type: String,
      default: 'brand'
    },
    wide: {
      type: String,
      default: 'px-3'
    },
    faIcon: {
      type: [String, Array]
    },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: [String, Number],
      default: undefined
    },
    iconViewBox: {
      type: String,
      default: '0 0 24 24'
    },
    responsive: Boolean,
    disabled: Boolean,
    loading: Boolean,
    shadowless: Boolean,
    borderless: Boolean,
    borderDashed: Boolean,
    capitalize: Boolean,
    hoverable: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: String,
      default: 'rounded'
    },
    justify: {
      type: String,
      default: 'justify-center'
    }
  },
  data() {
    return {
      Icon: null
    };
  },
  computed: {
    colorClass() {
      const colors = {
        white: 'bg-white text-black border',
        indigo: 'bg-indigo-600',
        red: 'bg-red-600',
        green: 'bg-green-600',
        yellow: 'bg-yellow-600',
        gray: 'bg-gray-caption',
        'gray-outline': 'border-gray-main-dark-ligh text-gray-caption',
        'red-outline': 'text-red-600 border-red-600 ',
        gray2: 'bg-gray-200 text-gray-800',
        brand: 'bg-brand-blue',
        'brand-outline': 'text-brand-blue border-brand-blue',
        light: 'bg-gray-main text-gray-800',
        'light-outline': 'border-gray-main-dark-light text-black',
        dark: 'bg-gray-800',
        disabled: 'bt-disabled',
        link: 'button--link',
        transparent: 'bg-transparent text-black hover:bg-light-gray',
        text: 'text-black',
        'text-danger': 'text-red-danger',
        'text-success': 'text-green-success',
        'text-indigo': 'text-indigo-600',
        'text-brand': 'text-brand-blue',
        'text-outline': 'border-gray-main-dark-light text-black',
        success: 'bg-green-success',
        danger: 'bg-red-danger',
        'danger-outline': 'text-red-danger border-red-danger'
      };
      const matchClass = [colors[this.color] || this.color];
      const hasClass = classList => classList.some(e => this.color.includes(e));
      const addClassWhen = (newClass, conditions) =>
        hasClass(conditions) && matchClass.push(newClass);

      addClassWhen('shadow-none', ['transparent', 'outline', 'text']);
      addClassWhen('border', ['transparent', 'outline']);
      addClassWhen('bg-transparent hover:underline button--text', ['text']);

      return matchClass.join(' ');
    },
    hasSlot() {
      return !!this.$slots.default;
    },
    roundedClass() {
      switch (this.rounded) {
        case null:
          return '';
        case 'sm':
          return 'rounded-sm';
        case 'circle':
          return 'rounded-full';
        default:
          return 'rounded';
      }
    },
    classes() {
      const activeClasses = {
        'w-full': this.full,
        'border-dashed': this.borderDashed,
        shadowless: this.shadowless,
        borderless: this.borderless,
        transparent: this.transparent,
        'hover--dark': this.hoverable,
        'cursor-default': !this.hoverable
      };
      return [this.colorClass, this.roundedClass, this.wide, activeClasses];
    }
  },
  watch: {
    icon: {
      immediate: true,
      handler(icon) {
        if (!icon) this.Icon = null;
        else {
          const replaceDash = str =>
            str.replace(/-([a-z])/, (match, p1) => p1.toUpperCase());
          const capitalName = str => `${str[0].toUpperCase()}${str.slice(1)}`;
          const iconName = replaceDash(capitalName(icon));
          this.Icon = () => import(`@/components/icons/Icon${iconName}`);
        }
      }
    }
  },
  methods: {
    click(event) {
      const self = this;
      if (self.to) {
        const res = self.$router.push(`/${self.$i18n.locale}${self.to}`);
        res.then(undefined, function(err) {
          self.$router.go(`/${self.$i18n.locale}${self.to}`);
        });
      } else this.$emit('click', event);
    }
  }
};
</script>

<style scoped>
.button__body {
  @apply whitespace-no-wrap flex items-center w-full;
}
.button__loader {
  @apply absolute inset-0 flex justify-center items-center;
}
.button__loader--spinning {
  display: inline-block;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2d3748;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.bt-disabled {
  @apply border bg-gray-main border-gray-main-dark-light;
  color: #333;
}

.button.shadowless {
  @apply shadow-none;
}

.button.borderless {
  @apply border-none;
}
.button--text {
  background-color: transparent !important;
}
.button--text:disabled {
  @apply no-underline text-gray-label;
}
.button--link {
  @apply p-0 shadow-none font-medium text-indigo-600 bg-transparent;
}
.button--link:disabled {
  @apply bg-transparent;
}
.button--link:hover {
  @apply underline;
}
.button.board {
  @apply px-8 py-2 text-sm;
}
.button.board--shrink {
  @apply py-2 text-sm;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
