import { RootState } from '@/types/Store';
import { MutationTree } from 'vuex';

const mutations: MutationTree<RootState> = {
  SET_MEMBER_VISIBLE(state, value: boolean) {
    state.memberVisible = value;
  }
};

export default mutations;
