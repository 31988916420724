import { NotificationSetting } from './Notification';
import { ApiCommonFields } from './Api';

export enum UserLanguage {
  ENGLISH = 'en',
  THAI = 'th'
}

export enum UserRole {
  COMMON = 10,
  PRIVILEGE = 100
}

export interface UserUpdate {
  bio: string;
  color: string;
  name: string;
  profilePicture: string;
  language: string;
}

export interface User extends UserUpdate, ApiCommonFields {
  bio: string;
  colorHex: string;
  email: string;
  facebookId: string;
  googleId: string;
  initialsName: string;
  isVerifyEmail: true;
  name: string;
  notificationSetting: NotificationSetting;
  profilePicture: string;
  role: UserRole;
  language: UserLanguage;
  deactivatedAt?: string;
  deactivatedReason?: string;
}
