var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.endAt),expression:"endAt"}],staticClass:"self-center whitespace-no-wrap"},[(_vm.diffDate == 0)?_c('div',{class:{
      'badge-danger': _vm.status != 2,
      'badge-success': _vm.status == 2
    }},[_vm._v(" "+_vm._s(_vm.$t('myTasks.today'))+" ")]):(_vm.diffDate == 1)?_c('div',{class:{
      'badge-warning': _vm.status != 2,
      'badge-success': _vm.status == 2
    }},[_vm._v(" "+_vm._s(_vm.$t('myTasks.tomorrow'))+" ")]):(_vm.diffDate < 0)?_c('div',{class:{
      'badge-danger': _vm.status != 2,
      'badge-success': _vm.status == 2
    }},[_vm._v(" "+_vm._s(_vm.getDate(_vm.endAt))+" ")]):_c('div',{class:{
      'badge-gray': _vm.status != 2,
      'badge-success': _vm.status == 2
    }},[_vm._v(" "+_vm._s(_vm.getDate(_vm.endAt))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }