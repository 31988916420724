<template>
  <modal v-bind="modalProps" v-on="$listeners">
    <v-scroller
      class="relative"
      height="100%"
      @bottom="$emit('bottom', $event)"
    >
      <div class="flex flex-col min-h-full">
        <div
          class="content--padding modal-box__header"
          :class="{ 'modal-box__header--b-0': headerBorderless }"
        >
          <p class="text-2xl font-medium">
            <slot name="header" />
          </p>
          <base-button
            icon="close"
            icon-size="18"
            wide="p-0"
            color="text"
            @click="close"
          />
        </div>
        <div class="content--padding relative z-0 flex-1">
          <slot />
        </div>
      </div>
    </v-scroller>
  </modal>
</template>

<script>
import VScroller from '@/components/scroller';
export default {
  components: {
    VScroller
  },
  props: {
    name: {
      type: String,
      required: true
    },
    headerBorderless: {
      type: Boolean,
      default: false
    },
    height: {
      type: [Number, String],
      default: '100%'
    }
  },
  computed: {
    modalProps() {
      return {
        ...this.$attrs,
        adaptive: true,
        height: this.height,
        maxHeight: 600,
        scrollable: true,
        name: this.name
      };
    }
  },
  methods: {
    close() {
      this.$modal.hide(this.name);
    }
  }
};
</script>

<style scoped>
.content--padding {
  @apply pl-6 pr-6 py-5;
}
.modal-box__header {
  @apply border-b flex justify-between items-center sticky top-0 bg-white;
  z-index: 1;
}
.modal-box__header--b-0 {
  @apply border-0;
}
</style>
