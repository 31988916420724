<template>
  <div>
    <div>
      <div class="flex">
        <tab-select
          v-model="tabActive"
          :tabs="tabs"
          class="divide-x border border-b-0"
        >
          <template #default="{ tab }">
            <div
              class="p-2 cursor-default"
              :class="{ 'cursor-pointer hover:bg-gray-100': !tab.active }"
            >
              <div class="flex ">
                <div class="">
                  <component
                    :is="tab.item"
                    :fill="tab.active ? '#168AE2' : '#cbd5e0'"
                  />
                </div>
                <div
                  v-if="tab.index === 0"
                  class="mx-2 font-medium"
                  :class="tab.active ? 'selected' : 'notselected'"
                >
                  {{$t('timelog.tab.timer')}}
                </div>
                <div
                  v-else
                  class="mx-2 font-medium"
                  :class="tab.active ? 'selected' : 'notselected'"
                >
                   {{$t('timelog.tab.manually')}}
                </div>
              </div>
            </div>
          </template>
        </tab-select>
      </div>
    </div>

    <div class="border p-5">
      <time-log-timer v-if="tabActive === 0" />
      <time-log-manual v-else-if="tabActive === 1" />
    </div>
  </div>
</template>

<script>
import TabSelect from './tab-select';
import PlaylistAdd from '@/assets/icons/playlist-add';
import ClockTimer from '@/assets/icons/clock-timer';
import TimeLogTimer from './tab-time-log-starter/time-log-timer';
import TimeLogManual from './tab-time-log-starter/time-log-manual';

export default {
  components: {
    TabSelect,
    PlaylistAdd,
    TimeLogTimer,
    TimeLogManual
  },
  data() {
    return {
      tabActive: 0
    };
  },
  computed: {
    tabs() {
      return [ClockTimer, PlaylistAdd];
    }
  }
};
</script>

<style scoped>
.selected {
  color: #168ae2;
}
.notselected {
  color: #cbd5e0;
}</style
>>
