





























import { Vue, Prop, Component, Emit } from 'vue-property-decorator';
import { ListItem } from '@/types/BaseComponents';

@Component
export default class BaseList extends Vue {
  @Prop({ default: () => [] }) readonly items!: ListItem[];
  @Prop({ default: 3 }) readonly maximum!: number;
  @Prop({ type: Boolean }) readonly bordered?: boolean;
  @Prop({ type: Boolean }) readonly keepMore?: boolean;
  @Prop({ type: String }) readonly btnClass?: string;
  @Prop({ type: String }) readonly listClass?: string;

  isVisible = false;

  get visibleItems(): ListItem[] {
    return this.isVisible ? this.items : this.items.slice(0, this.maximum);
  }

  get buttonVisible(): boolean {
    return this.maximum < this.items.length;
  }

  @Emit()
  viewMore(ev: MouseEvent) {
    if (!this.keepMore) {
      this.isVisible = !this.isVisible;
    }
    return ev;
  }

  get more(): number {
    return this.items.length - this.visibleItems.length;
  }
}
