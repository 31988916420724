<template>
  <div class="flex justify-between mb-3">
    <div>
      <span class="font-medium text-xl">{{ $t('timelog.logged.name') }}</span>
      <span v-if="totalTime" class="text-gray-600 text-sm ml-5">{{
        totalTimeDisplay
      }}</span>
    </div>
    <div class="flex items-center">
      <span class="text-sm">{{ $t('timelog.logged.view') }}</span>
      <tab-select :value="active" class="ml-3" :tabs="tabs" @input="changeTab">
        <template #default="{ tab }">
          <div
            class="tab-changer"
            :class="{ 'tab-changer--active': tab.active }"
          >
            <img width="18" height="18" :src="tab.item" />
          </div>
        </template>
      </tab-select>
    </div>
  </div>
</template>

<script>
import TabSelect from '@/components/tab/tab-select';
import ChartH from '@/assets/icons/chart-horizontal.svg';
import ChartV from '@/assets/icons/chart-vertical.svg';

const milisecMins = 60000;
const milisecHr = milisecMins * 60;
const divTime = (time, div) => Math.floor(time / div);
export default {
  components: {
    TabSelect
  },
  props: {
    active: {
      type: Number,
      default: 0
    },
    totalTime: {
      type: Number,
      default: 0
    }
  },
  computed: {
    tabs() {
      return [ChartH, ChartV];
    },
    totalTimeDisplay() {
      const total = (strings, hr, mins) => {
        const dHr = hr ? ` ${hr} hrs` : '';
        const dMins = mins ? ` ${mins} mins` : '';
        return `Total${strings[0]}${dHr}${dMins}`;
      };
      const leftoverMinutes = this.totalTime % milisecHr;
      const hours = divTime(this.totalTime, milisecHr);
      const minutes = divTime(leftoverMinutes, milisecMins);
      return total`${hours}${minutes}`;
    }
  },
  methods: {
    changeTab(val) {
      this.$emit('update:active', val);
    }
  }
};
</script>

<style scoped>
.tab-changer {
  @apply p-2 rounded;
}
.tab-changer.tab-changer--active {
  @apply bg-gray-200;
}
.tab-changer:not(:hover):not(.tab-changer--active) img {
  @apply opacity-50;
}
</style>
