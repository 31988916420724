




































































import Vue, { PropType } from 'vue';
import { Board } from '@/types/Board';
import { directive as onClickaway } from 'vue-clickaway';

export default Vue.extend({
  props: {
    board: {
      type: Object as PropType<Board | Partial<Board>>,
      default(): Partial<Board> {
        return {
          name: '',
          description: ''
        };
      }
    }
  },
  directives: {
    onClickaway
  },
  data() {
    return {
      name: '',
      description: '',
      nameOriginal: '',
      descriptionOriginal: '',
      isFoucusName: false,
      isFocusDescription: false
    };
  },
  computed: {
    changes(): Partial<Board> {
      const changes: Partial<Board> = {};
      if (this.name !== this.board.name) changes.name = this.name;
      if (this.description !== this.board.description)
        changes.description = this.description;
      return changes;
    },
    changed(): boolean {
      return Object.keys(this.changes).length > 0;
    },
    isNameEmpty(): boolean {
      return this.name === '';
    },
    isFocusInput(): boolean {
      return this.isFoucusName || this.isFocusDescription;
    }
  },
  watch: {
    board: {
      immediate: true,
      handler(value: Board) {
        this.setForm(value);
      }
    }
  },
  mounted() {
    const self = this;
    const modalElement = document.getElementsByClassName(
      'vm--overlay'
    )[0] as HTMLElement;
    modalElement.onclick = function() {
      self.closeModal();
    };
  },
  methods: {
    closeModal() {
      if (!this.isFocusInput) {
        this.save();
        this.$emit('close');
      } else {
        this.resetFocusName();
        this.resetFocusDescription();
      }
    },
    setForm({ name, description }: Board) {
      this.nameOriginal = name;
      this.descriptionOriginal = description;
      this.name = name;
      this.description = description;
    },
    async save() {
      if (this.changed) {
        const changes = { ...this.changes, id: this.board.id };
        await this.$store.dispatch('boards/editBoard', changes);
        this.$notify({
          title: `${this.$t('notifications.update_success', [
            this.$t('board.title')
          ])}`,
          type: 'success',
          group: 'app-noti'
        });
        this.onCancel();
      }
    },
    onCancel() {
      this.name = this.nameOriginal;
      this.description = this.descriptionOriginal;
      this.$emit('close');
    },
    resetFocusName() {
      this.isFoucusName = false;
    },
    resetFocusDescription() {
      this.isFocusDescription = false;
    }
  }
});
