import BoardsHome from '../views/BoardsHome.vue';
import BoardsList from '../views/BoardsList.vue';
import MyTasks from '../views/MyTasks.vue';
import BoardsArchive from '../views/BoardsArchive.vue';
import PersonalReport from '../views/PersonalReport.vue';
import TimeLog from '../views/TimeLog.vue';
import Admin from '../views/Admin.vue';
import BoardColumns from '../views/BoardColumns.vue';
import BoardsId from '../views/BoardsId.vue';
import TasksId from '../views/TasksId.vue';
import Link from '../views/Link.vue';
import SubScription from '../views/SubScription.vue';
import { RouteConfig } from 'vue-router';
import { jwtValidate } from './utils';
import TeamId from '../views/TeamsId.vue';
import { store } from '@/store';
import router from '@/router';

const JUST_ROUTER_VIEW_TEMPLATE = '<router-view></router-view>';

export enum BoardRoutNames {
  Kanban = 'Board-Columns',
  Waterfall = 'Board-Waterfall',
  Backlog = 'Board-Backlog'
}

export enum TaskRouteName {
  Kanban = 'Board-Columns-Task',
  Waterfall = 'Board-Waterfall-Task',
  Backlog = 'Board-Backlog-Task'
}

const boardChildrens: RouteConfig[] = [
  {
    path: '',
    name: 'Boards-Home',
    component: BoardsHome,
    children: [
      {
        path: '',
        name: 'Boards-List',
        component: BoardsList
      },
      {
        path: ':id/completed',
        name: 'TransactionsCompleted',
        component: BoardsList
      },
      {
        path: 'stared-boards',
        name: 'Stared-Boards',
        component: BoardsList
      },
      {
        path: 'recently-boards',
        name: 'Recently-Boards',
        component: BoardsList
      },
      {
        path: 'my-tasks',
        name: 'My-Tasks',
        component: MyTasks
      },
      {
        path: 'time-log',
        name: 'Time-Log',
        component: TimeLog
      },
      {
        path: 'archive-boards',
        name: 'Archive-Boards',
        component: BoardsArchive
      },
      {
        path: 'personal-report',
        name: 'Personal-Report',
        component: PersonalReport
      },
      {
        path: 'admin',
        name: 'board-admin',
        component: Admin,
        meta: {
          accessibleRoles: [100]
        }
      },
      {
        path: 'teams',
        name: 'Teams',
        component: () => import('@/views/Teams.vue'),
        meta: {
          authRequired: true
        },
        beforeEnter: (to, from, next) => {
          return jwtValidate(to, from, next);
        },
        children: [
          {
            path: ':id',
            name: 'Teams-Id',
            component: TeamId,
            children: [
              {
                path: '',
                name: 'Teams-Board',
                component: () => import('@/views/TeamsBoard.vue')
              },
              {
                path: 'starred',
                name: 'Workspace-Star-Board',
                component: () => import('@/views/WorkspaceBoardStarred.vue')
              },
              {
                path: 'dashboard',
                name: 'Teams-Dashboard',
                component: () => import('@/views/TeamsDashboard.vue')
              },
              {
                path: 'member',
                name: 'Teams-Members',
                component: () => import('@/views/TeamsMembers.vue')
              },
              {
                path: 'timeline',
                name: 'Teams-Timeline',
                component: () => import('@/views/TeamsTimeline.vue')
              },
              {
                path: 'calendar',
                name: 'Teams-Calendar',
                component: () => import('@/views/TeamsCalendar.vue')
              },
              {
                path: 'report',
                name: 'Teams-Report',
                component: () => import('@/views/TeamsReport.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'link',
    name: 'Boards-Link',
    component: Link,
    children: [
      {
        name: 'Boards-Link-Task',
        path: ':linkId',
        component: TasksId
      }
    ]
  },
  {
    path: 'subscription',
    name: 'Subscription',
    component: SubScription,
    beforeEnter: (to, from, next) => {
      store.dispatch('auth/fetchMe').then(
        response => {
          const profile = store.getters['auth/getUser'];

          if (
            (profile.haveActiveSubscription || !profile.canFreeTrial) &&
            !profile.subscriptionExempted
          ) {
            next();
          } else {
            router.push({ name: 'Boards-List' });
          }
        },
        error => {
          // handle error here
        }
      );
    },
    children: [
      {
        name: 'Subscription-Overview',
        path: '/',
        component: () =>
          import('@/components/subscription/subscriptionOverview.vue')
      },
      {
        name: 'Subscription-Payment',
        path: 'payment',
        component: () =>
          import('@/components/subscription/subscriptionPayment.vue')
      },
      {
        name: 'Subscription-SeatManagement',
        path: 'seatmanagement',
        component: () =>
          import('@/components/subscription/subscriptionSeatManagement.vue')
      },
      {
        name: 'Subscription-Space',
        path: 'space',
        component: () =>
          import('@/components/subscription/subscriptionSpace.vue')
      }
    ]
  },
  {
    path: ':id',
    name: 'Boards-Id',
    component: BoardsId,
    children: [
      {
        name: 'Board-Normal',
        path: 'normal',
        component: {
          template: JUST_ROUTER_VIEW_TEMPLATE
        },
        redirect: { name: 'Board-Columns' },
        children: [
          {
            name: 'Board-Columns',
            path: 'columns',
            component: BoardColumns,
            meta: {
              hideMember: true
            },
            children: [
              {
                name: TaskRouteName.Kanban,
                path: 't/:task_id',
                component: TasksId
              }
            ]
          },
          {
            path: 'dashboard',
            name: 'Board-Dashboard',
            component() {
              return import('@/views/BoardDashboard.vue');
            },
            meta: {
              hideMember: true
            }
          },
          {
            path: 'timeline',
            name: 'Board-Timeline',
            meta: {
              hideMember: true
            },
            component() {
              return import('@/views/BoardTimeline.vue');
            },
            children: [
              {
                name: 'Board-Timeline-Task',
                path: 't/:task_id',
                component: TasksId,
                meta: (route: { params: { disable: boolean } }) => ({
                  disableTask: route.params.disable
                })
              }
            ]
          }
        ]
      },
      // * waterfall board
      {
        path: 'waterfall',
        name: 'Board-Waterfall',
        component: () => import('@/views/BoardWaterfall.vue'),
        children: [
          {
            name: TaskRouteName.Waterfall,
            path: 't/:task_id',
            component: TasksId
          }
        ]
      },
      // * agile board
      {
        path: 'agile',
        name: 'Board-Agile',
        component: () => import('@/views/BoardAgile.vue'),
        redirect: { name: 'Board-Sprint' },
        children: [
          {
            name: 'Board-Backlog',
            path: 'backlog',
            meta: {
              hideMember: true
            },
            component: () => import('@/views/BoardAgileBacklog.vue'),
            children: [
              {
                name: TaskRouteName.Backlog,
                path: 't/:task_id',
                component: TasksId
              }
            ]
          },
          {
            name: 'Board-Sprint',
            path: 'sprint',
            meta: {
              hideMember: true
            },
            component: () => import('@/views/BoardAgileSprint.vue'),
            children: [
              {
                name: 'Board-Sprint-Task',
                path: 't/:task_id',
                component: TasksId
              }
            ]
          },
          {
            name: 'Board-Completed-Sprint',
            path: 'completed',
            component: () => import('@/views/BoardAgileCompleted.vue'),
            meta: {
              hideMember: true
            }
          },
          {
            name: 'Board-Completed-Sprint-Id',
            path: 'completed/:sprint_id',
            meta: {
              hideMember: true
            },
            component: () => import('@/views/BoardAgileCompletedSprint.vue'),
            children: [
              {
                name: 'Board-Completed-Sprint-Task',
                path: 't/:task_id',
                component: TasksId,
                props: {
                  modalName: 'task-detail-completed'
                }
                // meta: {
                //   disabledTask: true
                // }
              }
            ]
          },
          {
            path: 'timeline',
            name: 'Board-Agile-Timeline',
            meta: {
              hideMember: true
            },
            component() {
              return import('@/views/BoardTimeline.vue');
            },
            children: [
              {
                name: 'Board-Agile-Timeline-Task',
                path: 't/:task_id',
                component: TasksId,
                meta: (route: { params: { disable: boolean } }) => ({
                  disableTask: route.params.disable
                })
              }
            ]
          }
        ]
      },
      // * report
      {
        path: 'report/:type',
        name: 'export-report',
        meta: {
          hideMember: true
        },
        component() {
          return import('@/views/BoardReport.vue');
        }
      }
    ]
  }
];

export default boardChildrens;
