import Vue from 'vue';

import upperFirst from 'lodash.upperfirst';
import camelCase from 'lodash.camelcase';

const requireComponent = require.context(
  // The relative path of the components folder
  '../components/base',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /base-(\w+-?)+\.(vue|js)$/
);

requireComponent.keys().forEach(filePath => {
  // Get component config
  const componentConfig = requireComponent(filePath);

  const fileName = filePath.split('/').pop();

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      (fileName as string).replace(/\.\w+$/, '')
    )
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});
