import { RootState } from '@/types/Store';
import { ActionTree } from 'vuex';

const defaultReset = { members: true, tags: true };

const actions: ActionTree<RootState, RootState> = {
  setMemberVisible({ commit }, visible: boolean) {
    commit('SET_MEMBER_VISIBLE', visible);
  },
  resetTaskFilters({ commit }, reset = defaultReset) {
    const rootCommit = (type: string) => commit(type, [], { root: true });
    if (reset.members) rootCommit('members/SET_FILTER_IDS');
    if (reset.tags) rootCommit('tags/SET_FILTER_IDS');
  }
};

export default actions;
