<template>
  <div
    :class="{ 'border-b': bottomBorder }"
    class="flex justify-between items-center"
  >
    <div>
      <div class="flex items-center flex-wrap">
        <div
          v-if="clearRight && shouldShowSearchResult"
          class="mr-4 pr-4 border-r"
        >
          <base-button
            color="brand-outline"
            class="bg-brand-lighter text-sm"
            @click="clearFilters"
          >
            {{ $tc('counts.searchResult', counts || taskCounts) }}
            <fa-icon class="ml-2" icon="times-circle" />
          </base-button>
        </div>
        <div class="flex py-2 space-x-5 ">
          <form-field
            v-if="search && !searchRight"
            row
            :title="search.label ? $t('agile.backlog.search.search') : ''"
            :title-styles="{ color: '#333', 'font-size': '1rem' }"
          >
            <base-input-text
              v-model="fetchTasksString"
              class="input-pr-8"
              :placeholder="placeholder"
              inner-class="base-input--gray"
              :style="{
                width: search.width + 'px'
              }"
              icon-fa="search"
              :icon-right="fetchTasksString ? 'IconClose' : null"
              @icon-right-click="fetchTasksString = ''"
            />
          </form-field>
          <div v-if="!searchRight" class="form-field">
            <select-tags v-model="fetchTasksTags">
              <base-button color="transparent" wide="px-4 py-2">
                {{ $t('agile.backlog.tag') }}
                <span v-if="countActiveTags">
                  :
                  <span class="rounded-full px-3 badge--brand text-sm">
                    {{ countActiveTags }}
                  </span>
                </span>
                <base-icon class="ml-1" icon="chevron-down" size="14" />
              </base-button>
            </select-tags>
          </div>
        </div>
        <div class="flex py-2 items-center">
          <div class="form-field">
            <board-members ref="selectMembers" :should-select-commit="false" />
          </div>
          <div v-if="!isUserViewer && invite" class="form-field">
            <base-button
              wide="py-2"
              icon="UserPlus"
              icon-size="19"
              responsive
              class="bg-white text-sm"
              color="brand-outline"
              @click="openMemberInvite"
            >
              {{ $t('myBoard.invite') }}
            </base-button>
          </div>

          <div v-if="searchRight" class="form-field">
            <select-tags v-model="fetchTasksTags">
              <base-button color="transparent" wide="px-4 py-2">
                {{ $t('agile.backlog.tag') }}
                <span v-if="countActiveTags">
                  :
                  <span class="rounded-full px-3 badge--brand text-sm">
                    {{ countActiveTags }}
                  </span>
                </span>
                <base-icon class="ml-1" icon="chevron-down" size="14" />
              </base-button>
            </select-tags>
          </div>

          <form-field
            v-if="search && searchRight"
            row
            :title="search.label ? $t('agile.backlog.search.search') : ''"
            :title-styles="{ color: '#333', 'font-size': '1rem' }"
          >
            <base-input-text
              v-model="fetchTasksString"
              class="input-pr-8"
              :placeholder="placeholder"
              inner-class="base-input--gray"
              :style="{
                width: search.width + 'px'
              }"
              icon-fa="search"
              :icon-right="fetchTasksString ? 'IconClose' : null"
              @icon-right-click="fetchTasksString = ''"
            />
          </form-field>
          <div
            v-if="!clearRight && shouldShowSearchResult"
            class="ml-2 pl-4 border-l"
          >
            <base-button
              color="brand-outline"
              class="bg-brand-lighter text-sm"
              @click="clearFilters"
            >
              {{ $tc('counts.searchResult', counts || taskCounts) }}
              <fa-icon class="ml-2" icon="times-circle" />
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShowSelectTask">
      <div v-if="!isShowSelect">
        <base-button
          color="white"
          class="text-sm "
          icon="CheckListBox"
          @click="clickIsShowSelect"
        >
          Select Task
        </base-button>
      </div>
      <div v-else>
        <base-button color="white" class="text-sm " @click="clickIsShowSelect">
          Cancel
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import FetchTasks from '@/mixins/FetchTasks';
import BoardMembers from '../board/board-members.vue';
import FormField from '../forms/form-field';
import SelectTags from '../select/select-tags';
export default {
  mixins: [FetchTasks],
  components: { FormField, SelectTags, BoardMembers },
  props: {
    search: {
      type: [Object, Boolean],
      default() {
        return {
          label: true,
          icon: false,
          width: 390
        };
      }
    },
    bottomBorder: Boolean,
    clearRight: Boolean,
    searchRight: Boolean,
    isShowSelectTask: Boolean,
    invite: {
      default: false
    },
    counts: Number,
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      getTaskCounts: 'tasks/getTaskCounts',
      isShowSelect: 'sprint/getShowSelect'
    }),
    taskCounts() {
      return this.counts != null ? this.counts : this.getTaskCounts;
    },
    countActiveTags() {
      return this.fetchTasksTags.length;
    },
    isUserViewer() {
      return this.$store.getters['boards/isUserViewer'];
    }
  },
  watch: {
    loadingTask: {
      immediate: true,
      handler(val) {
        this.$emit('loading-task', val);
      }
    }
  },
  methods: {
    ...mapMutations({
      setMemberFilterIds: 'members/SET_FILTER_IDS',
      setShowSelectTask: 'sprint/SET_SHOW_SELECT_TASK',
      reset_selected_task: 'sprint/RESET_SELECTED_TASK'
    }),
    clickIsShowSelect() {
      this.setShowSelectTask(!this.isShowSelect);
      if (this.isShowSelect) {
        this.reset_selected_task();
      }
    },
    openMemberInvite() {
      this.$modal.show('invite-member');
    }
  }
};
</script>

<style></style>
