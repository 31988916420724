<template>
  <div ref="chartDetail">
    <h3 class="text-xl mb-4 border-b p-5">{{ displayDate }}</h3>
    <base-skeleton-body v-if="loading" :lines="5" />
    <div v-else class="flex p-5">
      <div class="px-5">
        <chart-bar-stacked
          ref="barChart"
          :chart-data="chartData"
          :options="timelogChartOptions"
        />
      </div>
      <div class="flex-1 px-5">
        <base-skeleton-body v-if="loading" :lines="5" />
        <base-empty
          v-if="!timelogs.length"
          :description="$t('timelog.no')"
          :image="require('@/assets/image/time-log-empty.svg')"
        />
        <div
          v-for="timelog in timelogs"
          :key="timelog.id"
          class="flex -mx-1 py-2 items-start"
          @click="showEditModal(timelog)"
        >
          <div class="flex-1 px-1">
            <div class="border flex-1 px-1 rounded">
              <div class="flex p-3 -mx-2">
                <div class="px-2 flex-shrink-0">
                  <div
                    class="w-5 h-5 rounded"
                    :style="{ 'background-color': timelog.color }"
                  />
                </div>
                <div class="px-2 flex-1">
                  <div class="detail-row">
                    <div class="flex">
                      <span v-if="timelog.board">
                        {{ timelog.board.name }}
                      </span>
                      <span v-if="timelog.task">
                        - {{ timelog.task.name }}
                      </span>
                      <span
                        v-if="timelog.input === 0"
                        class="text-clamp__header tag"
                        style="height: fit-content;"
                      >
                        {{ $t('timelog.edit') }}
                      </span>
                    </div>
                    <div>
                      {{ getTimeSpent(timelog.timeSpent) }}
                    </div>
                  </div>
                  <div class="flex text-sm">
                    <div class="mr-2">
                      {{ $t('timelog.timer.start') }} /
                      {{ $t('timelog.timer.end') }}
                    </div>
                    <div>
                      {{ formatTime(timelog.startAt) }} -
                      {{ formatTime(timelog.endAt) }}
                    </div>
                  </div>
                  <div class="text-sm">
                    <base-icon size="12">
                      <icon-description />
                    </base-icon>
                    {{ $t('modelTask.task.description') }}
                  </div>
                  <div class="text-sm">
                    {{ timelog.description || $t('timelog.no') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-bind="modalProps" height="auto" scrollable>
      <form-timelog />
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import FormTimelog from '@/components/forms/form-timelog';
import TimelogChartMixin from '@/mixins/TimelogChart';
import ChartBarStacked from '@/components/chart/chart-bar-stacked';
import IconDescription from '@/components/icons/IconDescription';
export default {
  mixins: [TimelogChartMixin],
  components: {
    ChartBarStacked,
    FormTimelog,
    IconDescription
  },
  props: {
    isExact: Boolean,
    date: {
      type: Date,
      default: () => new Date()
    }
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    activeTimelogs() {
      return this.$store.getters['time-log/getDetailTimelogs'];
    },
    timelogs() {
      const timelogs = this.activeTimelogs;
      if (!this.isExact) return timelogs;
      const exactFilter = timelog =>
        moment(timelog.startAt)
          .locale(`${this.$t('notifications.language')}`)
          .isSame(this.date, 'day');
      return timelogs.filter(exactFilter);
    },
    displayDate() {
      return moment(this.date)
        .locale(`${this.$t('notifications.language')}`)
        .format('D MMM YYYY');
    },
    chartData() {
      return this.oneDayChartData(this.timelogs);
    },
    modalProps() {
      return {
        name: 'timelog-edit',
        height: 'auto',
        adaptive: true,
        width: '100%',
        maxWidth: 660
      };
    }
  },
  watch: {
    timelogs: {
      handler(val, prev) {
        this.chartTimelogs = val;
        if (prev) {
          this.scrollIntoView();
        }
      },
      immediate: true
    },
    date: {
      handler: 'fetchTimelogs',
      immediate: true
    }
  },
  methods: {
    fetchTimelogs(date) {
      this.loading = true;
      this.$nextTick(async () => {
        await this.$store.dispatch('time-log/fetchDetailTimelogs', date);
        this.loading = false;
      });
    },
    hide() {
      this.$emit('hide');
    },
    formatTime(date) {
      return moment(date)
        .locale(`${this.$t('notifications.language')}`)
        .format('HH:mm');
    },
    scrollIntoView() {
      this.$refs.chartDetail.scrollIntoView();
    },

    showEditModal(timelog) {
      this.$store.commit('time-log/SET_EDIT_TIMELOG', timelog);
      this.$modal.show('timelog-edit');
    }
  }
};
</script>

<style scoped>
.detail-row {
  @apply flex justify-between items-start mb-1;
}
</style>
<style scoped>
.tag {
  @apply border text-sm px-2 rounded border-brand-blue text-brand-blue ml-2;
}
</style>
