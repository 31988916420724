














































































































































































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { ObserveVisibility } from 'vue-observe-visibility';
import { Getter } from 'vuex-class';
import moment from 'moment';
import TaskTags from '@/components/select/select-task-tags.vue';
import IconComment from '@/components/icons/IconComment.vue';
import IconClip from '@/components/icons/IconClip.vue';
import IconSetPriority from '@/components/icons/IconSetPriority.vue';
import IconArchiveBoardList from '@/components/icons/IconArchiveBoardList.vue';
import IconUser from '@/components/icons/IconUser.vue';
import IconCopyBoard from '@/components/icons/IconCopyBoard.vue';
import { Task, TaskStatus } from '../types/Task';
import { Avatar } from './base/base-avatar.vue';
import { FileType } from '@/types/File';
import SelectPriority from './select/select-priority.vue';
import WrapperObserveVisibility from './wrapper/wrapper-observe-visibility.vue';
import { Board, BoardType } from '@/types/Board';
import ConfirmModal from '@/components/board/board-dialog-confirm.vue';
import { directive as onClickaway } from 'vue-clickaway';
import ModalTaskCopy from '@/components/modal/modal-task-copy.vue';

const MARGIN = 8;

@Component({
  components: {
    TaskTags,
    IconComment,
    IconClip,
    SelectPriority,
    WrapperObserveVisibility,
    IconSetPriority,
    IconArchiveBoardList,
    IconUser,
    IconCopyBoard,
    ModalTaskCopy
  },
  directives: { ObserveVisibility, onClickaway },
  data() {
    return {
      menu: 0,
      quickMenu: [
        {
          value: 1,
          text: 'Set Priority',
          icon: 'icon-set-priority'
        },
        // {
        //   value: 2,
        //   text: 'Change Members',
        //   icon: 'icon-user'
        // },
        {
          value: 3,
          text: 'Archive',
          icon: 'icon-archive-board-list'
        },
        {
          value: 4,
          text: this.$t('modelTask.header.copy'),
          icon: 'icon-copy-board'
        }
      ]
    };
  }
})
export default class CardTask extends Vue {
  @Prop({ required: true }) readonly task!: Task;
  @Getter('boards/getActiveBoard') board!: Board;
  @Getter('boards/isViewOnly') isViewOnly!: boolean;
  public isActiveMenu = false;
  public hoverCard = false;
  public isActivePriority = false;
  public taskCopy = 0;
  get classBlur() {
    return {
      'opacity-100': this.isActivePriority,
      'opacity-50': this.isDone
    };
  }

  get isDone() {
    return this.task.status === TaskStatus.Done;
  }

  get footer() {
    return (
      this.getStatistic('comment') ||
      this.getStatistic('file') ||
      this.task.endAt ||
      this.task.assigns?.length
    );
  }

  //visibility
  get priorityHeight() {
    return '20px';
  }

  get coverHeight() {
    const COVER_HEIGHT = 120 + MARGIN;
    return COVER_HEIGHT + 'px';
  }

  get avatarInvisibleStyles() {
    const width = Math.min(this.assignAvatars.length, 3) * 32;
    return {
      height: '32px',
      'min-width': width + 'px'
    };
  }

  get footerHeight() {
    const FOOTER_HEIGHT = 32;
    const hasStats = this.getStatistic('comment') || this.getStatistic('file');
    const shouldBreakLine =
      hasStats && this.task.tags && (this.task.assigns?.length || 0) > 2;
    let lineBreakHeight = 0;
    if (shouldBreakLine) lineBreakHeight = FOOTER_HEIGHT;
    return FOOTER_HEIGHT + lineBreakHeight + 'px';
  }

  // display filters
  getStatistic = (key: string): number => {
    if (!['file', 'comment'].includes(key)) return 0;
    const keyCount = `${key}Count` as 'fileCount' | 'commentCount';
    return this.task.statistic?.[keyCount] ?? 0;
  };
  get assignAvatars(): Avatar[] {
    return this.task.assigns?.map(a => a.user) || [];
  }
  get imageCover(): string | undefined {
    const taskWithFileImage = this.task.files?.find(
      e => e.file.typeUpload === FileType.Image
    );
    const url = taskWithFileImage?.file.thumbnailImageUrl;
    return url ? `url("${url}")` : '';
  }
  get dueColor(): string {
    const colors = [
      'badge-danger',
      'badge-warning',
      'badge-gray',
      'badge-success'
    ];
    const dueDate = moment(this.task.endAt).locale(
      `${this.$t('notifications.language')}`
    );
    const daysLeft = dueDate.diff(
      moment()
        .locale(`${this.$t('notifications.language')}`)
        .startOf('day'),
      'days'
    );
    // adjust over days into index number
    // daysLeft < 0 days is Overdue; === 0 days is Today
    // === 1 day is Tomorrow
    // > 2 days is Upcoming
    const colorIndex =
      this.task.status == 2
        ? 3
        : daysLeft < 0
        ? 0
        : daysLeft > 2
        ? 2
        : daysLeft;
    return colors[colorIndex];
  }
  get formatDue(): string {
    return moment(this.task.endAt)
      .locale(`${this.$t('notifications.language')}`)
      .format('DD MMM');
  }
  get priority(): number {
    return this.task.priority;
  }
  get storyPoint(): string | null {
    if (this.board.type !== BoardType.AGILE) return null;
    return this.task.storyPoint?.toString() || '-';
  }

  setPriority(priority: number) {
    if (priority != this.priority) {
      this.$store.dispatch('tasks/setTask', {
        id: (this.task as Task).id,
        priority
      });
    }
    this.hideQuickMenu();
  }

  promptArchive() {
    this.isActiveMenu = false;
    this.$modal.show(
      ConfirmModal,
      {
        title: this.$t('alert.archivedTask.name'),
        description: this.$t('alert.archivedTask.title'),
        button: this.$t('alert.archivedTask.button'),
        confirmAction: 'tasks/archiveTask',
        confirmData: (this.task as Task).id
      },
      {
        height: 'auto',
        maxWidth: 320,
        adaptive: true
      }
    );
  }

  hideQuickMenu() {
    if (!this.hoverCard) {
      this.isActiveMenu = false;
    }
  }

  hidePriorityMenu() {
    this.isActivePriority = false;
  }

  visibilityChanged(entry: any) {
    if (!entry.isVisible) {
      this.isActiveMenu = false;
    }
  }

  async copyTask() {
    this.taskCopy = await (this.task as Task).id;
    this.$modal.show('copyy');
  }

  quickMenuSelected(menu: number) {
    const menuList = ['', '', 'promptArchive', 'copyTask'];
    menuList[menu - 1] != '' ? (this as any)[menuList[menu - 1]]() : '';
  }
}
