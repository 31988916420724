<template>
  <div
    class="flex p-3"
    :class="{
      'grid justify-items-start': isTwoLine,
      'all__card--height': isAllCard && !isChangePayment,
      'h-20': isChangePayment
    }"
  >
    <div class="flex items-center mr-4">
      <input
        v-if="isChangePayment"
        class="mr-2 leading-tight"
        type="radio"
        :checked="card.is_default_card"
        @click="selectCard(card.card_id)"
      />
      <base-icon :width="30" class="mr-2">
        <component :is="`icon${card.brand}`" />
      </base-icon>
      <div>
        <div class="flex">
          <span class="font-medium">{{ replaceCardNo }} </span>
          <div v-if="card.is_default_card" class="status">
            {{ $t('in_use') }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isChangePayment"
      class="flex"
      :class="{ 'flex-row-reverse': isAllCard, 'ml-auto': !isAllCard }"
    >
      <div
        class="flex self-center mx-4 cursor-pointer"
        @click="deleteCard(card.card_id)"
      >
        <div>{{ $t('remove') }}</div>
      </div>
      <div
        class="flex self-center"
        :class="{ 'bg-gray-main': card.is_default_card }"
      >
        <base-button
          color="transparent"
          :disabled="card.is_default_card"
          @click="selectCard(card.card_id)"
          >{{ $t('set_default') }}</base-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import IconMasterCard from '@/components/icons/IconMasterCard';
import IconVisa from '@/components/icons/IconVisa';

export default {
  components: {
    IconMasterCard,
    IconVisa
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    card: {
      type: Object,
      default: null
    },
    isTwoLine: { type: Boolean, default: false },
    isChangePayment: {
      type: Boolean,
      default: false
    },
    isAllCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    replaceCardNo() {
      return this.card.card_no.replaceAll('x', '*').replaceAll('-', ' ');
    }
  },
  methods: {
    async selectCard(cardId) {
      this.$emit('selectCard');
      await this.$store.dispatch('subscription/setCardDefault', cardId);
    },
    async deleteCard(cardId) {
      await this.$store.dispatch('subscription/deleteCards', cardId);
    }
  }
};
</script>

<style>
.status {
  @apply bg-green-success text-white p-1 ml-2 self-center rounded text-xs;
}

.all__card--height {
  @apply h-32;
}
</style>
<i18n>
{
  "en": {
    "in_use": "In use",
    "remove": "Remove",
    "set_default": "Set as default"
  },
  "th": {
    "in_use": "กำลังใช้งาน",
    "remove": "ลบ",
    "set_default": "ตั้งเป็นค่าเริ่มต้น"
  }
}
</i18n>
