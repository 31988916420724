













































































































import IconCreateWorkspace from '@/components/icons/IconCreateWorkspace.vue';
import { BaseSelectOption } from '@/types/BaseComponents';
import Vue, { PropType } from 'vue';
import { TeamType } from '@/types/Team';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';

export default Vue.extend({
  components: {
    IconCreateWorkspace,
    IconChevronDown
  },
  data() {
    return {
      formName: '',
      formType: 0,
      formDescription: '',
      formWebSite: ''
    };
  },
  methods: {
    async createWorkspace() {
      const form = {
        name: this.formName,
        type: this.formType,
        description: this.formDescription,
        webSite: this.formWebSite
      };
      this.$store.dispatch('teams/createTeam', form);

      this.$modal.hide('create-workspace');
    }
  },
  computed: {
    typeOptions(): BaseSelectOption[] {
      return this.types.map(e => {
        return {
          label: this.$i18n.locale === 'en' ? e.valueEN : e.valueTh,
          value: e.type
        };
      });
    },
    types(): TeamType[] {
      return this.$store.getters['teams/getTypes'];
    }
  },
  async mounted() {
    this.$store.dispatch('teams/fetchTypes');
  }
});
