import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
    SelectOptionSeat() {
      const seat = 5;
      const countSeat = 10;
      const SelectOptionSeat = [];
      for (let i = 0; i <= countSeat; i++) {
        SelectOptionSeat.push({
          label:
            i === countSeat
              ? 'Custom (Contect)'
              : (i + 1) * seat + `${this.$t('seat')}`,
          value: i === countSeat ? 0 : (i + 1) * seat
        });
      }
      return SelectOptionSeat;
    }
  }
});
