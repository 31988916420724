<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M4 18.5V17.85C4 17.51 4.16 17.19 4.41 17.04C6.1 16.03 8.03 15.5 10 15.5C10.03 15.5 10.05 15.5 10.08 15.51C10.18 14.81 10.38 14.14 10.67 13.53C10.45 13.51 10.23 13.5 10 13.5C7.58 13.5 5.32 14.17 3.39 15.32C2.51 15.84 2 16.82 2 17.85V20.5H11.26C10.84 19.9 10.51 19.22 10.29 18.5H4Z"
      fill="black"
    />
    <path
      d="M10 12.5C12.21 12.5 14 10.71 14 8.5C14 6.29 12.21 4.5 10 4.5C7.79 4.5 6 6.29 6 8.5C6 10.71 7.79 12.5 10 12.5ZM10 6.5C11.1 6.5 12 7.4 12 8.5C12 9.6 11.1 10.5 10 10.5C8.9 10.5 8 9.6 8 8.5C8 7.4 8.9 6.5 10 6.5Z"
      fill="black"
    />
    <path
      d="M20.75 16.5C20.75 16.28 20.72 16.08 20.69 15.87L21.83 14.86L20.83 13.13L19.38 13.62C19.06 13.35 18.7 13.14 18.3 12.99L18 11.5H16L15.7 12.99C15.3 13.14 14.94 13.35 14.62 13.62L13.17 13.13L12.17 14.86L13.31 15.87C13.28 16.08 13.25 16.28 13.25 16.5C13.25 16.72 13.28 16.92 13.31 17.13L12.17 18.14L13.17 19.87L14.62 19.38C14.94 19.65 15.3 19.86 15.7 20.01L16 21.5H18L18.3 20.01C18.7 19.86 19.06 19.65 19.38 19.38L20.83 19.87L21.83 18.14L20.69 17.13C20.72 16.92 20.75 16.72 20.75 16.5ZM17 18.5C15.9 18.5 15 17.6 15 16.5C15 15.4 15.9 14.5 17 14.5C18.1 14.5 19 15.4 19 16.5C19 17.6 18.1 18.5 17 18.5Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
