import apis from './apis';
import { Team, TeamAdd } from '@/types/Team';
import {
  BoardInvitationRequest,
  BoardMember,
  BoardMemberSetRoleRequest,
  BoardKickMemberRequest
} from '@/types/Board';
import {
  CommandResponse,
  ApiPagination,
  GenericRestApiFactory,
  ApiPaginationParams
} from '@/types/Api';
import { User } from '@/types/User';
import { TagAdd, Tag, TagEdit } from '@/types/Tag';
import { AxiosRequestConfig } from 'axios';
import { Params } from '@fortawesome/fontawesome-svg-core';

const apisTeams = (restFactory: GenericRestApiFactory) => ({
  ...restFactory<Team, TeamAdd>('teams'),
  fetchTeams(params: Params) {
    return apis.get(`/me/teams`, {
      params: params
    });
  },
  updateTeam(id: number, update: TeamAdd) {
    return apis.patch(`/teams/${id}`, update);
  },
  fetchBoards(id: number) {
    return apis.get(`/teams/${id}/boards`, {});
  },
  fetchMembers(id: number, params?: ApiPaginationParams) {
    return apis.get(`/teams/${id}/members`, { params });
  },
  inviteMember: ({ id, ...invitation }: BoardInvitationRequest) =>
    apis.post<CommandResponse | BoardMember[]>(
      `/teams/${id}/invite`,
      invitation
    ),
  setMemberRole: ({ id, ...req }: BoardMemberSetRoleRequest) =>
    apis.put<CommandResponse>(`/teams/${id}/permission`, req),
  kickMember: ({ id, memberID }: BoardKickMemberRequest) =>
    apis.delete<CommandResponse>(`/teams/${id}/leave`, { data: { memberID } }),
  fetchTypes() {
    return apis.get(`/guest/team/team_types`, {});
  }
});

export default apisTeams;
