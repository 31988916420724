<template>
  <div>
    <slot v-bind="counter" />
  </div>
</template>

<script>
import Timer from '@/mixins/Timer';
export default {
  mixins: [Timer],
  props: {
    startAt: [Date, String]
  },
  computed: {
    counter() {
      return {
        counter: {
          display: this.displayTimer,
          active: Boolean(this.startAt)
        }
      };
    }
  },
  watch: {
    startAt: {
      handler(startAt) {
        if (startAt && this.canStartTimer) this.start(startAt);
        if (!startAt) this.stop();
      },
      immediate: true
    }
  },
  methods: {
    start(startAt) {
      this.startTimer(startAt);
    },
    stop() {
      this.stopTimer();
    }
  }
};
</script>

<style></style>
