<template>
  <div class="inline-block">
    <base-dropdown
      class="flex"
      position="bottom-right"
      :class="{ 'w-full': expanded }"
    >
      <template #toggle>
        <button
          class="calendar-datepicker__toggle w-full"
          :disabled="disabled"
          :class="[toggleIcon ? 'pr-8' : 'pr-2', toggleClasses]"
          @click="onToggle"
        >
          <div class="toggle__body">
            <fa-icon
              v-if="togglePrefix"
              class="text-gray-600 mr-2"
              :icon="['far', 'calendar']"
            />
            <span v-if="value">
              <span>
                {{ formatShowDate.start }}
                <span v-if="formatShowDate.start != '' && !isOneDay">-</span>
                {{ formatShowDate.end }}
              </span>
              <span v-if="isCurrentToday" class="text-gray-600">
                ({{ $t('timelog.date.today') }})
              </span>
            </span>
            <input
              v-else
              class="flex-1 bg-transparent w-24 text-sm"
              :class="placeholderClasses"
              disabled
              :placeholder="placeholder"
            />
            <div
              v-if="!disabled && toggleIcon"
              class="absolute right-0 inset-y-0 flex justify-center items-center pr-4"
            >
              <span>
                <fa-icon icon="chevron-down" class="text-gray-600" />
              </span>
            </div>
          </div>
        </button>
      </template>
      <template #header>
        <strong slot="header">{{ $t('timelog.manual.date.name') }}</strong>
      </template>
      <template #default>
        <slot :attributes="datepickerAttrs">
          <calendar-datepicker
            v-model="currentDate"
            class="border-0"
            v-bind="datepickerAttrs"
            v-on="$listeners"
          />
        </slot>
      </template>
      <template #footer="{ events }">
        <div class="flex justify-between items-center">
          <span>{{ calendarFooterDate }}</span>
          <base-button wide="px-5" @click="events.hide">
            {{ $t('timelog.manual.done') }}</base-button
          >
        </div>
      </template>
    </base-dropdown>
  </div>
</template>

<script>
import moment from 'moment';
import ModalSelectDate from '@/components/modal/modal-select-date.vue';
export default {
  props: {
    value: {
      type: Date,
      default: undefined
    },
    hideToday: Boolean,
    expanded: Boolean,
    togglePrefix: Boolean,
    toggleIcon: {
      type: Boolean,
      default: true
    },
    placeholderClasses: {
      type: [Array, String],
      default: 'placeholder-gray-600'
    },
    toggleClasses: {
      type: [Array, String],
      default: ''
    },
    modal: Boolean,
    limitToday: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String
    },
    disabled: Boolean,
    task: Object,
    nameModal: String,
    isOneDay: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CalendarDatepicker: () =>
      import('v-calendar/lib/components/date-picker.umd')
  },
  data() {
    return {
      endDate: moment()
    };
  },
  computed: {
    currentDate: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    today() {
      return moment()
        .locale(`${this.$t('notifications.language')}`)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    },
    datepickerAttrs() {
      return {
        maxDate: this.limitToday ? this.today.toDate() : null,
        isInline: true,
        isExpanded: true,
        titlePosition: 'left',
        theme: {
          container: {
            light: 'vc-text-gray-900 vc-bg-white'
          }
        }
      };
    },
    mCurrentDate() {
      return moment(this.currentDate).locale(
        `${this.$t('notifications.language')}`
      );
    },
    calendarFooterDate() {
      if (!this.currentDate) return '';
      return moment(this.currentDate)
        .locale(`${this.$t('notifications.language')}`)
        .format('ddd LL');
    },
    isCurrentToday() {
      return !this.hideToday && this.mCurrentDate.isSame(this.today, 'day');
    },
    formatShowDate() {
      const formatDateStart = f => moment(this.currentDate).format(f);
      const formatDateEnd = e => moment(this.endDate).format(e);

      const dayStart = formatDateStart('DD');
      const dayEnd = formatDateEnd('DD');
      const monthStart = formatDateStart('MM');
      const monthEnd = formatDateEnd('MM');
      const yearStart = formatDateStart('YYYY');
      const yearEnd = formatDateEnd('YYYY');
      if (yearStart === yearEnd) {
        if (monthStart === monthEnd) {
          if (dayStart === dayEnd) {
            return {
              start: '',
              end: formatDateEnd('DD MMM YYYY')
            };
          }
          if (this.isOneDay) {
            return {
              start: formatDateStart('DD  MMM YYYY'),
              end: ''
            };
          } else {
            return {
              start: formatDateStart('DD'),
              end: formatDateEnd('DD MMM YYYY')
            };
          }
        } else {
          return {
            start: formatDateStart('DD MMM'),
            end: formatDateEnd('DD MMM YYYY')
          };
        }
      } else {
        return {
          start: formatDateStart('DD MMM YYYY'),
          end: formatDateEnd('DD MMM YYYY')
        };
      }
    }
  },
  mounted() {
    if (this.nameModal !== 'waterfall-task-create') {
      this.endDate = this.task?.endAt;
    }
  },
  methods: {
    onToggle(ev) {
      if (this.modal) {
        ev.stopPropagation();
        this.$modal.show(
          ModalSelectDate,
          {
            title: 'Start date',
            value: this.value,
            task: this.task,
            events: {
              pick: e => {
                this.currentDate = e.start;
                this.endDate = e.end;
                this.$emit('endDate', e.end);
                this.$modal.hide('select-date');
              }
            },
            ...this.datepickerAttrs
          },
          {
            width: 360,
            height: 'auto',
            name: 'select-date'
          }
        );
      }
    }
  }
};
</script>

<style scoped>
.calendar-datepicker__toggle {
  @apply text-black py-2 pl-4 border rounded inline-flex justify-between items-center h-full;
  min-height: 2rem;
}
.calendar-datepicker__toggle:hover {
  @apply bg-gray-100;
}
.toggle__body {
  @apply text-sm;
}
</style>
