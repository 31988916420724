

























import Vue from 'vue';
import TaskComment from '@/components/comments/task-comments.vue';
import TaskActivity from '@/components/comments/task-activities.vue';
// import TaskTimelog from '@/components/comments/task-timelog.vue';
export default Vue.extend({
  components: {
    TaskComment,
    TaskActivity
    // TaskTimelog
  },
  props: {
    commentDisabled: Boolean,
    taskId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      email: '',
      loading: false,
      tabIndex: 0,
      tabs: [
        `${this.$t('modelTask.task.comments')}`,
        `${this.$t('modelTask.task.activities')}`,
        `Timelog`
      ]
    };
  }
});
