import Vue from 'vue';

const resetFilters = { tags: true, members: true };

export default Vue.extend({
  mounted() {
    const { tagIDs, assigneeIDs } = this.$route.query;
    this.setMemberVisible(false);
    this.resetTaskFilters({
      tags: !tagIDs,
      members: !assigneeIDs
    });
  },
  beforeRouteLeave(to, _1, next) {
    if (!to.meta?.hideMember) this.setMemberVisible(true);
    this.resetTaskFilters();
    next();
  },
  methods: {
    setMemberVisible(visible: boolean) {
      this.$store.dispatch('setMemberVisible', visible);
    },
    resetTaskFilters(r = resetFilters) {
      this.$store.dispatch('resetTaskFilters', r);
    }
  }
});
