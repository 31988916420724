<template>
  <div @click="handleClick">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    ignoredClass: {
      type: String,
      default: 'click-ignored'
    }
  },
  methods: {
    handleClick(ev) {
      const element = ev.target;
      if (this.isIgnored(element)) this.$emit('click-ignored', ev);
      else this.$emit('click', ev);
    },
    isIgnored(el) {
      if (!el) return null;
      const ignoreSelector = this.ignoredClass;
      const parentEl = el.parentElement;
      const isParentIgnored = parentEl?.classList.contains(ignoreSelector);
      return isParentIgnored
        ? parentEl
        : this.isIgnored(parentEl, ignoreSelector);
    }
  }
};
</script>

<style></style>
