<template>
  <div class="grid gap-y-2 overflow-y-auto py-4 " style="max-height: 665px">
    <p class="text-xl">Billing Overview</p>
    <p class="text-sm text-gray-caption">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque quis
      quisque sed dolor ornare nec. Velit, sit pretium amet, massa, non aliquet
      habitant quis.
    </p>

    <p>current Workspace Subscription</p>
    <div class="p-4 border">
      <p>6 Months</p>
      <div>
        <p>Biling</p>
        <p>
          Uo to 5 seats at ฿1 {{ $t('subscription.thai_bath') }} per seat per
          mouth
        </p>
      </div>
      <div>
        <p>Next payment</p>
        <p>Estimated THB60.23 plus tax (based on usage to date)</p>
      </div>
      <div>
        <p>Payment date</p>
        <p>Oct 9, 2021</p>
      </div>
    </div>

    <payment-all-card style="height: 340px;" />

    <payment-history-bill />
  </div>
</template>

<script>
import PaymentHistoryBill from '@/components/payment/payment-history-bill';
import PaymentAllCard from '@/components/payment/payment-all-card';

export default {
  components: {
    PaymentHistoryBill,
    PaymentAllCard
  },
  mounted() {
    document.body.classList.add('vm--block-scroll');
  }
};
</script>
