


























































import Vue, { PropType } from 'vue';
import paymentCardCurrent from '@/components/payment/payment-card-current.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    card: {
      type: Object,
      default: null
    },
    isChangePayment: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 900
    }
  },
  components: {
    paymentCardCurrent,
    IconPlus
  },
  computed: {
    ...mapGetters('subscription', ['getCardList'])
  },
  data() {
    return {
      opened: false,
      loading: false
    };
  },
  methods: {
    addCard() {
      this.$modal.hide('all-card');
      this.$modal.show('card-form');
    }
  }
});
