








import { Component, Watch } from 'vue-property-decorator';
import TimelogChartMixin from '@/mixins/TimelogChart';
import ChartBarStacked from '@/components/chart/chart-bar-stacked.vue';
import { TimeLog } from '@/types/TimeLog';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    ChartBarStacked
  }
})
export default class TimelogChartToday extends mixins(TimelogChartMixin) {
  get todayTimelogs(): TimeLog[] {
    return this.$store.getters['time-log/getToday'];
  }

  get todayChartData() {
    return this.oneDayChartData(this.todayTimelogs);
  }

  @Watch('todayTimelogs', { immediate: true })
  onTodayTimelogsChanged(val: TimeLog[]) {
    this.chartTimelogs = val;
  }
}
