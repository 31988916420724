<template>
  <div class="relative">
    <div v-if="loading" class="dark-overlay">
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube" />
        <div class="sk-cube2 sk-cube" />
        <div class="sk-cube4 sk-cube" />
        <div class="sk-cube3 sk-cube" />
      </div>
    </div>

    <!-- nav -->
    <div class="absolute top-0 left-0 w-full bg-primary">
      <div
        class="container mx-auto px-4 py-2 flex items-center justify-between"
      >
        <!-- logo -->
        <div class="flex items-center space-x-2">
          <logo-workkami-small />
          <span class="text-white uppercase font-medium">workkami</span>
        </div>
        <!-- login signup -->
        <div class="hidden md:flex space-x-4 relative">
          <base-dropdown
            position="bottom-right"
            :notrelative="true"
            content-class="mt-4"
            :sm-right="true"
          >
            <button
              slot="toggle"
              class="px-6 py-1 md:px-8 md:py-2 focus:outline-none text-white bg-primary border border-white rounded text-sm"
            >
              {{ $t('landing.nav.login') }}
            </button>
            <base-dropdown-item keep :hoverable="false" class="">
              <login @loading="loading = $event" @loginFail="loginFail()" />
            </base-dropdown-item>
          </base-dropdown>

          <router-link
            :to="{ name: 'Signup' }"
            class="px-6 py-1 md:px-8 md:py-2 focus:outline-none text-primary bg-white border border-primary rounded text-sm"
          >
            {{ $t('landing.nav.register') }}
          </router-link>
        </div>
        <div class="block md:hidden">
          <div
            class=" cursor-pointer"
            @click="mobileMenuToggle = !mobileMenuToggle"
          >
            <base-icon icon="landing-hamberger" />
          </div>
        </div>
      </div>
    </div>

    <!-- mobile menu -->
    <div
      v-show="mobileMenuToggle"
      class="block md:hidden bg-primary pt-16 py-4"
    >
      <div class="px-6 py-2">
        <router-link
          :to="{ name: 'Signup' }"
          class="w-full block px-6 py-1 text-center md:px-8 md:py-2 focus:outline-none text-primary bg-white border border-primary rounded text-sm"
        >
          {{ $t('landing.nav.register') }}
        </router-link>
      </div>
      <div class="mx-6 my-2 relative">
        <base-dropdown
          position="bottom-right"
          :notrelative="true"
          content-class="mt-4"
          :sm-right="true"
          width="full"
        >
          <button
            slot="toggle"
            class="w-full block px-6 py-1 md:px-8 md:py-2 focus:outline-none text-white bg-primary border border-white rounded text-sm"
          >
            {{ $t('landing.nav.login') }}
          </button>
          <base-dropdown-item keep :hoverable="false" class="">
            <login @loading="loading = $event" @loginFail="loginFail()" />
          </base-dropdown-item>
        </base-dropdown>
      </div>
    </div>

    <!-- banner -->
    <div class="container mx-auto flex flex-wrap items-center pt-12 md:pt-24">
      <div class="w-full lg:w-1/2 px-6 py-8 text-center md:text-left">
        <div class="text-4xl font-medium leading-none">
          <span class="text-primary">Workkami.</span>
          {{ $t('landing.banner.title') }}
        </div>
        <div class="my-6 text-gray-600" v-html="$t('landing.banner.des')" />

        <div class="my-6 flex space-x-4 justify-center md:justify-start">
          <router-link
            :to="{ name: 'Signup' }"
            class="focus:outline-none bg-primary text-white px-6 py-2 rounded flex items-center"
          >
            <span class="mr-2">{{ $t('landing.banner.getStart') }}</span>
            <icon-right />
          </router-link>
          <a
            href="https://www.youtube.com/watch?v=-RqXsFPHW34&t=62s"
            target="_blank"
            class="focus:outline-none bg-white text-primary border border-primary px-6 py-2 rounded flex items-center"
          >
            <icon-play /><span class="ml-2">{{
              $t('landing.banner.getVideo')
            }}</span>
          </a>
        </div>

        <div class="my-4 hidden md:block">
          {{ $t('landing.banner.download') }}
        </div>

        <div class=" space-x-4 hidden md:flex">
          <a :href="appStoreLink" target="_blank">
            <img class="h-12" src="/img/app-store.png" />
          </a>
          <a :href="playStoreLink" target="_blank">
            <img class="h-12" src="/img/play-store.png" />
          </a>
        </div>
      </div>
      <div class="hidden md:block w-full lg:w-1/2 px-2 py-8">
        <img src="/img/banner.png" class="w-full" />
      </div>
    </div>

    <!-- job manage -->
    <div
      class="mt-20 pb-20 px-3"
      style="background: url(/img/job-bg.svg); background-size: auto; background-position: center; background-position-y: 80px;background-repeat: no-repeat;"
    >
      <div class="container mx-auto ">
        <div
          class="flex items-center justify-start md:justify-center text-primary space-x-1"
        >
          <icon-job />
          <div>{{ $t('landing.task.title') }}</div>
        </div>

        <div
          class="text-left md:text-center text-3xl"
          v-html="$t('landing.task.label')"
        />
        <div
          class="text-gray-600 my-2 w-full max-w-xl mx-auto text-left md:text-center"
        >
          {{ $t('landing.task.des') }}
        </div>
        <div class="mt-6 my-2">
          <img :src="jobTabs[jobIndex].img" class="w-full" />
        </div>

        <div class="">
          <div class="hidden md:flex  justify-center">
            <div
              class="flex items-stretch justify-center border border-gray-300 rounded-full"
            >
              <div
                v-for="(j, i) in jobTabs"
                :key="`job-${i}`"
                class=" px-4 py-1 rounded-full cursor-pointer flex items-center justify-center text-sm md:text-base"
                :class="{
                  'tab-item-default': i != jobIndex,
                  'border-2 border-primary text-primary': i == jobIndex
                }"
                @click="jobIndex = i"
              >
                {{ j.name }}
              </div>
            </div>
          </div>
          <div class="flex md:hidden items-center justify-between">
            <div class="flex space-x-2">
              <div
                v-for="(j, i) in jobTabs"
                :key="`job-${i}`"
                class="h-3 w-3 rounded-sm"
                :class="{
                  'bg-gray-300': i != jobIndex,
                  'bg-primary': i == jobIndex
                }"
                @click="jobIndex = i"
              />
            </div>
            <div class="flex">
              <div
                class=" cursor-pointer"
                @click="jobIndex = jobIndex <= 0 ? 0 : jobIndex - 1"
              >
                <icon-landing-left />
              </div>
              <div
                class=" cursor-pointer"
                @click="
                  jobIndex =
                    jobIndex >= jobTabs.length - 1
                      ? jobTabs.length - 1
                      : jobIndex + 1
                "
              >
                <icon-landing-right />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- template -->
    <div class="container mx-auto flex flex-wrap items-center my-8">
      <div class="w-full md:w-2/5 p-4">
        <div class="flex items-center text-primary space-x-1">
          <icon-template />
          <div>{{ $t('landing.template.title') }}</div>
        </div>

        <div class="text-2xl my-3" v-html="$t('landing.template.label')" />

        <div class="text-gray-500">
          {{ $t('landing.template.des') }}
        </div>

        <div class="hidden md:flex -mx-2 my-6">
          <div
            v-for="(t, i) in templateTabs"
            :key="`template-${i}`"
            class="mx-2 my-2 rounded-md border  px-4 pt-3 pb-1 flex flex-col items-center justify-center cursor-pointer"
            :class="{
              'border-gray-200': i != templateIndex,
              'border-primary bg-blue-100': i == templateIndex
            }"
            @click="templateIndex = i"
          >
            <div>
              <div :is="t.icon" />
            </div>
            <div class="mt-1">
              {{ t.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="hidden md:block w-full md:flex-1 p-4 overflow-y-auto">
        <img class="w-full" :src="templateTabs[templateIndex].img" />
      </div>
    </div>

    <!-- time log -->
    <div class="container mx-auto flex flex-wrap my-20">
      <div class="w-full md:flex-1 p-6 mt-6">
        <div class="rounded-md shadow-md border border-gray-200 px-6 py-3">
          <div class="text-lg">{{ $t('landing.log.header') }}</div>
          <img src="/img/time-log-row-1.png" class="my-2" />
          <img src="/img/time-log-row-2.png" class="my-2" />
          <div class="my-2 relative">
            <img src="/img/time-log-row-3.png" />

            <div class="absolute" style="top: 50%; right: 12%;">
              <div
                class="point-animation cursor-pointer"
                @click="showTimeLogVdo = true"
              >
                <div class="point " />
                <div class="bg " />
              </div>
            </div>

            <div
              v-if="showTimeLogVdo"
              v-on-clickaway="hideShowTimeLogVdo"
              class="absolute h-auto bg-white px-4 py-4 shadow-lg border border-gray-200 rounded-md transform -translate-y-full"
              style="top: 8%; right: 0; width: 100%;"
            >
              <div class="flex mb-3">
                <div class="flex-1 text-lg">
                  {{ $t('landing.log.header') }}
                </div>
                <div class="cursor-pointer" @click="hideShowTimeLogVdo">
                  <base-icon width="16px">
                    <icon-close />
                  </base-icon>
                </div>
              </div>

              <div class="">
                <video width="100%" autoplay>
                  <source src="/img/time-log-vdo.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

          <img src="/img/time-log-row-4.png" class="my-2" />
        </div>
      </div>
      <div class="w-full md:flex-1 p-4">
        <div class="flex items-center text-primary space-x-1">
          <icon-time-log />
          <div>{{ $t('landing.log.title') }}</div>
        </div>

        <div class="text-2xl my-3" v-html="$t('landing.log.label')" />

        <div class="text-gray-500">
          {{ $t('landing.log.des') }}
        </div>

        <base-box-toggle
          :title="$t('landing.log.time1Title')"
          icon="play"
          :toggle="true"
        >
          {{ $t('landing.log.time1Des') }}
        </base-box-toggle>
        <base-box-toggle :title="$t('landing.log.time2Title')" icon="time">
          {{ $t('landing.log.time2Des') }}
        </base-box-toggle>
      </div>
    </div>

    <!-- report -->
    <div class="container mx-auto flex flex-wrap my-20">
      <div class="w-full md:flex-1 p-4">
        <div class="flex items-center text-primary space-x-1">
          <icon-report />
          <div>{{ $t('landing.report.title') }}</div>
        </div>

        <div class="text-2xl my-3" v-html="$t('landing.report.label')" />

        <div class="text-gray-500">
          {{ $t('landing.report.des') }}
        </div>

        <base-box-toggle
          :title="$t('landing.report.privateTitle')"
          icon="user-circle"
        >
          {{ $t('landing.report.privateDes') }}
        </base-box-toggle>
        <base-box-toggle
          :title="$t('landing.report.planTitle')"
          icon="report"
          :toggle="true"
        >
          {{ $t('landing.report.planDes') }}
        </base-box-toggle>
      </div>
      <div class="w-full md:flex-1 p-4 relative">
        <!-- board -->
        <div class="absolute" style="top: 18%; left: 55%;">
          <div
            class="point-animation cursor-pointer"
            @click="showReportBoard = true"
          >
            <div class="point " />
            <div class="bg " />
          </div>
        </div>
        <img
          v-if="showReportBoard"
          v-on-clickaway="hideShowReportBoard"
          class="absolute h-auto"
          src="/img/report-board.png"
          style="top: 18%; left: 55%; width: 38%;"
        />

        <!-- export -->
        <div class="absolute" style="top: 20%; right: 11%;">
          <div
            class="point-animation cursor-pointer"
            @click="showReportExport = true"
          >
            <div class="point " />
            <div class="bg " />
          </div>
        </div>
        <img
          v-if="showReportExport"
          v-on-clickaway="hideShowReportExport"
          class="absolute h-auto"
          src="/img/report-export.png"
          style="top: 0%; right: 10%; width: 70%;"
        />

        <!-- graph -->
        <div class="absolute" style="top: 52%; left: 38%;">
          <div
            class="point-animation cursor-pointer"
            @click="showReportGraph = true"
          >
            <div class="point " />
            <div class="bg " />
          </div>
        </div>

        <div
          v-if="showReportGraph"
          v-on-clickaway="hideShowReportGraph"
          class="absolute h-auto bg-white px-4 py-4 shadow-lg border border-gray-200 rounded-md transform -translate-y-1/2 "
          style="top: 45%; right: 0; width: 100%;"
        >
          <div class="relative z-20">
            <div class="absolute top-0 right-0 mr-4 mt-2">
              <div class="cursor-pointer" @click="hideShowReportGraph">
                <base-icon width="16px">
                  <icon-close />
                </base-icon>
              </div>
            </div>
          </div>

          <div class="">
            <video width="100%" autoplay>
              <source src="/img/time-log-vdo.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        <img class="w-full h-auto" src="/img/report.png" />
      </div>
    </div>

    <!-- sumary -->
    <div class="container mx-auto my-20 px-3">
      <div class="flex items-center justify-center text-primary space-x-1">
        <icon-sumary />
        <div>{{ $t('landing.summary.title') }}</div>
      </div>

      <div class="text-center text-3xl" v-html="$t('landing.summary.label')" />
      <div class="text-gray-600 my-2 w-full max-w-xl mx-auto text-center">
        {{ $t('landing.summary.des') }}
      </div>
      <div
        class="mt-6 px-2 py-6"
        style="background: url(/img/sumary-bg.svg) center center; background-size: cover;"
      >
        <img src="/img/sumary.png" class="w-full" />
      </div>
    </div>

    <!-- app -->
    <div
      class="my-10 bg-gray-200 py-3 px-8 flex flex-wrap items-center justify-center space-x-4"
    >
      <div
        class="w-full md:w-auto my-2 text-lg"
        v-html="$t('landing.app.title')"
      />

      <div class="w-full md:w-auto my-2 flex space-x-4">
        <a :href="appStoreLink" target="_blank">
          <img class="h-8" src="/img/app-store.png" />
        </a>
        <a :href="playStoreLink" target="_blank">
          <img class="h-8" src="/img/play-store.png" />
        </a>
      </div>
    </div>

    <!-- footer -->
    <div class="border-t border-gray-200 px-6 ">
      <div class="container mx-auto flex flex-wrap items-center">
        <div class="w-full md:flex-1 flex items-center my-6">
          <div class="w-16 h-16 mr-2">
            <logo-workkami />
          </div>
          <div>
            <div class="text-primary text-lg font-medium">Workkami</div>
            <div class="text-gray-500 text-sm">Task Management Software</div>
          </div>
        </div>
        <div class="w-full md:w-auto my-6">
          <div class="flex items-center justify-end space-x-3 mb-2 pointer">
            <div @click="openLinkSocial('https://www.facebook.com/Workkami')">
              <icon-f-b />
            </div>

            <!-- <icon-t-w />
            <icon-i-g />
            <icon-i-n />
            <icon-y-t /> -->
          </div>
          <div class="text-gray-500 text-right text-sm">
            © 2021 Workkami Security Your Privacy Terms
          </div>
          <div class="text-primary text-right text-sm">
            Workkami was created using Workkami
          </div>
          <div class="text-gray-500 text-right text-sm">
            Accessibility
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import BaseBoxToggle from '@/components/base/base-box-toggle.vue';
import LoadingMixin from '@/mixins/LoadingMixin';
import LogoWorkkamiSmall from '../components/icons/LogoWorkkamiSmall';
import LogoWorkkami from '../components/icons/LogoWorkkami';
import Login from '@/components/login';
import IconRight from '@/components/icons/IconRight.vue';
import IconPlay from '@/components/icons/IconPlay.vue';
import IconJob from '@/components/icons/IconJob.vue';
import IconSumary from '@/components/icons/IconSumary.vue';
import IconTemplate from '@/components/icons/IconTemplate.vue';
import IconTemplateBasic from '@/components/icons/IconTemplateBasic.vue';
import IconTemplateWeekdays from '@/components/icons/IconTemplateWeekdays.vue';
import IconTemplateBlank from '@/components/icons/IconTemplateBlank.vue';
import IconTimeLog from '@/components/icons/IconTimeLog.vue';
import IconReport from '@/components/icons/IconReport.vue';
import IconFB from '@/components/icons/IconFB.vue';
// import IconTW from '@/components/icons/IconTW.vue';
// import IconIG from '@/components/icons/IconIG.vue';
// import IconIN from '@/components/icons/IconIN.vue';
// import IconYT from '@/components/icons/IconYT.vue';
import IconClose from '@/components/icons/IconClose.vue';
import IconLandingLeft from '@/components/icons/IconLandingLeft.vue';
import IconLandingRight from '@/components/icons/IconLandingRight.vue';

import firebase from '@/services/firebase';
import { mapActions } from 'vuex';
import BaseIcon from '@/components/base/base-icon.vue';

export default {
  directives: {
    onClickaway: onClickaway
  },
  components: {
    BaseBoxToggle,
    LogoWorkkamiSmall,
    LogoWorkkami,
    Login,
    IconRight,
    IconPlay,
    IconJob,
    IconSumary,
    IconTemplate,
    IconTemplateBasic,
    IconTemplateWeekdays,
    IconTemplateBlank,
    IconTimeLog,
    IconReport,
    IconFB,
    // IconTW,
    // IconIG,
    // IconIN,
    // IconYT,
    IconClose,
    BaseIcon,
    IconLandingLeft,
    IconLandingRight
  },
  mixins: [LoadingMixin],
  name: 'Landing',
  computed: {
    playStoreLink() {
      return 'https://play.google.com/store/apps/details?id=com.radius.workkami&hl=th&gl=US';
    },
    appStoreLink() {
      return 'https://apps.apple.com/us/app/workkami/id1529647285';
    },
    jobTabs() {
      return [
        {
          name: this.$t('landing.task.tabs[0]'),
          img: '/img/job-1.png'
        },
        {
          name: this.$t('landing.task.tabs[1]'),
          img: '/img/job-2.png'
        },
        {
          name: this.$t('landing.task.tabs[2]'),
          img: '/img/job-3.png'
        },
        {
          name: this.$t('landing.task.tabs[3]'),
          img: '/img/job-4.png'
        }
      ];
    },
    templateTabs() {
      return [
        {
          name: this.$t('landing.template.tabs[0]'),
          icon: 'icon-template-basic',
          img: '/img/template-basic.png'
        },
        {
          name: this.$t('landing.template.tabs[1]'),
          icon: 'icon-template-weekdays',
          img: '/img/template-weekdays.png'
        }
        // {
        //   name: this.$t("landing.template.tabs[2]"),
        //   icon: 'icon-template-blank',
        //   img: '/img/template-1.png'
        // }
      ];
    }
  },
  data() {
    return {
      mobileMenuToggle: false,
      jobIndex: 0,
      templateIndex: 0,

      showTimeLogVdo: false,
      showReportBoard: false,
      showReportExport: false,
      showReportGraph: false
    };
  },
  methods: {
    hideShowTimeLogVdo() {
      this.showTimeLogVdo = false;
    },
    hideShowReportBoard() {
      this.showReportBoard = false;
    },
    hideShowReportExport() {
      this.showReportExport = false;
    },
    hideShowReportGraph() {
      this.showReportGraph = false;
    },
    loginFail() {
      location.reload();
    },
    openLinkSocial(link) {
      console.log(link);
      window.open(link);
    }
  }
};
</script>

<style scoped>
.tab-item-default {
  color: #7a869a;
}
.point-animation {
  @apply relative;
  display: none !important;
}
.point-animation .point {
  @apply absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2;
  @apply bg-primary rounded-full;
  width: 12px;
  height: 12px;
  /* animation: sizeInOut 1s infinite;
  animation-delay: 0.5s; */
}
.point-animation .bg {
  @apply absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2;
  @apply w-6 h-6 bg-primary bg-opacity-25 rounded-full;
  animation: fadeInOut 1s infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sizeInOut {
  0% {
    width: 11px;
    height: 11px;
  }
  50% {
    width: 12px;
    height: 12px;
  }
  100% {
    width: 11px;
    height: 11px;
  }
}

video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
}
</style>
<style>
.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
</style>
