<template>
  <div>
    <div
      class="border-b p-4 text-xl capitalize flex items-center justify-between"
      data-testid="modal-create-folder"
    >
      <span>{{ modalTitle }}{{ $t('create.new_folder.folder') }}</span>
      <base-button
        data-testid="close-btn"
        icon="close"
        icon-size="18"
        color="text"
        @click="close"
      />
    </div>
    <div class="p-4">
      <paragraph-editable
        class="mb-4"
        data-testid="name-folder-input"
        bordered
        :placeholder="$t('create.new_folder.name')"
        :value="folder.name"
        @input="name = $event"
      />
      <select-colors
        v-model="color"
        data-testid="select-colors"
        :isFolder="true"
      />
    </div>
    <div class="flex justify-end p-4">
      <base-button
        :data-testid="isEdit ? 'delete-btn' : 'cancel-btn'"
        :color="isEdit ? 'danger-outline' : 'transparent'"
        wide="px-8"
        @click="isEdit ? promptDelete() : close()"
      >
        {{
          isEdit
            ? $t('create.new_folder.delete')
            : $t('create.new_folder.cancel')
        }}
      </base-button>
      <base-button
        :disabled="invalid"
        data-testid="save-btn"
        wide="px-10 py-3"
        @click="handleSave"
        >{{ $t('create.new_folder.save') }}</base-button
      >
    </div>
  </div>
</template>

<script>
import ParagraphEditable from '@/components/paragraph-editable';
import SelectColors, { colorFolder } from '@/components/select/select-colors';

const defaultValue = () => ({
  name: '\n',
  color: colorFolder[0] // Red is first item
});
export default {
  components: {
    SelectColors,
    ParagraphEditable
  },
  props: {
    folder: {
      type: Object,
      default: defaultValue
    }
  },
  data() {
    return defaultValue();
  },
  computed: {
    isEdit() {
      return this.folder.id;
    },
    modalTitle() {
      return this.isEdit
        ? this.$t('create.new_folder.edit')
        : this.$t('create.new_folder.create');
    },
    invalid() {
      return !this.name;
    },
    removeTrailingName() {
      return this.name.replace('\n', '');
    }
  },
  watch: {
    folder: {
      handler(val = defaultValue()) {
        this.name = val.name;
        this.color = val.color;
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$modal.hide('form-folder');
    },
    promptDelete() {
      this.$modal.hide('form-folder');
      this.$modal.show('delete-folder', { folder: this.folder });
    },
    handleSave() {
      const data = { name: this.removeTrailingName, color: this.color };
      const form = {};
      if (this.isEdit) form.edit = data;
      else form.create = data;
      this.$emit('form', form);
    }
  }
};
</script>

<style></style>
