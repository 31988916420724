<template>
  <modal
    :name="name"
    adaptive
    :max-width="605"
    width="100%"
    height="auto"
    @before-open="onOpen"
    @opened="openedCounts++"
    @closed="onClosed"
  >
    <div class="p-6">
      <header class="flex items-center mb-2 font-medium text-lg">
        <span
          class="has-centered-item w-10 h-10 bg-brand-lighter rounded-full mr-3"
        >
          <base-icon icon="share" size="18" class="text-brand-blue" />
        </span>
        {{ $t('modelTask.share.name') }}
      </header>
      <section class="text-sm text-gray-caption mb-5">
        <!-- {{ $t('modelTask.share.title') }} -->
      </section>
      <div class="bg-gray-main p-5 mb-6 rounded">
        <div class="flex space-x-4">
          <div
            class="p-3 border rounded inline-flex items-center flex-1 overflow-hidden relative"
            :class="loading ? 'bg-gray-main' : 'bg-white'"
          >
            <base-icon size="18" icon="share" />
            <span class="text-sm truncate">{{ sharedLink }}</span>
            <div class="pr-3 absolute right-0 has-centered-item h-full">
              <base-spinner :active="loading" size="24" />
            </div>
          </div>
          <base-select
            v-model="privacy"
            class="flex-shrink-0 bg-white inline-flex"
            :options="selectOptions"
            @change="$emit('changed', $event)"
          >
            <template #toggle="{ active }">
              <div class="p-1 flex items-center -mr-4">
                <base-icon
                  v-if="active.value === 1"
                  key="icon-public"
                  size="20"
                  icon="public-user"
                />
                <base-icon v-else key="icon-private" size="20" icon="lock" />
                <div class="ml-2">
                  {{ $t('modelTask.share')[active.label] }}
                </div>
              </div>
            </template>

            <template #label="{ props }">
              <div class="text-sm flex items-center">
                <base-icon
                  class="mr-2"
                  :icon="props.value === 1 ? 'public-user' : 'lock'"
                />
                {{ $t('modelTask.share')[props.label] }}
              </div>
            </template>
          </base-select>
        </div>
        <p class="mt-2 text-sm text-gray-caption">
          {{ shareTaskDescription }}
        </p>
      </div>
      <layout-confirm
        :key="openedCounts"
        :disabled="loading"
        right
        @cancel="hide"
      >
        <template #confirm="{attributes}">
          <base-button v-bind="attributes" @click="copyLink">
            <div class="flex items-center">
              <lottie-player
                v-show="copied"
                ref="lottiePlayer"
                class="mr-2"
                mode="normal"
                style="height: 18px;"
                :style="{ width: copied ? '18px' : '0px' }"
              />
              <div>
                {{ $t('modelTask.share')[copied ? 'copied' : 'link'] }}
              </div>
            </div>
          </base-button>
        </template>
      </layout-confirm>
    </div>

    <notifications
      group="share-link"
      class="mt-2"
      :duration="5000"
      position="bottom right"
      classes="vue-notification mr-2 text-base shadow"
    />
  </modal>
</template>

<script>
import apis from '@/services/apis';
import lottieCheck from '@/assets/lotties/check.json';
import LayoutConfirm from '../layout/layout-confirm.vue';

const LOAD_LINK_MSG = 'Generating link';

export default {
  components: { LayoutConfirm },
  props: {
    name: {
      type: String,
      default: 'share-task'
    },
    targetUrl: {
      type: String,
      required: true
    },
    defaultValue: {
      type: Number,
      default: 2
    }
  },
  data() {
    const selectOptions = [
      { label: 'public', value: 1 },
      { label: 'private', value: 2 }
    ];
    return {
      loading: true,
      sharedLink: LOAD_LINK_MSG,
      copied: false,
      // 1 = public, 2 = private
      privacy: this.defaultValue,
      labelTimeout: 0,
      selectOptions,
      isLottieLoaded: false,
      lottieCheck,
      openedCounts: new Date().getTime()
    };
  },
  computed: {
    shareTaskDescription() {
      const privacyLabel = this.selectOptions
        .find(e => e.value === this.privacy)
        .label.toLowerCase();
      return this.$t('modelTask.share.description')[privacyLabel];
    },
    player() {
      return this.$refs.lottiePlayer;
    }
  },
  watch: {
    privacy: {
      handler(val) {
        this.copied = false;
        this.getSharedLink(val);
      }
    }
  },
  methods: {
    getSharedLink(privacy) {
      const locale = this.$i18n.locale;
      function getBrowserHostAndLocale(url) {
        const hostReg = /https?:\/\/.*?\//;
        const { origin } = location;
        const browserHostAndLocale = `${origin}/${locale}/`;
        return url.replace(hostReg, browserHostAndLocale);
      }
      this.loading = true;
      this.sharedLink = this.$t('modelTask.share.generating');
      setTimeout(async () => {
        try {
          const res = await apis.get(this.targetUrl, {
            params: { privacy }
          });
          this.sharedLink = getBrowserHostAndLocale(res.data.shareLinkUrl);
        } catch (error) {
          this.sharedLink = 'Fail to get link, please try again.';
        }
        this.loading = false;
      }, 1000);
    },
    copyLink() {
      const el = document.createElement('textarea');

      this.copied = true;
      this.playCopied();
      el.value = this.sharedLink;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    toggleLabel() {
      this.copied = !this.copied;
    },
    hide() {
      this.$modal.hide(this.name);
    },
    onOpen({ params }) {
      this.privacy = params.defaultValue;
      this.getSharedLink(this.privacy);
    },
    onClosed() {
      this.copied = false;
      this.loading = true;
      this.isLottieLoaded = false;
      this.sharedLink = LOAD_LINK_MSG;
      this.$emit('closed');
    },
    playCopied() {
      if (!this.isLottieLoaded) {
        this.player.load(lottieCheck);
        this.isLottieLoaded = true;
      }
      this.player.stop();
      this.player.play();
    }
  }
};
</script>

<style></style>
