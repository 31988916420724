<template>
  <div class="flex justify-center">
    <div>
      <div class="mt-12 flex justify-center">
        <img :src="image" class="max-w-sm" />
      </div>
      <p class="mt-12 text-xl font-medium text-center">
        <slot name="heads">{{ title }}</slot>
      </p>
      <div class="mt-3 text-center">
        <p class="font-light max-w-md text-gray-caption text-sm">
          <slot>{{ description }}</slot>
        </p>
      </div>
      <div class="mt-10 text-center">
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  }
};
</script>
