import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const _dev = () => {
  const isProd = location.hostname === 'www.workkami.com';
  return isProd ? '' : '_dev';
};

const config = {
  apiKey: 'AIzaSyBj9D6J4Fl6e3NUHinDszq7JnX8Fk2rx_M',
  authDomain: 'workkami.firebaseapp.com',
  databaseURL: 'https://workkami.firebaseio.com',
  projectId: 'workkami',
  storageBucket: 'workkami.appspot.com',
  messagingSenderId: '827661701883',
  appId: '1:827661701883:web:b7b9b26c944046cdfad65e',
  measurementId: 'G-3F7G4ENY1R'
};

firebase.initializeApp(config);

const { auth, firestore, analytics } = firebase;

const getPath = (strings: TemplateStringsArray) =>
  `workkami_${strings[0]}${_dev()}`;

export const getCollections = () => ({
  boards: getPath`boards`,
  tasks: getPath`tasks`,
  users: getPath`users`,
  boardColumns: getPath`columns`,
  timeLogs: getPath`time_logs`
});

export default {
  auth,
  firestore,
  analytics
};
