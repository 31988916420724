<template>
  <div
    class="board-member"
    :class="{ hoverable }"
    @click="$emit('select', !selected)"
  >
    <div
      class="board-member__radio flex-perfect-center"
      :class="{ 'radio--selected': selected }"
    >
      <base-icon v-if="selected" class="text-transparent">
        <icon-check-circle />
      </base-icon>
    </div>
    <img
      v-if="!unassigned"
      class="board-member__profile object-cover"
      :src="profileUser(user)"
    />
    <div v-else class="board-member__profile flex-perfect-center">
      <base-icon view-box="0 0 15 15" :width="15" :height="15">
        <icon-user />
      </base-icon>
    </div>

    <div v-if="!unassigned" class="pl-3 overflow-hidden">
      <div v-if="me">
        <div class="name__text">{{ user.name }} ({{ $t('invite.you') }})</div>
        <div class="email__text">
          {{ user.email }}
        </div>
      </div>
      <div v-else>
        <div v-if="user.userID != undefined">
          <div class="name__text">
            {{ user.user.name || user.name }}
          </div>
          <div class="email__text">
            {{ user.user.email || user.email }}
          </div>
        </div>
        <div v-else>
          <div class="name__text">
            {{ user.inviteToEmail }}
          </div>
          <div class="email__text">
            {{ $t('invite.pending') }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="pl-3">Unassigned</div>
  </div>
</template>

<script>
import IconUser from '@/components/icons/IconUser';
import IconCheckCircle from '../icons/IconCheckCircle.vue';

export default {
  props: {
    user: {},
    me: {
      type: Boolean,
      default: false
    },
    unassigned: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    hoverable: {
      type: Boolean,
      default: true
    }
  },
  components: { IconUser, IconCheckCircle },
  data() {
    return {};
  },
  computed: {},
  methods: {
    profileUser(profile) {
      return (
        profile.profilePicture ||
        profile.user.profilePicture ||
        'https://storage.googleapis.com/workkami-files-dev/statics/f6dd2895acbe4cf4b6d2efa5f279855f_thumb.png'
      );
    }
  }
};
</script>

<style scoped lang="scss">
$icon-size: 14px;
.board-member {
  @apply flex items-center cursor-pointer px-4 py-1 overflow-hidden;

  &.hoverable:hover {
    @apply bg-light-gray;
  }

  &.px-0 {
    @apply px-0;
  }
}
.board-member__radio {
  @apply border rounded-full mr-2 cursor-pointer flex-shrink-0;
  width: $icon-size;
  height: $icon-size;

  &:not(.radio--selected) {
    @apply border-gray-500;
  }

  &.radio--selected {
    @apply border-brand-blue bg-brand-blue;
  }
}

.board-member__profile {
  @apply w-8 h-8 rounded-full flex-shrink-0 bg-gray-300;
}

.name__text {
  @apply text-gray-800 whitespace-no-wrap truncate font-medium;
}

.email__text {
  @apply text-gray-600 text-sm truncate;
}
</style>
