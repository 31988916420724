<template>
  <div>
    <modal
      name="subscription-expired"
      adaptive
      height="auto"
      width="764"
      scrollable
      :click-to-close="false"
    >
      <div>
        <div class="bg-header text-white padding-22">
          <p class="text-lg">{{ $t('subscriptions_have') }}</p>
          <p class="text-xl">{{ $tc('day_have', daysLeft) }}</p>
          <p class="text-sm" v-html="$t('select_package')" />
          <img
            src="/img/computer.png"
            class="absolute right-0 mr-12"
            style="top: 45px;"
          />
        </div>

        <div class="px-8">
          <div class="mt-12 mb-4">
            <p class="mb-4">{{ $t('features') }}</p>
            <div class="flex">
              <div class="flex mr-4">
                <img src="/img/unlimit-member.png" class="img-unlimit" />
                <div>
                  <p>{{ $t('feature.member.title') }}</p>
                  <p
                    class="text-xs text-gray-caption"
                    v-html="$t('feature.member.description')"
                  />
                </div>
              </div>
              <div class="flex">
                <img src="/img/unlimit-storage.png" class="img-unlimit" />
                <div>
                  <p>{{ $t('feature.dashboard.title') }}</p>
                  <p
                    class="text-xs text-gray-caption"
                    v-html="$t('feature.dashboard.description')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <p class="mb-2">{{ $t('seat') }}</p>
            <base-select
              style="width:200px"
              max-height="200"
              full-width
              :options="optionSeat"
              :placeholder="seatNum.label"
              placeholder-classes="text-black"
              @change="onDurationChanged"
            />
          </div>
          <p class="mb-2">{{ $t('plan') }}</p>
          <div class="mb-6">
            <div class="border p-4 rounded">
              <div class="flex">
                <p v-if="haveCurrentPlan" class="text-lg font-semibold">
                  {{ planCurrentName }}
                </p>
                <p v-else class="text-lg font-semibold">
                  {{ $t('no_plan') }}
                </p>
                <base-button
                  v-if="haveCurrentPlan"
                  color="text"
                  icon="ChangePlan"
                  responsive
                  icon-size="20"
                  class="border-brand-blue text-brand-blue ml-auto"
                  style="width: fit-content"
                  @click="changePlan()"
                  >{{ $t('change_plan') }}</base-button
                >
                <base-button
                  v-else
                  color="text"
                  responsive
                  icon-size="20"
                  class="border-brand-blue text-brand-blue ml-auto"
                  style="width: fit-content"
                  @click="changePlan()"
                  >{{ $t('select_plan') }}</base-button
                >
              </div>
              <div v-if="haveCurrentPlan">
                <p>
                  <span class="text-xl font-semibold"
                    >฿{{ planCurrentPricePerSeat }}
                  </span>
                  {{ $t('subscription.payment.seat_per_month') }}
                </p>
              </div>
            </div>
          </div>

          <div class="flex mb-8">
            <base-button
              class="ml-auto"
              color="white"
              @click="closeExpired()"
              >{{ $t('cancel') }}</base-button
            >
            <base-button :disabled="!haveCurrentPlan" @click="proceedClick()">{{
              $t('proceed')
            }}</base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import SelectOptionSeat from '@/mixins/OptionSeat.ts';
import moment from 'moment';

export default Vue.extend({
  mixins: [SelectOptionSeat],
  props: {},
  data() {
    return {
      seatNum: { label: '5 seat', value: 5 }
    };
  },
  watch: {
    suggessNumberMember() {
      this.seatNum = this.SelectOptionSeat.find(
        option => option.value === this.suggessNumberMember
      );
    }
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
    planCurrentName() {
      return this.getCurrentPlan.payment?.subscriptionOrder.name;
    },
    planCurrentPricePerSeat() {
      return this.getCurrentPlan.payment?.subscriptionOrder.pricePerSeat;
    },
    profile() {
      return this.$store.getters['auth/getUser'];
    },
    suggessNumberMember() {
      return this.profile.suggestionNumberOfSubscriptionMember;
    },
    optionSeat() {
      const numberSuggess = this.suggessNumberMember;
      const optionSuggess = this.SelectOptionSeat.filter(
        option => option.value >= numberSuggess || option.value === 0
      );
      return optionSuggess;
    },
    haveCurrentPlan() {
      return this.getCurrentPlan.payment;
    },

    daysLeft() {
      const endDate = moment(this.getCurrentPlan.payment?.endAt);
      const today = moment(new Date());
      const daysLeft = endDate ? endDate.diff(today, 'days') : 0;
      return daysLeft;
    }
  },

  methods: {
    onDurationChanged(value) {
      const seatSelect = this.SelectOptionSeat.find(x => x.value === value);
      this.seatNum = seatSelect;
    },
    changePlan() {
      this.$modal.hide('subscription-expired');
      this.$modal.show('plan-card');
    },
    async proceedClick() {
      await this.$store.dispatch('subscription/subscriptionPlanPreview', {
        numberOfSeats: this.seatNum.value,
        planID: this.haveCurrentPlan.subscriptionOrder.planID
      });
      this.$modal.show('plan-payment');
      this.$modal.hide('subscription-expired');
    },
    closeExpired() {
      const now = new Date().getTime();
      localStorage.setItem('setupTime_modal_expired_subscription', now);
      localStorage.setItem('setupTime_require_subscription', now);

      this.$modal.hide('subscription-expired');
    }
  }
});
</script>

<style scoped>
.bg-header {
  background: linear-gradient(88.91deg, #168ae2 0%, #3fa5f3 100%);
}

.img-unlimit {
  @apply mr-2;
  width: 40px;
  height: 40px;
}

.padding-22 {
  padding-top: 22px;
  padding-left: 22px;
  padding-bottom: 22px;
  padding-right: 22px;
}
</style>
<i18n>
{
  "en": {
    "subscriptions_have": "Time Remaining",
    "day_have": "{n} days left.",
    "select_package": "Select a package or switch to another that will best accommodate your team",
    "features":"Function",
    "unlimited_members":"Unlimited members",
    "no_plan": "No Plan",
    "per_user_billed": "Per user per month billed {price} THB monthly.",
    "cancel": "Cancel",
    "proceed": "Process with Payment",
    "select_plan": "Select plan",
    "change_plan": "Change plan",
    "plan": "Plans",
    "seat": "Number of Member",
    "feature":{
      "member":{
        "title": "Make team management easier",
        "description": "Simply add or delete member<br/> from any workspace"
      },
      "dashboard":{
        "title": "Show work summary in one page",
        "description": "Summarize your workspace<br/> in the form of a dashboard"
      }
    }
  },
  "th": {
    "subscriptions_have": "ระยะเวลาคงเหลือ",
    "day_have": "ใช้งานได้อีก {n} วัน",
    "select_package": "สามารถเลือกหรือเปลี่ยนแพ็คเกจเพื่อจัดการทีมตามขนาดของแต่ละองค์กรได้",
    "features":"ฟังก์ชัน",
    "unlimited_members":"$tUnlimited members",
    "no_plan": "ยังไม่มีแผน",
    "per_user_billed": "$tPer user per month billed {price} THB monthly.",
    "cancel": "ยกเลิก",
    "proceed": "ดำเนินการชำระเงิน",
    "select_plan": "เลือกแผน",
    "change_plan": "เปลี่ยนแผน",
    "plan": "แผน",
    "seat": "ที่นั่ง",
     "feature":{
      "member":{
        "title": "สามารถเพิ่มสมาชิกได้ง่ายชึ้น",
        "description": "สามารถเพิ่มหรือลบสมาชิกไปยังเวิร์ค<br/>สเปซไหนก็ได้"
      },
      "dashboard":{
        "title": "แสดงผลการทำงานภาพรวมในหน้าเดียว",
        "description": "สรุปผลการทำงานของเวิร์คสเปซในรูปแบบ<br/>ของ Dashboard"
      }
    }
  }
}
</i18n>
