<template>
  <v-calendar
    ref="calendar"
    :locale="$t('notifications.language')"
    class="timelog-calendar--month"
    v-bind="calendarProps"
    is-expanded
    style="border: none;"
  >
    <div slot="header" />
    <div slot="header-left-button" />
    <div slot="header-right-button" />
    <template #day-content="{ day }">
      <div class="relative flex items-center justify-center">
        <chart-doughnut
          class="chart-doughnut"
          :text-center="day.day.toString()"
          :chart-data="{
            datasets: [dateChartData[day.day] || { data: [] }]
          }"
          :options="chartOptions(day.date)"
          :styles="{ height: '70px', width: '70px' }"
        />
      </div>
    </template>
  </v-calendar>
</template>

<script>
import moment from 'moment';
import VCalendar from 'v-calendar/lib/components/calendar.umd';
import ChartDoughnut from '@/components/chart/chart-doughnut';
export default {
  components: {
    VCalendar,
    ChartDoughnut
  },
  props: {
    date: {
      type: Object
    },
    timelogs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    dateChartData() {
      const mDate = moment(this.date).locale(
        `${this.$t('notifications.language')}`
      );
      const monthData = [...Array(mDate.daysInMonth())].reduce(
        (p, c, i) => ({
          ...p,
          [i + 1]: {
            data: [],
            backgroundColor: []
          }
        }),
        {}
      );
      this.timelogs.forEach(timelog => {
        const month =
          monthData[
            moment(timelog.startAt)
              .locale(`${this.$t('notifications.language')}`)
              .date()
          ];
        if (month) {
          month.data.push(timelog.timeSpent);
          month.backgroundColor.push(timelog.color);
        }
      });
      return monthData;
    },
    calendarProps() {
      return {
        masks: {
          weekdays: 'WWW'
        }
      };
    }
  },
  watch: {
    date(val) {
      const mDate = moment(val).locale(`${this.$t('notifications.language')}`);
      this.$refs.calendar.move({
        month: mDate.month() + 1,
        year: mDate.year()
      });
    }
  },
  methods: {
    chartOptions(date) {
      return {
        responsive: true,
        tooltips: {
          enabled: false
        },
        cutoutPercentage: 60,
        onClick: () => {
          this.$emit('select', date);
        }
      };
    }
  }
};
</script>

<style>
.timelog-calendar--month .vc-weekday {
  @apply uppercase;
}
</style>
