var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.avatar)?_c('v-popover',{staticClass:"base-avatar",class:{
    hoverable: _vm.hoverable,
    'avatar--selected': _vm.selected,
    'size--small': _vm.sizeSmall,
    'size--smaller': _vm.sizeSmaller
  },style:({
    width: _vm.size,
    height: _vm.size
  }),attrs:{"offset":"8","trigger":"hover","disabled":!_vm.popup,"placement":_vm.popupPlacement,"popover-class":"avatar--popover"}},[_c('img',{staticClass:"avatar__profile",class:Object.assign({}, _vm.listItemClass),attrs:{"src":_vm.getPath}}),_c('p',{staticClass:"text-sm",attrs:{"slot":"popover"},slot:"popover"},[(_vm.userInfo.id)?_c('span',[_vm._v(" "+_vm._s(_vm.userInfo.name)+" "),(_vm.userInfo.id === _vm.$store.getters['auth/getUser'].id)?_c('span',{attrs:{"slot":"after"},slot:"after"},[_vm._v(" ("+_vm._s(_vm.$t('invite.you'))+") ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(_vm.avatar.inviteToEmail))])])]):_c('div',{staticClass:"flex items-center avatar--list"},[_vm._l((_vm.visibleList),function(item,i){return _c('base-avatar',{key:("avatar-" + (item.id) + "-" + i),attrs:{"avatar":item,"size-small":_vm.sizeSmall,"size-smaller":_vm.sizeSmaller,"popup":_vm.popup,"list-item-class":_vm.listItemClass,"selected":_vm.isSelected(item)},nativeOn:{"click":function($event){return _vm.onclick(item)}}})}),(_vm.visibleList.length)?_c('v-popover',{staticClass:"inline-flex avatar-dropdown-responsive avatar__dropdown-more",class:{
      'size--small': _vm.sizeSmall,
      'size--smaller': _vm.sizeSmaller,
      'avatar-dropdown--empty': !_vm.moreItems
    },attrs:{"popover-class":("" + (_vm.slotPopover ? 'user-filter-width' : '')),"placement":_vm.dropdownPosition}},[_c('div',{ref:"tooltipToggle",staticClass:"base-avatar__more",class:[_vm.listItemClass, { 'avatar--selected': _vm.isMoreSelected }]},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.moreItems),expression:"moreItems"}],staticClass:"avatar-more--degit has-centered-item",style:({
          'font-size': _vm.sizeSmall || _vm.sizeSmaller ? '10px' : 'inherit'
        })},[_vm._v(" +"+_vm._s(_vm.moreItems)+" ")])]),_c('v-scroller',{staticClass:"avatar__dropdown--item",style:({
        width: _vm.dropdownWidth,
        'max-height': _vm.listMaxHeightPx
      }),attrs:{"slot":"popover"},on:{"bottom":function($event){return _vm.$emit('scroll-bottom')}},slot:"popover"},[(_vm.slotPopover)?_vm._t("popover"):_c('div',[_vm._l((_vm.moreItemsList),function(moreItem,i){return _c('div',{key:("more-avatar-" + (moreItem.id) + "-" + i),staticClass:"base-dropdown__item hoverable",on:{"click":function($event){return _vm.$emit('click', moreItem)}}},[_c('div',{staticClass:"flex items-center"},[_c('base-avatar',{attrs:{"avatar":moreItem,"selected":_vm.isSelected(moreItem),"size-small":""}}),_c('div',{staticClass:"flex-1 flex flex-col overflow-hidden"},[_c('v-clamp',{staticClass:"text-black truncate text-sm pl-2 font-medium",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(moreItem.name)+" "),(moreItem.id === _vm.$store.getters['auth/getUser'].id)?_c('span',{staticClass:"text-gray-caption text-xs",attrs:{"slot":"after"},slot:"after"},[_vm._v(" ("+_vm._s(_vm.$t('invite.you'))+") ")]):_vm._e()]),_c('v-clamp',{staticClass:"text-gray-600 text-sm pl-2 truncate",attrs:{"max-lines":1,"autoresize":""}},[_vm._v(" "+_vm._s(moreItem.email)+" ")])],1)],1)])}),(_vm.loading)?_c('div',{staticClass:"base-dropdown__item"},[_c('div',{staticClass:"text-center"},[_c('base-spinner')],1)]):_vm._e()],2)],2)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }