import { ApiPagination, PageInformation, ApiCommonFields } from './Api';
import { Folder } from './Folder';
import { User } from './User';
import { TeamMemberStatus } from './Team';

export type Role = 1 | 2 | 100;

export enum BoardTemplate {
  Blank,
  Weekday,
  Basic
}

export enum BoardMemberRole {
  Viewer = 1,
  Editor = 2,
  Admin = 100
}

export enum BoardType {
  KANBAN,
  WATERFALL,
  AGILE
}

export enum ColumnStatus {
  Todo,
  InProgress,
  Done
}

export interface BoardColumnAdd {
  name: string;
  position: number;
}
export interface BoardColumn extends ApiCommonFields, BoardColumnAdd {
  archivedByUserId: number;
  archived_at: string;
  board_id: number;
  status: ColumnStatus;
}

export interface BoardAdd {
  description: string;
  key: string;
  name: string;
  template: BoardTemplate;
  folderID: number;
  backgroundCover: string;
  type: BoardType;
  teamID?: number;
}

export interface BoardUpdate {
  name?: string;
}

export interface Board extends BoardAdd, ApiCommonFields {
  archivedByUserId: number;
  archived_at: string;
  backgroundCover: string;
  image: string;
  status: number;
  trashedByUserId: number;
  trashed_at: string;
  isStarred: boolean;
  columns: BoardColumn[];
  createdByUsername: string;
  isMemberJoined: boolean;
  currentMember: {
    id: number;
    folderID: number;
    role: BoardMemberRole;
    position: number;
    isMute: boolean;
  };
  viewOnly: boolean;
}

export interface BoardsResponse extends ApiPagination<Board> {
  recentlyViewed: Board[];
  all: Board[];
  starred: Board[];
  folders: Folder[];
  pageInformation: PageInformation;
}

export interface BoardKickMemberRequest {
  id: number;
  memberID: number;
}

export enum InviteReturnType {
  Nothing,
  Email
}

export enum BoardMemberStatus {
  Unknown,
  Pending,
  Active,
  Leave,
  Deactivated
}

export interface BoardInvitationRequest {
  emails: string[];
  role: Role;
  id: number;
  returnType: InviteReturnType;
}

export interface BoardMemberSetRoleRequest {
  id: number;
  userID: number;
  role: Role;
  memberID: number;
}

export interface BoardMember {
  id: number;
  createdAt: string;
  updatedAt: string;
  inviteToEmail: string;
  role: Role;
  status: BoardMemberStatus;
  unread: boolean;
  lastSeenAt: string;
  boardID: number;
  userID: number;
  user: User;
  isCurrentUser: boolean;
  memberID: number;
}

export interface MyTasks {
  q?: string;
  filterType?: number;
}

export interface TaskLinkUpdate {
  taskShareLinkURL?: string;
  toTaskID: number;
}

export interface TaskLink {
  id: number;
}

export interface BoardCopyRequest {
  id: number;
  name: string;
}
