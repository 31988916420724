<template>
  <div>
    <slot :events="{ toggle, toggleSelectBoard, toggleSelectTask }">
      <button
        class="button timelog-selector"
        @click="() => !disabled && toggleSelectBoard()"
      >
        <div class="w-full">
          <div v-if="selectBoard" class="flex items-baseline">
            <v-clamp :max-lines="2" class="text-lg">
              {{ $t('timelog.select.choose.board') }} : {{ selectBoard.name }}
            </v-clamp>
            <button
              class="btn--remover button"
              @click.stop="onSelect({ board: null, task: null })"
            >
              <fa-icon icon="times" size="xs" />
            </button>
          </div>
          <div v-else class="flex items-baseline">
            <div
              class="has-centered-item w-5 h-5 border-brand-blue border-2 rounded-full"
            >
              <fa-icon icon="plus" size="xs" />
            </div>
            <span class="ml-2 text-lg">{{ $t('timelog.select.board') }}</span>
          </div>
        </div>
      </button>
      <button
        v-if="selectBoard"
        class="button timelog-selector"
        @click="() => !disabled && toggleSelectTask(selectBoard)"
      >
        <div class="w-full">
          <div v-if="selectTask" class="flex items-baseline">
            <v-clamp :max-lines="2" class="text-lg">
              <template #before
                >{{ $t('timelog.select.choose.task') }} :
              </template>
              {{ selectTask.name }}
            </v-clamp>

            <button
              class="btn--remover button"
              @click.stop="onSelect({ board: selectBoard, task: null })"
            >
              <fa-icon icon="times" size="xs" />
            </button>
          </div>
          <div v-else class="flex items-center">
            <div
              class="has-centered-item w-5 h-5 border-brand-blue border-2 rounded-full"
            >
              <fa-icon icon="plus" size="xs" />
            </div>
            <span class="ml-2 text-lg">{{ $t('timelog.select.task') }}</span>
          </div>
        </div>
      </button>
    </slot>
    <modal
      :id="modalName"
      :name="modalName"
      scrollable
      adaptive
      height="auto"
      :width="modalWidth"
      @before-close="keepBodyUnscroll"
    >
      <div class="flex flex-col overflow-hidden">
        <div class="border-b p-4">
          <div class="flex mb-2 items-start">
            <p class="flex-1">
              <button
                v-if="canGoBack"
                class="px-3 py-1 hover:bg-light-gray"
                @click="activeSelectBoard = true"
              >
                <fa-icon icon="chevron-left" />
              </button>
              <strong> {{ selectText }} </strong>
            </p>
            <button class="px-3 py-1 hover:bg-light-gray" @click="toggle">
              <fa-icon icon="times" size="lg" />
            </button>
          </div>
          <div>
            <div v-if="displayBoardSearch === true">
              <base-input-text
                v-if="activeSelectBoard"
                v-model="searchBoard"
                inner-class="base-input--gray"
                :placeholder="$t('timelog.search.board')"
                icon-fa="search"
              />
            </div>
            <div v-if="displayTaskSearch === true">
              <base-input-text
                v-if="!activeSelectBoard"
                v-model="searchTask"
                inner-class="base-input--gray"
                :placeholder="$t('timelog.search.task')"
                icon-fa="search"
              />
            </div>
          </div>
        </div>
        <div>
          <base-empty
            v-if="activeSelectBoard"
            v-show="showEmptyBoard"
            style="height:360px"
            :description="$t('timelog.description.board')"
            :image="require('@/assets/image/no-board.svg')"
          />
          <base-empty
            v-else
            v-show="showEmptyTask"
            style="height:360px"
            :description="$t('timelog.description.task')"
            :image="require('@/assets/image/no-task.svg')"
          />
          <v-scroller
            v-show="!showEmptyBoard"
            v-if="activeSelectBoard"
            class="select-list"
            :loading="fetchBoardLoading"
            :height="listHeight"
            @bottom="scrollBottom"
          >
            <div v-if="showRecentboard && !fetchBoardLoading">
              <div class="ml-5 mt-3 flex">
                <base-icon size="16" class="mt-1">
                  <icon-recently-view />
                </base-icon>
                <span class="text-base font-medium"
                  >{{ $t('timelog.search.recently') }}
                </span>
                <div class="ml-2" style="color: #7A869A;">
                  <span class="mr-1 text-sm font-normal">
                    {{ boardRecently.length }}</span
                  >
                  <span class="text-sm font-normal ">{{
                    $t('timelog.search.boards')
                  }}</span>
                </div>
              </div>
              <board-list
                :boards="boardRecently"
                is-static
                @click="onSelect({ board: $event }, true)"
              />
            </div>
            <div v-if="!fetchBoardLoading" class="ml-5 mt-3 flex">
              <base-icon size="16" class="mt-1">
                <icon-board-search />
              </base-icon>
              <span class="text-base font-medium"
                >{{ $t('timelog.search.boards') }}
              </span>
              <div class="ml-2" style="color: #7A869A;">
                <span class="mr-1 text-sm font-normal">
                  {{ boards.length }}</span
                >
                <span class="text-sm font-normal ">{{
                  $t('timelog.search.boards')
                }}</span>
              </div>
            </div>
            <board-list
              :boards="boards"
              is-static
              @click="onSelect({ board: $event }, true)"
            />
          </v-scroller>
          <v-scroller
            v-else
            v-show="!showEmptyTask"
            :height="listHeight"
            :loading="loading"
          >
            <div class="my-tasks px-4 py-5">
              <div class="task">
                <div v-if="!isListTask">
                  <my-task-task
                    v-for="task in tasks"
                    :key="task.id"
                    :task="task"
                    class="select-list mt-3 select-list--hover py-2 pr-3"
                    :class="{ 'select-list--active': task.id === valueTaskId }"
                    is-static
                    @click.native="onSelect({ board: selectBoard, task }, true)"
                  />
                </div>
                <div v-else>
                  <list-tasks-draggable
                    v-model="tasks"
                    :disabled="true"
                    :hide-description="true"
                    @click="selectedTask"
                  />
                </div>
              </div>
            </div>
          </v-scroller>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import VClamp from 'vue-clamp';
import debounce from 'lodash/debounce';
import { boardsApi } from '@/services/apis';
import { BoardMemberRole } from '@/types/Board';
import VScroller from '@/components/scroller';
import BoardList from '@/components/board/board-list';
import MyTaskTask from '@/components/my-task/my-task-task';
import IconRecentlyView from '@/components/icons/IconRecentlyView';
import IconBoardSearch from '@/components/icons/IconBoardSearch';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import ListTasksDraggable from '@/components/list/list-tasks-draggable.vue';

export default {
  props: {
    modalName: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      default() {
        return {
          board: null,
          task: null
        };
      }
    },
    selects: {
      type: Array,
      default: () => ['board', 'task']
    },
    disabled: Boolean,
    isListTask: {
      type: Boolean,
      required: false
    }
  },
  components: {
    VScroller,
    BoardList,
    VClamp,
    IconRecentlyView,
    IconBoardSearch,
    ListTasksDraggable,
    MyTaskTask
  },
  mixins: [DateTimeMixin],
  data() {
    return {
      loading: true,
      activeSelectBoard: true,
      // board
      searchBoard: '',
      boards: [],
      boardRecently: [],
      selectBoard: null,
      boardPage: {},
      boardNextPage: 1,
      // task
      searchTask: '',
      tasks: [],
      selectTask: null,
      displayBoardSearch: true,
      displayTaskSearch: true,
      showRecentboard: true
    };
  },
  computed: {
    listHeight: () => `360px`,
    showEmptyBoard() {
      return !this.boards.length && !this.loading;
    },
    showEmptyTask() {
      return !this.tasks.length && !this.loading;
    },
    valueBoardId() {
      return (this.value.board ?? {}).id;
    },
    valueTaskId() {
      return (this.value.task ?? {}).id;
    },
    selectText() {
      return this.activeSelectBoard
        ? this.$t('timelog.select.board')
        : this.$t('timelog.select.taskIn').concat(' ' + this.selectBoard?.name);
    },
    fetchBoardsDep() {
      return [this.searchBoard, this.boardNextPage];
    },
    fetchTasksDep() {
      return [this.searchTask, this.selectBoard];
    },
    fetchBoardLoading() {
      const hasNext = this.boardPage.lastPage > this.boardNextPage;
      return hasNext || this.loading;
    },
    onlySelectBoard() {
      return this.selects.length === 1 && !this.canSelectTask;
    },
    canSelectTask() {
      return this.selects.includes('task');
    },
    onlySelectTask() {
      return this.selects.length === 1 && this.canSelectTask;
    },
    canGoBack() {
      return !this.activeSelectBoard && this.selects.length > 1;
    },
    selectBoardVisible() {
      return !this.selectBoard || this.onlySelectBoard;
    },
    modalWidth() {
      const cardWidth = 265;
      const numberOfCards = 4;
      const windowOffset = 24;
      return cardWidth * numberOfCards + windowOffset;
    }
  },
  watch: {
    value: {
      handler(val) {
        this.onSelect(val);
      },
      immediate: true,
      deep: true
    },
    selectBoard: {
      handler(board) {
        if (board?.id && this.canSelectTask) {
          this.fetchTasks();
          this.activeSelectBoard = !this.canSelectTask;
        }
      },
      immediate: true
    },
    fetchBoardsDep: {
      immediate: true,
      handler: 'onFetchBoardDepChanged'
    },
    fetchTasksDep(dep) {
      const selectBoard = dep[1];
      this.tasks = [];
      this.taskNextPage = 1;

      if (selectBoard) {
        this.loading = true;

        this.$nextTick(() => {
          this.fetchTasks();
        });
      }
    }
  },
  methods: {
    async selectedTask(task) {
      this.onSelect({ board: this.selectBoard, task }, true);
    },
    toggle() {
      this.searchBoard = '';
      this.searchTask = '';
      this.$modal.toggle(this.modalName);
    },
    hide() {
      this.$modal.hide(this.modalName);
    },
    toggleSelectBoard() {
      this.toggle();
      this.activeSelectBoard = true;
    },
    toggleSelectTask(board = this.value.board) {
      this.toggle();
      this.activeSelectBoard = false;
      this.selectBoard = board;
    },
    openSelectTask(board) {
      this.activeSelectBoard = false;
      this.selectBoard = board;
    },
    opened() {
      this.fetchBoards();
    },
    onFetchBoardDepChanged: debounce(function(dep, prev = ['']) {
      if (this.onlySelectTask) return;
      const [search] = dep;
      const [prevSearch] = prev;
      if (search !== prevSearch) {
        this.boards = [];
        this.boardRecently = [];
        this.boardNextPage = 1;
        this.loading = true;
      }
      this.$nextTick(() => {
        if (this.searchBoard === '') {
          this.showRecentboard = true;
          this.fetchBoards();
        } else {
          this.showRecentboard = false;
          this.fetchBoards();
        }
      });
    }, 500),
    fetchBoards: debounce(async function() {
      const filterViewerBoard = board =>
        board.currentMember.role !== BoardMemberRole.Viewer;
      const params = {
        q: this.searchBoard,
        page: this.boardNextPage,
        sortByField: 'recent'
      };
      const { data } = await boardsApi.index({ params });
      const boards = data.all.filter(filterViewerBoard);
      const recentlyViewed = data.recentlyViewed.filter(filterViewerBoard);
      this.boards = [...this.boards, ...boards];
      this.boardPage = data.pageInformation;
      this.countBoard = this.boards.length;
      this.boardRecently = recentlyViewed;
      this.loading = false;
      if (this.boards.length === 0 && this.searchBoard.length === 0) {
        this.displayBoardSearch = false;
      } else if (this.searchBoard !== '') {
        this.displayRecently === false;
      }
    }, 500),
    resetBoard() {
      this.searchBoard = '';
      this.boards = [];
      this.boardRecently = [];
      this.boardNextPage = 1;
      this.selectBoard = null;
    },
    fetchTasks: debounce(async function() {
      /*
        filter type 2 for incomplete task
      */
      const params = { q: this.searchTask, filterType: 1 };
      const { data } = await boardsApi.tasksByBoard(this.selectBoard.id, {
        params
      });

      data.forEach(e => {
        if (e.endAt) e.diffDate = this.dayDiff(e.endAt);
        else e.diffDate = undefined;
      });

      this.tasks = [...data].sort(this.sortByUpdatedTasks);
      this.loading = false;
      if (this.tasks.length === 0 && this.searchTask.length === 0) {
        this.displayTaskSearch = false;
      } else {
        this.displayTaskSearch = true;
      }
    }, 500),
    resetTask() {
      this.searchTask = '';
      this.tasks = [];
      this.selectTask = null;
    },
    scrollBottom: debounce(function() {
      if (this.boardPage.lastPage > this.boardNextPage) {
        this.boardNextPage += 1;
      }
    }, 500),
    onSelect(payload = { board: null, task: null }, hide) {
      const { board, task } = payload;

      if (board) this.openSelectTask(board);

      if (hide) this.hide();

      this.selectBoard = board;
      this.selectTask = task;
      this.$emit('input', payload);
      this.$emit('select', payload);
    },
    breakline(text) {
      const isAllNoneText = /^\W+$/;
      return isAllNoneText.test(text);
    },
    sortByUpdatedTasks({ updatedAt: a }, { updatedAt: b }) {
      return b.localeCompare(a);
    },
    keepBodyUnscroll() {
      const openingSelector = `.vm--container:not(#${this.modalName})`;
      const otherOpeningModal = document.querySelector(openingSelector);
      if (otherOpeningModal) {
        document.body.classList.add('vm--block-scroll');
      }
    }
  }
};
</script>

<style scoped>
.select__none {
  @apply text-brand-blue;
}
.select-list {
  @apply rounded;
}
.select-list--hover:hover {
  @apply cursor-pointer bg-gray-100;
}
.select-list--active,
.select-list--active:hover {
  @apply bg-gray-300;
}

.button {
  @apply max-w-full;
}
.timelog-selector {
  @apply flex text-left text-brand-blue shadow-none rounded py-1 overflow-hidden px-0 font-medium;
}
button.timelog-selector:hover {
  @apply underline;
}
.btn--remover {
  @apply text-gray-600 w-6 h-6 rounded-full bg-gray-100 ml-2 shadow-none;
}
.btn--remover:hover {
  @apply bg-gray-200;
}
.btn--remover:active {
  @apply bg-gray-300;
}
</style>
