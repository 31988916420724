
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Doughnut, mixins } from 'vue-chartjs';
import { ChartData, ChartOptions } from 'chart.js';

@Component({
  extends: Doughnut,
  mixins: [mixins.reactiveProp]
})
export default class ChartDoughnut extends Mixins(Doughnut) {
  @Prop({ type: Object, default: () => ({}) }) chartData!: ChartData;
  @Prop({ type: Object, default: () => ({}) }) options!: ChartOptions;
  @Prop({ type: String, default: '' }) textCenter!: string;
  @Prop({ type: Boolean, default: false }) textCenterWithDescription!: boolean;
  @Prop({ type: Boolean, default: false }) fontWeightSemiBold!: boolean;

  mounted() {
    this.addPlugin({
      id: 'render-text-center',
      beforeDraw: () => {
        const minFont = 1.25;
        const offsetWithDescription = this.textCenterWithDescription ? 7 : 0;
        const minY = 70 * (3 / 5);
        const chart = this.$data._chart;
        const width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#333333';
        const texts = this.textCenter.split('/');
        const lineheight = 25;
        texts.forEach((text, index) => {
          ctx.font = `${this.fontWeightSemiBold ? '600' : '500'} ${Math.max(
            minFont,
            parseFloat(fontSize) / (index + 1)
          )}em sans-serif`;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = Math.max(minY, height / 2) - offsetWithDescription;
          ctx.fillText(text, textX, textY + index * lineheight);
        });
        ctx.save();
      }
    });
    this.renderChart(this.chartData, this.options);
  }

  get isAppleProduct(): boolean {
    return window.navigator.userAgent.includes('Safari');
  }
}
