<template>
  <v-scroller style="min-height: calc(100vh - 64px);">
    <main>
      <section class="px-8 flex-1 p-4 pb-10">
        <!-- <base-collapse v-if="getArchives.length" title="Archive Boards"> -->
        <div v-if="getArchives.length">
          <div class="sm:mx-4 sm:my-2 items-end flex">
            <span class="archive-title ">{{ $t('archive.name') }}</span>
            <span class="total text-gray-600 text-sm ml-2">
              {{ $t('archive.total') }} {{ getArchives.length }}
              {{ $t('archive.board') }}</span
            >
          </div>
          <board-list
            v-if="getArchives.length"
            :is-archive="true"
            :boards="getArchives"
            @update="fetchArchives"
          />
        </div>
        <div v-else class="flex justify-center align-middle">
          <div class="px-8 py-32">
            <div class="flex justify-center">
              <logo-archived-not-found class />
            </div>

            <div>
              <div class="mt-4 mb-2 text-archive-name font-medium">
                {{ $t('archive.no.name') }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </v-scroller>
</template>

<script>
import BoardList from '@/components/board/board-list';
import LogoArchivedNotFound from '@/components/icons/LogoArchivedNotFound';
import { createNamespacedHelpers } from 'vuex';
import VScroller from '@/components/scroller';

const { mapActions, mapGetters } = createNamespacedHelpers('boards');

export default {
  components: {
    BoardList,
    LogoArchivedNotFound,
    VScroller
  },
  name: 'Archive-Boards',
  mounted() {
    this.fetchArchives();
  },
  computed: {
    ...mapGetters(['getArchives'])
  },

  methods: {
    ...mapActions(['fetchArchives'])
  }
};
</script>

<style>
.archive-title {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  display: flex;
}
.text-archive-name {
  @apply text-center;
  font-size: 20px;
}
.text-title {
  @apply text-center text-gray-600 text-sm;
  width: 400px;
}
</style>
