<template>
  <div>
    <modal-task
      :format="2"
      :share-link-id="shareLinkId"
      disabled
      comment-disabled
      @closed="onTaskClosed"
    />
    <router-view />
  </div>
</template>

<script>
import ModalTask from '@/components/modal/modal-task';
import { mapGetters } from 'vuex';
export default {
  components: {
    ModalTask
  },
  computed: {
    ...mapGetters('auth', ['isUser']),
    shareLinkId() {
      return this.$route.params.linkId;
    }
  },
  methods: {
    onTaskClosed() {
      if (this.isUser)
        this.$router.push({
          name: 'Boards-List'
        });
      else this.$router.push({ name: 'Landing' });
    }
  }
};
</script>

<style></style>
