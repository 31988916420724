import Vue from 'vue';

export default Vue.extend({
  methods: {
    parseTextToLink(textOrigin: string) {
      const textSplitTag = textOrigin.split('</p>');

      const linkRegex = /<p>((http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9ก-๙:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9ก-๙@:%_.~#?&//=]*))<\/p>/gi;
      for (let i = 0; i < textSplitTag.length; i++) {
        const isTagA = textSplitTag[i].includes('</a>');
        if (isTagA) {
          const remove = textSplitTag[i].replace('</a>', '');
          textSplitTag[i] = `${decodeURI(remove)}</a></p>`;
        } else if (textSplitTag[i] === '') {
          delete textSplitTag[i];
        } else {
          textSplitTag[i] = `${textSplitTag[i]}</p>`;
        }
      }

      const parsedText = textSplitTag
        .join('')
        .replace(linkRegex, (_, $1) => `<p><a href="${$1.startsWith('http') ? $1 : `http://${$1}`}" target"_blank">${$1}</a></p>`);
      return parsedText;
    }
  }
});
