<template>
  <div>
    <div class="flex">
      <div>
        <base-icon viewBox="1 2 15 15" :width="13" :height="13">
          <icon-short-name />
        </base-icon>
      </div>
      <p class="ml-2">
        {{ $t('create.new_board.shortName') }}
      </p>
      <base-popup multiline size="is--medium">
        <base-icon class="pointer ml-1 p-1 justify-self-center" icon-size="18">
          <icon-alert-circle />
          />
        </base-icon>
        <div slot="content" class="text-left">
          <span>
            {{ $t('boardSetting.tooltip.shortName') }}
          </span>
        </div>
      </base-popup>
    </div>
    <div class="mt-3">
      <form @submit.prevent="save">
        <span>
          <div v-if="isAdmin">
            <base-input-text-uppercase
              v-model="shortName"
              @focus="isFocusShortName = true"
            />
          </div>
          <base-input-text
            v-else
            :disabled="true"
            inner-class="base-input--gray"
            :value="value"
          />
        </span>
        <div v-if="isFocusShortName" class="flex justify-end mt-2">
          <base-button
            wide="px-8 py-2"
            color="white"
            type="button"
            class="no-shadown"
            @click="cancel"
            >{{ $t('boardSetting.setting.cancel') }}</base-button
          >
          <base-button
            wide="px-8 py-2"
            color="brand"
            type="submit"
            :disabled="this.shortName == value"
            >{{ $t('boardSetting.setting.save') }}</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import IconShortName from '@/components/icons/IconShortName.vue';
import IconAlertCircle from '@/components/icons/IconAlertCircle';

export default {
  props: {
    isAdmin: Boolean,
    value: String
  },
  data() {
    return {
      shortName: '',
      isFocusShortName: false
    };
  },
  components: {
    IconShortName,
    IconAlertCircle
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.shortName !== val) this.shortName = val;
      }
    }
  },
  methods: {
    cancel() {
      this.shortName = this.value;
      this.isFocusShortName = false;
    },
    save() {
      this.isFocusShortName = false;
      this.$emit('save', this.shortName);
    }
  }
};
</script>

<style lang="scss" scoped></style>
