




















import { Vue, Component, Prop, Emit, Ref } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class BaseInputTextGrowingLine extends Vue {
  @Prop({ default: 'text-lg' }) size!: string;
  @Prop({ type: Boolean }) borderless: boolean | undefined;
  @Prop() dull: boolean | undefined;
  @Prop() customClass: string | undefined;
  @Prop({ default: false, type: Boolean }) newlineOnEnter: boolean | undefined;
  @Prop() value: string | undefined;
  @Prop() styles: object | undefined;

  windowWidth = window.innerWidth;
  rows = 1;
  isEditing = '';

  @Ref() textareaInput!: HTMLTextAreaElement & {
    focus(): void;
    blur(): void;
  };

  keydownEnter(ev: KeyboardEvent) {
    if (!this.newlineOnEnter) ev.preventDefault();
  }

  @Emit()
  input(ev: InputEvent) {
    return (ev.target as HTMLTextAreaElement).value;
  }

  @Emit()
  focus() {
    this.isEditing = 'is-editing';
  }

  @Emit()
  submit() {
    if (!this.newlineOnEnter) this.textareaInput.blur();
  }

  @Emit()
  blur() {
    this.isEditing = '';
  }
}
