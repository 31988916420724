<template>
  <v-popover
    :open.sync="open"
    :auto-hide="false"
    class="board-menu"
    placement="bottom-start"
    popover-arrow-class="no-arrow"
  >
    <base-button
      data-testid="option-board"
      color="white"
      wide="p-1"
      icon="dots-vertical"
      icon-size="20"
      shadowless
      rounded="sm"
      class="align-middle"
      borderless
    />
    <template slot="popover">
      <div
        v-if="isUserBoardAdmin(board) && !isArchive"
        class="board-menu__item flex items-center justify-between"
        data-testid="toggle-edit-board"
        @click="toggleEditModal(board)"
      >
        <div class="flex items-center">
          <base-icon size="13.5" icon="adjust-settings" />
          <span>{{ $t('board.edit') }}</span>
        </div>
      </div>
      <v-popover
        v-if="isUserBoardAdmin(board) && !isArchive"
        class="flex flex-col"
        placement="right-start"
        popover-arrow-class="no-arrow"
        @update:open="colorOpen = $event"
      >
        <div
          class="board-menu__item flex items-center justify-between"
          data-testid="set-color-board"
        >
          <div class="flex items-center">
            <div
              class="rounded mr-2"
              style="width:14px; height:14px"
              :style="getBoardBackgroundColor(board)"
            />
            <span>{{ $t('dashboard.board.set') }}</span>
          </div>
          <fa-icon size="sm" icon="chevron-right" />
        </div>
        <div slot="popover" :style="{ 'max-width': `${5 * 28}px` }">
          <select-board-colors
            :data-testid="`choose-color-board-${getBoardColor(board)}`"
            :value="getBoardColor(board)"
            size="small"
            padding="p-1"
            @input="setBoardColor({ color: $event, board })"
          />
        </div>
      </v-popover>
      <div
        v-if="isUserBoardAdmin(board) && !isArchive"
        class="board-menu__item flex items-center justify-between"
        data-testid="toggle-copy-board"
        @click="toggleCopyBoardModal(board)"
      >
        <div class="flex items-center">
          <base-icon width="18" height="18">
            <icon-copy-board />
          </base-icon>
          <span>{{ $t('board.copy') }}</span>
        </div>
      </div>
      <div
        v-for="{ label, action, classes } in confirmMenus"
        :key="label"
        v-close-popover="label != $t('team.change')"
        class="board-menu__item"
        :class="classes"
        @click="action"
      >
        <v-popover
          v-if="label == $t('team.change')"
          class="flex flex-col "
          placement="right-start"
          popover-arrow-class="no-arrow"
        >
          <div
            class="flex items-center py-2 px-4"
            data-testid="change-team-btn"
          >
            <p class="rounded-md mr-2">
              <base-icon width="14" height="10">
                <icon-team-change />
              </base-icon>
            </p>
            {{ label }}
          </div>
          <div slot="popover">
            <v-scroller class="scroll-box" @bottom="scrollBottom">
              <base-dropdown-item
                v-for="(team, i) in teams"
                :key="`team-${i}`"
                v-close-popover="true"
                class="flex"
                @click.prevent="changeTeam(team)"
              >
                <div class="w-6 mr-2">
                  <base-icon
                    v-if="
                      team.id == board.teamID ||
                        (team.id == 0 && board.teamID == undefined)
                    "
                    size="16"
                    class="align-text-top w-full"
                  >
                    <icon-check />
                  </base-icon>
                </div>
                <div class="flex-1 truncate">
                  {{ team.name }}
                </div></base-dropdown-item
              >
            </v-scroller>
          </div>
        </v-popover>

        <div v-else class="flex items-center" :data-testid="`${label}-btn`">
          <p class="rounded-md mr-2 grid">
            <base-icon width="14" height="14">
              <icon-trash-board-list
                v-if="label === 'Delete Board' || label === 'ลบบอร์ด'"
              />
              <icon-archive-board-list
                v-if="label === 'Archive Board' || label === 'เก็บบอร์ด'"
              />
              <icon-list-menu-leave
                v-if="label === 'Leave Board' || label === 'ออกจากบอร์ด'"
              />
              <icon-restore v-if="label === 'Restore' || label === 'กู้คืน'" />
            </base-icon>
          </p>
          {{ label }}
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import { mapGetters } from 'vuex';
import BoardUtils from '@/mixins/BoardUtils';
import SelectBoardColors from '@/components/select/select-board-colors';
import BoardDialogConfirm from '@/components/board/board-dialog-confirm';
import IconTrashBoardList from '@/components/icons/IconTrashBoardList.vue';
import IconArchiveBoardList from '@/components/icons/IconArchiveBoardList.vue';
import IconListMenuLeave from '@/components/icons/IconListMenuLeave.vue';
import IconRestore from '@/components/icons/IconRestore.vue';
import IconTeamChange from '@/components/icons/IconTeamChange.vue';
import IconCheck from '@/components/icons/IconCheck';
import IconCopyBoard from '@/components/icons/IconCopyBoard';
import VScroller from '@/components/scroller';
import FormEditBoard, { board } from '@/components/forms/form-edit-board.vue';
import ModalBoardCopy from '@/components/modal/modal-board-copy.vue';

export default {
  components: {
    SelectBoardColors,
    IconTrashBoardList,
    IconArchiveBoardList,
    IconListMenuLeave,
    IconRestore,
    IconTeamChange,
    IconCheck,
    VScroller,
    IconCopyBoard
  },
  mixins: [BoardUtils],
  props: {
    board: {
      type: Object,
      required: true
    },
    isArchive: Boolean
  },
  data() {
    return {
      open: false,
      colorOpen: false
    };
  },
  computed: {
    ...mapGetters({
      nextPage: 'teams/getNextPage'
    }),
    teams() {
      return [
        { name: 'No Workspace', id: 0 },
        ...this.$store.getters['teams/getTeams']
      ];
    },
    confirmMenus() {
      const { currentMember, name } = this.board;
      const deleting = this.createConfirm({
        label: this.$t('dashboard.board.delete'),
        classes: 'text-red-600',
        action: {
          title: this.$t('dashboard.board.delete'),
          description: this.$t('dashboard.board.alert.delete'),
          button: this.$t('dashboard.button.delete'),
          color: 'text-red-600',
          confirmAction: 'boards/deleteBoard',
          confirmData: this.board.id
        }
      });
      const restoringOrArchiving = this.isArchive
        ? this.createConfirm({
            label: this.$t('dashboard.board.restore'),
            action: {
              title: this.$t('dashboard.board.restore'),
              description: this.$t('dashboard.board.alert.restore'),
              button: this.$t('dashboard.button.restore'),
              confirmAction: 'boards/restoreBoard',
              confirmData: this.board.id
            }
          })
        : this.createConfirm({
            label: this.$t('dashboard.board.archive'),
            action: {
              title: this.$t('dashboard.board.archive'),
              description: this.$t('dashboard.board.alert.archive'),
              button: this.$t('dashboard.button.archive'),
              confirmAction: 'boards/archiveBoard',
              confirmData: this.board.id
            }
          });

      const leaving = this.createConfirm({
        label: this.$t('dashboard.board.leave'),
        classes: {
          'text-red-600': true,
          hidden:
            this.board.totalActiveAdmins == 1 &&
            this.isUserBoardAdmin(this.board) &&
            ['Leave Board', 'ออกจากบอร์ด'].includes(
              this.$t('dashboard.board.leave')
            )
        },
        action: {
          title: this.$t('dashboard.board.leave'),
          description: this.$t('dashboard.board.alert.leave1')
            .concat(` <strong>${name}</strong>?<br/>`)
            .concat(this.$t('dashboard.board.alert.leave2')),
          button: this.$t('dashboard.button.leave'),
          confirmAction: 'members/kickMemberBoard',
          confirmData: { boardId: this.board.id, memberId: currentMember?.id },
          confirmCallback: () => {
            this.$store.dispatch('boards/fetchBoards');
            this.$store.dispatch('boards/fetchArchives', true);
          }
        }
      });
      const changeTeam = {
        label: this.$t('team.change'),
        classes: 'default-padding',
        action: function() {
          console.log('change team');
        }
      };

      if (this.isUserBoardAdmin(this.board)) {
        return [changeTeam, restoringOrArchiving, leaving, deleting];
      } else {
        return [leaving];
      }
    },

    modalConfig() {
      return {
        height: 'auto',
        maxWidth: 440,
        adaptive: true
      };
    }
  },

  methods: {
    toggleEditModal(board) {
      this.$modal.show(
        FormEditBoard,
        { board },
        {
          scrollable: true,
          name: 'edit-board',
          clickToClose: false
        }
      );
      this.open = false;
    },
    toggleAutoHide() {
      this.autoHide = !this.autoHide;
    },
    setBoardColor({ color, board }) {
      this.$store.dispatch('boards/editBoard', {
        id: board.id,
        backgroundCover: color
      });
    },
    showModal(data) {
      this.$modal.show(BoardDialogConfirm, data, this.modalConfig);
    },
    createConfirm({
      label,
      classes,
      action: {
        title,
        description,
        button,
        confirmAction,
        confirmData,
        color,
        confirmCallback
      }
    }) {
      return {
        label,
        classes,
        action: () => {
          this.showModal({
            boardId: this.board.id,
            title,
            description,
            button,
            color,
            confirmAction,
            confirmData,
            confirmCallback
          });
        }
      };
    },
    async changeTeam(team) {
      await this.$store.dispatch('boards/editBoard', {
        ...this.board,
        teamID: team.id
      });

      this.open = false;
      await this.$store.dispatch('boards/fetchBoards');
    },
    scrollBottom() {
      if (this.nextPage) {
        this.$store.dispatch('teams/fetchTeamsNext', this.nextPage);
      }
    },
    toggleCopyBoardModal(board) {
      this.$modal.show(ModalBoardCopy, { board });
      this.open = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.board-menu__item {
  @apply py-2 px-4 cursor-pointer capitalize;
  min-width: 10rem;

  &:hover {
    @apply bg-gray-300;
  }
}
.default-padding {
  padding: 0px !important;
}
.scroll-box {
  max-height: 240px;
  max-width: 240px;
}
</style>
