import Vue from 'vue';

export default Vue.extend({
  methods: {
    getNumberToStringWithUnit(value: number, unit = ''): string {
      return `${value}${this.countUnits(unit, value)}`;
    },
    countUnits(unit = '', value: number) {
      return `${unit}${unit && value > 1 ? this.$t('admin.s') : ''}`;
    }
  }
});
