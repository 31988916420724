import { CommonObject } from './types';

export * from './list-utils';

function arrayToObjectByID<T extends CommonObject>(
  arr: T[]
): { [id: string]: T } {
  return arr.reduce((p, c) => ({ ...p, [c.id]: c }), {} as { [id: string]: T });
}

function objectToArray<T extends CommonObject>(obj: { [id: string]: T }): T[] {
  return Object.keys(obj).map(e => obj[e]);
}

function updateArrays<T extends CommonObject>(origin: T[], updates: T[]): T[] {
  const objArr = arrayToObjectByID(origin);
  updates.forEach(e => (objArr[e.id] = e));
  return objectToArray(objArr);
}

interface GetBottomPositionOfGroup {
  <T extends { position: number }>(
    key: keyof T,
    value: T[keyof T],
    group: T[]
  ): number;
}

const getBottomPositionOfGroup: GetBottomPositionOfGroup = (
  key,
  value,
  group
) => {
  const matched = group.filter(t => t[key] === value);
  if (!matched.length) return 1;
  const last = matched[matched.length - 1];
  return last.position;
};

function isIncluded<T extends CommonObject>(
  arr: T[],
  target: T | number
): boolean {
  return arr.some(
    item => item.id === (typeof target === 'number' ? target : target.id)
  );
}

const getById = <T extends CommonObject>(items: T[], id: number) =>
  items.find(item => item.id === id) || null;

export {
  arrayToObjectByID,
  objectToArray,
  updateArrays,
  getBottomPositionOfGroup,
  isIncluded,
  getById
};
