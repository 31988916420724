
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Bar, mixins } from 'vue-chartjs';
import { ChartTooltipCallback } from 'chart.js';

export interface TooltipBody {
  (item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[];
}

const tooltipCallbackProp = {
  type: Object,
  default: () => ({})
};

@Component({
  extends: Bar
})
export default class ChartBarStacked extends Mixins(Bar, mixins.reactiveProp) {
  @Prop(tooltipCallbackProp) tooltipCallback!: ChartTooltipCallback;
  @Prop({ type: Number }) aspectRatio?: number;
  @Prop({ type: Object, default: null }) options?: Chart.ChartOptions;
  mounted() {
    this.completeRenderChart();
  }
  @Watch('$i18n.locale')
  onLocaleChanged() {
    this.completeRenderChart();
  }

  completeRenderChart() {
    this.renderChart(this.chartData, this.options);
  }
}
