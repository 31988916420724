var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dropdown',_vm._b({staticClass:"base-select",attrs:{"disabled":_vm.disabled,"position":_vm.position,"width":_vm.fullWidth ? 'full' : 'small'}},'base-dropdown',_vm.$attrs,false),[(!_vm.isDotButton)?_c('div',{staticClass:"px-2 py-2 base-select__toggle",class:_vm.toggleClass,attrs:{"slot":"toggle"},slot:"toggle"},[(_vm.showPlaceholder)?_c('span',{staticClass:"px-2 text-sm",class:_vm.placeholderClasses},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_c('div',{staticClass:"base-select__active flex items-center px-2",class:{ 'mr-2': !_vm.disabled }},[(_vm.clearable)?_c('button',{staticClass:"hover:text-black text-gray-500 rounded-full",class:{ 'mr-2': !_vm.disabled },on:{"click":function($event){return _vm.$emit('change')}}},[_c('fa-icon',{attrs:{"icon":['far', 'times-circle'],"size":"sm"}})],1):_vm._e(),_c('div',{staticClass:"text-sm"},[_vm._t("toggle",function(){return [_vm._v(" "+_vm._s(_vm.active.label)+" ")]},{"active":_vm.active})],2)]),_c('div',{staticClass:"toggle-icon inline-flex has-centered-item px-2"},[_c('base-icon',{attrs:{"name":"select-toggle","size":"16"}},[_c('icon-chevron-down')],1)],1)]):_c('div',{attrs:{"slot":"toggle"},slot:"toggle"},[_c('base-button',{staticClass:"align-middle",attrs:{"color":"white","wide":"board","icon":"dots-vertical","icon-size":"20","shadowless":"","rounded":"sm"}})],1),_vm._l((_vm.options),function(option){return _c('base-dropdown-item',{key:option.value,class:[
      _vm.optionClasses,
      option.class,
      { 'base-select__option--active': _vm.active.value === option.value } ],attrs:{"active":!_vm.customItem && _vm.active.value === option.value && !_vm.check},on:{"click":function($event){return _vm.$emit('change', option.value)}}},[_vm._t("label",function(){return [(!_vm.check)?_c('div',[_vm._v(" "+_vm._s(option.label)+" ")]):_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-6 mr-2"},[(_vm.active.value === option.value)?_c('base-icon',{staticClass:"align-text-top w-full",attrs:{"size":"16"}},[_c('icon-check')],1):_vm._e()],1),_c('div',{staticClass:"flex-1 truncate"},[_vm._v(" "+_vm._s(option.label)+" ")])])]},{"props":{
        active: _vm.active.value === option.value,
        label: option.label,
        value: option.value,
      }})],2)}),(_vm.hasNoItem)?_c('base-dropdown-item',{staticClass:"text-gray-caption text-center",attrs:{"hoverable":false}},[_vm._t("empty",function(){return [_vm._v(" Nothing to select ")]})],2):_vm._e(),_vm._t("bottom")],2)}
var staticRenderFns = []

export { render, staticRenderFns }