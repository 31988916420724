<template>
  <div class="flex items-center mb-2">
    <base-icon v-if="icon" size="14" class="mr-1">
      <component :is="Icon" />
    </base-icon>
    <fa-icon v-if="faIcon" v-bind="faIcon" />
    <span class="mr-3 font-medium">
      <slot />
    </span>
    <base-button
      v-if="!disabled && canAdd"
      icon="plus"
      icon-size="16"
      color="light"
      wide="p-2"
      shadowless
      @click="$emit('click')"
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    },
    faIcon: {
      type: Object,
      default: null
    },
    disabled: Boolean
  },
  data() {
    return {
      Icon: null
    };
  },
  mounted() {
    if (this.icon) {
      this.Icon = () => import(`@/components/icons/${this.capitalizeIcon}`);
    }
  },
  computed: {
    capitalizeIcon() {
      return `Icon${this.icon[0]?.toUpperCase()}${this.icon.slice(1)}`;
    },
    canAdd() {
      return this.$listeners.click !== undefined;
    }
  }
};
</script>

<style></style>
