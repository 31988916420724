import { CommonObject } from './types';

export function getNewListWithUpdate<T extends CommonObject>(
  list: T[],
  update: T
): T[] {
  return list.map(item => (item.id === update.id ? update : item));
}

export function getNewListWithUpdatedItem<T extends CommonObject>(
  list: T[],
  update: Partial<T>
): T[] {
  const isSame = (item: T) => item.id === update.id;
  const getUpdated = (item: T) => ({ ...item, ...update });
  return list.map((item: T) => (isSame(item) ? getUpdated(item) : item));
}

export function getRemovedItemArray<T extends CommonObject>(
  arr: T[],
  id: number
): T[] {
  return arr.filter(e => e.id !== id);
}
