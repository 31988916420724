import { Component, Ref } from 'vue-property-decorator';
import moment, { unitOfTime } from 'moment';
import { ChartTooltipCallback, ChartTooltipModel } from 'chart.js';
import { Board } from '@/types/Board';
import { Task } from '@/types/Task';
import { TimeLog } from '@/types/TimeLog';
import NumberUtils from '@/mixins/NumberUtilsMixin';
import ChartBarStacked from './ChartBarStacked';

interface GetBarSegment {
  (tooltipModel: ChartTooltipModel): {
    top: number;
    left: number;
    width: number;
    height: number;
  };
}

const splitBy2 = (value: number) => value / 2;
const toPx = (value: number) => `${value}px`;
const toTopLeft = (top: number, left: number) => ({
  top: toPx(top),
  left: toPx(left)
});

@Component
export default class TimelogChartMixin extends NumberUtils {
  // * It is important to add ref="barChart" to used chart component
  get chartBar() {
    return this.$refs.barChart as ChartBarStacked;
  }
  chartTimelogs: TimeLog[] = [];
  get timelogChartOptions(): Chart.ChartOptions {
    return {
      responsive: true,
      legend: {
        display: false
      },
      elements: {
        rectangle: {
          borderWidth: 1,
          borderColor: 'white'
        }
      },
      tooltips: {
        enabled: false,
        cornerRadius: 4,
        bodySpacing: 4,
        titleMarginBottom: 8,
        xPadding: 12,
        yPadding: 12,
        callbacks: this.tooltipCallbacks,
        custom: this.tooltipCustom
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              display: true,
              beginAtZero: true,
              callback: val => `${val} ${this.$t('timelog.time.h')}`
            }
          }
        ]
      }
    };
  }

  get isGreaterThanZeroTimelogs() {
    return this.chartTimelogs.filter(timelog =>
      this.isGreaterThanZero(timelog.timeSpent)
    );
  }

  get tooltipCallbacks(): ChartTooltipCallback {
    const mFormat = (date: string) =>
      moment(date)
        .locale(`${this.$t('notifications.language')}`)
        .format('HH:mm');
    const getTimelogByIndex = (item: Chart.ChartTooltipItem[]) => {
      const timelogs = this.isGreaterThanZeroTimelogs;
      const index = item[0].datasetIndex ?? -1;
      return timelogs[index];
    };
    return {
      label: () => '',
      title: item => getTimelogByIndex(item)?.description || '',
      beforeBody: item => {
        const { datasetIndex } = item[0];
        if (datasetIndex == null) return '';
        const timelog = getTimelogByIndex(item);
        const texts = [
          `${this.$t('timelog.barChart.board')}<split>${this.displayBoardTask(
            timelog
          )}`,
          `${this.$t('timelog.barChart.start')}<split>${mFormat(
            timelog.startAt
          )}`,
          `${this.$t('timelog.barChart.end')}<split>${mFormat(timelog.endAt)}`,
          `${this.$t('timelog.barChart.time')}<split>${this.getTimeSpent(
            timelog.timeSpent
          )}`
        ];
        return texts;
      }
    };
  }

  tooltipCustom = (tooltipModel: ChartTooltipModel) => {
    /* FUNCTION TO GET BAR PROPS */
    const getBARSegment: GetBarSegment = () => {
      const { width, dataPoints } = tooltipModel;
      const chart = this.chartBar.$data._chart as Chart;
      const canvas = chart.canvas as HTMLCanvasElement;
      const datasetIndex = dataPoints[0].datasetIndex as number;
      const datasetMeta = chart.getDatasetMeta(datasetIndex);
      const bar = datasetMeta.data[dataPoints[0].index as number]._model;
      const canvasPosition = canvas.getBoundingClientRect();
      const paddingLeft = parseFloat(getComputedStyle(canvas).paddingLeft);
      const paddingTop = parseFloat(getComputedStyle(canvas).paddingTop);
      const scrollLeft = document.body.scrollLeft;
      const scrollTop = document.body.scrollTop;
      const height = bar.base - bar.y; // bar.base is the bottom position while bar.y is the top position start counting from the top of the chart

      return {
        width,
        height,
        top: bar.y + canvasPosition.top + paddingTop + scrollTop,
        left:
          bar.x -
          splitBy2(width) +
          canvasPosition.left +
          paddingLeft +
          scrollLeft
      };
    };
    /*** jQuery IS USED FOR SIMPLICITY ***/
    function selectEl(selector: string) {
      return document.querySelector<HTMLElement>(selector);
    }
    function createEl(elem: string, attrs?: { [key: string]: string }) {
      const newEl = document.createElement(elem);
      if (attrs) {
        for (const prop in attrs) {
          newEl.setAttribute(prop, attrs[prop]);
          document.body.appendChild(newEl);
        }
      }
      return newEl;
    }
    function hideEl(el: HTMLElement) {
      el.style.display = 'none';
    }
    function css(el: HTMLElement, styles: Partial<CSSStyleDeclaration>) {
      for (const prop in styles) {
        el.style[prop] = styles[prop] as string;
      }
    }
    function outerHeight(el: HTMLElement) {
      let height = el.offsetHeight;
      const style = getComputedStyle(el);

      height += parseInt(style.marginTop) + parseInt(style.marginBottom);
      return height;
    }
    function outerWidth(el: HTMLElement) {
      let width = el.offsetWidth;
      const style = getComputedStyle(el);

      width += parseInt(style.marginTop) + parseInt(style.marginBottom);
      return width;
    }

    /* TOOLTIP & CARET ELEMENT */
    /* OR: CREATE TOOLTIP & CARET ELEMENT AT FIRST RENDER */
    const tooltip = selectEl('#tooltip') || createEl('div', { id: 'tooltip' });
    const tooltipCaret =
      selectEl('#tooltip-caret') || createEl('div', { id: 'tooltip-caret' });

    /* HIDE IF NO TOOLTIP */
    if (!tooltipModel.opacity) {
      hideEl(tooltip);
      hideEl(tooltipCaret);
      return;
    }

    /* GET BAR PROPS (width, height, top, left) */
    const {
      width: barWidth,
      height: barHeight,
      top: barTop,
      left: barLeft
    } = getBARSegment(tooltipModel);

    /* SET STYLE FOR TOOLTIP 
                (these can also be set in separate css file) */
    tooltip.classList.add('shadow');
    css(tooltip, {
      display: 'inline-block',
      position: 'absolute',
      color: 'rgba(255, 255, 255, 1)',
      background: 'rgba(0, 0, 0, 0.7)',
      padding: '5px',
      font: '12px Arial',
      borderRadius: '3px',
      whiteSpace: 'nowrap',
      pointerEvents: 'none',
      zIndex: '2'
    });

    /* SET STYLE FOR TOOLTIP CARET 
                (these can also be set in separate css file) */
    css(tooltipCaret, {
      display: 'block',
      position: 'absolute',
      width: '0px',
      pointerEvents: 'none',
      borderStyle: 'solid',
      borderWidth: '8px 10px 8px 0',
      borderColor: 'transparent rgba(0, 0, 0, 0.7) transparent transparent',
      zIndex: '2'
    });

    /* ADD TABLE CONTENT IN TOOLTIP */
    const titleText = tooltipModel.title.join('');
    tooltip.innerHTML = `
      <div class="max-w-sm p-2">
        <p class="text-base mb-2">${titleText}</p>
        <table>
          <tbody class="tooltip__table-tbody">
          </tbody>
        </table>
      </div>
      `;

    // APPEND DATA TO TABLE
    const tbody = tooltip.querySelector(
      '.tooltip__table-tbody'
    ) as HTMLTableElement;
    const addingClasses = ['pl-4', 'opacity-75', 'whitespace-normal'];
    tooltipModel.beforeBody.forEach(bodyString => {
      const tr = tbody.appendChild(createEl('tr'));

      // make title<split>content => title content
      bodyString.split('<split>').forEach((text, i) => {
        const td = createEl('td');
        if (i !== 0) td.classList.add(...addingClasses);
        else td.classList.add('align-top');
        td.innerText = text;
        tr.appendChild(td);
      });
    });

    /* POSITION TOOLTIP & CARET
              (position should be set after tooltip & caret is rendered) */
    const centerX = barLeft + splitBy2(barWidth);
    const centerY = barTop + splitBy2(barHeight);
    const { scrollY, innerWidth: windowWidth } = window;
    const tooltipLeft = centerX + outerWidth(tooltipCaret);

    const tooltipPosition = toTopLeft(
      centerY - splitBy2(outerHeight(tooltip)) + scrollY,
      tooltipLeft
    );
    const tooltipCaretPosition = toTopLeft(
      centerY - splitBy2(outerHeight(tooltipCaret)) + scrollY,
      centerX
    );

    css(tooltip, tooltipPosition);
    css(tooltipCaret, tooltipCaretPosition);

    const tooltipRect = tooltip.getBoundingClientRect();

    // Turn tooltip over when it overlap window
    if (windowWidth < tooltipRect.right) {
      css(tooltip, {
        left: toPx(centerX - outerWidth(tooltip) - outerWidth(tooltipCaret))
      });
      css(tooltipCaret, {
        left: toPx(centerX - outerWidth(tooltipCaret)),
        borderWidth: '8px 0px 8px 10px',
        borderColor: 'transparent transparent transparent rgba(0, 0, 0, 0.7)'
      });
    }
  };

  createOnClick = (callback: <T>(t: T) => void) => (ev?: MouseEvent) => {
    const element = this.chartBar.$data._chart.getElementAtEvent(ev);
    const { _index: col, _datasetIndex: bar, _view: view } = element[0] || {};
    return callback({ col, bar, view });
  };
  // * calculation
  isGreaterThanZero(number: number) {
    return number > 0;
  }
  getTimeUnits(minute: number) {
    const HOUR_MINS = 60;
    const DAY_HOURS = 24 * HOUR_MINS;
    const get2Decimal = (val: number) => parseFloat(val.toFixed(2));
    const days = get2Decimal(minute / DAY_HOURS);
    const hrs = get2Decimal((minute % DAY_HOURS) / HOUR_MINS);
    const mins = get2Decimal(minute % 60);
    return { day: days, hour: hrs, minute: mins };
  }
  displayBoardTask({ board, task }: { board: Board; task: Task }) {
    return (
      [board, task]
        .filter(e => e)
        .map(e => e.name)
        .join(' - ') || '-'
    );
  }
  getTimeSpent(minutes: number) {
    const { day, hour, minute } = this.getTimeUnits(minutes);
    const units = [
      { val: day, unit: `${this.$t('timelog.time.day')}` },
      { val: hour, unit: `${this.$t('timelog.time.hour')}` },
      { val: minute, unit: `${this.$t('timelog.time.min')}` }
    ];
    const minuteUnit = units[2];
    const displayUnits = units
      .map(e => ({ ...e, val: Math.floor(e.val) }))
      .filter(e => this.isGreaterThanZero(e.val));
    if (!displayUnits.length) displayUnits.push(minuteUnit);
    return displayUnits
      .map(e => this.getNumberToStringWithUnit(e.val, e.unit))
      .join(' ');
  }
  getTimeDiff(
    { start, end }: { start: moment.MomentInput; end: moment.MomentInput },
    unit: unitOfTime.Base
  ) {
    return moment(end)
      .locale(`${this.$t('notifications.language')}`)
      .diff(
        moment(start).locale(`${this.$t('notifications.language')}`),
        unit,
        true
      );
  }
  oneDayChartData(timelogs: TimeLog[]) {
    const getYLabel = (end: moment.MomentInput, start: moment.MomentInput) => {
      const diffInMinutes = this.getTimeDiff({ start, end }, 'minutes');
      const { hour } = this.getTimeUnits(diffInMinutes);
      return hour;
    };
    const datasets = timelogs
      .filter(timelog => this.isGreaterThanZero(timelog.timeSpent))
      .map(tl => ({
        backgroundColor: tl.color,
        barThickness: 100,
        data: [
          {
            y: getYLabel(tl.endAt, tl.startAt),
            x: moment(tl.startAt)
              .locale(`${this.$t('notifications.language')}`)
              .format('LL')
          }
        ]
      }));
    return { datasets };
  }
}
