




















import { Vue, Component, Prop, Emit, Ref } from 'vue-property-decorator';
import WindowResize from '@/mixins/WindowResize';

@Component({
  inheritAttrs: false,
  mixins: [WindowResize]
})
export default class BaseTextEditable extends Vue {
  @Prop({ default: 'text-lg' }) size!: string;
  @Prop({ type: Boolean }) borderless: boolean | undefined;
  @Prop({ type: Number, default: 0 }) minHeight!: number;
  @Prop({ type: Number, default: null }) maxHeight!: number;
  @Prop() dull: boolean | undefined;
  @Prop() customClass: string | undefined;
  @Prop({ default: false, type: Boolean }) newlineOnEnter: boolean | undefined;
  @Prop({ default: '' }) value!: string;

  windowWidth = window.innerWidth;
  rows = 1;
  isEditing = '';

  get textValue(): string {
    return this.value;
  }
  set textValue(val: string) {
    this.input(val);
  }

  @Ref() textareaInput?: HTMLTextAreaElement & {
    $el: HTMLTextAreaElement;
    focus(): void;
    blur(): void;
  };

  keydownEnter(ev: KeyboardEvent) {
    if (!this.newlineOnEnter) ev.preventDefault();
  }

  @Emit()
  input(text: string) {
    return text;
  }

  @Emit()
  focus() {
    this.isEditing = 'is-editing';
  }

  @Emit()
  submit() {
    if (!this.newlineOnEnter) this.textareaInput?.$el.blur();
  }

  @Emit()
  blur() {
    this.isEditing = '';
  }
}
