


























































































































































































import { Vue, Component, Prop, Ref, Emit, Watch } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import { Action } from 'vuex-class';

import { Tag, TagAdd, TagColor, TagEdit } from '@/types/Tag';
import { Task, TaskTag } from '@/types/Task';
import SelectColors, {
  ColorOption
} from '@/components/select/select-colors.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import { debounce } from 'lodash';

enum Steps {
  LIST,
  ADD,
  EDIT,
  REMOVE
}

type Step = keyof typeof Steps;

interface Dropdown {
  toggleDropdown(): void;
  getDropdownContentFixedPosition(): void;
}

interface TagForm {
  color: TagColor;
  name: string;
  id?: number;
}

interface MoreTag {
  tag: { id: number; name: string; isMore: true; color: '#EAECF0' };
  id: 0;
}

const getDefaultTag = (defaultName = ''): TagForm => ({
  name: defaultName,
  color: ColorOption.LIME
});

@Component({
  watch: {
    searchTextTag: {
      handler: debounce(function(this: Vue, val) {
        this.$store.commit('tags/SET_NEXT', 1);
        this.$store.dispatch('tags/fetchTags', val);
      }, 200)
    }
  },
  computed: {
    ...mapGetters('tags', {
      tags: 'getTags',
      nextPage: 'getNextPage'
    })
  },
  components: {
    IconPlus,
    SelectColors
  },
  filters: {
    checked: (tag: Tag, { tags }: Task) =>
      (tags || []).some(t => t.tagID === tag.id)
  },
  methods: mapActions({
    taskAddTag: 'tasks/addTag',
    taskRemoveTag: 'tasks/removeTag',
    createTag: 'tags/createTag',
    boardSetTag: 'tags/setTag',
    boardRemoveTag: 'tags/removeTag',
    taskSearchTag: 'tags/searchTags'
  })
})
export default class SelectTaskTags extends Vue {
  @Prop({ type: Array, default: () => [] }) value!: Tag[];
  @Prop({ type: Object, required: true }) task!: Task;
  @Prop(Boolean) noAdd!: boolean;
  @Prop({ type: Boolean, default: false }) noTags!: boolean;
  @Prop({ type: Number, default: 1 }) lines!: number;
  @Prop(String) dropdownPosition?: string;
  @Prop({ type: Number }) hiddenAt?: number;
  @Prop({ type: String, default: '33%' }) tagMaxWidth?: string;
  @Ref() dropdown!: Dropdown;
  @Ref() tagList!: HTMLDivElement;
  @Ref() tagListItems?: HTMLDivElement[];
  @Emit() async select(tag: Tag) {
    if (!this.loading) {
      this.loading = true;
      const isChecked = this.$options?.filters?.checked(tag, this.task);
      if (isChecked) await this.taskRemoveTag(tag.id);
      else await this.taskAddTag(tag.id);
    }
    this.loading = false;
    return tag;
  }
  clientWidthTag = 250;

  @Watch('tags')
  onTagsChanged() {
    if (this.noAdd) return;
    setTimeout(() => {
      this.dropdown.getDropdownContentFixedPosition();
    }, 500);
  }
  // vuex
  tags!: Tag[];
  taskAddTag!: (tagID: number) => Promise<never>;
  taskRemoveTag!: (tagID: number) => Promise<never>;
  createTag!: (tagAdd: TagAdd) => Promise<Tag>;
  boardSetTag!: (tagEdit: TagEdit) => Promise<never>;
  boardRemoveTag!: (id: number) => Promise<never>;
  taskSearchTag!: (q: string) => Promise<never>;
  // state
  searchTextTag = '';
  step: Steps = Steps.LIST;
  tagForm: TagForm = getDefaultTag();
  loading = false;
  // computed
  get searchTags(): Tag[] {
    return this.step ? [] : this.tags;
  }

  get existingTaskTags(): TaskTag[] {
    return (this.task.tags || []).filter(t => t.tag);
  }

  get taskTags(): (TaskTag | MoreTag)[] {
    if (this.noTags) return [];
    const visibleTags = this.existingTaskTags.slice(0, this.hiddenAt as number);
    return visibleTags;
  }

  get headerTitle(): string {
    switch (this.step) {
      case Steps.ADD:
        return `${this.$t('modelTask.card.tags.create')}`;
      case Steps.EDIT:
        return `${this.$t('modelTask.card.tags.change')}`;
      case Steps.REMOVE:
        return `${this.$t('modelTask.card.tags.delete')}`;
      default:
        return '';
    }
  }
  get tagItemStyle() {
    let maxWidth;
    if (this.hiddenAt) {
      const maxLineItems = Math.ceil(this.hiddenAt / this.lines);
      const minTag = Math.min(this.taskTags.length, maxLineItems);
      let maxWidthTag;
      if (minTag == 1) {
        maxWidthTag = this.clientWidthTag;
      } else if (minTag == 2) {
        maxWidthTag = this.clientWidthTag - 20;
      } else if (minTag == 3) {
        maxWidthTag = this.clientWidthTag - 30;
      } else {
        maxWidthTag = this.clientWidthTag - 60;
      }
      maxWidth = (maxWidthTag / minTag).toString().concat('px');
    }
    return {
      minWidth: '40px',
      maxWidth
    };
  }
  // methods
  backStep() {
    this.step -= this.step === 2 ? 2 : 1;
    this.searchTextTag = '';
  }

  setStep(step: Step) {
    this.step = Steps[step];
  }

  isStep(step: Step) {
    return this.step === Steps[step];
  }

  setTagForm(tag?: Tag) {
    const { name, color, id } = tag || getDefaultTag(this.searchTextTag);
    this.tagForm = { name, color, id };
    this.setStep(tag ? 'EDIT' : 'ADD');
  }

  resetInput() {
    this.setStep('LIST');
    this.tagForm = getDefaultTag();
    this.searchTextTag = '';
  }

  async onCreateTag() {
    const newTag: TagAdd = {
      ...this.tagForm,
      boardID: this.task.boardID
    };
    const tag = await this.createTag(newTag);
    this.select(tag);
    this.resetInput();
  }

  onBoardSetTag() {
    this.boardSetTag(this.tagForm as TagEdit);
    this.resetInput();
  }

  onBoardRemoveTag() {
    this.$store.dispatch('tasks/removeTagFilter', this.tagForm.id);
    this.boardRemoveTag(this.tagForm.id || 0);
    this.resetInput();
  }

  onTagListResize() {
    const tagListWidth = this.$refs.tagList as HTMLElement;
    const { clientWidth } = tagListWidth;
    this.clientWidthTag = clientWidth;
  }

  // fetch tags
  @Action('tags/fetchTags') fetchTags!: (searchTextTag: string) => Promise<Tag>;
  async fetchTagsWithLoading() {
    this.loading = true;
    await this.fetchTags(this.searchTextTag);

    this.loading = false;
  }

  // more tags feature
  get moreTagItem(): MoreTag {
    const tags = this.existingTaskTags;
    const moreTagId = Math.max(Math.max(...tags.map(e => e.tagID)) + 1, 0);
    const leftOverCount = `+${tags.length - (this.hiddenAt as number)}`;
    return {
      tag: {
        color: '#EAECF0',
        name: leftOverCount,
        id: moreTagId,
        isMore: true
      },
      id: 0
    };
  }
  get hasMoreTag() {
    const tagHidden =
      this.hiddenAt && this.existingTaskTags.length > this.hiddenAt;
    return !this.noTags && tagHidden;
  }
  mounted() {
    window.addEventListener('resize', this.onTagListResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onTagListResize);
  }
}
