import { ApiCommonFields } from './Api';

export enum ColorOptionsOrder {
  '#FFC107',
  '#FFA000',
  '#FF7043',
  '#FF7A7A',
  '#F6403D',
  '#EC407A',
  '#AB47BC',
  '#7E57C2',
  '#5C6BC0',
  '#1073B9',
  '#29B6F6',
  '#26C6DA',
  '#26A69A',
  '#66BB6A',
  '#9CCC65',
  '#B6C339',
  '#8D6E63',
  '#28587B',
  '#263238',
  '#BDBDBD'
}

export type TagColor = keyof typeof ColorOptionsOrder;

interface TagCommon {
  color: TagColor;
  name: string;
}

export interface TagEdit {
  id: number;
  name: string;
  color?: TagColor;
  boardID?: number;
}

export interface TagAdd extends TagCommon {
  boardID: number;
}

export interface Tag extends TagAdd, ApiCommonFields {
  name: string;
  isTag: boolean;
}
