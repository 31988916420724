import Vue from 'vue';

const crop = function(
  value: string,
  maxChars: number,
  trailingCharCount: number
) {
  let result = value;

  if (value.length > maxChars) {
    const mid = '...';
    const frontLastPosition = maxChars - (trailingCharCount + mid.length);
    const front = value.substr(0, frontLastPosition);
    const end = value.substr(-trailingCharCount);

    result = front + mid + end;
  }
  return result;
};

Vue.filter('crop', crop);
