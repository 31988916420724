import { Vue, Component, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { ApiPagination } from '@/types/Api';

interface QueryParams {
  [key: string]: string | number;
}
interface Deps {
  0: string;
  1: QueryParams;
  2: number;
}

@Component
export default class QueryList<T> extends Vue {
  queryList: T[] = [];
  queryLoading = false;
  queryNextPage = 1;
  queryLastPage = 1;
  queryString = '';
  queryParams: QueryParams = {};

  get boardID() {
    return (
      parseInt(this.$route.params.id, 10) ||
      this.$store.getters['boards/getActiveBoardId']
    );
  }

  get $QueryList_deps(): Deps {
    return [this.queryString, this.queryParams, this.queryNextPage];
  }

  get shouldQueryNextPage() {
    return this.queryLastPage > this.queryNextPage;
  }

  @Watch('queryString')
  onQureyStringChanged() {
    this.resetQueryPage();
  }

  // this will be replaced by consumer
  get routerQuery(): { [key: string]: string } {
    return {};
  }

  get hasRouterQuery() {
    return Object.keys(this.routerQuery).length > 0;
  }

  @Watch('$QueryList_deps', { immediate: true, deep: true })
  onFetch(val: Deps, prev?: Deps) {
    this.queryLoading = true;
    if (!prev) return this.fetchList();
    const pagination = val[2];
    const prevPagination = prev[2];
    if (pagination === prevPagination) {
      this.resetQueryPage();
    }
    this.$nextTick(() => {
      this.fetchList();
    });
    if (this.hasRouterQuery) {
      const query = {
        ...this.routerQuery,
        q: this.queryString
      };
      this.$router.replace({
        query
      });
    }
  }

  public addQueryNextPage() {
    this.queryNextPage++;
  }

  queryFetcher!: {
    (config: AxiosRequestConfig): AxiosPromise<ApiPagination<T> | T[]>;
  };

  private fetchList = debounce(async function(this: QueryList<T>) {
    const params = {
      ...this.queryParams,
      boardID: this.boardID,
      q: this.queryString,
      page: this.queryNextPage
    };
    const { data } = await this.queryFetcher({
      params
    });
    if (Array.isArray(data)) {
      this.queryList = data;
    } else {
      const { entities, pageInformation } = data;
      this.queryList = [...this.queryList, ...entities];
      this.queryLastPage = pageInformation.lastPage;
    }
    this.queryLoading = false;
  }, 500);

  resetQueryPage() {
    this.queryNextPage = 1;
    this.queryList = [];
    this.queryLastPage = 1;
  }
}
