import apis from './apis';

interface Tokens {
  accessToken: string | null | undefined;
  refreshToken: string | null | undefined;
  uuid?: string | null;
}

type TokenKeys = keyof Tokens;

const storageToApiKeys: { [e: string]: TokenKeys } = {
  access_token: 'accessToken',
  refresh_token: 'refreshToken',
  uuid: 'uuid'
};

const mapStorageToApiKeys = Object.keys(storageToApiKeys) as Array<
  keyof typeof storageToApiKeys
>;

export default {
  setTokens: (tokens: Tokens) => {
    try {
      if (!localStorage) return;
    } catch (err) {
      return;
    }

    if (tokens.accessToken) {
      apis.defaults.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }
    if (tokens.uuid) apis.defaults.headers.UUID = tokens.uuid;

    mapStorageToApiKeys.forEach(storageKey => {
      const apiKey = storageToApiKeys[storageKey];
      const apiValue = tokens[apiKey];
      if (apiValue) localStorage.setItem(storageKey as string, apiValue);
    });
  },
  getTokens: () => {
    try {
      if (!localStorage) return {};
    } catch (err) {
      return {};
    }

    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const uuid = localStorage.getItem('uuid');
    return {
      accessToken,
      refreshToken,
      uuid
    };
  },
  removeTokens: () => {
    try {
      if (!localStorage) return {};
    } catch (err) {
      return {};
    }

    mapStorageToApiKeys.forEach(storageKey =>
      localStorage.removeItem(storageKey as string)
    );
    delete apis.defaults.headers.Authorization;
    delete apis.defaults.headers.UUID;
  },
  existed(): boolean {
    const tokens = this.getTokens();
    return Boolean(tokens.uuid || tokens.accessToken);
  },
  hasAccessToken(): boolean {
    return Boolean(this.getTokens().accessToken);
  },
  hasUUID(): boolean {
    return Boolean(this.getTokens().uuid);
  }
};
