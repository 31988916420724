import Vue from 'vue';
import moment, { MomentInput, unitOfTime as UnitOfTime } from 'moment';

Vue.filter('moment_format', function(
  date?: MomentInput | string,
  format?: string
): string {
  if (!date) return '';
  return moment(date).format(format);
});

Vue.filter('moment_diff_in', function(
  dateA: MomentInput,
  unitOfTime: UnitOfTime.Diff,
  dateB: MomentInput
) {
  return moment(dateA).diff(moment(dateB), unitOfTime) + 1;
});
