var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('select-colors',{attrs:{"label":false,"colors":_vm.colorOptions},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var color = ref.color;
var active = ref.active;
var select = ref.select;
return [_c('div',{class:_vm.padding},[_c('div',{staticClass:"relative cursor-pointer",on:{"click":select}},[_c('div',{staticClass:"rounded",style:({
            'background-color': color,
            width: _vm.sizes.background,
            height: _vm.sizes.background
          })}),_c('div',{staticClass:"absolute inset-0 flex"},[_c('div',{staticClass:"flex-1 has-centered-item"},[(active)?_c('fa-icon',{attrs:{"icon":"check","color":"white","size":_vm.sizes.check}}):_vm._e()],1)])])])]}}]),model:{value:(_vm.colorValue),callback:function ($$v) {_vm.colorValue=$$v},expression:"colorValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }