<template>
  <button
    class="board-star inline-flex has-centered-item cursor-pointer"
    :class="{ 'board-star--disabled': disabled, 'hover--dark': !disabled }"
    @click.stop="handleClick"
  >
    <base-icon v-if="active" name="star" class="text-star-yellow" :size="size">
      <icon-star />
    </base-icon>
    <base-icon v-else name="star-outlined" :size="size">
      <icon-star-outlined />
    </base-icon>
  </button>
</template>

<script>
import IconStar from '@/components/icons/IconStar';
import IconStarOutlined from '@/components/icons/IconStarOutlined';
export default {
  components: {
    IconStar,
    IconStarOutlined
  },
  props: {
    active: Boolean,
    size: {
      type: [Number, String],
      default: '20'
    },
    disabled: Boolean
  },
  methods: {
    handleClick() {
      if (!this.disabled) this.$emit('star');
    }
  }
};
</script>

<style scoped>
.board-star--disabled {
  cursor: default;
}
</style>
