<template>
  <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10.5596" cy="10.5103" r="10" />
    <path
      d="M6.51506 11.281C6.2074 11.0037 5.73317 11.0282 5.45582 11.3359C5.17847 11.6436 5.20304 12.1178 5.5107 12.3951L6.51506 11.281ZM9.04359 14.5702L8.54141 15.1273C8.69584 15.2665 8.90114 15.3354 9.10828 15.3174C9.31543 15.2995 9.50583 15.1964 9.63404 15.0327L9.04359 14.5702ZM15.847 7.10033C16.1024 6.77423 16.0451 6.30283 15.719 6.04742C15.3929 5.79201 14.9215 5.84931 14.6661 6.1754L15.847 7.10033ZM5.5107 12.3951L8.54141 15.1273L9.54577 14.0132L6.51506 11.281L5.5107 12.3951ZM9.63404 15.0327L15.847 7.10033L14.6661 6.1754L8.45314 14.1078L9.63404 15.0327Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
