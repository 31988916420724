<template>
  <div class="-mx-2">
    <div
      v-for="item in planUnplan"
      :key="item.label"
      class="inline-flex items-center px-2"
    >
      <span
        class="inline-block w-2 h-2 rounded-full mr-1"
        :style="{ 'background-color': item.color }"
      />
      <span class="capitalize text-sm font-light">
        {{ item.label }}
      </span>
    </div>
  </div>
</template>

<script>
const PLAN_COLOR = '#168AE2';
const UNPLAN_COLOR = '#A2AEC2';
export default {
  computed: {
    planUnplan() {
      return [
        { label: this.$t('timelog.plan.planned'), color: PLAN_COLOR },
        { label: this.$t('timelog.plan.unplanned'), color: UNPLAN_COLOR }
      ];
    }
  }
};
</script>

<style></style>
