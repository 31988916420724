<template>
  <div v-if="active" class="base-modal">
    <div class="base-modal__backdrop flex-perfect-center" @click.self="close">
      <div class="base-modal__box" :class="maxWidth">
        <div class="base-modal__close">
          <base-button
            icon="Close"
            color="text"
            icon-size="24"
            wide="p-1"
            class="text-gray-600"
            @click="close"
          />
        </div>
        <div class="base-modal__content relative">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
    maxWidth: {
      type: String,
      default: 'max-w-2xl'
    }
  },
  methods: {
    close() {
      this.$emit('update:active', false);
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.base-modal {
  @apply fixed inset-0;
  z-index: 999;

  &__backdrop {
    @apply absolute inset-0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__box {
    @apply overflow-auto flex-1 relative;
    max-height: 85%;
  }

  &__content {
    @apply relative;
  }

  &__close {
    @apply absolute z-10;
    top: 20px;
    right: 20px;
  }
}
</style>
