<template>
  <div id="task-comment" class="h-full w-full">
    <div
      v-if="!disabled && !isUserViewer"
      id="task-comment-create"
      class="comment-container w-full items-stretch space-x-1"
    >
      <div>
        <base-avatar size="25px" :avatar="currentUser" class="mr-2" />
      </div>

      <task-file-drag
        v-model="showOverView"
        class="w-full"
        @upload="previewFiles($event.files)"
      />

      <div v-show="!showOverView" class="comment-container__content w-11/12">
        <div class="border rounded-lg mx-2 mb-2">
          <div class="comment-text-area rounded-md">
            <input-editor-html
              :content.sync="comment"
              class="w-full resize-none"
              rows="3"
              :placeholder="$t('modelTask.task.text')"
              type="textarea"
              :extensions="inputExtensions"
              mentions
              :upload-image="false"
              :menu="false"
              :is-border="false"
              style="border: none;"
              @files="previewFiles"
            />
            <input
              ref="openFile"
              type="file"
              multiple
              class="hidden"
              @change="previewFiles"
            />
            <input
              ref="openFileEditComment"
              type="file"
              multiple
              class="hidden"
              @change="previewFilesEditComment"
            />

            <div class="mt-4 overflow-x-auto whitespace-no-wrap">
              <task-comments-attachment
                v-for="(f, i) in previews"
                :key="`preview-${i}`"
                :file="f"
                @remove="removePreview(i)"
              />
            </div>
          </div>
        </div>
        <div class="float-right flex mx-2">
          <base-button
            color="white"
            class="border-none height-auto"
            @click="$refs.openFile.click()"
          >
            <base-icon view-box="0 0 20 20" :width="20" :height="20">
              <icon-file-straight-line />
            </base-icon>
          </base-button>

          <base-button
            v-if="!disabled"
            :disabled="!isCreateValid"
            color="brand"
            @click="addComment"
          >
            {{ $t('modelTask.task.comment') }}
          </base-button>
        </div>
      </div>
    </div>

    <div class="commentList mt-5">
      <div
        v-for="(comment, index) in getComments"
        :key="comment.id"
        class="py-4"
      >
        <div class="comment-container space-x-1">
          <div>
            <base-avatar size="25px" :avatar="comment.user" class="mr-2" />
          </div>
          <div class="w-11/12 comment-container__content border rounded-lg p-2">
            <div class="flex items-center mb-2 space-x-2">
              <span
                class="font-medium truncate"
                :class="{
                  'text-deactivated': isMemberDeactivated(comment.user)
                }"
              >
                {{ comment.user.name }}
              </span>
              <span
                v-if="isMemberDeactivated(comment.user)"
                class="badge--deactivated"
              >
                {{ $t('deactivated') }}
              </span>
              <span class="comment-date">
                <human-date :date="comment.updatedAt" />
              </span>
            </div>

            <div v-if="editingId != comment.id">
              <input-editor-html
                disabled
                :content="comment.message"
                :extensions="inputExtensions"
                mentions
                @focus="editorFocus($event, comment)"
              />

              <div v-if="comment.commentFiles" class="-m-2 flex flex-wrap">
                <div
                  v-for="(f, i) in comment.commentFiles"
                  :key="`preview-${i}`"
                  class="relative p-2 cursor-pointer"
                  :class="{
                    'w-1/3 overflow-hidden': comment.commentFiles.length > 1,
                    'w-full': comment.commentFiles.length <= 1
                  }"
                  @click="previewFileClick(comment.commentFiles, f)"
                >
                  <img
                    v-if="
                      f.file.thumbnailImageUrl &&
                        ['png', 'jpg', 'jpeg', 'gif'].includes(
                          f.file.thumbnailImageUrl.split('.').pop()
                        )
                    "
                    :class="{
                      'h-32': comment.commentFiles.length > 1,
                      'h-100': comment.commentFiles.length <= 1
                    }"
                    class="rounded-md object-cover w-full"
                    :src="f.file.thumbnailImageUrl"
                  />
                  <div
                    v-else
                    class="
                      flex flex-col
                      border border-gray-200
                      rounded-md
                      bg-gray-100
                      overflow-hidden
                      w-full
                      h-32
                    "
                  >
                    <div class="flex-1 flex items-center justify-center">
                      <base-icon class="" name="file" size="32">
                        <icon-file
                      /></base-icon>
                    </div>
                    <div
                      class="
                        border-t border-gray-200
                        bg-white
                        text-sm text-gray-800
                        px-2
                        py-1
                        truncate
                      "
                    >
                      {{ f.file.fileName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isEditing && editingId == comment.id" class="mr-2">
              <input-editor-html
                auto-focus
                :content.sync="editingComment"
                mentions
                :upload-image="true"
                :menu="true"
                :is-border="false"
                style="border: none;"
                @files="previewFilesEditComment"
              />
              <div
                class="mt-4 overflow-x-auto whitespace-no-wrap overflow-auto"
              >
                <task-comments-attachment
                  v-for="(f, i) in previewsEditComment"
                  :key="`preview-${i}`"
                  :file="f"
                  @remove="removePreviewEditComment(i)"
                />
              </div>

              <div class="mt-2 flex justify-end text-gray-600">
                <base-button
                  color="white"
                  class="border-none height-auto"
                  @click="$refs.openFileEditComment.click()"
                >
                  <base-icon view-box="0 0 20 20" :width="20" :height="20">
                    <icon-file-straight-line />
                  </base-icon>
                </base-button>
                <button class="borderless mr-5" @click="onCancelEdit(index)">
                  {{ $t('alert.button.cancel') }}
                </button>
                <base-button
                  :color="editDisabled && notEdit ? 'disabled' : 'brand'"
                  :disabled="editDisabled && notEdit"
                  wide="px-6"
                  @click="onConfirmEdit(comment)"
                >
                  {{ $t('alert.button.save') }}
                </base-button>
              </div>
            </div>
            <div
              v-if="comment.user.id === currentUser.id && editable"
              class="flex justify-end mr-2 text-gray-600 mt-2"
            >
              <button
                class="borderless mr-3"
                @click="editComment(comment.message, comment)"
              >
                {{ $t('alert.button.edit') }}
              </button>
              <button class="borderless" @click="deleteComment(comment)">
                {{ $t('alert.button.delete') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="currentPage != lastPage && lastPage != 0 && getComments.length > 0"
    >
      <button class="loadmore-button" @click="loadmore">
        <span style="color: #336dd8"> {{ $t('modelTask.task.more') }} </span>
      </button>
    </div>

    <div v-if="isLoading" class="dark-overlay">
      <div class="lds-ripple">
        <div />
        <div />
      </div>
    </div>
    <notifications
      group="edit-comment"
      class="mt-2"
      :duration="3000"
      classes="vue-notification mr-2 text-base shadow"
    />

    <modal-preview
      :id="viewFileID"
      :f="viewFile"
      :files="viewFiles"
      :task-id="taskId"
    />

    <notifications
      group="comment-error"
      class="mt-2"
      :duration="3000"
      classes="vue-notification mr-2 text-base shadow"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import StringMixin from '@/mixins/StringMixin';
import IconFileStraightLine from '@/components/icons/IconFileStraightLine.vue';
import IconFile from '@/components/icons/IconFile.vue';
import IconClose from '@/components/icons/IconClose.vue';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import InputEditorHtml from '@/components/input-editor-html.vue';
import { TaskComment } from '@/types/Task';
import HumanDate from '@/components/date/human-date.vue';
import { OrderedList, BulletList, ListItem, Image } from 'tiptap-extensions';
import { Editor } from 'tiptap';

import TaskFileDrag from '@/components/modal/modal-task-file-drag.vue';

import TaskCommentsAttachment from '@/components/comments/task-comments-attachment.vue';
import { ApiReturnError } from '@/types/Api';
import BoardUtils from '@/mixins/BoardUtils';

import ModalPreview from '@/components/modal/modal-preview.vue';
import { last } from 'lodash';
import { filesApi } from '@/services/apis';

export default Vue.extend({
  mixins: [StringMixin, DateTimeMixin, BoardUtils],
  components: {
    InputEditorHtml,
    HumanDate,
    IconFileStraightLine,
    TaskFileDrag,
    ModalPreview,
    TaskCommentsAttachment,
    IconFile
  },
  props: {
    disabled: Boolean,
    taskId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      comment: '',
      modalConfig: {
        height: 'auto',
        maxWidth: 420,
        adaptive: true
      },
      isEditing: false,
      editingId: -1,
      editingComment: '',
      editingCommentOrg: '',

      showOverView: false,
      viewFile: {},
      viewFiles: [],
      viewFileID: `${Math.random()}`,

      previews: [],
      previewsEditComment: [],
      isLoading: false,
      removeImgPreview: [],
      notEdit: true
    };
  },
  computed: {
    ...mapGetters('auth', {
      currentUser: 'getUser'
    }),
    ...mapGetters('boards', ['getMembers', 'isUserViewer']),
    ...mapGetters('tasks', ['getComments', 'getCommentsPageInfo']),
    isCreateValid() {
      const text = this.comment.replaceAll(' ', '');
      return !(text === '<p></p>' || text == '') || this.previews.length > 0;
    },
    editDisabled() {
      const text = this.editingComment.replaceAll(' ', '');

      return (
        text === '<p></p>' ||
        text == '' ||
        this.editingComment == this.editingCommentOrg
      );
    },
    currentPage() {
      return this.getCommentsPageInfo.page;
    },
    lastPage() {
      return this.getCommentsPageInfo.lastPage;
    },
    inputExtensions() {
      // TODO: add Link when tiptap is v2
      return [new OrderedList(), new BulletList(), new ListItem(), new Image()];
    },
    editable() {
      return !this.isEditing && !this.disabled;
    }
  },
  mounted() {
    const shareLinkCode = this.$route.params.linkId;
    this.fetchTaskComments({ taskID: this.taskId, shareLinkCode });
  },
  methods: {
    ...mapActions('tasks', [
      'setTaskComment',
      'fetchTaskComments',
      'editTaskComment',
      'deleteTaskComment'
    ]),
    async addComment() {
      const group = 'add-comment';
      const fileIds = [];
      this.isLoading = true;
      if (this.previews.length) {
        const { data } = await filesApi.upload('comments', this.previews);
        data.forEach(f => {
          if (f.id) fileIds.push(f.id);
        });
      }

      try {
        this.setTaskComment({
          taskID: this.taskId,
          message: this.comment,
          fileIds: fileIds
        });
        this.comment = '';
      } catch (error) {
        this.onCommentError(error, group);
      }

      this.previews = [];
      this.isLoading = false;
    },
    editComment(commentMessage, comment) {
      if (comment?.commentFiles) {
        comment.commentFiles.forEach(file =>
          this.previewsEditComment.push(file)
        );
      }

      this.editingId = comment.id;
      this.editingComment = commentMessage;
      this.editingCommentOrg = commentMessage;
      this.isEditing = true;
    },
    onCancelEdit() {
      this.editingId = -1;
      this.isEditing = false;
      this.previewsEditComment = [];
      this.notEdit = true;
    },
    async onConfirmEdit(comment) {
      const group = 'edit-comment';
      this.isLoading = true;
      if (this.editingComment.replace(' ', '') == '<p></p>') {
        this.$notify({
          text: `${this.$t('notifications.popup.empty')}`,
          type: 'error',
          group
        });
        return;
      }
      const fileId = [];
      const haveFile = this.previewsEditComment.filter(item => item.url);
      if (haveFile.length) {
        const { data } = await filesApi.upload(
          'comments',
          this.previewsEditComment
        );
        data.forEach(f => {
          if (f.id) fileId.push(f.id);
        });
      }
      if (comment?.commentFiles) {
        comment.commentFiles.forEach(file => fileId.push(file.file.id));
      }
      const a = fileId.filter(el => !this.removeImgPreview.includes(el));

      try {
        const taskCommentEdit = {
          fileIDs: a,
          taskID: this.taskId,
          commentID: comment.id,
          message: this.editingComment
        };
        await this.editTaskComment(taskCommentEdit);
        this.editingComment = '';
        this.editingId = -1;
        this.isEditing = false;
        this.notEdit = true;
        this.previewsEditComment = [];
      } catch (error) {
        this.onCommentError(error, group);
      }
      this.isLoading = false;
    },
    deleteComment(comment) {
      const group = 'delete-comment';
      try {
        const taskCommentDelete = {
          taskID: this.taskId,
          commentID: comment.id
        };
        this.showModal({
          title: this.$t('alert.deleteComment.name'),
          description: this.$t('alert.deleteComment.title'),
          button: this.$t('alert.button.delete'),
          confirmAction: 'tasks/deleteTaskComment',
          confirmData: taskCommentDelete
        });
      } catch (error) {
        this.onCommentError(error, group);
      }
    },
    showModal(data, events) {
      this.$modal.show(
        DialogConfirm,
        data,
        this.modalConfig,
        events ? events : {}
      );
    },
    loadmore() {
      this.fetchTaskComments({
        taskID: this.taskId,
        page: this.currentPage + 1
      });
    },
    onCommentError(error, group) {
      const text =
        error.response?.data.message ||
        this.$t('notifications.popup.wrong').toString();
      this.$notify({
        text,
        type: 'error',
        group
      });
    },
    editorFocus(editor, comment) {
      const self = this;
      // find current focus
      setTimeout(() => {
        const start = editor.selection.from;
        const end = editor.selection.to;
        if (start + 1 == end || (start == 0 && end == 0)) {
          const els = editor.view.docView.children;
          for (let i = 0; i < els.length; i++) {
            const e = els[i];
            if (e.posAtStart >= start || start == 0) {
              const img = e.node.content.content[0];
              if (img.type.name == 'image' || img.type.name == 'video') {
                self.viewFile = {
                  id: comment.id,
                  url: img.attrs.src,
                  updatedAt: comment.updatedAt,
                  createdAt: comment.createdAt,
                  createdByUser: self.currentUser,
                  file: {
                    fileName: '',
                    fileSize: 0,
                    typeUpload: 1
                  }
                };
                self.viewFiles = [];

                setTimeout(() => {
                  self.$modal.show(`view-file-${self.viewFileID}`);
                }, 100);
              }
              break;
            }
          }
        }
      }, 100);
    },
    previewFilesEditComment(event) {
      const self = this;
      let isErrorMax = false;
      const files = event.target ? event.target.files : event;
      this.notEdit = false;
      if (files) {
        files.forEach(f => {
          if (f.type.startsWith('image/')) {
            f.url = URL.createObjectURL(f);
          }
          const count = self.previewsEditComment.length + 1;
          if (count <= 20) {
            self.previewsEditComment.push(f);
          } else {
            isErrorMax = true;
          }
        });
      }

      if (isErrorMax) {
        this.$notify({
          text: 'max 20 file',
          type: 'error',
          group: 'comment-error'
        });
      }
      event.target.value = '';
    },
    previewFiles(event) {
      const self = this;
      let isErrorMax = false;
      const files = event.target ? event.target.files : event;
      if (files) {
        files.forEach(f => {
          if (f.type.startsWith('image/')) {
            f.url = URL.createObjectURL(f);
          }
          const count = self.previews.length + 1;
          if (count <= 20) {
            self.previews.push(f);
          } else {
            isErrorMax = true;
          }
        });
      }

      if (isErrorMax) {
        this.$notify({
          text: 'max 20 file',
          type: 'error',
          group: 'comment-error'
        });
      }
      event.target.value = '';
    },
    removePreview(index) {
      this.previews = this.previews.filter((e, i) => index != i);
    },
    removePreviewEditComment(index) {
      this.removeImgPreview.push(this.previewsEditComment[index].fileID);
      this.notEdit = false;
      this.previewsEditComment = this.previewsEditComment.filter(
        (e, i) => index != i
      );
    },

    previewFileClick(files, f) {
      const self = this;

      self.viewFile = { ...f, url: f.file.originalImageUrl || f.file.fileUrl };
      self.viewFiles = files.map(f => {
        return { ...f, url: f.file.originalImageUrl || f.file.fileUrl };
      });

      setTimeout(() => {
        self.$modal.show(`view-file-${self.viewFileID}`);
      }, 100);
    }
  }
});
</script>

<style lang="scss" scoped>
.commentBtn {
  background: #336dd8;
  color: #ffffff;
  border-radius: 10px;
}
.commentMessage {
  white-space: pre-line;
  word-break: break-word;
}
.editText {
  &:focus {
    outline: none;
  }
}
.loadmore-button {
  color: #336dd8;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
}
.borderless {
  &:focus {
    outline: none;
  }
}
.comment-container {
  @apply flex;
  &__content {
    @apply relative  flex-1;
  }
}
.comment-date {
  @apply text-gray-500 font-light text-sm whitespace-no-wrap;
  margin-left: auto;
}
</style>
